import React, {Component} from "react";

class EmptyState extends Component {

  render() {
    return (
      <div className="empty-state text-center">
        <h6 className="empty-state__title">{this.props.title}</h6>
        <div className="empty-state__message" dangerouslySetInnerHTML={{__html: this.props.message}}></div>
      </div>
    )
  }

}

export default EmptyState;