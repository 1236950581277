import React from 'react';
import { connect } from 'react-redux';
import MultiDropdown from '../../../../global/multiDropdown/multiDropdown';
import { changeSetting } from "../../../../../redux/reducers/customConfig/customConfigActions";
import { SetCustomArtColors } from "../../../../../redux/reducers/decoration/decorationAction";

function EditCustomArtColors(props) {
    let value = props.locationContent.entity.numDecorationColors === null ? "SELECT" : `${props.locationContent.entity.numDecorationColors}`;
    let options = [1, 2, 3, 4, "NOT SURE"];

    const handleOnChange = (e) => {
        let numColors = e
        if (e === "NOT SURE") {
            numColors = null;
        }
        props.changeSetting(props.customConfig.locations, props.navigation.activeLocation, { setting: "numDecorationColors", value: numColors });
        props.SetCustomArtColors(numColors)
    }
    return (
        <div className="row pl-4 pr-4 mb-2 mt-2">
            <div className="col-12 mb-2">
                <div className="row">
                    <div className="col-5 pr-1">
                        <span className="art-color-text">HOW MANY COLORS DOES THIS ARTWORK CONTAIN?</span>
                    </div>
                    <div className="col-3 pl-0 pr-0">
                        <MultiDropdown
                            customClass="multidropdown-menu"
                            value={value}
                            options={options}
                            onChange={handleOnChange}
                        />
                    </div>
                    <div className="col-4 pl-1">
                        <span className="art-options-body">We will use this to customize your decoration estimate.</span>
                    </div>
                </div>
            </div>
            <div className="col-12 pl-3 pr-2">
                <span className="art-options-body">
                    Note: Our expert artists will correct the graphic and will contact you for approval before shipping.
                </span>
            </div>
        </div>
    )
}
const reduxActions = {
    changeSetting,
    SetCustomArtColors
}

function mapStateToProps(state) {
    return {
        navigation: state.navigation,
        baseProduct: state.baseProduct,
        customConfig: state.customConfig,
        decoration: state.decoration,
        user: state.user
    }
}

export default connect(mapStateToProps, reduxActions)(EditCustomArtColors);