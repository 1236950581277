import React, { Component } from 'react'; 
import AssetItem from './AssetItem'; 
import _ from 'lodash'; 

export default class AssetResults extends Component {

  renderAssets(){
    let selectedId = _.get(this.props,"currentSelected.id", null); 
    let results = []; 
    _.each(this.props.items, (item) => {
      let selected = (item.id === selectedId)
      results.push(<AssetItem key={item.id} item={item} select={this.props.select}  isSelected={selected} />)
    }); 
    return results; 
  }


  render() {
    return (
      <div className="asset-library__results" >
        <div className="asset-results">
          {this.renderAssets()}
        </div>
      </div>
    )
  }
}
