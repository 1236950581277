import React, {Component} from "react";

export default class Input extends Component {

  render() {
    const {styleOverrides, title, value, onChange, placeholder} = this.props;
    const defaultStyle = {height: 35, width: "100%", textAlign: "center"};
    const style = Object.assign({}, defaultStyle, styleOverrides);
    return (
      <div style={{display: "flex", flexDirection: "column"}}>
        <span>{title}</span>
        <input
          value={value}
          style={style}
          onChange={e => onChange(e.target.value)}
          placeholder={placeholder}
        />
      </div>
    );
  }
}