import React, { Component } from "react"; 
import LoadingSVG from "../../../../assets/icons/LoadingSVG"; 

export default class waitingForHostModal extends Component {
  render() {
    return (
    <div className="need-action-modal d-flex justify-content-center">
      <div style={{height:"400px", padding:"80px"}}>
        <h1>Loading... Please Wait.</h1> 
        <p style={{textAlign:"center"}}>Please wait while we get things ready.</p>
        <div className="waiting-loading d-flex justify-content-center">
          <LoadingSVG height={75} width={75}/>
        </div>
      </div>
    </div>
    )
  }
}
