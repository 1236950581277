import React from 'react'

const DownloadRosterSVG = function (props) {
  const {
    className,
    height = 32,
    width = 32,
    fill = 'black',
    stroke = 'black',
    strokeWidth = '0',
  } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="30px"
      viewBox="0 0 24 24"
      width="30px"
      fill="#000000"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M19 9h-4V3H9v6H5l7 7 7-7zm-8 2V5h2v6h1.17L12 13.17 9.83 11H11zm-6 7h14v2H5z" />
    </svg>
  )
}

export default DownloadRosterSVG
