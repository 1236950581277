import React, { Component } from "react";
import {connect} from 'react-redux';
import MessageBox from "../../../global/messageBox/messageBox";
import ATCPreviewImage from "../addToCartComponents/atcPreviewImage"; 
import ATCTemplateManager from "../addToCartComponents/atcTemplateManager"; 
import Table from "../../table/table";
import _ from "lodash";
import PlusSVG from "../../../../assets/icons/PlusSVG";
import ChevronLeftSVG from "../../../../assets/icons/ChevronLeftSVG";
import helpers from "../../../../helpers";

const passRef = (fn, ...args) => ({ target: { value: v } }) => fn(args, v);

//sample roster csv sheet
let sampleRoster = [
  ["Name", "Number", "Size"],
];

class MissingRosterModal extends Component {

  constructor(props) {
    super(props);
    this.tableConfig = {
      columns: [
        { key: 'name', name: 'Name', placeholder: 'Enter Name', renderer: null },
        { key: 'number', name: 'Number', placeholder: 'Enter Number', renderer: null },
        { key: 'size', name: 'Size', placeholder: 'Size', renderer: this.renderDatalist }
      ],
      sizeOptions: props.sizes ? props.sizes : []
    };
    let stylesList = [];
    let styleDesignList = [
        {
            "key": "J5JV1A",
            "value": ["JDB004", "JDB007", "JDB010", "JDB027", "JDB028", "JDB029", "JDBT01", "JDBT04", "JDBT09", "JDS001", "JDS014", "JDS023", "JDS024",
                "JDS025", "JDS026"]
        },
        {
            "key": "J5JV1Y",
            "value": ["JDB004", "JDB007", "JDB010", "JDB027", "JDB028", "JDB029", "JDBT01", "JDBT04", "JDBT09", "JDS001", "JDS014", "JDS023", "JDS024",
                "JDS025", "JDS026"]
        },
        {
            "key": "J5JV1W",
            "value": ["JDB004", "JDB007", "JDB010", "JDB027", "JDB028", "JDB029", "JDBT01", "JDBT04", "JDBT09", "JDS001", "JDS014", "JDS023", "JDS024",
                "JDS025", "JDS026"]
        },
        {
            "key": "J5JV1G",
            "value": ["JDB004", "JDB007", "JDB010", "JDB027", "JDB028", "JDB029", "JDBT01", "JDBT04", "JDBT09", "JDS001", "JDS014", "JDS023", "JDS024",
                "JDS025", "JDS026"]
        },
        {
            "key": "J5JC1A",
            "value": ["JDB004", "JDB007", "JDB010", "JDB027", "JDB028", "JDB029", "JDBT01", "JDBT04", "JDBT09", "JDS001", "JDS014", "JDS023", "JDS024"]
        },
        {
            "key": "J5JC1W",
            "value": ["JDB007", "JDB010", "JDB027", "JDB028", "JDBT01", "JDBT04", "JDBT09", "JDS001", "JDS014", "JDS023", "JDS024",
                "JDS025", "JDS026"]
        },
        {
            "key": "J5JC1Y",
            "value": ["JDB004", "JDB007", "JDB027", "JDB029", "JDBT01", "JDBT04", "JDBT09", "JDS001", "JDS014", "JDS023", "JDS024"]
        }
    ]
    if (props.baseProduct.configurationType && props.baseProduct.configurationType == 'custom-sub') {
      sampleRoster = [
          ["Name", "Number", "Gender", "Size"],
      ];
      this.tableConfig = {
        columns: [
          { key: 'name', name: 'Name', placeholder: 'Enter Name', renderer: null },
          { key: 'number', name: 'Number', placeholder: 'Enter Number', renderer: null },
          { key: 'youthToAdult', name: 'Gender', placeholder: 'Style', renderer: this.renderDatalist1 },
          { key: 'size', name: 'Size', placeholder: 'Size', renderer: this.renderDatalist }
        ],
        styleOptions: stylesList,
        sizeOptions: props.sizes ? props.sizes : []
      };

      if (props.style != null && props.style != '') {
          if (props.style.includes('J5JV1A')){
              stylesList.push("J5JV1A");
              if (props.baseProduct.designNum) {
                  styleDesignList.map((key, val) => {
                      if (key.key.includes("J5JV1Y")) {
                          if (key.value.includes(props.baseProduct.designNum)) {
                              stylesList.push("J5JV1Y");
                          }
                      }
                      if (key.key.includes("J5JV1W")) {
                          if (key.value.includes(props.baseProduct.designNum)) {
                              stylesList.push("J5JV1W");
                          }
                      }
                      if (key.key.includes("J5JV1G")) {
                          if (key.value.includes(props.baseProduct.designNum)) {
                              stylesList.push("J5JV1G");
                          }
                      }
                  });
              }
          }
          else if (props.style.includes('J5JV1Y')) {
              stylesList.push("J5JV1Y");
              if (props.baseProduct.designNum) {
                  styleDesignList.map((key, val) => {
                      if (key.key.includes("J5JV1A")) {
                          if (key.value.includes(props.baseProduct.designNum)) {
                              stylesList.push("J5JV1A");
                          }
                      }
                      if (key.key.includes("J5JV1W")) {
                          if (key.value.includes(props.baseProduct.designNum)) {
                              stylesList.push("J5JV1W");
                          }
                      }
                      if (key.key.includes("J5JV1G")) {
                          if (key.value.includes(props.baseProduct.designNum)) {
                              stylesList.push("J5JV1G");
                          }
                      }
                  });
              }
          }
          else if (props.style.includes('J5JV1W')) {
              stylesList.push("J5JV1W");
              if (props.baseProduct.designNum) {
                  styleDesignList.map((key, val) => {
                      if (key.key.includes("J5JV1Y")) {
                          if (key.value.includes(props.baseProduct.designNum)) {
                              stylesList.push("J5JV1Y");
                          }
                      }
                      if (key.key.includes("J5JV1A")) {
                          if (key.value.includes(props.baseProduct.designNum)) {
                              stylesList.push("J5JV1A");
                          }
                      }
                      if (key.key.includes("J5JV1G")) {
                          if (key.value.includes(props.baseProduct.designNum)) {
                              stylesList.push("J5JV1G");
                          }
                      }
                  });
              }
          }
          else if (props.style.includes('J5JV1G')) {
              stylesList.push("J5JV1G");
              if (props.baseProduct.designNum) {
                  styleDesignList.map((key, val) => {
                      if (key.key.includes("J5JV1Y")) {
                          if (key.value.includes(props.baseProduct.designNum)) {
                              stylesList.push("J5JV1Y");
                          }
                      }
                      if (key.key.includes("J5JV1A")) {
                          if (key.value.includes(props.baseProduct.designNum)) {
                              stylesList.push("J5JV1A");
                          }
                      }
                      if (key.key.includes("J5JV1W")) {
                          if (key.value.includes(props.baseProduct.designNum)) {
                              stylesList.push("J5JV1W");
                          }
                      }
                  });
              }
          }
          else if (props.style.includes('J5JC1A')) {
              stylesList.push("J5JC1A");
              if (props.baseProduct.designNum) {
                  styleDesignList.map((key, val) => {
                      if (key.key.includes("J5JC1Y")) {
                          if (key.value.includes(props.baseProduct.designNum)) {
                              stylesList.push("J5JC1Y");
                          }
                      }
                      if (key.key.includes("J5JC1W")) {
                          if (key.value.includes(props.baseProduct.designNum)) {
                              stylesList.push("J5JC1W");
                          }
                      }
                  });
              }
          }
          else if (props.style.includes('J5JC1Y')) {
              stylesList.push("J5JC1Y");
              if (props.baseProduct.designNum) {
                  styleDesignList.map((key, val) => {
                      if (key.key.includes("J5JC1A")) {
                          if (key.value.includes(props.baseProduct.designNum)) {
                              stylesList.push("J5JC1A");
                          }
                      }
                      if (key.key.includes("J5JC1W")) {
                          if (key.value.includes(props.baseProduct.designNum)) {
                              stylesList.push("J5JC1W");
                          }
                      }
                  });
              }
          }
          else if (props.style.includes('J5JC1W')) {
              stylesList.push("J5JC1W");
              if (props.baseProduct.designNum) {
                  styleDesignList.map((key, val) => {
                      if (key.key.includes("J5JC1Y")) {
                          if (key.value.includes(props.baseProduct.designNum)) {
                              stylesList.push("J5JC1Y");
                          }
                      }
                      if (key.key.includes("J5JC1A")) {
                          if (key.value.includes(props.baseProduct.designNum)) {
                              stylesList.push("J5JC1A");
                          }
                      }
                  });
              }
          } else
              stylesList.push(props.style);
      }
    }
  }
  
  handleUploadFile = (e) => {
    const file = _.get(e, "[0]");
    const acceptableSize = helpers.roster.validateSize(file);

    //if file is acceptable size try formatting, dispatch action or error message according to parse results
    //else if file size isnt acceptable dispatch error message

    if(e && e.length > 0 && acceptableSize){
      helpers.roster.parseRoster(e, this.props.allowedProductSizes, this.getData);
    } else if(!acceptableSize){
      this.props.showToast("Try a smaller size.");
    }
  }
  
  getData = (values) => {
    if(values && !_.isEmpty(values)){
      this.props.format(values);
    } else {
      this.props.showToast("Formatting issue. Use our roster template.");
    }
  }

  renderDatalist1 = (updateCell1, deleteRow1, addRow1, isLastRow1, keys1, styleOpts) => {
      return (cellData1, columnIndex1, rowIndex1, columnKey1) => {
          const onChange1 = isLastRow1 ? passRef(addRow1, columnKey1, keys1) : passRef(updateCell1, columnKey1, rowIndex1);
          const defaultOpt1 = cellData1 ? null : <option value="" disabled select="true"></option>
          return (
            <span className="tbl-complex1" key={columnIndex1}>
              <ChevronLeftSVG className="tblc-arrow" height={12} />
                <select onChange={onChange1} value={cellData1 ? cellData1 : helpers.product.getDefaultStyles(this.props.style)[0]}>
                  {defaultOpt1}
                    {styleOpts.map((val1, i) => <option key={i} value={val1}>{val1}</option>)}
                </select>
                  {
                      isLastRow1 ? <div style={{ minWidth: "22px" }}></div> : (
                          <div onClick={passRef(deleteRow1, rowIndex1)}>
                            <PlusSVG height="15px" width="15px" fill={"black"} />
                          </div>
                      )
                  }
            </span>
          )
      }
  };

  renderDatalist = (updateCell, deleteRow, addRow, isLastRow, keys, sizeOptions) => {
    return (cellData, columnIndex, rowIndex, columnKey) => {
      const onChange = isLastRow ? passRef(addRow, columnKey, keys) : passRef(updateCell, columnKey, rowIndex);
      const defaultOpt = cellData ? null : <option value="" disabled select="true"></option>
      return (
        <span className="tbl-complex" key={columnIndex}>
          <ChevronLeftSVG className="tblc-arrow" height={12} />
          <select onChange={onChange} value={cellData ? cellData : helpers.product.getDefaultSize()}>
            {defaultOpt}
            {sizeOptions.map((val, i) => <option key={i} value={val}>{val}</option>)}
          </select>
          {
            isLastRow ? <div style={{ minWidth: "22px" }}></div> : (
              <div onClick={passRef(deleteRow, rowIndex)}>
                <PlusSVG height="15px" width="15px" fill={"black"} />
              </div>
            )
          }
        </span>
      )
    }
  };

  render() {
    let rosterItems = _.get(this.props.rosters, "[0].items", []);
    let hasRoster = !_.isEmpty(rosterItems);
    let tableData = _.get(this.props, ["rosters", 0, "items"], []);
    let firstPreview = _.find(this.props.previews, v => { return v && v.data; });
    let firstPreviewImageData = _.get(firstPreview, 'data', null);
    let cancelToolTip = _.find(this.props.toolTips, { key: "missing-roster-cancel" }).message;
    let downloadToolTip = _.find(this.props.toolTips, { key: "download-roster-template" }).message;
    let uploadToolTip = _.find(this.props.toolTips, { key: "download-roster-template" }).message;
    let nextToolTip = _.find(this.props.toolTips, { key: "player-roster-next-button" }).message;
    const hasPlayerName = helpers.locations.hasFilledLocationByEntityType(helpers.appConst.EntityTypes.PlayerName);
    const hasPlayerNumber = helpers.locations.hasFilledLocationByEntityType(helpers.appConst.EntityTypes.PlayerNumber);
    const hasNecessaryData = helpers.roster.doesAnyRowHaveValidRosterData(hasPlayerName, hasPlayerNumber, this.props.baseProduct.configurationType);
    const disableSave = hasNecessaryData ? "" : "custom-disabled-button";

    return (<div className="missing-roster-modal">
      <div className="missing-roster-container">
        <div className="top-container">
          <div className="left-column">
            <ATCPreviewImage imageData={firstPreviewImageData} />
            <ATCTemplateManager 
              uploadToolTip={uploadToolTip}
              downloadToolTip={downloadToolTip}
              handleUploadFile={this.handleUploadFile} 
              rosterData={sampleRoster} 
              fileName="roster_template.csv"/>
          </div>
          <div className="right-column">
            <div>
              <div className="atc-header-message">
                <div style={{ width: "100%", fontSize: "16px", textAlign: "center" }}>
                  You're almost there
                </div>
                <div style={{ width: "100%", margin: "15px 0px 30px 0px", fontSize: "12px", textAlign: "center" }}>
                  Please add in your roster data.
                </div>
              </div>
              <div className="roster__container">
                <div className="roster-table fillx" style={{maxHeight : "160px", overflowY: "scroll"}}>
                  <Table
                    config={this.tableConfig}
                    tableData={tableData}
                    isEditable={true}
                    startInEditMode={true}
                    showToggleEditButton={false}
                  />
              </div>
            </div>
          </div>
            {(!hasRoster) ?
              <MessageBox
                className="custom-roster-message-box"
                title="Your roster is empty"
                message="You can add new items by clicking on any row."
                message2="You can also upload a spreadsheet file with roster information."
              />
              : null}
          </div>
        </div>
        <div className="bottom-row">
          <div className="left-button">
            {/* <button className="btn btn-outline-primary h-100 w-50">Skip This Step</button> */}
          </div>
          <div className="right-buttons">
            <button aria-label={cancelToolTip} data-balloon-pos="left"  onClick={() => this.props.toggle()} className="btn btn-outline-primary btn-atc">Back</button>
            <button aria-label={nextToolTip} data-balloon-pos="right" onClick={this.props.next} style={{ marginLeft: "5px", marginTop: "0px" }} className={`btn btn-primary btn-atc ${disableSave}`}>Next</button>
          </div>
        </div>
      </div></div>
    )
  }
}

function mapStateToProps(state) {
  return {
    reduxState: state,
    sizes: state.baseProduct.sizes,
    toolTips: state.toolTips,
    locations: _.get(state, "customConfig.locations", []),
    baseProduct: state.baseProduct,
    style: state.baseProduct.legacyStyleNum
  }
}

export default connect(mapStateToProps, null)(MissingRosterModal); 
