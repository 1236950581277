import React, { Component } from "react";
import MessageBox from "../../../global/messageBox/messageBox";
import ATCPreviewImage from "../addToCartComponents/atcPreviewImage";
import ATCTemplateManager from "../addToCartComponents/atcTemplateManager";
import helpers from "../../../../helpers";
import _ from "lodash"


export default class QuantityModal extends Component {

  constructor(props) {
    super(props)

    let sampleRoster = [["Size", "Quantity"]];
    _.each(props.loadedDefs.productSizes, e => {
      sampleRoster.push([e.code, 0])
    });

    this.state = {
      sampleRoster: sampleRoster
    }

    this.handleUploadFile = this.handleUploadFile.bind(this);
  }


  updateQuantity(value, size) {
    const safeValue = Number(value);
    const isIncreasing = (size.quantity < safeValue);
    const isDecreasing = (size.quantity > safeValue);

    if (isIncreasing) {
      const objectToAdd = { name: null, number: null, size: size.key };
      this.props.addMultipleRows(objectToAdd, safeValue - size.quantity);
    } else if (isDecreasing) {
      this.props.removeMultipleRows(size.key, safeValue, size.quantity);
    }
  }

  // toggleQuantity(size, value) 
  //   if (value === 1 && size.quantity < 999 && size.key) {
  //     this.props.addRow(["size", ["name", "number", "size"]], size.key);
  //   } else if (value < 0 && size.quantity > 0 && size.key) {
  //     let index = null;
  //     _.each(this.props.primaryRosterItems, (el, i) => {
  //       if (el && el.size === size.key) {
  //         index = i;
  //       }
  //     });
  //     if (_.isFinite(index)) {
  //       this.props.removeRow([index]);
  //     }
  //   }
  // }

  handleUploadFile = (e) => {
    const file = _.get(e, "[0]");
    const acceptableSize = helpers.roster.validateSize(file);

    //if file is acceptable size try formatting, dispatch action or error message according to parse results
    //else if file size isnt acceptable dispatch error message

    if (e && e.length > 0 && acceptableSize) {
      helpers.roster.parseQuantityRoster(e, this.props.allowedProductSizes, this.getData);
    } else if (!acceptableSize) {
      this.props.showToast("Try a smaller size.");
    }
  }

  getData = (values) => {
    if (values && !_.isEmpty(values)) {
      this.props.format(values);
    } else {
      this.props.showToast("Formatting issue. Use our roster template.");
    }
  }

  render() {
    let disableSave = this.props.sizeQuantities.length > 0 ? "" : "custom-disabled-button";
    let firstPreview = _.find(this.props.previews, v => { return v && v.data; });
    let firstPreviewImageData = _.get(firstPreview, 'data', null);
    const sizesWithQuantities = _.map(this.props.allowedProductSizes, sizeKey => {
      let sqItem = _.cloneDeep(_.find(this.props.sizeQuantities, { size: sizeKey }));
      if (!sqItem) { sqItem = { size: sizeKey, quantity: 0 }; }
      if (!sqItem.quantity < 0) { sqItem.quantity = 0; }
      sqItem.key = sizeKey;
      return sqItem;
    });
    let uploadToolTip = _.find(this.props.toolTips, { key: "upload-roster-button" }).message;
    let downloadToolTip = _.find(this.props.toolTips, { key: "download-roster-template" }).message;
    let cancelTooltip = _.find(this.props.toolTips, { key: "missing-roster-cancel" }).message;
    let nextTooltip = _.find(this.props.toolTips, { key: "player-roster-next-button" }).message;
    let inputTooltip = _.find(this.props.toolTips, { key: "quantity-input" }).message;

    return (<div className="missing-roster-modal">
      <div className="quantity-modal missing-roster-container">
        <div className="top-container">
          <div className="left-column">
            <ATCPreviewImage imageData={firstPreviewImageData} />
            <ATCTemplateManager
              uploadToolTip={uploadToolTip}
              downloadToolTip={downloadToolTip}
              handleUploadFile={this.handleUploadFile}
              uploadSizes={this.props.uploadSizes}
              rosterData={this.state.sampleRoster}
              fileName="size_quantity_template.csv" />
          </div>
          <div className="right-column">
            <div>
              <div className="quantity-modal__header1">
                <div className="atc-header-message">
                  <div style={{ width: "100%", fontSize: "16px", textAlign: "center" }}>
                    You're almost there
                  </div>
                  <div style={{ width: "100%", margin: "15px 0px 15px 0px", fontSize: "12px", textAlign: "center" }}>
                    Fill out your size and quantities below.
                  </div>
                </div>
                <div className="size-quantity-container">
                  {sizesWithQuantities.map((e, i) => {
                    return (
                      <div key={e.key} className="size-quantity-input">
                        <div style={{ marginBottom: "-10px" }}>
                          {e.key}
                        </div>
                        {/* <div className="size-quantity-input-up" onClick={f => this.toggleQuantity(e, 1)} />
                        <div className="size-quantity-input-down" onClick={f => this.toggleQuantity(e, -1)} /> */}
                        <div aria-label={inputTooltip} data-balloon-pos="up" >
                          <input
                            onChange={f => this.updateQuantity(f.target.value, e, i)}
                            type="number"
                            value={_.parseInt(e.quantity, 0)}
                            className="custom-input-field"
                            id="number"
                            min={0}
                            max={10000} />
                          {/* <input
                          className="custom-input-field"
                          type="text"
                          value={_.parseInt(e.quantity, 0)}
                          min={0}
                          maxLength={3} //WARNING possible memory leak here with high quantity numbers see customConfigActions.js line 379
                          onChange={f => this.updateQuantity(f.target.value, e, i)} /> */}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <MessageBox
              className="custom-roster-message-box"
              title="Tell us how many you want"
              message="You can add new items by clicking on any row."
              message2="You can also upload a spreadsheet file with size and quantity information."
            />
          </div>
        </div>
        <div className="bottom-row">
          <div className="left-button">
            {/* <button className="btn btn-outline-primary h-100 w-50">Skip This Step</button> */}
          </div>
          <div className="right-buttons">
            <button aria-label={cancelTooltip} data-balloon-pos="left" className="btn btn-outline-primary btn-cancel h-100 w-50" onClick={this.props.toggle}>Back</button>
            <button aria-label={nextTooltip} data-balloon-pos="right" style={{ marginTop: "0px" }} className={`btn btn-primary btn-next h-100 w-50 ${disableSave}`} onClick={this.props.next}>Next</button>
          </div>
        </div>
      </div>
    </div>
    )
  }
}
