import React from 'react';
import BadgerIconSVG from '../../../assets/icons/BadgerIconSVG';
import AllesonIconSVG from '../../../assets/icons/AllesonIconSVG';
import GarbIconSVG from '../../../assets/icons/GarbIconSVG';
import _ from 'lodash';


export default function Title(props){

  let productPrefix = props.styleNum? ('#' + props.styleNum): null;
  let formattedBrandName = _.trim(props.brandName);
  let legacyStyleNum = _.trim(props.legacyStyleNum);
  let formattedProductName = _.join(_.uniq(_.compact([legacyStyleNum, props.productName])), ' ');
  let formattedProductNameStr = _.join(_.uniq(_.compact([productPrefix, formattedProductName])), ' - ');
  let formattedProductDescription = _.trim(props.desc) || formattedProductNameStr;

  function brandImage(brandKey) {
    let retval = null;
    switch (brandKey) {
      case 'badger':
        retval = <BadgerIconSVG width="25px" height="25px"/>;
        break;
      case 'alleson':
        retval = <AllesonIconSVG width="25px" height="25px"/>;
        break;
      case 'garb':
        retval = <GarbIconSVG width="25px" height="25px"/>
        break;
      case 'teamwork':
        break;
      case 'founders':
        break;
      default:
        break;
    }
    return retval;
  }

  return (
    <div className="title">
      <div className="title__brand">
      {brandImage(props.brandKey)}
        <div className="title__brand__brand--name">
          {formattedBrandName}
        </div>
      </div>
      <div aria-label={formattedProductDescription} data-balloon-pos="right" className="title__product">
        {formattedProductNameStr}
      </div>
    </div>
  );
}
