import _ from 'lodash';
import * as Sentry from '@sentry/browser';
import AppConfig from '../../config';


export function loadSentry() {
  if (!AppConfig.sentryDsn || _.isEmpty(AppConfig.sentryDsn)) {
    return;
  }

  Sentry.init({dsn: AppConfig.sentryDsn});

  let appInfo = _.get(AppConfig, 'appInfo', {});
  let startupData = {
    version: appInfo.version,
    buildDate: appInfo.buildDate,
    gitSha: appInfo.gitSha,
    gitBranch: appInfo.gitBranch
  };

  _setTags(startupData);
}

export function sendSentryLaunchContextData(launchContext){ //used in redux middleware

  if (!AppConfig.sentryDsn || _.isEmpty(AppConfig.sentryDsn)){
    return;
  }

  let styleNum = _.get(launchContext, 'styleNum', null);
  let configId = _.get(launchContext, 'configId', null);
  let webUserId = _.get(launchContext, 'webUserId', null);
  let corpAcctId = _.get(launchContext, 'corpAcctId', null);
  let aeWebUserId = _.get(launchContext, 'aeWebUserId', null);
  let aeCorpAcctId = _.get(launchContext, 'aeCorpAcctId', null);
  let sentryId = webUserId || aeWebUserId || null;
  let startupData = { styleNum, configId, sentryId, webUserId, corpAcctId, aeWebUserId, aeCorpAcctId };

  let setUserId = (scope) => {
    if (aeWebUserId) {
      scope.setUser({ id: sentryId });
    }
  };

  _setTags(startupData, setUserId);
}

function _setTags(data, cb){
  Sentry.configureScope(scope => {
    Object.entries(data).forEach(([key, val]) => {
      scope.setTag(key, val);
    });

    if (typeof cb === 'function') {
      cb(scope);
    }
  });
}
