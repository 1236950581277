import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import MultiDropdown from '../../../../global/multiDropdown/multiDropdown';
import { changeSetting } from "../../../../../redux/reducers/customConfig/customConfigActions";

function EditTextHeightAndWidth(props) {

  let locationContent = _.find(props.customConfig.locations, { code: props.navigation.activeLocation });
  // let bpLocation = _.find( props.baseProduct.locations, { code: locationContent.code } );
  // let bpAllowedEntity = _.find( bpLocation.allowedEntities, { key: locationContent.entity.type } );
  // bpDecorationMethod has all allowed sizes for this location and entity type.
  // let bpDecorationMethod = _.find ( bpAllowedEntity.allowedDecorationMethods, { key: locationContent.entity.decorationMethod } );
  let masterImageDpi = _.find(props.baseProduct.views, { key: locationContent.view }).masterImageDpi;

  const handleOnChange = (e) => {
    let setting = e.setting;
    let value = parseInt(e.value) * masterImageDpi; // value coming in as string converting it to number.

    let newBounds = _.cloneDeep(locationContent.entity.bounds);
    if (setting === 'height') {
      newBounds.h = value;
      props.changeSetting(props.customConfig.locations, props.navigation.activeLocation, { setting: "bounds", value: newBounds });
      props.changeSetting(props.customConfig.locations, props.navigation.activeLocation, { setting: "_heightUpdated", value: true });
    } else if (setting === 'width') {
      newBounds.w = value;
      props.changeSetting(props.customConfig.locations, props.navigation.activeLocation, { setting: "bounds", value: newBounds });
      props.changeSetting(props.customConfig.locations, props.navigation.activeLocation, { setting: "_widthUpdated", value: true });
    }
  }

  let valueWidth = parseInt(locationContent.entity.bounds.w) / masterImageDpi;
  let valueHeight = parseInt(locationContent.entity.bounds.h) / masterImageDpi;
  valueWidth = valueWidth.toFixed(1);
  valueHeight = valueHeight.toFixed(1);
  let maxHeight = 10000; // should get from product location;
  let maxWidth = 10000; // should get from product location;
  let rightLabel = 'inches';


  return (
    <div className="EditTextHeightAndWidth" style={{ marginTop: "15px", position: "relative" }}>
      <div className="row pb-4 pt-2 pl-4">
        <div className="col-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 pt-2 pl-0">
          <label className="decoration-label">{"WIDTH(inches)"}</label>
        </div>
        <div className="col-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12">
          <div>
            <MultiDropdown
              customClass="multidropdown-menu"
            // marginTop=" drop-down-margin-top"
            // label='Width'
            // rightLabel={rightLabel}
            />
            <input
              className='text-height-number-input'
              type='number'
              value={valueWidth}
              id='number'
              placeholder="Select a text height"
              min={1}
              max={maxHeight}
              onChange={e => handleOnChange({ setting: 'width', value: e.target.value })} />
          </div>
        </div>
      </div>
      <div className="row pl-4">
        <div className="col-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 pt-2 pl-0">
          <label className="decoration-label">{"HEIGHT(inches)"}</label>
        </div>
        <div className="col-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12">
          <div>
            <MultiDropdown
              customClass="multidropdown-menu"
            // marginTop=" drop-down-margin-top"
            // label='Height'
            // rightLabel={rightLabel} 
            />
            <input
              className='text-height-number-input'
              type='number'
              value={valueHeight}
              id='number'
              placeholder="Select a text height"
              min={1}
              max={maxWidth}
              onChange={e => handleOnChange({ setting: 'height', value: e.target.value })} />
          </div>
        </div>
      </div>

    </div>
  )
}

const reduxActions = {
  changeSetting
}

function mapStateToProps(state) {
  return {
    navigation: state.navigation,
    baseProduct: state.baseProduct,
    customConfig: state.customConfig
  }
}

export default connect(mapStateToProps, reduxActions)(EditTextHeightAndWidth);