import React, { Component } from 'react'; 
import _ from "lodash"; 
import api from "../../../../api"; 
import { connect } from "react-redux"; 
import UserArtImage from '../../userArtModalComponents/userArtImage'; 
import UserArtButtonGroup from "../../userArtModalComponents/userArtButtonGroup"; 
import UserArtNameInput from "../../userArtModalComponents/userArtNameInput"; 
import UserArtNotSureButton from "../../userArtModalComponents/userArtNotSureButton"; 
import UserArtNumberOfColorsInput from "../../userArtModalComponents/userArtNumberOfColorsInput"; 
import { updateUserArtAssetData } from "../../../../redux/reducers/user/userActions"; 
import { toggleUserArtEditMode } from "../../../../redux/reducers/navigation/navigationActions"; 
class EditUserArtDataModal extends Component {

  constructor(props){
    super(props); 
    let numDecorationColors = _.get(props, "item.numDecorationColors", 0) || 0; 
    let isNotSure = (1 > numDecorationColors); 
    let name = _.get(props, "item.name", "") || ""; 
    this.state={
      isNotSure,
      name, 
      numDecorationColors
    }
  }

  componentWillUnmount(){
    this.props.toggleUserArtEditMode(false); 
  }

  save = async () => {      
      let assets = _.cloneDeep(this.props.user.userArtAssets); 
      let data = _.find(assets, {id : this.props.item.id}); 
      if(data){      
        data['name'] = this.state.name; 
        let ndc =  (this.state.isNotSure) ? null : this.state.numDecorationColors; 
        data['numDecorationColors'] = ndc; 
        api.webUserArtAssets.updateNameAndNumberOfColors(this.props.item.id, this.state.name, ndc).then(data, () => {}); 
      }
      this.props.updateUserArtAssetData(assets);       
      this.props.toggle(); 
  }

  handleName = (e) => {
    this.setState({ name: e.target.value }); 
  }

  handleDecorationColors = (e) => {
    this.setState({ numDecorationColors: e.target.value, isNotSure: false }); 
  }

  render() {  
    return (
      <div className = "edit-userart">
        <div className = "edit-userart__panels">
          <div className="edit-userart__lpanel">
            <UserArtImage url={this.props.item.url} alt={"art"} />
            <div className="edit-userart__approval-message">Our expert artists will correct the art and contact you for approval before shipping</div>
          </div>
          <div className="edit-userart__rpanel">
            <UserArtNameInput name={this.state.name} onChange={this.handleName} />
            <div className="edit-userart__numdc">
              <div className="edit-userart__numdc-label">How many colors does your artwork contain?</div>
              <div className="edit-userart__numdc-inputs">
                <UserArtNumberOfColorsInput 
                  isActive={!this.state.isNotSure}
                  setActive={() => this.setState({isNotSure:false})}
                  value = {this.state.numDecorationColors}
                  onChange = {this.handleDecorationColors} />
                <UserArtNotSureButton 
                  isNotSure={this.state.isNotSure} 
                  onClick={ () => this.setState({isNotSure:true}) } />
               </div>
              <div className="edit-userart__numdc-use">We will use this to customize your decoration estimate.</div>
            </div>                
              <UserArtButtonGroup
                save={this.save}
                close={this.props.toggle} />
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state){
  return {
    user: state.user
  }; 
}

const reduxActions = {
  toggleUserArtEditMode, 
  updateUserArtAssetData
}; 

export default connect (mapStateToProps, reduxActions)(EditUserArtDataModal); 
