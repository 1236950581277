import _ from 'lodash';
import axios from 'axios';
import urljoin from 'url-join';
import { ErrorDisplayHandler } from '../../lib/actionTracker';


export default class ApiRequestHelper {

  constructor(apiConfig = null) {
    this.config = apiConfig || {};
  }

  get baseUrl() {
    return _.get(this.config, 'baseApiUrl', null);
  }

  get imagesRasterizerUrl() {
    // return _.get(this.config, 'imagesRasterizerApiUrl', null);
    return '____imagesRasterizerApiUrl____';
  }

  buildUrl(path = '', queryParams = null) {
    let urlPath = this.buildUrlPath(path, queryParams);
    return urljoin(this.baseUrl, urlPath);
  }

  buildUrlPath(path = '', queryParams = null) {
    if (_.isArray(path)) {
      path = _.map(path, _.toString);  // make sure all args passed to urljoin are strings
      path = urljoin(...path);
    }
    let url = _.toString(path) || '';

    let queryParamsStr = this.buildQueryJsonString(queryParams);
    if (queryParamsStr) {
      let delim = _.includes(url, '?') ? '&' : '?';
      url += delim + queryParamsStr;
    }

    return url;
  }

  buildQueryJsonString(queryParams = null) {
    let str = '';
    if (!_.isEmpty(queryParams)) {
      const { designNum, ...qP } = queryParams;
      str += !designNum ? '' : designNum ? `designNum=${designNum}` : `design=${designNum}`;
      // str += designNum ? `designNum=${designNum}` : '';
      str = !_.isEmpty(qP) ? 'json=' + encodeURIComponent(JSON.stringify(qP)) : str;
    }
    return str;
  }

  buildQueryUrlString(queryParams = null) {
    let str = '';
    if (!_.isEmpty(queryParams)) {
      str = _.chain(queryParams)
        .map((v, k) => {
          let v2 = null;
          if (!_.isUndefined(k)) {
            v2 = String(k);
            if (!_.isUndefined(v)) {
              v2 += '=' + String(v);
            }
          }
          return v2;
        })
        .compact()
        .join('&')
        .value();
    }
    return str;
  }

  getAuthTokenFromState() {
    return _.get(this.config, 'authToken', null);
  }

  buildRequestConfig(opts) {
    let config = null;
    if (opts && opts.auth) {
      const authToken = this.getAuthTokenFromState();
      config = { headers: { 'Authorization': `Bearer ${authToken}` } };
    }
    return config;
  }

  get(path = '', queryParams = null, opts = null, errOpts = null) {
    let url = this.buildUrl(path, queryParams);
    let config = this.buildRequestConfig(opts);

    return axios.get(url, config)
      .then(this.handleApiResults.bind(this))
      .catch((err) => this.handleApiError(err, { url, ...errOpts }));
  }

  put(path = '', putParams = null, queryParams = null, opts = null, errOpts = null) {
    let url = this.buildUrl(path, queryParams);
    let config = this.buildRequestConfig(opts);

    return axios.put(url, putParams, config)
      .then(this.handleApiResults.bind(this))
      .catch((err) => this.handleApiError(err, { url, ...errOpts }));
  }

  post(path = '', postParams = null, queryParams = null, opts = null, errOpts = null) {
    let url = this.buildUrl(path, queryParams);
    let config = this.buildRequestConfig(opts);

    return axios.post(url, postParams, config)
      .then(this.handleApiResults.bind(this))
      .catch((err) => this.handleApiError(err, { url, ...errOpts }));
  }

  postFileToConvert(path = '', fileObj = null) {
    const url = urljoin(this.imagesRasterizerUrl, path);
    // const url = path;
    const config = {};
    config.headers = config.headers || {};
    config.headers['content-type'] = 'multipart/form-data';
    config.responseType = 'arraybuffer';
    return axios.post(url, fileObj, config)
      .catch((err) => this.handleApiError(err, { path }));
  }

  postFile(path = '', postParams = null, queryParams = null, opts = null, errOpts = null) {
    let url = this.buildUrl(path, queryParams);
    let config = this.buildRequestConfig(opts) || {};
    config.headers = config.headers || {};
    config.headers['content-type'] = 'multipart/form-data';

    return axios.post(url, postParams, config)
      .then(this.handleApiResults.bind(this))
      .catch((err) => { this.handleApiError(err, { url, ...errOpts }) });
  }

  patch(path = '', patchParams = null, queryParams = null, opts = null, errOpts = null) {
    let url = this.buildUrl(path, queryParams);
    let config = this.buildRequestConfig(opts);

    return axios.patch(url, patchParams, config)
      .then(this.handleApiResults.bind(this))
      .catch((err) => this.handleApiError(err, { url, ...errOpts }));
  }

  delete(path = '', queryParams = null, opts = null, errOpts = null) {
    let url = this.buildUrl(path, queryParams);
    let config = this.buildRequestConfig(opts);

    return axios.delete(url, config)
      .then(this.handleApiResults.bind(this))
      .catch((err) => this.handleApiError(err, { url, ...errOpts }));
  }

  handleApiResults(res) {
    let data = (res && res.data) || null;
    return Promise.resolve(data);
  }

  handleApiError(err, errOpts = null) {
    ErrorDisplayHandler.handleDisplayApiErr(err, errOpts);
    return Promise.reject(err);
  }

  resolve(res) {
    return Promise.resolve(res);
  }

  reject(err) {
    return Promise.reject(err);
  }
}

