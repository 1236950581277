import React from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types"
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import BaseInputComponent from '../baseInputComponent/baseInputComponent';
import { toggleDelete } from "../../../redux/reducers/navigation/navigationActions";
import _ from "lodash"
import { ErrorDisplayHandler } from '../../../lib/actionTracker';
import { FaCaretDown } from "react-icons/fa";

class MultiDropdown extends BaseInputComponent {

  static get propTypes() {
    return {
      // eslint-disable-next-line
      ...super.propTypes,
      options: PropTypes.array,
      optionRenderer: PropTypes.func,
      onHover: PropTypes.func,    // TODO: $DEPRECATED.  use BaseInputComponent onHover
      onHoverOff: PropTypes.func, // TODO: $DEPRECATED.  use BaseInputComponent onHover
    }
  };

  static get defaultProps() {
    return {
      ...super.defaultProps,
      options: [],
      optionRenderer: _.noop,
      onHover: _.noop,        // TODO: $DEPRECATED.  use BaseInputComponent onHover
      onHoverOff: _.noop,     // TODO: $DEPRECATED.  use BaseInputComponent onHover
    }
  };

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    // this.renderOptionItem = this.renderOptionItem.bind(this);
    this.state = {
      ...this.state,
      initialValue: props.value,    // TODO: $DEPRECATED.  use BaseInputComponent onHover. we shouldn't need initialValue or originalKey
      toggle: false,
      inDropdown: false
    };
  }

  componentWillUnmount() {
    if (this.state.toggle) {
      this.props.toggleDelete(false);
    }
  }

  toggle() {
    this.props.toggleDelete(!this.state.toggle);
    this.setState(prevState => ({
      toggle: !prevState.toggle,
      initialValue: this.props.originalKey    // TODO: $DEPRECATED.  use BaseInputComponent onHover. we shouldn't need initialValue or originalKey
    }));
  }

  onOptionClicked(e) {
    this.props.onChange(e);                   // TODO: $DEPRECATED.  use BaseInputComponent setValue instead. don't trigger props.onChange twice
    this.setState({ value: e, inDropdown: false });
  }

  onOptionHovered(e) {
    // TODO: Logan. update this to use BaseInputComponent hover state
    // this.setHover(e);

    this.props.onHover(e);
    this.setState({ value: e, inDropdown: true });
  }

  handleOnMouseLeave() {
    // TODO: Logan. update this to use BaseInputComponent hover state
    // this.setHover(null);

    let e = _.find(this.props.options, { key: this.state.initialValue });
    this.props.onHoverOff(e)
    this.setState({ inDropdown: false });
  }

  renderValue() {
    let returnValue = null;
    if (this.props.value && this.props.value.name) {
      returnValue = this.props.value.name;
    } else if (this.props.value && typeof this.props.value === "string") {
      returnValue = this.props.value;
    } else {
      returnValue = "-";
    }
    return returnValue;
  }

  renderOptions(e) {
    if (e && e.name) {
      return e.name;
    } else if (e) {
      return e;
    }
  }

  mapOptions(options, imageKey, isImageStyle) {
    if (options.length !== 0) {
      const errObj = { message: "400" };
      const sourceKey = "image_renderer_modify_text";
      const handleErr = () => ErrorDisplayHandler.handleDisplayApiErr(errObj, { sourceKey });

      let optionKey = _.get(options, "[0].key")
      if (!optionKey) {
        optionKey = _.get(options, "[0]")
      }

      return (
        <div>
          {
            options.map((e, i) => {
              const key = _.get(e, "key");
              const keySuffix = i + key;
              let toolTip = null;
              if (this.props.toolTips) {
                toolTip = _.find(this.props.toolTips, { key: key }).message;
              }
              return (
                <div
                  key={"option-" + keySuffix + optionKey + i}
                  aria-label={toolTip}
                  data-balloon-pos="up"
                >
                  {imageKey ?
                    <DropdownItem
                      style={{ padding: "0px" }}
                      className="dropdown-menu-option">
                      {
                        imageKey ? <img onMouseOver={() => this.onOptionHovered(e)} onMouseLeave={() => this.handleOnMouseLeave()} onClick={() => this.onOptionClicked(e)}
                          alt="" src={e[imageKey]} style={isImageStyle} onError={handleErr} /> : this.renderOptions(e)
                      }
                    </DropdownItem>
                    :
                    <DropdownItem
                      onMouseOver={() => this.onOptionHovered(e)}
                      onMouseLeave={() => this.handleOnMouseLeave()}
                      onClick={() => this.onOptionClicked(e)}
                      className="dropdown-menu-option"
                      key={"option-" + i + key}
                    >
                      {
                        imageKey ? <img
                          alt=""
                          src={e[imageKey]}
                          style={isImageStyle}
                          onError={handleErr} /> : this.renderOptions(e)
                      }
                    </DropdownItem>
                  }
                </div>
              );
            })
          }
        </div>
      );
    } else {
      return null;
    }
  }


  renderChildComponent() {
    //if (this.state.value === null || this.props.options.length === 0) {
    if (this.props.options.length === 0) {
      return (null);
    }
    const { imageKey, isImageStyle } = this.props;

    return (
      <div className={this.props.className + " multi-dropdown"}>
        <Dropdown style={{ width: "100%" }} isOpen={this.state.toggle} toggle={this.toggle}>
          <div aria-label={this.props.menuToolTip} data-balloon-pos="left">

            <DropdownToggle
              color='secondary'
              className="dropdown-menu-space-between p-1"
              outline
            >
              <div className="row">
                <div className="col-8">
                  <div className="dropdown-value"> {this.renderValue()}</div>
                </div>
                <div className="col-4">
                  <div className="dropdown-caret"><FaCaretDown /></div>
                </div>
              </div>


            </DropdownToggle>
          </div>
          <DropdownMenu
            //onMouseLeave={() => this.onMouseLeave()}
            className={this.props.customClass}
          // className="multidropdown-menu"
          // modifiers={{
          //   setMaxHeight: {
          //     enabled: true,
          //     order: 890,
          //     fn: data => {
          //       return {
          //         ...data,
          //         styles: {
          //           ...data.styles,
          //           overflow: "auto",
          //           maxHeight: "100px"
          //         }
          //       };
          //     }
          //   }
          // }}
          >
            {
              this.props.menuHeader ? (
                <DropdownItem header>
                  {this.props.menuHeader}
                </DropdownItem>
              ) : null
            }
            {this.mapOptions(this.props.options, imageKey, isImageStyle)}

          </DropdownMenu>
        </Dropdown>
      </div>
    )
  }
}

export default connect(null, { toggleDelete })(MultiDropdown)