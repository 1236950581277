import { RestService } from '../lib';


/**
 * Service Endpoint Implementation
 */
export default class AuthService extends RestService {

  constructor(opts = null) {
    super(opts);
    this.serviceName = 'auth';
    this.routePath = 'builder/web-user/auth';
    this.showroomDealerUserAuthRoutePath = 'auth/showroom-dealer';
    this.authEnabled = true;
  }

  async loginAEWebUser(aeWebUserId, aeCorpAcctId, guestWebUserId = null, guestCorpAcctId = null, prevAuthToken = null, allowGuest = true) {
    let errOpts = { sourceKey: 'auth' };

    // NOTE: if aeWebUserId or aeCorpAcctId are invalid, a guest user may be returned
    aeWebUserId = aeWebUserId || undefined;
    aeCorpAcctId = aeCorpAcctId || undefined;
    guestWebUserId = guestWebUserId || undefined;
    guestCorpAcctId = guestCorpAcctId || undefined;
    prevAuthToken = prevAuthToken || undefined;
    allowGuest = !!allowGuest;

    if (!allowGuest) {
      guestWebUserId = undefined;
      guestCorpAcctId = undefined;
    }

    let data = { aeWebUserId, aeCorpAcctId, guestWebUserId, guestCorpAcctId, prevAuthToken, allowGuest };

    return this.request.post(this.routePath, data, null, errOpts);
  }

  async loginShowroomDealerUser(dealerUserId, guestUserId = null, prevAuthToken = null, allowGuest = true) {
    let errOpts = { sourceKey: 'auth' };

    // NOTE: if aeWebUserId or aeCorpAcctId are invalid, a guest user may be returned
    dealerUserId = dealerUserId || undefined;
    guestUserId = guestUserId || undefined;
    prevAuthToken = prevAuthToken || undefined;
    allowGuest = !!allowGuest;

    if (!allowGuest) {
      guestUserId = undefined;
    }

    let data = { dealerUserId, guestUserId, prevAuthToken, allowGuest };
    return this.request.post(this.showroomDealerUserAuthRoutePath, data, null, errOpts);
  }

}


