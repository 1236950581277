import React, { Component } from "react";
import ColorPicker from "../../../../../global/colorSwatch/colorPicker";
import NewColorPicker from "../../../../../global/colorSwatch/NewColorPicker";
import helpers from "../../../../../../helpers";
import _ from "lodash";
import Constants from "../../../../../../constants";
import { connect } from "react-redux";

class TextColorSelector extends Component {
  onColorChanged(color, type, index) {
    this.props.changeColor(color, type, index);
  }

  constructor(props) {
    super(props);

    let allColors = _.get(props, "loadedDefs");
    let allowedColorCodes = _.get(props, "allowedColors");
    let allowedColors;
    let chromaColors;
    let noneColor;

    if (this.props.chromagear) {
      chromaColors = this.props.colorPalettes;
    } else {
      allowedColorCodes.push(null);
      allowedColors = _.map(allowedColorCodes, (v) => {
        return _.find(allColors, { code: v });
      });
      allowedColors = _.uniq(allowedColors);
      noneColor = _.find(allowedColors, { key: "none" });
    }

    this.state = {
      // noneColor,
      allowedColors: allowedColors,
      chromaColors: chromaColors,
      noneColor: noneColor,
      selectedDecorationMethod: null,
    };
  }

  handleColorHover(v, key, i) {
    this.props.onColorHovered(v, key, i);
  }

  generateHoverText(hoverColorName, allowedColors) {
    let retval = hoverColorName || "";
    if (!_.isEmpty(hoverColorName)) {
      let match = allowedColors.find((el) => el.name === hoverColorName);
      let colorCode = _.get(match, "code");
      let decorationMethod = _.get(this.props, "entity.key", null);
      let costlyColors =
        decorationMethod &&
        _.get(Constants.DecorationMethodCostlyColors, decorationMethod);
      let isCostlyColor =
        colorCode && costlyColors && _.includes(costlyColors, colorCode);
      if (isCostlyColor) {
        retval += " $";
      }
    }
    return retval;
  }
  renderConditionalColors = (
    availableColors,
    decorationMethodName,
    currentAction
  ) => {
    let colorsToSend = availableColors;
    const colorsForPlayerNumber = [
      "BK",
      "CB",
      "FS",
      "GD",
      "KG",
      "MA",
      "NY",
      "RY",
      "SI",
      "WH",
    ];
    const colorsForPlayerName = [
      "BK",
      "CB",
      "FS",
      "GD",
      "KG",
      "MA",
      "NY",
      "PU",
      "RY",
      "SI",
      "VG",
      "WH",
    ];
    availableColors = [
      ...availableColors,
      {
        code: "VG",
        hex: "#c0b077",
        id: 301,
        key: "vegas_gold",
        name: "Vegas Gold",
        rgb: 12628087,
        rgbObj: { b: 119, g: 176, r: 192 },
        swatchUrl: null,
      },
    ];
    if (decorationMethodName === "screenprint-duratek")
      switch (currentAction) {
        case "player-number":
          return (colorsToSend = availableColors.filter((v) =>
            colorsForPlayerNumber.includes(v.code)
          ));
        case "player-name":
          return (colorsToSend = availableColors.filter((v) =>
            colorsForPlayerName.includes(v.code)
          ));
        default:
          return colorsToSend;
      }
    return colorsToSend;
  };

  getColorPalettes = () => {
    const { completeLoadedDefs, baseProduct } = this.props;
    let colorPalettes = [];
    if (baseProduct.configurationType === "custom-sub") {
      _.each(baseProduct.customLayers.allowedColorPalettes, (palette) => {
        let paletteDef = _.find(completeLoadedDefs.colorPalettes, {
          key: palette,
        });
        let tempPalette = _.cloneDeep(paletteDef);
        let defColors = [];
        _.each(tempPalette.colors, (color) => {
          let defColor = _.find(completeLoadedDefs.colors, { code: color });
          defColors.push(defColor);
          tempPalette.colors = defColors;
        });
        colorPalettes.push(tempPalette);
      });
    }
    return colorPalettes;
  };

  render() {
    let defaultColors = this.props.defaultColors;
    let isDisabled;
    let { customConfig, chromagear } = this.props;
    if (chromagear) {
      let configPrimaryColors = [];
      let configLayerColors = [];
      _.each(customConfig.customLayers.primaryColors, (e) => {
        configPrimaryColors.push(e);
      });
      if (helpers.customLayers.isBasicMode()) {
        if (defaultColors !== customConfig.customLayers.primaryColors) {
          defaultColors = configPrimaryColors;
        }
      } else if (helpers.customLayers.isAdvancedMode()) {
        // let baseProductcolors = _.get(baseProduct, "customLayers.defaultColors");
        let configLayers = _.get(customConfig, "customLayers.layers");
        _.each(configLayers, (layer) => {
          configLayerColors.push({ color: layer.color, key: layer.key });
        });
        if (defaultColors !== configLayerColors) {
          defaultColors = configLayerColors;
        }
      }
    } else {
      //render text color selections

      defaultColors = this.props.entity.capabilities.fillColors;
      let patternFill =
        this.props.customLocation.entity.capabilities.textPatternFillId ===
          null ||
        this.props.customLocation.entity.capabilities.textPatternFillId ===
          undefined
          ? false
          : true;
      isDisabled = false;
      if (
        defaultColors !==
        this.props.customLocation.entity.capabilities.fillColors
      ) {
        defaultColors =
          this.props.customLocation.entity.capabilities.fillColors;
      }

      if (patternFill) {
        let index = defaultColors
          .map((decorationFill) => {
            return decorationFill.key;
          })
          .indexOf("fill");
        if (index === 0) {
          defaultColors.splice(0, 1);
        }
      } else {
        if (defaultColors[0].key !== "fill") {
          this.props.customLocation.entity.capabilities.fillColors = [
            { key: "fill", value: "LG" },
            { key: "outline1", value: null },
            { key: "outline2", value: null },
          ];
        }
      }

      //To check if the entity type is player-number and splice outline2 from decoration methods other than screenprint-duratek
      if (
        this.props.customLocation.entity.type === "player-number" &&
        defaultColors.length > 2
      ) {
        var index = defaultColors
          .map(function (decorationFill) {
            return decorationFill.key;
          })
          .indexOf("outline2");
        defaultColors.splice(index, 1);
      }
      // to show outline 1 first if null and show outline 2 once outline 1 is selected
      if (defaultColors.length > 2) {
        var indexOutline = defaultColors
          .map(function (decorationFill) {
            return decorationFill.key;
          })
          .indexOf("outline1");
        if (
          defaultColors[indexOutline].key === "outline1" &&
          defaultColors[indexOutline].value === null
        ) {
          var indexOutline2 = defaultColors
            .map(function (decorationFill) {
              return decorationFill.key;
            })
            .indexOf("outline2");
          defaultColors = defaultColors.slice(0, indexOutline2);
        } else {
          defaultColors = this.props.entity.capabilities.fillColors;
          if (
            defaultColors !==
            this.props.customLocation.entity.capabilities.fillColors
          ) {
            defaultColors =
              this.props.customLocation.entity.capabilities.fillColors;
          }
        }
      }
    }

    return (
      <div
        className="text-color-selector-container"
        style={this.props.customWidth}
      >
        {defaultColors.map((e, i) => {
          let hint = null;
          let key = null;
          let toolTipKey = null;
          let allColors = _.get(this.props, "loadedDefs");
          let allowedColorCodes = _.get(this.props, "allowedColors");
          if (this.props.chromagear) {
            key = _.get(e, "key");
            let layerDef = _.find(this.props.baseProduct.customLayers.layers, {
              key: key,
            });
            if (layerDef) {
              hint = layerDef.name;
            } else {
              hint = `Color ${i + 1}`;
            }
            toolTipKey = "select-color-default";
          } else {
            key = _.get(e, "key");
            switch (key) {
              case "fill": {
                hint = "Fill";
                toolTipKey = "edit-text-fill-color-picker";
                break;
              }
              case "outline1": {
                hint = "Outline 1";
                toolTipKey = "edit-text-outline-1-color-picker";
                break;
              }
              case "outline2": {
                hint = "Outline 2";
                toolTipKey = "edit-text-outline-2-color-picker";
                break;
              }
              default: {
                hint = "Select Color";
                toolTipKey = "select-color-default";
                break;
              }
            }
          }
          let allowedColors = _.map(allowedColorCodes, (v) => {
            return _.find(allColors, { code: v });
          });

          if (defaultColors.length > 1) {
            allowedColors.push(this.state.noneColor);
          }

          if (defaultColors.length === 1) {
            let noneIndex = _.findIndex(allowedColors, { key: "none" });
            allowedColors.splice(noneIndex, 1);
          }

          allowedColors = _.uniq(allowedColors);
          if (key === "fill") {
            let noneIndex = allowedColors.filter((e) => e.key.includes("none"));
            if (noneIndex) {
              allowedColors = allowedColors.slice(0, -1);
            } else {
              allowedColors = allowedColors;
            }
          } else {
            allowedColors = allowedColors;
          }

          let updatedAllowedColors = this.getColorPalettes();
          let isStock = false;

          if (
            this.props.baseProduct.designNum === null ||
            this.props.baseProduct.designNum === "default"
          ) {
            isStock = true;
            let selectedDecoration =
              this.props.customLocation.entity.decorationMethod;
            let entityType = this.props.customLocation.entity.type;
            allowedColors = this.renderConditionalColors(
              allowedColors,
              selectedDecoration,
              entityType
            );
          } else {
            if (updatedAllowedColors && updatedAllowedColors.length > 0) {
              if (key != "fill") {
                allowedColors = updatedAllowedColors;
                let colors = [];
                let colorsObj = {};
                colorsObj.id = null;
                colorsObj.hex = "#ffffff";
                colorsObj.key = "none";
                colorsObj.rgb = 16777215;
                colorsObj.code = null;
                colorsObj.name = "None";
                colorsObj.swatchUrl = null;
                let rgbObj = {};
                rgbObj.b = 255;
                rgbObj.g = 255;
                rgbObj.r = 255;
                colorsObj.rgbObj = rgbObj;
                colors.push(colorsObj);

                let noneColor = {};
                noneColor.key = "none";
                noneColor.name = "None";
                noneColor.colors = colors;

                allowedColors.push(noneColor);
              } else {
                allowedColors = updatedAllowedColors;
              }
            }
          }
          let selectedColor = null;

          if (this.props.chromagear) {
            selectedColor = helpers.colors.getColor(e.color);
            if (!selectedColor) {
              selectedColor = helpers.colors.getColor(e);
            }
          } else {
            selectedColor = helpers.colors.getColor(e.value);
          }

          if (!selectedColor) {
            selectedColor = this.state.noneColor;
          }
          if (!selectedColor) {
            selectedColor = helpers.colors.getColor(e.defaultColor);
          }

          let toolTip = _.find(this.props.toolTips, {
            key: toolTipKey,
          }).message;
          let toolTipDirection = "up";
          if (key === "outline2") {
            toolTipDirection = "left";
          } else if (key === "fill") {
            toolTipDirection = "right";
          }

          let topMargin = null;
          if (defaultColors.length > 3) {
            topMargin = "5px";
          } else {
            topMargin = "0px";
          }

          let keyId = null;

          if (this.props.chromagear) {
            keyId = " chromagear-color-group";
            allowedColors = this.state.chromaColors;
          } else {
            keyId = " color-swatch-group";
          }
          if (this.props.baseProduct.configurationType === "stock-dec") {
            return (
              <div key={key + keyId + i}>
                <div className="row">
                  <div className="col-4">
                    <ColorPicker
                      toggleDelete={this.props.toggleDelete}
                      value={selectedColor}
                      chromagear={this.props.chromagear}
                      colorOptions={allowedColors}
                      watchProps={true}
                      dropdownHeader="Select a Color"
                      toolTip={toolTip}
                      toolTipDirection={toolTipDirection}
                      chromaDrawer={this.props.chromaDrawer}
                      type={key}
                      // label={hint}
                      // hint={selectedColor.name}
                      displayHint={true}
                      hoverColorName={this.generateHoverText(
                        this.props.hoverColorName,
                        allowedColors
                      )}
                      disabled={key === "outline2" && isDisabled ? true : false}
                      onChange={(v) => this.onColorChanged(v, key, i)}
                      onHover={(v) => this.handleColorHover(v, key, i)}
                      isStock={isStock}
                      id={i}
                    />
                  </div>
                  <div className="col-8 pt-2">
                    <div className="row">
                      <div className="col-12 text-color-hint-heading">
                        {hint.toUpperCase()}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-color-name-subheading">
                        {selectedColor.name.toUpperCase()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div key={key + keyId + i}>
                <div className="row">
                  <div className="col-3 pl-3 pr-0 ">
                    <ColorPicker
                      toggleDelete={this.props.toggleDelete}
                      value={selectedColor}
                      chromagear={this.props.chromagear}
                      colorOptions={allowedColors}
                      watchProps={true}
                      dropdownHeader="Select a Color"
                      toolTip={toolTip}
                      toolTipDirection={toolTipDirection}
                      chromaDrawer={this.props.chromaDrawer}
                      type={key}
                      // label={hint}
                      // hint={selectedColor.name}
                      displayHint={true}
                      hoverColorName={this.generateHoverText(
                        this.props.hoverColorName,
                        allowedColors
                      )}
                      disabled={key === "outline2" && isDisabled ? true : false}
                      onChange={(v) => this.onColorChanged(v, key, i)}
                      onHover={(v) => this.handleColorHover(v, key, i)}
                      isStock={isStock}
                      id={i}
                    />
                  </div>
                  <div className="col-8 pt-2">
                    <div className="row">
                      <div className="col-12 text-color-hint-heading">
                        {hint.toUpperCase()}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-color-name-subheading">
                        {selectedColor.name.toUpperCase()}
                      </div>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    baseProduct: state.baseProduct,
    customConfig: state.customConfig,
  };
}

export default connect(mapStateToProps, {})(TextColorSelector);
