import React, { Component } from 'react'; 
import _ from 'lodash'; 
import consts from '../../../../../../constants'; 
import { FootballSVG } from '../../../../../../assets/icons/FootballSVG';
import { BasketballSVG } from '../../../../../../assets/icons/BasketballSVG';
import { BaseballSVG } from '../../../../../../assets/icons/BaseballSVG';
import { TennisSVG } from '../../../../../../assets/icons/TennisSVG';
import { HockeySVG } from '../../../../../../assets/icons/HockeySVG';

export default class QuickLinks extends Component {

  renderLinks(){
    let quickLinks = []; 
    _.each(consts.QuickLinks, (link) => {
      let name = (this.props.selected === link) ? `fast_link fast_link_selected ${link}` : `fast_link ${link}`; 
      quickLinks.push(<button 
        key={link} 
        onClick={ () => this.props.search(link) }  
        className={ name }> 
          { this.findLogo(link) } 
        </button>);
    }); 
    return quickLinks;
  }

  findLogo(value){
    switch (value) {
      case "basketball": return (<BasketballSVG height={25} width={25} />); 
      case "baseball": return (<BaseballSVG height={25} width={25} />); 
      case "tennis": return (<TennisSVG height={25} width={25} />); 
      case "hockey": return (<HockeySVG height={25} width={25} />); 
      default: return (<FootballSVG height={25} width={25} />); 
    }
}

  render() {
    let browse_all = (this.props.selected === "all") ? "browse_all fast_link_selected" : "browse_all";
    return ( 
      <div className="asset-library_fast_links">
        {this.renderLinks()}
        <button 
          className={ browse_all } 
          onClick={() => this.props.search("all")}>
          Browse All
        </button>
      </div>);
  }
}
