import base from './defaults/defaultUser'; 
import _ from 'lodash'; 


class UserValidator {

  creator(webUserId, corpAcctId, userArtAssets = [] ){
        let userState = {};
        userState.corpAccount = {
          id: corpAcctId,
          name: 'Dealer # ' + _.toString(corpAcctId)
        };
        userState.webUser = {
          id: webUserId,
          name: 'Web User # ' + _.toString(webUserId)
        };
        userState.userPatterns = [];
        userState.userArtAssets = !_.isEmpty(userArtAssets)? userArtAssets: [];
        return userState; 
  }

  validate(val) {
    val = Object.assign({}, base, val); 
    return val;
  }

}

const userValidator = new UserValidator(); 
export default userValidator; 
