import React, { Component } from 'react'
import DesignTable from './designTable/designTable';
import api from '../../api';
import _ from 'lodash';


export default class MyDesigns extends Component {

  constructor(props){
    super(props); 

    this.state = {
      data: [], 
      dataLoaded: false,
      total: 0,
      searchTotal: 0,
      query : { 
        $skip: 0, 
        $limit: 5, 
        $sort:[{updatedAt:false}], 
        $attributes:["id","name","configId","webRef","updatedAt","previews","createdAt"]        
      }
    };
      
    this.goToPage = this.goToPage.bind(this); 
    this.search = this.search.bind(this); 
  }

  componentDidMount(){
    this.goToPage(1);
  }

  async search( searchString ){
    searchString = _.trim( searchString ); 
    let query = _.clone( this.state.query ); 
    query["$skip"] = 0; 
    query["$where"] = ( _.isEmpty( searchString ) ) ? {} : { $or: [{ name: {$ilike:`%${searchString}%`} }, { webRef: {$ilike: `%${searchString}%`}} ] }; 
    return api.webUserSavedConfigs.find( query ).then( res => {
      let data = _.get(res, 'data', []); 
      let total = _.get(res, 'total', 0); 
      this.setState({data, total, query});  
    }).catch( () => {
      this.setState({data:[], total:0}); 
    }); 
  }

  async goToPage( page=1 ) {
    page = page - 1; 
    let query = _.clone( this.state.query ); 
    query["$skip"] = page * 5; 
    return api.webUserSavedConfigs.find( query ).then( res => {
      let data = _.get(res, 'data', []);
      let total = _.get(res, 'total', 0); 
      this.setState( { data, total, dataLoaded: true, query } );
     }).catch( () => {
      this.setState( { data: [], dataLoaded: true, query } );
     }); 
  }

  render() {
    return (
      <div className="mydesigns-app fade-in">
        <DesignTable 
          totalCount = {this.state.total}
          search = {this.search}
          pagination = {this.goToPage}
          data = {this.state.data} 
          dataLoaded = {this.state.dataLoaded} />
      </div>
    );
  }
}
