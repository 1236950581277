import { RestService } from '../lib';

export default class BaseProductPricingService extends RestService {

    constructor(opts = null) {
        super(opts);
        this.serviceName = 'baseProductPricing';
        this.routePath = 'builder/product-pricing';

        // keep track of recent requests and avoid calling the API multiple times for the same request
        this.maxNumRecentRequests = 20;
        this.recentRequests = [];
    }

    async _baseProductPricesFromApi(aeCorpId, styleNum) {
        let queryParams = null;
        let opts = this._getDefaultRequestOpts();
        let errOpts = { sourceKey: 'base_product_prices' };

        let postParams = {};
        postParams.aeCorpId = aeCorpId.toString();
        postParams.styleNum = styleNum;

        return this.request.post(this.routePath, postParams, queryParams, opts, errOpts);
    }
}