import React from "react";

const ChevronLeftSVG =(props)=>{
  const { className, height=32, width=32, fill="black", stroke = "black", strokeWidth = "0", onClick } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 16" className={className} height={height} width={width} fill={fill} stroke={stroke} strokeWidth={strokeWidth} onClick={onClick}>
    <path d="M5.5 3L7 4.5 3.25 8 7 11.5 5.5 13l-5-5 5-5z"/>
    </svg>
  );
};

export default ChevronLeftSVG;