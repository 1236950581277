import React from 'react';

export const TennisSVG = (props) => {
  const { className, height=32, width=32, fill="black", stroke="black", strokeWidth="0" } = props;
    return (
      <div>
        <svg
          className={className}
          height={height}
          width={width} 
          fill={fill} 
          stroke={stroke} 
          strokeWidth={strokeWidth}
          xmlns="http://www.w3.org/2000/svg" 
          viewBox="0 0 496 512">
            <path d="M219 79.1c-.9-27.9 5.6-52.4 18.4-71.1C113.8 13.2 5.8 110.6 0 245.3c18.7-12.9 43.1-19.6 71.1-18.4C150 228.8 221.2 150.3 219 79.1zm207.4 157.8c19.4.4 64.8-3.1 58.7-54.8-11.6-37.2-32-72.3-61.5-101.8S359 30.4 321.8 18.8c-51.7-6-55.4 39.2-54.8 58.7 1.5 47.1-21.6 101.6-58.7 138.7-37.1 37.3-91.3 60.6-138.7 58.8-19.6-.5-64.9 3.1-58.7 55 11.6 37.2 31.9 72.2 61.4 101.7s64.5 49.8 101.7 61.4c51.8 6 55.6-39.1 55-58.7-1.5-47.1 21.6-101.6 58.7-138.7 37.2-37.2 91.9-60.7 138.7-58.8zm-1.5 48.1c-71.5-2.6-150.3 70.4-147.9 147.9.9 27.9-5.6 52.4-18.5 71.1 121-5.1 231.6-100.1 237.4-237.4-18.6 12.8-43.1 19.3-71 18.4z"/>
          </svg>
      </div>
    )
  }
