import React from "react";

const LogoBadgerIconSVG =(props)=>{
  const { className, height=32, width=32, fill="black", fill2="#ed2124", stroke = "black", strokeWidth = "0", onClick } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" className={className} height={height} width={width} fill={fill} fill2={fill2}stroke={stroke} strokeWidth={strokeWidth} onClick={onClick}>
      <g id="Group_644" transform="translate(0 .004)">
        <path id="Path_454" d="M78.8 73v24.8h8.6c8.4-2.3 9-9.9 9-16.6 0-6.1-1-12-4.4-14.7 4.4-2.5 8.6-5.5 12.4-8.8 9.9 3.1 19.7 12.4 19.7 22.9-.2 13-11.1 25.2-25.4 25.2-8.4 0-46.4-.4-46.4 0V81.4c6.4-1.4 12.7-3.2 18.9-5.5 2.6-1 5.1-1.8 7.6-2.9M52.3 58.5s6.7.2 12.2-3.4c5.5-3.6 14.1-9 14.1-9V61c-7.8 4.6-17 8.4-26.3 11.6V58.5z"/>
        <path id="Path_455" d="M43.9 32.5s25.2-9.9 50-10.3c24.8-.4 25.4 13.2 18.1 24.4C104.6 57.7 76.3 83.3 3.8 85c0 0 64.7-7.1 87.8-31.3C123.3 19.9 59.9 31 43.9 32.5" fill={fill2}/>
      </g>
    </svg>
  );
};

export default LogoBadgerIconSVG;