import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateCell, addRow, removeRow } from "../../../redux/reducers/customConfig/customConfigActions";
import _, { camelCase } from "lodash";
import { translatePrice } from "../../../helpers/translation";

const passRef = (fn, ...args) => ({ target: { value: v } }) => fn(args, v);

export class Cell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ""
    }
  }

  componentDidUpdate() {
    const canAddRow = !this._isNotMounted && this.props.isLastRow && this.state.value.length > 0;
    if (canAddRow) {
      this.props.addRow(this.props.columnKey, this.state.value, this.props.keyOrder);
      this.setState({ value: "" });
    }
  }

  componentWillUnmount() { this._isNotMounted = true };

  updateValue = ({ target: { value } }) => this.setState({ value });

  renderDefaultEdit = (cellData, columnIndex, rowToModify, columnToModify) => {
    let tableData = _.get(this.props.customConfig, ["rosters", 0, "items"], []);
    let location = _.get(this.props.customConfig, "locations",  null );
    // if(location){
    //   location.forEach(item => {
    //     if (item.entity.type === 'player-name') {
    //       tableData.forEach( (item,index) => {

    //       })
    //     } else if (item.entity.type === 'player-number') {
          
    //     }
    //   });
    // }
    const onChange = this.props.isLastRow ? this.updateValue : passRef(this.props.updateCell, columnToModify, rowToModify);
    const placeholder = this.props.isLastRow ? this.props.placeholder : "";
    const value = this.props.isLastRow ? this.state.value : cellData;
    return (
      columnToModify === 'qty' ? <input
          key={`input-${columnIndex}`} type="number"
          value={value !== "" ? value : 1}
          onChange={onChange} min="0" /> : columnToModify === 'number'? <input
          key={`input-${columnIndex}`} type="number" value={value !== "" ? value : 0} 
          step="1" onChange={onChange} min="0" />  : columnToModify === 'price' ? ""
        : <input
          key={`input-${columnIndex}`} type="text" placeholder={placeholder}
          value={value ? value : ""}
          onChange={onChange}
        /> 
    )
  };

  determineRenderFn = () => {
    const { cellData, columnIndex, rowIndex, columnKey, updateCell, removeRow, isLastRow, addRow, keyOrder, config } = this.props;
    const columns = _.get(this.props.config, "columns");
    const activeRow = columns.filter((el) => el.key === columnKey);
    let optionsVal = config.sizeOptions;
    if (activeRow && activeRow.length > 0) {
      if (activeRow[0].key == 'size')
        optionsVal = config.sizeOptions;
      else if (activeRow[0].key == 'youthToAdult')
        optionsVal = config.styleOptions;
      else
        optionsVal = config.styleOptions;
    }
    const customRenderFn = activeRow.length > 0 && _.get(activeRow, "[0].renderer");
    const renderFn = customRenderFn ? customRenderFn(updateCell, removeRow, addRow, isLastRow, keyOrder, optionsVal) : this.renderDefaultEdit;
    return renderFn(cellData, columnIndex, rowIndex, columnKey);
  };

  buildClassName = () => {
    const { columnIndex, rowIndex } = this.props;
    const cellcolor = columnIndex === 5 ? "tbl-cell-price" : "tbl-dark"
    const constantClass = columnIndex === 5 ? `tbl ${cellcolor} tbl-col-${columnIndex} ` : `tbl tbl-cell tbl-cell-data tbl-col-${columnIndex} ${cellcolor} `;
    const dynamicClass = (this.props.editMode ? `tbl-cell-edit tbl-size-${columnIndex}` : 'tbl-cell-view');
    return constantClass + dynamicClass;
  };

  render() {
    let displayValue;
    if (this.props.editMode) {
      displayValue = this.determineRenderFn();
    } else if (this.props.isLastRow) {
      displayValue = _.get(this.props.columnConfig, "lastRowVal");
      const isNumber = Number(displayValue) > 0;
      if (isNumber) {
        displayValue = displayValue;
      }
    } else {
      const { cellData } = this.props;
      const isNumber = typeof cellData === "number";
      const isMoney = !this.props.columnConfig.isCount; // cellData > 3 isnt as flexible as each column telling if it is just a number
      displayValue = isNumber && isMoney ? cellData : cellData;
    }

    const isZero = displayValue === 0;

    return (
      <div key={this.props.columnIndex} className={this.buildClassName()}>
        {isZero ? null : _.get(this.props.columnConfig, "symbol")}
        {isZero ? 0 : displayValue}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {customConfig: state.customConfig}
}

export default connect(mapStateToProps, { updateCell, addRow, removeRow })(Cell);

Cell.propTypes = {
  updateCell: PropTypes.func.isRequired,
  addRow: PropTypes.func.isRequired,
  removeRow: PropTypes.func.isRequired
};
