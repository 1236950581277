import React from 'react';
import PropTypes from 'prop-types';
import BaseInputComponent from '../baseInputComponent/baseInputComponent';
import CheckmarkSVG from '../../../assets/icons/CheckmarkSVG';
import { connect } from 'react-redux';
import { clearHover } from '../../../redux/reducers/hover/hoverActions';
import helpers from '../../../helpers';
import _ from 'lodash';
import SlashLeftSVG from "../../../assets/icons/SlashLeftSVG";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const SLASH_COLOR = '#fe0000';
const DISABLED_COLOR = '#e2e2e2';
const MISSING_COLOR = null; // '#c1ff93';

class NewColorSwatch extends BaseInputComponent {

    // NOTE: if you extend propTypes in child component class, you will get a react warning.
    static get propTypes() {
        return {
            // eslint-disable-next-line
            ...super.propTypes,
            className: PropTypes.string,
            onClick: PropTypes.func,
            inlinePadding: PropTypes.number
        }
    };

    static get defaultProps() {
        return {
            ...super.defaultProps,
            onClick: _.noop,
            inlinePadding: 0
        }
    };

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            // initialValue: props.chosenColorObject     // TODO: $DATA_CLEANUP$  use BaseInputComponent value. remove initialValue. remove chosenColorObject
        };

        this.onButtonClick = this.onButtonClick.bind(this);
        this.onButtonHover = this.onButtonHover.bind(this);
        this.onButtonHoverLeave = this.onButtonHoverLeave.bind(this);
        this.setHoverDebounced = _.debounce(this.setHover.bind(this), 50);
    }

    onButtonClick() {
        this.setValue(this.state.value, true);
        this.props.onClick(this.state.value);
    }

    onButtonHover() {
        this.setHoverDebounced(this.state.value);
    }

    onButtonHoverLeave() {
        this.setHoverDebounced(null);
    }

    renderChildComponent() {
        // console.log("Props:", this.props);
        // we need to be able to render a hex rgb color and a swatch image url
        // add ability to toggle between rendering a color in the button and rendering an svg?
        let color = this.state.value;
        let isLightColor = helpers.colors.isLightColor(color);

        let classes = 'new-color-swatch btn-color-swatch1';
        if (this.props.className) {
            if (this.props.className === 'dropdown-toggle-color-swatch0')
                classes = 'new-color-swatch btn-color-swatch2';
            else
                classes = 'new-color-swatch btn-color-swatch3';
        }


        if (this.hasChangeOrHoverListener()) {
            classes += ' clickable';
        }

        let isColorNone = (!color || color.key === "none");

        let innerSwatches = null;
        let childColorCodes = _.split(color && color.code, '/');
        //let isMultiColor = (_.size(childColorCodes) >= 2);
        let isMultiColor;
        if (isMultiColor) {
            // render multi color child swatches
            childColorCodes = _.slice(childColorCodes, 0, 4); // enforce max limit of 4 color swatches
            let childColorDefs = _.map(childColorCodes, v => { return helpers.colors.getColor(v); });
            innerSwatches = _.map(childColorDefs, this.renderInnerSwatch.bind(this));
        } else {
            // render single color swatch
            innerSwatches = [this.renderInnerSwatch(color, this.props.isSelected)];
        }

        if (isLightColor) {
            classes += ' light-color';
        }

        if (isMultiColor) {
            classes += ' multi-color multi-color-' + _.size(childColorCodes);
        }

        let showSelectedCheckMark = (this.props.isSelected && !this.props.isDisabled);
        if (showSelectedCheckMark) {
            classes += ' selected';
        }
        if (this.props.isDisabled) {
            classes += ' disabled';
        }

        let customWidth;
        if (this.props.navigation.activeTab.tab === "colors") {
            if (this.props.customStyle) {
                customWidth = "75px"
            }
        } else {
            if (this.props.customStyle) {
                customWidth = "33px"
            }
        }

        return (
            <div
                className={classes}
                //style={{ margin: this.props.inlinePadding, width: customWidth }}
                disabled={this.props.isDisabled}
                onClick={this.onButtonClick}
                onMouseOver={this.onButtonHover}
                onMouseLeave={this.onButtonHoverLeave}
                //title={this.props.value ? this.props.value.name : "none"}
            >
                { innerSwatches }
                { showSelectedCheckMark ? this.renderSelectedCheckmark() : null}
                { isColorNone ? this.renderColorNone() : null}
            </div>
        );
    }

    renderInnerSwatch(colorDef, selected, swatchIdx = 0) {
        let childColorCodes = _.split(colorDef && colorDef.code, '/');
        childColorCodes = _.slice(childColorCodes, 0, 4);
        let childColorDefs = _.map(childColorCodes, v => { return helpers.colors.getColor(v); });

        let colorHex = null;
        if (this.props.isDisabled) {
            colorHex = DISABLED_COLOR;
        } else if (colorDef) {
            if (colorDef.rgb === null || colorDef.rgb === undefined) {
                colorHex = MISSING_COLOR;
            } else {
                colorHex = helpers.colors.toHexString(colorDef.rgb);
            }
        } else {
            colorHex = MISSING_COLOR;
        }

        let style = {};
        let style1 = {};

        if (this.props.className) {
            if (colorHex && this.props.className === 'dropdown-toggle-color-swatch0') {
                style.backgroundColor = colorHex;
                style.boxShadow = '0px 3px 6px #00000029';
                style.opacity = 1;
            } else {
                style.backgroundColor = colorHex;
                style.boxShadow = '0px 3px 6px #00000029';
                style.opacity = 1;
            }
        } else {
            style1.width = '9px';
            style1.height = '9px';
            style1.background = 'transparent 0% 0% no-repeat padding-box';
            style1.color = '#ffffff';
            style1.opacity = 1;
            style1.marginRight = '40px';
            style1.marginBottom = '3px';
            style1.verticalAlign = 0;

            if (childColorDefs.length > 1) {
                let multiColorHex = '';
                for (var i=0; i < childColorDefs.length; i ++) {
                    multiColorHex = multiColorHex + " " + childColorDefs[i].hex + " " + (i * Math.round(100/childColorDefs.length)) + "%,"
                                    + " " + childColorDefs[i].hex + " " + ((i + 1) * Math.round(100/childColorDefs.length)) + "%,";
                }
                multiColorHex = multiColorHex.slice(0, -1);
                style.background = 'repeating-linear-gradient(315deg,' + multiColorHex + ')';
            } else {
                style.backgroundColor = colorHex;
            }

            if (selected) {
                style.borderRadius = '5px 5px 0px 0px';
                
            }
                
            else {
                style.borderRadius = '5px';
                style.border = '1px solid #D6D6D6';
            }
               
        }

        let className = `color-swatch-icon ${this.props.className}`;
        let className1 = this.props.className;

        if (colorHex && className1) {
            if (className1.includes('dropdown-toggle-color-swatch')) {
                return (
                    <div key={'idx-' + swatchIdx} className={className} style={style}>&nbsp;</div>
                );
            }
        } else {
            return (
                <div className={className}>
                    <div key={'idx-' + swatchIdx} className={className} style={style}>
                        { colorDef.isImportant && this.props.baseProduct.configurationType !== 'stock-dec' ?
                            <div><FontAwesomeIcon icon={faStar} style={style1} /></div> : null }
                    </div>
                </div>
            );
        }
    }

    renderColorNone() {
        return (
            <div className="color-none-icon">
                <SlashLeftSVG className="fillxy" fill={SLASH_COLOR} />
            </div>
        );
    }

    renderSelectedCheckmark() {
        return (
            <div className="selected-checkmark">
                <CheckmarkSVG
                    height="26"
                    width="20"
                    opacity="1"
                    stroke={"black"}
                    fill={"black"}
                    className={"checkmark-svg"}
                />
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        baseProduct: state.baseProduct,
        customConfig: state.customConfig,
        loadedDefs: state.loadedDefs,
        user: state.user,
        hover: state.hover,
        toolTips: state.toolTips,
        navigation: state.navigation,
    };
}

export default connect(mapStateToProps, { clearHover })(NewColorSwatch);
