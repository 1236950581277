import AuthService from './auth.service';
import LogoService from './logo.service';
import BaseProductsService from './base-products.service';
import CustomProductsService from './custom-products.service';
import CustomProductAssetsService from './custom-product-assets.service';
import DeocrationPricingService from './decoration-pricing.service';
import WebUserArtAssetsService from './web-user-art-assets.service';
import WebUserConfigsService from './web-user-configs.service';
import WebUserSavedConfigsService from './web-user-saved-configs.service';
import RosterUploaderService from './roster-uploader.service';
import SublimatedProductsService from './sublimated-products.service';
import TextDesignPatternService from './text-design-patterns.service';
import MockupService from './mockup.service';
import BaseProductPricingService from './base-product-pricing.service';

export default {
  AuthService,
  LogoService,
  BaseProductsService,
  CustomProductsService,
  CustomProductAssetsService,
  DeocrationPricingService,
  WebUserArtAssetsService,
  WebUserConfigsService,
  WebUserSavedConfigsService,
  RosterUploaderService,
  SublimatedProductsService,
  TextDesignPatternService,
  MockupService,
  BaseProductPricingService
};

