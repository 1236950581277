import React, { Component } from "react";
import { connect } from "react-redux";
import { setUserInitState } from "../../redux/reducers/user/userActions";
import { setBaseProduct } from "../../redux/reducers/baseProduct/baseProductActions";
import { mergeLoadedDefs } from "../../redux/reducers/loadedDefs/loadedDefsActions";
import {
  loadNewConfig,
  // setStyleNum,
  setConfigId,
  setWebRef,
} from "../../redux/reducers/customConfig/customConfigActions";
import { initLaunchContext } from "../../redux/reducers/launchContext/launchContextActions";
import {
  useLocalStorage,
  waitingForHost,
  infoModal
} from "../../redux/reducers/modal/modalActions";
import {
  setActiveTab,
  setInIframe,
  enableTabs
} from "../../redux/reducers/navigation/navigationActions";
import MyDesigns from "../../components/myDesigns/myDesigns";
import LoadingPage from "../statusPages/loadingPage/LoadingPage";
import UltimateModal from "../global/ultimateModal/ultimateModal";
import UltimateToast from "../global/ultimateToast/ultimateToast";
import Header from "../header/header";
import Footer from "../footer/footer";
import Sidebar from "../sidebar/sidebar";
import PreviewStage from "../previewStage/previewStage";
import ProductViews from "../productViews/productViews";
import NotFound404 from "../statusPages/notFound404/NotFound404";
import AppConfig from "../../config";
import appLoader from "../../appLoader/appLoader";
import _ from "lodash";
import queryString from "query-string";
import PreviewRenderGenerator from "../global/previewRenderGenerator/previewRenderGenerator";
import ClosePopup from "../global/closePopup/closePopup";
import { clearIframeMessages } from "../../redux/reducers/notifications/notificationsActions";
import TestProductsPage from "../testPages/testProductsPage";
import { sendSentryLaunchContextData } from "../../lib/analytics/sentry";
import SublimatedProductsPage from "../sublimatedProductsDesigns/sublimatedProductsPage";


// script imported via index.html script tag
const IframeMessengerSdk = window.IframeMessengerSdk;

class UltimateBuilder extends Component {
  constructor(props) {
    super(props);

    this.displayBuilder = this.displayBuilder.bind(this);
    this.display404 = this.display404.bind(this);
    this.designsMode = window.location.pathname === "/designs";
    this.testpageMode = window.location.pathname === "/test-products";
    this.sublimatedpageMode = window.location.pathname === "/sublimation-designs"
    this.rosterFlag = false;
    // NOTE: print out the app info data for testing.
    console.log(
      JSON.stringify({ ...AppConfig.appInfo, env: AppConfig.env }, null, 2)
    );
    this.state = {

      loading: true,
      showLoadView: false,
      blockActions: false,
      webUserId: null,
      configAccount: {},
      initialDesign: null,
      initialData: null,
      initialConfig: null,
      rosterChange: false,
    };

    // if the app has not finished loading after N seconds, then allow the loading page to show
    // this is to prevent a flicker during init. if the app loads fast enough, then we don't need to show the pre-loader
    setTimeout(() => {
      if (this.state.loading) {
        this.setState({ showLoadView: true });
      }
    }, 3000);
  }
  componentDidMount() {
    this.initIframeMessenger();  // loads data if in Iframe

    // this.props.setInIframe(this.isEmbeddedInIframe);

    if (!this.isEmbeddedInIframe || !this.messenger) {
      // NOTE: this data is formatted in the same style as initData from that the iframe integration SDK would expect.
      // let defaultInitData =  _.get(AppConfig, 'defaultInitData', {});
      let defaultInitData = {
        // custom sub init data
        webUserId: 60,
        accountId: 123456,
        // style: "412000",
        style: "AS1982",
        design: "JDS014",
        colors: [],
      };
      this.setState({ initialData: defaultInitData });
      let queryParams = this.tryParseQueryParams() || {};
      if (!_.isEmpty(queryParams)) {
        if (queryParams.initData) {
          defaultInitData = queryParams.initData;
        } else {
          defaultInitData.style = queryParams.SKU;
          defaultInitData.design = queryParams.designNum;
          defaultInitData = _.extend({ ...defaultInitData, design: queryParams.isStock ? null : defaultInitData.design }, queryParams);
        }
      }

      console.log("builder.initData: " + JSON.stringify(defaultInitData));
      this.setState({ webUserId: defaultInitData.webUserId });
      this.loadAppStartupData(defaultInitData); // loads data if not in Iframe
    }
  }

  initIframeMessenger() {
    this.isEmbeddedInIframe = !(window.location === window.parent.location);
    console.log(
      "iframe embed mode: " + (this.isEmbeddedInIframe ? "true" : "false")
    );
    if (this.isEmbeddedInIframe) {
      if (typeof IframeMessengerSdk !== "undefined") {
        var publisher = (window && window.parent) || window;
        var subscriber = window;
        if (publisher && subscriber) {
          this.messenger = new IframeMessengerSdk({ publisher, subscriber });
          this.messenger.on("initData", (initData) => {
            console.log(
              "if-messenger.on.initData: " + JSON.stringify(initData)
            );
            this.setState({ webUserId: initData.webUserId })
            this.loadAppStartupData(initData);
          });
        }
      } else {
        console.warn("iframe-messenger-sdk not loaded");
      }
    }
  }

  async loadAppStartupData(initData) {
    if (initData.configId) {
      this.setState({ initialConfig: initData.configId });
    }
    this.setState({ initialDesign: initData.design });
    let launchContext = await appLoader.prepareLaunchContextFromInitData(initData);
    // tell redux about the init state launch context
    this.props.initLaunchContext(launchContext);

    // tell sentry about the launch context directly so that it can add appropriate tags to all future sentry messages
    sendSentryLaunchContextData(launchContext);

    let showDesigns = !!(this.designsMode || this.testpageMode);
    return appLoader
      .loadStartupData(launchContext, showDesigns)
      .then((startupData) => {
        startupData = startupData || {};
        startupData = { ...startupData, customConfig: { ...startupData.customConfig } }
        this.setReduxStates(startupData);
        /*this.setState({ loading: false });*/
        this.setState({ loading: false, configAccount: startupData.customConfig });
        return true;
      })
      .catch((err) => {
        let errorMsg = _.toString(err) || "unable to load product";
        this.setState({ loading: false, error: errorMsg });
        return false;
      });

  }

  async setReduxStates(startupData) {
    if (!startupData) {
      return;
    }
    let {
      user,
      baseProduct,
      loadedDefs,
      customConfig,
      localCustomConfig,
      allowedTabs,
    } = startupData;
    this.props.setUserInitState(user);
    this.props.mergeLoadedDefs(loadedDefs);
    this.props.setBaseProduct(baseProduct);
    this.props.loadNewConfig(customConfig);
    this.props.enableTabs(this.props.navigation.options, allowedTabs);
    if (localCustomConfig) {
      this.props.useLocalStorage(localCustomConfig);

    }
    if (startupData.customConfig.configId) {
      if (startupData.auth.corpAcctId !== startupData.customConfig.corpAcctId && !startupData.customConfig.isMasterConfig) {
        this.props.infoModal();
      }
    }
    this.props.changeDesignColor.isDesignLoading = false;
  }

  tryParseQueryParams() {
    let queryParams = null;
    try {
      let queryStr =
        (window && window.location && window.location.search) || "";
      queryParams = queryString.parse(queryStr);
      if (queryParams.initData) {
        if (_.isString(queryParams.initData)) {
          try {
            queryParams.initData = JSON.parse(queryParams.initData);
          } catch (ex) { }
        }
      }
    } catch (ex) { }
    return queryParams;
  }

  componentDidUpdate(oldProps) {
    let { iframe } = this.props.reduxState.notifications;
    const { launchContext } = this.props.reduxState;
    if ((launchContext.isMasterConfig === true) || (launchContext.isMasterConfig === false)) {
      if ((this.state.initialConfig && (launchContext.configId !== this.state.initialConfig))) {
        this.loadAppStartupData(launchContext);
        // this.props.changeDesignColor.isDesignLoading = false;  
        this.setState({ initialConfig: launchContext.configId });
      } else if ((this.state.initialDesign && (launchContext.design !== this.state.initialDesign))) {
        this.loadAppStartupData(launchContext);

        this.setState({ initialDesign: launchContext.design });
      }
      // this.props.changeDesignColor.isDesignLoading = false;
    }

    if (iframe && oldProps.reduxState.notifications.iframe !== iframe) {
      let { action, data } = iframe;
      setTimeout(() => {
        this.props.clearIframeMessages();
      }, 0);
      if (this.messenger) {
        console.log("if-messenger.send.action: " + JSON.stringify({ action, data })
        );
        this.messenger.sendMessage("action", { action, data });
        if (action === "cancel") {
          this.headToCancel();
        }
      } else {
        console.log(
          "if-messenger.send.action (IGNORED): " +
          JSON.stringify({ action, data })
        );
      }
    }

    let oldScrim = _.get(oldProps, "reduxState.notifications.scrim", false);
    let scrim = _.get(this.props, "reduxState.notifications.scrim", false);
    if (scrim && scrim !== oldScrim && this.messenger) {
      this.showMyScrim();
    }
    // let locations = _.get(oldProps, "reduxState.customConfig.locations", []);
    //       if(locations.length > 0){
    //         locations.forEach(item => {
    //               if (item.entity.type === 'player-name' || item.entity.type === 'player-number') {
    //                 this.rosterFlag =true;
    //               } else {
    //                 this.rosterFlag = false;
    //               }
    //             });
    //     }
  }

  showMyScrim() {
    this.setState({ blockActions: true }, () =>
      setTimeout(this.props.waitingForHost, 5000)
    );
  }

  headToCancel(){
    //To Close Back to Products Cancel pop up
  }

  displayBuilder() {
    let locations = _.get(this.props.customConfig, "locations", []);
    if (locations.length > 0) {
      let rosterFound=false;
      locations.forEach(item => {
        if (item.entity.type === 'player-name' || item.entity.type === 'player-number') {
          rosterFound=true;
        } 
      });
      this.rosterFlag = rosterFound?true:false;
    } else {
      this.rosterFlag = false;
    }
    let showSize = (this.props.navigation.activeTab.tab === "names-and-numbers" && !this.rosterFlag) ? true : false;
    return (
      <div id="builder">
        {(this.props.customConfig.isLoading || this.props.changeDesignColor.isDesignLoading) &&
          <div>
            <div className='overlay-box'>
              <div className="loader"></div>
            </div>
          </div>
        }
        <ClosePopup />
        {this.state.blockActions ? <div className="block-actions"></div> : null}
        {true ? null : (
          <button onClick={() => console.log("props", this.props.reduxState)}>
            console log props
          </button>
        )}
        {true ? null : (
          <button
            onClick={() => console.log("navigation", this.props.navigation)}
          >
            console log navigation
          </button>
        )}
        <UltimateModal />
        <UltimateToast
          message="This is a test branch"
          toast={this.props.toast}
        />
        <Header></Header>
        {(this.props.navigation.activeTab.tab !== 'order-summary' && !showSize) ?
          <div className="grid-container">
            <div className="app-grid">
              <div className="row">
                <div className="col-2 preview-container pr-0">
                  <ProductViews nav={this.props.setActiveTab} />
                </div>
                <div className="col-5 pl-0 pr-3">
                  <PreviewStage issues={this.props.issues} />
                </div>
                <div className="col-5">
                  <div className="sidebar-container">
                    <Sidebar actionAccount={this.state.webUserId} configAccount={this.state.configAccount} />
                  </div>
                </div>
              </div>
            </div>
          </div> :
          <div className="grid-container">
            <div className="app-grid">
              <div className="col-12">
                <div className="sidebar-container">
                  <Sidebar actionAccount={this.state.webUserId} configAccount={this.state.configAccount} />
                </div>
              </div>
            </div>
          </div>}
        <Footer price={_.get(
          this,
          "props.customConfig.decorationPricing.subtotalCents",
          0
        )}
          isMSRP={_.get(
            this,
            "props.customConfig.decorationPricing.isMSRP",
            false
          )} nav={this.props.setActiveTab} actionAccount={this.state.webUserId} configAccount={this.state.configAccount}></Footer>
        <PreviewRenderGenerator renderHeight={600} />
      </div>
    );
  }

  displayMyDesigns() {
    return (
      <div>
        <UltimateModal />
        <MyDesigns />
      </div>
    );
  }

  display404() {
    console.error(this.state.error);
    return <NotFound404 />;
  }

  displayLoading() {
    return <LoadingPage />;
  }

  displayTestProductsPage() {
    return <TestProductsPage />;
  }
  displaySublimatedProductsPage() {
    return (
      <div>
        <UltimateModal />
        <SublimatedProductsPage data={this.state.initialData} />
      </div>
    );
  }

  render() {
    const hasError = !!this.state.error;
    if (hasError) {
      return this.display404();
    }
    if (this.state.loading) {
      return this.state.showLoadView ? this.displayLoading() : null;
    }
    if (this.designsMode) {
      return this.displayMyDesigns();
    }
    if (this.testpageMode) {
      return this.displayTestProductsPage();
    }
    if (this.sublimatedpageMode) {
      return this.displaySublimatedProductsPage();
    }
    return this.displayBuilder();
  }
}

function mapStateToProps(state) {
  return {
    issues: state.issues,
    toast: state.toast,
    navigation: state.navigation,
    reduxState: state,
    baseProduct: state.baseProduct,
    customConfig: state.customConfig,
    launchContext: state.launchContext,
    changeDesignColor: state.changeDesignColor
  };
}

const mapDispatchToProps = {
  initLaunchContext,
  setUserInitState,
  mergeLoadedDefs,
  setBaseProduct,
  loadNewConfig,
  // setStyleNum,
  setConfigId,
  setWebRef,
  waitingForHost,
  useLocalStorage,
  infoModal,
  clearIframeMessages,
  setActiveTab,
  setInIframe,
  enableTabs
};

export default connect(mapStateToProps, mapDispatchToProps)(UltimateBuilder);