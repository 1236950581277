import {UPDATE_HOVER} from '../../actions/actionTypes';
import helpers from '../../../helpers';

export function productColorHover(colorDef) {

  let hoverType = helpers.hover.HOVER_TYPES.productColor;
  let hoverItem = null;

  if (colorDef) {
    hoverItem = { hoverType, color: colorDef };
  }

  let newHoverState = helpers.hover.replaceHoverItem(hoverType, hoverItem);
  return { type: UPDATE_HOVER, hover: newHoverState };
}

export function stitchingColorHover(colorDef) {

  let hoverType = helpers.hover.HOVER_TYPES.stitchingColor;
  let hoverItem = null;
  if (colorDef) {
    hoverItem = { hoverType, color: colorDef };
  }

  let newHoverState = helpers.hover.replaceHoverItem(hoverType, hoverItem);
  return { type: UPDATE_HOVER, hover: newHoverState };
}

export function patternHover(pattern) {
  let hoverType = helpers.hover.HOVER_TYPES.pattern;
  let hoverItem = null;
  if (pattern) {
    hoverItem = {pattern}
  }

  let newHoverState = helpers.hover.replaceHoverItem(hoverType, hoverItem)
  return { type: UPDATE_HOVER, hover: newHoverState };
}

export function primaryColorsHover(colors) {
  let hoverType = helpers.hover.HOVER_TYPES.primaryColors;
  let hoverItem = null;

  if (colors) {
    hoverItem = { hoverType, primaryColors: colors };
  }

  let newHoverState = helpers.hover.replaceHoverItem(hoverType, hoverItem);
  return { type: UPDATE_HOVER, hover: newHoverState };
}

export function locationEditHover(locationKey){
  let hover = []; 
  if(locationKey){
    let hovered = {
      location: locationKey
    };
    hover = [hovered]; 
  }
  return {type: UPDATE_HOVER, hover}; 
}

export function textColorHover(color) {
  let hover = []
    if (color) {
      let hovered = {url: color}
      hover.push(hovered);
    }
    hover = color
    return {type: UPDATE_HOVER, hover};
}

export function clearHover() {
  let hover = [];
  return {type: UPDATE_HOVER, hover};
}

export function addHoverObject( hoverObject ) {
  let hover = []
    if ( hoverObject ) {
      hover.push( hoverObject );
    }
  return {type: UPDATE_HOVER, hover};
}
