import _ from 'lodash';
import { fabric } from 'fabric';

export default function objectScaling(event, canvas) {
  //   // if it gets scaled outside obj
  let id = event.target.id.split("-");
  id = id[1];
  let locationDetails = _.find(canvas.getObjects(), {id:`location-${id}`});
  if(locationDetails){
    let locationDetailsBoundingRect = locationDetails.getBoundingRect();
    let target = event.target;
    let objBoundingRect = target.getBoundingRect();
    target.setCoords();
    let zoom = canvas.getZoom();
    resize(target, objBoundingRect, locationDetailsBoundingRect , zoom);
  }
}

function resize (target, objBoundingRect, locationDetailsBoundingRect, zoom){
  if( objBoundingRect.width > locationDetailsBoundingRect.width ||  objBoundingRect.height > locationDetailsBoundingRect.height){

    let cW = target.width;
    let cH = target.height;
    let angle = target.angle;
    let rad = fabric.util.degreesToRadians(angle);
    let cBW = Math.abs(cH * Math.sin(rad)) + Math.abs(cW * Math.cos(rad));
    let cBH = Math.abs(cW * Math.sin(rad)) + Math.abs(cH * Math.cos(rad));
    let nBW = locationDetailsBoundingRect.width / zoom;
    let nBH = locationDetailsBoundingRect.height / zoom;
    let CoeffH = nBW / cBW;
    let CoeffV = nBH / cBH;
    let Coeff = Math.min(CoeffH, CoeffV);
    // scaleOffset is for when it gets resized to the exact value it will pop every time object is moved
    let scaleOffset = .98;
    target.scaleX = Coeff * scaleOffset;
    target.scaleY = Coeff * scaleOffset;
  }
}