import React, {Component} from "react";
import {connect} from "react-redux";

class HelpBubble extends Component {
  render() {
    return (
      <div>
        {this.props.toggle ?
              <div className="help-bubble-container">
            <div className="help-bubble">
              {this.props.bubbleMessage}
            </div>
            <div className="arrow-left"/>
            </div>
      :
      <div/>}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    toolTips: state.toolTips
  }
}

export default connect(mapStateToProps)(HelpBubble)
