import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import App from './App';
import helpers from './helpers'
import store from './redux/configureStore';
import AppConfig from './config';
import loadGoogleAnalytics from '../src/lib/analytics/googleAnalytics';
import loadFullStory from '../src/lib/analytics/fullstory';
import ErrorBoundary from './lib/actionTracker/errorHandler';
import {testEsFeatures} from './tests/polyfillTests';
import { loadSentry } from './lib/analytics/sentry';
import 'bootstrap/dist/css/bootstrap.min.css';

// include all css styles needed by the application here
// NOTE: make sure to include third party css libs before the main app css.
// only include main css files once (otherwise we can get out of order results)
import './styles/main.scss';


helpers.state.initReduxStore(store);

function initAppTrackers() {

  if (AppConfig.googleAnalyticsKey) {
    loadGoogleAnalytics(AppConfig.googleAnalyticsKey);
  }

  if (AppConfig.sentryDsn){
    loadSentry();
  }

  if (AppConfig.fullstoryKey) {
    loadFullStory(AppConfig.fullstoryKey);
  }
}

function renderMainApp() {

  if (AppConfig.showFriendlyErrorPage || AppConfig.logReactErrors) {
    ReactDOM.render(
      <Provider store={store}>
        <ErrorBoundary>
          <App/>
        </ErrorBoundary>
      </Provider>,
      document.getElementById('root')
    );
  } else {
    ReactDOM.render(
      <Provider store={store}>
        <App/>
      </Provider>,
      document.getElementById('root')
    );
  }
}


if (AppConfig.testEsFeatures) {
  testEsFeatures();
}

initAppTrackers();
renderMainApp();
