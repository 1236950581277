import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment';
import _ from 'lodash'
import ArtGalleryCard from '../../uploadArt/artGallery/artGalleryCard/ArtGalleryCard';

const EditArtFlip = (props) => {


  const calculateColors = (total) => {
    let noun
    if (total > 1) {
      noun = "Colors"
    } else {
      noun = "Color"
    }
    return (
      <div>
        {total + " " + noun}
      </div>
    )
  }

  // let artAsset = {numDecorationColors: 3, name: "test", createdAt: "2019-05-09T02:43:02.666Z"}
  let locationContent = _.find(props.customConfigLocations, { code: props.activeLocation });
  let artAsset = _.find(props.userArtAssets, { id: locationContent.entity.userArtId });
  
  let numberColors = _.get(artAsset, "numDecorationColors", 1);
  let updatedAt = _.get(artAsset, "updatedAt", 0);
  let name = _.get(artAsset, "name", "badName");

  return (
    <div className="art-row">
      <ArtGalleryCard item={artAsset} page="edit" 
      flipVal={locationContent.entity.flipArt} rotateVal={locationContent.entity.rotateArt}></ArtGalleryCard>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    userArtAssets: state.user.userArtAssets,
    activeLocation: state.navigation.activeLocation,
    customConfigLocations: state.customConfig.locations,
  }
}

export default connect(mapStateToProps)(EditArtFlip)
