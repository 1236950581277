import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux"
import helpers from "../../../../helpers"
import AddArtDrawer from "./addArtDrawer/AddArtDrawer";
import { clearNotification } from "../../../../redux/reducers/notifications/notificationsActions";
import LocationSummary from "../addText/locationSummary/locationSummary";
import OptionHeading from "../../../global/optionHeading/OptionHeading"
import EditDrawer from "../editDrawer/EditDrawer";

class AddArt extends Component {

  constructor(props) {
    super(props);
    this.showDisplay = this.showDisplay.bind(this);
  }
  
  showDisplay(){

    let display;
    let containsEntities = _.filter(this.props.customConfig.locations, x => (x.entity.type === "custom-art" && x.entity.value === 'system-art'));
       
    let addArtDrawer = (<div id="drawer">
        <AddArtDrawer used={containsEntities} />
      </div>);
    
    switch (this.props.navigation.activeTab.page) {
      case "main":
        let toolTip = _.find(this.props.toolTips, {key: "add-art-back-button"}).message;
        if (containsEntities.length > 0){
          display = (<div id="drawer">
            <OptionHeading 
              toolTip={toolTip}
              backFunction = {false} 
              title={"Add or edit Art"} 
              description={"Upload custom artwork to use in your design."} />
            <div className="option-body">
              <LocationSummary 
                value={'system-art'} 
                title={"Art"} 
                availableLocations={helpers.product.getOpenLocations(this.props.baseProduct.locations, this.props.customConfig.locations)} 
                filters={['custom-art']} />
            </div>
          </div> );
        } else {
          // if no art entities display AddArtDrawer
          display = addArtDrawer
        }
        break;
      case "add":
        display = addArtDrawer
        break;
      case "edit":
        display = (<div id="drawer" className={` fade-in`}>
           <EditDrawer />
        </div>);
        break;
      default:
        display = (
          <div> display defaulted</div>
        )
        break;
    }
    return display;
  }


  render() {
    return (
      <div className="fade-in">
        {this.showDisplay()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    hover: state.hover,
    notifications: state.notifications,
    reduxState: state,
    customConfig: state.customConfig,
    navigation: state.navigation,
    colorOptions: state.colorOptions,
    baseProduct: state.baseProduct,
    toolTips: state.toolTips
  }
}

export default connect(mapStateToProps, { clearNotification })(AddArt)
