import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import MultiDropdown from '../../../../global/multiDropdown/multiDropdown';
import { addHoverObject, clearHover } from "../../../../../redux/reducers/hover/hoverActions";
import { changeSetting } from "../../../../../redux/reducers/customConfig/customConfigActions";
import { FaLock } from "react-icons/fa";

function EditTextSize(props) {

  let locationContent = _.find(props.customConfig.locations, { code: props.navigation.activeLocation });
  let bpLocation = _.find(props.baseProduct.locations, { code: locationContent.code });
  let bpAllowedEntity = _.find(bpLocation.allowedEntities, { key: locationContent.entity.type });
  // bpDecorationMethod has all allowed sizes for this location and entity type.
  let bpDecorationMethod = _.find(bpAllowedEntity.allowedDecorationMethods, { key: locationContent.entity.decorationMethod });
  let masterImageDpi = _.find(props.baseProduct.views, { key: locationContent.view }).masterImageDpi;
  let configurationType = props.baseProduct.configurationType

  const handleOnChange = (e) => {
    let size = e.key // update redux location entity size
    props.changeSetting(props.customConfig.locations, props.navigation.activeLocation, { setting: "size", value: size });

    let newBounds = _.cloneDeep(locationContent.entity.bounds);
    //newBounds.h = e.w * masterImageDpi;
    // BUILD- 357
    let w = e.w;
    let h = e.h;
    let newBoundsCalValue = "";
    if (w == null) {
      newBoundsCalValue = h;
    }
    else if (h == null) {
      newBoundsCalValue = w;
    }
    else if (w !== null && h !== null) {
      if (w == h) {
        newBoundsCalValue = h;
      }
      else if (w < h) {
        newBoundsCalValue = w;
      }
      else if (w > h) {
        newBoundsCalValue = h;
      }
    }
    newBounds.h = newBoundsCalValue * masterImageDpi;


    props.changeSetting(props.customConfig.locations, props.navigation.activeLocation, { setting: "bounds", value: newBounds });
    props.changeSetting(props.customConfig.locations, props.navigation.activeLocation, { setting: "_heightUpdated", value: true });

    // if(props.navigation.activeLocation =='LC' && props.customConfig.locations.find(v=>v.code=='CF')){
    //  let boundsValue = props.customConfig.locations.find(v=>v.code=='CF');

    //  let newBounds2 = _.cloneDeep( boundsValue.entity.bounds );
    //  newBounds2.y=newBounds.y + newBounds.h + 5;
    //  console.log('test the data ', newBounds, newBounds2.y);
    //  props.changeSetting(props.customConfig.locations, 'CF', { setting: "bounds", value: newBounds2 });
    // // props.changeSetting(props.customConfig.locations, 'LWFT', { setting: "_heightUpdated", value: true });

    // }

    props.clearHover();
  }
  const handleOnHover = (e) => {
    let key = "height";
    // let value = e.w * masterImageDpi ; 

    // BUILD- 357

    let w = e.w;
    let h = e.h;
    let newBoundsCalValue = "";

    if (w == null) {
      newBoundsCalValue = h;
    }
    else if (h == null) {
      newBoundsCalValue = w;
    }
    else if (w !== null && h !== null) {
      if (w == h) {
        newBoundsCalValue = h;
      }
      else if (w < h) {
        newBoundsCalValue = w;
      }
      else if (w > h) {
        newBoundsCalValue = h;
      }
    }
    let value = locationContent.entity.textEffect === 'text-and-tail-straight' || locationContent.entity.textEffect === "text-and-tail" ? newBoundsCalValue * masterImageDpi + 7.7 : newBoundsCalValue * masterImageDpi;
    //let value = newBoundsCalValue * masterImageDpi;
    props.addHoverObject({ [key]: value });

  }
  const handleOnHoverOff = () => {
    props.clearHover();
  }

  let label = "Text Size";
  let value = "";
  let size = _.find(bpDecorationMethod.allowedSizes, { key: locationContent.entity.size });
  if (size) {
    value = size.name;
  }

  let hasWidths = _.some(bpDecorationMethod.allowedSizes, v => (v.w > 0));
  let hasHeights = _.some(bpDecorationMethod.allowedSizes, v => (v.h > 0));
  if (hasWidths && !hasHeights) {
    if (configurationType === 'custom-sub') {
      label = "Text Width";
    }
    else {
      label = "Width";
    }
  } else if (hasHeights && !hasWidths) {
    if (configurationType === 'custom-sub') {
      label = "Text Height";
    }
    else {
      label = "Height";
    }
  }

  let options = _.map(bpDecorationMethod.allowedSizes, (x) => {
    return x;
  });
  let rightLabel = null;
  let menuHeader = null;

  if (options.length > 1) {
    return (
      <div className="EditTextSize" style={{ marginTop: "15px", position: "relative" }}>
        <div className="row pl-4">
          <div className="col-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 label-padding-top">
            <label className="decoration-label">{label.toUpperCase()}</label>
          </div>
          <div className="col-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12">
            <MultiDropdown
            customClass = "multidropdown-menu"
              // label={ label }
              rightLabel={rightLabel}
              menuHeader={menuHeader}
              value={value}
              options={options}
              onChange={handleOnChange}
              onHover={handleOnHover}
              onHoverOff={handleOnHoverOff}
            />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="EditTextSize" style={{ marginTop: "15px", position: "relative" }}>
        <div className="row pl-4">
          <div className="col-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 label-padding-top">
            <label className="decoration-label">{label.toUpperCase()}</label>
          </div>
          <div className="col-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12">
            <MultiDropdown
              customClass="multidropdown-menu"
              // label={ label }
              value={value} />
            <div className="input-group">
              <input value={value} className="text-height-number-input form-control border-right-0" disabled />
              <div className="input-group-append ">
                <span className="input-group-text border-left-0 append-border"><FaLock /></span>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

const reduxActions = {
  changeSetting,
  addHoverObject,
  clearHover
}

function mapStateToProps(state) {
  return {
    navigation: state.navigation,
    baseProduct: state.baseProduct,
    customConfig: state.customConfig
  }
}

export default connect(mapStateToProps, reduxActions)(EditTextSize);
