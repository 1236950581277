import React, { Component } from "react";

export default class ATCPreviewImage extends Component {

  renderImage(){
    if (this.props.imageData){
      return (<img className="preview-image" src={this.props.imageData} alt="product preview"/>); 
    }
    return null; 
  }

  render() {
    return (
      <div className="preview-image">
        {this.renderImage()}
      </div>
    )
  }
}
