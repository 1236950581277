import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setActiveTab } from '../../../../../redux/reducers/navigation/navigationActions';
import OptionHeading from '../../../../global/optionHeading/OptionHeading';

import AssetLibrary from "../AssetLibrary/assetLibrary";
// import helpBubble from '../../../../global/helpBubble/helpBubble';

class AddArtDrawer extends Component {

  renderLibrary = () =>{
    let hasFilledLocations = (this.props.used.length > 0); 
    return(
      <div className="AddArtDrawer">
        <OptionHeading
          backFunction={hasFilledLocations ? () => this.props.setActiveTab({ tab: 'system-art', page: 'main' }) : false}
          title="Add Art"
          description="Upload custom art work to use in your design." 
        />
        <div className="option-body"> 
          <AssetLibrary />
        </div>
      </div>
    )
  }


  render(){
    return(
      <div >
      { this.renderLibrary()}
      </div>
    )
  }
}


  



const reduxActions = {
  setActiveTab
}

function mapStateToProps ( state ) {
  return {
    navigation: state.navigation,
    customConfig: state.customConfig,
    baseProduct: state.baseProduct
  }
}

export default connect(mapStateToProps, reduxActions )(AddArtDrawer);


