import AppConfig from '../config';
import moment from 'moment';
import _ from 'lodash';


class DesignManager {

    constructor() {
        this.styleNum = false;
        let expired = moment().subtract(28, 'days');
        try {
            let validated = {};
            let temp = JSON.parse(localStorage.getItem("past-designs")) || {};
            _.each(temp, (value, key) => {
                _.each(value, (v, k) => {
                    let timestamp = moment(v.autoSavedAt);
                    if (expired.isBefore(timestamp)) { _.set(validated, `[${key}][${k}]`, v); }
                });
            });
            this.styleInfo = validated;
        } catch (error) {
            this.styleInfo = {};
        }
    }

    get isAutoSaveEnabled() {
        return !!AppConfig.localStorageAutoSaveEnabled;
    }

    saveDesign(data) {

        if (!this.isAutoSaveEnabled) { return false; }

        if (data.styleNum !== this.styleNum) { this.styleNum = data.styleNum; }
        if (!this.styleNum) return false;
        if (!this.styleInfo[this.styleNum]) { this.styleInfo[this.styleNum] = {}; }
        data = _.cloneDeep(data);
        if (this.isConfigDuplicate(data, this.data)) { return false; }
        data['autoSavedAt'] = moment().toISOString();
        this.data = data;
        this.id = data.configId || 'new'; // using 'new' when we don't have a valid id yet.  this id should not be assigned to the config
        if (this.id !== 'new') { delete this.styleInfo[this.styleNum].new; }
        _.set(this.styleInfo, `[${this.styleNum}][${this.id}]`, data);
        let str = JSON.stringify(this.styleInfo);
        localStorage.setItem("past-designs", str);
    }

    loadLocalConfig(loadedCopy, loadedAt) {
        if (!this.isAutoSaveEnabled) { return null; }

        let configId = _.get(loadedCopy, "configId", "new") || "new";
        let styleNum = _.get(loadedCopy, "styleNum");
        if (styleNum && this.styleInfo[styleNum] && this.styleInfo[styleNum][configId]) {
            let localCopy = _.cloneDeep(this.styleInfo[styleNum][configId]);
            localCopy.rosters[0] = loadedCopy.rosters[0];
            if (this.isConfigDuplicate(localCopy, loadedCopy)) { return null; } //if they are the same don't load saved copy

            if (loadedAt) { // if the api data is newer then the local data don't load the saved copy
                let localTimestamp = moment(localCopy.autoSavedAt);
                if (localTimestamp.isBefore(moment(loadedAt))) {
                    return null;
                }
            }
            return localCopy;
        }
        return null;

    }

    isConfigDuplicate(a, b) {
        if (a && b) {
            let sameLocations = _.isEqual(a.locations, b.locations);
            let sameLayers = _.isEqual(a.customLayers, b.customLayers);
            let sameColor = _.isEqual(a.color, b.color);
            let sameUpgrades = _.isEqual(a.upgrades, b.upgrades);
            let sameName = _.isEqual(a.name, b.name);
            let sameRoster = _.isEqual(a.rosters, b.rosters);
            let sameNotes = _.isEqual(a.summaryNotes, b.summaryNotes);
            return (sameLocations && sameColor && sameUpgrades && sameName && sameRoster && sameNotes && sameLayers);
        }
        return false;
    }

}

let manager = new DesignManager();
export default manager;