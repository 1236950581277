import React, {Component} from "react";
import ViewSelector from "./viewSelector/ViewSelector";

class ProductViews extends Component {
  render() {
    return (
      <div className="views order-1 order-lg-2">
        <div className="views-content">
          <div className="sel">
            <ViewSelector/>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductViews;