import { RestService } from '../lib';
import helpers from '../../helpers';
import _ from 'lodash';


export default class DecorationPricingService extends RestService {

  constructor(opts = null) {
    super(opts);
    this.serviceName = 'decorationPricing';
    this.routePath = 'builder/decoration-pricing';

    // keep track of recent requests and avoid calling the API multiple times for the same request
    this.maxNumRecentRequests = 20;
    this.recentRequests = [];
  }

  async calcPrices(decorationsData, webUserId = null) {
    if (!decorationsData || _.isEmpty(decorationsData.entities)) {
      // if we have an empty request, then return now
      return null;
    }

    if (webUserId === null) {
      webUserId = helpers.user.getWebUserId();
    }

    // do a simple check to prevent calling the API twice in a row with the same request data.
    let oldRequestInfo = this._findRecentMatchingRequest(decorationsData, webUserId);
    if (oldRequestInfo) {
      return oldRequestInfo.response;
    }

    let response = this._calcPricesFromApi(decorationsData, webUserId);

    this.recentRequests.push({ request: decorationsData, webUserId, response });

    if (_.size(this.recentRequests) > this.maxNumRecentRequests) {
      // if we have too many saved requests, remove the oldest one
      this.recentRequests.unshift();
    }

    return response;
  }

  _findRecentMatchingRequest(requestData, webUserId) {
    return _.find(this.recentRequests, v => {
      return (v.webUserId === webUserId && _.isEqual(v.request, requestData));
    });
  }

  async _calcPricesFromApi(decorationsData, webUserId) {
    let queryParams = null;
    let opts = this._getDefaultRequestOpts();
    let errOpts = { sourceKey: 'calc_prices' };

    decorationsData = _.cloneDeep(decorationsData);
    decorationsData.userId = webUserId;
    decorationsData.webUserId = webUserId;
    return this.request.post(this.routePath, decorationsData, queryParams, opts, errOpts);
  }

}

