import React, { Component } from "react";
import { connect } from "react-redux";
import { FaSave } from "react-icons/fa";
import SaveAndShareSVG from '../../../assets/icons/SaveAndShareSVG';
import { saveModal } from "../../../redux/reducers/modal/modalActions";
import helpers from "../../../helpers";
import api from "../../../api";
import { setConfigId } from "../../../redux/reducers/customConfig/customConfigActions";
import { triggerRenderPreviewImagesAction } from "../../../redux/reducers/appActions/appActionsActions";
import _ from "lodash";

class SaveButton extends Component {
  constructor(props) {
    super(props);

    this.saveToMyDesignsClicked = this.saveToMyDesignsClicked.bind(this);
  }
  async capturePreviewImages() {
    let timeoutMS = 20000;
    return new Promise((resolve, reject) => {
      let timeoutRef = null;
      let handleDone = _.once((err, res) => {
        if (timeoutRef) { clearTimeout(timeoutRef); timeoutRef = null; }
        if (err) { return reject(err); }
        resolve(res);
      });
      if (timeoutMS > 0) {
        timeoutRef = setTimeout(() => {
          console.warning('cpi timed out');
          timeoutRef = null;
          handleDone(new Error('capturePreviewImages timed out'), null);
        }, timeoutMS);
      }
      this.props.triggerRenderPreviewImagesAction(res => {
        handleDone(null, res);
      });
    })
  }

  saveToMyDesignsClicked = async () => {
    // Do not send the message to the iframe, yet, 
    // let the saveModal dialog do that after the user clicks save
    this.props.setConfigId({ isLoading: true });
    let isMaster = this.props.launchContext.isMasterDesign
    await this.createOrUpdateConfig();
    this.props.saveModal(isMaster);
  }

  async createOrUpdateConfig() {
    let overwrite;
    let baseProduct = this.props.baseProduct;
    let customConfig = this.props.customConfig;
    let launchContext = this.props.launchContext;
    let isSavedDesign = false;
    let isOrderedDesign = false;
    let isMasterConfig = false;
    /*
    if(this.props.configAcc && this.props.accountType.toString() === this.props.configAcc.decorationPricing.webUserId.toString()){
    overwrite = true
    }else{
    const actionPayload={configId:'', isSavedDesign: true}
    this.props.setConfigId(actionPayload); 
    overwrite = false}
    */
    let previews = await this.capturePreviewImages();
    // if(this.props.launchContext.isMasterDesign && this.props.launchContext.isMasterDesign === true) {
    //   const actionPayload={configId:'', isSavedDesign: true, isOrderedDesign: false, corpAcctId: this.props.user.corpAccount.id}
    //       this.props.setConfigId(actionPayload);
    //       overwrite = false
    // }
    if (this.props.launchContext.isMasterDesign === undefined || this.props.launchContext.isMasterDesign === false) {
      if (customConfig.isMasterConfig === true) {
        const actionPayload = { configId: '', isSavedDesign: true, isOrderedDesign: false, corpAcctId: this.props.user.corpAccount.id, isMasterConfig: false }
        this.props.setConfigId(actionPayload);
        overwrite = false
      } else if (customConfig.isMasterConfig === false || customConfig.isMasterConfig === undefined) {
        if (customConfig.configId && customConfig.isSavedDesign) {
          if ((customConfig.isOrderedDesign === true || (customConfig.corpAcctId !== undefined && this.props.user.corpAccount.id !== customConfig.corpAcctId))) {
            const actionPayload = { configId: '', isSavedDesign: true, isOrderedDesign: false, corpAcctId: this.props.user.corpAccount.id, isMasterConfig: false }
            this.props.setConfigId(actionPayload);
            overwrite = false
          } else {
            overwrite = true;
            const actionPayload = { configId: customConfig.configId, isSavedDesign: true, isOrderedDesign: false, corpAcctId: this.props.user.corpAccount.id, name: customConfig.name, isMasterConfig: false }
            this.props.setConfigId(actionPayload);
          }
        }
      } else {
        const actionPayload = { configId: '', isSavedDesign: true, isOrderedDesign: false, corpAcctId: this.props.user.corpAccount.id }
        this.props.setConfigId(actionPayload);
        overwrite = false
      }
    }
    else {
      const actionPayload = { configId: '', isSavedDesign: true, isOrderedDesign: false, corpAcctId: this.props.user.corpAccount.id, isMasterConfig: true }
      this.props.setConfigId(actionPayload);
      overwrite = false
    }
    // if (customConfig.configId && customConfig.isSavedDesign) {
    //   if((this.props.customConfig.isOrderedDesign === true || (this.props.customConfig.corpAcctId !== undefined && this.props.user.corpAccount.id !== this.props.customConfig.corpAcctId)) ){
    //       const actionPayload={configId:'', isSavedDesign: true, isOrderedDesign: false, corpAcctId: this.props.user.corpAccount.id}
    //       this.props.setConfigId(actionPayload);
    //       overwrite = false
    //     }else{  
    //    overwrite = true;    
    //    const actionPayload={configId:customConfig.configId, isSavedDesign: true, isOrderedDesign: false, corpAcctId: this.props.user.corpAccount.id, name:customConfig.name}
    //    this.props.setConfigId(actionPayload);
    //   }  
    // }
    if (this.props.launchContext.isMasterDesign) {
      isMasterConfig = true;
    } else {
      isMasterConfig = false;
    }
    let params = await helpers.serialization.prepareApiSaveData(baseProduct, customConfig, previews, isSavedDesign, overwrite, isOrderedDesign, isMasterConfig);
    return api.webUserSavedConfigs.save(params.configId, params).then(res => {
      let configId = (res && res.configId) || null;
      if (overwrite === true && overwrite !== undefined) {
        const actionPayload = { configId: configId, isSavedDesign: true, name: customConfig.name, isLoading: false }
        this.props.setConfigId(actionPayload);
      } else {
        const actionPayload = { configId: configId, isSavedDesign: false, isLoading: false }
        this.props.setConfigId(actionPayload);
      }
      return res.configId;
    }).catch((err) => {
      console.error("error on creating config", err);
      return false;
    });

  };

  saveAndShare() {
    return (
      <div className="saveandshare">
        SAVE & SHARE
      </div>
    );
  }
  notSaveAndShare() {
    return (
      <div>
        <b>SAVE</b>
        <br /><b id="design">YOUR DESIGN</b>
      </div>
    );
  }

  renderDesktop() {
    return (
      <div className="row" onClick={this.saveToMyDesignsClicked} style={{ cursor: "pointer" }}>

        <div>
          <button
            type="button"
            aria-label={this.props.toolTip}
            data-balloon-pos="up-left"
            className="btn saveBtn btn-lg "
          >
            <SaveAndShareSVG className="savebutton" size={30} />
          </button>
        </div>
        <div>
          <div className="savetext">
            {this.props.launchContext.isMasterDesign ? this.notSaveAndShare() : this.saveAndShare()}
          </div>
        </div>

      </div>
    );
  }

  renderMobile() {
    return (
      <div
        className=".mobile__area moblie-save"
        onClick={this.saveToMyDesignsClicked}>
        <SaveAndShareSVG />
        <div className="service__name">
          {this.props.accountType === "0" ? 'Share' : 'Save & Share'}
        </div>
      </div>
    );
  }
  render() {
    if (this.props.mobile) {
      return this.renderMobile();
    }
    return this.renderDesktop();
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    customConfig: state.customConfig,
    baseProduct: state.baseProduct,
    launchContext: state.launchContext,
  }
}

let reduxActions = {
  saveModal,
  setConfigId,
  triggerRenderPreviewImagesAction
}

export default connect(mapStateToProps, reduxActions)(SaveButton); 