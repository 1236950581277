import React, { Component } from 'react';
import ColorSwatchStitchGroup from '../../../../global/colorSwatch/colorSwatchStitchGroup';
import helpers from '../../../../../helpers';
import _ from 'lodash';
import { connect } from 'react-redux';
import { updateAddStitchingUpgrade, updateStitching, removeUpgrade } from '../../../../../redux/reducers/customConfig/customConfigActions';
import { stitchingColorHover } from '../../../../../redux/reducers/hover/hoverActions';
import CheckBoxStitch from "../../../../global/checkbox/checkboxStitching";
import { translatePrice } from "../../../../../helpers/translation";

class StitchingColor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stitchingHoverColor: "",
    }
    this.onStitchingColorChanged = this.onStitchingColorChanged.bind(this);
    this.onStitchingColorHovered = this.onStitchingColorHovered.bind(this);
    this.onAddStitchingUpgradeChange = this.onAddStitchingUpgradeChange.bind(this);
  }

  getStitchingOptions() {
    let retval = null;
    let options = helpers.product.getUpgrade('stitching');
    let allowed = !!(options && _.get(options, 'enabled', true));
    if (allowed) {
      let customOptions = helpers.locations.getUpgradeOptions('stitching');
      let defaultColorCode = _.get(options, 'defaultColor', null);
      let defaultColor = helpers.colors.getColorFromColorCode(defaultColorCode);

      let selectedColorCode = _.get(customOptions, 'color', null);
      let selectedColor = helpers.colors.getColorFromColorCode(selectedColorCode) || defaultColor;

      let allowedColors = helpers.colors.getColorsFromColorCodes(options.allowedColors);
      retval = { selectedColor, defaultColor, allowedColors, options };
    }

    return retval;
  }

  getStitchingHoverColorName() {
    return _.get(this.state.stitchingHoverColor, 'name', null);
  }

  onAddStitchingUpgradeChange(checkboxValue) {
    this.props.updateAddStitchingUpgrade(this.props.customConfig, checkboxValue);

    if (!checkboxValue) {
      this.props.removeUpgrade(this.props.customConfig, "stitching")
      this.clearStitchingColorHovered(null);
    }
  }

  allowStitching() {
    let upgradeKey = "stitching";
    let baseUpgradeItem = _.find(this.props.baseProduct.allowedUpgrades, { key: upgradeKey });
    let configUpgradeItem = _.find(this.props.customConfig.upgrades, { key: upgradeKey });

    let isAllowed = _.get(configUpgradeItem, "value") || _.get(baseUpgradeItem, "defaultValue") || "";
    return isAllowed;
  }

  onStitchingColorChanged(selectedStitchingColor) {
    // set and move to redux
    this.props.updateStitching(this.props.customConfig, selectedStitchingColor);
  }

  onStitchingColorHovered(hoverColorDef) {
    if (this.state.stitchingHoverColor !== hoverColorDef) {
      this.setState({ stitchingHoverColor: hoverColorDef });
      // set and move to redux
      this.props.stitchingColorHover(hoverColorDef);
    }
  }

  clearStitchingColorHovered() {
    this._stitchingHoverColorDef = null;
    if (this.onStitchingColorHovered.cancel) {
      this.onStitchingColorHovered.cancel();
    }

    this.setState({ stitchingHoverColor: null });
    this.props.stitchingColorHover(null);
  }

  getSelectedStitchingColor() {
    let upgradeKey = "stitching";
    let baseUpgradeItem = _.find(this.props.baseProduct.allowedUpgrades, { key: upgradeKey });
    let configUpgradeItem = _.find(this.props.customConfig.upgrades, { key: upgradeKey });
    let code = _.get(configUpgradeItem, "color") || _.get(baseUpgradeItem, "defaultColor") || "";
    let selectedStitchingColor = _.find(this.props.colorDefs, { code });
    let selectedStitchingColorName = selectedStitchingColor ? selectedStitchingColor.name : "";
    return { selectedStitchingColor, selectedStitchingColorName };
  }

  render() {
    let allowStitching = this.allowStitching();
    let stitchingOptions = this.getStitchingOptions();
    let stitchingColors = _.get(stitchingOptions, 'allowedColors', null);
    let selectedStitchingColorDef = _.get(stitchingOptions, 'selectedColor', null);
    let selectedStitchingColorName = _.get(stitchingOptions, 'selectedColor.name', null);
    let stitchingHoverColorName = this.getStitchingHoverColorName();

    let price = helpers.loadedDefs.getDefaultPrice("stitching", "stitching");
    let displayPrice = translatePrice(price);

    // checkbox needs a truthy value or it breaks
    let value = !!_.find(this.props.customConfig.upgrades, { key: "stitching" });
    let chromagearStyle = null;
    if (this.props.chromagear) {
      chromagearStyle = {
        width: "33px",
        height: "33px",
      }
    }
    return (
      <div className="StitchingColor">
          <CheckBoxStitch
            value={value}
            onChange={this.onAddStitchingUpgradeChange}
            label="ADD CUSTOM STITCHING"
            rightLabel={"+$" + displayPrice}
            description={""}
            watchProps={true}
          />    
        {allowStitching ? <div className="row">
          <div className="col-12">
            <ColorSwatchStitchGroup
              value={selectedStitchingColorDef}
              isDisabled={!allowStitching}
              colorOptions={stitchingColors}
              label=""
              rightLabel=""
              selectedColor=  {(allowStitching) ? "SELECTED : " + selectedStitchingColorName : 'None'}
              hint={stitchingHoverColorName}
              displayHint={false}
              dropdownName=""
              onChange={this.onStitchingColorChanged}
              // onHover={this.onStitchingColorHovered}
              watchProps={true}
            />
          </div>
        </div> : null}
      </div>
    );
  }
}

const reduxActions = {
  updateAddStitchingUpgrade,
  stitchingColorHover,
  updateStitching,
  removeUpgrade
}

function mapStateToProps(state) {
  return {
    colorDefs: state.loadedDefs.colors,
    customConfig: state.customConfig,
    baseProduct: state.baseProduct,
  }
}

export default connect(mapStateToProps, reduxActions)(StitchingColor);
