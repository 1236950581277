import stateProvider from './stateProvider';
import _ from 'lodash';

class RosterHelper {

    // state helpers

    getStateVal(key, defaultValue = null) {
        return stateProvider.get(key, defaultValue);
    }

    // tests

    unitTests() {
        return {
            getPrimaryRoster: this.getPrimaryRoster(),
            hasValidRosterData: this.hasValidRosterData(),
            hasValidQuantityData: this.hasValidQuantityData(),
        };
    }


    // location helpers

    getPrimaryRoster() {
        return this.getStateVal('customConfig.rosters.0');
    }

    createEmptyRoster() {
        return {
            name: '',
            items: []
        };
    }

    getSizeQuantitiesData() {
        let rosterData = this.getPrimaryRoster();
        return this.convertToSizeQuantitesFromRosterData(rosterData);
    }

    getSizeQuantitiesData1(rosterData, baseProd) {
        return this.convertToSizeQuantitesFromRosterData1(rosterData, baseProd);
    }

    getSizeQuantitiesData2() {
        let rosterData = this.getPrimaryRoster();
        return this.convertToSizeQuantitesFromRosterData2(rosterData);
    }

    rosterHasNameOrNumber(roster, checkName = false, checkNumber = false) {
        const items = _.get(roster, "[0].items", []);
        const checkFor = keyString => (acc, row) => row[keyString] ? acc : acc = false;
        const hasItems = items.length > 0;

        let hasNecessaryData;
        if (hasItems && checkName && checkNumber) {
            const hasAllNameData = items.reduce(checkFor("name"), true);
            const hasAllNumberData = items.reduce(checkFor("number"), true);
            hasNecessaryData = hasAllNameData && hasAllNumberData;
        } else if (hasItems && checkName) {
            hasNecessaryData = items.reduce(checkFor("name"), true);
        } else if (hasItems && checkNumber) {
            hasNecessaryData = items.reduce(checkFor("number"), true);
        }

        return hasNecessaryData;
    }

    hasAnyValidRosterData(checkPlayerName = true, checkPlayerNumber = true, checkSize = true) {
        let retval = false;
        let roster = this.getPrimaryRoster();
        let rosterItems = roster && roster.items;
        if (checkPlayerName || checkPlayerNumber || checkSize) {
            retval = !!_.find(roster && roster.items, v => {
                let hasName = !!_.get(v, 'name');
                let hasNumber = !!_.get(v, 'number');
                let hasSize = !!_.get(v, 'size');
                let hasAny = false;
                if ((checkPlayerName && hasName) || (checkPlayerNumber && hasNumber) || (checkSize && hasSize)) {
                    hasAny = true;
                }
                return hasAny;
            });
        } else {
            retval = !_.isEmpty(rosterItems);
        }

        return retval;
    }

    // $deprecated%. use doesAnyRowHaveValidRosterData instead
    hasValidRosterData(checkPlayerName = true, checkPlayerNumber = true, checkGender = true, checkSize = true) {
        return this.doesAnyRowHaveValidRosterData(checkPlayerName, checkPlayerNumber, checkGender, checkSize);
    }

    // returns true if 1 roster row has all required valid items, each row must have size
    // returns false if roster is empty.
    doesAnyRowHaveValidRosterData(checkPlayerName = true, checkPlayerNumber = true, configType, checkGender = true, checkSize = true) {
        if (configType == 'custom-sub') {
            const roster = _.get(this.getPrimaryRoster(), "items", []);
            const hasRoster = roster && roster.length > 0 && !_.isEmpty(roster);
            const mustCheckAtLeastOneValue = checkPlayerName || checkPlayerNumber || checkGender || checkSize;

            let atLeastOneRowHasNameOrNumber = false;
            let allRowsHaveSize = true;
            let allRowsHaveGender = true;

            hasRoster && mustCheckAtLeastOneValue && roster.forEach(row => {
                const hasName = !!_.get(row, "name");
                const hasNumber = !!_.get(row, "number");
                const sizePasses = !!_.get(row, "size") && checkSize;
                const genderPasses = !!_.get(row, "youthToAdult") && checkGender;

                if (!sizePasses || !allRowsHaveSize) {
                    allRowsHaveSize = false; //not having size always fails if size is required
                }
                if (!genderPasses || !allRowsHaveGender) {
                    allRowsHaveGender = false; //not having gender always fails if gender is required
                }

                const nameCheckedAndPassed = checkPlayerName && hasName;
                const numberCheckedAndPassed = checkPlayerNumber && hasNumber;

                if (nameCheckedAndPassed && numberCheckedAndPassed) {
                    atLeastOneRowHasNameOrNumber = true;
                } else if (nameCheckedAndPassed && !checkPlayerNumber) {
                    atLeastOneRowHasNameOrNumber = true;
                } else if (numberCheckedAndPassed && !checkPlayerName) {
                    atLeastOneRowHasNameOrNumber = true;
                }
            });

            return atLeastOneRowHasNameOrNumber && allRowsHaveSize && allRowsHaveGender;
        } else if (configType == 'stock-dec') {
            const roster = _.get(this.getPrimaryRoster(), "items", []);
            const hasRoster = roster && roster.length > 0 && !_.isEmpty(roster);
            const mustCheckAtLeastOneValue = checkPlayerName || checkPlayerNumber || checkSize;

            let atLeastOneRowHasNameOrNumber = false;
            let allRowsHaveSize = true;

            hasRoster && mustCheckAtLeastOneValue && roster.forEach(row => {
                const hasName = !!_.get(row, "name");
                const hasNumber = !!_.get(row, "number");
                const sizePasses = !!_.get(row, "size") && checkSize;

                if (!sizePasses || !allRowsHaveSize) {
                    allRowsHaveSize = false; //not having size always fails if size is required
                }

                const nameCheckedAndPassed = checkPlayerName && hasName;
                const numberCheckedAndPassed = checkPlayerNumber && hasNumber;

                if (nameCheckedAndPassed && numberCheckedAndPassed) {
                    atLeastOneRowHasNameOrNumber = true;
                } else if (nameCheckedAndPassed && !checkPlayerNumber) {
                    atLeastOneRowHasNameOrNumber = true;
                } else if (numberCheckedAndPassed && !checkPlayerName) {
                    atLeastOneRowHasNameOrNumber = true;
                }
            });

            return atLeastOneRowHasNameOrNumber && allRowsHaveSize;
        }
    }

    // returns true if all roster rows have all required valid items
    // returns false if roster is empty.
    doAllRowsHaveValidRosterData(checkPlayerName = true, checkPlayerNumber = true, checkSize = true) {
        let retval = false;
        let roster = this.getPrimaryRoster();
        let rosterItems = roster && roster.items;
        let isRosterEmpty = _.isEmpty(rosterItems);
        if (!isRosterEmpty && (checkPlayerName || checkPlayerNumber || checkSize)) {
            retval = !!_.every(roster && roster.items, v => {
                let hasName = !!_.get(v, 'name');
                let hasNumber = !!_.get(v, 'number');
                let hasSize = !!_.get(v, 'size');
                let hasAll = true;
                if ((checkPlayerName && !hasName) || (checkPlayerNumber && !hasNumber) || (checkSize && !hasSize)) {
                    hasAll = false;
                }
                return hasAll;
            });
        } else {
            retval = !isRosterEmpty;
        }

        return retval;
    }

    // $deprecated%. use doesAnyRowHaveValidQuantityData instead
    hasValidQuantityData(checkSize = true, checkQuantity = true) {
        return this.doesAnyRowHaveValidQuantityData(checkSize, checkQuantity);
    }

    // returns true if 1 roster row has all required valid items
    // returns false if roster is empty.
    doesAnyRowHaveValidQuantityData(checkSize = true, checkQuantity = true) {
        let retval = false;

        // NOTE: we are expecting this to be in the same location as roster data, event if the name/number values are not expected or used.
        let roster = this.getPrimaryRoster();
        let rosterItems = roster && roster.items;
        let isRosterEmpty = _.isEmpty(rosterItems);
        if (!isRosterEmpty && (checkSize || checkQuantity)) {
            retval = !!_.find(roster && roster.items, v => {
                let hasSize = !!_.get(v, 'size');
                let hasQuantity = !!_.get(v, 'quantity', 1);
                let hasAll = true;
                if ((checkSize && !hasSize) || (checkQuantity && !hasQuantity)) {
                    hasAll = false;
                }
                return hasAll;
            });
        } else {
            retval = !isRosterEmpty;
        }

        return retval;
    }

    // returns true if all roster rows have all required valid items
    // returns false if roster is empty.
    doAllRowsHaveValidQuantityData(checkSize = true, checkQuantity = true) {
        let retval = false;

        // NOTE: we are expecting this to be in the same location as roster data, event if the name/number values are not expected or used.
        let roster = this.getPrimaryRoster();
        let rosterItems = roster && roster.items;
        let isRosterEmpty = _.isEmpty(rosterItems);
        if (!isRosterEmpty && (checkSize || checkQuantity)) {
            retval = !!_.every(roster && roster.items, v => {
                let hasSize = !!_.get(v, 'size');
                let hasQuantity = !!_.get(v, 'quantity', 1);
                let hasAll = true;
                if ((checkSize && !hasSize) || (checkQuantity && !hasQuantity)) {
                    hasAll = false;
                }
                return hasAll;
            });
        } else {
            retval = !isRosterEmpty;
        }

        return retval;
    }

    getRosterStatsInfo(roster) {

        let rosterItems = _.get(roster, 'items', null);
        if (!rosterItems && _.isArray(roster)) {
            rosterItems = roster;
        }

        let columnSums = {
            name: 0,
            number: 0,
            size: 0,
            skus: 0,
            validRows: 0
        };

        _.each(rosterItems, v => {
            if (!v) {
                return;
            }
            let valid = false;
            if (!_.isEmpty(v.name)) {
                columnSums.name++;
                valid = true;
            }
            if (!_.isEmpty(v.number)) {
                columnSums.number++;
                valid = true;
            }
            if (!_.isEmpty(v.size)) {
                columnSums.size++;
                valid = true;
            }
            if (!_.isEmpty(v.sku)) {
                columnSums.sku++;
                valid = true;
            }
            if (valid) {
                columnSums.validRows++;
            }
        });

        let quantity = columnSums.validRows;

        return {
            quantity,
            columnSums
        };
    }

    convertToRosterDataFromSizeQuantites(sizeQuantitiesData, colorCode = null, skus = null) {
        // we need the color code for sku lookup
        if (colorCode === null) {
            colorCode = this.getStateVal('customConfig.color');
        }

        // use the skus from redux state if the were not  passed in
        if (skus === null) {
            skus = this.getStateVal('baseProduct.skus');
        }

        let rosterData = {
            name: '',
            items: []
        };

        if (!_.isEmpty(sizeQuantitiesData)) {
            _.each(sizeQuantitiesData, v => {
                // roster needs empty strings, toDO: allow null, will require rework of code
                // roster doesn't need the sku
                // let name = null;
                // let number = null;
                let name = _.get(v, 'name', "");
                let number = _.get(v, 'number', "");
                let youthToAdult = _.get(v, 'youthToAdult', "");
                // let size = _.get(v, 'size', null);
                let size = _.get(v, 'size', "");
                let qty = +(_.get(v, 'qty', 1));
                // let sku = _.get(v, 'sku', null);
                let sku = this.findSku(skus, colorCode, size);
                if (qty > 0) {
                    rosterData.items.push({
                        name,
                        number,
                        size,
                        sku,
                        qty,
                        youthToAdult
                    });
                }
            });
        }
        return rosterData;
    }

    convertToSizeQuantitesFromRosterData(rosterData, colorCode = null, skus = null) {

        // make sure roster data is well formed
        // catch the case where we have an array of roster items pased in instead of the roster data object
        if (rosterData && _.isArray(rosterData) && !rosterData.items) {
            rosterData = {
                items: rosterData
            };
        }

        // we need the color code for sku lookup
        if (colorCode === null) {
            //colorCode = this.getStateVal('customConfig.color');
            colorCode = this.getStateVal('baseProduct.configurationType') === 'custom-sub' ? null : this.getStateVal('customConfig.color');
        }

        // use the skus from redux state if the were not  passed in
        if (skus === null) {
            skus = this.getStateVal('baseProduct.skus');
        }

        let sqItems = _.chain(rosterData && rosterData.items)
            .map(v => {
                // size quantities do not care about the name & numbers
                let quantity = 1;
                let size = _.get(v, 'size', null);
                let sku = this.findSku(skus, colorCode, size);
                return {
                    size,
                    quantity,
                    sku
                };
            })
            .groupBy(v => v.sku || 'none')
            .map((items, sku) => {
                let firstItem = _.first(items);
                firstItem.quantity = _.size(items);
                return firstItem;
            })
            .value();

        return sqItems;
    }

    convertToSizeQuantitesFromRosterData2(rosterData, colorCode = null, skus = null) {

        // make sure roster data is well formed
        // catch the case where we have an array of roster items pased in instead of the roster data object
        if (rosterData && _.isArray(rosterData) && !rosterData.items) {
            rosterData = {
                items: rosterData
            };
        }

        // we need the color code for sku lookup
        if (colorCode === null) {
            //colorCode = this.getStateVal('customConfig.color');
            colorCode = this.getStateVal('baseProduct.configurationType') === 'custom-sub' ? null : this.getStateVal('customConfig.color');
        }

        // use the skus from redux state if the were not  passed in
        if (skus === null) {
            skus = this.getStateVal('baseProduct.skus');
        }

        let sqItems = _.chain(rosterData && rosterData.items)
            .map(v => {
                // size quantities do not care about the name & numbers
                let quantity = v.qty && v.qty !== null ? v.qty : 1;
                // console.log("Quantity:: ", quantity);
                let size = _.get(v, 'size', null);
                let sku = this.findSku(skus, colorCode, size);
                return {
                    size,
                    quantity,
                    sku
                };
            })
            .groupBy(v => v.sku || 'none')
            .map((items, sku) => {
                // console.log("Items:", items);
                let quantity = 0;
                for (let i = 0; i < items.length; i++) {
                    quantity += items[i].quantity;
                }
                // console.log("Quantity:::", quantity);

                let firstItem = _.first(items);
                firstItem.quantity = quantity;
                return firstItem;
            })
            .value();

        return sqItems;
    }

    convertToSizeQuantitesFromRosterData1(rosterData, baseProd, colorCode = null, skus = null) {

        // make sure roster data is well formed
        // catch the case where we have an array of roster items pased in instead of the roster data object
        if (rosterData && _.isArray(rosterData) && !rosterData.items) {
            rosterData = {
                items: rosterData
            };
        }

        // we need the color code for sku lookup
        if (colorCode === null) {
            //colorCode = this.getStateVal('customConfig.color');
            colorCode = baseProd.configurationType === 'custom-sub' ? null : this.getStateVal('customConfig.color');
        }

        // use the skus from redux state if the were not  passed in
        if (skus === null) {
            skus = baseProd.skus;
        }
        let sqItems = _.chain(rosterData && rosterData.items)
            .map(v => {
                let quantity = +_.get(v, 'qty', null);
                let size = _.get(v, 'size', null);
                let sku = this.findSku(skus, colorCode, size);
                return {
                    size,
                    quantity,
                    sku
                };
            })
            .groupBy(v => v.sku || 'none')
            .map((items, sku) => {
                let quantity = 0;
                for (let i = 0; i < items.length; i++) {
                    quantity += items[i].quantity;
                }
                let obj = {
                    sku: items[0].sku,
                    quantity: quantity,
                    size: items[0].size
                }
                // items.reduce((curr, next) => {
                //     curr.sku = next.sku;
                //     curr.quantity = curr.quantity + next.quantity;
                //     curr.size = next.size;
                // }, );
                let firstItem = obj;
                // let firstItem = _.first(items);
                // // firstItem.quantity = _.size(items);
                return firstItem;
            })
            .value();
        return sqItems;
    }

    findSku(skus, colorCode, sizeCode) {
        let skuDef = _.find(skus, {
            size: sizeCode,
            color: colorCode
        });
        return _.get(skuDef, 'sku', null);
    }

    validateSize(file, defaultFileSize = 0) {
        let size = _.get(file, 'size', defaultFileSize);
        let type = _.get(file, 'type', '');
        let name = _.get(file, 'name', '');
        let excelStrings = ['xlsx', 'xls'];
        let excelMaxSize = 25000;
        let csvMinSize = 10000;

        let isCsv = _.includes(type, 'csv') || _.includes(type, 'application/vnd.ms-excel');
        let isExcel = _.find(excelStrings, str => {
            return _.includes(type, str) || _.includes(name, str);
        });

        return (isExcel && size > 0 && size < excelMaxSize) || (isCsv && size > 0 && size < csvMinSize);
    }

    parseQuantityRoster(results, allowedSizes, getData) {
        let containsData = _.get(results, "data")
        if (containsData) {
            let payload = results.data.reduce((acc, row) => {

                const size = row.find(el => Number(el) > 0 ? false : allowedSizes.includes(el));
                const quantityOfOneSize = row.find(el => Number(el) > 0 ? Number(el) : false);

                if (!size) {
                    return acc;
                }

                const rosterRowObject = {
                    size,
                    name: null,
                    number: null,
                    qty: 1
                };
                let i;
                let arrayOfRowObjects = [];
                for (i = 0; i < quantityOfOneSize; i++) {
                    arrayOfRowObjects.push(rosterRowObject);
                }
                return [...acc, ...arrayOfRowObjects];
            }, []);

            getData(payload);
        } else {
            let newPayload = []
            _.each(results, result => {
                for (var i = 0; i < result.quantity; i++) {
                    newPayload.push({ name: null, number: null, size: result.size, qty: 1 })
                }
            })
            getData(newPayload)
        }
    }

    parseRoster(results, allowedSizes, getData) {

        let nameDone = false;
        let numberDone = false;
        let youthToAdultDone = false;
        let sizeDone = false;
        let quantityDone = false

        let finalArray = [];
        let data;
        let containsData = _.get(results, "data");
        if (containsData) {
            data = containsData;
            let allowedIndexes = [
                [],
                [],
                [],
                []
            ];
            _.each(data[0], (e, i) => {
                //sort through the excel data and grab only the first name, number and size columns. Ignore the rest
                if (e.toUpperCase() === "NAME" && nameDone === false) {
                    allowedIndexes[0] = i;
                    nameDone = true
                } else if (e.toUpperCase() === "NUMBER" && numberDone === false) {
                    allowedIndexes[1] = i;
                    numberDone = true
                } else if (e.toUpperCase() === "GENDER" && youthToAdultDone === false) {
                    allowedIndexes[2] = i;
                    youthToAdultDone = true
                } else if (e.toUpperCase() === "SIZE" && sizeDone === false) {
                    allowedIndexes[3] = i;
                    sizeDone = true
                } else if (e.toUpperCase === "QTY" && quantityDone === false) {
                    allowedIndexes[4] = i;
                    quantityDone = true
                }
            });
            _.each(data, (e) => {
                //if the user is missing one of the three columns, include it but leave the data as empty strings
                if (e[allowedIndexes[0]] === undefined) {
                    e[allowedIndexes[0]] = ""
                }
                if (e[allowedIndexes[1]] === undefined) {
                    e[allowedIndexes[1]] = ""
                }
                if (e[allowedIndexes[2]] === undefined) {
                    e[allowedIndexes[2]] = ""
                }
                if (e[allowedIndexes[3]] === undefined) {
                    e[allowedIndexes[3]] = ""
                }
                if (e[allowedIndexes[4]] === undefined) {
                    e[allowedIndexes[4]] = "1"
                }
                finalArray.push({
                    name: e[allowedIndexes[0]],
                    number: _.isNaN(Number(e[allowedIndexes[1]])) ? "" : Number(e[allowedIndexes[1]]),
                    youthToAdult: e[allowedIndexes[2]],
                    size: e[allowedIndexes[3]],
                    qty: e[allowedIndexes[4]]
                })
            });
            finalArray.shift();
            let payload = finalArray.reduce((acc, row) => { //deals w last row issues, do not change
                const hasValue = Object.values(row).reduce((final, col) => {
                    return final = col ? true : final;
                }, false);
                return acc = hasValue ? [...acc, row] : acc;
            }, []).map(row => {
                const upperCasedSize = row.size.toUpperCase();
                const isXL = _.includes(["1 xl", "1xl", "1XL", "1 XL"], upperCasedSize)
                const formattedSize = isXL ? "XL" : upperCasedSize;
                const defaultSize = allowedSizes[0];
                const isAllowedSize = _.includes(allowedSizes, formattedSize);
                const size = isAllowedSize ? formattedSize : defaultSize;
                return {
                    ...row,
                    size
                };
            });
            getData(payload);
        } else {
            let newPayload = []
            _.each(results, result => {
                newPayload.push({ name: result.name, number: result.number, qty: result.qty, youthToAdult: result.youthToAdult, size: result.size })
            })
            getData(newPayload)
        }

    }
}


// export a static singleton instance
const rosterHelper = new RosterHelper();
export default rosterHelper;