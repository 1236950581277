// import _ from 'lodash';
// import * as Sentry from '@sentry/browser';

// NOTE sentry automatically records errors with out any configuration beyond the Sentry.init step. See: "https://docs.sentry.io/error-reporting/capturing/?platform=browser".

export default class EventTracker {
  logError(err, errInfo) { //second param/arg has stacktrace but sentry picks it up automatically
    // const errorMsg = _.get(err, "message", err);
    // Sentry.captureException(err);
    return;
  }
}

