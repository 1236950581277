
export const CHANGE_NAVIGATION_OPTION = 'CHANGE_NAVIGATION_OPTION';
export const TOGGLE_DISPLAY_OPTION_MOBILE = 'TOGGLE_DISPLAY_OPTION_MOBILE';
export const LOAD_COLOR_OPTIONS_SUCCESS = 'LOAD_COLOR_OPTIONS_SUCCESS';

// App actions
export const RENDER_PREVIEWS_ACTION = "RENDER_PREVIEWS_ACTION";
// export const RENDER_PREVIEWS_STANDBY = "RENDER_PREVIEWS_STANDBY_ACTION";
// export const RENDER_PREVIEWS_RESULT = "RENDER_PREVIEWS_RESULT";

// Navigation actions
export const SET_IN_IFRAME = "SET_IN_IFRAME";
export const TOGGLE_EDIT_DRAWER = "TOGGLE_EDIT_DRAWER";
export const TOGGLE_ART_DRAWER = "TOGGLE_ART_DRAWER";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const AMEND_LOCATION = "AMEND_LOCATION";
export const UPDATE_BOUNDS = "UPDATE_BOUNDS";
export const SELECT_LOCATION = "SELECT_LOCATION";
export const DRAG_ART = "DRAG_ART";
export const SET_VIEW = "SET_VIEW";
export const SET_OPTIONS = "SET_OPTIONS";
export const TOGGLE_LOGOS = "TOGGLE_LOGOS";
export const TOGGLE_USER_EDIT_MODE = "TOGGLE_USER_EDIT_MODE";

// Notification action
export const SEND_NOTIFICATION = "SEND_NOTIFICATION";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
export const SEND_IFRAME_MESSAGE = "SEND_IFRAME_MESSAGE";
export const SEND_ADD_TO_CART_MESSAGE = "SEND_ADD_TO_CART_MESSAGE";
export const SET_SCRIM = "ENABLE_SCRIM";

// Custom actions
export const UPDATE_PRODUCT_COLOR = "UPDATE_PRODUCT_COLOR";
export const LOAD_CONFIG = "LOAD_CONFIG";
export const UPDATE_UPGRADES = "UPDATE_UPGRADES";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const UPDATE_NOTES = "UPDATE_NOTES";
export const UPDATE_ROSTER_ITEMS = "UPDATE_ROSTER_ITEMS";
export const ADD_ROSTER_ITEM = "ADD_ROSTER_ITEM";
export const ADD_ROSTER_ITEMS = "ADD_ROSTER_ITEMS";
export const REMOVE_ROSTER_ITEMS = "REMOVE_ROSTER_ITEMS";
export const REMOVE_ROSTER_ITEM = "REMOVE_ROSTER_ITEM";
export const DELETE_ROSTER = "DELETE_ROSTER";
export const CHANGE_TEXT_FONT = "CHANGE_TEXT_STYLE";
export const CHANGE_TEXT_EFFECT = "CHANGE_TEXT_EFFECT";
export const UPDATE_RENDER_URL = "UPDATE_RENDER_URL";
export const UPDATE_ENTITY = "UPDATE_ENTITY";
export const UPDATE_ID = "UPDATE_ID";
export const UPDATE_NAME = "UPDATE_NAME";
export const UPDATE_QUANTITY = "UPDATE_QUANTITY";
export const MERGE_LOADED_DEFS = "MERGE_LOADED_DEFS";
export const SET_LOADED_DEFS = "SET_LOADED_DEFS";
export const SET_BASE_PRODUCT = "SET_BASE_PRODUCT";
export const SET_STYLE_NUM = "SET_STYLE_NUM";
export const SET_CONFIG_ID = "SET_CONFIG_ID";
export const SET_WEB_REF = "SET_WEB_REF";
export const UPLOAD_QUANTITY_ROSTER = "UPLOAD_QUANTITY_ROSTER";
export const CHANGE_FABRIC = "CHANGE_FABRIC";
export const UPLOAD_ROSTER = "UPLOAD_ROSTER";
export const UPDATE_CHROMAGEAR_COLOR = "UPDATE_CHROMAGEAR_COLOR";
export const UPDATE_PATTERN = "UPDATE_PATTERN";
export const TOGGLE_COLOR_MODE = "TOGGLE_COLOR_MODE";
export const CHANGE_PATTERN_ADVANCED = "CHANGE_PATTERN_ADVANCED";
export const CHANGE_COLOR_ADVANCED = "CHANGE_COLOR_ADVANCED";
export const CHANGE_PATTERN_COLOR = "CHANGE_PATTERN_COLOR";

//color changes in color tab
export const CHANGE_DESIGN_COLOR = "CHANGE_DESIGN_COLOR"

// decoration tab changes
export const CHANGE_DECORATION_GRID = "CHANGE_DECORATION_GRID";
export const EDIT_DECORATION_TYPE = "EDIT_DECORATION_TYPE";
export const SET_DECORATION_SUBTAB = "SET_DECORATION_SUBTAB";
export const SET_ART_SUBTAB = "SET_ART_SUBTAB";
export const SET_DECORATION_TYPE = "SET_DECORATION_TYPE";
export const SET_PREV_LOCATION_DETAILS = "SET_PREV_LOCATION_DETAILS";
export const UPDATE_ART_NAME = "UPDATE_ART_NAME";
export const UPDATE_ART_COLORS = "UPDATE_ART_COLORS";
export const PLAYER_NAME_NUMBER_UPDATED = "PLAYER_NAME_NUMBER_UPDATED";

/// hover changes

export const UPDATE_HOVER = "UPDATE_HOVER";

// modal changes

export const SHOW_MODAL = "SHOW_MODAL";
export const UPDATE_MODAL = "UPDATE_MODAL";

// toast changes
export const SHOW_TOAST = "SHOW_TOAST";
export const HIDE_TOAST = "HIDE_TOAST";

// toolTip changes
export const SHOW_TOOL_TIP = "SHOW_TOOL_TIP";

// launch context changes
export const INIT_LAUNCH_CONTEXT = "INIT_LAUNCH_CONTEXT";

// user actions

export const UPDATE_ART_ASSET_DATA = "UPDATE_ART_ASSET_DATA";
export const SET_USER_INIT_STATE = "SET_USER_INIT_STATE";
export const ADD_UPLOADED_ART = "ADD_UPLOADED_ART";
export const USER_PATTERN_HISTORY = "USER_PATTERN_HISTORY";

//issues (notifications/errors/warnings)
export const DISPLAY_ISSUE = "DISPLAY_ISSUE";

export const UPDATE_DECORATION_DATA = "UPDATE_PRICE_DATA";
export const REMOVE_DECORATION_DATA = "REMOVE_DECORATION_DATA";
export const ADD_DECORATION_DATA = "ADD_DECORATION_DATA";
export const CLEAR_DECORATION_DATA = "CLEAR_DECORATION_DATA";
export const REPLACE_DECORATION_PRICING = "REPLACE_DECORATION_PRICING";

//artAssets
export const TOGGLE_SYSTEM_ART_ITEMS = "TOGGLE_SYSTEM_ART_ITEMS";
export const EDIT_SEARCH_TERM = "EDIT_SEARCH_TERM";
export const UPDATE_ART_ASSETS = "SET_ART_ASSETS";
export const UPDATE_ART_CATEGORIES = "UPDATE_ART_CATEGORIES";
export const CHANGE_CATALOG_NAVIGATION = "CHANGE_CATALOG_NAVIGATION";
export const UPDATE_QUICK_LINK_SELECTION = "UPDATE_QUICK_LINK_SELECTION";
export const EDIT_SEARCH_CATEGORIES_TERM = "EDIT_SEARCH_CATEGORIES_TERM";

// Save converted image and vector image
export const SET_ADDITIONAL_ASSETS = "SET_ADDITIONAL_ASSETS";