import AppConstants from '../constants';
import colorsHelper from './colorsHelper';
import customConfigHelper from './customConfigHelper';
import customLayersHelper from './customLayersHelper';
import displayHelper from './displayHelper';
import hoverHelper from './hoverHelper';
import loadedDefsHelper from './loadedDefsHelper';
import locationsHelper from './locationsHelper';
import navigationHelper from './navigationHelper';
import pricingHelper from './pricingHelper';
import productHelper from './productHelper';
import rosterHelper from './rosterHelper';
import stateProvider from './stateProvider';
import urlRenderHelper from './urlRenderHelper'
import serializationHelper from './serializationHelper'
import userHelper from './userHelper';



// Notice: these helpers should treat the redux state as READ-ONLY.
// they should not manipulate or write to the redux state.
// Doing so would cause redux state update thrashing.
// they are intended to interpret and parse the redux state in a read only manner.
// all helper objects should already be a singleton instance.


export default {
  appConst: AppConstants,
  state: stateProvider,
  colors: colorsHelper,
  customConfig: customConfigHelper,
  customLayers: customLayersHelper,
  display: displayHelper,
  hover: hoverHelper,
  loadedDefs: loadedDefsHelper,
  locations: locationsHelper,
  navigation: navigationHelper,
  pricing: pricingHelper,
  product: productHelper,
  roster: rosterHelper,
  urlRender: urlRenderHelper,
  user: userHelper,
  serialization: serializationHelper
};
