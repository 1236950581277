import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import MultiDropdown from '../../../../global/multiDropdown/multiDropdown';
import { changeSetting } from "../../../../../redux/reducers/customConfig/customConfigActions";

function EditTextValue(props) {

    let locationContent = _.find(props.customConfig.locations, { code: props.navigation.activeLocation });
    let bpLocation = _.find(props.baseProduct.locations, { code: locationContent.code });
    let bpAllowedEntity = _.find(bpLocation.allowedEntities, { key: locationContent.entity.type });

    const handleOnChange = (e) => {
        let value = e.target.value
        props.changeSetting(props.customConfig.locations, props.navigation.activeLocation, { setting: "value", value: value });

    }

    let value = locationContent.entity.value;
    let placeholder = bpAllowedEntity.placeholderValue;
    let maxLength = bpAllowedEntity.maxCharLength;
    if(value === 'ENTER TEXT'){
        value='';
    }
    return (
        <div className="EditTextValue" style={{ marginTop: "15px", position: "relative" }}>
            <MultiDropdown
            />
             <input
                className="text-height-number-input-text-value"
                value={value}
                onChange={handleOnChange}
                placeholder={placeholder}
                maxLength={maxLength}
            /> 
        </div>
    )

}

const reduxActions = {
    changeSetting    
}

function mapStateToProps(state) {
    return {
        navigation: state.navigation,
        baseProduct: state.baseProduct,
        customConfig: state.customConfig,
        navigation: state.navigation
    }
}

export default connect(mapStateToProps, reduxActions)(EditTextValue);