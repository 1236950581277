import React, { Component } from 'react'; 
import { ChevDown, ChevRight } from '../../../../assets/icons/ChevronSVG'; 
import HtmlComponent from '../../htmlComponent/HtmlComponent'; 
import _ from 'lodash'; 

export default class AssetSearchInterface extends Component {

  searchLink = (term) => {
    this.props.search(term); 
  }

  navLink = (tree, open) => {
    if (open){
      tree.pop(); 
    }
    this.props.setTitle(tree); 
    this.props.setNav(tree);  
  }

  renderChev(open){
    return (open) ? (<ChevDown />) : (<ChevRight />); 
  }

  renderAllSelection(searchTerm, element, isSearching, tree){ 
    let matchesKey = element.key.includes(searchTerm);    
    return (<li 
      className={`asset-library_endpoint ${matchesKey ? "selected " : "" } ${isSearching ? "searching " : ""}`}
      key={element.id}>
        <span onClick={() => this.searchLink(element.key, tree)}>
          <HtmlComponent 
          title="All"
          line = {element.name} 
          word = {searchTerm} />
        </span>
      </li>); 
    }

  renderTree(searchNav = null){
    let { nav } = this.props;
    let message = false;  

    if (this.props.searchTerm !== "" ){
      if (_.isEmpty(searchNav)){
        message="no matches found..."
      } else {
        nav = searchNav; 
      }
    }

    let tree = []; 
    let elements = []; 

    _.each(this.props.catalog.children, (el) => { elements.push(this.addElement(el, tree, this.props.searchTerm, nav)) }); 

   return (<ul id="logo-nav">
    <div className="logo-nav__message"> 
      { message }
    </div> 
   { elements }
   </ul>);
  }

  createFoundList(){
    let list = [];
     _.each(this.props.results, result => {
       let { depth }  = result; 
       _.each(depth, (term) => {        
         if (list.indexOf(term) < 0){ list.push(term); }
       }); 
      });

    return list; 
  }

  addElement = (element, navArray, searchTerm, nav) => {
    let isSearching = (searchTerm !== "");     
    let isOpen = nav.includes(element.key); 
    let tree = _.cloneDeep(navArray); 
    tree.push(element.key); 
    if (!element.children) {
      return (<li 
        className={`asset-library_endpoint ${isOpen ? "selected " : " " } ${isSearching ? "searching " : " "}`}
        key={element.id}>
          <span onClick={() => this.searchLink(element.key, tree)}>
            <HtmlComponent 
            line={element.name} 
            word = {searchTerm} />
          </span>
        </li>); 
    } 
    let elements = [this.renderAllSelection(searchTerm, element, isSearching, tree)];
    _.each(element.children, (el) =>{ elements.push(this.addElement(el, tree, searchTerm, nav)) }); 
      return (<li key={element.id}><span 
        onClick={() => this.navLink(tree, isOpen)}
        className={ `asset-library-nav ${isOpen ? "nav-open" : "nav-closed"} ${isSearching ? "searching " : null}` }>
          { this.renderChev(isOpen)}
          <div> {element.name} </div>
      </span>
        <ul className={ isOpen ? "active" : "nested" }>
        { elements }
      </ul></li>); 
  }

  render() {
    let newNav = this.createFoundList(); 
    return (<div>
      <div className="asset-library__navagation fancy-scrollbar">
        <div className="asset-library__nav-tree-container">
          {this.renderTree(newNav)}
        </div>
      </div>
    </div>); 
  }
}
