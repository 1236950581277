// import _ from 'lodash';
// import store from '../../redux/configureStore';
import { showIssueMessage, hideIssueMessage } from '../../redux/reducers/issues/issuesActions';
// import { showErrorModal, closeModal } from '../../redux/reducers/modal/modalActions';
import { showErrorModal } from '../../redux/reducers/modal/modalActions';
import { showIssueToast, hideToast } from '../../redux/reducers/toast/toastActions';
import { EventTracker } from '../../lib/actionTracker';

export default class ErrorDisplayHandler {

  constructor(configOpts, defaultConfig) {
    this.configOpts = configOpts;
    this.errorColors = [
      {severity: 'low', color: 'blue'},
      {severity: 'high', color: 'red'},
      {severity: 'medium', color: 'orange'},
    ];
    this.notifOpts = [
      {key: 'modal', fns: [showErrorModal]},
      {key: 'message', fns: [showIssueMessage, hideIssueMessage]},
      {key: 'toast', fns: [showIssueToast, hideToast]}
    ];
    this.codeOpts = ['2', '3', '4', '5', 'custom', 'timeout', 'default'];
    this.defaultOpt = defaultConfig;
    this.defaultTitle = 'Error';
    this.defaultMsg = 'There was an issue and we are working on it!';
  }

  handleDisplayApiErr(err, errorInfo) {
    //get/build error code
    EventTracker.logError(err, errorInfo);
    // const foundErrorCode = this.codeOpts.find(errStr => error.message.includes(errStr));
    // const err = foundErrorCode && Number(foundErrorCode) > 0 ? foundErrorCode + '00' : foundErrorCode;
    // const errorCode = foundErrorCode ? err : 'default';

    // //get closest config match to error
    // const foundConfig = _.reduce(this.configOpts, (acc, config) => {
    //   const isRightCategory = config.sourceKey === errorInfo.sourceKey;
    //   const isRightErrorCode = errorCode === config.errorCode;
    //   return isRightCategory && !acc && isRightErrorCode ? acc = config : acc;
    // }, null);
    // const activeConfig = foundConfig ? foundConfig : this.defaultOpt;

    // //get right display fn, text color, message from config
    // const getFn = opt => opt.key === activeConfig.displayLocation;
    // const {fns: [show, hide]} = _.find(this.notifOpts, getFn, {fns: [showErrorModal, closeModal]});
    // const textColor = _.find(this.errorColors, opt => opt.severity === activeConfig.severity, 'red');
    // const verifiedMsg = activeConfig.userMessage ? activeConfig.userMessage : this.defaultMsg;
    // const verifiedTitle = activeConfig.title ? activeConfig.title : this.defautTitle;

    // //dispatch show and hide events
    // store.dispatch(show(verifiedMsg, verifiedTitle, textColor));
    // if(hide) setTimeout(() => store.dispatch(hide()), 5000);
  }

  logReactError(err, errorInfo) {
    EventTracker.logError(err, errorInfo);
  }
}
