export default {
  scale: 10, //placeholder scale until we get the pixels to inches conversion data in
  id: 0,
  chromagear: false,
  configurationType: "custom-sub",
  description: '',
  brand: {
    id: 101,
    key: 'badger',
    name: 'Badger Sport',
    mainUrl: 'https://www.badgersport.com',
    helpUrl: 'https://www.badgersport.com/service/',
    logoUrlSm: 'images/brands/badger_b_logo_sm.png',
    logoUrlLg: 'images/brands/badger_b_logo_sm.png'
  },
  fabrics: ["9","A","H","I","M"],
  defaultFabric : "9",
  customLayers: {
    allowedColorPalettes: ["athletic", "battlefield", "earth", "electric", "spirit"],
    allowedPatternPalettes: ["cg-sublimated-patterns"],
    layers: [
      {
        key: "collar",
        name: "Collar",
        allowedColorPalettes: ["athletic"],
        allowedPatternPalettes: null,
        colorGroup: 0,   // color group starts at 0
        patternGroup: 0,
      },
      {
        key: "front1",
        name: "Main Front Panel",
        allowedColorPalettes: ["battlefield"],
        allowedPatternPalettes: ["cg-sublimated-patterns"],
        colorGroup: 0,
        patternGroup: 0,
      },
      {
        key: "front2",
        name: "Inner Front Panel",
        allowedColorPalettes: ["earth"],
        allowedPatternPalettes: ["cg-sublimated-patterns"],
        colorGroup: 2,
        patternGroup: 0,
      },
      {
        key: "side",
        name: "Side Stripe",
        allowedColorPalettes: ["electric"],
        allowedPatternPalettes: ["cg-sublimated-patterns"],
        colorGroup: 1,
        patternGroup: 0
      },
      {
        key: "cuff",
        name: "Cuff",
        allowedColorPalettes: ["spirit"],
        allowedPatternPalettes: null,
        colorGroup: 0,
        patternGroup: 0
      },
      {
        key: "sleeve",
        name: "Sleeves",
        allowedColorPalettes: ["athletic", "battlefield", "earth", "electric", "spirit"],
        allowedPatternPalettes: ["cg-sublimated-patterns"],
        colorGroup: 2,
        patternGroup: 0
      },
      {
        key: "back",
        name: "Back Panel",
        allowedColorPalettes: ["athletic", "battlefield", "earth", "electric", "spirit"],
        allowedPatternPalettes: ["cg-sublimated-patterns"],
        colorGroup: 1,
        patternGroup: 0
      }
    ],
    defaultColors: ["WH", "RY", "BK"],
    defaultPatterns: [null, {"key": "flat", "colors": ["RY"]}]
  },

  // use m3 color codes.  lookup extended color information from the loadedDefs.colors data
  colors: [],
  defaultColor: null,
  defaultView:  'front',
  locations: [],
  allowedUpgrades: []
}