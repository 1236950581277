import _ from "lodash";
import moment from "moment";
import validators from "../validators";
import api from "../api";
import helpers from "../helpers";
import designManager from "../helpers/designManager";
import AppConstants from "../constants";

class AppLoader {
  async prepareLaunchContextFromInitData(initData, remapPropertyNames = true) {
    if (initData.style !== undefined && !initData.isMasterConfig && initData.design !== undefined) {
      let dataRes = await api.sublimatedProducts.getDesignsByStyleNumber(initData.style);
      let filteredDesign = dataRes.designs.filter(filterdesign => filterdesign.designNum.includes(initData.design));
      if (filteredDesign[0].isMasterConfig === true) {
        initData.configId = filteredDesign[0].configId;
        initData.style = undefined;
        initData.design = undefined;
        initData.SKU = undefined
      }
    }
    let launchContext = _.cloneDeep(initData || {});
    if (!_.isEmpty(launchContext) && remapPropertyNames) {
      if (launchContext.style && !launchContext.styleNum) {
        launchContext.styleNum = launchContext.style;
        delete launchContext.style;
      }
      if (launchContext.webUserId === 0 || launchContext.webUserId === "0") {
        launchContext.webUserId = null;
      }
      if (launchContext.accountId === 0 || launchContext.accountId === "0") {
        launchContext.accountId = null;
      }
      // convert from plain property names to ae prefixed names
      if (launchContext.webUserId && !launchContext.aeWebUserId) {
        launchContext.aeWebUserId = launchContext.webUserId;
        launchContext.aeCorpAcctId =
          launchContext.aeCorpAcctId ||
          launchContext.accountId ||
          launchContext.corpAcctId ||
          null;
        launchContext.webUserId = null;
        launchContext.accountId = null;
        launchContext.corpAcctId = null;
      }
    }
    return launchContext;
  }

  async loadStartupData(launchContext, showDesigns = false) {
    // console.log("Load Startup Data...");
    launchContext = launchContext || {};
    let styleNum = launchContext.styleNum || null;
    let aeWebUserId = launchContext.aeWebUserId || null;
    let aeCorpAcctId = launchContext.aeCorpAcctId || null;
    let configId = launchContext.configId || null;

    if (configId === null) {
      configId = launchContext.ConfigId
    }

    let getProductData = styleNum && styleNum !== "sample" && !showDesigns;

    let configAPISavedAt = null;

    // logic to track and re-use guest login auth state
    let authInitAt = moment();
    let prevAuthState = (await this.loadPrevAuthState(authInitAt)) || {};
    let designNum = launchContext.design;

    return Promise.all([
      api.auth.loginAEWebUser(
        aeWebUserId,
        aeCorpAcctId,
        prevAuthState.guestWebUserId,
        prevAuthState.guestCorpAcctId,
        prevAuthState.authToken,
        true
      ),
      getProductData ? api.baseProducts.get(styleNum, designNum ? { designNum } : null) : null
    ])
      .then(async ([authState, baseProductRes]) => {
        let authToken = _.get(authState, "token", null);
        let webUserId = _.get(authState, "userId", null);
        let corpAcctId = _.get(authState, "corpAcctId", null);

        // set localStorage values for authState now
        let storageJson = JSON.stringify({
          ...authState,
          initAt: authInitAt.toISOString()
        }); // authToken, webUserId, webUserUid, corpAcctId, isGuestUser, createdAt });
        localStorage.setItem("fsg-builder-auth", storageJson);

        // set API auth token for all future API calls
        api.config.authToken = authToken;

        if (aeCorpAcctId === null || aeCorpAcctId === '')
          aeCorpAcctId = 123456;
        return Promise.all([
          this.loadCustomConfig(configId),
          this.loadWebUserArtAssets(webUserId, showDesigns),
          this.loadPricingDefaults(webUserId),
        // styleNum !== '' && styleNum !== null ? this.loadBaseProductPricing(aeCorpAcctId, styleNum) : null
        ]).then(
          async ([
            customConfigRes,
            userArtAssets,
            resolvedPricingDefaultOpts,
            // productPricingRes
          ]) => {
            let customConfig, baseProduct, loadedDefs, userState;
            // console.log("Product Res:", productPricingRes);
            // console.log("product pricing res is", productPricingRes, customConfigRes);
            let baseProductPricing;
          // if ((styleNum === null || productPricingRes === null) && customConfigRes.styleNum !== null) {
          //   console.log("base price is", customConfigRes.styleNum);
          //     baseProductPricing = await this.loadBaseProductPricing(aeCorpAcctId, customConfigRes.styleNum);
          //     productPricingRes = baseProductPricing;
          //   }

            baseProduct = _.get(baseProductRes, "data", {});
            if(!showDesigns){
            let styleNumForPricing = baseProduct.styleNum || customConfigRes.styleNum
            console.log("Base Product Mode", styleNumForPricing);
            if(styleNumForPricing !== null){
              baseProductPricing = await this.loadBaseProductPricing(aeCorpAcctId, styleNumForPricing);
            }
            }
            if (customConfigRes && customConfigRes.data) {
              // if there is a loaded custom config use that data
              if (styleNum !== customConfigRes.styleNum) {
                baseProductRes = await api.baseProducts.get(
                  // customConfigRes.styleNum
                  customConfigRes.styleNum && (customConfigRes.designNum !== 'Stock') ? customConfigRes.styleNum + "?designNum=" + customConfigRes.designNum : customConfigRes.styleNum,
                );
                baseProduct = _.get(baseProductRes, "data", {});
              }

              configAPISavedAt = _.get(customConfigRes, "updatedAt", null);
              customConfig = _.get(customConfigRes, "data", {});
              customConfig.webUserId = _.get(customConfigRes, 'webUserId', {});
              customConfig.corpAcctId = _.get(customConfigRes, 'corpAcctId', {});
              customConfig.isMasterConfig = _.get(customConfigRes, 'isMasterConfig', {});
              _.each(helpers.serialization.TransientCustomConfigFields, k => {
                let v = _.get(customConfigRes, k, undefined);
                if (v !== undefined) {
                  _.set(customConfig, k, v);
                }
              });
            } else {
              // need to create a new customConfig 
              customConfig = validators.customConfig.creator(baseProduct);
            }

            let productLayers;
            let primaryColors = _.get(baseProduct, 'customLayers.defaultColors');
            let newAdvancedLayers = [];
            if(baseProduct.configurationType === 'custom-sub') {
              productLayers = _.get(baseProduct, 'customLayers.layers');
              _.each(productLayers, (layer, index) => {
                let layerName = layer.name;
                if (layerName) {
                  layerName = layerName.replace(/[\{].+?[\}]/g, '');
                  layerName = layerName.replace('Layer', '');
                  layerName = layerName.trim();
                }
                newAdvancedLayers.push({ key: layer.key, color: primaryColors[index], layerName: layerName, colorGroup: layer.colorGroup });
              });
              if (customConfig.customLayers.layers.length === 0) {
                customConfig.customLayers.layers = newAdvancedLayers;
              }
            }

            if (_.isEmpty(baseProduct) && !showDesigns) {
              throw new Error("product not found");
            }
            validators.customConfig.addLaunchContext(
              customConfig,
              launchContext,
              baseProduct
            );

            userState = validators.user.creator(
              webUserId,
              corpAcctId,
              !_.isEmpty(userArtAssets) ? userArtAssets
                .filter(asset => ['image/png', 'image/jpeg', 'image/svg+xml'].includes(asset.contentType)) : []

            );

            loadedDefs = validators.loadedDefs.creator(
              baseProductRes,
              resolvedPricingDefaultOpts
            );

            let state = {};
            state.error = false;
            state.auth = this.validateAuth(authState);
            state.user = this.validateUser(userState);
            state.baseProduct = this.validateBaseProduct(baseProduct);
            state.baseProduct.baseProductPrices = baseProductPricing;
            state.loadedDefs = this.validateLoadedDefs(loadedDefs);
            state.customConfig = this.validateCustomConfig(
              customConfig,
              baseProduct,
              loadedDefs
            );
            state.allowedTabs = this.validateNavigation(baseProduct);
            state.localCustomConfig = designManager.loadLocalConfig(
              state.customConfig,
              configAPISavedAt
            );
            let leadTime = _.get(baseProductRes, "leadTime", {});
            state.baseProduct.leadTime = leadTime;
            let productMetaData = _.get(baseProductRes,"productMetaData",{});
            state.baseProduct.productMetaData = productMetaData;
            // console.log("State:", state);
            return state;
          }
        );
      })
      .catch(err => {
        console.warn("app.loadStartup failed", err);
        return Promise.reject(err);
      });
  }

  async loadPrevAuthState(currentTime = moment()) {
    let prevAuthState = null;
    try {
      prevAuthState = JSON.parse(localStorage.getItem("fsg-builder-auth"));
      if (prevAuthState) {
        prevAuthState.expiresAt =
          (prevAuthState.expiresAt && moment(prevAuthState.expiresAt)) || null;
        if (
          !currentTime ||
          !prevAuthState.expiresAt ||
          currentTime.isAfter(prevAuthState.expiresAt)
        ) {
          prevAuthState.authToken = null;
        }
      }
    } catch (ex) {
      prevAuthState = null;
    }

    return prevAuthState;
  }

  async loadCustomConfig(configId) {
    if (!configId) { return null; }
    return api.webUserConfigs.get(configId, { isShare: true }).then(res => {
      return res;
    }).catch(err => {
      console.warn('app.loadCustomConfig failed', err);
      return null;
    });
  }

  async loadWebUserArtAssets(webUserId, showDesigns) {
    if (!webUserId || showDesigns) {
      return null;
    }
    let q = { $limit: 1000, $sort: [{ createdAt: "desc" }] }; // add $sort to show newest first
    return api.webUserArtAssets
      .find(q)
      .then(res => {
        return _.get(res, "data", null);
      })
      .catch(err => {
        console.warn("app.loadWebUserArtAssets failed", err);
        return null;
      });
  }

  validateAuth(authState) {
    return validators.auth.validate(authState);
  }

  validateUser(user) {
    return validators.user.validate(user);
  }

  validateLoadedDefs(loadedDefs) {
    return validators.loadedDefs.validate(loadedDefs);
  }

  validateBaseProduct(baseProduct) {
    baseProduct = validators.baseProduct.validate(baseProduct);

    return baseProduct;
  }

  validateNavigation(baseProduct) {
    return validators.nav.validate(baseProduct);
  }

  validateCustomConfig(customConfig, baseProduct, loadedDefs) {
    return validators.customConfig.validate(
      customConfig,
      baseProduct,
      loadedDefs
    );
  }

  validateProductColorCode(loadedDefs, colorCode) {
    return validators.loadedDefs.validateProductColorCode(
      loadedDefs,
      colorCode
    );
  }

  validateProductColorCodes(loadedDefs, colorCodes) {
    return validators.loadedDefs.validateProductColorCodes(
      loadedDefs,
      colorCodes
    );
  }

  async loadPricingDefaults(webUserId) {
    return api.decorationPricing.calcPrices(
      AppConstants.DefaultPricingRequestData,
      webUserId
    );
  }

  async loadBaseProductPricing(aeCorpId, styleNum) {
    let response = await api.baseProductPricing._baseProductPricesFromApi(aeCorpId, styleNum);
    return response;
  }

  debugPrintAllowedLocationEntites() {
    // NOTE: debug only -- log all locations and allowed entity types
    let locations = helpers.locations.getAllLocations();
    console.log(_.size(locations) + " location(s) found");
    _.each(locations, (loc, idx) => {
      let num = _.padStart(idx + 1, 2, " ");
      let view = _.padStart(loc.view, 5, " ");
      let code = _.padStart(loc.code, 4, " ");
      let allowedEntityTypes = _.join(
        helpers.locations.getLocationEntityTypes(loc, true),
        ", "
      );
      console.log(
        `  ${num} - view: ${view}, code: ${code}, entities: ${allowedEntityTypes}`
      );
    });
  }

}

let app = new AppLoader();
export default app;
