import { RestService } from '../lib';


/**
 * Service Endpoint Implementation
 */
export default class WebUserArtAssetsService extends RestService {

  constructor(opts = null) {
    super(opts);
    this.serviceName = 'webUserArtAssets';
    this.routePath = 'builder/web-user/art-assets';
    this.authEnabled = true;
  }

  async uploadImage(fileObj, numDecorationColors = null, name = null) {
    // let fileData = fileObj;
    let filename = fileObj.name;
    let filesize = fileObj.size;
    let contentType = fileObj.type || null;
    let formData = new FormData();
    formData.append('file', fileObj);
    formData.append('name', name || filename);
    formData.append('filename', filename);
    formData.append('filesize', filesize);
    formData.append('contentType', contentType);
    formData.append('numDecorationColors', numDecorationColors);
    return this.uploadFile(formData, null, null);
  }

  async updateNameAndNumberOfColors(id, name, numDecorationColors){
    return this.patch(id, {name, numDecorationColors}, null, null); 
  }

  async convertFile(fileObj) {
    let formData = new FormData();
    formData.append('file', fileObj);
    return this.convertVectorFile(formData);
  }

}


