import React, { Component } from "react";
import { connect } from "react-redux";
import { setView, setActiveLocation, setActiveTab, dragItem , setPendingLocation} from "../../../../redux/reducers/navigation/navigationActions";
import { addPendingLocationToLocations } from "../../../../redux/reducers/customConfig/customConfigActions"
import helpers from "../../../../helpers"
import _ from "lodash"
import PreviewCanvasTwo from "../../../previewCanvasTwo/PreviewCanvasTwo";

class ViewSelectorCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false
    };

    this.handleDrop = this.handleDrop.bind(this);
  }

  handleBack(page = "main") {
    let tabObj = { tab: this.props.navigation.activeTab.tab, page };
    this.props.setActiveTab(tabObj);
  }

  setToBestLocation = ( bestLocation, entityKey ) =>{
    let entityDefaultValues = _.find( bestLocation.allowedEntities, {key: entityKey});
    if ( entityDefaultValues ) {
      let value = entityDefaultValues.placeholderValue;
      if ( entityKey === 'custom-text'){
        if ( this.props.navigation.customTextInput ){
          value = this.props.navigation.customTextInput;
        }
      }
      let artAsset = null;
      if ( this.props.navigation.pendingLocation.entity.type === "custom-art"){
        let pendingLocation = this.props.navigation.pendingLocation;
        artAsset = _.cloneDeep(pendingLocation.entity);
        artAsset.id = artAsset.userArtId;
      }
      let pendingLocation = helpers.navigation.makePendingLocation( entityDefaultValues.type, value, bestLocation.view, bestLocation.code, artAsset);
      this.props.setPendingLocation( pendingLocation );
      this.props.setActiveLocation( bestLocation.code );
      this.props.setView( bestLocation.view ); 
    }
  }

  handleChangeView = () => {
    let bestLocation = null;
    let entityKey = null 
    if ( this.props.navigation.pendingLocation && this.props.navigation.pendingLocation.entity.type){
       entityKey = this.props.navigation.pendingLocation.entity.type;
    };
    // if location does not support entity type find next available location
    bestLocation = helpers.locations.findBestEmptyLocationByEntityType( entityKey, this.props.item.key);
    // TODO: logic what to do when selecting view. you have  ////
    if ( bestLocation ){
      //  if there is no best location on current view search all views
      this.setToBestLocation( bestLocation, entityKey );
    }
    this.props.setView(this.props.item.key);
  };

  handleDrop = () => {
    let view = this.props.item.key;
    clearTimeout(this.changeViewTimer);
    this.setState({hovered: false});
    this.props.dragItem(false);

    // does drop view have a location that supports selected entity type.
    let bestLocation = null;
    let entityKey = null 
    if ( this.props.navigation.pendingLocation && this.props.navigation.pendingLocation.entity.type){
       entityKey = this.props.navigation.pendingLocation.entity.type;
    };
    // if location does not support entity type find next available location
    bestLocation = helpers.locations.findBestEmptyLocationByEntityType( entityKey, view);
    if ( bestLocation ){
      //  if there is no best location on current view search all views
      this.setToBestLocation( bestLocation, entityKey );
      this.addToDesign();
    }
  }

  addToDesign = () => {
    let oldLocations = this.props.customConfig.locations;
    let pendingLocation = this.props.navigation.pendingLocation;
    this.props.addPendingLocationToLocations( oldLocations, pendingLocation );

  }

  handleDragLeave = () => {
    this.setState({hovered: false});
    clearTimeout(this.changeViewTimer);
  }

  handleDragEnter = () => {
    this.setState({ hovered: true });
    this.changeViewTimer = setTimeout(() => {
      this.handleChangeView()
    }, 100);
  }

  allowDrop = (event) => {
    event.preventDefault();
  }

  handleOnMouseEnter = () => {
    if ( this.props.navigation.drag ){
      this.handleDragEnter()
    }
  }
  handleOnMouseLeave = () => {
    this.handleDragLeave()
  }


  render() {
    let hover = this.state.hovered ? "view-selector-card-on" : "";
    let showBorders = _.get(this.props.navigation, 'drag', false);
    let toolTip = _.find(this.props.toolTips, { key: `view-card-${this.props.item.key}` }).message;
    let active = this.props.navigation.activeView === this.props.item.key ? "active" : ""
    // borders needs to be a bool prop type.
    showBorders = !!showBorders;
    return (
      <div 
        aria-label={toolTip} 
        data-balloon-pos="right"
        className={`ViewSelectorCard clickable ${hover} ${active}`}>
        <div className="img-container">
          <div className="img">
            <PreviewCanvasTwo view={this.props.item} isThumbnail={true} borders={showBorders} />
          </div>
        </div>
        <div className="name">{this.props.item.name}</div>
        {/* selector shield is preventing events being triggered on children */}
        <div className="selector-shield" style={{backgroundColor:"transparent", height: "100%", width: "100%", position:"absolute"}}
          onClick={this.handleChangeView}
          onDrop={this.handleDrop}
          onDragLeave={this.handleDragLeave}
          onDragEnter={this.handleDragEnter}
          onDragOver={this.allowDrop}
          onMouseEnter={this.handleOnMouseEnter}
          onMouseLeave={this.handleOnMouseLeave}
        />
      </div>
    );
  }
}


const outputActions = {
  setView,
  setActiveTab,
  setActiveLocation,
  addPendingLocationToLocations,
  dragItem,
  setPendingLocation
};

function mapStateToProps(state) {
  return {
    navigation: state.navigation,
    user: state.user,
    baseProduct: state.baseProduct,
    customConfig: state.customConfig,
    toolTips: state.toolTips
  }
}

export default connect(mapStateToProps, outputActions)(ViewSelectorCard);
