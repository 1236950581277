import { RestService } from '../lib';


/**
 * Service Endpoint Implementation
 */
export default class CustomProductsService extends RestService {

  constructor(opts = null) {
    super(opts);
    this.serviceName = 'customProducts';
    this.routePath = 'custom-products';
  }
}


