import _ from "lodash"; 

class NavigationValidator {
  validate(baseProduct){
    let text = false; 
    let art = false; 
    _.each(baseProduct.locations, (loc) => {
      _.each(loc.allowedEntities, (ent) => {
        let type = ent.type; 
        if (type === "custom-art"){
          art = true; 
        }
        if (type === "custom-text" || type === "player-number"  || type === "player-name" ){
          text = true; 
        }
      }); 
    }); 
    let fabrics = _.get(baseProduct, "fabrics", 0); 
    let fabric = (fabrics[0] === "0" ); 
    return {text, art, fabric}; 
  }


}

const navigationValidator = new NavigationValidator(); 
export default navigationValidator; 
