import { Component } from 'react';
import { connect } from 'react-redux'; 
import { deleteModal } from '../../../redux/reducers/modal/modalActions'; 
import { setActiveTab } from '../../../redux/reducers/navigation/navigationActions'; 
import { deleteLocation } from '../../../redux/reducers/customConfig/customConfigActions'; 
import helpers from "../../../helpers"; 
import _ from 'lodash'; 

class KeyboardActions extends Component {
  constructor(props){
    super(props); 

    this.handleKeypress = this.handleKeypress.bind(this);
    this.removeLocation = this.removeLocation.bind(this); 
  }
  

  componentDidMount(){
    window.addEventListener('keydown', this.handleKeypress); 
  }

  componentWillUnmount(){
    window.removeEventListener('keydown', this.handleKeypress); 
  }

  handleKeypress(e){
    let key = _.get(e, "key", null);     
    switch(key){
      case "Delete": 
        this.deleteLocation(); 
        return true; 
      default :
        return false; 
    }
  }

  deleteLocation() {
    let correctTab = (this.props.navigation.activeTab.tab === "add-art" || this.props.navigation.activeTab.tab === "add-text"); 
    let correctPage = (this.props.navigation.activeTab.page === "edit"); 
    let notInAModal = (!this.props.modal.isActive); 
    if (notInAModal && correctTab && correctPage && !this.props.navigation.inputHasFocus){
      let activeLocation = helpers.locations.getCustomConfigLocationEntityData(this.props.navigation.activeLocation);
      if (activeLocation) {
        let name = _.get(activeLocation, "entity.type");
        name = name.replace("-", " ")
        this.props.deleteModal (() => this.removeLocation(), name)
      }
    }
  } 

  
  removeLocation() {
    let location = helpers.locations.getCustomConfigLocationEntityData(this.props.navigation.activeLocation);  //need to get the current locaton ?? helper
    let { locations } = this.props.customConfig;
    this.props.deleteLocation(location, locations);
    let tabObj = { tab: this.props.navigation.activeTab.tab, page: "add" }
    this.props.setActiveTab(tabObj);
  }

  render() {
    return (null); 
  }
}

function mapStateToProps(state){
  return{
    navigation: state.navigation, 
    customConfig: state.customConfig, 
    modal: state.modal
  }
}

const reduxActions = {
  deleteModal,
  deleteLocation,
  setActiveTab
}

export default connect (mapStateToProps, reduxActions)(KeyboardActions); 