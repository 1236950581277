import { CHANGE_DECORATION_GRID, EDIT_DECORATION_TYPE, SET_ART_SUBTAB, SET_DECORATION_SUBTAB, SET_DECORATION_TYPE, SET_PREV_LOCATION_DETAILS, UPDATE_ART_NAME, UPDATE_ART_COLORS, PLAYER_NAME_NUMBER_UPDATED } from '../../actions/actionTypes';

const initialState = {
  canvasGrid: true,
  selectedSubTab: "",
  editDecorationType: "",
  selectedArtTab: "",
  selectedDecType: "",
  prevLocation: "",
  customArtName: "",
  customArtColors: "",
  playerNameNumber: false,

};

export default function decorationReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_DECORATION_GRID: return Object.assign({}, state, action.decoration);
    case EDIT_DECORATION_TYPE: return Object.assign({}, state, action.decoration);
    case SET_DECORATION_SUBTAB: return Object.assign({}, state, action.decoration);
    case SET_ART_SUBTAB: return Object.assign({}, state, action.decoration);
    case SET_DECORATION_TYPE: return Object.assign({}, state, action.decoration);
    case SET_PREV_LOCATION_DETAILS: return Object.assign({}, state, action.decoration);
    case UPDATE_ART_NAME: return Object.assign({}, state, action.decoration);
    case UPDATE_ART_COLORS: return Object.assign({}, state, action.decoration);
    case PLAYER_NAME_NUMBER_UPDATED: return Object.assign({}, state, action.decoration);
    default:
      return state;
  }
}
