import React, { Component } from 'react'; 
import { CSVLink } from "react-csv";
import FileUploader from '../../../global/fileUploader/fileUploader';

export default class ATCTemplateManager extends Component {
  render() {
    return (
    <div className="file-upload-logic w-100">
        <FileUploader 
          fileUpload="roster"
          toolTip={this.props.uploadToolTip}
          quantityOrPlayer={this.props.quantityOrPlayer}
          useTiny={true} 
          allowedFiles={['xls', 'xlsx', 'csv']}
          tinyMessage="Upload Roster"
        />
      <div className="d-flex align-items-center, justify-content-center">
        <div className="missing-roster-download-template">
          <svg xmlns="http://www.w3.org/2000/svg" width="16.33" height="10.886" viewBox="0 0 16.33 10.886">
            <path id="path" d="M419.732,355.082a3.224,3.224,0,0,1,2.245,1.089,3.36,3.36,0,0,1,.953,2.313,3.088,3.088,0,0,1-1.021,2.381,3.6,3.6,0,0,1-2.381,1.021h-8.845a3.706,3.706,0,0,1-2.858-1.225A4.2,4.2,0,0,1,406.6,357.8a3.981,3.981,0,0,1,1.021-2.722,3.8,3.8,0,0,1,2.586-1.293,6.063,6.063,0,0,1,1.905-2.041,4.623,4.623,0,0,1,2.654-.748,4.968,4.968,0,0,1,4.967,4.082Zm-1.565,2.041h-2.041V354.4H413.4v2.722h-2.041l3.4,3.4Z" transform="translate(-406.6 -351)" fill="#9b9b9b" />
          </svg>
        </div>
          <div aria-label={this.props.downloadToolTip} data-balloon-pos="down"  style={{ marginLeft: "5px", marginTop: "13px" }}>
            <CSVLink filename={this.props.fileName} data={this.props.rosterData}>Download Template</CSVLink>
        </div>
      </div>
    </div>); 
  }
}
