import React, { Component } from "react";
import PropTypes from "prop-types";
import Cell from "./cell";
import CheckmarkSVG from "../../../assets/icons/CheckmarkSVG";
import PencilSVG from "../../../assets/icons/PencilSVG";
import _ from "lodash";
import TrashSVG from "../../../assets/icons/Trash";
import helpers from "../../../helpers";
import { updateCell, addRow, removeRow, changeSetting, deleteAll } from "../../../redux/reducers/customConfig/customConfigActions";
import { connect } from "react-redux";
import PlusSVG from "../../../assets/icons/PlusSVG";
import RemoveEyeSVG from "../../../assets/icons/RemoveEyeSVG";
import PreviewSVG from "../../../assets/icons/PreviewSVG";
const passRef = (fn, ...args) => ({ target: { value: v } }) => fn(args, v);
let icon = '';
export class Table extends Component {

  static propTypes = {
    config: PropTypes.object.isRequired,
    tableData: PropTypes.array.isRequired,
    isEditable: PropTypes.bool,
    startInEditMode: PropTypes.bool,
    showToggleEditButton: PropTypes.any
  };

  static defaultProps = {
    config: null,
    tableData: null,
    isEditable: false,
    startInEditMode: false,
    showToggleEditButton: true
  };


  constructor(props) {
    super(props);

    this.state = {
      isEditable: this.props.isEditable,
      editMode: this.props.startInEditMode,
      showToggleEditButton: this.props.showToggleEditButton,
      playerButton: 'Add Or Edit Player',
    }
    this.makeEditable = this.makeEditable.bind(this);
    this.deleteTableRow = this.deleteTableRow.bind(this);
  }

  makeEditable = () => {
    if (!this.props.isSummaryTable && this.props.modal.isActive === false) {
      this.setState({ editMode: !this.state.editMode });
      if (this.state.playerButton === 'Save Changes') {
        this.state.playerButton = 'Add Or Edit Player';
      } else {
        this.state.playerButton = 'Save Changes';
      }
    }
  };

  renderEditButton = () => {
    let icon = null;
    let editToolTip = _.find(this.props.toolTips, { key: "edit-roster-button" }).message;
    let confirmToolTip = _.find(this.props.toolTips, { key: "confirm-roster-button" }).message;
    if (this.state.editMode) {
      icon = <div aria-label={confirmToolTip} data-balloon-pos="left" className="icon expanded"><CheckmarkSVG width="20" height="20" /></div>;
    } else {
      icon = <div aria-label={editToolTip} data-balloon-pos="left" className="icon collapsed"><PencilSVG width="20" height="20" /></div>;
    }

    return (
      <div className="tbl-edit-actions">
        <button
          className="btn btn-default"
          onClick={this.makeEditable} >
          {icon}
        </button>
      </div>
    );
  };

  buildColConfigFromRowData(rows) {
    return _.chain(rows)
      .map(_.keys)
      .flatten()
      .uniq()
      .compact()
      .value();
  }

  getHeaderName(item) {
    return _.get(item, 'headerName') || _.get(item, 'name') || item;
  }

  genClassName = (i) => {
    return "tbl-header tbl-col-" + i + " tbl- tbl-cell tbl-cell-view " + i;
  }

  addRow = (tableData, keyOrder) => {
    const emptyLastRow = keyOrder.reduce((acc, cur) => acc = { ...acc, [cur]: "" }, {});
    return [...tableData, emptyLastRow];
  }

  buildTrash = () => {
    return (
      <div className="tbl-trash">
        <div onClick={this.props.deleteAll}>
          <TrashSVG height={15} fill={"Gray"} />
          <span>Clear All</span>
        </div>
      </div>
    )
  }

  buildHeaderColumns = (colConfig) => colConfig.map((el, i) => {
    return (
      <div className={this.genClassName(i)} key={"header-" + i}>
        {this.getHeaderName(el)}
      </div>
    );
  });

  getValue(columnKey, row) {
    const foundMatch = columnKey.find(option => row[option]);
    return row[foundMatch ? foundMatch : columnKey];
  }

  previewSelection(row) {
    this.setState({ selectedOne: true });
    this.setState({ rowNum: row })
    let rowVal = row;
    let tableData = _.get(this.props.customConfig, ["rosters", 0, "items"], []);
    if (tableData[row].preview === false) {
      tableData = tableData.map(data => ({ ...data, preview: false }))
      this.props.customConfig.rosters[0].items = tableData
      let playerData = tableData[row]
      let previewVal = true
      let updateRow = ["preview", rowVal];
      this.props.updateCell(updateRow, previewVal);
      this.renderIcon(tableData, row)
      let locations = _.get(this.props.customConfig, ["locations"], []);
      locations.forEach(item => {
        if (item.entity.type === 'player-name') {
          this.props.changeSetting(this.props.customConfig.locations, item.code, { setting: "value", value: playerData.name });
        } else if (item.entity.type === 'player-number') {
          this.props.changeSetting(this.props.customConfig.locations, item.code, { setting: "value", value: playerData.number });
        }
      });
    } else {
      tableData = tableData.map(data => ({ ...data, preview: false }))
      this.props.customConfig.rosters[0].items = tableData
      // let previewVal = false
      // updateRow = ["preview", rowVal];
      // this.props.updateCell(updateRow, previewVal);
      // this.renderIcon(tableData, row)
      let locations = _.get(this.props.customConfig, ["locations"], []);
      locations.forEach(item => {
        if (item.entity.type === 'player-name') {
          this.props.changeSetting(this.props.customConfig.locations, item.code, { setting: "value", value: '' });
        } else if (item.entity.type === 'player-number') {
          this.props.changeSetting(this.props.customConfig.locations, item.code, { setting: "value", value: '' });
        }
      })
    }

  }
  renderIcon(data, row, last) {
    // console.log(data);
    switch (true) {
      case data.length > 0 && data[row] && ((data[row].name != '' || data[row].number != '') && (data[row].name != null || data[row].number != null)) && data[row].preview === true && last === false:
        icon = <PreviewSVG className="preview" size={30} />
        break;

      case data.length > 0 && data[row] && ((data[row].name != '' || data[row].number != '') && (data[row].name != null || data[row].number != null)) && data[row].preview === false && last === false:
        {
          icon = <RemoveEyeSVG className="preview" size={30} />
        }
        break;

      case data.length > 0 && data[row] && ((data[row].name != '' || data[row].number != '') && (data[row].name != null || data[row].number != null)) && last === false:
        {
          icon = <RemoveEyeSVG className="preview" size={30} />
        }
        break;
      case data.length > 0 && data[row] && ((data[row].name != '' || data[row].number != '')):
        icon = <RemoveEyeSVG className="preview" size={30} />;
        break;
      default: {
        icon = '   ';
      }
        break;
    }
  }


  renderPreview = (updateCell, deleteRow, addRow, isLastRow, keys, sizeOpts) => {

    return (cellData, columnIndex, rowIndex, columnKey) => {

      const onChange = isLastRow ? passRef(addRow, columnKey, keys) : passRef(updateCell, columnKey, rowIndex);
      const defaultOpt = cellData ? true : <option value="" disabled select="true"></option>;
      let tableData = _.get(this.props, ["rosters", 0, "items"], []);
      // if(!initial && rowIndex === 0 ) {
      //   sizeValue = cellData;
      // }
      return (
        // <span className="tbl-complex" key={columnIndex}>
        <span>
          <div
            type="button"
            onClick={async () => {
              await this.previewSelection(rowIndex)
            }} >
            {/* { tableData[this.state.rowNum] ? 
               <PreviewSVG className="preview" size={30} /> :
                 <RemoveEyeSVG className="preview" size={30} />
                } */}
            {this.renderIcon(tableData, rowIndex, isLastRow)}
            {icon}
          </div>
        </span>
      )
    }
  };

  defaultPreview(row) {
    let tableData = _.get(this.props.customConfig, ["rosters", 0, "items"], []);
    let deleteData = tableData
    let previewLength = 0;
    deleteData.map((data, i) => {
      if (data && data.preview && i != row) {
        previewLength++
      }
    })
    let locations = _.get(this.props.customConfig, ["locations"], []);
    locations.forEach(item => {
      if (item.entity.type === 'player-name') {
        if (previewLength == 0) {
          this.props.changeSetting(this.props.customConfig.locations, item.code, { setting: "value", value: '' });
        }
      } else if (item.entity.type === 'player-number') {
        if (previewLength == 0) {
          this.props.changeSetting(this.props.customConfig.locations, item.code, { setting: "value", value: '' });
        }
      }
    });
  }

  deleteTableRow = (rowIndex) => {
    this.props.removeRow([rowIndex]);
  }

  buildCell = (row, rowIndex, keyOrder, isLastRow) => {
    const className = `tbl-row tbl-row-${rowIndex} tbl-light`;
    // const onCellClick = this.state.editMode ? () => { } : this.makeEditable;
    return (
      <div className={className}>
        {
          keyOrder.map((columnKey, columnIndex) => {
            const placeholder = _.get(this.props.config, `columns[${columnIndex}].placeholder`);
            const columnConfig = this.props.config.columns.find(col => col.key === columnKey);
            const hasMultipleOptions = typeof columnKey === "object";
            const cellData = hasMultipleOptions ? this.getValue(columnKey, row) : row[columnKey];
            let tableData = _.get(this.props.customConfig, ["rosters", 0, "items"], []);
            if (this.props.navigation.activeTab.tab === "names-and-numbers" && !this.props.modal.isActive && columnIndex === 0 && !this.state.editMode) {
              return <span>
                <div
                  type="button"
                  onClick={async () => {
                    await this.previewSelection(rowIndex)
                  }} >
                  {this.renderIcon(tableData, rowIndex, isLastRow)}
                  {icon}
                </div>
              </span>
            }
            if (columnConfig.key === 'price') {
              return (
                <div className="price tbl-col-5">
                  $ {cellData ? Number(cellData).toFixed(2) : 0.00}
                </div>
              )
            }
            if (this.props.navigation.activeTab.tab === "names-and-numbers" && !this.props.modal.isActive && columnConfig.key === 'delete' && !this.state.editMode) {
              return (
                <div type="button" className="trash-icon-padding" onClick={isLastRow ? () => { } : this.deleteTableRow.bind(this, rowIndex)}>
                  {isLastRow ? (
                    <TrashSVG height="0px" width="15px" fill={"none"} />
                  )
                    : (
                      <TrashSVG onClick={async () => { await this.defaultPreview(rowIndex) }} height="18px" width="18px" fill={"black"} />
                    )
                  }
                </div>

              );
            }
            else {
              return (
                <React.Fragment key={columnIndex}>
                  <Cell
                    cellData={cellData}
                    columnKey={columnKey}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    editMode={this.state.editMode}
                    isLastRow={isLastRow}
                    keyOrder={keyOrder}
                   config={this.props.config}
                    placeholder={placeholder}
                    columnConfig={columnConfig}
                  />
                </React.Fragment>
              )
            }
          })
        }
      </div>
    )
  }

  render() {
    let tableData = this.props.tableData || [];
    tableData = _.map(tableData, tableRow => { 
      if(tableRow.qty === "" || tableRow.qty === null || tableRow.qty === undefined || tableRow.qty < 1 || isNaN(tableRow.qty)){
        tableRow.qty = 1;
      }
      if(tableRow.number === null || tableRow.number === undefined || tableRow.number < 0){
        tableRow.number = 0;
      }
      return tableRow;
    })
    let colConfig = _.get(this.props, 'config.columns');
    if (!colConfig) colConfig = this.buildColConfigFromRowData(tableData);
    let keyOrder = _.map(colConfig, v => _.get(v, 'key') || v);
    tableData = this.state.editMode ? this.addRow(tableData, keyOrder) : tableData;
    const adjustedIndex = this.state.editMode ? 1 : 0;
    const lastRowIndex = tableData.length - adjustedIndex;
    const isLastRow = (i) => i === lastRowIndex;
    let productPrice = 0;
    let rosterSku, sku, skuPrice, rosterSkuPrice, defaultSkuPrice;
    let data = this.props.isSummaryTable ? [...tableData, {}] : tableData;

    if (data && data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        // Finding the sku from the base product based on the size for custom sub, size and color for stock dec
        if (this.props.baseProduct.configurationType === 'custom-sub')
          sku = _.find(this.props.baseProduct.skus, x => (x.size === data[i].size));
        else if (this.props.baseProduct.configurationType === 'stock-dec')
          sku = _.find(this.props.baseProduct.skus, x => (x.size === data[i].size) && (x.color === this.props.customConfig.color));

        // Finding the default sku price from base product prices list
        if (sku) {
          skuPrice = _.find(this.props.baseProduct.baseProductPrices, p => p.sku === sku.sku);
          productPrice = skuPrice.price;
          let price = {}
          price.price = productPrice;
          data[i].price = productPrice;
        }
        // console.log("Product Price:", productPrice);
      }
    }
    // console.log("Data:: ", JSON.stringify(data));

    // let rosterChange = false;
    // this.props.customConfig.locations.forEach(item => {
    //   if (item.entity.type === 'player-name' || item.entity.type === 'player-number') {
    //     rosterChange = true;
    //     return;
    //   }
    // })
    let sizeorRosterTab = this.props.modal.isActive === true && this.props.modal.message === 'ItemTotalModal' ? false : true
    // console.log("roster in table.js",rosterChange)
    // + (this.state.editMode ? "edit-mode" : "view-mode")
    return (
      <div aria-label={this.props.toolTip} data-balloon-pos="up" className="tbl-table-wrapper">
        <div className={"tbl-table"}>
          <div className="tbl-row tbl-row-header tbl-light">
            {this.buildHeaderColumns(colConfig)}
          </div>
          {
            data.map((row, rowIndex) => { //build each row

              return (
                <React.Fragment key={rowIndex}>
                  {
                    this.buildCell(row, rowIndex, keyOrder, isLastRow(rowIndex))
                  }
                </React.Fragment>
              )
            })
          }

        </div>
        {!this.props.itemTotal  && this.props.navigation.activeTab.tab === 'names-and-numbers' ?
          <div className="row">
            <div className="col-12 d-flex justify-content-center roster-add-button">
              <button
                style={{ border: "1px solid $ub-grey--200" }}
                onClick={this.makeEditable}
                className="custom-white-button">
                {/* ADD ANOTHER PLAYER */}
                {this.state.playerButton}
              </button>
            </div>
          </div> : <div></div>}
        {/* x{this.state.editMode && tableData.length > 0 ? this.buildTrash() : null} */}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    customConfig: state.customConfig,
    toolTips: state.toolTips,
    modal: state.modal,
    navigation: state.navigation,
    baseProduct: state.baseProduct
  }
}
const actions = { addRow, removeRow, updateCell, deleteAll, changeSetting };

export default connect(mapStateToProps, actions)(Table);