import _ from 'lodash';
import helpers from '../helpers';



const TRANSIENT_CUSTOM_CONFIG_FIELDS = 'id name revision configId webRef decorationDocId isSavedDesign isOrderedDesign autoSavedAt createdAt updatedAt deletedAt'.split(' ');

class SerializationHelper {

  get TransientCustomConfigFields() {
    return TRANSIENT_CUSTOM_CONFIG_FIELDS;
  };

  // reduxState is data currently in redux
  // dbConfigModel should be the DB model of the data sent back from the api
  // dbConfigModel.data <=> customConfig
  async prepareAddToCartMessageData(baseProd, customConf, dbConfigModel) {
    let customConfig = customConf || {};
    dbConfigModel = dbConfigModel || {};
    dbConfigModel.data = dbConfigModel.data || {};

    let style = baseProd.configurationType === 'custom-sub' ? baseProd.legacyStyleNum : baseProd.styleNum || null;
    let design = baseProd.configurationType === 'custom-sub' ? baseProd.designNum : null || null;
    let configId = dbConfigModel.configId;

    let colors = _.get(customConfig, 'color', null);
    let firstPreview = _.first(_.get(dbConfigModel, 'previews')) || null;
    let previewUrl = _.get(firstPreview, 'url', null);
    let customData = _.get(baseProd, "launchContext.customData", null);
    let rosterData = _.get(customConfig, 'rosters.0', null);
    let isSizesorRoster = _.get(customConfig,'isPlayerRoster', false);

    let decorationPricing = _.get(customConfig, 'decorationPricing');
    let totPriceAdjust = _.get(decorationPricing, 'totalCents', 0);
    let sizeQuantities = isSizesorRoster ? helpers.roster.getSizeQuantitiesData1(rosterData, baseProd) : helpers.roster.getSizeQuantitiesData2(rosterData, baseProd);
    let decorations = _.chain(decorationPricing && decorationPricing.entities)
      .map((v, k) => {
        if (!v) { return null; }
        return {
          decorationId: k,
          entityType: v.entityType,
          decorationType: v.decorationMethod,
          colors: colors,
          previewUrl: null,
          priceAdjust: v.priceCents
        };
      })
      .compact()
      .value();

    let iframeData = {
      style,
      design,
      configId,
      colors,
      previewUrl,
      customData,
      totPriceAdjust,
      sizeQuantities,
      decorations
    };

    return iframeData;
  }

  async prepareApiSaveData(baseProduct, customConfig, previews, isSavedDesign, overwriteExisting, isOrderedDesign, isMasterConfig) {

    // create a copy of the config so that we can modify it before sending it to the API
    customConfig = _.cloneDeep(customConfig);

    if (!overwriteExisting) {
      // clear out all ID properties so we can create a new record
      customConfig.id = null;
      customConfig.configId = null;
      customConfig.webRef = null;
    }

    let styleNum = _.get(baseProduct, 'styleNum', null);
    let name = _.get(customConfig, 'name', null);
    let configId = _.get(customConfig, 'configId', null);


    _.each(this.TransientCustomConfigFields, k => {
      _.unset(customConfig, k);
    });

    // do not save these properties with the inner config data.
    delete customConfig.id;
    delete customConfig.name;
    delete customConfig.configId;
    delete customConfig.webRef;
    delete customConfig.autoSavedAt;

    let filledLocationEntities = helpers.locations.getAllCustomConfigLocations();
    let assets = _.map(filledLocationEntities, customLoc => {
      let productLoc = helpers.locations.getViewLocation(customLoc.view, customLoc.code);
      let entity = (customLoc && customLoc.entity) || {};
      let entityColorInfo = helpers.locations.getLocationEntityColorInfo(entity);
      let textValue = helpers.locations.isTextEntityType(entity.type) ? entity.value : null;
      let productLocEntitySize = helpers.locations.getViewLocationEntitySize(customLoc.view, customLoc.code, entity.type, entity.decorationMethod, entity.size);
      let sizeName = _.get(productLocEntitySize, 'name', null);
      let rotation = _.get(entity, 'bounds.r', null);
      let userNotes = _.get(entity, 'notes', null);
      let textPatternId = _.get(entity, 'textPatternid', null);
      let textPatternColors = _.get(entity, 'capabilities.textPatternColors', null);
      if (_.isFinite(rotation)) { rotation = Math.round(rotation); }
      else { rotation = null; }

      let asset = {
        viewKey: customLoc.view,
        locationCode: customLoc.code,
        locationName: productLoc.name,
        entityType: entity.type,
        decorationMethod: entity.decorationMethod,
        fontKey: entity.font,
        fontName: helpers.loadedDefs.getFontName(entity.font),
        textEffectKey: entity.textEffect,
        textEffectName: helpers.loadedDefs.getTextEffectName(entity.textEffect),
        sizeKey: entity.size,
        sizeName: sizeName,
        textValue: textValue,
        rotation: rotation,
        url: entity.url,
        userNotes: userNotes,
        textPatternColors: textPatternColors,
        textPatternId: textPatternId,
        ...entityColorInfo
      };
      return asset;
    });
    return { styleNum, configId, name, data: customConfig, previews, assets, isSavedDesign, isOrderedDesign, isMasterConfig };
  }
  async prepareAddToCartApiSaveData(baseProduct, customConfig, previews, isSavedDesign, isOrderedDesign, overwriteExisting) {

    // create a copy of the config so that we can modify it before sending it to the API
    customConfig = _.cloneDeep(customConfig);

    if (!overwriteExisting) {
      // clear out all ID properties so we can create a new record
      customConfig.id = null;
      customConfig.configId = null;
      customConfig.webRef = null;
    }

    let styleNum = _.get(baseProduct, 'styleNum', null);
    let name = _.get(customConfig, 'name', null);
    let configId = _.get(customConfig, 'configId', null);

    _.each(this.TransientCustomConfigFields, k => {
      _.unset(customConfig, k);
    });

    // do not save these properties with the inner config data.
    delete customConfig.id;
    delete customConfig.name;
    delete customConfig.configId;
    delete customConfig.webRef;
    delete customConfig.autoSavedAt;

    let filledLocationEntities = helpers.locations.getAllCustomConfigLocations();
    let assets = _.map(filledLocationEntities, customLoc => {
      let productLoc = helpers.locations.getViewLocation(customLoc.view, customLoc.code);
      let entity = (customLoc && customLoc.entity) || {};

      let entityColorInfo = helpers.locations.getLocationEntityColorInfo(entity);
      let textValue = helpers.locations.isTextEntityType(entity.type) ? entity.value : null;

      let productLocEntitySize = helpers.locations.getViewLocationEntitySize(customLoc.view, customLoc.code, entity.type, entity.decorationMethod, entity.size);
      let sizeName = _.get(productLocEntitySize, 'name', null);
      let rotation = _.get(entity, 'bounds.r', null);
      if (_.isFinite(rotation)) { rotation = Math.round(rotation); }
      else { rotation = null; }

      let asset = {
        viewKey: customLoc.view,
        locationCode: customLoc.code,
        locationName: productLoc.name,
        entityType: entity.type,
        decorationMethod: entity.decorationMethod,
        fontKey: entity.font,
        fontName: helpers.loadedDefs.getFontName(entity.font),
        textEffectKey: entity.textEffect,
        textEffectName: helpers.loadedDefs.getTextEffectName(entity.textEffect),
        sizeKey: entity.size,
        sizeName: sizeName,
        textValue: textValue,
        rotation: rotation,
        url: entity.url,
        ...entityColorInfo
      };
      return asset;
    });

    return { styleNum, configId, name, data: customConfig, previews, assets, isSavedDesign, isOrderedDesign };
  }

}

// export a static singleton instance
const serializationHelper = new SerializationHelper();
export default serializationHelper;


