import React from "react";
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Tooltip } from 'reactstrap'
import BaseInputComponent from "../baseInputComponent/baseInputComponent";
import NewColorSwatch from "./NewColorSwatch";
import NewColorSwatchGroup from "./NewColorSwatchGroup";

const modifiers = {
    setMaxHeight: {
        enabled: true,
        order: 890,
        fn: (data) => {
            return {
                ...data,
                styles: {
                    ...data.styles,
                    overflow: 'auto',
                    maxHeight: 300,
                },
            };
        },
    },
}

export default class NewColorPicker extends BaseInputComponent {

    // NOTE: if you extend propTypes in child component class, you will get a react warning.
    static get propTypes() {
        return {
            // eslint-disable-next-line
            ...super.propTypes,
            colorOptions: PropTypes.array
        }
    };

    static get defaultProps() {
        return {
            ...super.defaultProps,
            colorOptions: [],
            dropdownHeader: 'Select a color'
        }
    };

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.onSwatchSelected = this.onSwatchSelected.bind(this);
        //this.onSwatchHovered = this.onSwatchHovered.bind(this);

        this.state = {
            ...this.state,
            hoverColor: null,
            isDropdownOpen: !!this.props.isOpen
        };
    }

    toggle() {
        this.props.toggleDelete(!this.state.toggle);
        this.setState({
            isDropdownOpen: !this.state.isDropdownOpen
        });
    }

    onSwatchSelected(colorKey) {
        this.setValue(colorKey);
    }

    onSwatchHovered(hoverColorDef, groupIndex) {
        if (this.state.hoverColor !== hoverColorDef) {
            this.setState({ hoverColor: hoverColorDef, groupIndex: groupIndex });
            this.setHover(hoverColorDef)
        }
    }

    renderChildComponent() {
        // console.log("Id:", this.props);
        let colorOptions = this.props.colorOptions || [];
        let isStock = this.props.isStock;

        let chromagearStyle = null;
        if (this.props.chromagear) {
            if (this.props.id == 0) {
                chromagearStyle = {
                    width: "208px",
                    height: "40px"
                }
            } else {
                chromagearStyle = {
                    width: "114px",
                    height: "40px"
                }
            }
        }

        let styleHeader = {};
        styleHeader.width = '126px';
        // styleHeader.height = '22px';
        styleHeader.fontSize = '22px';
        styleHeader.fontFamily = 'Slate Std Regular';
        styleHeader.fontWeight = 'Bold';
        styleHeader.color = '#000000';
        styleHeader.alignment = 'Left';

        let styleSubHeader = {};
        //styleSubHeader.float = '235px';
        styleSubHeader.fontFamily = 'Forza';
        styleSubHeader.fontSize = '14px';
        styleSubHeader.color = '#000000';
        styleSubHeader.opacity = 50;

        let layerName = '';
        if (this.props.layerName !== '') {
            layerName = this.props.layerName;
            layerName = layerName.replace(/[\(].+?[\)]/g,'');
            layerName = layerName.replace('Layer', '');
            layerName = layerName.trim();
        }

        let colorName = '';
        if (this.props.value) {
            colorName = this.props.value.name ? this.props.value.name : null;
        }

        return (
            <div className={`new-color-picker ${this.props.disabled ? "disabled-color-picker" : ""}`}>
                <Dropdown isOpen={this.state.isDropdownOpen} toggle={this.toggle}>
                    <div aria-label={this.props.toolTip} data-balloon-pos={this.props.toolTipDirection} className={`color-picker-container`}>
                        <DropdownToggle className={`dropdown-toggle-button`} color='secondary' outline>
                            <div className={`dropdown-toggle-button-content`}>
                                <NewColorSwatch className={'dropdown-toggle-color-swatch' + this.props.id} customFooter={this.props.customFooter} customStyle={this.props.chromagear} value={this.state.value} isDisabled={this.props.disabled} watchProps={true} />
                            </div>
                            {/*<div className="carent-container">
                                <div className="caret"></div>
                            </div>*/}
                        </DropdownToggle>
                    </div>
                    {this.props.chromagear ?
                        <div>
                            <div className="col-12">
                                <DropdownMenu modifiers={modifiers} className="dropdown-menu fancy-scrollbar">
                                    <DropdownItem>
                                        <div className="col-12">
                                            <div className="row ">
                                                <div className="col-6 pl-0">
                                                    <p className="design-tab-heading">{this.props.dropdownHeader} {layerName}</p>
                                                </div>
                                                <div className="col-6 selected-design-name text-right pt-2 pr-0">
                                                    SELECTED: {colorName}
                                                </div>
                                            </div>
                                        </div>
                                    </DropdownItem>
                                    <div className="chromagear-color-swatch-group1 fancy-scrollbar">
                                        {colorOptions.map((e, i) => {
                                            return (
                                                <div key={e.name + this.props.uniqueKey}>
                                                    <DropdownItem header>
                                                        {e.name}
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <NewColorSwatchGroup
                                                            dropdownName={this.props.type}
                                                            value={this.state.value}
                                                            colorOptions={e.colors}
                                                            watchProps={true}
                                                            hint={this.props.hoverColorName}
                                                            onChange={this.onSwatchSelected}
                                                            //onHover={color => this.onSwatchHovered(color, i)}
                                                        />
                                                    </DropdownItem>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {this.state.isDropdownOpen ?
                                        <div>
                                            {/*<div className="chromagear-hover-color-tip">
                                                {this.state.hoverColor ?
                                                    <Tooltip tooltipShowDelay={0} data-toggle="tooltip" data-placement="right" title={this.state.hoverColor.name} interactive>
                                                    </Tooltip>
                                                    :null}
                                            </div>*/}
                                        </div>
                                        : null}
                                </DropdownMenu>
                            </div>
                            <div className="col-12">
                                <div className="layerName-style">{layerName.toUpperCase()}</div>
                                <div className="colorName-style">{colorName.toUpperCase()}</div>
                            </div>
                        </div>
                        : <DropdownMenu className="dropdown-menu">
                            <DropdownItem header>
                                {this.props.dropdownHeader}
                            </DropdownItem>
                            <DropdownItem className="color-picker-hover">
                                { isStock ?
                                    <div className="chromagear-color-swatch-group1 fancy-scrollbar">
                                        <NewColorSwatchGroup
                                            dropdownName={this.props.type}
                                            value={this.state.value}
                                            colorOptions={colorOptions}
                                            watchProps={true}
                                            hint={this.props.hoverColorName}
                                            onChange={this.onSwatchSelected}
                                            //onHover={e => this.onSwatchHovered(e)}
                                        />
                                    </div> :
                                    <div className="chromagear-color-swatch-group1 fancy-scrollbar">
                                        {colorOptions.map((e, i) => {
                                            return (
                                                <div key={e.name + this.props.uniqueKey}>
                                                    <DropdownItem header>
                                                        {e.name}
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <NewColorSwatchGroup
                                                            dropdownName={this.props.type}
                                                            value={this.state.value}
                                                            colorOptions={e.colors}
                                                            watchProps={true}
                                                            hint={this.props.hoverColorName}
                                                            onChange={this.onSwatchSelected}
                                                            //onHover={color => this.onSwatchHovered(color, i)}
                                                            title="abc"
                                                        />
                                                    </DropdownItem>
                                                </div>
                                            )
                                        })}
                                        {this.state.isDropdownOpen ?
                                            <div>
                                                <div className="chromagear-hover-color-tip">
                                                    {this.state.hoverColor ?
                                                        <Tooltip tooltipShowDelay={0} data-toggle="tooltip" data-placement="right" title={this.state.hoverColor.name} interactive>
                                                        </Tooltip>
                                                        :null}
                                                </div>
                                            </div>
                                            : null }
                                    </div>
                                }
                            </DropdownItem>
                        </DropdownMenu>}
                </Dropdown>
            </div>
        );
    }
}

