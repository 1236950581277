import { RestService } from '../lib';


/**
 * Service Endpoint Implementation
 */
export default class BaseProductsService extends RestService {

  constructor(opts = null) {
    super(opts);
    this.serviceName = 'baseProducts';
    this.routePath = 'builder/base-products';
  }

}


