import * as types from "../../actions/actionTypes"; 

export function updateArtAssets(assets){
  return {type: types.UPDATE_ART_ASSETS, assets}; 
}
export function updateSystemCategories(categories){
  return {type: types.UPDATE_ART_CATEGORIES, categories};
}

export function changeCatalogNavigation(nav){
  return {type: types.CHANGE_CATALOG_NAVIGATION, nav}; 
}

export function editSystemArtSearchTerm(searchTerm){
  return {type: types.EDIT_SEARCH_TERM, searchTerm}; 
}

export function toggleSystemArtResults(isActive){
  return {type: types.TOGGLE_SYSTEM_ART_ITEMS, isActive};
}

export function updateQuickLink(link){
  return {type: types.UPDATE_QUICK_LINK_SELECTION, link}; 
}

export function updateSearchCategoryTerm(searchTerm){
  return { type : types.EDIT_SEARCH_CATEGORIES_TERM, searchTerm }; 
}