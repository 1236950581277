import {
  SHOW_TOAST, 
  HIDE_TOAST
} from "../../actions/actionTypes"; 

import {buildAction} from "../functions"; 

export function showToast(message, milliseconds = 5000){
  return async (dispatch) => {
    dispatch(buildAction(SHOW_TOAST, message)); 
    setTimeout(() => {
      dispatch(buildAction(HIDE_TOAST, {})); 
    }, milliseconds); 
  };
}

export function hideToast(){
  return buildAction(HIDE_TOAST, "hide");
}

export function showIssueToast(message){
  return buildAction(SHOW_TOAST, message);
}