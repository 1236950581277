import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import helpers from "../../../../../helpers";
import EmptyState from '../../../../global/emptyState/emptyState';
import { setActiveTab } from "../../../../../redux/reducers/navigation/navigationActions";
import DecorationDetails from "../DecorationDetails/DecorationDetails";
import { SetDecorationSubtab } from "../../../../../redux/reducers/decoration/decorationAction";

class AddDecorationButton extends Component {

    constructor(props) {
        super(props);
        this.props.decoration.selectedSubTab = "";
        this.props.decoration.editDecorationType = "";
        this.props.decoration.selectedDecType = "";
        this.state = {
            showButton: true,
            showDetailsScreen: false,
        }
    }

    handleChangePage = () => {
        this.setState({ showButton: false, showDetailsScreen: true })
        // let subTab = "TEXT";
        // this.props.SetDecorationSubtab(subTab);
        let tabObj = { tab: this.props.navigation.activeTab.tab, page: "add" }
        this.props.setActiveTab(tabObj)
    }

    canAdd() {
        let availableLocations = helpers.product.getOpenLocations(this.props.baseProduct.locations, this.props.customConfig.locations);
        if (availableLocations.length === 0) {
            return (<EmptyState title="All decoration locations are already being used!" message={"<p>You can edit an existing decoration by clicking on it from above.</p><p>You can also delete any decoation and create a new one.</p>"} />);
        }
        return (
            <div className="text-location-summary-add pt-5 pr-4 pl-4">
                <button
                    style={{ border: "none", margin: "0"}}
                    onClick={this.handleChangePage}
                    className="btn btn-lg btn-add-decoration btn-block button-font-forza">
                    ADD NEW DECORATION
                </button>
            </div>
        );
    }

    render() {
        return (
            <div className="text-location fade-in">
                {this.state.showButton && this.canAdd()}
                <div>{this.state.showDetailsScreen && <DecorationDetails></DecorationDetails>}</div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        hover: state.hover,
        notifications: state.notifications,
        reduxState: state,
        customConfig: state.customConfig,
        navigation: state.navigation,
        colorOptions: state.colorOptions,
        baseProduct: state.baseProduct,
        decoration: state.decoration,
    }
}
let reduxActions = {
    setActiveTab,
    SetDecorationSubtab
}

export default connect(mapStateToProps, reduxActions)(AddDecorationButton)
