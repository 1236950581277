import React from "react";
import MultiDropdown from "../multiDropdown/multiDropdown";
import _ from "lodash";
import { FaLock } from "react-icons/fa";


export default class EditLocationPicker extends React.Component {
  render() {
    let view = this.props.view
    let viewDisplay = "";
    if (view && view.charAt(0)) {
      viewDisplay = view.charAt(0).toUpperCase() + view.slice(1);
    }

    let newLocations = []
    let currentLocations = this.props.currentLocations

    _.each(currentLocations, e => {
      let vd = e.view.charAt(0).toUpperCase() + e.view.slice(1)
      newLocations.push({ name: `${vd} - ${e.name}`, key: e.key, code: e.code })
    })

    if (currentLocations && currentLocations.length === 1) {
      return (
        <div className="row">
          <div className="col-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 label-padding-top">
            <span className="decoration-label">{newLocations.length !== 0 ? "LOCATION" : null}</span>
          </div>
          <div className="col-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12">
            <div aria-label={this.props.toolTip} data-balloon-pos="up">
              <div>
                <MultiDropdown
                  customClass="multidropdown-menu"
                  marginTop="15px"
                // label={newLocations.length !== 0 ? "Location" : null} 
                />
                <div className="input-group mb-3">
                  <input value={`${viewDisplay} - ${this.props.selectedLocation}`} className="text-height-number-input form-control border-right-0" disabled />
                  <div className="input-group-append ">
                    <span className="input-group-text border-left-0 append-border"><FaLock /></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="row pt-4 pl-4">
          <div className="col-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 label-padding-top">
            <span className="decoration-label">{newLocations.length !== 0 ? "LOCATION" : null}</span>
          </div>
          <div className="col-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12">
            <div aria-label={this.props.toolTip} data-balloon-pos="up">
              <MultiDropdown
                customClass="multidropdown-menu"
                marginTop="15px"
                // label={newLocations.length !== 0 ? "Location" : null}
                menuHeader="Select a location"
                value={{ name: `${viewDisplay} - ${this.props.selectedLocation}` }}
                options={newLocations}
                onMouseLeave={() => { }}
                onChange={e => this.props.change(e)}
                onHover={e => this.props.hover(e)}
                onHoverOff={() => this.props.hover(null)}
              />
            </div>
          </div>
        </div>

      );
    }
  }
}
