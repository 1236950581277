import { UPDATE_MODAL } from "../../actions/actionTypes";


export function closeModal(locked = false) {
  let modal = {
    isActive: locked,
    value: null
  };
  return { type: UPDATE_MODAL, modal };
}

export function locationUsed(sourceLocation, targetLocation, swapEnabled = false) {
  // TODO: get pretty name from base product for title;
  let title = `Replace ${targetLocation.entity.type}?`

  let modal = {
    isActive: true,
    title,
    sourceLocation,
    targetLocation,
    message: "ReplaceArtwork",
    locked: false,
    swapEnabled
  }
  return { type: UPDATE_MODAL, modal };
}

export function useLocalStorage(data) {
  let modal = {
    size: null,
    isActive: true,
    title: "Use Saved Data",
    message: "LoadFromStorage",
    locked: false,
    value: data
  };
  return { type: UPDATE_MODAL, modal };
}

export function browseArtGallery() {
  let modal = {
    size: null,
    isActive: true,
    title: "Art Library",
    message: "BrowseArtGallery",
    locked: false
  };
  return { type: UPDATE_MODAL, modal };
}

export function saveModal( master, accountType, onlyShare = false, configIdOptional = '', imgUrlOptional = '') {
  let modal = {
    size: "lg",
    isActive: true,
    title: master ? "Save your Design" : "Save & Share your Design",
    message: "SaveModal",
    accountType: accountType === "0" ? 'Gst' : 'Dealer',
    locked: false,
    onlyShareView: onlyShare,
    shareId: configIdOptional,
    imgUrl: imgUrlOptional,
    isMaster: master
  };
  return { type: UPDATE_MODAL, modal };
}

export function shareModal(accountType, onlyShare, configIdOptional = '', imgUrlOptional = '') {
  let modal = {
    size: null,
    isActive: true,
    title: "Share",
    message: "ShareModal",
    locked: false,
    accountType: accountType === "0" ? 'Gst' : 'Dealer',
    onlyShareView: onlyShare,
    shareId: configIdOptional,
    imgUrl: imgUrlOptional
  };
  return { type: UPDATE_MODAL, modal }
}

export function duplicateModal() {
  let modal = {
    size: "lg",
    isActive: true,
    title: "Your design has been duplicated",
    message: "DuplicateModal",
    locked: false,
  };
  return { type: UPDATE_MODAL, modal }
}

export function infoModal() {
  let modal = {
    size: "md",
    isActive: true,
    title: "MAKE SURE TO SAVE YOUR WORK!",
    message: "InfoModal",
    locked: false
  };
  return { type: UPDATE_MODAL, modal };
}

export function changeDesignModal(designNum, master, configId, tempLayers) {
  let modal = {
    size: "md",
    isActive: true,
    title: "Change Design",
    message: "ChangeDesign",
    locked: false,
    design: designNum,
    isMaster: master,
    configID: configId,
    tempLayers: tempLayers,
  };
  return { type: UPDATE_MODAL, modal };
}

export function mockupWarningModal(val) {
    let modal = {
        size: "md",
        isActive: true,
        title: "Mockup Download Warning",
        message: "MockupWarning",
        contentType: val,
        locked: false
    };
    return { type: UPDATE_MODAL, modal };
}

export function itemTotalModal(val) {
    let modal = {
        size: "md",
        isActive: true,
        title: "",
        message: "ItemTotalModal",
        contentType: val,
        locked: false
    };
    return { type: UPDATE_MODAL, modal };
}

export function leadTimeModal(val) {
    let modal = {
        size: "md",
        isActive: true,
        title: "",
        message: "LeadTimeModal",
        contentType: val,
        locked: false
    };
    return { type: UPDATE_MODAL, modal };
}

export function SublimatedProductDetailsModal(DesignNum, styleNum, name, configId){

  let modal = {
    size: "lg",
    isActive: true,
    title: "Design Details",
    message: "SublimatedProductDetailsModal",
    locked: false,
    content: DesignNum, 
    contentStyle: styleNum,
    contentName: name,
    contentconfigID: configId
  }; 
  return {type: UPDATE_MODAL, modal}; 
  
}
export function imageUploadModal(content) {
  let modal = {
    size: null,
    isActive: true,
    title: "Image Uploaded",
    message: "ImageUploadModal",
    locked: false,
    content: content
  };
  return { type: UPDATE_MODAL, modal };
}

export function systemArtModal() {
  let modal = {
    size: null,
    isActive: true,
    title: "Select Type of Art",
    message: "SystemArtModal",
    locked: false,
    content: null
  };
  return { type: UPDATE_MODAL, modal };
}

export function missingRosterModal(previews) {
  let modal = {
    size: "lg",
    isActive: true,
    title: "Enter Player Information",
    message: "MissingRoster",
    locked: false,
    content: previews
  };
  return { type: UPDATE_MODAL, modal };
}

export function editUserArtDataModal(item) {
  let modal = {
    size: null,
    isActive: true,
    title: "Edit Artwork Information",
    message: "EditUserArtDataModal",
    locked: false,
    content: item
  };
  return { type: UPDATE_MODAL, modal }
}


export function colorModeModal(toggle, boolean, switchDrawer) {
  let modal = {
    size: "lg",
    isActive: true,
    title: `Do you want to select Primary color mode?`,
    message: "ColorMode",
    locked: false,
    boolean: boolean,
    switchDrawer: switchDrawer,
    toggle: toggle,
  };
  return { type: UPDATE_MODAL, modal };
}

export function quantityModal(previews) {
  let modal = {
    size: "lg",
    isActive: true,
    title: "Enter Quantities And Sizes",
    message: "Quantity",
    locked: false,
    content: previews
  };
  return { type: UPDATE_MODAL, modal };
}

export function showErrorModal(message) {
  let modal = {
    size: "lg",
    isActive: true,
    title: "Error",
    message: message ? message : "Sorry, there was an error!",
    locked: false
  }
  return { type: UPDATE_MODAL, modal };
}

export function waitingForHost() {
  let modal = {
    size: "lg",
    isActive: true,
    title: "Waiting for Host",
    message: "Waiting",
    locked: true
  };
  return { type: UPDATE_MODAL, modal };
}

export function deleteModal(deleteAction, entityName) {
  let modal = {
    size: null,
    isActive: true,
    title: "Delete Content",
    message: "Delete",
    locked: false,
    delete: deleteAction,
    entityName: entityName
  };
  return { type: UPDATE_MODAL, modal };
}
