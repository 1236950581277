import {RENDER_PREVIEWS_ACTION} from '../../actions/actionTypes';


export default function appActionsReducer(state = [], action) {
  switch (action.type) {
    case RENDER_PREVIEWS_ACTION: return Object.assign({}, state, { renderPreviewsAction: action.callback });
    // case RENDER_PREVIEWS_STANDBY: return Object.assign({}, state, { renderPreviewsStandby: action.standby });
    default:
      return state;
  }
}
