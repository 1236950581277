import React, { Component } from "react";
import InfoSVG from "../../../../assets/icons/InfoSVG";
import RecommenedSVG from "../../../../assets/icons/Recommended";

export default class FabricDetail extends Component {

  open = () => {
    let url = this.props.fabric.faqUrl;
    if (url) {
      window.open(url, "fabric_faq");
    }
  };

  render() {
    let { fabric } = this.props;
    let detailClassName = this.props.selected ? "fabric-detail selected" : "fabric-detail";
    let price = `+ $${(Number(fabric.priceCents) / 100).toFixed(2)}`;
    let description = fabric.description.substr(0, fabric.description.indexOf("("));
    return (
      <div onClick={() => this.props.select()}>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked={this.props.selected} />
          <label class="form-check-label" for="exampleRadios1">
            <div className="row">
              <div className="col-6">
                <div className="fabric-detail__img">
                  {fabric.isRecommended ? (<RecommenedSVG height={15} width={15} className="fabric-detail__rec" />) : null}
                  <img className="fabric-image" src={fabric.swatchUrl} alt={"fabric swatch"}></img>
                </div>
              </div>
              <div className="col-6 pl-0">
                <div className="fabric-detail__description">
                  <div className="fabric-detail__top">
                    <div className="fabric-detail__info">
                      <div className="fabric-detail__title">{fabric.name}</div>
                      <div className="fabric-detail__blend">{fabric.blend}</div>
                    </div>
                    {/* <div className="fabric-detail__price">{price}</div> */}
                  </div>
                  <div className="fabric-detail__lower">
                    <div className="fabric-detail__details">
                      {description}
                    </div>ƒ
                  </div>
                </div>
              </div>
            </div>
          </label>
        </div>
      </div>);
  }
}
