import React, { Component } from "react";
import { connect } from 'react-redux';
import copy from "copy-to-clipboard";

class ShareModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shareLinkCopied: false
        }
    }
    close() {
        this.props.toggle();
    }
    copyToClipboard(value) {
        copy(value);
        this.setState({ shareLinkCopied: true }, () => {
            setTimeout(() => {
                this.setState({ shareLinkCopied: false })
            }, 6000);
        });
    };

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-6 card h-100 share-image">
                        <img className="card-img-top " src={this.props.modalProps.imgUrl} alt="testImage"></img>
                    </div>
                    <div className="col-6 card h-100 border-0">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="background double"><span>Share</span></h2>
                            </div>
                            <div className="col-12 text-center">
                                <p>&nbsp;&nbsp;&nbsp;View it anytime! Share with anyone!</p>
                            </div>
                            <div className="col-12 text-center">
                                <button type="button" className="btn btn-outline-dark w-100 pb-2 btn-black" value="testValue" onClick={e => this.copyToClipboard(`____shareUrl____${this.props.customConfig.configId || this.props.modalProps.shareId}`)}>Click to Copy Link</button>
                                {this.state.shareLinkCopied ? <small className="text-success">Link Copied!</small> : <div></div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            // <div className="ShareModal">
            // <div className = "container-fluid">
            // <div className="row">
            // <div className = "col-6 card">
            // <img className="card-img-top share-image" src={this.props.modalProps.imgUrl} alt="testImage"></img>
            // </div>
            // {/* <div className="col-6">
            // <div className="preview-container">
            // <div className="size">

            // </div>
            // </div>
            // </div> */}
            // <div className="col-6">
            // <div className="input">
            // <h2 className="background double"><span>Share</span></h2>
            // <p>&nbsp;&nbsp;&nbsp;View it anytime! Share with anyone!</p>
            // <button type="button" className="btn btn-primary w-100" value="testValue" onClick={e => this.copyToClipboard(`____shareUrl____${this.props.customConfig.configId || this.props.modalProps.shareId}`)}>Click to Copy Link</button>
            // {this.state.shareLinkCopied ? <div className="mockup-saved">Link Copied!</div> : <div></div>}
            // </div>
            // </div> 
            // </div>

            // {/* </div> */}
            // </div>
            // </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        customConfig: state.customConfig,
        modalProps: state.modal,
        baseProduct: state.baseProduct,
        accountType: state.modal.accountType,
        reduxState: state
    }
}
export default connect(mapStateToProps, {})(ShareModal);

/*
Welcome to the NEW Founder’s Builder beta version! Please be aware that you may encounter some minor software bugs as we continuously improve our builder.
All orders will be reviewed by a customer service representative, and art proofs will be sent within 1 business day.
Please send any special instructions or vector artwork (.ai, .eps, .pdf, etc) to artdepartment@badgersportswear.com.
If you are having trouble placing your order or looking to add tackle twill please contact our customer service builder specialists at decoration@foundersportgroup.com
*/
