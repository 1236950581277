import React, { Component } from "react"
import { connect } from "react-redux"
import OptionHeading from "../../../global/optionHeading/OptionHeading";
import Table from 'react-bootstrap/Table';
import _ from "lodash";
import makeReadable from "../../../global/table/tableFormatter";
import AppConstants from "../../../../constants";
import LocationDetailSum from "../addText/locationSummary/locationDetailSum";
import { triggerRenderPreviewImagesAction } from "../../../../redux/reducers/appActions/appActionsActions";
import TabHeader from "../../../global/TabHeader/TabHeader";
import PlayerRoster from "../playerRoster/playerRoster";
import { changeSetting } from '../../../../redux/reducers/customConfig/customConfigActions';
import { setActiveTab } from '../../../../redux/reducers/navigation/navigationActions';

class SummaryTab extends Component {
  counter = 0
  constructor(...args) {
    super(...args);
    this.summarybtnClick = this.summarybtnClick.bind(this)

    const decorationPricing = _.get(this.props, 'customConfig.decorationPricing') || {};
    this.decorationPricing = decorationPricing;
    let tableConfig = {
      columns: [
        { key: 'name', name: 'Name', placeholder: 'Enter Name', renderer: null },
        { key: 'number', name: 'Number', placeholder: 'Enter Num', isCount: true, renderer: null },
        { key: 'size', name: 'Size', placeholder: 'Size', renderer: this.renderDatalist }
      ],
      sizeOptions: this.props.sizes ? this.props.sizes : []
    };


    let tableReadyPricing = _.cloneDeep(decorationPricing);
    _.each(tableReadyPricing && tableReadyPricing.entities, entity => {
      let isInk = entity && entity.decorationMethod && _.includes(AppConstants.InkDecorationMethods, entity.decorationMethod);
      if (isInk) {
        entity.ink = entity.decorationMethod;
      }
    });

    let tableData = makeReadable(tableReadyPricing, tableConfig);
    this.state = {
      tableConfig,
      tableData,
      previewImg: [],
      loaded: false,
      inputClassName: "summary-custom-input",
      showMockupButton: true
    };
  }

  componentDidMount() {
    const element = _.get(document.getElementsByClassName(this.state.inputClassName), "[0]");
    if (element) {
      element.focus();
    }
    this.Previews();
    let locations = _.get(this.props.customConfig, ["locations"], []);
    locations.forEach(item => {
      if (item.entity.type === 'player-name') {
        this.props.changeSetting(this.props.customConfig.locations, item.code, { setting: "value", value: "PLAYER NAME" });
      } else if (item.entity.type === 'player-number') {
        this.props.changeSetting(this.props.customConfig.locations, item.code, { setting: "value", value: "00" });
      }
    });

  }

  onLoadImages() {
    this.counter++;
    if (this.counter === this.state.previewImg.length) {
      this.setState({ loaded: true });
    }
  }


  renderDetails() {
    let locationDetails = [];
    let filters = ['player-name', 'custom-text', 'player-number', 'custom-art']
    for (let l in this.props.customConfig.locations) {
      let loc = this.props.customConfig.locations[l];
      for (let filter in filters) {
        let checkVal = true;
        if (this.props.value) {
          let locationValue = loc.entity.value || "";
          let val = (this.props.value === 'art') ? "" : this.props.value;
          checkVal = (locationValue === val);
        }
        if (loc.entity.type === filters[filter] && checkVal) {
          let name = _.get(loc, "entity.type");
          // let toolTip = _.find(this.props.toolTips, { key: `${name}-location-summary` }).message;
          // let deleteToolTip = _.find(this.props.toolTips, { key: `edit-${name}-delete-button` }).message;
          if (name) {
            name = name.replace("-", " ");
          }
          locationDetails.push(<LocationDetailSum baseProduct={this.props.baseProduct} key={l} data={loc} remove={() => this.props.deleteModal(() => this.removeLocation(loc), name)} />);
        }
      }
    }
    return (locationDetails);
  }

  async LoadPreviews() {
    return new Promise((resolve, reject) => {
      let timeoutRef = null;
      let handleDone = _.once((err, res) => {
        if (timeoutRef) { clearTimeout(timeoutRef); timeoutRef = null; }
        if (err) {
          console.error(err);
          return reject(err);
        }
        resolve(res);
      });
      /*if (timeoutMS > 0) {
        timeoutRef = setTimeout(() => {
          handleDone(new Error('capturePreviewImages timed out'), null);
        }, timeoutMS);
      }*/
      this.props.triggerRenderPreviewImagesAction(res => {
        handleDone(null, res);
      });
    });
  }

  async Previews() {
    let previews = await this.LoadPreviews();
    this.setState({
      previewImg: previews
    })
  }

  getsizeCount(size) {
    if (this.props.customConfig.rosters[0] !== undefined) {
      let sizeroster = this.props.customConfig.rosters[0].items;
      let count = 0;
      sizeroster.forEach(sr => {
        if (size === sr.size) {
          if (sr.qty)
            count = sr.qty ? sr.qty : 1;
          else
            count = count + 1;
        }
      });
      return count;
    }
  }

  summarybtnClick() {
    let currrentTab = this.props.navigation.activeTab.tab;
    currrentTab = 'names-and-numbers';
    let tabObj = { tab: currrentTab, page: "main" }
    this.props.setActiveTab(tabObj);
  }

  render() {
    let rosterChange = this.props.customConfig.isPlayerRoster;
    let productData = this.props.baseProduct.productMetaData.sort((a, b) => (a.Ordinal > b.Ordinal) ? 1 : -1).map(i => <li key={i.Ordinal} >{i.ConstructionBullet}</li>);
    // this.props.customConfig.locations.forEach(item => {
    //   if (item.entity.type === 'player-name' || item.entity.type === 'player-number') {
    //     rosterChange = true;
    //     return;
    //   }
    // })
    var text = this.props.baseProduct.configurationType === 'custom-sub' ? this.props.baseProduct.legacyStyleNum : this.props.baseProduct.styleNum;
    if (this.props.baseProduct.configurationType === 'custom-sub' && text.length > 6) {
      text = text.slice(0, -1);
    }
    return (

      // this.props.customConfig.rosters.length === 0 || this.props.customConfig.rosters[0].items.length === 0 ?
      //   <div className="fade-in">
      //     <div className="summary-tab-container option-body">
      //       <div className="container-fluid error-details pt-4">
      //         <div className="row">
      //           <div className="col-12">
      //             <h1>
      //               <b>Your design is not yet complete.</b>
      //             </h1>
      //           </div>
      //         </div>
      //         <div className="row pt-4">
      //           <div className="col-12">
      //             <h4>
      //               Please complete the required steps (add sizes or roster) before viewing your summary.
      //             </h4>

      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div> : 

      <div className="fade-in">
        <div className="summary-tab-container option-body">
          <div className="container-fluid pt-2">
            <div className="row header-one-content">
              <div className="col-6">
                <OptionHeading
                  title={"STYLE"}
                  description={""}
                  tablet=""
                  mobile="" />
              </div>
              <div className="col-6">
                {/* <div className="row">
                    <div className="col-6"> */}
                <OptionHeading
                  title={"PREVIEWS"}
                  description={""}
                  tablet=""
                  mobile="" />
              </div>
              {/* <div className="col-6">
                      <OptionHeading className="download-pdf"
                        title={"Download as PDF"}
                        description={""}
                        tablet=""
                        mobile="" />
                    </div> */}
              {/* </div> */}
              {/* </div> */}
            </div>
            <div className="container-fluid pb-3">
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col-12">
                      <TabHeader text={text} name={this.props.baseProduct.name}></TabHeader>
                    </div>
                  </div>
                  <div className="row pt-4">
                    <div className="col-12">
                      {productData.length > 0 ?
                        <ul className="ul-list">
                          {productData}
                        </ul>
                        :
                        <ul className="ul-list">
                          <li>100% Polyester moisture management/antimicrobial performance fabric </li>
                          <li>Sublimated front and back body</li>
                          <li>Badger Sport paneled shoulder for maximum movement </li>
                          <li>Self-fabric collar</li>
                          <li>Double-needle hem</li>
                          <li>Badger heat seal logo on left sleeve</li>
                        </ul>}
                    </div>
                  </div>
                </div>
                <div className="col-6" style={{ paddingRight: '0px' }}>
                  {/* <ViewSelector /> */}
                  {this.state.previewImg.map(list => {
                    return (
                      <img src={list['data']} alt="design" className="col-md-3 preview-images" onLoad={() => { this.onLoadImages() }} loading="eager" />
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="row header-two-content">
              <div className="col-6">
                <OptionHeading disabled={true}
                  title={"DECORATIONS"}
                  description={""}
                  tablet=""
                  mobile="" />
              </div>
              <div className="col-6">
                <OptionHeading
                  title={rosterChange ? 'ROSTER' : 'SIZES'}
                  description={""}
                  tablet=""
                  mobile="" />
              </div>
            </div>
            <div className="row">
              <div className="col-6 decoration-details" disabled={true}>
                {this.renderDetails()}
              </div>
              <div className="col-6 roster-details">
                {!rosterChange ?
                  this.props.customConfig.rosters.length === 0 || this.props.customConfig.rosters[0].items.length === 0 ?
                    <div style={{ fontFamily: 'Forza', textAlign: 'center' }}>
                      <div className='design-summary'>Your design is not yet complete</div>
                      <p className='note'>Please add sizes or a roster before reviewing your full summary.</p>
                      <button className='summary-button' onClick={this.summarybtnClick}>Return to sizes</button>

                    </div>
                    :
                    <Table bordered size="sm">
                      <thead>
                        <tr>
                          <th colSpan={this.props.baseProduct.sizes.length}>{this.props.baseProduct.styleNum}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr rowSpan="3">
                          {this.props.baseProduct.sizes.map(s => {
                            return (
                              <td><b>{s}</b>
                                <div className="tablerow">
                                  {this.getsizeCount(s) !== 0 ? this.getsizeCount(s) : " "}
                                </div>
                              </td>
                            )
                          })}
                        </tr>
                      </tbody>
                    </Table>
                  :
                  this.props.customConfig.rosters.length === 0 || this.props.customConfig.rosters[0].items.length === 0 ?
                    <div style={{ fontFamily: 'Forza', textAlign: 'center' }}>
                      <div className='design-summary'>Your design is not yet complete</div>
                      <p>Please add sizes or a roster before reviewing your full summary.</p>
                      <button className='summary-button' onClick={this.summarybtnClick}>Return to Roster</button>
                    </div>
                    :
                    <PlayerRoster />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    baseProduct: state.baseProduct,
    toolTips: state.toolTips,
    product: state.baseProduct,
    customConfig: state.customConfig,
    navigation: state.navigation
  };
}

const reduxActions = {
  triggerRenderPreviewImagesAction,
  changeSetting,
  setActiveTab
}


export default connect(mapStateToProps, reduxActions)(SummaryTab);
