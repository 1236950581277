import React from "react";

const ArrowDropDownSVG =(props)=>{
  const { className, height=32, width=32, fill="black", stroke = "black", strokeWidth = "0", onClick } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 24 24" className={className} width={width} fill={fill}>
    <path d="M0 0h24v24H0V0z" fill="none"/><path d="M7 10l5 5 5-5H7z"/>
    </svg>
  );
};

export default ArrowDropDownSVG;