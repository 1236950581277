import {REPLACE_DECORATION_PRICING} from "../actions/actionTypes";
import api from "../../api";
import _ from "lodash";
import * as types from "../actions/actionTypes";
import helpers from "../../helpers";

const typesToWatchFor = [ //add init type
  //  DO NOT LISTEN TO type.REPLACE_DECORATION_PRICING
  types.LOAD_CONFIG,
  types.AMEND_LOCATION,
  types.DELETE_LOCATION,
  types.UPDATE_UPGRADES,
  types.ADD_ROSTER_ITEM,
  types.ADD_ROSTER_ITEMS,
  types.UPDATE_ROSTER_ITEMS,
  types.REMOVE_ROSTER_ITEM,
  types.REMOVE_ROSTER_ITEMS,
  types.UPLOAD_QUANTITY_ROSTER,
  types.UPLOAD_ROSTER,
  types.UPDATE_QUANTITY,
  types.DELETE_ROSTER,
  types.ADD_UPLOADED_ART,
  types.CHANGE_FABRIC
];

export const decorationPricingWatcher = store => next => action => {
  if (action && action.type && typesToWatchFor.includes(action.type)) {
    invalidateDecorationPricingFromAPI(store);
  }
  return next(action);
};


const __invalidateDecorationPricingFromAPIWorker = async (store) => {
  const decorationPricing = helpers.pricing.getDecorationPricingRequestDataFromState();
  const res = await api.decorationPricing.calcPrices(decorationPricing);
  store.dispatch({ type: REPLACE_DECORATION_PRICING, payload: { decorationPricing: res } });
  return true;
};

export const invalidateDecorationPricingFromAPI = _.debounce(__invalidateDecorationPricingFromAPIWorker, 100);
