import _ from 'lodash';


//  React app env config loader
//
//  These util methods rename env config variables back and forth
//  between 'REACT_APP_XXX' and 'XXX' this allows for standard json and js
//  style objects to have the 'REACT_APP_' prefix added to them,
//  then passed to react-scripts or other build tools,
//  then remapped and re-loaded by the app again at runtime.


const ReactAppVarNamePrefix = 'REACT_APP_';
const SerializedVarNameSuffix = '$JSON';

const DefaultModifyOpts = {
  srcPrefix: '', dstPrefix: '',
  srcSuffix: '', dstSuffix: '',
  valueRemapper: null
};


// extend the main process.env variables
// assumes that process.env already exists
function ExtendProcessEnv(...dataItems) {
  return _.extend((process && process.env) || {}, ...dataItems);
}

/* // extend multiple data items at the same time
function ExtendNonSerializedBuildtimeConfig(...dataItems) {
  dataItems = _.map(dataItems, v => { return PrepareBuildtimeConfig(v, false); });
  return _.extend({}, ...dataItems);
} //*/

// extend multiple data items at the same time
function ExtendSerializedBuildtimeConfig(...dataItems) {
  dataItems = _.map(dataItems, v => { return PrepareBuildtimeConfig(v, true); });
  return _.merge({}, ...dataItems);
}

// adds the REACT_APP_ prefix to all variables
function PrepareBuildtimeConfig(data, serialize = true) {
  let valueRemapper = serialize? SerializeData: null;
  return RenameObjectKeys(data, { srcPrefix: '', dstPrefix: ReactAppVarNamePrefix, valueRemapper });
}

// finds variables starting with the REACT_APP_ prefix, strips the prefix and
// returns a data object containing only those values
function LoadRuntimeAppConfig(deserialize = true) {
  let valueRemapper = deserialize? DeserializeData: null;
  return RenameObjectKeys(process && process.env, { srcPrefix: ReactAppVarNamePrefix, dstPrefix: '', valueRemapper });
}

function SerializeData(ctx) {
  let modified = false;
  if (ctx.val && (_.isObject(ctx.val) || _.isArray(ctx.val))) {
    ctx.key += !_.endsWith(SerializedVarNameSuffix)? SerializedVarNameSuffix: '';
    ctx.val = TryJsonStringify(ctx.val);
    modified = true;
  }
  return modified;
}

function DeserializeData(ctx) {
  let modified = false;
  if (_.endsWith(ctx.key, SerializedVarNameSuffix)) {
    ctx.key = ctx.key.slice(0, -SerializedVarNameSuffix.length);
    ctx.val = TryJsonParse(ctx.val);
    modified = true;
  } else if (_.isString(ctx.val)) {
    switch (ctx.val) {
      case 'null':
        ctx.val = null;
        modified = true;
        break;
      case 'undefined':
        ctx.val = undefined;
        modified = true;
        break;
      case 'false':
        ctx.val = false;
        modified = true;
        break;
      case 'true':
        ctx.val = true;
        modified = true;
        break;
      default:
        break;
    }
  }

  return modified;
}

// scans through the data input object and renames variables starting with srcPrefix to dstPrefix
// It only includes values that start with srcPrefix or end with srcSuffix
// if srcPrefix is empty, then all values are included and renamed
function RenameObjectKeys(data, opts = DefaultModifyOpts) {
  opts = _.defaults({}, opts, DefaultModifyOpts);
  if (data && (opts.srcPrefix || opts.dstPrefix) && opts.srcPrefix !== opts.dstPrefix) {
    data = _.transform(data, (ctx, v1, k1) => {
      let k2 = k1;
      let v2 = v1;
      if (!opts.srcPrefix || _.startsWith(k2, opts.srcPrefix)) {
        k2 = ModifyString(k2, opts);
        if (opts.valueRemapper) {
          let ctx = { val: v2, key: k2 };
          if (opts.valueRemapper(ctx)) {
            k2 = ctx.key;
            v2 = ctx.val;
          }
        }
        ctx[k2] = v2;
      }
      return ctx;
    }, {});
  }
  return data;
}

function ModifyString(inputStr, opts = DefaultModifyOpts) {
  let str = inputStr || '';

  if ((opts.srcPrefix || opts.dstPrefix) && opts.srcPrefix !== opts.dstPrefix) {
    if (str && opts.srcPrefix && _.startsWith(str, opts.srcPrefix)) {
      str = str.slice(opts.srcPrefix.length);
    }
    if (opts.dstPrefix && !_.startsWith(str, opts.dstPrefix)) {
      str = opts.dstPrefix + str;
    }
  }

  if ((opts.srcSuffix || opts.dstSuffix) && opts.srcSuffix !== opts.dstSuffix) {
    if (str && opts.srcSuffix && _.endsWith(str, opts.srcSuffix)) {
      str = str.slice(0, -opts.srcSuffix.length);
    }
    if (opts.dstSuffix && !_.endsWith(str, opts.dstSuffix)) {
      str += opts.dstSuffix;
    }
  }

  return str;
}

function TryJsonStringify(data, ...args) {
  let retval = data;
  try {
    retval = JSON.stringify(data, ...args);
  } catch (ex) { /* noop */ }
  return retval;
}

function TryJsonParse(data, ...args) {
  let retval = data;
  try {
    retval = JSON.parse(data, ...args);
  } catch (ex) { /* noop */ }
  return retval;
}

const ExtendBuildtimeConfig = ExtendSerializedBuildtimeConfig;

const ReactAppConfigLoader = {
  ExtendProcessEnv,
  LoadRuntimeAppConfig,
  ExtendBuildtimeConfig
};

export default ReactAppConfigLoader;
export { LoadRuntimeAppConfig, ExtendBuildtimeConfig };
