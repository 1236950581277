import React, { Component } from 'react';
import helpers from '../../../../../../helpers/';
import _ from 'lodash';
import { connect } from 'react-redux';
import { setPendingLocation } from '../../../../../../redux/reducers/navigation/navigationActions';
import { dragItem } from "../../../../../../redux/reducers/navigation/navigationActions";

class AssetItem extends Component {

  handleOnClick = () => {
    let type = "custom-art";
    let value = "system-art";
    let view = this.props.navigation.activeView;
    let code = this.props.navigation.activeLocation;
    let artAsset = this.props.item;
    
 
    let pendingLocation = helpers.navigation.makePendingLocation( type, value, view, code, artAsset);
    this.props.setPendingLocation( pendingLocation );
  } 

  handleOnDrag = () => {
    if ( !this.props.navigation.drag ){
      this.handleOnClick();
      this.props.dragItem(true)
    }
  }

  errorImage = () => {
    console.error("error loading asset");
  }

  render() {   
    let { item } = this.props; 
    let pendingLocationUserArtId = _.get( this.props.navigation.pendingLocation, 'entity.userArtId', null );
    let name = (pendingLocationUserArtId === this.props.item.id) ? "asset-item selected" : "asset-item";  
    return (<div className={name} onClick={this.handleOnClick} onDragStart={this.handleOnDrag}>
    <img src={item.url} alt="asset from server" />
  </div>); 
  } 
}

const reduxActions = {
  setPendingLocation,
   dragItem
}

function mapStateToProps (state){
  return {
    navigation : state.navigation,
  }
}

export default connect(mapStateToProps, reduxActions)(AssetItem);
