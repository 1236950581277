import React, { Component } from 'react'

export default class DesignPagination extends Component {

  renderPrev(){
    let {current} = this.props; 
    if ( current > 1 ){ 
      return (<div className="design-table-pagination-lt" onClick={() => this.props.change(current - 1)}>
        Previous
        </div>); 
    } else {
      return (<div className="disabled text-secondary">
        Previous
        </div>); 
        } 
  }

  renderNext(){
    let {current, total} = this.props; 
    if ( current < total ){
      return (<div className="design-table-pagination-gt" onClick={() => this.props.change(current + 1)}> 
        Next
      </div>); 
    } else {
      return (<div className="disabled text-secondary"> 
        Next 
      </div>); 
    }
  }

  render() {
    let { current } = this.props;
    return (
      <div className="d-flex flex-row justify-content-between">
      <div></div>
        <div className="design-table-page d-flex flex-row justify-content-end align-items-center">
          { this.renderPrev() }
          <div className="pagination-val">  { current } </div>
          { this.renderNext() }
          </div>
      </div>
    )
  }
}
