import ApiService from './api-service';
import ApiConfig from './api-config';
import AppConfig from '../config';
import _ from 'lodash';

// return a singleton instance
let configOpts = _.pick(AppConfig, ApiConfig.getDefaultConfigKeys());
export const api = new ApiService({ config: new ApiConfig(configOpts) });
export default api;

export { ApiService };




