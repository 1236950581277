import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import TextColorSelector from './textColorSelector/textColorSelector';
import MultiDropdown from "../../../../global/multiDropdown/multiDropdown";
import { toggleDelete } from '../../../../../redux/reducers/navigation/navigationActions';
import { changeTextColor, changeSetting } from '../../../../../redux/reducers/customConfig/customConfigActions';
import { clearHover, addHoverObject } from '../../../../../redux/reducers/hover/hoverActions';
import toolTips from '../../../../../redux/reducers/toolTips/toolTips';
import helpers from '../../../../../helpers/'


class EditTextColorPicker extends React.Component {
  constructor(props) {
    super(props)
    this.onColorHovered = this.onColorHovered.bind(this);
    this.changeColor = this.changeColor.bind(this);
    this.state = {
      hoverColorName: "",
    }
  }

  changeColor(input, type) {
    let oldLocations = this.props.customConfig.locations;
    this.props.changeTextColor(oldLocations, this.props.navigation.activeLocation, type, input);
    this.props.clearHover();
  }

  onColorHovered(hoverColor, type) {
    if (hoverColor) {
      this.setState({ hoverColorName: hoverColor.name });
      const originalConfigs = this.props.customConfig.locations;
      let allCustomConfigs = _.cloneDeep(originalConfigs);
      let current = _.find(allCustomConfigs, { code: this.props.navigation.activeLocation });
      _.each(current.entity.capabilities.fillColors, e => {
        if (e.key === type) {
          let index = _.findIndex(current.entity.capabilities.fillColors, e);
          current.entity.capabilities.fillColors[index] = { key: type, value: hoverColor.code };
        }
      });
      let newUrl = helpers.urlRender.returnProductRenderUrl(current.entity);


      this.props.addHoverObject({ url: newUrl });
    } else {
      this.setState({ hoverName: null });
      this.props.clearHover();
    }
  }

  showColors() {
    let label = "Colors";
    let locationContent = _.find(this.props.customConfig.locations, { code: this.props.navigation.activeLocation });
    let bpLocation = _.find(this.props.baseProduct.locations, { code: locationContent.code });
    let bpAllowedEntity = _.find(bpLocation.allowedEntities, { key: locationContent.entity.type });
    // bpDecorationMethod has all allowed sizes for this location and entity type.
    let bpDecorationMethod = _.find(bpAllowedEntity.allowedDecorationMethods, { key: locationContent.entity.decorationMethod });
    let entity = bpDecorationMethod;
    return (
      <div style={{ marginTop: "15px" }}>
        <div className="row pl-1">
          <div className="col-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 color-label-padding">
            <label className="decoration-label">{label.toUpperCase()}</label>
          </div>
          <div className="col-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12">
            <div className="row">
              <div className="col-12 padding-left-color-picker">
                <MultiDropdown />
                <TextColorSelector
                  toggleDelete={this.props.toggleDelete}
                  defaultCapabilities={entity.capabilities.fillColors}
                  loadedDefs={this.props.loadedDefs.colors}
                  customLocation={locationContent}
                  selectedLocation={this.props.navigation.activeLocation}
                  allowedColors={entity.allowedColors}
                  entity={entity}
                  toolTips={toolTips}
                  hoverColorName={this.state.hoverColorName}
                  changeColor={this.changeColor}
                  onColorHovered={this.onColorHovered}
                  completeLoadedDefs={this.props.loadedDefs}
                />
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="EditTextColorPicker">
        {this.showColors()}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    navigation: state.navigation,
    baseProduct: state.baseProduct,
    customConfig: state.customConfig,
    loadedDefs: state.loadedDefs
  }
}

let reduxActions = {
  toggleDelete,
  clearHover,
  addHoverObject,
  changeTextColor,
  changeSetting
};

export default connect(mapStateToProps, reduxActions)(EditTextColorPicker);
