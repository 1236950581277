import _ from 'lodash';
import { ApiRequestHelper } from './lib';
import ApiConfig from './api-config';
import Models from './models';
import Services from './services';


export default class ApiService {

  constructor(opts = null) {
    opts = opts || {};
    this.config = opts.config || new ApiConfig();
    this.request = new ApiRequestHelper(this.config);

    this.models = Models;
    this.serviceNames = [];

    let serviceOpts = { request: this.request };

    _.each(Services, ServiceClass => {
      let service = new ServiceClass(serviceOpts);
      let serviceName = service.serviceName || ServiceClass.serviceName || this.formatClassNameToServiceName(ServiceClass.name);
      this.serviceNames.push(serviceName);
      this[serviceName] = service;
    });

  }


  formatClassNameToServiceName(str) {
    str = str || '';
    if (_.endsWith(str, 'Service')) {
      str = str.replace(/Service$/, '');
      str = str.charAt(0).toLowerCase() + str.slice(1);
    }
    return str;
  }
}
