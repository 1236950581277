
import React from "react";

const ChromagearBadgerBigSVG = (props) => {
    const {
        filla = "#ff4b00",
        fillb = "#ff9f00",
        fillc = "#6b0",
        filld = "#00cc71",
        fille = "#0095ff",
        fillf = "#6b77e8",
        fillg = "#b760ea",
        fillh = "#ff193d",
        filli = "#ff7816",
        fillj = "#fdbf00",
        fillk = "#7d0",
        filll = "#00dd7b",
        fillm = "#3aaaff",
        filln = "#7984eb",
        fillo= "#cb75f6",
        fillp = "#ff4949",
        fillq = "#ffd400",
        fillr = "#89f900",
        fills = "#00ee84",
        fillt = "#73bcff",
        fillu = "#979fef",
        fillv = "#da90f8",
        fillw = "#ff6c6c",
    } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20.475" height="25.475" viewBox="0 0 15.475 15.475"><defs>
            {/* <style>.a{fill:#ff4b00;}.b{fill:#ff9f00;}.c{fill:#6b0;}.d{fill:#00cc71;}.e{fill:#0095ff;}.f{fill:#6b77e8;}.g{fill:#b760ea;}.h{fill:#ff193d;}.i{fill:#ff7816;}.j{fill:#fdbf00;}.k{fill:#7d0;}.l{fill:#00dd7b;}.m{fill:#3aaaff;}.n{fill:#7984eb;}.o{fill:#cb75f6;}.p{fill:#ff4949;}.q{fill:#ffd400;}.r{fill:#89f900;}.s{fill:#00ee84;}.t{fill:#73bcff;}.u{fill:#979fef;}.v{fill:#da90f8;}.w{fill:#ff6c6c;}</style> */}
            </defs>
            <path fill={filla} d="M226.907,0,226,1.5l.907,1.247,3.525,1.462,1.731-.825.191-1.1A7.709,7.709,0,0,0,226.907,0Z" transform="translate(-219.169)"/>
            <path fill={fillb} d="M374.541,75.771l-1.923,1.923,1.462,3.525,1.445.907,1.305-.907A7.71,7.71,0,0,0,374.541,75.771Z" transform="translate(-361.356 -73.481)"/>
            <path fill={fillc} d="M374.08,256l-1.462,3.525.088,1.9,1.835.021a7.71,7.71,0,0,0,2.29-5.447Z" transform="translate(-361.356 -248.263)"/>
            <path fill={filld} d="M230.431,372.618l-3.525,1.462-.907,1.5.907,1.249a7.71,7.71,0,0,0,5.447-2.29Z" transform="translate(-219.169 -361.356)"/>
            <path fill={fille} d="M77.694,371.806l-1.788-.025-.134,1.948a7.709,7.709,0,0,0,5.447,2.29v-2.75Z" transform="translate(-73.481 -360.544)"/>
            <path fill={fillf} d="M2.75,226.907,1.419,226,0,226.907a7.71,7.71,0,0,0,2.29,5.447l1.923-1.923Z" transform="translate(0 -219.169)"/>
            <path fill={fillg} d="M3.445,76.019,2.29,75.771A7.71,7.71,0,0,0,0,81.218H2.75l1.462-3.525Z" transform="translate(0 -73.481)"/>
            <path fill={fillh} d="M81.218,2.75V0a7.71,7.71,0,0,0-5.447,2.29l1.923,1.923A4.971,4.971,0,0,1,81.218,2.75Z" transform="translate(-73.481)"/>
            <path fill={filli} d="M226.907,91,226,92.36l.907,1.36,1.6.665,1.8-.891.124-1.031A4.971,4.971,0,0,0,226.907,91Z" transform="translate(-219.169 -88.25)"/>
            <path fill={fillj} d="M310.93,139.382l-1.923,1.923.665,1.6,1.258.907,1.462-.907A4.971,4.971,0,0,0,310.93,139.382Z" transform="translate(-299.668 -135.169)"/>
            <path fill={fillk} d="M309.672,256l-.665,1.6.215,1.122,1.708.8A4.971,4.971,0,0,0,312.392,256Z" transform="translate(-299.668 -248.263)"/>
            <path fill={filll} d="M228.509,309.007l-1.6.665L226,311.089l.907,1.3a4.972,4.972,0,0,0,3.525-1.462Z" transform="translate(-219.169 -299.668)"/>
            <path fill={fillm} d="M141.056,309.007l-1.93.117.008,1.806a4.972,4.972,0,0,0,3.525,1.462v-2.72Z" transform="translate(-134.921 -299.668)"/>
            <path fill={filln} d="M93.72,226.907,92.455,226,91,226.907a4.971,4.971,0,0,0,1.462,3.525l1.923-1.923Z" transform="translate(-88.25 -219.169)"/>
            <path fill={fillo} d="M94.287,139.393l-1.825-.011A4.971,4.971,0,0,0,91,142.907h2.72l.665-1.6Z" transform="translate(-88.25 -135.169)"/>
            <path fill={fillp} d="M139.382,92.462l1.923,1.923,1.6-.665V91A4.971,4.971,0,0,0,139.382,92.462Z" transform="translate(-135.169 -88.25)"/>
            <path fill={fillb} d="M228.509,181.665a2.26,2.26,0,0,0-1.6-.665l-.907.893.907,1.374,1.708-.8Z" transform="translate(-219.169 -175.529)"/>
            <path fill={fillq} d="M258.267,204.6a2.26,2.26,0,0,0-.665-1.6l-1.6,1.6,1.445.907Z" transform="translate(-248.263 -196.858)"/>
            <path fill={fillr} d="M257.6,257.6a2.26,2.26,0,0,0,.665-1.6H256l.8,1.708Z" transform="translate(-248.263 -248.263)"/>
            <path fill={fills} d="M226.907,256,226,257.275l.907.992a2.26,2.26,0,0,0,1.6-.665Z" transform="translate(-219.169 -248.263)"/>
            <path fill={fillt} d="M204.6,256l-1.36.453-.242,1.149a2.26,2.26,0,0,0,1.6.665Z" transform="translate(-196.858 -248.263)"/>
            <path fill={fillu} d="M181,241.453a2.26,2.26,0,0,0,.665,1.6l1.6-1.6L181.935,241Z" transform="translate(-175.529 -233.716)"/>
            <path fill={fillv} d="M181.665,202.993a2.26,2.26,0,0,0-.665,1.6h2.267l-.453-1.36Z" transform="translate(-175.529 -196.858)"/>
            <path fill={fillw} d="M204.6,181a2.26,2.26,0,0,0-1.6.665l1.6,1.6Z" transform="translate(-196.858 -175.529)"/></svg>
    );
};

export default ChromagearBadgerBigSVG;
