import _ from 'lodash';
import stateProvider from "./stateProvider";

const HOVER_TYPES = {
  productColor: 'productColor',
  stitchingColor: 'stitchingColor',
  pattern: 'pattern',
  primaryColors: 'primaryColors'
};

class HoverHelper {

  get HOVER_TYPES() { return HOVER_TYPES; }

  // state helpers

  getStateVal(key, defaultValue = null) {
    return stateProvider.get(key, defaultValue);
  }

  // tests

  unitTests() {
    return {
    };
  }


  // hover state helpers

  getAllHoverItems() {
    return this.getStateVal('hover') || [];
  }

  getHoverItem(hoverType) {
    let hoverItems = this.getAllHoverItems();
    return _.find(hoverItems, { hoverType }) || null;
  }

  getProductColorHoverItem() {
    return this.getHoverItem(HOVER_TYPES.productColor)
  }

  getProductColorDef() {
    return _.get(this.getHoverItem(HOVER_TYPES.productColor), 'color', null);
  }

  getStitchingColorHoverItem() {
    return this.getHoverItem(HOVER_TYPES.stitchingColor)
  }

  getStitchingColorDef() {
    return _.get(this.getHoverItem(HOVER_TYPES.stitchingColor), 'color', null);
  }

  getPatternHoverItem() {
    return this.getHoverItem(HOVER_TYPES.pattern);
  }

  getPrimaryColorsHoverItem() {
    return this.getHoverItem(HOVER_TYPES.primaryColors);
  }

  // hover state merge helpers

  replaceHoverItem(hoverType, hoverItem) {
    if (hoverItem && !hoverItem.hoverType) { hoverItem.hoverType = hoverType; }
    let hoverItems = _.cloneDeep(this.getAllHoverItems());
    let idx = _.findIndex(hoverItems, { hoverType });
    if (idx >= 0) {
      if (hoverItem) {
        hoverItems[idx] = hoverItem;  // replace the old item
      } else {
        hoverItems.splice(idx, 1);    // remove the old item
      }
    } else if (hoverItem) {
      hoverItems.push(hoverItem);     // add the new item
    }
    return hoverItems;
  }

  /*
  mergeEntityHoverState(entity) {

    // create a clone so we do not clobber the original item
    let mergedVal = _.cloneDeep(entity || {});

    // TODO: add impl

    return mergedVal;
  }

  mergeProductColorState(productColorKey) {
    return this.getHoverColorKey('productColor', productColorKey);
  }

  getHoverColorKey(hoverType, defaultValue = null) {
    let retval = defaultValue;
    let hoverItem = this.getStateHoverItem(hoverType);
    if (hoverItem) {
      retval = _.get(hoverItem, 'key', null);
    }
    return retval;
  } */

}

// export a static singleton instance
const hoverHelper = new HoverHelper();
export default hoverHelper;


