import * as types from '../../actions/actionTypes';
const initialState =  {
  corpAccount: {},
  webUser: {},
  userArtAssets: [],
  userPatterns: [
    //{key: flat, colors: [...]}
  ]
}; 

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_USER_INIT_STATE:  return Object.assign ({}, action.user);
    case types.ADD_UPLOADED_ART:  return Object.assign ({}, state, {userArtAssets : action.userArtAssets}); 
    case types.USER_PATTERN_HISTORY: return Object.assign ({}, state, {userPatterns : action.patterns}); 
    case types.UPDATE_ART_ASSET_DATA: return Object.assign({}, state, {userArtAssets: action.userArtAssets}); 
    default:
      return state;
  }
}
