import _ from 'lodash';
import ApiRequestHelper from './api-request.helper';


const _defaultOpts = {
  path: null,
  idParamName: 'id',
  model: null
};


/**
 * Base Service Endpoint Implementation
 */
export default class BaseService {

  static get serviceName() {
    return this.formatClassNameToServiceName(this.name);
  }

  static formatClassNameToServiceName(str) {
    str = str || '';
    if (_.endsWith(str, 'Service')) {
      str = str.replace(/Service$/, '');
      str = str.charAt(0).toLowerCase() + str.slice(1);
    }
    return str;
  }

  constructor(opts = null) {
    this.opts = _.defaultsDeep(opts || {}, _defaultOpts);
    this.request = this.opts.request || new ApiRequestHelper();
    this.initDefaultProperties();
  }

  initDefaultProperties() {
    // init properties
    this.name = _.get(this.opts, 'name') || this.constructor.name;
    this.routePath = _.get(this.opts, 'path', null);
    this.idParamName = _.get(this.opts, 'idParamName', null);
    this.modelClass = _.get(this.opts, 'model', null);
  }


  //
  // properties
  //

  name = null;

  // relative route path for this REST resource service endpoint
  routePath = null;

  // default id variable name for this REST resource
  idParamName = null;

  // optional resource model, uses standard JS object if not set.
  modelClass = null;

  // does this service enable request auth token by default
  authEnabled = false;

}

