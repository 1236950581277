import React, { Component } from "react";
import _ from "lodash";
import TabHeader from "../../../global/TabHeader/TabHeader";
import { connect } from "react-redux";
import helpers from "../../../../helpers";
import api from "../../../../api";
import SelectedDesignSVG from '../../../../assets/icons/SelectedDesignSVG';
import { initLaunchContext } from "../../../../../src/redux/reducers/launchContext/launchContextActions.js";
import { changeDesignModal } from "../../../../redux/reducers/modal/modalActions";
class AddDesign extends Component {
  counter = 0;
  constructor(props) {
    super(props);
    this.state = {
      designs: [],
      loaded: false,
      selectedDesign: this.props.baseProduct.designNum,
      selectedDesignName: "",
    }
  }

  componentDidMount() {
    this.getDesigns();
  }

  async getDesigns() {
    this.data = await api.sublimatedProducts.getDesignsByStyleNumber(this.props.baseProduct.styleNum);
    const selectedColors = this.props.customConfig.customLayers.primaryColors;
    const primaryColors = helpers.colors.getColorKeysFromColorCodes(
      selectedColors
    );
    let params = {};
    params.layers = {};
    _.each(this.props.customConfig.customLayers.layers, patternColor => {
      params.layers[patternColor.key] = helpers.colors.getColorKeyFromColorCode(patternColor.color);
    })
    this.data.designs = this.data.designs.map(design => ({ ...design, thumbnailURL1: `${design.thumbnailURL1}?json=${encodeURIComponent(JSON.stringify(params))}`, thumbnailURL: `${design.thumbnailURL}?json=${encodeURIComponent(JSON.stringify(params))}` }));
    this.data.designs = this.data.designs.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0);
    let designName = this.data.designs.filter(filterdata => filterdata.designNum.includes(this.state.selectedDesign));
    this.setState({ designs: this.data.designs, selectedDesignName: designName[0].name });
  }

  onLoadImages() {
    this.counter++;
    if (this.counter === this.state.designs.length) {
      this.setState({ loaded: true });
    }
  }
  componentDidUpdate() {
    this.getLayers();
  }

  getLayers() {
    let launchContextLayers = this.props.launchContext.layers;
    let customConfigLayer = this.props.customConfig.customLayers.layers;
    if (launchContextLayers !== undefined) {
      if (customConfigLayer.length === 0) {
        this.props.customConfig.customLayers.layers = {};
        this.props.customConfig.customLayers.layers = launchContextLayers;
      }
    }
  }

  renderSelectedCheckmark() {
    return (
      <div className="circle">
        <div className="selected-design-checkmark">
          <SelectedDesignSVG
            height="170"
            width="170"
            strokeWidth="1.5"
            viewBox="0 0 100 100"
            fill={"white"}
            className={"selected-design-checkmark"}
          />
        </div>
      </div>
    );
  }
  renderEmptyResultsMessage() {
    return (
      <div className="no-results-warning">
        <h4 className="center-text">No Designs Available!</h4>
      </div>
    );
  }

  render() {
    const selectedColors = [];
    let selectedDesign;
    var text = this.props.baseProduct.legacyStyleNum;
    if (text.length > 6) {
      text = text.slice(0, -1);
    }
    _.each(
      this.props.customConfig.customLayers.layers,
      (layersColor, index) => {
        selectedColors[index] = layersColor.color;
      }
    );
    this.getLayers();
    const tempLayers = this.props.customConfig.customLayers.layers;
    return (
      <div className="">
        {!this.state.loaded && (
          <div>
            <div className="overlay-box">
              <div className="loader"></div>
            </div>
          </div>
        )}
        <div className="option-body">
          {!this.state.loading && _.isEmpty(this.state.designs)
            ? this.renderEmptyResultsMessage()
            : null}
          <div className="row pl-4">
            <div className="col-12 pl-0 pr-0 pb-4">
              <TabHeader text={text} name={this.props.baseProduct.name}></TabHeader>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-6 pl-0">
                  <p className="design-tab-heading">Choose Your Design</p>
                </div>
                <div className="col-6 selected-design-name text-right pt-2 pr-0">
                  SELECTED:{this.state.selectedDesignName}
                </div>

              </div>

            </div>
            <div className="row pl-2">
              {
                this.state.designs.map((list, index) => {
                  selectedDesign = (list['designNum'] === this.state.selectedDesign);
                  return (
                    <div className=" col-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-6 p-2" key={index}>
                      <div className={selectedDesign ? "card card-design-active rounded-0" : "card card-design-tab "} onClick={async (e) => {
                        if (this.props.customConfig.locations.length > 0) {
                          this.setState({ selectedDesign: list['designNum'], selectedDesignName: list['name'] });
                          this.props.changeDesignModal(list['designNum'], list['isMasterConfig'], list['configId'], tempLayers);
                        } else {
                          if (this.props.changeDesignColor.isChanged) {
                            this.setState({ selectedDesign: list['designNum'], selectedDesignName: list['name'] });
                            list['designNum'] === this.props.baseProduct.designNum ? this.props.changeDesignColor.isDesignLoading = false : this.props.changeDesignColor.isDesignLoading = true;
                            this.props.launchContext.configId = "";
                            this.props.launchContext.design = list["designNum"];
                            this.props.launchContext.designNum =
                              list["designNum"];
                            this.props.launchContext.layers = tempLayers;
                            this.props.launchContext.style = this.props.baseProduct.styleNum;
                            this.props.launchContext.styleNum = this.props.baseProduct.styleNum;
                            this.props.launchContext.isMasterConfig =
                              list["isMasterConfig"];
                            this.props.initLaunchContext(
                              this.props.launchContext
                            );
                          } else {
                            if (list['configId'] !== "") {
                              this.setState({ selectedDesign: list['designNum'], selectedDesignName: list['name'] });
                              list['designNum'] === this.props.baseProduct.designNum ? this.props.changeDesignColor.isDesignLoading = false : this.props.changeDesignColor.isDesignLoading = true;
                              this.props.launchContext.configId = list['configId'];
                              this.props.launchContext.design = undefined;
                              this.props.launchContext.designNum = undefined;
                              this.props.launchContext.style = undefined;
                              this.props.launchContext.styleNum = undefined;
                              this.props.launchContext.colors = [];
                              this.props.launchContext.layers = undefined;
                              this.props.launchContext.isMasterConfig =
                                list["isMasterConfig"];
                              this.props.initLaunchContext(
                                this.props.launchContext
                              );
                            } else {
                              if (list['designNum'] && list['configId'] === "") {
                                this.setState({ selectedDesign: list['designNum'], selectedDesignName: list['name'] });
                                list['designNum'] === this.props.baseProduct.designNum ? this.props.changeDesignColor.isDesignLoading = false : this.props.changeDesignColor.isDesignLoading = true;
                                this.props.launchContext.configId = "";
                                this.props.launchContext.design =
                                  list["designNum"];
                                this.props.launchContext.designNum =
                                  list["designNum"];
                                this.props.launchContext.layers = tempLayers;
                                this.props.launchContext.style = this.props.baseProduct.styleNum;
                                this.props.launchContext.styleNum = this.props.baseProduct.styleNum;
                                this.props.launchContext.isMasterConfig =
                                  list["isMasterConfig"];
                                this.props.initLaunchContext(
                                  this.props.launchContext
                                );
                              }
                            }
                          }
                        }
                      }} >
                        {selectedDesign ? this.renderSelectedCheckmark() : null}
                        <div className="text-center" >
                        </div>
                        <div className="card-img-top">
                          {this.props.changeDesignColor.isChanged === true ? <img src={list['thumbnailURL1']} alt="design" className="image-card-designs image-center" onLoad={() => { this.onLoadImages() }} loading="eager" /> : <img src={list['thumbnailURL']} alt="design" className="card-img-top image-card-designs image-center" onLoad={() => { this.onLoadImages() }} loading="eager" />}
                        </div>
                        <div className="selected-name card-text design-name">
                          <div className="text-center" >
                            {list['name']}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = {
  initLaunchContext,
  changeDesignModal,
};

function mapStateToProps(state) {
  return {
    toolTips: state.toolTips,
    baseProduct: state.baseProduct,
    customConfig: state.customConfig,
    launchContext: state.launchContext,
    changeDesignColor: state.changeDesignColor,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDesign);
