import React, { Component } from 'react'; 
import ArtGallery from '../../sidebar/tabs/uploadArt/artGallery/ArtGallery';

class BrowseArtGallery extends Component {

  render() {
    return (
      <div className="simple-modal" style={{display:"flex" , flexDirection:"column", textAlign:"center" ,justifyContent:"center"}}>
        <h6>Select Your Art</h6>
        <ArtGallery toggle={this.props.toggle} useTinyUpload={true}/>
      </div>
    )
  }
}

export default BrowseArtGallery;
