import _ from 'lodash';
import stateProvider from "./stateProvider";



class LoadedDefsHelper {

  // state helpers

  getStateVal(key, defaultValue = null) {
    return stateProvider.get(key, defaultValue);
  }

  // tests

  unitTests() {
    return {
    };
  }


  // loaded def helpers

  getDefaultPrice(decorationMethod, entityType){
    const pricingDefaults = this.getStateVal('loadedDefs.pricingDefaults');
    const entity = _.find(pricingDefaults, { decorationMethod, entityType });

    return _.get(entity, "priceCents", 0);
  }

  getColorDef(colorCode) {
    return _.find(this.getStateVal('loadedDefs.colors'), { code: colorCode }) || null;
  }

  getColorName(colorCode) {
    return _.get(this.getColorDef(colorCode), 'name', null);
  }

  getFontDef(fontKey) {
    return _.find(this.getStateVal('loadedDefs.fonts'), { key: fontKey }) || null;
  }

  getFontName(fontKey) {
    return _.get(this.getFontDef(fontKey), 'name', null);
  }

  getTextEffectDef(textEffectKey) {
    return _.find(this.getStateVal('loadedDefs.textEffects'), { key: textEffectKey }) || null;
  }

  getTextEffectName(textEffectKey) {
    return _.get(this.getTextEffectDef(textEffectKey), 'name', null);
  }

  getProductSizeDef(sizeCode) {
    return _.find(this.getStateVal('loadedDefs.productSizes'), { code: sizeCode }) || null;
  }

  getProductSizeName(sizeCode) {
    return _.get(this.getProductSizeDef(sizeCode), 'name', null);
  }

  getDecorationMethodDef(decorationMethodKey) {
    return _.find(this.getStateVal('loadedDefs.decorationMethods'), { key: decorationMethodKey }) || null;
  }

  getDecorationMethodName(decorationMethodKey) {
    return _.get(this.getDecorationMethodDef(decorationMethodKey), 'name', null);
  }

  getLayerPatternDef(layerPatternKey) {
    return _.find(this.getStateVal('loadedDefs.layerPatterns'), { key: layerPatternKey }) || null;
  }

  getLayerPatternName(layerPatternKey) {
    return _.get(this.getLayerPatternDef(layerPatternKey), 'name', null);
  }

  getLayerPatternNumColors(layerPatternKey) {
    return _.get(this.getLayerPatternDef(layerPatternKey), 'numPatternColors', null);
  }

}

// export a static singleton instance
const loadedDefsHelper = new LoadedDefsHelper();
export default loadedDefsHelper;


