import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LoadingSVG from '../../assets/icons/LoadingSVG';


class SublimatedProductsDesignImageLoader extends Component {


  // NOTE: if you extend propTypes in child component class, you will get a react warning.
  static get propTypes() {
    return {
      // eslint-disable-next-line
      ...super.propTypes,
      src: PropTypes.string,
      alt: PropTypes.string,
    }
  };

  static get defaultProps() {
    return {
      ...super.defaultProps,
      src: null,
      alt: null,
    }
  };

  constructor(props) {
    super(props);

    this.onImgLoaded = this.onImgLoaded.bind(this);
    this.onImgError = this.onImgError.bind(this);

    this.state = {
      src: this.props.src,
      init: false,
      loaded: false,
      success: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // compare specific props and watch for changes.
    // if any of those props change, then update them in state
    if (this.props.src !== prevState.src) {
      this.setState({ src: this.props.src, loaded: false, success: false });
    }
  }

  onImgLoaded(src) {
    if (!src || this.state.src !== src) { return; }
    this.setState({ loaded: true, success: true });
  }

  onImgError(src) {
    if (!src || this.state.src !== src) { return; }
    this.setState({ loaded: true, success: false });
  }

  render() {

    let src = this.state.src;
    let alt = this.props.alt;

    return (
        <div className="card-img-top image-center">
      <div className="test-product-image-loader preview-image center-text">
        <div className={"loading-spinner " + (!this.state.loaded? "show": "hide")}>
          <LoadingSVG height="32" width="32" fill={"black"} />
        </div>
        <img className= "card-img-top"src={src} alt={alt} onLoad={() => this.onImgLoaded(src)} onError={() => this.onImgError(src)}/>
      </div>
      </div>
    );
  }
}


export default SublimatedProductsDesignImageLoader;
