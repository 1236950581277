import _ from 'lodash';
import stateProvider from './stateProvider';
import helpers from '../helpers';
import AppConstants from '../constants/appConstants';


class PricingHelper {

  // state helpers

  getStateVal(key, defaultValue = null) {
    return stateProvider.get(key, defaultValue);
  }

  // tests

  unitTests() {
    return {
    };
  }

  // pricing helpers

  getDecorationPricingRequestDataFromState() {

    // this will build the pricing request data, without the pricing values.
    // once sent to the API, the server will populate the pricing values.

    /* // sample data
    {
      "entities":[
        {"view":"front","locationCode":"CF","entityType":"custom-text","decorationMethod":"screenprint-silicone","numDecorationColors":2,"quantity":4},
        {"view":"front","locationCode":"LC","entityType":"custom-art","decorationMethod":"screenprint-silicone","numDecorationColors":1,"quantity":4},
        {"view":"back","locationCode":"UB","entityType":"player-name","decorationMethod":"screenprint-duratek","numDecorationColors":1,"quantity":4},
        {"view":"back","locationCode":"CB","entityType":"player-number","decorationMethod":"screenprint-duratek","numDecorationColors":1,"quantity":4},
        {"upgradeKey":"stitching","upgradeCode":null,"numDecorationColors":1,"quantity":4}
      ],
      "quantity":4
    }; */

    let customLocationEntities = helpers.locations.getAllCustomConfigLocations();
    let upgrades = helpers.locations.getEnabledUpgrades();
    let roster = helpers.roster.getPrimaryRoster();
    let rosterStats = helpers.roster.getRosterStatsInfo(roster);
    let quantity = rosterStats.quantity;

    let locationEntities = _.chain(customLocationEntities)
      .map(v => {
        return this._createPricingEntityFromCustomLocationEntity(v, rosterStats)
      })
      .compact()
      .value();

    let upgradeEntities = _.chain(upgrades)
      .map(v => {
        return this._createPricingEntityFromCustomUpgrade(v, rosterStats)
      })
      .compact()
      .value();

    let entities = [].concat(locationEntities, upgradeEntities);

    if (helpers.product.supportsCustomFabrics()) {
      let fabricCode = helpers.customConfig.getSelectedFabricCode();
      if (fabricCode) {
        let fabricEntity = {
          decorationMethod: 'fabric',
          entityType: 'fabric',
          fabricCode: fabricCode,
          quantity: quantity
        };
        entities.push(fabricEntity);
      }
    }

    let decorationPricing = {
      entities,
      quantity
    };

    return decorationPricing;
  }

  _createPricingEntityFromCustomLocationEntity(customLocation, rosterStats) {

    // let __sample = {"view":"front","locationCode":"CF","entityType":"custom-text","decorationMethod":"screenprint-silicone","numDecorationColors":2,"quantity":4},;

    if (!customLocation || !customLocation.entity) { return null; }

    let view = customLocation.view;
    let locationCode = customLocation.code;
    let entityType = customLocation.entity.type;
    let decorationMethod = customLocation.entity.decorationMethod;

    let quantity = 1;
    if (rosterStats) {
      quantity = rosterStats.quantity;
      switch (entityType) {
        case AppConstants.EntityTypes.PlayerName:
          quantity = rosterStats.columnSums.name;
          break;
        case AppConstants.EntityTypes.PlayerNumber:
          quantity = rosterStats.columnSums.number;
          break;
        case AppConstants.EntityTypes.CustomText:
        case AppConstants.EntityTypes.CustomArt:
        default:
          break;
      }
    }

    let entityColorInfo = helpers.locations.getLocationEntityColorInfo(customLocation.entity);
    let numDecorationColors = entityColorInfo.numDecorationColors;

    return {
      view,
      locationCode,
      entityType,
      decorationMethod,
      numDecorationColors,
      colorCodes: entityColorInfo.decorationColors,
      quantity
    };
  }


  _createPricingEntityFromCustomUpgrade(upgrade, rosterStats) {
    // {"upgradeKey":"stitching","upgradeCode":null,"numDecorationColors":1,"quantity":4}
    // {"decorationPricing": "braiding", "braidCode": "QSLVSU"}

    // stitching, braiding and other upgrades
    if (!upgrade) { return null; }

    let upgradeKey = _.get(upgrade, 'key', null);
    let upgradeCode = _.get(upgrade, 'code', null);
    let braidCode = _.get(upgrade, 'braidCode', null);
    let numDecorationColors = _.get(upgrade, 'numDecorationColors', 1);
    let quantity = rosterStats? rosterStats.quantity: 0;

    return {
      decorationMethod: upgradeKey, //required on server
      entityType: upgradeKey,
      upgradeKey,
      upgradeCode,
      braidCode,
      numDecorationColors,
      quantity
    };
  }

}

// export a static singleton instance
const pricingHelper = new PricingHelper();
export default pricingHelper;


