import base from './defaults/defaultUser'; 


class UserValidator {

  validate(val) {
    val = Object.assign({}, base, val); 
    return val;
  }

}

const userValidator = new UserValidator(); 
export default userValidator; 
