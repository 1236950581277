import React, { Component } from 'react'; 
import { SearchSVG } from "../../../../../../assets/icons/SsearchSVG"; 

export default class SystemArtSearchBar extends Component {
  render() {
    let  { search, searchValue } = this.props; 
    return (<div className="asset-library__search">
      <input 
        className="asset-library__search_bar" 
        placeholder="Search"
        value={searchValue} 
        onChange={search} />
      <div className="asset-library__search_append">
        <SearchSVG height={25} width={25} fill={"lightgray"} />
      </div>
    </div>); 
  }
}
