
import _ from 'lodash';
import objectScaling from './objectScaling';
import helpers from '../../../helpers';

export default function objectModified(event, canvas, dndRedux, props) {
  let cloneBeforeModified = props.navigation.originalEntityPosition;
  let target = event.target;
  // if it gets rotated outside obj
  let zoom = canvas.getZoom();
  let id = event.target.id.split("-");
  id = id[1];

  objectScaling(event, canvas);
  target = event.target
  target.setCoords();

  // got the bound box after the scaling has happened because old values were coming in when setting the location.
  let locationDetails = _.find(canvas.getObjects(), {id:`location-${id}`});
  let objBoundingRect = target.getBoundingRect();
  if (locationDetails){

    let locationDetailsBoundingRect =  locationDetails.getBoundingRect();
    if ( objBoundingRect.left < locationDetailsBoundingRect.left){
      target.left = locationDetailsBoundingRect.left / zoom  + objBoundingRect.width / zoom / 2 ;
    }
    if ( objBoundingRect.top < locationDetailsBoundingRect.top){
      target.top = locationDetailsBoundingRect.top / zoom  + objBoundingRect.height / zoom / 2 ;
    }
    if ( objBoundingRect.left + objBoundingRect.width > locationDetailsBoundingRect.left + locationDetailsBoundingRect.width){
      target.left = (locationDetailsBoundingRect.left + locationDetailsBoundingRect.width ) / zoom  - objBoundingRect.width / zoom / 2 ;
    }
    if ( objBoundingRect.top + objBoundingRect.height > locationDetailsBoundingRect.top + locationDetailsBoundingRect.height){
      target.top = (locationDetailsBoundingRect.top + locationDetailsBoundingRect.height ) / zoom  - objBoundingRect.height / zoom / 2 ;
    }

    // set opacity back to 1 since when moving outside location it gets set to .5;
    target.set({opacity:1});

    target.setCoords();
    let allObjects = canvas.getObjects();
    _.forEach(allObjects, (x)=>{
      if ( x.id.includes("trash")){
        canvas.remove(x);
      }
    })



    if( props && props.navigation && props.navigation.drag ){
      props.dragItem(false);
      // I have no drop location
      if ( !dndRedux.hasLocation ){
        // set entity back to its start location before moved.
        // if I am outside my original location box
        let pointer = {x:event.e.layerX, y:event.e.layerY};
        let locationDetailsBoundingRect =  locationDetails.getBoundingRect();
        if( (( pointer.x < ( locationDetailsBoundingRect.left  ) ) ||
          ( pointer.x > ( locationDetailsBoundingRect.left  + locationDetailsBoundingRect.width  ) ) ||
          ( pointer.y < ( locationDetailsBoundingRect.top  ) ) ||
          ( pointer.y > ( locationDetailsBoundingRect.top  + locationDetailsBoundingRect.height  ) ))
        ) {
          // set entity back to its start location before moved.
          if( cloneBeforeModified ){
            target.left = cloneBeforeModified.left;
            target.top = cloneBeforeModified.top;       
          }
        } else if(props.baseProduct.configurationType === 'custom-sub'){
            //for sublimated products do not allow text to move inside the location box
            if( cloneBeforeModified ){
              target.left = cloneBeforeModified.left;
              target.top = cloneBeforeModified.top;       
            }
          }
      }
    }
  } else {
    // if locationDetails is not defined that means we are on a different view than the original.
    if ( dndRedux && !dndRedux.hasLocation ){
      canvas.remove(target);
      // get original canvas
      // add target back to location
      let location = _.find(props.baseProduct.locations, {code: id});
      let canvasId = `canvas-${location.view}`
      let originalCanvas = _.get(document.getElementById(canvasId), 'fabric', null);
      // set entity back to its start location before moved.
      if ( cloneBeforeModified ) {
        target.left = cloneBeforeModified.left;
        target.top = cloneBeforeModified.top; 
        let locationContent = _.find(props.customConfig.locations, {code: id});
        props.setView( locationContent.view );
      }
      originalCanvas.add(target);
    }
  }

  if ( dndRedux && dndRedux.hasLocation ){
    // here we call create new location or swap locations with the locations from dndRedux;
    
    let locationTarget = dndRedux.locationB;
    let locationSource = dndRedux.locationA;
    let locationInfo = _.find(props.baseProduct.locations, {code: locationTarget});

    let sourceContent = helpers.locations.getCustomConfigLocationEntityData(dndRedux.locationA);
    let bpLocationDetails = _.find(props.baseProduct.locations, {code: dndRedux.locationB});
    let allowedEntities = bpLocationDetails.allowedEntities;

    let sourceIsAllowedEntity = _.find(allowedEntities, {type: sourceContent.entity.type});

    if( sourceIsAllowedEntity ){
      swapOrReplace(locationInfo, locationTarget ,locationSource, props);
    } else {
      // else this is not an allowed entity. red location.
      canvas.remove(target);
      // get original canvas
      // add target back to location
      let location = _.find(props.baseProduct.locations, {code: id});
      let canvasId = `canvas-${location.view}`;
      let originalCanvas = _.get(document.getElementById(canvasId), 'fabric', null);
      if (originalCanvas) {
        originalCanvas.add(target);
      }
      // do nothing / maybe a pop up saying location not allowed.
      // set entity back to its start location before moved.
      if ( cloneBeforeModified ){
        target.left = cloneBeforeModified.left;
        target.top = cloneBeforeModified.top;
        let locationContent = _.find(props.customConfig.locations, {code: id});
        props.setView( locationContent.view );
      }
    }

  }
  let allObjects = _.filter(canvas.getObjects() , (x)=>x.id.includes("trash"))
  _.forEach(allObjects, (x)=>{
    if ( x.id !== `trash-${id}`){
      canvas.remove(x);
    }
  });
  props.dragItem(false);

  // set back to default borders and control settings.
  if ( cloneBeforeModified ){
    target.hasBorders = cloneBeforeModified.hasBorders;
    target.hasControls = cloneBeforeModified.hasControls;
  }
  target.opacity = 1;
  props.setPendingLocation( null );
  let bounds = {x:target.left , y:target.top , w:(target.width * target.scaleX ), h:(target.height * target.scaleY), r:target.angle};
  return bounds;
}

function swapOrReplace(e, locationTarget, currentLocationCode, props) {
  let chosenLocationCode = locationTarget;

  let chosenLocation = helpers.locations.getLocation(chosenLocationCode); 
  let doesLocationHaveContent = helpers.locations.getCustomConfigLocationEntityData(chosenLocationCode);
  
  if ( !doesLocationHaveContent ){
    let subTab;
    let sourceLocationCode = currentLocationCode;
    let targetLocationCode = locationTarget;
    props.moveToEmptyLocation( props.customConfig.locations, sourceLocationCode, targetLocationCode );
    props.setActiveLocation(chosenLocationCode, props.navigation);
    props.setView(chosenLocation && chosenLocation.view); 
    let locationContent = _.find(props.customConfig.locations, { code: sourceLocationCode });
    props.SetEditDecorationType(locationContent.entity.type)
    if (locationContent.entity.type === 'custom-text' || locationContent.entity.type === 'player-name' || locationContent.entity.type === 'player-number') {
      subTab = "TEXT";
      props.SetDecorationSubtab(subTab);
      let tabObj = { tab: "decorations", subTab: "add-text", page: "edit" }
      props.setActiveTab(tabObj)
    } else {
      subTab = "GRAPHIC";
      props.SetDecorationSubtab(subTab);
      let tabObj = { tab: "decorations", subTab: "add-art", page: "edit" }
      props.setActiveTab(tabObj)
    }
    // let tabObj = { tab: props.navigation.activeTab.tab, page: "sub-edit" }
    // props.setActiveTab(tabObj);
  } else {

    let sourceLocation = _.find( props.customConfig.locations, { code: currentLocationCode});
    let targetLocation = _.find( props.customConfig.locations, { code: locationTarget });
    props.previewSample(props.baseProduct, sourceLocation.code, targetLocation.code, props.navigation.pendingLocation);
    props.locationUsed(  sourceLocation, targetLocation, true );
  }
  let hasLocation = false;
  let locationA = "";
  let locationB = "";
  props.dndLocToLoc( hasLocation, locationA, locationB );
}

