import React, {Component} from "react";
import { connect } from "react-redux";
import { toggleMoblieTab, setActiveTab, setActiveLocation} from "../../../redux/reducers/navigation/navigationActions"; 
import HelpBubble from "../../global/helpBubble/helpBubble";
import ChevronLeftSVG from "../../../assets/icons/ChevronLeftSVG";
import QuestionCircleSVG from "../../../assets/icons/QuestionCircleSVG";

class OptionHeading extends Component {
  
  constructor(props){
    super(props); 
    this.state = {
      questionHover: "#A9A9A9",
      helpBubble: false
    }
    this.closeMobileEditScreen = this.closeMobileEditScreen.bind(this); 
  }

  closeMobileEditScreen(){
    this.props.setActiveTab({
      tab: 'colors',
      page: 'main'
    }); 
    this.props.setActiveLocation(null); 
    this.props.toggleMoblieTab(false);

  }

  render() {
    const displayButton = this.props.backFunction;
    // console.log("DisplayButton:", displayButton);
    const toolTip = this.props.toolTip;
    const helpToolTip = this.props.helpToolTip;
    let mobile = (this.props.mobile) ? this.props.mobile : this.props.description; 
    let tablet = (this.props.tablet) ? this.props.tablet : this.props.description; 
    return (
      <div className={"option-heading align-items-center justify-content-center pb-3"}>
        { 
          (displayButton) ? (
            <button aria-label={toolTip} data-balloon-pos="right" className="option-heading__back-btn p-0 flex-shrink-0 button--light" onClick={() => this.props.backFunction()}>
              <ChevronLeftSVG width="20px" height="20px"/>
            </button>
          ) : null 
        }
        <div className={"option-heading__content d-flex flex-column justify-content-center mx-auto text-left"}>
          { (this.props.title && !this.props.helpBubble) ? (<p className="option-heading__title font-wight-normal">{this.props.title}</p>) : null }
          { (this.props.title && this.props.helpBubble) ?
          <div className="option-heading__help-bubble-container">
            <div/>
            <div>
              {this.props.title}
            </div>
            <div onMouseOver={() => this.setState({questionHover: "black", helpBubble: true})} onMouseLeave={() => this.setState({questionHover: "#A9A9A9", helpBubble: false})}>
            <QuestionCircleSVG width="20px" height="20px" fill={this.state.questionHover} />
            <HelpBubble bubbleMessage={helpToolTip} toggle={this.state.helpBubble} />
            </div>
          </div>
        :null}
          {/*{ (this.props.description) ? (<p className="option-heading__description font-wight-normal">{this.props.description}</p>) : null }
          <p className="option-heading__tablet_discription font-wight-normal">{tablet}</p>
          <p className="option-heading__mobile_discription font-wight-normal">{mobile}</p>*/}
        </div>
        {
          (displayButton) ? (
            <button aria-label={toolTip} data-balloon-pos="up" style={{opacity: "0", pointerEvents: "none"}} className="option-heading__back-btn p-0 flex-shrink-0 button--light" onClick={() => this.props.backFunction()}>
              <ChevronLeftSVG width="20px" height="20px"/>
            </button>
          ) : null 
      }
        <div className="option-heading__close">
          <button 
            aria-label="Close"
            className="option-heading__close__btn close" 
            onClick={this.closeMobileEditScreen}>
            <span style={{textShadow:"none"}} aria-hidden="true">&times;</span>
          </button> 
        </div>
      </div>
    )
  }
}

export default connect(null, { toggleMoblieTab, setActiveTab, setActiveLocation })(OptionHeading);
