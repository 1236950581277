import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearRenderPreviewImagesAction } from '../../../redux/reducers/appActions/appActionsActions';
import PreviewCanvasTwo from '../../previewCanvasTwo/PreviewCanvasTwo';
import _ from 'lodash'
import Promise from 'bluebird';
import PromiseRetry from 'bluebird-retry';


class PreviewRenderGenerator extends Component {

  static get propTypes() {
    return {
      views: PropTypes.array,
      renderHeight: PropTypes.number
    }
  }

  static get defaultProps() {
    return {
      views: [],
      renderHeight: 600,
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      capturing: false
    };

    this._captureHandlers = [];
    this.createImageSnapshots = this.createImageSnapshots.bind(this);
    this.setPreviewCaptureHandler = this.setPreviewCaptureHandler.bind(this);
  }

  componentDidMount(){
    if (_.isFunction(this.props.setCapture)) {
      this.props.setCapture(this.createImageSnapshots);
    }
  }

  componentDidUpdate(oldProps) {
    let renderPreviewsAction = _.get(this.props, 'actions.renderPreviewsAction', null);
    if (renderPreviewsAction && _.isFunction(renderPreviewsAction)) {
      this.props.clearRenderPreviewImagesAction();
      this.setState({ capturing: true }, () => {
        setTimeout(() => {
          this.createImageSnapshots().then(res => {
            renderPreviewsAction(res);
            this.setState({ capturing: false });
          });
        }, 150);    // give the preview component a little time before trying the first capture
      });
    }
  }

  onPreviewLoaded() {
  }

  setPreviewCaptureHandler(idx, view, handler) {
    this._captureHandlers[idx] = { view, handler };
  }

  async createImageSnapshots() {
    return Promise.map(this._captureHandlers, v => {
      // retry until we get valid image data, then return the results
      return PromiseRetry(() => {
        return v.handler().then(data => {
          if (_.isEmpty(data)) { throw new Error('invalid image capture data'); }
          let view = _.get(v, 'view.key', null);
          let name = _.get(v, 'view.name', null);
          return { view, name, data };
        });
      }, { timeout: 15000, interval: 100, max_interval: 1000, backoff: 2, throw_original: true });
    }).catch(err => {
      console.error(err);
    });
  }

  render() {
    if (!this.state.capturing) { return null; }

    let views = _.compact(this.props.views);

    return (
      <div className="preview-render-generator">
        <div className="preview-render-content">
          {_.map(views, (view, idx) => (
            <div key={idx} className="preview-render-item">
              <PreviewCanvasTwo
                view={view}
                isThumbnail={true}
                showHover={false}
                showShadow={true}
                animate={false}
                sample={"render"}
                borders={false}
                onLoaded={loaded => { this.onPreviewLoaded(idx, view, loaded) }}
                setCapture={capture => { this.setPreviewCaptureHandler(idx, view, capture) }}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }

}


function mapStateToProps(state) {
  return {
    actions: state.actions,
    views: state.baseProduct && state.baseProduct.views
  };
}

export default connect(mapStateToProps, { clearRenderPreviewImagesAction })(PreviewRenderGenerator);
