import * as types from '../../actions/actionTypes';
import _ from 'lodash';

export function setUserInitState(user) {
  return {type: types.SET_USER_INIT_STATE, user};
}

export function addUploadedArt(user, userArtAsset) {
  let userArtAssets = _.clone(user.userArtAssets || []);
  userArtAssets.unshift(userArtAsset);

  return {type: types.ADD_UPLOADED_ART, userArtAssets};
}

export function setArtAssetToDefault(id, placeholder, artList){
  let userArtAssets = _.cloneDeep(artList); 
  _.each(userArtAssets, (art) => {
    if (art.id === id){
      art.url=placeholder; 
    }
  }); 
  return {type: types.ADD_UPLOADED_ART, userArtAssets}
}

export function updatePatternHistory(color, colorIndex, layerKey, customConfig, userPatterns) {
  let oldConfig = _.cloneDeep(customConfig)
  let oldUserPatterns = _.cloneDeep(userPatterns)
  let configLayer = _.find(oldConfig.customLayers.layers, {key: layerKey})
  let configPattern = _.get(configLayer, "pattern.key");
  
  if (!configPattern) {
    configPattern = configLayer.pattern;
  }

  let configColors = _.get(configLayer, "colors")
  if (!configColors) {
    configColors = configLayer.pattern.colors;
  }

  let newPattern = {key: configPattern, colors: configColors}
  let patternExists = _.find(oldUserPatterns, {key: configPattern});
  if (patternExists) {
    let patternIndex = _.findIndex(userPatterns, {key: patternExists.key})
    oldUserPatterns[patternIndex].colors = configColors
  } else {
    oldUserPatterns.push(newPattern)
  }
  return {type: types.USER_PATTERN_HISTORY, patterns: oldUserPatterns}
}

export function updateUserArtAssetData(userArtAssets){
  return { type : types.UPDATE_ART_ASSET_DATA, userArtAssets }; 
}