import React from "react";

const SlashLeftSVG = (props) => {
  const { className, height, width, fill = "black", stroke = "black", strokeWidth = "2", onClick } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={className} height={height} width={width} fill={fill} stroke={stroke} strokeWidth={strokeWidth} onClick={onClick}>
      <path d="M508.4,33.3L33.3,508.4c-4.7,4.7-12.3,4.7-17,0L3.6,495.7c-4.7-4.7-4.7-12.3,0-17L478.7,3.6c4.7-4.7,12.3-4.7,17,0 l12.7,12.7C513.1,21,513.1,28.6,508.4,33.3z" />
    </svg>
  );
};

export default SlashLeftSVG;
