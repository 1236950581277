import React from "react";
import {ErrorDisplayHandler} from ".";
import AppConfig from "../../config";
import GeneralError from "../../components/statusPages/generalErrorPage/generalErrorPage";


export default class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  componentDidCatch(error, errorInfo) {

    if (!error) { return; }

    if (AppConfig.logReactErrors) {
      ErrorDisplayHandler.logReactError(error, errorInfo);
    }

    this.setState({ error, errorInfo });
  }


  render() {

    if (AppConfig.showFriendlyErrorPage && this.state.error) {
      return (<GeneralError error={this.state.error} errorInfo={this.state.errorInfo} />);
    }

    //rendering this.props.children will allow stack traces to be shown on the screen when there are errors and will allow the app to load when there are no errors
    return this.props.children;
  }

}

