import * as types from '../../actions/actionTypes';


export function mergeLoadedDefs(loadedDefs) {
  return {type: types.MERGE_LOADED_DEFS, loadedDefs};
}

export function setLoadedDefs(loadedDefs) {
  return {type: types.SET_LOADED_DEFS, loadedDefs};
}
