let pattern1 = require("../../../src/assets/images/patterns/pattern1.jpg")
let pattern2 = require("../../../src/assets/images/patterns/pattern2.jpg")
let pattern3 = require("../../../src/assets/images/patterns/pattern3.jpg")
let pattern4 = require("../../../src/assets/images/patterns/pattern4.jpg")
let pattern5 = require("../../../src/assets/images/patterns/pattern5.jpg")
let pattern6 = require("../../../src/assets/images/patterns/pattern6.jpg")
let solidPattern = require("../../../src/assets/images/patterns/solid-pattern.JPG")

export default {
  fonts: [
    {
      id: 0,
      key: 'aachen',
      name: 'AACHEN',
      fontFamily: 'AACHEN',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/aachen.png'
    },
    {
      id: 1,
      key: 'abberancy',
      name: 'ABBERANCY',
      fontFamily: 'ABBERANCY',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/abberancy.png'
    },
    {
      id: 2,
      key: 'add_city_boy',
      name: 'ADD CITY BOY',
      fontFamily: 'ADD CITY BOY',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/add_city_boy.png'
    },
    {
      id: 3,
      key: 'air_millhouse',
      name: 'AIR MILLHOUSE',
      fontFamily: 'AIR MILLHOUSE',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/air_millhouse.png'
    },
    {
      id: 4,
      key: 'apache',
      name: 'APACHE',
      fontFamily: 'APACHE',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/apache.png'
    },
    {
      id: 5,
      key: 'arial',
      name: 'ARIAL',
      fontFamily: 'ARIAL',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/arial.png'
    },
    {
      id: 6,
      key: 'athletic_script',
      name: 'ATHLETIC SCRIPT',
      fontFamily: 'ATHLETIC SCRIPT',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/athletic_script.png'
    },
    {
      id: 7,
      key: 'billboard',
      name: 'BILLBOARD',
      fontFamily: 'BILLBOARD',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/billboard.png'
    },
    {
      id: 8,
      key: 'ball_park',
      name: 'BALL PARK',
      fontFamily: 'BALL PARK',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/ball_park.png'
    },
    {
      id: 9,
      key: 'brush_script',
      name: 'BRUSH SCRIPT',
      fontFamily: 'BRUSH SCRIPT',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/brush_script.png'
    },
    {
      id: 10,
      key: 'cooper_black',
      name: 'COOPER BLACK',
      fontFamily: 'COOPER BLACK',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/cooper_black.png'
    },
    {
      id: 11,
      key: 'collegiate',
      name: 'COLLEGIATE',
      fontFamily: 'COLLEGIATE',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/collegiate.png'
    },
    {
      id: 12,
      key: 'classic_script',
      name: 'CLASSIC SCRIPT',
      fontFamily: 'CLASSIC SCRIPT',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/classic_script.png'
    },
    {
      id: 13,
      key: 'elite',
      name: 'ELITE',
      fontFamily: 'ELITE',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/elite.png'
    },
    {
      id: 14,
      key: 'evolution',
      name: 'EVOLUTION',
      fontFamily: 'EVOLUTION',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/evolution.png'
    },
    {
      id: 15,
      key: 'futura',
      name: 'FUTURA',
      fontFamily: 'FUTURA',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/futura.png'
    },
    {
      id: 16,
      key: 'full_block',
      name: 'FULL BLOCK',
      fontFamily: 'FULL BLOCK',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/full_block.png'
    },
    {
      id: 17,
      key: 'falcon',
      name: 'FALCON',
      fontFamily: 'FALCON',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/falcon.png'
    },
    {
      id: 18,
      key: 'goudy',
      name: 'GOUDY',
      fontFamily: 'GOUDY',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/goudy.png'
    },
    {
      id: 19,
      key: 'geared',
      name: 'GEARED',
      fontFamily: 'GEARED',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/geared.png'
    },
    {
      id: 20,
      key: 'garamond',
      name: 'GARAMOND',
      fontFamily: 'GARAMOND',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/garamond.png'
    },
    {
      id: 21,
      key: 'homestead',
      name: 'HOMESTEAD',
      fontFamily: 'HOMESTEAD',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/homestead.png'
    },
    {
      id: 22,
      key: 'hometown',
      name: 'HOMETOWN',
      fontFamily: 'HOMETOWN',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/hometown.png'
    },
    {
      id: 23,
      key: 'interval',
      name: 'INTERVAL',
      fontFamily: 'INTERVAL',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/interval.png'
    },
    {
      id: 24,
      key: 'machine',
      name: 'MACHINE',
      fontFamily: 'MACHINE',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/machine.png'
    },
    {
      id: 25,
      key: 'mistral',
      name: 'MISTRAL',
      fontFamily: 'MISTRAL',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/mistral.png'
    },
    {
      id: 26,
      key: 'old_english',
      name: 'OLD ENGLISH',
      fontFamily: 'OLD ENGLISH',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/old_english.png'
    },
    {
      id: 27,
      key: 'plain_block',
      name: 'PLAIN BLOCK',
      fontFamily: 'PLAIN BLOCK',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/plain_block.png'
    },
    {
      id: 28,
      key: 'playmaker',
      name: 'PLAYMAKER',
      fontFamily: 'PLAYMAKER',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/playmaker.png'
    },
    {
      id: 29,
      key: 'serpentine',
      name: 'SERPENTINE',
      fontFamily: 'SERPENTINE',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/serpentine.png'
    },
    {
      id: 30,
      key: 'sans_inserat',
      name: 'SANS INSERAT',
      fontFamily: 'SANS INSERAT',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/sans_inserat.png'
    },
    {
      id: 31,
      key: 'tiffany',
      name: 'TIFFANY',
      fontFamily: 'TIFFANY',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/tiffany.png'
    },
    {
      id: 32,
      key: 'text_and_tail_straight',
      name: 'TEXT AND TAIL STRAIGHT',
      fontFamily: 'TEXT AND TAIL STRAIGHT',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/text_and_tail_straight.png'
    },
    {
      id: 33,
      key: 'unicorn',
      name: 'UNICORN',
      fontFamily: 'UNICORN',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/unicorn.png'
    },
    {
      id: 34,
      key: 'university',
      name: 'UNIVERSITY',
      fontFamily: 'UNIVERSITY',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/university.png'
    },
    {
      id: 35,
      key: 'warrior_1',
      name: 'WARRIOR 1',
      fontFamily: 'WARRIOR 1',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/warrior_1.png'
    },
    {
      id: 36,
      key: 'warrior_2',
      name: 'WARRIOR 2',
      fontFamily: 'WARRIOR 2',
      fontUrl: null,
      previewUrl: './images/testProduct/fonts/warrior_2.png'
    }
  ],

  textEffects: [
    {
      id: 0,
      key: 'straight',
      name: 'Straight',
      previewUrl: './images/testProduct/text-effects/straight.png'
    },
    {
      id: 1,
      key: 'arch_up',
      name: 'Arch Up',
      previewUrl: './images/testProduct/text-effects/vert-arch.png'
    },
    {
      id: 2,
      key: 'arch_down',
      name: 'Arch Down',
      previewUrl: './images/testProduct/text-effects/rev-vert-arch.png'
    },
    {
      id: 3,
      key: 'bridged',
      name: 'Bridged',
      previewUrl: './images/testProduct/text-effects/bridged.png'
    },
    {
      id: 4,
      key: 'bookends',
      name: 'Bookends',
      previewUrl: './images/testProduct/text-effects/bookends.png'
    },
    {
      id: 5,
      key: 'bowtie',
      name: 'Bowtie',
      previewUrl: './images/testProduct/text-effects/bowtie.png'
    },
    {
      id: 6,
      key: 'text-and-tail-straight',
      name: 'Text and Tail Straight',
      previewUrl: './images/testProduct/text-effects/text-and-tail-straight.png'
    },
    {
      id: 7,
      key: 'text-and-tail',
      name: 'Text and Tail',
      previewUrl: './images/testProduct/text-effects/text-and-tail.png'
    }
  ],

  patterns: [{
    key: "flat",
    name: "Flat",
    numberOfColors: 1,
    customProductId: 0,  // used to render colorized asset
    url: pattern1
  }, {
    key: "force",
    name: "Force",
    numberOfColors: 2,
    customProductId: 0,  // used to render colorized asset
    url: pattern2
  }, {
    key: "plaid",
    name: "Plaid",
    numberOfColors: 3,
    customProductId: 0,  // used to render colorized asset
    url: pattern3
  }, {
    key: "carbon",
    name: "Carbon",
    numberOfColors: 3,
    customProductId: 0,  // used to render colorized asset
    url: pattern4
  }, {
    key: "drive",
    name: "Drive",
    numberOfColors: 4,
    customProductId: 0,  // used to render colorized asset
    url: pattern5
  }, {
    key: "digi-camo",
    name: "Digital Camouflage",
    numberOfColors: 5,
    customProductId: 0,  // used to render colorized asset
    url: pattern6
  },
  {
    key: "solid",
    name: "Solid",
    numberOfColors: 1,
    customProductId: 0,  // used to render colorized asset
    url: solidPattern
  },
  ],

  patternPalettes: [
    {
      key: "cg-sublimated-patterns",
      patterns: ["flat", "force", "plaid", "carbon", "drive", "digital-camouflage"]
    }
  ],

  colorPalettes:
    [{
      colors: ["BK", "BO", "CR", "CB", "EB", "FS", "GD"],
      key: "athletic",
      name: "Athletic"
    }, {
      colors: ["GR", "HC", "HP", "KG", "LM", "MA"],
      key: "battlefield",
      name: "Battlefield"
    }, {
      colors: ["GR", "HC", "HP", "KG", "LM", "MA"],
      key: "complementary",
      name: "Complementary"
    }, {
      colors: ["NY", "PK", "PU", "RD", "RY", "SO", "SY", "SI", "VG", "WH"],
      key: "bright",
      name: "Bright"
    }, {
      colors: ["NY", "PK", "PU", "RD", "RY", "SO", "SY", "SI", "VG", "WH"],
      key: "earth",
      name: "Earth"
    }, {
      colors: ["BK", "BO", "CR", "CB", "EB", "FS", "GD"],
      key: "flat",
      name: "Flat"
    }, {
      colors: ["BK", "BO", "CR", "CB", "EB", "FS", "GD"],
      key: "shade",
      name: "Shaded"
    }, {
      colors: ["GR", "HC", "HP", "KG", "LM", "MA"],
      key: "anal",
      name: "Analogous"
    }, {
      colors: ["GR", "HC", "HP", "KG", "LM", "MA"],
      key: "neutral",
      name: "Neutral"
    }, {
      colors: ["NY", "PK", "PU", "RD", "RY", "SO", "SY", "SI", "VG", "WH"],
      key: "warm",
      name: "Warm"
    }, {
      colors: ["NY", "PK", "PU", "RD", "RY", "SO", "SY", "SI", "VG", "WH"],
      key: "cool",
      name: "Cool"
    }, {
      colors: ["NY", "PK", "PU", "RD", "RY", "SO", "SY", "SI", "VG", "WH"],
      key: "electric",
      name: "Electric"
    }, {
      colors: ["NY", "PK", "PU", "RD", "RY", "SO", "SY", "SI", "VG", "WH"],
      key: "spirit",
      name: "Spirit"
    }],

  fabrics: [
    {
      code: "9",
      name: "Fabric 9",
      description: "stretch warp knit pin dot mesh (Fabric 9)",
      features: ["100% Polyester"],
      benefits: [],
      faqUrl: "https://www.foundersport.com/service/faq/",
      swatchUrl: "./images/fabrics/fabric_9.png",
      priceCents: 0,
      isRecommended: false,
    },
    {
      code: "A",
      name: "Fabric A",
      description: "mock mesh with moisture management fibers (5.5 oz.) (Fabric A)",
      features: ["100% Polyester"],
      benefits: [],
      swatchUrl: "./images/fabrics/fabric_A.png",
      faqUrl: "https://www.foundersport.com/service/faq/",
      priceCents: 0,
      isRecommended: false,
    },
    {
      code: "C",
      name: "Fabric C",
      description: "90% Nylon / 10% Spandex stretch mesh bottom body (Fabric C)",
      features: ["100% Polyester"],
      benefits: [],
      faqUrl: "https://www.foundersport.com/service/faq/",
      swatchUrl: "./images/fabrics/fabric_C.png",
      priceCents: 0, // 300,
      isRecommended: false,
    },
    {
      code: "H",
      name: "Fabric H",
      description: "12 oz. double knit (Fabric H)",
      features: ["100% Polyester"],
      benefits: [],
      faqUrl: "https://www.foundersport.com/service/faq/",
      swatchUrl: "./images/fabrics/fabric_H.png",
      priceCents: 0,
      isRecommended: false,
    },
    {
      code: "I",
      name: "Fabric I",
      description: "14 oz. warp knit (Fabric I)",
      features: ["100% Polyester"],
      benefits: [],
      faqUrl: "https://www.foundersport.com/service/faq/",
      swatchUrl: "./images/fabrics/fabric_I.png",
      priceCents: 0,
      isRecommended: false,
    },
    {
      code: "M",
      name: "Fabric M",
      description: "Pointelle mesh (Fabric M)",
      features: ["92% polyester / 8% spandex"],
      benefits: [],
      swatchUrl: "./images/fabrics/fabric_M.png",
      faqUrl: "https://www.foundersport.com/service/faq/",
      priceCents: 0,
      isRecommended: true,
    },
  ]

};

