import {EDIT_SEARCH_TERM,
   UPDATE_ART_ASSETS, 
   UPDATE_QUICK_LINK_SELECTION,
   UPDATE_ART_CATEGORIES, 
   CHANGE_CATALOG_NAVIGATION, 
   TOGGLE_SYSTEM_ART_ITEMS, 
   EDIT_SEARCH_CATEGORIES_TERM} from '../../actions/actionTypes';

const initialState = {
  catalogNavigation: [],
  searchTerm: "",
  searchCategoryTerm: "",
  showSearchResults: false, 
  quickLinkSelection: "all",
  assets: [],
  categories: {}
};

export default function systemArtReducer(state = initialState, action) {
  switch (action.type) {
    case EDIT_SEARCH_CATEGORIES_TERM: 
      return Object.assign( {}, state, { searchCategoryTerm: action.searchTerm } ); 
    case UPDATE_QUICK_LINK_SELECTION: 
      return Object.assign( {}, state, { quickLinkSelection: action.link } ); 
    case EDIT_SEARCH_TERM:
      return Object.assign( {}, state, { searchTerm: action.searchTerm } ); 
    case CHANGE_CATALOG_NAVIGATION: 
      return Object.assign( {}, state, { catalogNavigation: action.nav } ); 
    case UPDATE_ART_ASSETS: 
      return Object.assign( {}, state,  { assets: action.assets } ); 
    case UPDATE_ART_CATEGORIES: 
      return Object.assign( {},  state,  { categories: action.categories } ); 
    case TOGGLE_SYSTEM_ART_ITEMS: 
      return Object.assign( {}, state, { showSearchResults: action.isActive } ); 
    default:
      return state;
  }
}
