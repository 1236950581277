import React, { Component } from 'react'; 
import { connect } from 'react-redux'; 
import _ from 'lodash'; 
import helpers from '../../../../../helpers'; 
import api from "../../../../../api"; 
import { updateQuickLink, toggleSystemArtResults, editSystemArtSearchTerm, updateArtAssets, updateSystemCategories, changeCatalogNavigation, updateSearchCategoryTerm} from '../../../../../redux/reducers/systemArt/systemArtActions';
import { previewSample, toggleLibraries, setPendingLocation, setView, setActiveTab, setActiveLocation } from '../../../../../redux/reducers/navigation/navigationActions';
import { locationUsed, systemArtModal } from '../../../../../redux/reducers/modal/modalActions'; 
import { addPendingLocationToLocations } from '../../../../../redux/reducers/customConfig/customConfigActions'; 
import SystemArtSearchBar from "./assetElements/SystemArtSearchBar"; 
import AssetResults from './assetElements/AssetResults';
import AssetTitleBar from './assetElements/AssetTitleBar';
import QuickLinks from './assetElements/QuickLinks'; 
import AddToDesignButton from '../../AddToDesignButton/AddToDesignButton';

class AssetLibrary extends Component {

  constructor(props){
    super(props); 
    this.state = {
      searchTerm: "",
      selectedItem: null, 
      selectedType: null
    };
  }

  componentDidUpdate(preProps){
    if (preProps.systemArt.assets !== this.props.systemArt.assets){
      let item = _.get(this.props.systemArt, "assets[0]", null); 
      this.selectItem(item); 
    }
  }

  async componentDidMount(){
    // this.searchAPI();
    let recommended = helpers.locations.findBestLocationByEntityType('custom-art', this.props.navigation.activeView);
    if (recommended) {
      this.props.setView(recommended.view);
      this.props.setActiveLocation(recommended.code, this.props.navigation);
    }
    this.setState({selectedItem: _.get(this.props.systemArt, "assets[0]", null)}); 


    if(_.isEmpty(this.props.systemArt.categories)){
      api.logos.getCategories().then( (res) => {
        let data = _.get(res, "data", []); 
        this.props.updateSystemCategories(data);  
      }); 
    }
  }

  navLinkSetTitle = (tree) => {
    this.setState({selectedType:tree}); 
  }

  quickSearch = async (link) => {
    let showItems = (link !== "all"); 
    this.props.updateSearchCategoryTerm(link); 
    this.props.editSystemArtSearchTerm(""); 
    this.props.updateQuickLink(link); 
    if (showItems){
      this.searchAPI(link);  
    } else {
      this.props.systemArtModal(); 
      this.props.toggleSystemArtResults(showItems);
    }
  }

  selectItem = (choice) => {
    let type = "custom-art";
    let value = "system-art";
    let view = this.props.navigation.activeView;
    let code = this.props.navigation.activeLocation;
    let artAsset = choice;
 
    let pendingLocation = helpers.navigation.makePendingLocation( type, value, view, code, artAsset);
    this.props.setPendingLocation( pendingLocation );

    this.setState({selectedItem: choice}); 
  }

  searchAPI = async (searchTerm) => {
    this.setState({searchTerm}); 
    api.logos.getAllItems().then((res) =>{
      let items = res.data.data; 
      _.each(items, (item) => {
        item.url = `http://api.dev.foundersportgroup.com/api/custom-mascot-logos/${item.id}/render`; 
      }); 
      let selectedItem = _.get(items, "[0]", null); 
      this.selectItem(selectedItem); 
      this.props.updateArtAssets(items); 
      this.props.toggleSystemArtResults(true); 
    });
  }

  handleSearch = async (event) => {
    let search = _.get(event, "target.value", ""); 
    search = _.trim(search); 
    this.props.editSystemArtSearchTerm(search);
    this.props.updateQuickLink("all");  
    if (search !== ""){
      this.searchAPI(search); 
    }; 
    
  }
  
  renderTitle(){
    let { showSearchResults, searchTerm, quickLinkSelection } = this.props.systemArt; 
    if (!showSearchResults){
      return "Select Type of Art"; 
    }
    if (quickLinkSelection === "all"){
      if (searchTerm === ""){
        searchTerm = this.state.searchTerm; 
      }
      return "Searching on " + searchTerm; 
    }
    return "Quick Link Category > " + _.capitalize(quickLinkSelection); 
  }

  render() {
    return (
      <div className="asset-library">
        <SystemArtSearchBar 
          searchValue={this.props.systemArt.searchTerm} 
          search={this.handleSearch} />
        <QuickLinks search={this.quickSearch} selected={this.props.systemArt.quickLinkSelection}/>
        <AssetTitleBar title={this.renderTitle()} />
        <AssetResults 
          items={ this.props.systemArt.assets } 
          select={ this.selectItem } 
          currentSelected={ this.state.selectedItem } />
          <AddToDesignButton />
      </div>
    );
  }
}

function mapStateToProps(state){
  return {
    systemArt: state.systemArt,
    customConfig: state.customConfig,
    baseProduct: state.baseProduct,
    loadedDefs: state.loadedDefs,
    navigation: state.navigation, 
    user: state.user
  };
}

const reduxActions = {
  systemArtModal,
  locationUsed,
  previewSample,
  toggleLibraries,
  setPendingLocation,
  addPendingLocationToLocations,
  setView, 
  setActiveTab,
  setActiveLocation, 
  updateArtAssets, 
  updateSearchCategoryTerm,
  editSystemArtSearchTerm,
  updateSystemCategories,
  changeCatalogNavigation,
  toggleSystemArtResults,
  updateQuickLink
};

export default connect(mapStateToProps, reduxActions)(AssetLibrary);
