import { translateKey } from "../../../helpers/translation";
import _ from "lodash";


export default function makeReadable(tableData, config){
  if (_.isEmpty(tableData) || _.isEmpty(config)) {
    return {};
  }

  const configKeys = config.columns.reduce((acc, column) => {
    const hasMultiple = typeof column.key === "object";
    const newVal = hasMultiple ? column.key.map(string => string) : [column.key];
    return acc = [...acc, ...newVal];
  }, []); // ["entityType", "view", "decorationMethod", "numDecorationColors", "priceCents" "subtotal"]
  const translateRelevantRowValues = row => {
    return Object.entries(row).reduce((acc, [key, value]) => {
      const isDisplayable = configKeys.some(tableColumn => tableColumn === key);
      return isDisplayable ? acc = {...acc, [key]: translateKey(value, value)} : acc;
    }, {});
  };
  return tableData.entities.map(translateRelevantRowValues);
}
