import * as types from '../../actions/actionTypes';
import Moment from "moment"
import _ from "lodash";
import designManager from '../../../helpers/designManager';
import helpers from '../../../helpers';

const time = new Moment();
const dm = designManager;

export default function customReducer(state = {}, action) {
  let newState = null;
  switch (action.type) {
    case types.LOAD_CONFIG: newState = Object.assign({}, action.data);
      break;
    case types.SET_STYLE_NUM: newState = Object.assign({}, state, { styleNum: action.styleNum });
      break;
    case types.SET_CONFIG_ID: newState = Object.assign({}, state, { configId: action.actionPayload.configId, isSavedDesign: action.actionPayload.isSavedDesign, isOrderedDesign: action.actionPayload.isOrderedDesign, corpAcctId: action.actionPayload.corpAcctId, name: action.actionPayload.name, isLoading: action.actionPayload.isLoading || false });
      break;
    case types.SET_WEB_REF: newState = Object.assign({}, state, { webRef: action.webRef });
      break;
    case types.DELETE_LOCATION: newState = Object.assign({}, state, { locations: action.updatedLocations });
      break;
    case types.UPDATE_NOTES: newState = Object.assign({}, state, { summaryNotes: action.text });
      break;
    case types.AMEND_LOCATION: newState = Object.assign({}, state, { locations: action.locations });
      break;
    case types.UPDATE_BOUNDS: newState = Object.assign({}, state, { locationBounds: action.locArray });
      break;
    case types.UPDATE_PRODUCT_COLOR: newState = Object.assign({}, state, action.temp);
      break;
    case types.UPDATE_CHROMAGEAR_COLOR: newState = Object.assign({}, state, action.temp);
      break;
    case types.UPDATE_PATTERN: newState = Object.assign({}, state, action.temp);
      break;
    case types.UPDATE_LOCATION: newState = Object.assign({}, state, action.payload);
      break;
    case types.UPDATE_UPGRADES: newState = Object.assign({}, state, { upgrades: action.upgrades });
      break;
    case types.UPDATE_ROSTER_ITEMS: newState = updateCell(state, action);
      break;
    case types.UPDATE_QUANTITY: newState = updateQuantityRoster(state, action);
      break;
    case types.ADD_ROSTER_ITEM: newState = addRow(state, action);
      break;
    case types.REMOVE_ROSTER_ITEM: newState = removeRow(state, action);
      break;
    case types.UPDATE_ID: newState = Object.assign({}, state, { id: action.id });
      break;
    case types.UPDATE_NAME: newState = Object.assign({}, state, { name: action.name });
      break;
    case types.DELETE_ROSTER: newState = Object.assign({}, state, { rosters: [{ items: [] }] });
      break;
    case types.UPDATE_DECORATION_DATA: newState = { ...state, ...{ decorationPricing: action.payload } }
      break;
    case types.REMOVE_DECORATION_DATA: newState = { ...state, ...{ decorationPricing: action.payload } }
      break;
    case types.ADD_DECORATION_DATA: newState = { ...state, ...{ decorationPricing: action.payload } }
      break;
    case types.CLEAR_DECORATION_DATA: newState = { ...state, ...{ decorationPricing: { quantity: 0, subtotalCents: 0, totalCents: 0, entities: [] } } };
      break;
    case types.UPLOAD_ROSTER: newState = uploadRoster(state, action);
      break;
    case types.UPLOAD_QUANTITY_ROSTER: newState = uploadQuantityRoster(state, action);
      break;
    case types.REPLACE_DECORATION_PRICING: newState = { ...state, ...action.payload };
      break;
    case types.ADD_ROSTER_ITEMS: newState = addRows(state, action.payload);
      break;
    case types.REMOVE_ROSTER_ITEMS: newState = removeRows(state, action.payload);
      break;
    case types.CHANGE_PATTERN_ADVANCED: newState = newState = Object.assign({}, state, { customLayers: action.payload });
      break;
    case types.CHANGE_COLOR_ADVANCED: newState = newState = Object.assign({}, state, { customLayers: action.payload });
      break;
    case types.CHANGE_FABRIC: newState = Object.assign({}, state, { fabric: action.fabric });
      break;
    case types.CHANGE_PATTERN_COLOR: newState = Object.assign({}, state, action.temp);
      break;
    case types.TOGGLE_COLOR_MODE: newState = {
      ...state,
      customLayers: action.customLayers
    }
      break;
    default:
      return state;
  }
  dm.saveDesign(newState);
  return newState;
}

function uploadRoster(state, action) {
  let createdAt = time.format()
  const roster = state.rosters[0] ? state.rosters[0] : {}
  const updatedRoster = {
    name: roster.name ? roster.name : "My Roster",
    createdAt: roster.createdAt ? roster.createdAt : createdAt,
    updatedAt: roster.updatedAt ? roster.updatedAt : createdAt,
    items: action.payload
  };

  return Object.assign({}, state, { rosters: [updatedRoster] });
}

function uploadQuantityRoster(state, action) {
  let createdAt = time.format()
  const roster = state.rosters[0] ? state.rosters[0] : {}
  const updatedRoster = {
    name: roster.name ? roster.name : "My Roster",
    createdAt: roster.createdAt ? roster.createdAt : createdAt,
    updatedAt: roster.updatedAt ? roster.updatedAt : createdAt,
    items: action.payload
  };

  return Object.assign({}, state, { rosters: [updatedRoster] });
}

function updateQuantityRoster(state, action) {
  let createdAt = time.format()
  const roster = state.rosters[0] ? state.rosters[0] : {}
  const updatedRoster = {
    name: roster.name ? roster.name : "My Roster",
    createdAt: roster.createdAt ? roster.createdAt : createdAt,
    updatedAt: roster.updatedAt ? roster.updatedAt : createdAt,
    items: action.payload
  };

  return Object.assign({}, state, { rosters: [updatedRoster] });
}

function addRow(state, action) {
  if (!action.payload.size) {
    action.payload.size = helpers.product.getDefaultSize();
  }
  if (!action.payload.qty) {
    action.payload.qty = 1;
  }
  if (!action.payload.preview) {
    action.payload.preview = false;
  }
  if (!action.payload.youthToAdult) {
    action.payload.youthToAdult = helpers.product.getDefaultLegacyStyle(state.styleNum);
  }
  const curItems = state.rosters && state.rosters[0] ? state.rosters[0].items : [];
  const newState = { rosters: [{ items: [...curItems, action.payload] }] };
  return { ...state, ...newState };
}

function updateCell(state, { payload }) {
  let curItems = _.get(state, "rosters[0].items", []);
  let hasName = false;
  let hasNumber = false;
  let hasQty = false;
  curItems.forEach(row => {
    Object.keys(row).forEach(key => {
      if (key == "name") {
        hasName = true;
      }
      else if (key == "number") {
        hasNumber = true;
      }
      else if (key == "qty") {
        hasQty = true;
      }
    })
  })
  // console.log("payload is : ",payload)
  // console.log("Name: ",hasName," number: ",hasNumber," qty: ",hasQty);
  const updateRow = (row) => Object.keys(row).reduce((acc, key) => {
    let value = key === payload.col ? payload.value : row[key];
    if (key === payload.col && payload.col === 'qty') {
      value = value > 0 || value === null ? value : 1;
    }
    if (key === payload.col && payload.col === 'number') {
      value = value !== null ? value : 0;
    }
    return { ...acc, ...{ [key]: value } };
  }, {});
  if (payload.col == 'number' && !hasNumber) {
    curItems = curItems.map(data => ({ ...data, number: payload.value }))
  }
  else if (payload.col == 'name' && !hasName) {
    curItems = curItems.map(data => ({ ...data, name: payload.value }))
  }
  else if (payload.col == 'qty' && !hasQty) {
    curItems = curItems.map(data => ({ ...data, qty: payload.value }))
  }
  const items = curItems.map((row, rowIndex) => rowIndex === payload.row ? updateRow(row) : row);
  // console.log("roster items",items);
  return { ...state, ...{ rosters: [{ items }] } };
}

function removeRow(state, action) {
  const curItems = _.get(state, "rosters[0].items", []);
  const items = curItems.filter((el, i) => i !== action.payload);
  return { ...state, ...{ rosters: [{ items }] } };
}

function addRows(state, payload) {
  let curItems = _.get(state, "rosters[0].items", []);
  curItems = [].concat(curItems, payload);
  _.set(state, "rosters[0].items", curItems);
  return { ...state };
}

function removeRows(state, payload) {
  const curItems = _.get(state, "rosters[0].items", []);
  const sizeToChange = payload.sizeToModify.toUpperCase();

  let quantityToRemove = payload.newCount - payload.oldCount;
  const items = curItems.reverse().filter(row => {
    const isMatchingSize = row.size.toUpperCase() === sizeToChange;

    if (isMatchingSize && quantityToRemove > 0) {
      quantityToRemove = quantityToRemove - 1;
      return false;
    } else {
      return true;
    }
  });

  return { ...state, ...{ rosters: [{ items }] } };
}

