import _ from 'lodash';
// import objectModified from './objectModified';
import { fabric } from 'fabric';
import helpers from '../../../helpers';

export default function objectMoving(event, canvas, dndLocToLoc, dndRedux, props) {
  // restrict movement to inside  location bounds


  let id = event.target.id.split("-");
  id = id[1];

  let target = event.target;


  target.setCoords();

  let location = _.find(props.baseProduct.locations, {code: id});
  let canvasses = _.compact(_.map(props.baseProduct.views, x => {
      let id = `canvas-${x.key}`;
      return getCanvasFromElementId(id);
  }));

  let originalCanvasId = `canvas-${location.view}`;
  let originalCanvas = getCanvasFromElementId(originalCanvasId);

  let prevCanvasId = `canvas-${props.navigation.activeView}`;
  let prevCanvas = getCanvasFromElementId(prevCanvasId);

  canvasSwitch(event, prevCanvas, canvasses, originalCanvas, props, dndLocToLoc, dndRedux);
  if( !props.navigation.drag ){
    props.dragItem(true);
  }
}

function getCanvasFromElementId(elementId) {
  // may return null if element does not exist anymore
  let el = document.getElementById(elementId);
  return _.get(el, 'fabric', null);
}

function getCanvasElementId(canvas) {
  // may return null if canvas or element do not exist anymore
  let el = canvas && canvas.getElement();
  return _.get(el, 'id', null);
}

function pointerOutsideOriginalLocation (target, canvas, zoom, pointer, locationsOptions, props, id, dndRedux, dndLocToLoc){

  // location colors are based of pendingLocation.
  let contentSource = _.find(props.customConfig.locations, {code: id})
  // pending Location data is driving the location border colors. changing entity type matches colors.
  if( contentSource ){
    if ( !props.navigation.pendingLocation ){
      props.setPendingLocation( contentSource );
    } else {
      if ( contentSource.entity.type !== props.navigation.pendingLocation.entity.type ){
        props.setPendingLocation( contentSource );
      }
    }
    // end of setting pending entity data
  }

  if(target.opacity === 1 ){
    target.set({opacity:.5});
  }
  // if pointer goes outside of current location box
  _.forEach(canvas.getObjects(), (obj)=>{
    let type = obj.id.split("-")[0];
    let id = obj.id.split("-")[1];
     if( type === "location" ){
      let locationObj = _.find(canvas.getObjects(), {id: `location-${id}`});
      let modifiedPointer = {
        x: pointer.x * zoom,
        y: pointer.y * zoom,
      }
      if ( locationObj.containsPoint(modifiedPointer) ){
        // if pointer is inside another location box
        locationsOptions.push(locationObj);
     }
    }
  });
 if (locationsOptions.length === 0 ){
  // if I have no locations set redux to default
  if ( dndRedux.hasLocation ){
    let hasLocation = false;
    let locationA = null;
    let locationB = null;
    dndLocToLoc( hasLocation, locationA, locationB );
  }
} else {
   let payload = {
    hasLocation: true,
    locationA: id,
    locationB: locationsOptions[0].id.split("-")[1]
  }
   let styleDrag = {
    // used when dragging over an item
    dragTargetAccepted: {
      fill: 'rgba(25, 205, 85, 0.25)',
      stroke: 'rgba(25, 205, 85, 1)',
      strokeDashArray: [0, 0],
    },
    dragTargetReplace: {
      fill: 'rgba(190, 200, 200, 0.5)',
      stroke: 'rgba(190, 200, 200, 1)',
      strokeDashArray: [0, 0],
    },
    dragTargetNotAllowed: {
      fill: 'rgba(180, 15, 15, 0.45)',
      stroke:  'rgba(180, 15, 15, 1)',
      strokeDashArray: [0, 0],
    },
  }
  let styleBase = {
     fill: 'rgba(25, 205, 85, 0.25)',
     stroke: '#999B9C',
     strokeWidth: 1.5,
     strokeDashArray: [0, 0],
     opacity: 1,
     $selectable: true,
     shadow: {   // add a subtle drop shadow around location box borders with slight contrast to border colors to make it easier to see when underlyng product image is nearly the same color.
       color: 'rgba(0,0,0,0.2)',
       offsetX: 1,
       offsetY: 1,
       blur: 1.5,
     },
  }
  let style = _.extend({}, styleBase);
  let targetContent = helpers.locations.getCustomConfigLocationEntityData(payload.locationB);
  let sourceContent = helpers.locations.getCustomConfigLocationEntityData(payload.locationA);
  let bpLocationDetails = _.find(props.baseProduct.locations, {code: payload.locationB});
  let allowedEntities = bpLocationDetails.allowedEntities;
   let sourceIsAllowedEntity = _.find(allowedEntities, {type: sourceContent.entity.type});
  if( sourceIsAllowedEntity ){
    if( targetContent ){
      style = _.extend(style, styleDrag.dragTargetReplace);
     } else {
      style = _.extend(style, styleDrag.dragTargetAccepted);
    }
  } else {
    style = _.extend(style, styleDrag.dragTargetNotAllowed);
  }
   // -- style box start -- //
  if ( locationsOptions.length > 0 ){
    //---- locationObjFirst is the target location to drop too --- ///
     // TODO: Alan : validate target location against source.
    let locationObjFirst = locationsOptions[0];
    let id = locationsOptions[0].id.split("-")[1];
    let oldRect = _.find(canvas.getObjects(), {id:`trash-${id}`});
   if (!oldRect){

    const DRAG_BORDER_OFFSET = 1;
    const ANIM_DURATION_MS = 150;
     let rect = new fabric.Rect({
      id: `trash-${id}`,
      left: locationObjFirst.left - DRAG_BORDER_OFFSET,
      top: locationObjFirst.top - DRAG_BORDER_OFFSET,
      width: locationObjFirst.width + 2*DRAG_BORDER_OFFSET,
      height:  locationObjFirst.height + 2*DRAG_BORDER_OFFSET,
      centeredRotation: true,
      angle: locationObjFirst.angle,
      selectable: false,
      evented: false,
      ...style,
      opacity: 0  // set to 0 for fade-in anim
     });
     rect.animate('opacity', 1, {
       duration: ANIM_DURATION_MS,
       onChange: canvas.renderAll.bind(canvas),
     });
      canvas.add(rect);
     let allObjects = canvas.getObjects();
     allObjects = _.filter(allObjects, (x)=>x.id.includes("trash"))
     _.forEach(allObjects, (x)=>{
       if ( x.id !== `trash-${id}`){
         canvas.remove(x);
       }
     });
   }

  } else {
    let allObjects = canvas.getObjects();
      _.forEach(allObjects, (x)=>{
        if ( x.id.includes("trash" )){
          canvas.remove(x);
        }
      });
    }
    // -- style box end -- //
    if ( !dndRedux.hasLocation || !_.isEqual(dndRedux, payload)){
      let hasLocation = true;
      let locationA = id;
      let locationB = locationsOptions[0].id.split("-")[1];
      dndLocToLoc( hasLocation, locationA, locationB );
    }
  }
}

function pointerIsInsideCanvasArea ( canvas, pointer ){
  let outside = false;

  let zoom = canvas.getZoom();
  let height = canvas.getHeight() / zoom ;
  let width = canvas.getWidth() / zoom ;
  if ( pointer.x < 0 ||
       pointer.x > width ||
       pointer.y < 0 ||
       pointer.y > height
    ){
    outside = true
  }
  return outside;
}

function canvasSwitch (event, prevCanvas, canvasses, originalCanvas, props, dndLocToLoc, dndRedux, ){

  let prevCanvasElId = getCanvasElementId(prevCanvas);
  let originalCanvasElId = getCanvasElementId(originalCanvas);

  _.forEach(canvasses, (canvas)=>{
    _.forEach(canvas && canvas.getObjects(), (x)=> x.setCoords());

    let canvasElId = getCanvasElementId(canvas);

    if( event.target.canvas === canvas ) {
      let outsideCanvas = pointerIsInsideCanvasArea( canvas, event.pointer);
      if ( outsideCanvas ) {
        if ( canvasElId && canvasElId !== prevCanvasElId ) {
          migrateItem(canvas, prevCanvas, event.target);
          return;
        }
      } else {
        event.target.hasControls =  false;
        event.target.hasBorders = true;
        event.target.opacity = .5;
      }

      let zoom = canvas.getZoom();
      let target = event.target;
      let id = event.target.id.split("-")[1];
      let objBoundingRect = target.getBoundingRect();
      let pointer = event.pointer;
      let locationsOptions = [];
      let locationDetails = _.find(originalCanvas && originalCanvas.getObjects(), {id: `location-${id}`});
      let locationDetailsBoundingRect =  locationDetails.getBoundingRect();
      // if previous selected view  matches original view this is the original view where entity came from.
      if ( prevCanvasElId && prevCanvasElId === originalCanvasElId ) {

        // if original canvas is active then use location boxes to find position.
        if ( originalCanvas && originalCanvas.isActive ) {
          // if I am outside my original location box
          if( ( pointer.x < ( locationDetailsBoundingRect.left / zoom ) ) ||
            ( pointer.x > ( locationDetailsBoundingRect.left / zoom + locationDetailsBoundingRect.width / zoom ) ) ||
            ( pointer.y < ( locationDetailsBoundingRect.top / zoom ) ) ||
            ( pointer.y > ( locationDetailsBoundingRect.top / zoom + locationDetailsBoundingRect.height / zoom ) )
          ) {

            pointerOutsideOriginalLocation (target, canvas, zoom, pointer, locationsOptions, props, id, dndRedux, dndLocToLoc);


          } else  {
            // else I am  inside my original location box
            // if i am inside my location  set redux to default , and clear trash locations.
            // restrain entity obj inside locationBox bounds
            if ( objBoundingRect.left < locationDetailsBoundingRect.left){
              target.left = locationDetailsBoundingRect.left / zoom  + objBoundingRect.width / zoom / 2 ;
            }
            if ( objBoundingRect.top < locationDetailsBoundingRect.top){
              target.top = locationDetailsBoundingRect.top / zoom  + objBoundingRect.height / zoom / 2 ;
            }
            if ( objBoundingRect.left + objBoundingRect.width > locationDetailsBoundingRect.left + locationDetailsBoundingRect.width){
             target.left = (locationDetailsBoundingRect.left + locationDetailsBoundingRect.width ) / zoom  - objBoundingRect.width / zoom / 2 ;
            }
            if ( objBoundingRect.top + objBoundingRect.height > locationDetailsBoundingRect.top + locationDetailsBoundingRect.height){
              target.top = (locationDetailsBoundingRect.top + locationDetailsBoundingRect.height ) / zoom  - objBoundingRect.height / zoom / 2 ;
            }

            if ( dndRedux.hasLocation ){
              let hasLocation = false;
              let locationA = null;
              let locationB = null;
              dndLocToLoc( hasLocation, locationA, locationB );
            }

            let allObjects = canvas.getObjects();
            _.forEach(allObjects, (x)=>{
              if ( x.id.includes("trash" )){
                canvas.remove(x);
              }
            });

            if(target.opacity === .5 ){
              target.set({opacity:1})
            }
            // if you are not allowed to move entity give it a fade.
            let baseProduct = props.baseProduct;
            let bpLocationDetails = _.find(baseProduct.locations, {code:id});
            let configLocation = _.find(props.customConfig.locations, {code: id});
            let entityData = _.find(bpLocationDetails.allowedEntities, {key:configLocation.entity.type});
            if( !entityData.allowFreeDrag ){
              target.set({opacity:.5});
            }
          }
        }
      } else {
        // else I am on a new view not the original view from where entity originated.
        pointerOutsideOriginalLocation (target, canvas, zoom, pointer, locationsOptions, props, id, dndRedux, dndLocToLoc);
      }
      // if no locationOptions clear style.
      if ( locationsOptions.length === 0 ){
        let allObjects = canvas.getObjects();
        _.forEach(allObjects, (x)=>{
          if ( x.id.includes("trash" )){
            canvas.remove(x);
          }
        });
      }
    }
  });
};


function migrateItem( sourceCanvas , targetCanvas, pendingImage) {
  sourceCanvas.remove(pendingImage);

  let pendingTransform = sourceCanvas._currentTransform;
  sourceCanvas._currentTransform = null;
  let isTouchDevice = false;

  let removeListener = fabric.util.removeListener;
  let addListener = fabric.util.addListener;
      removeListener(fabric.document, 'mouseup', sourceCanvas._onMouseUp);
      removeListener(fabric.document, 'touchend', sourceCanvas._onMouseUp);

      removeListener(fabric.document, 'mousemove', sourceCanvas._onMouseMove);
      removeListener(fabric.document, 'touchmove', sourceCanvas._onMouseMove);

      addListener(sourceCanvas.upperCanvasEl, 'mousemove', sourceCanvas._onMouseMove);
      addListener(sourceCanvas.upperCanvasEl, 'touchmove', sourceCanvas._onMouseMove, {
          passive: false
      });

      if (isTouchDevice) {
          // Wait 500ms before rebinding mousedown to prevent double triggers
          // from touch devices
          let _this = sourceCanvas;
          setTimeout(function() {
              addListener(_this.upperCanvasEl, 'mousedown', _this._onMouseDown);
          }, 500);
      }
      addListener(fabric.document, 'touchend', targetCanvas._onMouseUp, {
          passive: false
      });
      addListener(fabric.document, 'touchmove', targetCanvas._onMouseMove, {
          passive: false
      });

      removeListener(targetCanvas.upperCanvasEl, 'mousemove', targetCanvas._onMouseMove);
      removeListener(targetCanvas.upperCanvasEl, 'touchmove', targetCanvas._onMouseMove);

      if (isTouchDevice) {
          // Unbind mousedown to prevent double triggers from touch devices
          removeListener(targetCanvas.upperCanvasEl, 'mousedown', targetCanvas._onMouseDown);
      } else {
          addListener(fabric.document, 'mouseup', targetCanvas._onMouseUp);
          addListener(fabric.document, 'mousemove', targetCanvas._onMouseMove);
      }

  setTimeout(function() {
      pendingImage.canvas = targetCanvas;
      pendingImage.migrated = true;
      pendingImage.opacity = 0;
      pendingImage.hasControls = false;
      pendingImage.hasBorders = false;
      targetCanvas.add(pendingImage);

      targetCanvas._currentTransform = pendingTransform;
      targetCanvas.setActiveObject(pendingImage);
  }, 10);

}




