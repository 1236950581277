
export default function objectSelected( event , canvas){
  let target = event.target;
  target.transparentCorners = false;
  target.borderColor = '#50A8FF';
  target.borderScaleFactor = 2;
  target.cornerColor = 'white';
  target.minScaleLimit = 2;
  target.cornerStyle = 'square';
  target.minScaleLimit = 0;
  target.lockScalingFlip = true;
  target.centeredRotation = true;
  target.centeredScaling = true;
  target.rotatingPointOffset = 24;
  target.cornerSize = 10;
}