import React from "react";

const PaintBucketSVG =(props)=>{
  const { className, height=32, width=32, fill="black", stroke="black", strokeWidth="0", onClick } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.73 55.51" className={className} height={height} width={width} fill={fill} stroke={stroke} strokeWidth={strokeWidth} onClick={onClick}>
      <path d="M42.91 28.88l-8.63-9-7.35-7.64L15.15 0l-4.32 4.16 7.62 7.93-16.63 16a5.94 5.94 0 0 0-.16 8.4l16 16.66a5.94 5.94 0 0 0 8.4.17l16.66-16a5.94 5.94 0 0 0 .19-8.44zM6 32.35l16.61-15.94L38.54 33zM54.61 53a6 6 0 0 1-9.77-7l7.58-8.82 3.08 10.7a6 6 0 0 1-.89 5.12z"/>
    </svg>
  );
};

export default PaintBucketSVG;
