import React, { Component } from "react";
import { connect } from "react-redux";
import api from "../../../../api";
import { triggerRenderPreviewImagesAction } from "../../../../redux/reducers/appActions/appActionsActions";
import { messageIframe } from "../../../../redux/reducers/notifications/notificationsActions";
import Input from "../../../global/input/input";
import { closeModal, duplicateModal } from "../../../../redux/reducers/modal/modalActions";
import { setConfigId, updateName } from "../../../../redux/reducers/customConfig/customConfigActions";
import helpers from "../../../../helpers";
import _ from "lodash";
import Promise from "bluebird";
import copy from "copy-to-clipboard";

class SaveModal extends Component {

  constructor(props) {
    super(props);
    let designNameChanged = false;
    let designName = _.get(this.props, 'customConfig.name', null);
    if (_.isEmpty(designName)) {
      let productName = _.get(this.props, 'baseProduct.name', null);
      let dateStr = helpers.display.formatCurrentDateTime();
      designName = _.join(_.compact([productName, dateStr]), ' ');
      designNameChanged = true;
    }

    let activeView = helpers.locations.getDefaultView();

    if (designNameChanged) {
      this.props.updateName(designName);
    }

    this.handleChangeView = this.handleChangeView.bind(this);

    let overwrite = true;
    if (this.props.customConfig.configId && !this.props.customConfig.isSavedDesign) {
      overwrite = false;
    }

    this.state = {
      overwrite,
      activeView,
      previews: null,
      previewsLoading: true,
      designName,
      isUpdate: false,
      isSaved: false,
      configId: null,
      isEnabled: true
    };
  }

  componentDidMount() {
    !this.props.modalProps.onlyShareView && this.capturePreviewImages().then(previews => {
      this.setState({ previews, previewsLoading: false, configId: this.props.customConfig.configId });
    }).catch(err => {
      // TODO: Joe- add logic to fallback to "preview not available" placeholder images
      // we can probably return just (for default view)
      console.error('preview capture failed: ' + _.toString(err));
      this.setState({ previews: null, previewsLoading: false });
      return false;
    });
  }

  copyToClipboard(value) {
    copy(value);
    this.setState({ shareLinkCopied: true }, () => {
      setTimeout(() => {
        this.setState({ shareLinkCopied: false })
      }, 6000);
    });
  };

  handleOnChange(value) {
    if (value.length > 0) {
      this.setState({ isEnabled: true })
      this.props.updateName(value);
    } else {
      this.setState({ isEnabled: false })
      this.props.updateName(value);
    }

  }
  handleCheckbox(value) {
    if (!value) {
      this.props.updateName(this.state.designName);
    }
    this.setState({ overwrite: value })
  }

  async handleSave(action) {
    this.createOrUpdateConfig(action).then(() => {
      this.props.messageIframe('saved');
    });
    if (action === "duplicate") {
      this.setState({ isUpdate: true }, () => {
        setTimeout(() => {
          this.setState({ isUpdate: false })
        }, 6000);
      });
      this.props.closeModal();
      this.props.duplicateModal();
    } else if (action === "save") {
      this.setState({ isSaved: true }, () => {
        setTimeout(() => {
          this.setState({ isSaved: false })
        }, 6000);
      });
    }

  }

  async capturePreviewImages() {
    let timeoutMS = 20000;
    return new Promise((resolve, reject) => {
      let timeoutRef = null;
      let handleDone = _.once((err, res) => {
        if (timeoutRef) { clearTimeout(timeoutRef); timeoutRef = null; }
        if (err) { return reject(err); }
        resolve(res);
      });
      if (timeoutMS > 0) {
        timeoutRef = setTimeout(() => {
          // console.warning('cpi timed out');
          timeoutRef = null;
          handleDone(new Error('capturePreviewImages timed out'), null);
        }, timeoutMS);
      }
      this.props.triggerRenderPreviewImagesAction(res => {
        handleDone(null, res);
      });
    })
  }

  async createOrUpdateConfig(action) {
    let baseProduct = this.props.baseProduct;
    let customConfig = this.props.customConfig;
    let previews = this.state.previews;
    let isSavedDesign = true;
    let isOrderedDesign = false;
    let isMasterConfig = false;
    let overwrite
    if (action === "save") {
      overwrite = true;
    } else if (action === "duplicate") {
      overwrite = true;
    }
    if (this.props.launchContext.isMaster) {
      isMasterConfig = true;
    } else {
      isMasterConfig = false;
    }
    let params = await helpers.serialization.prepareApiSaveData(baseProduct, customConfig, previews, isSavedDesign, overwrite, isOrderedDesign, isMasterConfig);

    return api.webUserSavedConfigs.save(params.configId, params).then(res => {
      let configId = (res && res.configId) || null;
      if (action === "save") {
        const actionPayload = { configId: configId, isSavedDesign: true, isOrderedDesign: false, corpAcctId: this.props.user.corpAccount.id, name: this.props.customConfig.name }
        this.props.setConfigId(actionPayload);
      } else if (action === "duplicate") {
        const actionPayload = { isOrderedDesign: true }
        this.props.setConfigId(actionPayload);
      }
    }).catch((err) => {
      console.error("error on creating config", err);
      return false;
    });

  };

  handleCancel() {
    this.props.closeModal();
  }

  handleChangeView(key) {
    let activeView = _.find(this.props.baseProduct.views, (x) => { return (x.key === key); });
    this.setState({ activeView })
  }

  viewButtons() {
    let activeView = this.state.activeView.key;
    let buttons = _.map(this.state.previews, (x, i) => {
      let active = "";
      // let previewImageData = _.get(x.data, 'data', null);
      if (x.key === activeView) {
        active = "active-btn"
      }
      return (
        <div>
          {this.props.baseProduct.views.length > 2 ?
            (<img className={`btn btn-outline-primary preview-image ${active}`} onClick={() => this.handleChangeView(x.view)} key={i} value={x.view} src={x.data} height='80px' width='90px' alt="preview" />)
            //<button key={i} value={x.key} className={`btn btn-outline-primary ${active}`} onClick={this.handleChangeView}>{x.name}</button>
            : (<img className={`btn btn-outline-primary preview-image ${active}`} onClick={() => this.handleChangeView(x.view)} key={i} value={x.view} src={x.data} height='90px' width='90px' alt="preview" />)}
        </div>
      );
    });
    return buttons;
  };
  saveDesign() {
    return (
      <div className="row">
        <div className="col-12">
          <div className="card border-0 h-100">
            <div className="inputs" >
              {!this.props.modalProps.onlyShareView ?
                (<div className="input">
                  <div className="form-group">
                  {this.props.customConfig.isSavedDesign === true ? <label><b>DESIGN NAME</b></label> :<label><b>NAME YOUR DESIGN</b></label>}
                    <div className="row">
                      <div className="col-8 pr-0">
                      {this.props.customConfig.isSavedDesign === true ?
                                <Input value={this.props.customConfig.name} readonly />
                                :<Input value={this.props.customConfig.name} onChange={(e) => this.handleOnChange(e)} placeholder="Enter Design Name" />
                                  }
                      </div>
                      <div className="col-4">
                        <button type="button" className="btn w-100 btn-red" onClick={() => this.handleSave("save")}>SAVE</button>
                      </div>
                    </div>
                    {this.state.isSaved ? <small className="mockup-saved form-text text-success">Your Mockup has been Saved. As a dealer you can retrieve this by visiting My Account <span>&#62;</span> My Saved Mock-Ups</small> : <div></div>}
                  </div>
                </div>) : null}
              <div className="input">
                <div className="form-group">
                  <label><b>SHARING LINK</b></label>
                  <div className="row">
                    <div className="col-8 pr-0">
                      {/* <Input value={`____shareUrl____${this.props.customConfig.configId || this.props.modalProps.shareId}`} readonly /> */}
                      <Input value={`____shareUrl____${this.state.configId}`} readonly />
                    </div>
                    <div className="col-4">
                      <button type="button" className="btn w-100 btn-red" onClick={e => this.copyToClipboard(`____shareUrl____${this.state.configId}`)}>COPY LINK</button>
                    </div>
                  </div>
                  {this.state.shareLinkCopied ? <small className="mockup-saved form-text text-success">The link for this mock-up has been copied to your clipboard, which you can use to share this design with others.</small> : <div></div>}
                </div>
              </div>
              {!this.props.modalProps.onlyShareView ?
                (<p className="pt-5 pb-3">
                  If you are ready to start a new design and want to use this design as a starting point, please click to DUPLICATE below.
                  This previous design is saved and you can use it to build a new one. Make sure to save your new design when done.
                </p>) : null}
              {!this.props.modalProps.onlyShareView ?
                (<div className="buttonPadding">
                  <button type="button" className="btn btn-outline-dark btn-block btn-outline" onClick={() => this.handleSave("duplicate")}>DUPLICATE THE DESIGN</button>
                </div>) : null}
              {this.state.isUpdate ? <small className="mockup-saved form-text text-center text-success">Design Duplicated!</small> : <div></div>}
            </div>
          </div>
        </div>
      </div>
    );
  }

  saveMasterDesign() {
    return (
      <div className="row">
        <div className="col-12">
          <div className="card border-0 h-100">
            <div className="inputs" >
              {!this.props.modalProps.onlyShareView ?
                (<p className="pt-5 pb-3">
                  Are you sure you want to save new Master Design?
                </p>) : null}
              {!this.props.modalProps.onlyShareView ?
                (
                  <div className="row">
                    <div className="col-6">
                      <div className="">
                        <button type="button" className="btn btn-block btn-outline-dark btn-outline" onClick={() => this.handleSave("save")}>SAVE</button>
                      </div>
                      {this.state.isSaved ? <small className="mockup-saved form-text text-success">Mockup Saved!</small> : <div></div>}
                    </div>
                    <div className="col-6">
                      <div className="">
                        <button type="button" className="btn btn-outline-dark btn-block btn-outline-red" onClick={() => this.handleCancel()}>CANCEL</button>
                      </div>
                    </div>
                  </div>
                ) : null}
            </div>
          </div>
        </div>
      </div>
    )
  }


  render() {
    let selectedViewKey = _.get(this.state, 'activeView.key', null);
    let selectedPreview = _.find(this.state.previews, { view: selectedViewKey }) || null;
    if (!selectedPreview) {
      // use a preview not available image
    }
    let selectedPreviewImageData = _.get(selectedPreview, 'data', null);
    return (
      <div className="preview-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6">
              {this.props.launchContext.isMasterDesign ? this.saveMasterDesign() : this.saveDesign()}
            </div>
            <div className="col-6 saveModalPreview">
              <div className="innerContainer imageHeight">
                {this.state.previewsLoading && this.props.modalProps.onlyShareView ?
                  null :
                  (<img className="preview-image card fade-in" src={selectedPreviewImageData} alt="preview" />)
                }
                {this.props.modalProps.onlyShareView ?
                  (<div className=" saveModalPreview shareHeight">
                    <img className="img-fluid" src={this.props.modalProps.imgUrl} alt="testImage"></img>
                  </div>) : null
                }
              </div>
              <div className="preview-buttons btn-group col-4 imageButtons" role="group">
                {!this.props.modalProps.onlyShareView && this.viewButtons()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    customConfig: state.customConfig,
    modalProps: state.modal,
    baseProduct: state.baseProduct,
    accountType: state.modal.accountType,
    launchContext: state.launchContext,
  }
}

let outputActions = {
  triggerRenderPreviewImagesAction,
  messageIframe,
  closeModal,
  duplicateModal,
  setConfigId,
  updateName
};
export default connect(mapStateToProps, outputActions)(SaveModal)
