import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import MultiDropdown from '../../../../global/multiDropdown/multiDropdown';
import { addHoverObject, clearHover } from "../../../../../redux/reducers/hover/hoverActions";
import { changeSetting } from "../../../../../redux/reducers/customConfig/customConfigActions";
import TextPatterns from '../../colors/ChromaPatterns/TextPatterns';
import TextPatternColors from "../../colors/patternColors/textPatternColors";


function EditTextPatternFill(props) {
    let locationContent = _.find(props.customConfig.locations, { code: props.navigation.activeLocation });
    if (!locationContent.entity.patternFill) {
        locationContent.entity.patternFill = "No"
    }
    const allowedOptions = [
        "Yes",
        "No"
    ];
    const onChangeFill = (e) => {
        let locationContent = _.find(props.customConfig.locations, { code: props.navigation.activeLocation });

        props.changeSetting(props.customConfig.locations, props.navigation.activeLocation, { setting: "patternFill", value: e });
        if (e === "No") {
            locationContent.entity.capabilities.textPatternFillId = null;
            if (locationContent.entity.capabilities.textPatternColors) {
                locationContent.entity.capabilities.textPatternColors = [];
            }
        }
    }
    let label = "Text Pattern Fill";
    let value = locationContent.entity.patternFill;
    let options = _.map(allowedOptions, (x) => {
        return x;
    });
    let rightLabel = null;
    let menuHeader = null;
    let patternColorsLength = 0;
    if (locationContent.entity.capabilities.textPatternFillId) {
        patternColorsLength = locationContent.entity.capabilities.textPatternFillId.designImages.length;
    }
    return (
        <div className="EditTextFont" style={{ marginTop: "15px", position: "relative" }}>
            <div className="row pl-4">
                <div className="col-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 label-padding-top">
                    <label className="decoration-label">{label.toUpperCase()}</label>
                </div>
                <div className="col-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12">
                    <MultiDropdown
                        // label={label}
                        customClass = "multidropdown-menu"
                        rightLabel={rightLabel}
                        menuHeader={menuHeader}
                        value={value}
                        options={options}
                        onChange={onChangeFill}
                    />
                </div>
            </div>

            <div>
                {value === 'Yes' &&
                    <div>
                        <div className="row pt-3">
                            <div className="col-3 pl-5">
                                <TextPatterns />
                            </div>
                            <div className="col-9">
                                <div className="row">
                                    <div className="col-12 padding-left-color-picker">
                                        {patternColorsLength > 0 ? <TextPatternColors colorData={patternColorsLength} /> : null}
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                }
            </div>
        </div>

    )


}

const reduxActions = {
    changeSetting,
    addHoverObject,
    clearHover
}

function mapStateToProps(state) {
    return {
        navigation: state.navigation,
        baseProduct: state.baseProduct,
        customConfig: state.customConfig
    }
}

export default connect(mapStateToProps, reduxActions)(EditTextPatternFill);