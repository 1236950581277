import {fabric} from 'fabric';
// import Promsie from 'bluebird';


let _rotationHandleImage = null;
let _rotationHandleImageLoaded = false;

async function loadRotationHandleImageOnce() {

  if (_rotationHandleImageLoaded) { return _rotationHandleImage; }
  _rotationHandleImageLoaded = true;

  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = './images/global/rotate-handle.png';
    img.onload = () => {
      _rotationHandleImage = img;
      resolve(img);
    };
    img.onerror = (err) => {
      _rotationHandleImage = false;
      resolve(false);
    };
  });

}

loadRotationHandleImageOnce();

export default function customRotationController() {
  fabric.Object.prototype.drawControls = function (ctx) {

    if (!this.hasControls) {
      return this;
    }
  
    var wh = this._calculateCurrentDimensions(),
    width = wh.x,
    height = wh.y,
    scaleOffset = this.cornerSize,
    left = -(width + scaleOffset) / 2,
    top = -(height + scaleOffset) / 2,
    methodName = this.transparentCorners ? 'stroke' : 'fill';
  
    ctx.save();
    ctx.strokeStyle = ctx.fillStyle = this.cornerColor;
    if (!this.transparentCorners) {
      ctx.strokeStyle = this.cornerStrokeColor;
    }
   this._setLineDash(ctx, this.cornerDashArray, null);
  
    // top-left
   this._drawControl('tl', ctx, methodName,left,top);
  
   // top-right
  this._drawControl('tr', ctx, methodName, left + width,top);
  
  // bottom-left
  this._drawControl('bl', ctx, methodName,left, top + height);
  
  // bottom-right
  this._drawControl('br', ctx, methodName,left + width,top + height);
  
  if (!this.get('lockUniScaling')) {
  
  // middle-top
  this._drawControl('mt', ctx, methodName,
    left + width / 2,
    top);
  
  // middle-bottom
  this._drawControl('mb', ctx, methodName,
    left + width / 2,
    top + height);
  
  // middle-right
  this._drawControl('mr', ctx, methodName,
    left + width,
    top + height / 2);
  
  // middle-left
  this._drawControl('ml', ctx, methodName,
    left,
    top + height / 2);
  }
  
  // middle-top-rotate
  if (this.hasRotatingPoint) {
    // TODO: alan- decide what to do here.
  }
  
  ctx.restore();
  
  return this;
  
  }
  //////////////////////////////////////
  fabric.Object.prototype.drawControls = function (ctx) {

    if (!this.hasControls) {
      return this;
    }
  
    let wh = this._calculateCurrentDimensions(),
        width = wh.x,
        height = wh.y,
        scaleOffset = this.cornerSize,
        left = -(width + scaleOffset) / 2,
        top = -(height + scaleOffset) / 2,
        methodName = this.transparentCorners ? 'stroke' : 'fill';
  
    ctx.save();
    ctx.strokeStyle = ctx.fillStyle = this.cornerColor;
    if (!this.transparentCorners) {
      ctx.strokeStyle = this.cornerStrokeColor;
    }
    this._setLineDash(ctx, this.cornerDashArray, null);
  
    // top-left
    this._drawControl('tl', ctx, methodName,
      left,
      top);
  
    // top-right
    this._drawControl('tr', ctx, methodName,
      left + width,
      top);
  
    // bottom-left
    this._drawControl('bl', ctx, methodName,
      left,
      top + height);
  
    // bottom-right
    this._drawControl('br', ctx, methodName,
      left + width,
      top + height);
  
    if (!this.get('lockUniScaling')) {
  
      // middle-top
      this._drawControl('mt', ctx, methodName,
        left + width / 2 ,
        top);
  
      // middle-bottom
      this._drawControl('mb', ctx, methodName,
        left + width / 2,
        top + height);
  
      // middle-right
      this._drawControl('mr', ctx, methodName,
        left + width,
        top + height / 2);
  
      // middle-left
      this._drawControl('ml', ctx, methodName,
        left,
        top + height / 2);
    }
  
    // middle-top-rotate
    if (this.hasRotatingPoint) {
      let rotateLeft = left + width / 2;
      let rotateTop = top - this.rotatingPointOffset;
      if (_rotationHandleImage) {
        ctx.drawImage(_rotationHandleImage, rotateLeft - 3, rotateTop - 8, 16, 16);
      } else {
        // TODO: alan- draw square control

      }

    }
  
    ctx.restore();
    return this;
  }
}
