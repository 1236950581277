import React, { Component } from "react";
import SideBarToolTip from "../../global/helpBubble/sideBarTooltip";

export default class Navbutton extends Component {

  constructor(props) {
    super(props)
    this.state = {
      toggle: false
    }
  }

  render() {
    const activeClass = this.props.isActive ? " c-nav__btn--is-active" : "";
    const className = `c-nav__btn ${activeClass}`
    return (
      <div className='col' style={{ textAlign: 'center' }}>
        <button style={{ width: '100%' }} onMouseOver={() => this.setState({ toggle: true })} onMouseLeave={() => this.setState({ toggle: false })} onClick={this.props.onClick(this.props.selectorKey)} className={className}>
          <div>{this.props.displayName}</div>
        </button>
        <SideBarToolTip toggle={this.state.toggle} bubbleMessage={this.props.toolTip} />
      </div>
    )
  }
}