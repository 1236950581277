import React, { Component } from 'react';

export default class UserArtNotSureButton extends Component {
  render() {
    let notSureClassName = "btn btn-outline-primary btn-cancel"; 
    if( this.props.isNotSure ) {
      notSureClassName =  "btn btn-selected"; 
    }

    return (
      <div className="userart__not-sure">
         <button onClick={this.props.onClick} className={`userart__not-sure-button ${notSureClassName}`}>I'm Not Sure</button>    
      </div>
    )
  }
}
