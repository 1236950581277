import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fabric } from 'fabric';
import { connect } from 'react-redux';
import { setActiveLocation, setActiveTab, setView, dragItem, previewSample, dndLocToLoc, setOriginalEntityPosition, setPendingLocation, setCustomTextInput } from '../../redux/reducers/navigation/navigationActions';
import { changeSetting, addPendingLocationToLocations, updateLocationsBound, moveToEmptyLocation } from '../../redux/reducers/customConfig/customConfigActions';
import { SetEditDecorationType, SetDecorationSubtab } from "../../redux/reducers/decoration/decorationAction";
import { locationUsed } from '../../redux/reducers/modal/modalActions';
import objectMoving from './helpers/objectMoving';
import objectModified from './helpers/objectModified';
import objectScaling from './helpers/objectScaling';
import objectSelected from './helpers/objectSelected';
import mouseDown from './helpers/mouseDown';
import mouseOver from './helpers/mouseOver';
import customRotationController from './helpers/customRotationController';
import _ from 'lodash';
import LocationBoxHelper from './helpers/LocationBoxHelper';
import ProductImageHelper from './helpers/ProductImageHelper';
import EntityHelper from './helpers/EntityHelper';
import helpers from "../../helpers";
import updateDimensionsHelper from "../../helpers/updateDimentionsHelper";


const MIN_VALID_BASE64_PNG_SIZE = 1000;

class PreviewCanvasTwo extends Component {

  static get propTypes() {
    return {
      isThumbnail: PropTypes.any,
      view: PropTypes.any,
      sample: PropTypes.string,
      borders: PropTypes.bool,
      animate: PropTypes.bool,
      showHover: PropTypes.bool,
      showShadow: PropTypes.bool,
    };
  }

  static get defaultProps() {
    return {
      isThumbnail: false,
      view: null,
      sample: "",
      borders: true,
      animate: true,
      showHover: false,
      showShadow: true,
    };
  }

  constructor(props) {
    super(props);

    let isThumbnail = this.props.isThumbnail ? "-thumbnail" : "";
    let isSample = this.props.sample ? `-${this.props.sample}` : "";
    this.canvasId = `canvas-${this.props.view.key || this.props.view.view}${isThumbnail}${isSample}`;
    this.createImageSnapshot = this.createImageSnapshot.bind(this);
    this.onCanvasContainerClicked = this.onCanvasContainerClicked.bind(this);
    this.windowUpdater = new updateDimensionsHelper();
    this.locationBoxHelper = new LocationBoxHelper();
    this.productImageHelper = new ProductImageHelper();
    // this.entityHelper = new EntityHelper();
    this.entityHelper = new EntityHelper();
    this.state = {
      previousLocations: [],
      previousColor: "",
      previousPattern: "",
      previousPrimaryColors: [],
      previousStitchingColor: ""
    }
  }

  onCanvasContainerClicked(e) {
    // prevent accidental click through
    e.preventDefault();
    e.stopImmediatePropagation();
    return false;
  }

  checkLocations() {
    let UpdatedLocations = this.props.customConfig.locations;
    // UpdatedLocations = _.filter(UpdatedLocations, loc =>
    //   // loc.saveEntity === true || (this.props.decoration.editDecorationType !== "" && loc.editEntity === true)
    //   loc.saveEntity === true
    // );
    if (this.props.decoration.prevLocation !== "") {
      UpdatedLocations = _.each(UpdatedLocations, loc => {
        if (loc.editEntity !== true) {
          if (this.props.decoration.prevLocation.code === loc.code) {
            let returnLoc = this.props.decoration.prevLocation;
            this.props.decoration.prevLocation = {}
            return returnLoc;
          }
        }
        else {
          return loc;
        }
      })
      this.props.customConfig.locations = UpdatedLocations;
    }
  }

  componentDidMount() {
    let previousLocations = _.cloneDeep(this.props.customConfig.locations);
    let previousColor = this.props.customConfig.color;
    let previousPattern = _.get(this.props.customConfig, "customLayers.primaryPattern", null);
    let previousPrimaryColors = _.get(this.props.customConfig, "customLayers.primaryColors", []);
    this.setState({ previousLocations, previousColor, previousPattern, previousPrimaryColors });

    if (_.isFunction(this.props.setCapture)) {
      this.props.setCapture(this.createImageSnapshot);
    }

    let setScale = true;
    window.addEventListener('resize', _.debounce(this.updateDimensions, 100));
    window.addEventListener('load', this.updateDimensions, false);
    if (!this.props.isThumbnail) {
      // If I am not a thumbnail do this.
      window.addEventListener('resize', this.updateDimensions);
      window.addEventListener('load', this.updateDimensions, false);
      this.canvas = new fabric.Canvas(this.canvasId);
      this.canvas.isActive = false;

      let nativeCanvasElement = document.getElementById(this.canvasId);
      if (nativeCanvasElement) {
        nativeCanvasElement.fabric = this.canvas;
      }
      customRotationController();

      let imgUrl = this.getProductImageUrl();
      let canvasSizer = document.getElementById(`canvasSizer-${this.canvasId}`);
      // let canvasContainer = document.getElementById(`innerCanvasContainer-${this.canvasId}`);
      this.productImageHelper.mount(this.canvas, imgUrl, canvasSizer, setScale, this.props.animate, this.props.showShadow);
      this.locationBoxHelper.mount(this.canvas, this.props.view, this.props.setActiveLocation, this.props.addPendingLocationToLocations, this.props.setActiveTab, this.props.previewSample, this.props.locationUsed, this.props.dragItem, this.props.borders, this.props.setCustomTextInput, this.props.setPendingLocation, this.props, this.props.customConfig, updateLocationsBound);
      this.entityHelper.mountAll(this.canvas, this.props.view, this.props.sample);

      this.canvas.selection = false; // disables multi selection / group selections
      // add event listeners
      // TODO: Joe- add when ready. canvasContainer.addEventListener("click", (event)=>this.onCanvasContainerClicked(event));
      this.canvas.on("mouse:down", (event) => {
        mouseDown(event, this.props)
      });
      this.canvas.on("mouse:over", (event) => {
        mouseOver(event, this.canvas)
      });
      this.canvas.on("object:moving", (event) => {
        objectMoving(event, this.canvas, this.props.dndLocToLoc, this.props.navigation.dndLocToLoc, this.props)
      });
      this.canvas.on("object:scaling", (event) => {
        objectScaling(event, this.canvas)
      });
      this.canvas.on("object:selected", (event) => {
        objectSelected(event, this.canvas);
        let locationId = event.target.id.split("-")[1];
        let locationContent = _.find(this.props.customConfig.locations, { code: locationId });
        this.props.setActiveLocation(locationId);
        this.props.SetEditDecorationType(locationContent.entity.type);
        if (locationContent.entity.type === 'custom-text' || locationContent.entity.type === 'player-name' || locationContent.entity.type === 'player-number') {
          this.checkLocations();
          let subTab = "TEXT";
          this.props.SetDecorationSubtab(subTab);
          let tabObj = { tab: "decorations", subTab: "add-text", page: "edit" };
          this.props.setActiveTab(tabObj)
        } else {
          let subTab = "GRAPHIC";
          this.props.SetDecorationSubtab(subTab);
          let tabObj = { tab: "decorations", subTab: "add-art", page: "edit" }
          this.props.setActiveTab(tabObj)
        }
      });
      this.canvas.on("before:transform", (event) => {
        // save a version of the canvasEntityObj before transformed. used for setting it back to its original position.
        this.props.setOriginalEntityPosition(_.cloneDeep(event.transform.target));
      });
      this.canvas.on("object:modified", (event) => {
        // after object has been modified make call to redux to update entity bounds
        let locationCode = event.target.id.split("-")[1];
        let newBounds = objectModified(event, this.canvas, this.props.navigation.dndLocToLoc, this.props);
        this.props.changeSetting(this.props.customConfig.locations, locationCode, { setting: "bounds", value: newBounds });
        this.props.changeSetting(this.props.customConfig.locations, locationCode, { setting: "_heightUpdated", value: true });
      });
    } else {
      // If I am thumbnail do this.
      this.canvas = new fabric.StaticCanvas(this.canvasId);
      let nativeCanvasElement = document.getElementById(this.canvasId);
      if (nativeCanvasElement) {
        nativeCanvasElement.fabric = this.canvas;
      }
      let imgUrl = this.getProductImageUrl();
      let canvasSizer = document.getElementById(`canvasSizer-${this.canvasId}`);
      this.productImageHelper.mount(this.canvas, imgUrl, canvasSizer, setScale, this.props.animate, this.props.showShadow);
      this.locationBoxHelper.mount(this.canvas, this.props.view, this.props.setActiveLocation, this.props.addPendingLocationToLocations, this.props.setActiveTab, this.props.previewSample, this.props.locationUsed, this.props.dragItem, this.props.borders, this.props.setCustomTextInput, this.props.setPendingLocation, this.props, this.props.customConfig, this.props.updateLocationsBound);
      this.entityHelper.mountAll(this.canvas, this.props.view, this.props.sample);
    }
  }

  componentWillUnmount() {

    if (!this.props.isThumbnail) {
      window.removeEventListener('resize', this.updateDimensions);
      window.removeEventListener('load', this.updateDimensions, false);
    }
  }

  async componentDidUpdate(prevProps, newProps) {
    if (this.props.className === "inactive") {
      if (this.canvas.isActive !== false) {
        this.canvas.isActive = false;
      }
    } else if (this.props.className === "active") {
      if (this.canvas.isActive !== true) {
        this.canvas.isActive = true;
      }
    }
    let canvasSizer = document.getElementById(`canvasSizer-${this.canvasId}`);
    // NOTE: joe- prevent CPU thrashing by simply checking that the hover state has changed or not
    let hoverChanged = (prevProps.hover !== this.props.hover);

    // update product img url when hover updated
    if (hoverChanged) {
      if (!this.props.isThumbnail) {
        let imgUrl = this.getProductImageUrl();
        this.productImageHelper.mount(this.canvas, imgUrl, canvasSizer, null, this.props.animate, this.props.showShadow);
      }
    }

    // update product img url when new color doesn't match previous color.
    if (this.props.customConfig.color !== this.state.previousColor) {
      let imgUrl = this.getProductImageUrl();
      this.productImageHelper.mount(this.canvas, imgUrl, canvasSizer, null, this.props.animate, this.props.showShadow);
      this.setState({ previousColor: this.props.customConfig.color });
    }

    // update product img url when new stitchcolor doesn't match previous stitchcolor.
    let stitchColor;
    if (this.props.customConfig.upgrades.length > 0) {
      let stitchIndex = _.findIndex(this.props.customConfig.upgrades, { key: 'stitching' });
      let stitchingData = this.props.customConfig.upgrades[stitchIndex];
      stitchColor = stitchingData.color;
    }

    if (stitchColor !== this.state.previousStitchingColor && this.props.customConfig.upgrades.length > 0) {
      let imgUrl = this.getProductImageUrl();
      this.productImageHelper.mount(this.canvas, imgUrl, canvasSizer, null, this.props.animate, this.props.showShadow);
      let stitchIndex = _.findIndex(this.props.customConfig.upgrades, { key: 'stitching' });
      let stitchingData = this.props.customConfig.upgrades[stitchIndex];
      stitchColor = stitchingData.color;
      this.setState({ previousStitchingColor: stitchColor });
    }

    // update product img url when pattern does not match previous pattern
    let configPattern = _.get(this.props.customConfig, 'customLayers.primaryPattern', null);
    if (configPattern !== this.state.previousPattern) {
      let imgUrl = this.getProductImageUrl();
      this.productImageHelper.mount(this.canvas, imgUrl, canvasSizer, null, this.props.animate, this.props.showShadow);
      this.setState({ previousPattern: configPattern })
    }
    ///////// -- base start -- //////////
    //  this.locationBoxHelper.mount(this.canvas, this.props.view, this.props.setActiveLocation, this.props.addPendingLocationToLocations, this.props.setActiveTab, this.props.previewSample, this.props.locationUsed, this.props.dragItem, this.props.borders, this.props.setCustomTextInput, this.props.setPendingLocation, this.props, this.props.customConfig, this.props.updateLocationsBound);

    //------------ update location Box start -------------//
    this.locationBoxHelper.style(this.canvas, this.props.borders);
    //------------ update location Box end ---------------//
    ///////// -- base end -- //////////



    let previousLocations = this.state.previousLocations;
    let currentLocations = _.cloneDeep(this.props.customConfig.locations);

    if (!_.isEqual(previousLocations, currentLocations)) {
      _.forEach(currentLocations, (currentLocation) => {
        // only run if view is relevant;
        if (currentLocation.view === this.props.view.key) {
          let locationCode = currentLocation.code;
          let previousLocation = _.find(previousLocations, { code: locationCode });
          if (previousLocation) {
            // if height or width changed. // these are triggering twice?
            let scaleFrom;
            let urlChanged = currentLocation.entity.url !== previousLocation.entity.url;
            let rotationChanged = currentLocation.entity.bounds.r !== previousLocation.entity.bounds.r;
            let positionChanged = currentLocation.entity.bounds.x !== previousLocation.entity.bounds.x || currentLocation.entity.bounds.y !== previousLocation.entity.bounds.y;
            let flipArtChanged = currentLocation.entity.flipArt !== previousLocation.entity.flipArt;
            if (currentLocation.entity._init) {
              this.entityHelper.init(this.canvas, currentLocation).then(this.handleNewEntityBounds);
            } else if (currentLocation.entity._heightUpdated || currentLocation.entity._rootSizeUpdated) {
              scaleFrom = "height";
              this.entityHelper.updateSize(this.canvas, currentLocation, scaleFrom).then(this.handleNewEntityBounds);
            } else if (currentLocation.entity._widthUpdated) {
              scaleFrom = "width";
              this.entityHelper.updateSize(this.canvas, currentLocation, scaleFrom).then(this.handleNewEntityBounds);
            } else if (urlChanged) {
              this.entityHelper.mountSingle(this.canvas, currentLocation).then(this.handleNewEntityBounds);
            } else if (currentLocation.entity._deleteUpdated) {
              this.entityHelper.init(this.canvas, currentLocation).then(this.handleNewEntityBounds);
            } else if (rotationChanged) {
              // using object modified since it already handles rotation going out of bounds.
              let canvasObj = _.find(this.canvas.getObjects(), { id: `entity-${locationCode}` });
              if (canvasObj) {
                canvasObj.rotate(currentLocation.entity.bounds.r);
                previousLocation.entity.bounds.r = currentLocation.entity.bounds.r;
                let event = { target: canvasObj }
                if (this.props.baseProduct.configurationType === 'stock-dec') {
                  let newBounds = objectModified(event, this.canvas, this.props.navigation.dndLocToLoc, this.props, this.cloneBeforeModified);
                  this.props.changeSetting(this.props.customConfig.locations, locationCode, { setting: "bounds", value: newBounds });
                }
              }
            } else if (positionChanged) {
              let canvasEntity = _.find(this.canvas.getObjects(), { id: `entity-${locationCode}` });
              if (canvasEntity) {
                canvasEntity.set({
                  left: currentLocation.entity.bounds.x,
                  top: currentLocation.entity.bounds.y,
                });
              }
            } else if (flipArtChanged) {
              let canvasObj = _.find(this.canvas.getObjects(), { id: `entity-${locationCode}` });
              if (canvasObj) {
                canvasObj.set('flipX', currentLocation.entity.flipArt);
              }
            }

          } else {
            // if no previous location found. need to canvas location.
            // am I a brand new entity,
            let isInit = currentLocation.entity.hasOwnProperty("_init");
            if (isInit) {
              this.entityHelper.init(this.canvas, currentLocation).then(this.handleNewEntityBounds);
            }
          }
        }
      });

      // after all the comparisons are done set previous locations to the current locations.
      let updatePreviousLocations = _.cloneDeep(currentLocations);
      this.setState({ previousLocations: updatePreviousLocations });
    }

    // if entity exists but not content remove it from canvas.
    let filterCanvasEntities = _.filter(this.canvas.getObjects(), (x) => { if (x.id.includes("entity")) { return x; } });
    _.forEach(filterCanvasEntities, (entity) => {
      let locationCode = entity.id.split("-")[1];
      let doesContentExist = _.find(currentLocations, { code: locationCode })
      if (!doesContentExist) {
        this.canvas.remove(entity)
      }
    });

    //--------------Hover start ------------------//
    if (!this.props.isThumbnail) {
      if (hoverChanged) {
        if (this.props.hover.length > 0) {
          let hoverObj = this.props.hover[0];
          let hoverUrl = hoverObj.url ? hoverObj.url : null;
          let hoverLocation = hoverObj.location ? hoverObj.location : null;
          let hoverHeight = hoverObj.height ? hoverObj.height : null;
          if (hoverUrl) {
            await this.entityHelper.hoverUrl(this.canvas, this.props.navigation.activeLocation, hoverUrl).then(() => {
              // wait for the img to come if hover is empty then clear it. getting desync while waiting to load.
              if (this.props.hover.length === 0) {
                this.entityHelper.hoverClear(this.canvas);
              }
            });
          } else if (hoverLocation) {
            await this.entityHelper.hoverLocation(this.canvas, this.props.navigation.activeLocation, hoverLocation).then(() => {
              // wait for the img to come if hover is empty then clear it. getting desync while waiting to load.
              if (this.props.hover.length === 0) {
                this.entityHelper.hoverClear(this.canvas);
              } else {
                // after resolved if hover matches keep the matching entity and remove others.
                let hovers = _.filter(this.canvas.getObjects(), (x) => { if (x.id.includes("hover")) { return x; } });
                _.forEach(hovers, (x) => {
                  let locationCode = x.id.split("-")[1];
                  if (locationCode !== this.props.hover[0].location.code) {
                    this.canvas.remove(x);
                  }
                })
              }
            });
          } else if (hoverHeight) {
            this.entityHelper.hoverHeight(this.canvas, this.props.navigation.activeLocation, hoverHeight);
          }

        } else {
          this.entityHelper.hoverClear(this.canvas);
        }
      }
    }

    //--------------Hover end   ------------------//

    let allObjects = this.canvas.getObjects();
    _.forEach(allObjects, (x) => {
      if (x.id.includes("entity")) {
        x.setCoords();
      }
    });
    this.canvas.renderAll();
  }

  handleNewEntityBounds = (newBounds) => {
    // only update redux if I am the main view / aka not a thumbnail.
    if (this.props.isThumbnail) {
      if (newBounds) {
        this.props.changeSetting(this.props.customConfig.locations, newBounds.locationCode, { setting: "bounds", value: newBounds.bounds });
        // change update size to false, when height or width change it turns this to true.
        this.props.changeSetting(this.props.customConfig.locations, newBounds.locationCode, { setting: "_heightUpdated", value: false });
        this.props.changeSetting(this.props.customConfig.locations, newBounds.locationCode, { setting: "_widthUpdated", value: false });
        this.props.changeSetting(this.props.customConfig.locations, newBounds.locationCode, { setting: "_init", value: false });

        //New Entity data
        let existingCode = this.props.customConfig.locations.map(v => { return { code: v.code, order: v.displayOrder, groupName: v.groupName } });
        let isInit = newBounds.entity ? newBounds.entity.hasOwnProperty("_init") ? newBounds.entity._init : false : false;


        existingCode.sort(function (a, b) {
          var keyA = a.order,
            keyB = b.order;

          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });

        _.forEach(existingCode, (previousCode) => {
          let previousLocationCode = this.props.customConfig.locationBounds;
          let sourceLocation = this.props.baseProduct.locations.find(v => v.code == previousLocationCode);
          if (this.props.baseProduct.configurationType === 'custom-sub') {
            if ((newBounds.displayOrder < previousCode.order && previousCode.code != 'LC' && previousCode.code != 'RC' && previousCode.groupName == newBounds.groupName) || ((previousCode.code == 'LC' || previousCode.code == 'RC') && !isInit && newBounds.displayOrder < previousCode.order && previousCode.groupName == newBounds.groupName)) {
              let existingOrdersArray = existingCode.filter(v => v.order != previousCode.order && v.groupName == previousCode.groupName).map(v => v.order)
              let lastGreater = Math.max(...existingOrdersArray);

              existingOrdersArray = existingOrdersArray.filter(v => v < previousCode.order);
              existingOrdersArray = existingOrdersArray.sort((a, b) => a - b);
              const closestFirst = existingOrdersArray.length > 0 ? existingOrdersArray.reduce((a, b) => {
                return Math.abs(b - previousCode.order) < Math.abs(a - previousCode.order) ? b : a;
              }) : newBounds.displayOrder;

              let considerOrder = closestFirst ? closestFirst : newBounds.displayOrder;
              if (newBounds.displayOrder < closestFirst) {
                considerOrder = closestFirst;
              }
              let boundsValue = this.props.customConfig.locations.find(v => v.displayOrder == previousCode.order);
              let currentBounds = _.cloneDeep(newBounds.bounds);
              if (considerOrder != newBounds.displayOrder) {
                let boundsToConsider = this.props.customConfig.locations.find(v => v.displayOrder == considerOrder);
                currentBounds = _.cloneDeep(boundsToConsider.entity.bounds);
              }
              //Updated bounds are the bounds which will be updated after below conditions
              let updatedBounds = _.cloneDeep(boundsValue.entity.bounds);
              let viewImageDetails = _.find(this.props.baseProduct.views, { key: boundsValue.view });
              // zzz 600 is hard coded should be image size that we get from api works for now because request size is hard coded. relation: navigationActions.js
              let scale = 600 / viewImageDetails.masterImageSize.h;
              let newTop = 0;
              //To check the below display orders from current bounds in order to change bound values as per new bound orders
              if (this.props.customConfig.locations.find(v => v.displayOrder == lastGreater)) {
                // newTop = (currentBounds.h * scale )+ currentBounds.y +75;
                if (boundsValue.entity.type == 'player-number' && boundsValue.entity.size == '8in') {
                  newTop = currentBounds.y + (currentBounds.h * scale) + 90;
                  // }else if (boundsValue.entity.type == 'player-name' && boundsValue.entity.size == '2.5in') {
                  //   newTop = currentBounds.y + (currentBounds.h * scale) + 30;
                  // }else {
                } else {
                  newTop = currentBounds.y + (currentBounds.h * scale) + 75;
                }
                updatedBounds.y = newTop;
                updatedBounds.x = updatedBounds.x;
                this.props.changeSetting(this.props.customConfig.locations, previousCode.code, { setting: "bounds", value: updatedBounds });
                this.props.changeSetting(this.props.customConfig.locations, previousCode.code, { setting: "_heightUpdated", value: false });
                this.props.changeSetting(this.props.customConfig.locations, previousCode.code, { setting: "_widthUpdated", value: false });
                this.props.changeSetting(this.props.customConfig.locations, previousCode.code, { setting: "_init", value: false });
              }
            } else if ((sourceLocation && sourceLocation.displayOrder < previousCode.order && sourceLocation.groupName == previousCode.groupName && newBounds.locationCode != 'LC' && newBounds.locationCode != 'RC' && previousCode.groupName != newBounds.groupName)) {
              let existingOrdersArray = existingCode.filter(v => v.order != previousCode.order && v.groupName == previousCode.groupName).map(v => v.order)
              let lastGreater = Math.max(...existingOrdersArray);

              existingOrdersArray = existingOrdersArray.filter(v => v < previousCode.order);
              existingOrdersArray = existingOrdersArray.sort((a, b) => a - b);
              const closestFirst = existingOrdersArray.length > 0 ? existingOrdersArray.reduce((a, b) => {
                return Math.abs(b - previousCode.order) < Math.abs(a - previousCode.order) ? b : a;
              }) : previousCode.order;

              let considerOrder = closestFirst ? closestFirst : sourceLocation.displayOrder;
              if (sourceLocation.displayOrder < closestFirst) {
                considerOrder = closestFirst;
              }
              let boundsValue = this.props.customConfig.locations.find(v => v.displayOrder == previousCode.order);
              let considerBounds = this.props.customConfig.locations.find(v => v.displayOrder == considerOrder);
              let currentBounds = _.cloneDeep(considerBounds.entity.bounds);

              //Updated bounds are the bounds which will be updated after below conditions
              let updatedBounds = _.cloneDeep(boundsValue.entity.bounds);
              let viewImageDetails = _.find(this.props.baseProduct.views, { key: boundsValue.view });
              // zzz 600 is hard coded should be image size that we get from api works for now because request size is hard coded. relation: navigationActions.js
              let scale = 600 / viewImageDetails.masterImageSize.h;
              let newTop = 0;
              //To check the below display orders from current bounds in order to change bound values as per new bound orders
              if (this.props.customConfig.locations.find(v => v.displayOrder == lastGreater) && existingOrdersArray.length > 0) {
                newTop = currentBounds.y + (currentBounds.h * scale) + 75;
                updatedBounds.y = newTop;
                updatedBounds.x = updatedBounds.x;
                this.props.changeSetting(this.props.customConfig.locations, previousCode.code, { setting: "bounds", value: updatedBounds });
                this.props.changeSetting(this.props.customConfig.locations, previousCode.code, { setting: "_heightUpdated", value: false });
                this.props.changeSetting(this.props.customConfig.locations, previousCode.code, { setting: "_widthUpdated", value: false });
                this.props.changeSetting(this.props.customConfig.locations, previousCode.code, { setting: "_init", value: false });
              } else if (existingOrdersArray.length == 0) {
                let considerBounds = this.props.customConfig.locations.find(v => v.code == previousCode.code);
                let currentEntity = _.cloneDeep(considerBounds.entity);
                let rootLocation = _.find(this.props.customConfig.locations, { code: previousCode.code })
                let considerLocation = _.find(this.props.baseProduct.locations, { code: previousCode.code });
                let baseLocationSize = _.find(considerLocation.allowedEntities, { key: rootLocation.entity.type })
                let baseProductScale = 600 / viewImageDetails.masterImageSize.h;

                if (considerLocation.anchorOriginCode === 'CC') {
                  // zzz 600 is hard coded should be image size that we get from api works for now because request size is hard coded. relation: navigationActions.js
                  newTop = considerLocation.bounds.y * baseProductScale + considerLocation.bounds.h * baseProductScale / 2;
                } else if (considerLocation.anchorOriginCode === 'NC' || considerLocation.anchorOriginCode === 'SC') {
                  if (rootLocation.entity.size !== baseLocationSize.defaultSize) {
                    this.props.changeSetting(this.props.customConfig.locations, previousCode.code, { setting: "_rootSizeUpdated", value: true });
                  } else {
                    this.props.changeSetting(this.props.customConfig.locations, previousCode.code, { setting: "_deleteUpdated", value: true });
                  }
                }
                // zzz 600 is hard coded should be image size that we get from api works for now because request size is hard coded. relation: navigationActions.js
                //let newTop = considerLocation.bounds.y * baseProductScale + considerLocation.bounds.h * baseProductScale / 2;
                updatedBounds.y = newTop;
                updatedBounds.x = currentEntity.bounds.x;
                this.props.changeSetting(this.props.customConfig.locations, previousCode.code, { setting: "bounds", value: updatedBounds });
                this.props.changeSetting(this.props.customConfig.locations, previousCode.code, { setting: "_heightUpdated", value: false });
                this.props.changeSetting(this.props.customConfig.locations, previousCode.code, { setting: "_widthUpdated", value: false });
                this.props.changeSetting(this.props.customConfig.locations, previousCode.code, { setting: "_init", value: false });

              }


            }
          }
        })


      }
    }
  };

  handleBack(page = "main") {
    let tabObj = { tab: this.props.navigation.activeTab.tab, page };
    this.props.setActiveTab(tabObj);
  }

  removeLocation(code) {
    let locations = this.props.customConfig.locations;
    let location = _.findIndex(locations, { code: code });
    this.props.deleteLocation(location, locations);
    this.props.setActiveLocation(code, this.props.navigation);
    this.handleBack()
  }

  selectCanvasEntityObj = () => {
    let canvasEntity = _.find(this.canvas.getObjects(), { id: `entity-${this.props.navigation.activeLocation}` })
    if (canvasEntity) {
      this.canvas.setActiveObject(canvasEntity);
    } else {
      this.canvas.discardActiveObject();
    }
  }

  updateDimensions = () => {
    this.windowUpdater.update(this.canvasId, this.canvas);
  };

  async createImageSnapshot() {
    let png = null;
    try {
      png = await this.canvas.toDataURL({ format: 'png' });
      if (_.size(png) <= MIN_VALID_BASE64_PNG_SIZE) { png = null; }
    } catch (ex) {
      console.error('png capture error: ', ex);
      throw ex;
    }
    return png;
  }

  getProductImageUrl() {
    let styleNum = _.get(this.props.baseProduct, 'styleNum', null);
    let colorCode = _.get(this.props.customConfig, 'color', null);
    let colorKey = helpers.colors.getColorKeyFromColorCode(colorCode);
    let viewKey = _.get(this.props.view, 'key', null);
    if (!viewKey) {
      viewKey = _.get(this.props.view, 'view', null);
    }
    let size = 600;

    // use standard colors config by default
    let primaryColors = colorKey;


    if (helpers.product.isCustomSub() || helpers.product.isFullCustom()) {
      primaryColors = helpers.customLayers.getPrimaryColorKeys();


      let launchContextLayers = this.props.launchContext.layers;
      let customConfigLayer = this.props.customConfig.customLayers.layers
      let baseProductLayers = this.props.baseProduct.customLayers.layers
      let baseProductDefaultColors = this.props.baseProduct.customLayers.defaultColors
      let test = _.isEqual(customConfigLayer, launchContextLayers)
      if (launchContextLayers !== undefined) {
        if (customConfigLayer.length === 0) {
          this.props.customConfig.customLayers.layers = {}
          this.props.customConfig.customLayers.layers = launchContextLayers
        }
        if (!test) {
          // this.props.launchContext.layers = this.props.customConfig.customLayers.layers;
          launchContextLayers = this.props.launchContext.layers;
          customConfigLayer = this.props.customConfig.customLayers.layers
          if (customConfigLayer.length < launchContextLayers.length) {
            let index = launchContextLayers.length - customConfigLayer.length;
            let tempLayers = launchContextLayers;
            tempLayers.splice(customConfigLayer.length, index)
            this.props.customConfig.customLayers.layers = tempLayers;
          }
          else {
            if (baseProductLayers.length > customConfigLayer.length) {
              // let base_index = baseProductLayers.length - customConfigLayer.length;
              this.props.customConfig.customLayers.layers = launchContextLayers;
              _.each(baseProductLayers, (layer, index) => {
                if (launchContextLayers.length <= index) {
                  let layerName = layer.name;
                  if (layerName) {
                    layerName = layerName.replace(/[\{].+?[\}]/g, '');
                    layerName = layerName.trim();
                  }
                  customConfigLayer.push({ key: layer.key, color: baseProductDefaultColors[index], layerName: layerName, colorGroup: layer.colorGroup });
                }
              })
            }
            else if (primaryColors.length === 1) {
              this.props.customConfig.customLayers.layers = launchContextLayers;
              customConfigLayer = this.props.customConfig.customLayers.layers;
            }
            if (baseProductLayers.length < customConfigLayer.length) {
              let length = customConfigLayer.length - baseProductLayers.length
              customConfigLayer.splice(baseProductLayers.length - 1, length)
            }
            if (primaryColors.length === customConfigLayer.length) {
              // baseProductLayers.splice(0,launchContextLayers.length,launchContextLayers)
              // this.props.customConfig.customLayers.layers = customConfigLayer;
            }
          }
        }
      }
    }

    let opts = helpers.urlRender.generateUrlOptions(this.props.customConfig, this.props.launchContext, this.props.baseProduct);

    if (this.props.showHover) {
      let hoverColorDef = helpers.hover.getProductColorDef();
      let hoverColorKey = _.get(hoverColorDef, 'key', null);
      if (hoverColorKey) {
        primaryColors = hoverColorKey;
      }

      // check hover stitching upgrades
      let stitchingColorDef = helpers.hover.getStitchingColorDef();
      let stitchingColorKey = _.get(stitchingColorDef, 'key', null);
      if (stitchingColorKey) {
        _.set(opts, 'layers.stitching', stitchingColorKey);
      }
    }

    // check pattern
    let patternDef = helpers.hover.getPatternHoverItem();
    if (patternDef) {
      if (helpers.customLayers.isAdvancedMode()) {
        let layers = _.get(this.props, "customConfig.customLayers.layers");
        let tempLayers = [];
        _.each(layers, layer => {
          tempLayers.push(helpers.colors.getColorKeyFromColorCode(layer.color));
        })
        tempLayers = tempLayers.join(",");
        opts.layers = patternDef.pattern;
        primaryColors = tempLayers;
      } else {
        opts.layers = { [patternDef.pattern]: true };
      }
    }

    // check layer colors
    let primaryColorsHover = helpers.hover.getPrimaryColorsHoverItem();
    if (primaryColorsHover) {
      if (helpers.customLayers.isAdvancedMode()) {
        opts.layers = primaryColorsHover.primaryColors;
      } else {
        primaryColors = primaryColorsHover.primaryColors;
      }
    }

    let url = null;
    if (styleNum && viewKey) {
      url = helpers.urlRender.getProductRenderUrl(styleNum, viewKey, size, primaryColors, opts);
    }

    if (this.props.isBasic) {
      let customLayers = _.get(this.props, "customConfig.customLayers")
      let customColorLayers = _.get(this.props, "customConfig.customLayers.layers");
      let newLayerColors = [];
      _.each(customColorLayers, layer => {
        newLayerColors.push(helpers.colors.getColorKeyFromColorCode(layer.color))
      })
      let newLayersObj = { [customLayers.primaryPattern.key]: newLayerColors.join(",") }
      _.each(newLayerColors, (color, index) => {
        newLayersObj[`layer_${index + 1}`] = color
      })
      let newOpts = {
        layers: newLayersObj
      }
      url = helpers.urlRender.getProductRenderUrl(styleNum, viewKey, size, primaryColors, newOpts);
    }
    return url;
  }

  render() {
    return (
      <div className={`preview-canvas-two ${this.props.className}`}>
        <div id={`canvasSizer-${this.canvasId}`} className="canvas-sizer">
          <div className="innerCanvasContainer" id={`innerCanvasContainer-${this.canvasId}`}>
            <canvas id={this.canvasId} height="100%" width="100%" ></canvas>
          </div>
        </div>
      </div>
    );
  };
}


const outputActions = {
  setActiveLocation,
  setActiveTab,
  changeSetting,
  setView,
  addPendingLocationToLocations,
  updateLocationsBound,
  previewSample,
  locationUsed,
  dragItem,
  dndLocToLoc,
  moveToEmptyLocation,
  setOriginalEntityPosition,
  setPendingLocation,
  setCustomTextInput,
  SetEditDecorationType,
  SetDecorationSubtab
};


function mapStateToProps(state) {
  return {
    baseProduct: state.baseProduct,
    customConfig: state.customConfig,
    navigation: state.navigation,
    hover: state.hover,
    launchContext: state.launchContext,
    decoration: state.decoration
  }
}

export default connect(mapStateToProps, outputActions)(PreviewCanvasTwo);
