import helpers from '../../../helpers';
import _ from 'lodash';
import { fabric } from 'fabric';
import Promise from 'bluebird';


export default class EntityHelper {
  constructor() {
    this._cachedImages = {};
  }

  // state helpers
  getStateVal(key, defaultValue = null) {
    return helpers.state.get(key, defaultValue);
  };

  test() {
    // let baseProduct = this.getStateVal('baseProduct');
  }

  /////// below updated, needed  or added  ///////
  swapAndReplace(location, sample){
    // swap or replace Logic start //
    let url = null;
    location = _.cloneDeep(location);
    let navigation = this.getStateVal('navigation');
    let locationCode = location.code;
    if (sample === "original-a"){
      if ( locationCode === navigation.previewSample.locationDetailsA.code){
        url = null;
      }
      let locationB = _.get(navigation, "previewSample.locationDetailsB", null);
      if ( locationB ) {
        if ( locationCode === navigation.previewSample.locationDetailsB.code){
          url = null;
        }
      }
    }
    if (sample === "original-b"){
      if ( locationCode === navigation.previewSample.locationDetailsB.code){
        let newEnt = _.cloneDeep(_.get(navigation, "previewSample.locationContentB.entity"));
        url = newEnt.url;
      }
      if ( locationCode === navigation.previewSample.locationDetailsA.code){
        let newEnt = _.cloneDeep(_.get(navigation, "previewSample.locationContentA.entity"));
        url = newEnt.url;
      }
    }
    if (sample === "replace-a"){
      if (navigation.previewSample.locationDetailsB === null) {  // if b is null then adding new
        if ( locationCode === navigation.previewSample.pendingLocationDetails.code){
          url = navigation.previewSample.pendingLocationContent.entity.url;
        }
      } else {
        if ( locationCode === navigation.previewSample.locationDetailsA.code){  // put nothing in
          url = " ";
        }
        if ( locationCode === navigation.previewSample.locationDetailsB.code){  // set b to a
        let newEnt = _.cloneDeep(_.get(navigation, "previewSample.locationContentA.entity"));
        url = newEnt.url;
        }
      }
    }
    if (sample === "replace-b"){
      if (navigation.previewSample.locationDetailsB === null) {
        if ( locationCode === navigation.previewSample.pendingLocationDetails.code){  // do we get here?
          url = navigation.previewSample.pendingLocationContent.entity.url;
        }
      } else {
        if ( locationCode === navigation.previewSample.locationDetailsA.code){  // a in on another view
          url = " ";
        }
        if ( locationCode === navigation.previewSample.locationDetailsB.code){ // set b to a
          let newEnt = _.cloneDeep(_.get(navigation, "previewSample.locationContentA.entity"));
          url = newEnt.url;
        }
      }
    }
    if (sample === "swap-a"){
      if ( locationCode === navigation.previewSample.locationDetailsA.code){
        let newEnt = _.cloneDeep(_.get(navigation, "previewSample.locationContentB.entity"));
        url = newEnt.url;

      }
      if ( locationCode === navigation.previewSample.locationDetailsB.code){
        let newEnt = _.cloneDeep(_.get(navigation, "previewSample.locationContentA.entity"));
        url = newEnt.url;
      }
    }
    if (sample === "swap-b"){
      if ( locationCode === navigation.previewSample.locationDetailsB.code){
        let newEnt = _.cloneDeep(_.get(navigation, "previewSample.locationContentA.entity"));
        url = newEnt.url;
      }
      if ( locationCode === navigation.previewSample.locationDetailsA.code){
        let newEnt = _.cloneDeep(_.get(navigation, "previewSample.locationContentB.entity"));
        url = newEnt.url;
      }
    }

    // swap or replace logic end //
    let override = {url, location}
    return override;
}

  mountAll(canvas, view, sample){
    let customConfig = this.getStateVal('customConfig');
    _.forEach(customConfig.locations, (location)=>{
      location = _.cloneDeep(location);
      let override;
      override = this.swapAndReplace(location, sample)

      if(view.key === location.view || view.view === location.view){
        // my location has an override . overrides come from swap and replace previews.
        if( sample ) {
          if( override && override.location.code === location.code ){
            if ( override.url ){
              // for Previews if override treat preview entities like brand new with init.
              let resetBounds = { x:0 , y:0, h:0, w:0, r:0 }
              location.entity.bounds = resetBounds;
              this.init( canvas, location, override )
            } else {
              // special mount call for previews treat the rest of the entities normally but add a 50% opacity fade on them.
              let fade = true;
              if ( sample === "original-a" || sample === "original-b" || sample === "render"){
                this.mountSingle(canvas, location );
              } else {
                this.mountSingle(canvas, location, fade );
              }
            }
          }
        } else {
          //  regular mount my location does not have an override
          this.mountSingle(canvas, location );   // Not handling new bounds for this case
        }
      }
    });
  }

  clearAll(canvas){
    let filterCanvasEntities = _.filter(canvas.getObjects(),  (x)=> { if ( x.id.includes("entity")){return x;} });
    _.forEach(filterCanvasEntities, (x)=>{
      canvas.remove(x);
    });
  }

  // if the size change overhangs location box but does not exceed the max width.
  handleResizeOverhangLocation( canvasEntity, canvasLocation, canvas ){
    let baseProduct = this.getStateVal('baseProduct');
    let entityBoundingRect
    if(baseProduct.configurationType === 'custom-sub'){
     entityBoundingRect = canvasLocation.getBoundingRect();
    }else{
     entityBoundingRect = canvasEntity.getBoundingRect();
    }
    let locationBoundingRect = canvasLocation.getBoundingRect();
    let zoom = canvas.getZoom();

    if ( entityBoundingRect.left < locationBoundingRect.left){
      canvasEntity.left = locationBoundingRect.left / zoom  + entityBoundingRect.width / zoom / 2 ;
    }
    if ( entityBoundingRect.top < locationBoundingRect.top){
      canvasEntity.top = locationBoundingRect.top / zoom  + entityBoundingRect.height / zoom / 2 ;
    }
    if ( entityBoundingRect.left + entityBoundingRect.width > locationBoundingRect.left + locationBoundingRect.width){
      canvasEntity.left = (locationBoundingRect.left + locationBoundingRect.width ) / zoom  - entityBoundingRect.width / zoom / 2 ;
    }
    if ( entityBoundingRect.top + entityBoundingRect.height > locationBoundingRect.top + locationBoundingRect.height){
      canvasEntity.top = (locationBoundingRect.top + locationBoundingRect.height ) / zoom  - entityBoundingRect.height / zoom / 2 ;
    }
    canvasEntity.setCoords();
  }

  async updateSize( canvas, location, scaleFromArg , hover, cloneCanvasLocationObj){
    let bounds = null;
    let locationCode = location.code;
    let view = location.view;
    let groupName = location.groupName;
    let displayOrder = location.displayOrder;
    let baseProduct = this.getStateVal('baseProduct');
    let canvasEntity;
    if ( hover ){
      canvasEntity = cloneCanvasLocationObj;
    } else {
      canvasEntity = _.find(canvas.getObjects(), {id:`entity-${locationCode}`});
    }

    let canvasLocation = _.find(canvas.getObjects(), {id:`location-${locationCode}`});
    let scaleFrom;

    if ( canvasEntity && canvasLocation ) {
      if( scaleFromArg === "width"){
        scaleFrom = location.entity.bounds.w /  canvasEntity.width ;
      } else if ( scaleFromArg === "height"){
        scaleFrom = location.entity.bounds.h /  canvasEntity.height ;
      }

      let sizeObj = this.getEntityDataSizeObj( location );
      if ( sizeObj ){
        this.checkSizeObject( canvas , location , sizeObj, canvasEntity, scaleFromArg, scaleFrom,  hover  );
      }

      // if allow free drag false set to anchor origin.
      let entityData = this.getEntityData( location );
      if ( !entityData.allowFreeDrag ){
        let pos = this.getAnchorOrigin( location, null , canvasEntity.scaleX, canvasEntity.scaleY, canvasEntity );
        canvasEntity.left = pos.left;
        canvasEntity.top = pos.top;
      } else if(baseProduct.configurationType === 'custom-sub' ){
        let pos = this.getAnchorOrigin( location, null , canvasEntity.scaleX, canvasEntity.scaleY, canvasEntity );
        if(location.code!= 'RC' &&  location.code!= 'LC'){
        let customConfig = this.getStateVal('customConfig')
        let existingCode = customConfig.locations.map(v => { return { code: v.code, order: v.displayOrder, groupName: v.groupName } });
        let currentLocation = _.cloneDeep(location)
        let isInit = currentLocation.entity.hasOwnProperty("_init");
        let existingOrdersArray = existingCode.filter(v => v.order != currentLocation.displayOrder && v.groupName == currentLocation.groupName).map(v => v.order)
        // let lastGreater = Math.max(...existingOrdersArray);

        // existingOrdersArray = existingOrdersArray.filter(v=>v <= currentLocation.displayOrder);

        const closestFirst = existingOrdersArray.length > 0 ? existingOrdersArray.reduce((a, b) => {
          return Math.abs(b - currentLocation.displayOrder) < Math.abs(a - currentLocation.displayOrder) ? b : a;
        }) : currentLocation.displayOrder;
        if (currentLocation.displayOrder > closestFirst && existingOrdersArray.length > 0 && baseProduct.configurationType == 'custom-sub') {
          let previousCode = existingCode.find(v => v.order == closestFirst)
          let boundsValue = customConfig.locations.find(v => v.code == previousCode.code);
          let ufBounds = _.cloneDeep(boundsValue.entity.bounds);
          let viewImageDetails = _.find(baseProduct.views, { key: location.view });
          // zzz 600 is hard coded should be image size that we get from api works for now because request size is hard coded. relation: navigationActions.js
          let scale = 600 / viewImageDetails.masterImageSize.h;
          let newTop = 0;
          if (currentLocation.entity.type == 'player-number' && currentLocation.entity.size == '8in') {
            newTop = (ufBounds.h * scale) + ufBounds.y + 90;
            canvasEntity.left = pos.left;
            canvasEntity.top = newTop;
          } else {
            newTop = (ufBounds.h * scale) + ufBounds.y + 75;
            //canvasEntity.left = pos.left;
            canvasEntity.top = newTop;
          }
        }
      }
     }
      this.handleResizeOverhangLocation( canvasEntity, canvasLocation, canvas );

      bounds = { locationCode, view, groupName, displayOrder, bounds: { x: canvasEntity.left, y: canvasEntity.top, w: canvasEntity.width * canvasEntity.scaleX, h: canvasEntity.height * canvasEntity.scaleY, r: canvasEntity.angle } };
      return bounds;
    }
  }

  checkSizeObject( canvas , location , sizeObj, canvasEntity, scaleFromArg, scaleFrom,  hover ){
    let scaleX, scaleY;
    let locationCode = location.code;
    if ( !sizeObj.allowHeight && !sizeObj.allowWidth ){
      // TODO: free scale could be improved when going outside bounds.
      // I can increment height and width independently , squash and stretch;
      // if width false, and height false
      scaleX = location.entity.bounds.w /  canvasEntity.width  ;
      scaleY = location.entity.bounds.h /  canvasEntity.height ;
      if ( sizeObj ){
        let resizeScale;
        let img = canvasEntity;
        // how you want to handle resizing if it goes outside location bounds.
        canvasEntity.set({
          scaleX: scaleX,
          scaleY: scaleY,
        });
        canvasEntity.setCoords();
        if( !this.doesObjectFitInsideLocation( canvas , location ) ) {
          resizeScale = this.resize( canvas , img , locationCode );
          if ( scaleFromArg === "width" ){
            scaleX = resizeScale;
          } else if ( scaleFromArg === "height" ){
            scaleY = resizeScale;
          }
        }
      }
    } else if ( sizeObj.allowHeight && !sizeObj.allowWidth ){
      // I can increment height. Maintain its height, but width can be squished.
      // if height true, and width false
      // if(location.entity.textEffect != 'text-and-tail-straight' || location.entity.textEffect === "text-and-tail"){
      //   let textSize = location.entity.size
      //   let h = textSize.replace(/[^0-9\.]/g,"");
      //   let masterImageDpi = 15;
      //   location.entity.bounds.h = h * masterImageDpi
      // }
      let height = location.entity.bounds.h / canvasEntity.height
      height = location.entity.textEffect === "text-and-tail-straight" || location.entity.textEffect === "text-and-tail"  ? height + 0.04 : height
      scaleX = location.entity.bounds.h /  canvasEntity.height ;
      //scaleY = location.entity.bounds.h / canvasEntity.height;
      scaleY = height;
      canvasEntity.set({
        scaleX: scaleX,
        scaleY: scaleY,
      });
      canvasEntity.setCoords();
      if ( sizeObj ){
        let resizeScale;
        let img = canvasEntity;
        // how you want to handle resizing if it goes outside location bounds.

        if( !this.doesObjectFitInsideLocation( canvas , location, null, null, canvasEntity, hover ) ) {
          resizeScale = this.resize( canvas , img , locationCode );
          scaleX = resizeScale;
        }
      }
    } else if ( !sizeObj.allowHeight && sizeObj.allowWidth ){
      // I can increment height. Maintain its width, but height can be squished.
      // if width true, and height false
      scaleX = location.entity.bounds.w /  canvasEntity.width ;
      scaleY = location.entity.bounds.w /  canvasEntity.width ;
      canvasEntity.set({
        scaleX: scaleX,
        scaleY: scaleY,
      });
      canvasEntity.setCoords();
      if ( sizeObj ){
        let resizeScale;
        let img = canvasEntity;
        // how you want to handle resizing if it goes outside location bounds.
        if( !this.doesObjectFitInsideLocation( canvas , location, null, null, canvasEntity, hover ) ) {
          resizeScale = this.resize( canvas , img , locationCode );
          scaleY = resizeScale;
        }
      }
    } else if ( sizeObj.allowHeight && sizeObj.allowWidth ){
      // I can increment height and width but img maintains aspect ratio
      // if width true, and height true
      scaleX = scaleFrom;
      scaleY = scaleFrom;
      canvasEntity.set({
        scaleX: scaleX,
        scaleY: scaleY,
      });
      canvasEntity.setCoords();
      if ( sizeObj ){
        let resizeScale;
        let img = canvasEntity;
        // how you want to handle resizing if it goes outside location bounds.
        if( !this.doesObjectFitInsideLocation( canvas , location ) ) {
          resizeScale = this.resize( canvas , img , locationCode );
          scaleX = resizeScale;
          scaleY = resizeScale;
        }
      }
    }
    canvasEntity.set({
      scaleX: scaleX,
      scaleY: scaleY,
    });
    canvasEntity.setCoords();
  }

  // resizes entity to fit inside locationBox.
  resize (canvas , img , locationCode){
    let zoom = canvas.getZoom();
    let locationCanvasEntity = _.find(canvas.getObjects(), {id:`location-${locationCode}`});
    let locationDetailsBoundingRect = locationCanvasEntity.getBoundingRect();
    let cW = img.width ;
    let cH = img.height ;
    let angle = img.angle;
    let rad = fabric.util.degreesToRadians(angle);
    let cBW = Math.abs(cH * Math.sin(rad)) + Math.abs(cW * Math.cos(rad));
    let cBH = Math.abs(cW * Math.sin(rad)) + Math.abs(cH * Math.cos(rad));
    let nBW = locationDetailsBoundingRect.width / zoom;
    let nBH = locationDetailsBoundingRect.height / zoom;
    let CoeffH = nBW / cBW;
    let CoeffV = nBH / cBH;
    let Coeff = Math.min(CoeffH, CoeffV);

    let scale = Coeff;
    return scale;
  }

  getEntityDataSizeObj( location ){
    let entityData = this.getEntityData( location );
    let sizeObj = _.find(entityData.allowedSizes, {key: entityData.defaultSize});
    return (sizeObj);
  }

  getDefaultHeight( location ){
    let locationCode = location.code;
    let sizeObj = this.getEntityDataSizeObj( location );
    let baseProduct = this.getStateVal('baseProduct');
    let locationDetails = _.find(baseProduct.locations, {code:locationCode});
    let viewImageDetails = _.find(baseProduct.views, {key:locationDetails.view});
    let heightConversion = 0;
    /*** Build: 405 - Incorrect initial player number image in the respective locations. ***/
    if (locationDetails.defaultEntityType === 'player-name') {
      /*** Build: 456 - Player Name Text not reflected on Product images - Stock Products. ***/
      if (sizeObj.w !== null)
        heightConversion = sizeObj.w * viewImageDetails.masterImageDpi;
      else
        heightConversion = sizeObj.h * viewImageDetails.masterImageDpi;
    } else {
      if (sizeObj.w !== null)
        heightConversion = sizeObj.w * viewImageDetails.masterImageDpi;
      else
        heightConversion = sizeObj.h * viewImageDetails.masterImageDpi;
    }
    heightConversion = heightConversion.toFixed(4) * 1 ;
    return (heightConversion);
  }

  getEntityData( location ){
    let locationCode = location.code;
    let baseProduct = this.getStateVal('baseProduct');
    let locationDetails = _.find(baseProduct.locations, {code:locationCode});
    let entityData = _.find(locationDetails.allowedEntities, {key:location.entity.type});
    return entityData;
  }

  getAnchorOrigin( location, img , scaleX, scaleY, canvasEntity ){
    let locationCode = location.code;
    let baseProduct = this.getStateVal('baseProduct');
    let locationDetails = _.find(baseProduct.locations, {code:locationCode});
    let viewImageDetails = _.find(baseProduct.views, {key:locationDetails.view});
    let baseProductScale = 600 / viewImageDetails.masterImageSize.h;
    let left, top;
    let imgBoundingRect;
    if( img ){
      // img is something we have before its added to canvas. so we don't have a canvasEntity.
      imgBoundingRect = img.getBoundingRect();
    }
    if ( canvasEntity ) {
      // create obj off the canvas entity to match img.boundingRect.
      imgBoundingRect = { height: canvasEntity.height, width: canvasEntity.width };
    }
    let anchorOriginCode = _.toUpper(locationDetails.anchorOriginCode);
    switch (anchorOriginCode) {
      case "N":
      case "NC":
        left = (locationDetails.bounds.x * baseProductScale) + (locationDetails.bounds.w * baseProductScale / 2);
        top = locationDetails.bounds.y * baseProductScale + imgBoundingRect.height * scaleY / 2 ;
        if (canvasEntity)
          canvasEntity.top = top;
        break;
      case "S":
      case "SC":
        left = locationDetails.bounds.x * baseProductScale + locationDetails.bounds.w * baseProductScale / 2;
        top = locationDetails.bounds.y * baseProductScale + locationDetails.bounds.h * baseProductScale - imgBoundingRect.height * scaleY / 2;
        if (canvasEntity)
          canvasEntity.top = top;
        break;
      case "NE":
        left = locationDetails.bounds.x * baseProductScale + locationDetails.bounds.w * baseProductScale - imgBoundingRect.width * scaleX / 2;
        top = locationDetails.bounds.y * baseProductScale + imgBoundingRect.height * scaleY / 2;
        if (canvasEntity)
          canvasEntity.top = top;
        break;
      case "E":
      case "EC":
        left = locationDetails.bounds.x * baseProductScale + locationDetails.bounds.w * baseProductScale - imgBoundingRect.width * scaleX / 2;
        top = locationDetails.bounds.y * baseProductScale + locationDetails.bounds.h * baseProductScale / 2;
        if (canvasEntity)
          canvasEntity.top = top;
        break;
      case "SE":
        left = locationDetails.bounds.x * baseProductScale + locationDetails.bounds.w * baseProductScale - imgBoundingRect.width * scaleX / 2;
        top = locationDetails.bounds.y * baseProductScale + locationDetails.bounds.h * baseProductScale - imgBoundingRect.height * scaleY / 2;
        if (canvasEntity)
          canvasEntity.top = top;
        break;
      case "SW":
        left = locationDetails.bounds.x * baseProductScale + imgBoundingRect.width * scaleX / 2;
        top = locationDetails.bounds.y * baseProductScale + locationDetails.bounds.h * baseProductScale - imgBoundingRect.height * scaleY / 2;
        if (canvasEntity)
          canvasEntity.top = top;
        break;
      case "W":
      case "WC":
        left = locationDetails.bounds.x * baseProductScale + imgBoundingRect.width * scaleX / 2;
        top = locationDetails.bounds.y * baseProductScale + locationDetails.bounds.h * baseProductScale / 2;
        if (canvasEntity)
          canvasEntity.top = top;
        break;
      case "NW":
        left = locationDetails.bounds.x * baseProductScale + imgBoundingRect.width * scaleX / 2;
        top = locationDetails.bounds.y * baseProductScale + imgBoundingRect.height * scaleY / 2;
        if (canvasEntity)
          canvasEntity.top = top;
        break;
      case "C":
      case "CC":
      case "CENTER":
      default:
        left = locationDetails.bounds.x * baseProductScale + locationDetails.bounds.w * baseProductScale / 2;
        top = locationDetails.bounds.y * baseProductScale + locationDetails.bounds.h * baseProductScale / 2;
        if (canvasEntity)
          canvasEntity.top = top;
        break;
    }
    let customConfig = this.getStateVal('customConfig')
    let existingCode= customConfig.locations.map(v=>{return {code:v.code, order:v.displayOrder, groupName: v.groupName}});
    let currentLocation = _.cloneDeep(location)
    let isInit = currentLocation.entity.hasOwnProperty("_init");
    let existingOrdersArray = existingCode.filter(v => v.order!= currentLocation.displayOrder && v.groupName == currentLocation.groupName).map(v => v.order)
    //let lastGreater = Math.max(...existingOrdersArray);
     
    existingOrdersArray = existingOrdersArray.filter(v=>v<currentLocation.displayOrder);

    const closestFirst = existingOrdersArray.length>0 ?existingOrdersArray.reduce((a, b) => {
      return Math.abs(b - currentLocation.displayOrder) < Math.abs(a - currentLocation.displayOrder) ? b : a;
    }) : currentLocation.displayOrder;

    if(isInit && currentLocation.displayOrder > closestFirst && existingOrdersArray.length > 0 &&
        currentLocation.code!= 'RC' &&  currentLocation.code!= 'LC' && baseProduct.configurationType === 'custom-sub' ) {
      let previousCode  = existingCode.find( v => v.order == closestFirst  && v.groupName == currentLocation.groupName)
      let boundsValue = customConfig.locations.find(v=>v.code== previousCode.code);
      let ufBounds = _.cloneDeep( boundsValue.entity.bounds );
      let viewImageDetails = _.find(baseProduct.views, {key:location.view});
      // zzz 600 is hard coded should be image size that we get from api works for now because request size is hard coded. relation: navigationActions.js
      let scale = 600 / viewImageDetails.masterImageSize.h;            
      let newTop =0;
      // if(currentLocation.code == 'CF'){
      // newTop = (ufBounds.h * scale )+ ufBounds.y + 75;
      // }else{
        // if (currentLocation.entity.type == 'player-name' && currentLocation.entity.size == '2.5in') {
        //   console.log('player number')
        //   newTop = (ufBounds.h * scale )+ ufBounds.y + 40;
        // }else{
        newTop = (ufBounds.h * scale )+ ufBounds.y + 70;
        // }
      // }

      // newTop = ufBounds.y * baseProductScale + ufBounds.h * baseProductScale / 2;
      // newTop = 263 ;

      top = newTop;
    }
    return{left, top}
  }

  getBaseImageProperties( canvas , location , img, override ){
    let locationCode = location.code;
    let baseProduct = this.getStateVal('baseProduct');
    let locationDetails = _.find(baseProduct.locations, {code:locationCode});
    let entityData = _.find(locationDetails.allowedEntities, {key:location.entity.type});
    // set base image properties
    img.id = `entity-${locationCode}`;
    if (entityData){
      if( override && override.location ){
        img.id = `hover-${override.location}`;
      }
      img.originX = "center";
      img.originY = "center";
      img.selectable = true;
      // scale, position, and rotation set after being calculated.
      // img.scaleX = scaleX;
      // img.scaleY = scaleY;
      // img.left = left;
      // img.top = top;
      // img.rotate(angle);
      img.centeredScaling = true;
      img.lockScalingFlip = true;
      img.lockUniScaling = true;
      img.lockMovementX = false; //!entityData.allowFreeDrag; allow all entities to move. but check movement on objectMoving.js
      img.lockMovementY = false; //!entityData.allowFreeDrag; allow all entities to move. but check movement on objectMoving.js
      img.lockRotation = !entityData.capabilities.rotation;
      img.hasRotatingPoint = entityData.capabilities.rotation;
      if (!entityData.allowFreeDrag){
        img.hasControls = false;
        img.on("mouseover", (x)=>{
          canvas.hoverCursor = 'pointer';
        });
      } else {
        img.on("mouseover", (x)=>{
          canvas.hoverCursor = 'move';
        });
      }
      // player numbers should be draggable but not scale able.
      if( location.entity.type === 'player-number' ){
        img.hasControls = false;
      }
      // to disable image controls for sublimated products
      if(baseProduct.configurationType === 'custom-sub') {
        if(location.entity.type === 'player-number' || 'player-name' || 'custom-text'){
        img.hasControls = false;
      }
      }
    }
  }

  async loadImageFromUrl(url, urlCacheKey) {
    // BLD-287. isSafariCORS append a special suffix if browser does not support CORS header to ensure that image gets reloaded by browser.
    let isSafariCORS = helpers.state.get("navigation.isSafariCORS");
    if ( isSafariCORS ){
      url = helpers.urlRender.appendParamStringToUrl(url, 'cb=1')
    }

    if (!url || _.isEmpty(_.trim(url))) { return false; }

    this._cachedImages[urlCacheKey] = url;

    return new Promise((resolve, reject) => {
      fabric.Image.fromURL(url, (img) => {

        if (!img || !img.getElement()) {
          // console.error('EntityHelper.mountSingle invalid image for url: ' + url);
          return resolve(false);
        }

        // make sure that the image we were attempting to load is still this image.
        // ie. canvas X has URL X and canvas X hasn't been changed to URL Y in an out of sync load/apply action
        if (this._cachedImages[urlCacheKey] === url) {
          delete this._cachedImages[urlCacheKey];
        } else {
          return resolve(false);
        }

        resolve(img);

      }, { crossOrigin: 'anonymous' });
    });
  }

  doesObjectFitInsideLocation( canvas , location , img, scaleX, hoverCanvasEntity, hover){
    // run to see if canvas object will fit inside location Box;
    let canvasEntity = _.find(canvas.getObjects(), {id: `entity-${location.code}`});
    if ( hover ) {
      canvasEntity = hoverCanvasEntity;
    }
    let doesObjectFit = true;
    let canvasLocation = _.find(canvas.getObjects(), {id: `location-${location.code}`});
    let canvasLocationBoundingRect = canvasLocation.getBoundingRect();
    if( canvasEntity ){
      let canvasEntityBoundingRect = canvasEntity.getBoundingRect();
      if( ( canvasEntityBoundingRect.width )> canvasLocationBoundingRect.width  ||  (canvasEntityBoundingRect.height)  > canvasLocationBoundingRect.height){
        doesObjectFit = false;
      }
    } else {
      if ( img ){
        // img is a special size because we don't have the entity mounted on canvas yet so we have to manually set scale.( imgBoundingRect.width * scale * zoom )
        let imgBoundingRect = img.getBoundingRect();
        let zoom = canvas.getZoom();
        if( ( imgBoundingRect.width * scaleX * zoom )> canvasLocationBoundingRect.width  ||  (imgBoundingRect.height * scaleX * zoom)  > canvasLocationBoundingRect.height){
          doesObjectFit = false;
        }
      }
    }
    return doesObjectFit;
  }

  getInitScale( canvas, location, img, override ) {
    let locationCode = location.code;
    let size = location.entity.size;
    let scale, scaleX, scaleY;

    if ( size === "free-size" ){
      // allow any number increment
      // if it is free size set to percentage of location Box size
      scale = this.resize( canvas , img , locationCode );
      // scale offset only applied on new entity
      let scaleOffset = .8;
      scaleX = scale * scaleOffset;
      scaleY = scale * scaleOffset;
    } else {
      // find the matched default allowed height object. get width from img.
      let defaultHeight = this.getDefaultHeight( location );
      scaleX = defaultHeight / img.height;
      scaleY = defaultHeight / img.height;
    }

    let sizeObj = this.getEntityDataSizeObj( location );
    let hoverCanvasEntity;
    let hover;
    if ( override ){
      hover = true;
      hoverCanvasEntity = img;
    }

    if ( sizeObj ){
      let resizeScale;
      if( !this.doesObjectFitInsideLocation( canvas , location, img, scaleX, hoverCanvasEntity, hover) ) {
        resizeScale = this.resize( canvas , img , locationCode );
      }

      if ( !sizeObj.allowHeight && !sizeObj.allowWidth ){
        // I can increment height and width independently , squash and stretch;
      } else if ( sizeObj.allowHeight && !sizeObj.allowWidth ){
        // I can increment height. Maintain its height, but width can be squished.

        // if I don't fit inside location resizeScale
        if( resizeScale ) {
          scaleX = resizeScale;
        }
      } else if ( !sizeObj.allowHeight && sizeObj.allowWidth ){
        // I can increment height. Maintain its width, but height can be squished.

        // if I don't fit inside location resizeScale
        if( resizeScale ) {
          scaleY = resizeScale;
        }
      } else if ( sizeObj.allowHeight && sizeObj.allowWidth ){
        // I can increment height and width but img maintains aspect ratio

        // if I don't fit inside location resizeScale
        if( resizeScale ) {
          scaleX = resizeScale;
          scaleY = resizeScale;
        }
      }
    }

    return { scaleX, scaleY };
  }

  getScale( canvas, location, img, hover ) {
    let scaleX, scaleY;
    let locationCode = location.code;
    let baseProduct = this.getStateVal('baseProduct');
    if(baseProduct.configurationType === 'custom-sub' && location.entity.textEffect){
      let textSize = location.entity.size
      let h = textSize.replace(/[^0-9\.]/g,"");
      let masterImageDpi = 15;
      location.entity.bounds.h = h * masterImageDpi
    }
    // }else{
    //   let textSize = location.entity.size
    //   let h = textSize.replace(/[^0-9\.]/g,"");
    //   let masterImageDpi = 15;
    //   location.entity.bounds.h = h * masterImageDpi
    // }
    scaleY = location.entity.bounds.h / img.height;
    scaleX = location.entity.bounds.h / img.height;

    let sizeObj = this.getEntityDataSizeObj( location );

    if ( sizeObj ){
      let resizeScale;
      if( !this.doesObjectFitInsideLocation( canvas , location, img, scaleX, null, hover ) ) {
        resizeScale = this.resize( canvas , img , locationCode );
      }

      if ( !sizeObj.allowHeight && !sizeObj.allowWidth ){
        // I can increment height and width independently , squash and stretch;
        scaleY = location.entity.bounds.h / img.height;
        scaleX = location.entity.bounds.w / img.width;
      } else if ( sizeObj.allowHeight && !sizeObj.allowWidth ){
        // I can increment height. Maintain its height, but width can be squished.
        let height = location.entity.bounds.h / img.height
        let width = location.entity.bounds.h / img.height
        height = location.entity.textEffect === "text-and-tail-straight" || location.entity.textEffect === "text-and-tail"  ? height + 0.078 : height
        width  = location.entity.textEffect === "text-and-tail-straight" || location.entity.textEffect === "text-and-tail"  ? width + 0.04 : width
        //scaleY = location.entity.textEffect === "text-and-tail-straight" ? location.entity.bounds.h /  height + 0.05 : location.entity.bounds.h /  height ;
        //img.height = location.entity.textEffect === "text-and-tail-straight" ? 141 : img.height;
        scaleY = height;
        scaleX = width;
        // if I don't fit inside location resizeScale
        if( resizeScale ) {
          scaleX = resizeScale;
        }
      } else if ( !sizeObj.allowHeight && sizeObj.allowWidth ){
        // I can increment height. Maintain its width, but height can be squished.
        scaleY = location.entity.bounds.w / img.width;
        scaleX = location.entity.bounds.w / img.width;
        // if I don't fit inside location resizeScale
        if( resizeScale ) {
          scaleY = resizeScale;
        }
      } else if ( sizeObj.allowHeight && sizeObj.allowWidth ){
        // I can increment height and width but img maintains aspect ratio
        scaleY = location.entity.bounds.h / img.height;
        scaleX = location.entity.bounds.h / img.height;
        // if I don't fit inside location resizeScale
        if( resizeScale ) {
          scaleX = resizeScale;
          scaleY = resizeScale;
        }
      }
    }
    img.scaleX = scaleX;
    img.scaleY = scaleY;
  }

  getInitPosition( location, img, scaleX, scaleY){
    let left, top;
    // set to anchor;
    let position = this.getAnchorOrigin( location , img, scaleX, scaleY )
    left = position.left;
    top = position.top;
    return { left, top };
  }

  getPosition( canvas, location, img ){
    let left, top;
    left = location.entity.bounds.x;
    top = location.entity.bounds.y;
    img.left = left;
    img.top = top;

    // creating a tempImg canvas to mount to get bounding box locations for overhang.
    let tempImg = _.cloneDeep(img);

    tempImg.id = "tempImg";
    tempImg.opacity = 0;
    canvas.add(tempImg)
    tempImg.top = top;
    tempImg.left = left;
    tempImg.setCoords();

    let locationCode = location.code;
    let canvasEntity = _.find(canvas.getObjects(), {id:"tempImg"});
    let canvasLocation = _.find(canvas.getObjects(), {id:`location-${locationCode}`});
    this.handleResizeOverhangLocation( canvasEntity, canvasLocation, canvas );

    img.left = canvasEntity.left;
    img.top = canvasEntity.top;
    // then remove tempImg
    let tempExists =  _.find(canvas.getObjects(), {id:"tempImg"});
    if( tempExists ){
      canvas.remove(tempExists);
    }
  }

  setInitProperties( canvas , location, img, override ){
    // let locationCode = location.code;
    // getBaseImageProperties  takes care of the base properties, does rotate, can free drag.
    this.getBaseImageProperties( canvas, location, img, override);

    // placeholder values
    let scaleX = .1;
    let scaleY = .1;
    let left = 0;
    let top = 0;
    // let angle = 0;

    // get scale
    let scale = this.getInitScale( canvas, location, img, override );
    scaleX = scale.scaleX;
    scaleY = scale.scaleY;
    // get position
    let position = this.getInitPosition( location, img , scaleX, scaleY );
    left = position.left;
    top = position.top;

    // set properties
    img.scaleX = scaleX;
    img.scaleY = scaleY;
    img.left = left;
    img.top = top;
  }

  // call this when its needs to act like a brand new entity.
  async init( canvas, location, override = null ){

    let bounds = null;
    location = _.cloneDeep(location);
    // resetting bounds so no rotation or positioning come through when entity is init.
    let resetBounds = { x:0 , y:0, h:0, w:0, r:0 };
    let comp = _.isEqual(location.entity.bounds, resetBounds);
    // if( !comp ){
    //   this.mountSingle( canvas, location );
    //   return;
    // }

    let locationCode = location.code;
    let view = location.view;
    let groupName = location.groupName;
    let entity = location.entity
    let displayOrder = location.displayOrder;
    let url = location.entity.url;
    if( override && override.url ){
      url = override.url;
    };
    let img = await this.loadImageFromUrl(url, locationCode);

    if (!img || !img.getElement()) {
      // console.error('EntityHelper.mountSingle invalid image for url: ' + url);
      return false;
    }

    let isLocationOverride = override && override.location;
    if( !isLocationOverride ){
      // only remove old entity if location override is not happening
      let oldEntity = _.find(canvas.getObjects(), {id:`entity-${locationCode}`});
      if (oldEntity){
          canvas.remove(oldEntity);
      };
    };

    this.setInitProperties( canvas , location, img, override );

    // then add to canvas
    canvas.add(img);
    canvas.renderAll();

    // need to return bounds for when img is to big for location need to send data back to update redux.
    bounds = {locationCode, view, groupName, entity, displayOrder, bounds:{x:img.left, y: img.top, w:img.width * img.scaleX, h: img.height * img.scaleY, r: img.angle}};
    return bounds;
  }

  async mountSingle( canvas, location, fade, hover ){
    // if the canvas does not match the view don't run.
    if ( location.view !== canvas.getElement().id.split("-")[1]) {
      return ;
    }
    let bounds = null;
    location = _.cloneDeep(location);
    let locationCode = location.code;
    let url = location.entity.url;

    let img = await this.loadImageFromUrl(url, locationCode);

    if (!img || !img.getElement()) {
      // console.error('EntityHelper.mountSingle invalid image for url: ' + url);
      return false;
    }

    if ( !hover ){
      let oldEntity = _.find(canvas.getObjects(), {id:`entity-${locationCode}`});
      if (oldEntity){
          canvas.remove(oldEntity);
      };
    }

    // set flip art property
    if(location.entity.flipArt===true){
      img.flipX = true;
    }

    // get base img properties
    this.getBaseImageProperties( canvas , location , img );

    // set rotation
    img.rotate(location.entity.bounds.r);

    // get scale
    this.getScale( canvas, location, img, hover );

    // get position
    this.getPosition( canvas, location, img );

    if ( fade ) {
      img.opacity = .5;
    }
    if ( hover ) {
      img.id = "hover";
    }

    // then add to canvas
    canvas.add(img);
    canvas.renderAll();

    // need to return bounds for when img is to big for location need to send data back to update redux.
    bounds = {locationCode, bounds:{x:img.left, y:  img.top, w:img.width * img.scaleX, h: img.height * img.scaleY, r: img.angle}};
    return bounds;
  }

  async hoverUrl( canvas, locationCode, hoverUrl ) {
    canvas.discardActiveObject();
    let allObjects = canvas.getObjects();
    _.forEach(allObjects, (x)=>{
      if (x.id.includes("hover")){
        canvas.remove(x);
      }
    });

    let customConfig = this.getStateVal('customConfig');
    let reduxLocationObj = _.find( customConfig.locations, {code:locationCode} );
    let canvasLocationObj = _.find( canvas.getObjects(), {id:`entity-${locationCode}`} );
    let cloneLocation = _.cloneDeep(reduxLocationObj);
    cloneLocation.entity.url = hoverUrl;
    let hover = true;

    await this.mountSingle( canvas, cloneLocation, null, hover );

    if( canvasLocationObj ){
      canvasLocationObj.opacity = 0;
    }
  }

  hoverHeight( canvas, locationCode, hoverHeight ) {
    canvas.discardActiveObject();

    let allObjects = canvas.getObjects();
    _.forEach(allObjects, (x)=>{
      if (x.id.includes("hover")){
        canvas.remove(x);
      }
    });

    let customConfig = this.getStateVal('customConfig');
    let reduxLocationObj = _.find( customConfig.locations, {code:locationCode} );
    let canvasLocationObj = _.find( canvas.getObjects(), {id:`entity-${locationCode}`} );
    if( !canvasLocationObj ){
      return;
    }
    canvasLocationObj.opacity = 0;

    let cloneLocation = _.cloneDeep(reduxLocationObj);
    cloneLocation.entity.bounds.h = hoverHeight;

    let cloneCanvasLocationObj = _.cloneDeep(canvasLocationObj);
    cloneCanvasLocationObj.id = `hover-${locationCode}`;
    cloneCanvasLocationObj.opacity = 1;
    canvas.add(cloneCanvasLocationObj);

    let scaleFrom = "height";
    let hover = true;
    this.updateSize( canvas, cloneLocation, scaleFrom, hover, cloneCanvasLocationObj );
  }

  async hoverLocation( canvas, locationCode, hoverLocation ) {
    canvas.discardActiveObject();

    let baseProduct = this.getStateVal('baseProduct');
    let bpLocation = _.find( baseProduct.locations, { code: hoverLocation.code });
    let customConfig = this.getStateVal('customConfig');
    let reduxLocationObj = _.find( customConfig.locations, {code:locationCode} );
    let canvasLocationObj = _.find( canvas.getObjects(), {id:`entity-${locationCode}`} );

    if( !canvasLocationObj ){
      return;
    }

    if ( locationCode === hoverLocation.code ){
      canvasLocationObj.opacity = 1;
      return;
    }

    // if view does not match don't show hover preview.
    if( bpLocation && bpLocation.view !== reduxLocationObj.view){
      canvasLocationObj.opacity = .5;
      return;
    }

    canvasLocationObj.opacity = .5;
    let canvasLocationTargetObj = _.find( canvas.getObjects(), {id:`entity-${hoverLocation.code}`} );
    if( canvasLocationTargetObj ){
      canvasLocationTargetObj.opacity = 0;
    }

    let cloneLocation = _.cloneDeep(reduxLocationObj);
    let resetBounds = {x:0 , y:0, w:0, h:0, r:0 };
    cloneLocation.code = hoverLocation.code;
    cloneLocation.entity.bounds = resetBounds;

    let override = { location: hoverLocation.code }
    await this.init( canvas, cloneLocation, override ).then(()=>{
    })

  }

  hoverClear( canvas ){
    _.forEach(canvas.getObjects(), (x)=>{
      if (x.id.includes("hover")){
        canvas.remove(x);
      }
       else if  (x.id.includes("entity")){
        x.opacity = 1;
      }
    });
  }
}

