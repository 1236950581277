import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import MultiDropdown from '../../../../global/multiDropdown/multiDropdown';
import { addHoverObject, clearHover } from "../../../../../redux/reducers/hover/hoverActions";
import { changeSetting } from "../../../../../redux/reducers/customConfig/customConfigActions";
import helpers from '../../../../../helpers';
import { FaLock } from "react-icons/fa";

function EditTextEffect(props) {
  // ---- this comp is identical to editTextFont.js other than it makes images from TextEffect instead of Font. ----//

  let locationContent = _.find(props.customConfig.locations, { code: props.navigation.activeLocation });

  let bpLocation = _.find(props.baseProduct.locations, { code: locationContent.code });
  let bpAllowedEntity = _.find(bpLocation.allowedEntities, { key: locationContent.entity.type });
  // bpDecorationMethod has all allowed fonts for this location and entity type.
  let bpDecorationMethod = _.find(bpAllowedEntity.allowedDecorationMethods, { key: locationContent.entity.decorationMethod });

  const handleOnChange = (e) => {
    let textEffect = e.key // update redux location entity textEffect
    props.changeSetting(props.customConfig.locations, props.navigation.activeLocation, { setting: "textEffect", value: textEffect });

    let url; // update redux location entity url
    let locationContentClone = _.cloneDeep(locationContent);
    locationContentClone.entity.textEffect = e.key;
    url = helpers.urlRender.returnProductRenderUrl(locationContentClone.entity);
    props.changeSetting(props.customConfig.locations, props.navigation.activeLocation, { setting: "url", value: url });
    props.clearHover();
  }
  const handleOnHover = (e) => {
    let locationContentClone = _.cloneDeep(locationContent);
    locationContentClone.entity.textEffect = e.key;

    let key = "url";
    let value = helpers.urlRender.returnProductRenderUrl(locationContentClone.entity);
    props.addHoverObject({ [key]: value });
  }
  const handleOnHoverOff = () => {
    props.clearHover();
  }

  let label = "Text Effect";
  let value = helpers.loadedDefs.getTextEffectName(locationContent.entity.textEffect);
  let options = _.map(bpDecorationMethod.allowedTextEffects, (x) => {
    return helpers.loadedDefs.getTextEffectDef(x);
  });
  let rightLabel = null;
  let menuHeader = null;
  let isSublimation = props.baseProduct.configurationType;
  if (locationContent.entity.type === "player-number" && isSublimation === "custom-sub") {
    var index = options.map(function (text) {
      return text.key;
    }).indexOf("straight");
    options.splice(index, options.length);
  }

  if (options.length > 1) {
    return (
      <div className="EditTextEffect" style={{ marginTop: "15px", position: "relative" }}>
        <div className="row pl-4">
          <div className="col-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 label-padding-top">
            <label className="decoration-label">{label.toUpperCase()}</label>
          </div>
          <div className="col-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12">
            <MultiDropdown
              customClass="multidropdown-menu"
              // label={ label }
              rightLabel={rightLabel}
              menuHeader={menuHeader}
              value={value}
              options={options}
              onChange={handleOnChange}
              onHover={handleOnHover}
              onHoverOff={handleOnHoverOff}
              imageKey={"previewUrl"}
            />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="EditTextEffect" style={{ marginTop: "15px", position: "relative" }}>
        <div className="row pl-4">
          <div className="col-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 label-padding-top">
            <label className="decoration-label">{label.toUpperCase()}</label>
          </div>
          <div className="col-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12">
            <MultiDropdown
              customClass="multidropdown-menu"
              // label={ label }
              value={value}
            />
            <div className="input-group">
              <input value={value} className="text-height-number-input form-control border-right-0" disabled />
              <div className="input-group-append ">
                <span className="input-group-text border-left-0 append-border"><FaLock /></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const reduxActions = {
  changeSetting,
  addHoverObject,
  clearHover
}

function mapStateToProps(state) {
  return {
    navigation: state.navigation,
    baseProduct: state.baseProduct,
    customConfig: state.customConfig
  }
}

export default connect(mapStateToProps, reduxActions)(EditTextEffect);