import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { setActiveTab } from '../../../redux/reducers/navigation/navigationActions';
import { leadTimeModal } from '../../../redux/reducers/modal/modalActions';

class LeadTime extends Component {

  constructor(props) {
    super(props);

    this.leadTimeMouseHover = this.leadTimeMouseHover.bind(this);
  }

  leadTimeMouseHover() {
    this.setState(this.toggleLeadTimeState);
  }

  toggleLeadTimeState(state) {
    this.props.leadTimeModal(this.state);
  }

  getLeadTime() {
    let baseProductType = this.props.baseProduct.configurationType;
    let leadTime;
    let type;
    if (baseProductType === "stock-dec") {
      type = "Screen Print Logos";
    } else if (baseProductType === "custom-sub") {
      let productName = this.props.baseProduct.name;
      if (productName === "5-D VNECK TECH TEE") {
        type = "5 Day Tech Tees";
      } else {
        type = "Chromagear Sublimation"
      }
    }
    let leadTimeObj = this.props.baseProduct.leadTime;
    leadTime = leadTimeObj.filter((val) => { return val.decorationMethod === type })
    return leadTime[0].currentLeadTime;
  }
  render() {
    let leadTimeVal = this.getLeadTime();
    let locationContent = _.find(this.props.customConfig.locations, { code: this.props.navigation.activeLocation });
    let bpLocation = _.find(this.props.baseProduct.locations, { code: locationContent ? locationContent.code : null });
    let bpAllowedEntity = _.find(bpLocation ? bpLocation.allowedEntities : null, { key: locationContent ? locationContent.entity.type : null });

    let entity = bpAllowedEntity ? bpAllowedEntity.key : null;
    let confType = this.props.baseProduct ? this.props.baseProduct.configurationType : null;
    //let location = _.find(this.props.customConfig.locations, { code: this.props.navigation.activeLocation });

    //console.log("Entity:", entity, " ConfType:", confType, " locationContent:", locationContent, "bpLocation:", bpLocation);
    if ((entity && entity !== 'custom-text') && confType === 'stock-dec') {
      if (locationContent) {
        let decorationLeadTime = '';
        if (locationContent.entity.decorationMethod === 'screenprint-duratek')
          decorationLeadTime = 'Screen Print Logos, DuraTEK Player Names and/or Numbers';
        else if (locationContent.entity.decorationMethod !== 'screenprint-duratek')
          decorationLeadTime = 'Screen Print Logos, Screen Print Player Names and/or Numbers';

        let leadTimeObj = this.props.baseProduct.leadTime;
        let leadTime = leadTimeObj.filter((val) => { return val.decorationMethod === decorationLeadTime })

        if (leadTime.length > 0)
          leadTimeVal = leadTime[0].currentLeadTime;
      }
    }

    let qty = 0;
    if (this.props.customConfig && this.props.customConfig.rosters[0]) {
      qty = this.props.customConfig.rosters[0].items.length;
    }

    let className = 'lead-time-text';

    if (qty > 199) {
      leadTimeVal = 'TBD';
      className = 'lead-time-text-1'
    }
    // console.log(leadTimeVal);
    return (
      <div className="d-flex justify-content-center align-items-center pl-0 pr-0 col-12">
        <div className={className}>
          LEAD TIME
          </div>
        {
          (leadTimeVal == 'TBD') ? (
            <div>
              <div className="lead-time-tbd" onMouseEnter={this.leadTimeMouseHover}>{leadTimeVal}</div>
            </div>
          ) : (
              <div className="d-flex lead-time-head">
                <div className="lead-time">{leadTimeVal}</div>
                <div style={{ paddingTop: '3px' }}>
                  <div className="lead-time-subtext">BUSINESS</div>
                  <div className="lead-time-subtext">DAYS</div>
                </div>
              </div>
            )
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reduxState: state,
    user: state.user,
    customConfig: state.customConfig,
    baseProduct: state.baseProduct,
    toolTips: state.toolTips,
    navigation: state.navigation
  }
}

const reduxActions = {
  setActiveTab,
  leadTimeModal
}

export default connect(mapStateToProps, reduxActions)(LeadTime);