import defaultProduct from './defaults/defaultBaseProduct'; 


class BaseProductValidator {

  validate(val) {
    if (!val) {
      val = defaultProduct; 
    }
    if (!val.views) {
      val.views = defaultProduct.views
    }

    if (!val.brand) {
      val.brand = { name: 'undefined' }
    }
    
    return val;
  }
}

// export a static singleton instance
const baseProductValidator = new BaseProductValidator();



export default baseProductValidator;


