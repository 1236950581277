import React, { Component } from "react";
import { connect } from 'react-redux';
import { initLaunchContext } from "../../../../../src/redux/reducers/launchContext/launchContextActions.js";
import { closeModal } from "../../../../redux/reducers/modal/modalActions";

class ChangeDesignModal extends Component {


  render() {
    return (
      <div className="info-modal-container">
        <div className="row">
          <div className="col-12 pt-3">
            <div className="info-1">
              <p>Are you sure you want to change the design? Changing designs will remove any decorations (text or graphics) you might have added to your garment.</p>
            </div>
          </div>
          <div className="col-12 pt-3">
            <div className="row">
              <div className="col-6">
                <button type="button" className="btn btn-block btn-outline-dark btn-outline" onClick={async (e) => {
                  if (this.props.changeDesignColor.isChanged) {
                    this.props.design === this.props.baseProduct.designNum ? this.props.changeDesignColor.isDesignLoading = false : this.props.changeDesignColor.isDesignLoading = true;
                    this.props.launchContext.configId = "";
                    this.props.launchContext.design = this.props.design;
                    this.props.launchContext.designNum = this.props.design;
                    // this.props.launchContext.colors = selectedColors;
                    this.props.launchContext.layers = this.props.tempLayers;
                    this.props.launchContext.style = this.props.baseProduct.styleNum;
                    this.props.launchContext.styleNum = this.props.baseProduct.styleNum;
                    this.props.launchContext.isMasterConfig = this.props.isMaster;
                    this.props.initLaunchContext(this.props.launchContext);
                  } else {
                    if (this.props.configId !== "") {
                      this.props.design === this.props.baseProduct.designNum ? this.props.changeDesignColor.isDesignLoading = false : this.props.changeDesignColor.isDesignLoading = true;
                      this.props.launchContext.configId = this.props.configId;
                      this.props.launchContext.design = undefined;
                      this.props.launchContext.designNum = undefined;
                      this.props.launchContext.style = undefined;
                      this.props.launchContext.styleNum = undefined;
                      this.props.launchContext.colors = [];
                      this.props.launchContext.layers = undefined;
                      this.props.launchContext.isMasterConfig = this.props.isMaster;
                      this.props.initLaunchContext(this.props.launchContext);
                    } else {
                      if (this.props.design && this.props.configId === "") {
                        this.props.design === this.props.baseProduct.designNum ? this.props.changeDesignColor.isDesignLoading = false : this.props.changeDesignColor.isDesignLoading = true;
                        this.props.launchContext.configId = "";
                        this.props.launchContext.design = this.props.design;
                        this.props.launchContext.designNum = this.props.design;
                        // this.props.launchContext.colors = selectedColors;
                        this.props.launchContext.layers = this.props.tempLayers;
                        this.props.launchContext.style = this.props.baseProduct.styleNum;
                        this.props.launchContext.styleNum = this.props.baseProduct.styleNum;
                        this.props.launchContext.isMasterConfig = this.props.isMaster;
                        this.props.initLaunchContext(this.props.launchContext);
                      }
                    }
                  }
                  this.props.closeModal();
                }}>CHANGE DESIGN</button>
              </div>
              <div className="col-6 ">
                <div className="buttons-container">
                  {/* <button className="btn btn-outline-dark btn-block btn-outline" onClick={() => this.close()}><strong>CONTINUE TO DESIGN </strong></button> */}
                  <button type="button" className="btn btn-outline-dark btn-block btn-outline-red" onClick={() => { this.props.closeModal(); }}>CANCEL</button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
}
const mapDispatchToProps = {
  initLaunchContext,
  closeModal,
};

function mapStateToProps(state) {
  return {
    reduxState: state,
    baseProduct: state.baseProduct,
    customConfig: state.customConfig,
    launchContext: state.launchContext,
    changeDesignColor: state.changeDesignColor
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ChangeDesignModal);


