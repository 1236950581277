import React, { Component } from 'react';
import ColorSwatchGroup from '../../../../global/colorSwatch/colorSwatchGroup';
import NewColorSwatchGroup from '../../../../global/colorSwatch/NewColorSwatchGroup';
import helpers from '../../../../../helpers';
import _ from 'lodash';
import { connect } from 'react-redux';
import {changeProductColor} from '../../../../../redux/reducers/customConfig/customConfigActions';
import {productColorHover} from '../../../../../redux/reducers/hover/hoverActions';

class ProductColor extends Component {
  constructor(props){
    super(props);
    this.state = {
      hoverColor:""
    }

    this.getColorOptions = this.getColorOptions.bind(this);
    this.onColorChanged = this.onColorChanged.bind(this);
    //this.onColorHovered = this.onColorHovered.bind(this);
  }

  getColorOptions() {
    return this.props.baseProductColorOptions.map(base => {
      // TODO: $DATA_CLEANUP$  why are we treating white so special here?  use the data.
      // use the color helper to find the correct color def form the color code.
      // fallback to the default color defined by the baseProduct if we can't find a matchin color
      let temp = this.props.colorDefs.find( def => def.code === base) || this.props.colorDefs.find( def => def.code === "WH");
      return temp; 
    });
  }

  getSelectedColorDef() {
    return helpers.colors.getSelectedProductColorDef(true);
  }

  onColorHovered(hoverColorDef) {
    if (this.state.hoverColor !== hoverColorDef) {
      this.setState({ hoverColor: hoverColorDef });
      this.props.productColorHover(hoverColorDef);
    }
  }

  onColorChanged(color) {
    // set and move to redux
    this.props.changeProductColor(this.props.customConfig, color);
  }

  
  getHoverColorName() {
    return _.get(this.state, 'hoverColor.name', null);
  }

  render(){
    let colorOptions = this.getColorOptions();
    let selectedColorDef = this.getSelectedColorDef();
    let selectedColorName = _.get(selectedColorDef, 'name', null);
    let hoverColorName = this.getHoverColorName();
    return(
      <div className="ProductColor">
        <NewColorSwatchGroup
            value={selectedColorDef}
            colorOptions={colorOptions}
            label=""
            rightLabel=""
            hint={hoverColorName}
            displayHint={true}
            onChange={this.onColorChanged}
            //onHover={this.onColorHovered}
            watchProps={true}
          />
      </div>
    );
  }
}

const reduxActions = {
  productColorHover,
  changeProductColor
}

function mapStateToProps (state){
  return {
    baseProductColorOptions: state.baseProduct.colors || [],
    colorDefs: state.loadedDefs.colors,
    customConfig: state.customConfig,
  }
}

export default connect(mapStateToProps, reduxActions)(ProductColor);
