import moment from 'moment';


class DisplayHelper {


  // date & time helpers

  formatCurrentDateTime(formatStr = null) {
    return this.formatDateTime(moment(), formatStr);
  }

  formatCurrentDate() {
    return this.formatDate(moment());
  }

  formatCurrentTime() {
    return this.formatTime(moment());
  }

  formatDateTime(val, formatStr = null) {
    let str = '';
    if (val !== null && val !== undefined) {
      if (formatStr === null) { formatStr = 'MMM D, YYYY h:mma'; }
      str = moment(val).format(formatStr);
    }
    return str;
  }

  formatDate(val) {
    return this.formatDateTime(val, 'M/D/YYYY');
  }

  formatTime(val) {
    return this.formatDateTime(val, 'h:mma');
  }

}

// export a static singleton instance
const displayHelper = new DisplayHelper();
export default displayHelper;


