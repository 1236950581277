import axios from 'axios';
import AppConfig from '../../config';

// TODO: joe- we need to update this service to extend the RestService and use the same URL endpoint.
// main API needs to be updated to support these service endpoints first.

/**
 * Service Endpoint Implementation
 */
export default class TextDesignPatternService {

  constructor() {
    this.serviceName='textDesignPatterns';
    this.routePath = AppConfig.baseApiUrl;
  }

   getTextDesignPatterns(){
   return axios.get(this.routePath + "/builder/text-pattern", null);
  }

}
