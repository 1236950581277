import React from "react";
import PropTypes from 'prop-types';
import BaseInputComponent from "../baseInputComponent/baseInputComponent";
import ColorSwatchStitch from "./colorSwatchStitch"
import CheckmarkSVG from '../../../assets/icons/CheckmarkSVG';
import { Tooltip } from "reactstrap";
import _ from 'lodash';



export default class ColorSwatchStitchGroup extends BaseInputComponent {

  // NOTE: if you extend propTypes in child component class, you will get a react warning.
  static get propTypes() {
    return {
      // eslint-disable-next-line
      ...super.propTypes,
      colorOptions: PropTypes.array
    }
  };

  static get defaultProps() {
    return {
      ...super.defaultProps,
      colorOptions: [],
    }
  };

  constructor(props) {

    super(props);

    this.onSwatchSelected = this.onSwatchSelected.bind(this);
    this.onSwatchHovered = this.onSwatchHovered.bind(this);
    this.onSwatchHoveredDebounced = _.debounce(this.onSwatchHovered.bind(this), 50);

    this.state = {
      ...this.state,
      selectedColor: ''
    };
  }

  get selectedColorCode() {
    return _.get(this.state.value, 'code', null);
  }

  onSwatchSelected(color) {
    this.setValue(color, true);
  }

  onSwatchHovered(color) {
    this.state.selectedColor = color
    // console.log(this.props)
    this.setHover(color, true);
  }

  //todo remove onswatch leave, may be deprecated - logan
  onSwatchLeave() {
    this.setHover(null, true);
  }

  renderSelectedCheckmark() {
    return (
      <div className="selected-checkmark">
        <CheckmarkSVG
          height="20"
          width="20"
          stroke={"black"}
          strokeWidth="4"
          fill={"black"}
          className={"checkmark-svg"}
        />
      </div>
    );
  }

  backgroundfill(){
    let style = {};
    return style
  }

  renderChildComponent() {
    let colorOptions = this.props.colorOptions || [];
    let selectedColorDef = this.state.value;
    const divStyle = this.props.value.hex !== "#ffffff" ? {
      'borderTopColor':  this.props.value.hex 
    } : {
      'borderTopColor': '#f8f8ff',
    }

    return (
      <div className="container-fluid">
      <div className="row">
        <div className="selected-color col-12">
          {this.props.selectedColor}
        </div>

      </div>

      <div className="row">
        <div className="stitching-color-border mt-3" style={divStyle}>
          <div className="color-swatch-stitch-group mb-3">
            {
              colorOptions.map(color => {
                if (color) {
                  let elemId = (this.props.type || 'csgi') + '-color-' + _.get(color, 'key', 'none') + '-' + (this.props.dropdownName || 'dd');
                  let isSelected = (color === selectedColorDef);
                  let isDisabled = this.props.isDisabled;
                  let colorName = _.get(color, 'name', 'None');
                  let classes = !isDisabled ? 'clickable' : '';
                  return (
                    <div className="col-6 mt-1" id={elemId} key={elemId}>
                      <div className="row">
                        <div className="col-4 mr-0 pl-0"
                          onClick={() => this.onSwatchSelected(color)}
                          onMouseEnter={() => this.onSwatchHoveredDebounced(color)}
                          onMouseLeave={() => this.onSwatchHoveredDebounced(null)}
                        >
                          <ColorSwatchStitch
                            value={color}
                            isSelected={isSelected}
                            isDisabled={isDisabled}
                          />
                        </div>
                        <div className="col-6 mt-2">
                          {isSelected ? <div className="stitch-color-name-bold">
                            {colorName}
                          </div> : <div className="stitch-color-name">
                            {colorName}
                          </div> }
                        </div>
                        <div className="col-2 mt-2">
                        { isSelected ? this.renderSelectedCheckmark() : null}
                        </div>
                      </div>
                    </div>
                  );
                } else { return null }
              })
            }
          </div>
        </div>
      </div>
      </div>

    );
  }
}

