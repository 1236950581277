import React, { Component } from 'react'
import { Card, Col } from 'react-bootstrap'
import Badge from 'react-bootstrap/Badge'
//import { DesignTrashSVG } from "../../../../assets/icons/trashSVG"; 

export default class DesignTableItem extends Component {

  constructor(props){
    super(props); 
    this.state = {
      removeFill: "black"
    }

    this.editItem = this.editItem.bind(this); 
    this.shareItem = this.shareItem.bind(this); 
  }

  mouseOnRemove(){
    this.setState({removeFill: "red"}); 
  }  
  
  mouseOffRemove(){
    this.setState({removeFill: "black"}); 
  }

  editItem(){
    this.props.edit(this.props.configId); 
  }
  shareItem(){
    this.props.openModal(this.props.configId, this.props.imageUrl);
  }
  removeItem(){
    this.props.remove(this.props.refId);
  }

  render() {
    let { imageUrl, name, configId, createdAt, updatedAt, previews, status } = this.props
    name = name !== "" ? name : "MY SAVED DESIGN";
    status = (status && status !== "") ? status : "saved";
    return (
        // <div className="row design-table-item d-flex align-items-center">
        <Col className="col-xs-12 col-sm-12 col-md-2 design--table_card">
          <Card body className="border-0">
            <Card.Img className="img-lgt-gray" variant="top" src={imageUrl} /> 
            <Card.Body className="card-body-border">
              <Card.Title className="text-left">
                <div className="row">
                  <span className="col col-8 design--table_id">
                    {configId}
                  </span>
                  <Badge className="col col-4 design--table_badge bg-secondary" bg="secondary">{status.toUpperCase()}</Badge>
                </div>
              </Card.Title>
              <Card.Text className="text-left">
                {name !== "" && <span className="h6 design--item_name" onClick={this.editName}>{name}</span>}
                <br />
                {createdAt !== "" && <span className="design--table_dates"><small>Created on {createdAt}</small></span>}
                <br />
                {updatedAt !== "" && <span className="design--table_dates"><small>Last Edited on {updatedAt}</small></span>}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        // </div>
    )
  }
}
