
import React, { Component } from 'react';
import { connect } from 'react-redux'
import Dropzone from 'react-dropzone';
import _ from 'lodash';
import UploadCloudSVG from '../../../assets/icons/UploadCloudSVG';
import PublishSVG from '../../../assets/icons/PublishSVG';
import { showToast } from '../../../redux/reducers/toast/toastActions';
import { setAdditionalAssets } from "../../../redux/reducers/customConfig/customConfigActions";
import { addUploadedArt } from "../../../redux/reducers/user/userActions";
import { imageUploadModal } from '../../../redux/reducers/modal/modalActions';
import { formatRoster } from '../../../redux/reducers/customConfig/customConfigActions';
import helpers from '../../../helpers/';
import api from '../../../api';
import Papa from 'papaparse';
import CloudUploadIconSVG from '../../../assets/icons/CloudUploadIconSVG';
import {
  setPendingLocation,
  setActiveTab,
  previewSample,
  setCustomTextInput,
  setView,
} from "../../../redux/reducers/navigation/navigationActions";
import {
  addPendingLocationToLocations,
  changeSetting,
} from "../../../redux/reducers/customConfig/customConfigActions";
import { SetDecorationSubtab } from "../../../redux/reducers/decoration/decorationAction";

const PlaceholderImage = require("../../../assets/images/artwork-placeholder.png");
class FileUploader extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isMouseOver: false,
      name: "",
      totalColors: "1",
      imagePreviewUrl: "",
    };

    this.accept = this.accept.bind(this);
  }

  handleMouseEnter = () => {
    this.setState({ isMouseOver: true })
  }

  handleMouseLeave = () => {
    this.setState({ isMouseOver: false })
  }

  getImage = (e) => {
    e.preventDefault();
    this.props.upload(e)
    e.target.value = null;
  }

  uploadAdditionalFiles = () => {
    if (this.state.convertedFile) {
      const promisesArr = [api.webUserArtAssets.uploadImage(this.state.originalFile, 0)];
      return Promise.all(promisesArr);
    } else {
      return Promise.resolve([]);
    }
  }

  dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  async handleImageUpload(e) {
    let tmp = _.first(e);
    let convertedFileName = '';
    let bas64Img = '';
    let convertedFile = '';
    let selectedFile = '';
    if (!_.isEmpty(tmp)) {
      //Uploaded File Conversion.
      let reader = new FileReader();
      selectedFile = tmp;
      // const uploadedFileMimeType = selectedFile.type;
      const placeholderTypes = ['psd', 'xls', 'xlsx', 'txt', 'doc', 'cad'];
      const fileTypesForConversion = ['ai', 'pdf', 'eps',];
      let filetype = selectedFile.path.split(".");
      filetype = filetype[filetype.length - 1];
      let accepted = true;
      if (placeholderTypes.includes(filetype)) {
        accepted = false;
      }
      if (fileTypesForConversion.includes(filetype)) {
        convertedFile = await api.webUserArtAssets.convertFile(selectedFile);
        convertedFileName = `${filetype[0]}.png`;
        bas64Img = `data:image/png;base64,${Buffer.from(convertedFile.data, 'binary').toString('base64')}`;
        selectedFile = this.dataURLtoFile(bas64Img, convertedFileName);
      } else {
        reader.onloadend = () => {
          let result = reader.result;
          if (accepted === false) {
            result = PlaceholderImage;
          }
        };
        reader.readAsDataURL(selectedFile);
      }

      //API call to upload the image
      let file = selectedFile;
      let numDecorationColors = null;
      let uploadRes = await api.webUserArtAssets.uploadImage(file, numDecorationColors, tmp.name);
      if (uploadRes) {
        let type = uploadRes.url.split(".");
        type = type[type.length - 1];
        let accepted = true;
        let placeholderTypes = ['ai', 'psd', 'eps', 'xls', 'xlsx', 'txt', 'doc', 'pdf', 'cad'];
        if (placeholderTypes.includes(type)) {
          accepted = false;
        }
        if (!accepted) {
          uploadRes.url = PlaceholderImage;
        }
        const additionalAssets = await this.uploadAdditionalFiles();
        this.props.setAdditionalAssets(additionalAssets);
        this.props.addUploadedArt(this.props.user, uploadRes);
      }
      // if (!_.isEmpty(tmp)) {
      //   this.props.imageUploadModal(tmp);
      // }
    }
    //directly add uploaded image 
    let type = "custom-art";
    let view = _.get(this.props, "navigation.activeView");
    let code = _.get(this.props, "navigation.activeLocation");
    // let artAsset = this.props.item;
    let artAsset = this.props.user.userArtAssets[0];
    let pendingLocation = helpers.navigation.makePendingLocation(
      type,
      "",
      view,
      code,
      artAsset
    );
    this.props.setPendingLocation(pendingLocation);
    if (!this.props.artOptions) {
      let groupName = this.props.baseProduct.locations.filter(
        (v) => v.code === pendingLocation.code
      );
      let locationCodeA = pendingLocation.code;
      this.props.previewSample(
        this.props.baseProduct,
        locationCodeA,
        null,
        pendingLocation
      );

      let isLocationFilled = _.find(this.props.customConfig.locations, {
        code: pendingLocation.code,
      });
      if (isLocationFilled) {
        // let sourceLocation = pendingLocation;
        // let targetLocation = isLocationFilled;
        // this.props.locationUsed(sourceLocation, targetLocation);
      } else {
        let tabObj = {
          tab: this.props.navigation.activeTab.tab,
          subTab: "add-art",
          page: "sub-edit",
        };
        this.props.setActiveTab(tabObj);
        let subTab = "GRAPHIC";
        this.props.SetDecorationSubtab(subTab);
        this.props.addPendingLocationToLocations(
          this.props.customConfig.locations,
          pendingLocation,
          groupName
        );
      }
      this.props.setCustomTextInput("");
      this.props.setPendingLocation(null);
      this.props.setView(pendingLocation.view);
    }
  }

  handleRosterUpload(e) {
    const file = _.get(e, "[0]");
    const acceptableSize = helpers.roster.validateSize(file);
    let allowedProductSizes = helpers.product.getAllowedProductSizes();
    //if file is acceptable size try formatting, dispatch action or error message according to parse results
    //else if file size isnt acceptable dispatch error message
    Papa.parse(file, {
      delimiter: ",",
      complete: async (results) => {
        const isACsv = _.get(file, "path", "").includes("csv");
        if (!isACsv) {
          results = await api.rosterUploader.translateExcelToCsv(file);
          for (var i in results[0]) {
            if (i === "Quantity") {
              helpers.roster.parseQuantityRoster(results, allowedProductSizes, this.getData);
            } else if (i === "number") {
              helpers.roster.parseRoster(results, allowedProductSizes, this.getData);
            }
          }
        } else {
          if (e && e.length > 0 && acceptableSize) {
            if (_.isEmpty(allowedProductSizes) || _.isEmpty(e)) {
              return false
            };
            _.each(results.data[0], header => {
              if (header === "Quantity") {
                helpers.roster.parseQuantityRoster(results, allowedProductSizes, this.getData);
              } else if (header === "Number") {
                helpers.roster.parseRoster(results, allowedProductSizes, this.getData);
              }
            })
          } else if (!acceptableSize) {
            this.props.showToast("Try a smaller size.");
          }
        }
      }
    }
    )
  }

  getData = (values) => {
    if (values && !_.isEmpty(values)) {
      this.props.formatRoster(values);
    } else {
      this.props.showToast("Formatting issue. Use our roster template.");
    }
  }

  accept(file) {
    const size = _.get(file, "[0].size");
    let path = _.get(file, "[0].path");
    path = path.split(".");
    path = path[1];
    path = path.toLowerCase();

    if (_.get(this.props, "allowedFiles", [""]).includes(path) && size !== 0) {
      switch (this.props.fileUpload) {
        case "roster": this.handleRosterUpload(file); break;
        case "image": this.handleImageUpload(file); break;
        default: this.props.showToast("FileUpload not set in properties", 5000); break;
      }
    } else {
      this.props.showToast("Invalid File Type - Try Again", 5000);
    }
  }

  render() {
    let size = { height: "170px", width: "100%" };
    let margin;
    if (this.props.useTiny) {
      size = { height: "35px", width: `${this.props.rosterSize ? this.props.rosterSize : "180px"}`, display: "flex", flexDirection: "row" };
      margin = "0px";
    }
    // let backgroundColor = this.state.isMouseOver? "#BFBCBC": "transparent";
    let backgroundColor = this.props.navigation.activeTab.tab === 'names-and-numbers' ?
      (this.state.isMouseOver ? "#BFBCBC" : "transparent")
      : "lightgrey";
    let nodragProp = this.props.navigation.activeTab.tab === 'names-and-numbers' ? 'false' : 'true';
    return (
      <Dropzone noDrag={nodragProp} onDrop={acceptedFiles => this.accept(acceptedFiles)} className="FileUploader">
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <div aria-label={this.props.toolTip} data-balloon-pos="down" className="FileUploader" >
                <div className="content-container btn clickable"
                  style={{ backgroundColor, position: "relative", ...size }}
                  onMouseEnter={this.handleMouseEnter}
                  onMouseLeave={this.handleMouseLeave}
                  onDragEnter={this.handleMouseEnter}
                  onDragLeave={this.handleMouseLeave}
                >
                  {!this.props.useTiny ?
                    (this.props.navigation.activeTab.tab === 'names-and-numbers' ?
                      <PublishSVG /> :
                      <div><div>{this.props.message}</div> <CloudUploadIconSVG width="60" height="45" fill="#898989" /> </div>)
                    // <UploadCloudSVG width="60" height="45" fill="#898989"/> 
                    : null}

                  {this.props.useTiny ?
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                      {this.props.navigation.activeTab.tab === 'names-and-numbers' ?
                        <UploadCloudSVG width="30" height="30" fill="#898989" /> :
                        <CloudUploadIconSVG width="30" height="30" fill="#898989" />}

                      <div style={{ fontSize: 12, marginLeft: "5px", fontWeight: "bold" }} >
                        {this.props.tinyMessage}
                      </div>
                    </div>
                    :
                    (this.props.navigation.activeTab.tab === 'names-and-numbers' ?
                      <div>
                        <div>{this.props.message}</div>
                        <div >-OR-</div>
                        <div >Click to Browse</div>
                      </div>
                      :
                      <div className="artUpload">
                        <div>File Types: JPG, PNG, SVG,</div>
                        <div>EPS, AI, PDF</div>
                      </div>)
                  }
                </div>
              </div>
              <input {...getInputProps()} />
            </div>
          </section>
        )}
      </Dropzone>
    );
  }
}

const reduxActions = {
  imageUploadModal,
  showToast,
  formatRoster,
  setAdditionalAssets,
  addUploadedArt,
  setPendingLocation,
  setActiveTab,
  previewSample,
  setCustomTextInput,
  setView,
  addPendingLocationToLocations,
  changeSetting,
  SetDecorationSubtab,
}

function mapStateToProps(state) {
  return {
    toast: state.toast,
    navigation: state.navigation,
    user: state.user,
    decoration: state.decoration,
    baseProduct: state.baseProduct,
    customConfig: state.customConfig
  }
}

export default connect(mapStateToProps, reduxActions)(FileUploader);