import React, { Component } from "react";
import { connect } from "react-redux";
import TabHeader from "../../../global/TabHeader/TabHeader";

class StyleDetails extends Component {

    render() {
        var text = this.props.baseProduct.configurationType === 'custom-sub' ? this.props.baseProduct.legacyStyleNum : this.props.baseProduct.styleNum;
        let productData = this.props.baseProduct.productMetaData.sort((a,b)=>(a.Ordinal>b.Ordinal)?1:-1).map(i=><li key = {i.Ordinal} >{i.ConstructionBullet}</li>);
        if (this.props.baseProduct.configurationType === 'custom-sub' && text.length > 6) {
            text = text.slice(0, -1);
        }
        return (
            <div className="AddArtDrawer">
                <div className="option-body">
                    <div className="row">
                        <div className="col-12">
                            <TabHeader text={text} name={this.props.baseProduct.name}></TabHeader>
                        </div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-12">
                         {productData.length>0?
                          <ul className="ul-list">
                              {productData}
                          </ul>
                         :
                          <ul className="ul-list">
                                <li>100% Polyester moisture management/antimicrobial performance fabric </li>
                                <li>Sublimated front and back body</li>
                                <li>Badger Sport paneled shoulder for maximum movement </li>
                                <li>Self-fabric collar</li>
                                <li>Double-needle hem</li>
                                <li>Badger heat seal logo on left sleeve</li>
                            </ul>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapDispatchToProps = {
};

function mapStateToProps(state) {
    return {
        toolTips: state.toolTips,
        baseProduct: state.baseProduct,
        customConfig: state.customConfig,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StyleDetails)