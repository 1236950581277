import React, { Component } from 'react';
import _ from "lodash";
import { connect } from "react-redux";
import { closeModal } from "../../../../redux/reducers/modal/modalActions";
import { toggleBasicMode } from "../../../../redux/reducers/customConfig/customConfigActions";
import PreviewCanvasTwo from "../../../previewCanvasTwo/PreviewCanvasTwo";


 class ReplaceArtwork extends Component {

  handleClose() {
    this.props.closeModal();
  }

   renderMobileCancel(){
     return (<div className="original-cancel-mobile" onClick={() => this.handleClose() } style={{width:"152px", cursor:'pointer'}}>
        <div className="ra-or-text">-or-</div>
        <div className="ra-prompt ra-cancel" style={{margin:"10px 0px"}} >No</div>
        <div className="ra-message" style={{ fontSize: "16px", padding: "0px 22px" }}>Stay On Primary Colors</div>
     </div>);
   }

   handleToggle() {
      //reset to the primary colors when switching from advanced mode to basic mode
      let {customConfig, baseProduct} = this.props;
        this.props.defaultLayerColors(customConfig);
     this.props.toggleBasicMode(customConfig, baseProduct);
     this.props.closeModal();
   }

  render() {
    let cancelToolTip = _.find(this.props.toolTips, { key: "cancel-replace" }).message;
    let replaceToolTip = _.find(this.props.toolTips, { key: "replace" }).message;
    return (
      <div className="simple-modal" style={{display:"flex" , flexDirection:"column", textAlign:"center" ,justifyContent:"center"}}>
        <div className="replace-artwork-header">When switching to Primary colors, you may lose some of your Advanced color choices.</div>
          <div className="choices d-flex " style={{justifyContent:"space-around"}}>
            {/* original */}
            <div className="original-cancel-full">
            <div className="original d-flex flex-column" onClick={() => this.handleClose()} style={{width:"152px", cursor:'pointer'}}>
              <div style={{ border:"solid 1px darkgrey"}}>
                <div className="original-a" style={{width:"150px", height:"200px", backgroundColor:"lightgrey", borderBottom:"solid 1px darkgrey"}}>
                   <PreviewCanvasTwo isBasic={false} view={ {view: this.props.navigation.activeView}  } isThumbnail={true} sample={"advanced-switch"} borders={false}/>
                </div>
                <div aria-label={cancelToolTip} data-balloon-pos="left" className="ra-prompt ra-no" style={{margin:"10px 0px"}}>No</div>
              </div>
              <div className="ra-message" style={{ fontSize: "16px", padding: "0px 22px" }}>Stay On Advanced Colors</div>
            </div>
            </div>
            {/* replace */}
            <div className="replace d-flex flex-column" onClick={() => this.handleToggle()} style={{width:"152px", cursor:'pointer'}}>
              <div style={{ border:"solid 1px darkgrey"}}>
                <div className="replace-a" style={{width:"150px", height:"200px", backgroundColor:"lightgrey", borderBottom:"solid 1px darkgrey"}}>
                <PreviewCanvasTwo isBasic={true} view={ {view: this.props.navigation.activeView}  } isThumbnail={true} sample={"advanced-switch-a"} borders={false}/>
                </div>
                <div aria-label={replaceToolTip} data-balloon-pos="right" className="ra-prompt ra-no" style={{padding:"10px 0px", backgroundColor:'black', color:"white"}}>Yes</div>
              </div>
              <div className="ra-message" style={{ fontSize: "16px", padding: "0px 22px" }}>Switch To Primary Colors</div>
            </div>
          </div>
        { this.renderMobileCancel()}
      </div>
    )
  }
}
function mapStateToProps(state){
  return {
    modal: state.modal,
    toolTips: state.toolTips,
    navigation: state.navigation
  }
}
let reduxFunctions = {
  closeModal,
  toggleBasicMode
}
export default connect(mapStateToProps, reduxFunctions)(ReplaceArtwork);
