import React, {Component} from "react";
import _ from "lodash";
import OptionHeading from "../../../global/optionHeading/OptionHeading";
import helpers from "../../../../helpers";
import ProductColor from "./productColor/ProductColor";
import StitchingColor from "./stitchingColor/StitchingColor"
import { connect } from 'react-redux';
import appConstants from "../../../../constants/appConstants";
import ChromaDrawer from "./ChromaDrawer/ChromaDrawer";
import LogoBadgerIconSVG from '../../../../assets/icons/BadgerIconSVG';

class ColorsTab extends Component {
  brandLogo = require("../../../global/ultimateModal/sublimatedProductDetailsModal/CG-Logo_Horizontal_NoBG.png");

  getStitchingOptions() {
    let retval = null;
    let options = helpers.product.getUpgrade('stitching');
    let allowed = !!(options && _.get(options, 'enabled', true));
    if (allowed) {
      let customOptions = helpers.locations.getUpgradeOptions('stitching');
      let defaultColorCode = _.get(options, 'defaultColor', null);
      let defaultColor = helpers.colors.getColorFromColorCode(defaultColorCode);
      let selectedColorCode = _.get(customOptions, 'color', null);
      let selectedColor = helpers.colors.getColorFromColorCode(selectedColorCode) || defaultColor;
      let allowedColors = helpers.colors.getColorsFromColorCodes(options.allowedColors);
      retval = { selectedColor, defaultColor, allowedColors, options };
    }
    return retval;
  }

    headerStock(){
      return (
        <div className="card w-100 pr-0 pl-0">
          <div className="row">
            <div className="col-3 pr-0 pt-2 pb-0">
              <div className="design-heading-main">{this.props.baseProduct.styleNum}</div>
            </div>
            <div className="col-3 pl-0 stock-brand-logo">
              <LogoBadgerIconSVG></LogoBadgerIconSVG>
            </div>
          </div>
          <div className="row">
            <div className="heading-low col-12 pt-1">
                {this.props.baseProduct.name}
            </div>
          </div>
        </div>
      );
    }

    headerSub(){
      return (
        <div className="card w-100 pr-0 pl-0">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-6 pr-0 pt-2 pb-0">
              <div className="design-heading-main">{this.props.baseProduct.legacyStyleNum}</div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-6 pl-0">
              <img
                  src={this.brandLogo}
                  alt="brand logo"
                  className="design-brand-image"
              />
            </div>
          </div>
          <div className="row">
            <div className="heading-low col-12 pt-1">
                {this.props.baseProduct.name}
            </div>
          </div>
        </div>
      );
    }

  renderStockDrawer = () =>{
    let stitchingOptions = this.getStitchingOptions();
    let stitchingColors = _.get(stitchingOptions, 'allowedColors', null);
    return (
      <div className="fade-in">
        <div>
            {this.props.baseProduct.configurationType === 'custom-sub' ? this.headerSub() : this.headerStock()}
        </div>
        <OptionHeading title={"Choose your colors"} description={"Choose from the available colors shown below"} />
        <div className="option-body">
          <ProductColor />
          {stitchingColors && stitchingColors.length > 0 ? <StitchingColor /> : null }
        </div>
      </div>
    );
  }

  renderChromaDrawer = () =>{
    return (
      <div className="fade-in" style={{height: "100%"}}>
        <ChromaDrawer />
      </div>
    );
  }

  render() {
    let isChromaGear = this.props.baseProduct.configurationType === appConstants.ProductConfigurationTypes.CustomSublimated;
    return(
      <div style={{height: "100%"}}>
      { isChromaGear ? this.renderChromaDrawer() : this.renderStockDrawer() }
      </div>
    );
  }
}

function mapStateToProps (state){
  return{
    baseProduct: state.baseProduct
  }
}

export default connect(mapStateToProps)(ColorsTab);
