import { chromagear, badgerBase } from "./defaults/defaultCustomConfig";
import _ from "lodash";
import AppConstants from "../constants";
import helpers from "../helpers";

class CustomConfigValidator {

    constructor(errors = []) {
        this.errors = errors;
    }

    creator(baseProduct) {
        let customConfig = {};
        switch (baseProduct.configurationType) {
            case AppConstants.ProductConfigurationTypes.CustomSublimated:
                customConfig = _.extend(customConfig, chromagear);
                break;
            case AppConstants.ProductConfigurationTypes.FullCustom:
                customConfig = _.extend(customConfig, chromagear);
                break;
            default:
                customConfig = _.extend(customConfig, badgerBase);
        }

        customConfig.fabric = this._getDefaultFabric(baseProduct);
        customConfig.color = this._getDefaultColor(baseProduct);
        customConfig.upgrades = this._getDefaultUpgrades(baseProduct);
        customConfig.styleNum = this._getStyleNum(baseProduct);
        customConfig.designNum = this._getDesignNum(baseProduct);

        if (this._isCustom(baseProduct)) {
            _.set(
                customConfig,
                "customLayers.primaryColors",
                this._getDefaultColors(baseProduct)
            );
            _.set(
                customConfig,
                "customLayers.primaryPattern",
                this._getDefaultPattern(baseProduct)
            );
        }

        return customConfig;
    }

    addLaunchContext(customConfig, launchContext, baseProduct) {
        let sizeQuantites = _.get(customConfig, "rosters[0]", null);
        if (launchContext.color || launchContext.colors) {
            this._loadColor(customConfig, launchContext, baseProduct);
        }
        this._loadFabric(customConfig, launchContext, baseProduct);

        this._addRosterData(customConfig, baseProduct, sizeQuantites);
    }

    validate(customConfig, baseProduct, loadedDefs) {
        if (this._isCustom(baseProduct)) {
            this.validateChromagear(customConfig, baseProduct, loadedDefs);
        } else {
            this.validateBadgerBaseProduct(customConfig, baseProduct, loadedDefs);
        }
        return customConfig;
    }

    validateChromagear(customConfig, baseProduct, loadedDefs) {
        this.validateChormaColors(customConfig, baseProduct, loadedDefs);
        this.validateRoster(customConfig, baseProduct);
        this.validateLocations(customConfig, baseProduct);
        this.validateFabric(customConfig, baseProduct, loadedDefs);
    }

    validateBadgerBaseProduct(customConfig, baseProduct, loadedDefs) {
        this.validateColor(customConfig, baseProduct, loadedDefs);
        this.validateFabric(customConfig, baseProduct, loadedDefs);
        this.validateLocations(customConfig, baseProduct);
        this.validateRoster(customConfig, baseProduct);
    }

    //----- defaults -------//

    _getDesignNum(baseProduct) {
        return _.get(baseProduct, "designNum", null);
    }

    _getStyleNum(baseProduct) {
        return _.get(baseProduct, "styleNum", null);
    }

    _getDefaultFabric(baseProduct) {
        return _.get(baseProduct, "defaultFabric", null);
    }

    _getDefaultColor(baseProduct) {
        return _.get(baseProduct, "defaultColor", null);
    }

    _getDefaultColors(baseProduct) {
        let baseProductColors = _.get(baseProduct, "colors") || [];
        let firstColor = _.first(baseProductColors);
        let defaultColor = _.get(baseProduct, "defaultColor", firstColor);
        let defaultColors = _.get(baseProduct, "defaultColors", null);
        if (_.isEmpty(defaultColors) && defaultColor) {
            defaultColors = [defaultColor];
        }
        return defaultColors;
    }

    _getDefaultUpgrades(baseProduct) {
        return _.get(baseProduct, "defaultUpgrades", []);
    }

    _getDefaultPattern(baseProduct) {
        let defaultPattern = {
            key: "$none",
            name: "None",
            numPatternColors: 1,
            colors: null
        };
        defaultPattern = _.get(baseProduct, "defaultPattern", defaultPattern);
        return defaultPattern;
    }

    _loadColor(customConfig, launchContext, baseProduct) {
        let defaultContext = { ...launchContext, color: customConfig.color };
        // let defaultContextLayers = { ...launchContext, layers: customConfig.customLayers.layers };
        let baseProductColors = _.get(baseProduct, "colors") || [];
        let colors = _.get(defaultContext, "colors", null);
        // let layers = _.get(defaultContextLayers, "layers", null);
        if (!_.isEmpty(colors)) {
            colors = _.chain(colors)
                .split(",")
                .map(_.trim)
                .compact()
                .value();
            if (_.isEmpty(colors)) {
                colors = null;
            }
        }
        // customConfig.customLayers.layers = layers;
        let loadedColor = defaultContext.color || _.first(colors);
        let valid = _.includes(baseProductColors, loadedColor);
        let initColor =
            loadedColor && valid
                ? loadedColor
                : _.get(baseProduct, "defaultColor", customConfig.color);
        let initColors = colors;
        if (_.isEmpty(colors)) {
            let bpDef = this._getDefaultColors(baseProduct);
            let temp = _.cloneDeep(bpDef);
            if (temp)
                temp[0] = initColor ? initColor : [];
            else
                temp = [];
            initColors = temp;
            this.errors.push("using color as first of colors")
        }
        customConfig.color = initColor;
        if (this._isCustom(baseProduct)) {
            _.set(customConfig, "customLayers.primaryColors", initColors);
        }

        if (!valid) {
            this.errors.push("init color not accepted");
        }
    }

    _loadFabric(customConfig, launchContext, baseProduct) {
        if (launchContext.fabric) {
            _.set(
                customConfig,
                "fabric",
                _.get(launchContext, "fabric", this._getDefaultFabric(baseProduct))
            );
        }
    }

    _isCustom(baseProduct) {
        let ct = _.get(baseProduct, "configurationType", "");
        return (
            ct === AppConstants.ProductConfigurationTypes.CustomSublimated ||
            ct === AppConstants.ProductConfigurationTypes.FullCustom
        );
    }

    _addRosterData(customConfig, baseProduct, sizeQuantites) {
        let skus = _.get(baseProduct, "skus", null);
        let rosterData;
        if (customConfig.rosters[0]) {
            let updatedQ = sizeQuantites.items;
            rosterData = helpers.roster.convertToRosterDataFromSizeQuantites(
                updatedQ,
                customConfig.color,
                skus
            );
        }
        else {
            rosterData = helpers.roster.convertToRosterDataFromSizeQuantites(
                sizeQuantites,
                customConfig.color,
                skus
            );
        }
        customConfig.rosters = !_.isEmpty(rosterData && rosterData.items) ? [rosterData] : [];
    }

    //// validators /////
    validateChormaColors(customConfig, baseProduct, loadedDefs) {
        let valid = true;
        let colors = _.get(customConfig, "customLayers.primaryColors", []);
        let bpDefault = this._getDefaultColors(baseProduct);
        let desiredLength = bpDefault.length;
        if (colors.length !== desiredLength) {
            let temp = Array(desiredLength);
            for (let x = 0; x < desiredLength; x++) {
                temp[x] = colors[x] || bpDefault[x];
            }
            colors = temp;
            _.set(customConfig, "customLayers.primaryColors", colors);
        }

        // load in baseproduct & loadedDefs
        let bpColors = _.get(baseProduct, "colors", []);
        let defColors = _.get(loadedDefs, "colors", []);
        _.each(colors, color => {
            valid = valid && typeof color == "string";
            valid = valid && _.includes(bpColors, color);
            valid = valid && -1 < _.findIndex(defColors, { code: color });
        });

        if (!valid) {
            _.set(
                customConfig,
                "customLayers.primaryColors",
                this._getDefaultColors(baseProduct)
            );

            this.errors.push("chromagear colors not valid");
        }

        return valid;
    }

    validateRoster(customConfig, baseProduct) {
        let sizes = _.get(baseProduct, "sizes");
        let rosters = _.get(customConfig, "rosters", null);
        let valid = true;
        if (rosters.length && rosters.length < 1) {
            _.each(rosters, roster => {
                valid = valid && !!roster.items;
                if (roster.items && roster.items.length > 0) {
                    _.each(roster.items, rosterItem => {
                        valid = valid && _.includes(sizes, rosterItem.size);
                    });
                }
            });
        }

        if (!valid) {
            this.errors.push("roster sizes not vaild");
        }

        return valid;
    }

    validateLocations(customConfig, baseProduct) {
        let valid = true;
        let bpLocations = _.get(baseProduct, "locations", []);
        let locations = _.get(customConfig, "locations", []);

        if (!_.isEmpty(locations)) {
            _.each(locations, loc => {
                let code = _.get(loc, "code", null);
                let index = _.findIndex(bpLocations, { code });
                valid = valid && index > -1;
                loc.saveEntity = true;
                loc.editEnity = true;
                this.validateEntity(loc, bpLocations[index]);
            });
        }

        if (!valid) {
            this.errors.push("locations not valid");
        }
        return valid;
    }

    validateEntity(customLocation, productLocation) {
        let valid = true;
        let entity = _.get(customLocation, "entity", {});
        let allowedTypes = _.get(productLocation, "allowedEntities", []);
        // check if type is allowed for location
        let prodTypeIndex = _.findIndex(allowedTypes, { type: entity.type });
        if (prodTypeIndex < 0) {
            this.errors.push("entityType not valid for this location: " + customLocation.code);
            valid = false;
        }
        let prodType = allowedTypes[prodTypeIndex];
        // check decoration method is allowed for type
        let ccDecMethod = _.get(entity, "decorationMethod", null);
        let allowedDecMethods = _.get(prodType, "allowedDecorationMethods", []);
        let prodDecIndex = _.findIndex(allowedDecMethods, { key: ccDecMethod });
        if (prodDecIndex < 0) {
            this.errors.push(`Decoration Method ${ccDecMethod} not allowed for this location ${customLocation.code} and type ${entity.type}`);
            valid = false;
        }
        let bpDecMethod = allowedDecMethods[prodDecIndex];

        // validate text settings
        if (prodType === 'custom-text') {
            this.validateFont(entity, bpDecMethod);
            this.validateTextEffect(entity, bpDecMethod);
        } else if (prodType === 'player-name') {
            this.validateFont(entity, bpDecMethod);
            this.validateTextEffect(entity, bpDecMethod);
        } else if (prodType === 'player-number') {
            this.validateFont(entity, bpDecMethod);
            this.validateTextEffect(entity, bpDecMethod);
        }
        return valid;
    }

    validateFont(entity, bpDecMethod) {
        let valid = true;
        let ccfont = _.get(entity, "font", null);
        let allowedFonts = _.get(bpDecMethod, "allowedFonts", []);
        if (!_.includes(allowedFonts, ccfont)) {
            this.errors.push("font not found");
            valid = false;
        }
        return valid;
    }
    validateTextEffect(entity, bpDecMethod) {
        let valid = true;
        let ccTextEffect = _.get(entity, "textEffect", null);
        let allowedTextEffects = _.get(bpDecMethod, "allowedTextEffects", []);
        if (!_.includes(allowedTextEffects, ccTextEffect)) {
            this.errors.push("text effect not found");
            valid = false;
        }

        return valid;
    }

    validateFabric(customConfig, baseProduct, loadedDefs) {
        let fabric = _.get(customConfig, "fabric", null);
        let valid = true;
        // format should be a string
        if (typeof fabric != "string") {
            valid = false;
        }
        // check if in baseproduct & loadedDefs
        let bpFabrics = _.get(baseProduct, "fabrics", []);
        let defFabrics = _.get(loadedDefs, "fabrics", []);
        valid = _.includes(bpFabrics, fabric);
        valid = !(0 > _.findIndex(defFabrics, { code: fabric }));
        if (valid) {
            customConfig.fabric = fabric;
        } else {
            customConfig.fabric = this._getDefaultFabric(baseProduct);
            this.errors.push("fabric not vaild");
        }

        return valid;
    }

    validateColor(customConfig, baseProduct, loadedDefs) {
        let color = _.get(customConfig, "color", null);
        let valid = true;
        let bpColors = _.get(baseProduct, "colors", []);
        let defColors = _.get(loadedDefs, "colors", []);
        valid = valid && typeof color == "string";
        valid = valid && _.includes(bpColors, color);
        valid = valid && -1 < _.findIndex(defColors, { code: color });

        if (!valid) {
            customConfig.color = this._getDefaultColor(baseProduct);
            this.errors.push("color not valid");
        }

        return valid;
    }
}

// export a static singleton instance
const customConfigValidator = new CustomConfigValidator();
export default customConfigValidator;