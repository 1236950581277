import _ from 'lodash';
import urljoin from 'url-join';
import helpers from '../../helpers';


const _defaultOpts = {
  baseUrl: null,
  routePath: null,
  size: null,
  crop: null,
  primaryColors: null,      // comma delimited string of color
  layers: null,
  layerOpts: null
};

/**
 * Util object to aid in creating and generating consistent custom-product render urls
 */
export default class CprodRenderUrl {

  constructor(opts = null) {
    opts = _.defaults({}, opts, _defaultOpts);
    _.extend(this, opts);
  }

  setBaseUrl(url) {
    this.baseUrl = url;
  }

  setRoutePath(routePath) {
    this.routePath = routePath;
  }

  setPrimaryColorCodes(colorCodes) {
    this.primaryColors = helpers.colors.getColorKeysFromColorCodes(colorCodes);
  }

  setPrimaryColorKeys(colorKeys) {
    this.primaryColors = colorKeys;
  }

  setLayer(layerKey, value = true) {
    this.layers = this.layers || {};
    this.layers[layerKey] = value;
  }

  setLayerOpt(layerKey, value = true) {
    this.layerOpts = this.layerOpts || {};
    this.layerOpts[layerKey] = value;
  }

  toUrl() {
    // build and return  full absolute url string with all query parameters
    let url = this.toRelativeUrl();
    return url? this.safeUrlJoin(this.baseUrl, url): null;
  }

  toRelativeUrl() {
    // build and return relative string with all query parameters
    let url = this.routePath || '';
    let params = this.toRenderParamsObj();
    if (params) {
      let paramsStr = 'json=' + encodeURIComponent(JSON.stringify(params));
      url = this.appendParamStringToUrl(url, paramsStr);
    }
    return url;
  }

  toRenderParamsObj() {
    // attempt to keep param order consistent so that render CDN caching will work better
    let params = {};
    if (this.size) {
      params.size = this.size;
    }
    if (this.crop) {
      params.crop = this.crop;
    }
    if (this.primaryColors) {
        params.primaryColors = helpers.colors.getColorKeyStrFromColorKeys(this.primaryColors);
    }
    if (!_.isEmpty(this.layers)) {
      params.layers = this.layers;
    }
    if (!_.isEmpty(this.layerOpts)) {
      params.layerOpts = this.layerOpts;
    }

    if (!_.isEmpty(this.optParams)) {
      // add any additional custom option params now
      // avoid clobbering any previously defined fields
      params = _.defaultsDeep(params, this.optParams);
    }
    return !_.isEmpty(params)? params: null;
  }

  safeUrlJoin(...pathParts) {
    pathParts = _.compact(pathParts);
    return !_.isEmpty(pathParts)? urljoin(pathParts): '';
  }

  // this helper checks whether the url already contains '?' and appends using '?' or '&' accordingly
  appendParamStringToUrl(url, paramsStr) {
    if (!url || !_.isString(url)) { url = ''; }
    if (_.isString(paramsStr)) {
      paramsStr = _.trimStart(paramsStr, ['?', '&']);
      if (!_.isEmpty(paramsStr)) {
        let delim = _.includes(url, '?')? '&': '?';
        url += delim + paramsStr;
      }
    }
    return url;
  }

}
