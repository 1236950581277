import React from "react";
import PropTypes from "prop-types";
import BaseInputComponent from "../baseInputComponent/baseInputComponent"


export default class CheckBoxStitching extends BaseInputComponent {

  static get propTypes() {
    return {
          // eslint-disable-next-line
    ...super.propTypes,
    onClick: PropTypes.func
    }
  };

  static get defaultProps() {
    return {
      ...super.defaultProps,
      colorOptions: [],
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      value: (this.props.value) ? this.props.value : false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    if (this.isDisabled()) { return; }
    this.setValue(!this.state.value);
  }

  handleChange(event) {
    if (this.isDisabled()) { return; }
    this.setValue(event.target.checked);
  }

  render() {
    let customClass = null;
    if (this.props.customClass) {
      customClass = this.props.customClass
    } else {
      customClass = "pt-3 pb-3 mt-3"
    }
    return (
      <div className={customClass}>
        <div className='checkbox-container'>
          <label className="clickable">
            <input type='checkbox'
              onChange={(e) => this.handleChange(e)}
              checked={this.state.value} />
            <span className='checkmark mr-2'></span>
          </label>
          <div className="label">
            <div className="d-flex w-100 pb-1 checkbox-label-container">
              <div className="checkbox__label clickable" onClick={this.toggle}>
                {this.props.label}
              </div>
              <div className="checkbox__rightLabel">
                {this.props.rightLabel}
              </div>
            </div>
            <div className="checkbox__description">
              {this.props.description}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
