import React, { Component } from 'react';
import _ from "lodash";
import { connect } from "react-redux";
import { closeModal } from "../../../redux/reducers/modal/modalActions";
import helpers from "../../../helpers";
import { setActiveTab, setActiveLocation, setView } from "../../../redux/reducers/navigation/navigationActions";
import { actionNotification } from "../../../redux/reducers/notifications/notificationsActions";
import { SetDecorationSubtab } from "../../../redux/reducers/decoration/decorationAction";
import { replaceLocation, swapLocations } from "../../../redux/reducers/customConfig/customConfigActions";
import PreviewCanvasTwo from "../../previewCanvasTwo/PreviewCanvasTwo";

class ReplaceArtwork extends Component {
  constructor(props) {
    super(props);
    this.replaceLocation = this.replaceLocation.bind(this);
    this.swapLocations = this.swapLocations.bind(this);
  }

  replaceLocation() {
    let oldLocations = this.props.customConfig.locations;
    let sourceLocation = this.props.modal.sourceLocation;
    let targetLocationCode = this.props.modal.targetLocation;
    console.log(targetLocationCode)
    let subTab;
    this.props.replaceLocation(oldLocations, sourceLocation, targetLocationCode);
    this.props.closeModal();
    this.props.setActiveLocation(targetLocationCode.code, this.props.navigation);
    if (targetLocationCode.entity.type === 'custom-text' || 'player-name' || 'player-number') {
      subTab = "TEXT";
      this.props.SetDecorationSubtab(subTab);
      let tabObj = { tab: this.props.navigation.activeTab.tab, subTab: "add-text", page: "add" }
      this.props.setActiveTab(tabObj)

    }
    // let tabObj = { tab: this.props.navigation.activeTab.tab, page: "edit" };
    // this.props.setActiveTab(tabObj);
    this.props.setView(targetLocationCode.view);
  }

  swapLocations() {
    let subTab;
    let oldLocations = this.props.customConfig.locations;
    let sourceLocation = this.props.modal.sourceLocation;
    let targetLocationCode = this.props.modal.targetLocation;
    this.props.swapLocations(oldLocations, sourceLocation, targetLocationCode);
    this.props.closeModal();
    this.props.setActiveLocation(targetLocationCode.code, this.props.navigation);
    if (targetLocationCode.entity.type === 'custom-text' || 'player-name' || 'player-number') {
      subTab = "TEXT";
      this.props.SetDecorationSubtab(subTab);
      let tabObj = { tab: this.props.navigation.activeTab.tab, subTab: "add-text", page: "add" }
      this.props.setActiveTab(tabObj)

    }
    // let tabObj = { tab: this.props.navigation.activeTab.tab, page: "edit" };
    // this.props.setActiveTab(tabObj);
    this.props.setView(targetLocationCode.view);

    // remove canvas extra entity from dragging
    let canvasId = `canvas-${targetLocationCode.view}`;
    let canvas = document.getElementById(canvasId).fabric;
    let canvasObjectToRemove = _.find(canvas.getObjects(), { id: `entity-${sourceLocation.code}` });
    canvas.remove(canvasObjectToRemove);
  }

  handleClose() {
    // if i am a pending entity data I don't need to to do anything.
    if (this.props.navigation.previewSample && !this.props.navigation.previewSample.locationContentB) {
      this.props.closeModal();
      return;
    }
    // find the canvas > find the entity > set it back to its original position before moving.
    let canvasId = `canvas-${this.props.navigation.activeView}`;
    let targetCanvas = _.get(document.getElementById(canvasId), 'fabric', null);
    let entity = _.find(targetCanvas && targetCanvas.getObjects(), { id: `entity-${this.props.navigation.activeLocation}` });

    // if my view does not match my entity id view, remove it add back to original canvas.
    let entityLocationCode = entity.id.split("-")[1];
    let canvasView = canvasId.split("-")[1];
    let entityLocationDetails = _.find(this.props.baseProduct.locations, { code: entityLocationCode });
    if (entityLocationDetails) {
      if (entityLocationDetails.view !== canvasView) {
        targetCanvas.remove(entity);
        // get original canvas and add entity back to it.
        let originalCanvasId = `canvas-${entityLocationDetails.view}`;
        let originalCanvas = _.get(document.getElementById(originalCanvasId), 'fabric', null);
        if (originalCanvas) {
          originalCanvas.add(entity);
        }
      }
    }

    if (entity) {
      if (this.props.navigation.originalEntityPosition) {
        entity.set({
          left: this.props.navigation.originalEntityPosition.left,
          top: this.props.navigation.originalEntityPosition.top,
        });
      }
    }
    targetCanvas.renderAll();
    this.props.closeModal();
  }

  renderMobileCancel() {
    return (<div className="original-cancel-mobile" onClick={() => this.handleClose()} style={{ width: "152px", cursor: 'pointer' }}>
      <div className="ra-or-text">-or-</div>
      <div className="ra-prompt ra-cancel" style={{ margin: "10px 0px" }} >Cancel</div>
      <div className="ra-message" style={{ fontSize: "16px", padding: "0px 22px" }}>Keep the old Artwork</div>
    </div>);
  }

  renderSwapButton(multipleViews) {
    const { locationContentA, locationContentB } = this.props.navigation.previewSample;
    let swapToolTip = _.find(this.props.toolTips, { key: "swap-replace" }).message;
    if (!helpers.locations.doesViewLocationAllowEntityType(locationContentA.view, locationContentA.code, locationContentB.entity.type)) {
      return (null);
    }
    return (
      <div className="swap d-flex flex-column" onClick={() => this.swapLocations()} style={{ width: "152px", cursor: 'pointer' }}>
        <div style={{ border: "solid 1px darkgrey" }}>
          <div className="swap-a" style={{ width: "150px", height: "200px", backgroundColor: "lightgrey", borderBottom: "solid 1px darkgrey" }}>
            <PreviewCanvasTwo view={locationContentA} isThumbnail={true} sample={"swap-a"} borders={false} />
          </div>
          {multipleViews ?
            <div className="swap-b" style={{ width: "150px", height: "200px", backgroundColor: "lightgrey", borderBottom: "solid 1px darkgrey" }}>
              <PreviewCanvasTwo view={locationContentB} isThumbnail={true} sample={"swap-b"} borders={false} />
            </div> : null
          }
          <div aria-label={swapToolTip} data-balloon-pos="right" className="ra-prompt ra-no" style={{ margin: "10px 0px" }} >Swap</div>
        </div>
        <div className="ra-message" style={{ fontSize: "16px", padding: "0px 22px" }}>Switch the locations</div>
      </div>
    )
  }

  render() {
    let multipleViews = false;
    let canSwap = false;
    let { locationContentA, locationContentB, pendingLocationContent } = this.props.navigation.previewSample;
    let cancelToolTip = _.find(this.props.toolTips, { key: "cancel-replace" }).message;
    let replaceToolTip = _.find(this.props.toolTips, { key: "replace" }).message;
    if (locationContentB) {
      canSwap = true;
      if (locationContentA.view !== locationContentB.view) {
        multipleViews = true;
      }
    } else {
      locationContentB = pendingLocationContent;
    }

    return (
      <div className="simple-modal" style={{ display: "flex", flexDirection: "column", textAlign: "center", justifyContent: "center" }}>
        <div className="replace-artwork-header">Do you want to replace your choice at this location?</div>
        <div className="choices d-flex " style={{ justifyContent: "space-around" }}>
          {/* original */}
          <div className="original-cancel-full">
            <div className="original d-flex flex-column" onClick={() => this.handleClose()} style={{ width: "152px", cursor: 'pointer' }}>
              <div style={{ border: "solid 1px darkgrey" }}>
                <div className="original-a" style={{ width: "150px", height: "200px", backgroundColor: "lightgrey", borderBottom: "solid 1px darkgrey" }}>
                  <PreviewCanvasTwo view={locationContentA} isThumbnail={true} sample={"original-a"} borders={false} />
                </div>
                {multipleViews ?
                  <div className="original-b" style={{ width: "150px", height: "200px", backgroundColor: "lightgrey", borderBottom: "solid 1px darkgrey" }}>
                    <PreviewCanvasTwo view={locationContentB} isThumbnail={true} sample={"original-b"} borders={false} />
                  </div> : null
                }
                <div aria-label={cancelToolTip} data-balloon-pos="left" className="ra-prompt ra-no" style={{ margin: "10px 0px" }} >Cancel</div>
              </div>
              <div className="ra-message" style={{ fontSize: "16px", padding: "0px 22px" }}>Keep the old Artwork</div>
            </div>
          </div>
          {/* swap */}
          {canSwap ? this.renderSwapButton(multipleViews) : null}
          {/* replace */}
          <div className="replace d-flex flex-column" onClick={() => this.replaceLocation()} style={{ width: "152px", cursor: 'pointer' }}>
            <div style={{ border: "solid 1px darkgrey" }}>
              <div className="replace-a" style={{ width: "150px", height: "200px", backgroundColor: "lightgrey", borderBottom: "solid 1px darkgrey" }}>
                <PreviewCanvasTwo view={locationContentA} isThumbnail={true} sample={"replace-a"} borders={false} />
              </div>
              {multipleViews ?
                <div className="replace-b" style={{ width: "150px", height: "200px", backgroundColor: "lightgrey", borderBottom: "solid 1px darkgrey" }}>
                  <PreviewCanvasTwo view={locationContentB} isThumbnail={true} sample={"replace-b"} borders={false} />
                </div> : null
              }
              <div aria-label={replaceToolTip} data-balloon-pos="right" className="ra-prompt ra-no" style={{ padding: "10px 0px", backgroundColor: 'black', color: "white" }} >Replace</div>
            </div>
            <div className="ra-message" style={{ fontSize: "16px", padding: "0px 22px" }}>Remove old Artwork</div>
          </div>
        </div>
        { this.renderMobileCancel()}
        <div className="ra-end-message" style={{ padding: "20px 0px 8px 0px" }}>Don't worry -- Any previously uploaded artwork will still be available in your artwork library</div>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    navigation: state.navigation,
    baseProduct: state.baseProduct,
    notifications: state.notifications,
    customConfig: state.customConfig,
    modal: state.modal,
    toolTips: state.toolTips,
    decoration: state.decoration
  }
}
let reduxFunctions = {
  closeModal,
  setActiveLocation,
  actionNotification,
  replaceLocation,
  swapLocations,
  setView,
  setActiveTab,
  SetDecorationSubtab,
}
export default connect(mapStateToProps, reduxFunctions)(ReplaceArtwork);
