import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import helpers from '../../../helpers';
import { deleteLocation } from '../../../redux/reducers/customConfig/customConfigActions';
import { deleteModal } from "../../../redux/reducers/modal/modalActions";
import { setPendingLocation, setActiveLocation, setActiveTab, setView, setCustomTextInput, previewSample } from '../../../redux/reducers/navigation/navigationActions';
import { addPendingLocationToLocations } from '../../../redux/reducers/customConfig/customConfigActions';
import { SetDecorationSubtab, SetDecorationType } from "../../../redux/reducers/decoration/decorationAction";
import { locationUsed } from "../../../redux/reducers/modal/modalActions";

class CardToggleButtonGroup extends Component {
  ;
  constructor(props) {
    super(props);
    this.state = {
      selectedType: this.props.decoration.editDecorationType !== "" ? this.props.decoration.editDecorationType : "",
    }
  }
  componentDidMount() {
    // whenever this mounts set the pendingLocation.
    let entityTypes = this.getProductEntityTypes();
    let bestLocation = null;
    _.some(entityTypes, (entity) => {
      bestLocation = this.findBestEmptyLocation(entity.key)
      if (bestLocation) {
        this.setToBestLocation(bestLocation, entity.key);
        if (this.props.navigation.activeLocation !== bestLocation.view) {
          this.props.setView(bestLocation.view);
        }
        return true;
      }
    });
    if (this.props.decoration.editDecorationType !== "") {
      this.handleOnClick(this.props.decoration.editDecorationType);
    }

  }

  getProductEntityTypes() {
    let productEntities = [];
    _.forEach(this.props.baseProduct.locations, (location) => {
      _.forEach(location.allowedEntities, (allowedEntity) => {
        let productLocationExists = _.find(productEntities, { key: allowedEntity.key });
        if (!productLocationExists && allowedEntity.key !== 'custom-art') {
          productEntities.push(allowedEntity);
        }
      });
    });
    productEntities = helpers.product.formatTypeDisplayOrder(productEntities);
    return productEntities;
  }

  findBestEmptyLocation = (entityKey) => {
    let views = helpers.locations.getAllViews();
    let bestLocation = null;
    // if location does not support entity type find next available location
    bestLocation = helpers.locations.findBestEmptyLocationByEntityType(entityKey, this.props.navigation.activeView);

    if (!bestLocation) {
      //  if there is no best location on current view search all views
      _.some(views, (view) => {
        bestLocation = helpers.locations.findBestEmptyLocationByEntityType(entityKey, view.key);
        if (bestLocation) { return true; }
      });
    }

    return bestLocation;
  }

  findBestFilledLocation = (entityKey) => {
    let views = helpers.locations.getAllViews();
    let bestLocation = null;
    // if location does not support entity type find next available location
    bestLocation = helpers.locations.findBestFilledLocation(entityKey, this.props.navigation.activeView);

    if (!bestLocation) {
      //  if there is no best location on current view search all views
      _.some(views, (view) => {
        bestLocation = helpers.locations.findBestFilledLocation(entityKey, view.key);
        if (bestLocation) { return true; }
      });
    }

    return bestLocation;
  }

  setToBestLocation = (bestLocation, entityKey) => {
    let entityDefaultValues = _.find(bestLocation.allowedEntities, { key: entityKey });
    if (entityDefaultValues) {
      let value = entityDefaultValues.placeholderValue;
      if (entityKey === 'custom-text') {
        if (this.props.navigation.customTextInput) {
          value = this.props.navigation.customTextInput;
        }
      }
      this.pendingLocation = helpers.navigation.makePendingLocation(entityDefaultValues.type, value, bestLocation.view, bestLocation.code);

      let locationContent = _.find(this.props.baseProduct.locations, { code: this.pendingLocation.code });

      if (this.props.baseProduct.designNum === null || this.props.baseProduct.designNum === 'default') {
        if (this.props.navigation.duplicate) {
          if (this.props.navigation.duplicate.entity.type === 'custom-text') {
            if (this.pendingLocation.entity.type === 'player-name' || this.pendingLocation.entity.type === 'player-number') {

              let used_color = this.props.navigation.duplicate.entity.capabilities.fillColors[0].value;
              let exactAllowedEntity = _.find(locationContent.allowedEntities, { type: this.pendingLocation.entity.type });

              if (exactAllowedEntity.allowedColors !== undefined) {
                const found = exactAllowedEntity.allowedColors.find(element => element === used_color);
                if (found === undefined) {
                  this.pendingLocation.entity.capabilities.fillColors[0].value = exactAllowedEntity.defaultColor;
                } else {
                  this.pendingLocation.entity.capabilities.fillColors[0].value = this.props.navigation.duplicate.entity.capabilities.fillColors[0].value;
                }
              }
              // this.pendingLocation.entity.capabilities.fillColors[0].value = this.props.navigation.duplicate.entity.capabilities.fillColors[0].value;

            } else {
              this.pendingLocation.entity.capabilities = this.props.navigation.duplicate.entity.capabilities;
            }
          } else if (this.props.navigation.duplicate.entity.type === 'player-number' || this.props.navigation.duplicate.entity.type === 'player-name') {
            if (this.pendingLocation.entity.type === 'custom-text') {
              this.pendingLocation.entity.capabilities.fillColors[0].value = this.props.navigation.duplicate.entity.capabilities.fillColors[0].value;
            } else {
              this.pendingLocation.entity.capabilities = this.props.navigation.duplicate.entity.capabilities;
            }

          }
        }
      } else {
        if (this.props.navigation.duplicate) {
          if (this.props.navigation.duplicate.entity.type === 'custom-text' || this.props.navigation.duplicate.entity.type === 'player-name') {
            if (this.props.navigation.duplicate.entity.patternFill === 'No') {
              if (this.pendingLocation.entity.type === 'player-number') {
                this.pendingLocation.entity.capabilities.fillColors[0].value = this.props.navigation.duplicate.entity.capabilities.fillColors[0].value;
                if (this.props.navigation.duplicate.entity.capabilities.fillColors[1].value !== null) {
                  this.pendingLocation.entity.capabilities.fillColors[1].value = this.props.navigation.duplicate.entity.capabilities.fillColors[1].value;
                }
              } else {
                this.pendingLocation.entity.capabilities = this.props.navigation.duplicate.entity.capabilities;
              }
            } else {
              if (this.pendingLocation.entity.type === 'player-number') {
                this.pendingLocation.entity.patternFill = this.props.navigation.duplicate.entity.patternFill;
                this.pendingLocation.entity.capabilities.fillColors[1].value = this.props.navigation.duplicate.entity.capabilities.fillColors[0].value;
                this.pendingLocation.entity.capabilities.fillColors.splice(2, 1);
                this.pendingLocation.entity.capabilities.textPatternFillId = this.props.navigation.duplicate.entity.capabilities.textPatternFillId;
                this.pendingLocation.entity.capabilities.textPatternColors = this.props.navigation.duplicate.entity.capabilities.textPatternColors;
              } else {
                this.pendingLocation.entity.patternFill = this.props.navigation.duplicate.entity.patternFill;
                this.pendingLocation.entity.capabilities = this.props.navigation.duplicate.entity.capabilities;
              }
            }
          } else if (this.props.navigation.duplicate.entity.type === 'custom-art') {
            this.pendingLocation.entity.capabilities = this.props.navigation.duplicate.entity.capabilities;
          }
          else {
            if (this.props.navigation.duplicate.entity.patternFill === 'No') {
              this.pendingLocation.entity.capabilities.fillColors[0] = this.props.navigation.duplicate.entity.capabilities.fillColors[0];
              this.pendingLocation.entity.capabilities.fillColors[1] = this.props.navigation.duplicate.entity.capabilities.fillColors[1];
            } else {
              if (this.pendingLocation.entity.type !== 'player-number') {
                this.pendingLocation.entity.patternFill = this.props.navigation.duplicate.entity.patternFill;
                this.pendingLocation.entity.capabilities.textPatternFillId = this.props.navigation.duplicate.entity.capabilities.textPatternFillId;
                this.pendingLocation.entity.capabilities.textPatternColors = this.props.navigation.duplicate.entity.capabilities.textPatternColors;
                this.pendingLocation.entity.capabilities.fillColors[1].value = this.props.navigation.duplicate.entity.capabilities.fillColors[0].value;
              } else {
                this.pendingLocation.entity.patternFill = this.props.navigation.duplicate.entity.patternFill;
                this.pendingLocation.entity.capabilities = this.props.navigation.duplicate.entity.capabilities;
              }
            }
          }

        }
      }


      this.props.setPendingLocation(this.pendingLocation);
      this.props.setActiveLocation(bestLocation.code);
      this.props.setView(bestLocation.view);

    }
  }

  handleOnClick = (key) => {
    this.props.SetDecorationType(key);
    let entityKey = key;
    let bestEmptyLocation = null;
    let baseProductLocationDetails = _.find(this.props.baseProduct.locations, { code: this.props.navigation.activeLocation });
    let doesLocationSupportEntityType = _.find(baseProductLocationDetails.allowedEntities, { key: entityKey });
    let UpdatedLocations = this.props.customConfig.locations
    // remove locations with saveentity as false
    UpdatedLocations = _.filter(UpdatedLocations, loc =>
      loc.saveEntity === true || (this.props.decoration.editDecorationType !== "" && loc.editEntity === true)
    );

    this.props.customConfig.locations = UpdatedLocations
    // does current location support selected entity
    let isLocationEmpty = !_.find(this.props.customConfig.locations, { code: this.props.navigation.activeLocation });
    if (isLocationEmpty || this.props.decoration.editDecorationType !== "") {
      if (doesLocationSupportEntityType) {
        let entityDefaultValues = _.find(baseProductLocationDetails.allowedEntities, { key: entityKey });
        if (entityDefaultValues) {
          let value = entityDefaultValues.placeholderValue;
          if (entityKey === 'custom-text') {
            if (this.props.navigation.customTextInput) {
              // value = this.props.navigation.customTextInput;
            }
          }
          if (this.props.activeView !== baseProductLocationDetails.view) {
            this.props.setView(baseProductLocationDetails.view);
          }
          this.pendingLocation = helpers.navigation.makePendingLocation(entityDefaultValues.type, value, baseProductLocationDetails.view, baseProductLocationDetails.code);
          let locationContent = _.find(this.props.baseProduct.locations, { code: this.pendingLocation.code });

          if (this.props.baseProduct.designNum === null || this.props.baseProduct.designNum === 'default') {
            if (this.props.navigation.duplicate) {
              if (this.props.navigation.duplicate.entity.type === 'custom-text') {
                if (this.pendingLocation.entity.type === 'player-name' || this.pendingLocation.entity.type === 'player-number') {
                  let used_color = this.props.navigation.duplicate.entity.capabilities.fillColors[0].value;
                  let exactAllowedEntity = _.find(locationContent.allowedEntities, { type: this.pendingLocation.entity.type });
                  if (exactAllowedEntity.allowedColors !== undefined) {
                    const found = exactAllowedEntity.allowedColors.find(element => element === used_color);
                    if (found === undefined) {
                      this.pendingLocation.entity.capabilities.fillColors[0].value = exactAllowedEntity.defaultColor;
                    } else {
                      this.pendingLocation.entity.capabilities.fillColors[0].value = this.props.navigation.duplicate.entity.capabilities.fillColors[0].value;
                    }
                  }
                }
                else {
                  this.pendingLocation.entity.capabilities = this.props.navigation.duplicate.entity.capabilities;
                }
              } else if (this.props.navigation.duplicate.entity.type === 'player-number' || this.props.navigation.duplicate.entity.type === 'player-name') {
                if (this.pendingLocation.entity.type === 'custom-text') {
                  this.pendingLocation.entity.capabilities.fillColors[0].value = this.props.navigation.duplicate.entity.capabilities.fillColors[0].value;
                } else {
                  this.pendingLocation.entity.capabilities = this.props.navigation.duplicate.entity.capabilities;
                }

              }
            }
          } else {
            if (this.props.navigation.duplicate) {
              if (this.props.navigation.duplicate.entity.type === 'custom-text' || this.props.navigation.duplicate.entity.type === 'player-name') {
                if (this.props.navigation.duplicate.entity.patternFill === 'No') {
                  if (this.pendingLocation.entity.type === 'player-number') {
                    this.pendingLocation.entity.capabilities.fillColors[0].value = this.props.navigation.duplicate.entity.capabilities.fillColors[0].value;
                    if (this.props.navigation.duplicate.entity.capabilities.fillColors[1].value !== null) {
                      this.pendingLocation.entity.capabilities.fillColors[1].value = this.props.navigation.duplicate.entity.capabilities.fillColors[1].value;
                    }
                  } else {
                    this.pendingLocation.entity.capabilities = this.props.navigation.duplicate.entity.capabilities;
                  }
                } else {
                  if (this.pendingLocation.entity.type === 'player-number') {
                    this.pendingLocation.entity.patternFill = this.props.navigation.duplicate.entity.patternFill;
                    this.pendingLocation.entity.capabilities.fillColors[1].value = this.props.navigation.duplicate.entity.capabilities.fillColors[0].value;
                    this.pendingLocation.entity.capabilities.fillColors.splice(2, 1);
                    this.pendingLocation.entity.capabilities.textPatternFillId = this.props.navigation.duplicate.entity.capabilities.textPatternFillId;
                    this.pendingLocation.entity.capabilities.textPatternColors = this.props.navigation.duplicate.entity.capabilities.textPatternColors;
                  } else {
                    this.pendingLocation.entity.patternFill = this.props.navigation.duplicate.entity.patternFill;
                    this.pendingLocation.entity.capabilities = this.props.navigation.duplicate.entity.capabilities;
                  }
                }
              } else if (this.props.navigation.duplicate.entity.type === 'custom-art') {
                this.pendingLocation.entity.capabilities = this.props.navigation.duplicate.entity.capabilities;
              }
              else {
                if (this.props.navigation.duplicate.entity.patternFill === 'No') {
                  this.pendingLocation.entity.capabilities.fillColors[0] = this.props.navigation.duplicate.entity.capabilities.fillColors[0];
                  this.pendingLocation.entity.capabilities.fillColors[1] = this.props.navigation.duplicate.entity.capabilities.fillColors[1];
                } else {
                  if (this.pendingLocation.entity.type !== 'player-number') {
                    this.pendingLocation.entity.patternFill = this.props.navigation.duplicate.entity.patternFill;
                    this.pendingLocation.entity.capabilities.textPatternFillId = this.props.navigation.duplicate.entity.capabilities.textPatternFillId;
                    this.pendingLocation.entity.capabilities.textPatternColors = this.props.navigation.duplicate.entity.capabilities.textPatternColors;
                    this.pendingLocation.entity.capabilities.fillColors[1].value = this.props.navigation.duplicate.entity.capabilities.fillColors[0].value;
                  } else {
                    this.pendingLocation.entity.patternFill = this.props.navigation.duplicate.entity.patternFill;
                    this.pendingLocation.entity.capabilities = this.props.navigation.duplicate.entity.capabilities;
                  }
                }
              }
            }
          }

          this.props.setPendingLocation(this.pendingLocation);
          this.props.setActiveLocation(baseProductLocationDetails.code);
        }
      } else {
        bestEmptyLocation = this.findBestEmptyLocation(entityKey);
        if (bestEmptyLocation) {
          this.setToBestLocation(bestEmptyLocation, entityKey);
        } else {
          let bestFilledLocation = this.findBestFilledLocation(entityKey);
          this.setToBestLocation(bestFilledLocation, entityKey)
        }
      }
    } else {
      bestEmptyLocation = this.findBestEmptyLocation(entityKey);
      if (bestEmptyLocation) {
        this.setToBestLocation(bestEmptyLocation, entityKey);
      } else {
        let bestFilledLocation = this.findBestFilledLocation(entityKey);
        this.setToBestLocation(bestFilledLocation, entityKey)
      }
    }

    let groupName = this.props.baseProduct.locations.filter(v => v.code === this.pendingLocation.code);
    let locationCodeA = this.pendingLocation.code;
    this.props.previewSample(this.props.baseProduct, locationCodeA, null, this.pendingLocation);

    let isLocationFilled = _.find(this.props.customConfig.locations, { code: this.pendingLocation.code });
    if (isLocationFilled) {
      let sourceLocation = this.pendingLocation;
      let targetLocation = isLocationFilled;
      if (this.props.decoration.editDecorationType === "") {
        this.props.locationUsed(sourceLocation, targetLocation);
      }
    } else {
      this.props.addPendingLocationToLocations(this.props.customConfig.locations, this.pendingLocation, groupName);
      let subTab = "TEXT";
      this.props.SetDecorationSubtab(subTab);
      let tabObj = { tab: this.props.navigation.activeTab.tab, subTab: "add-text", page: "add" }
      this.props.setActiveTab(tabObj);
    }
    this.props.setCustomTextInput("");
    this.props.setPendingLocation(null);
    this.props.setView(this.pendingLocation.view);
  }

  renderButtons = () => {
    let types = this.getProductEntityTypes();
    let selectedType;
    let buttons = _.map(types, (productEntity, i) => {
      selectedType = (productEntity.key === this.state.selectedType);
      if (productEntity.key === 'custom-text') {
        productEntity.placeholderValue = 'TEAM NAME';
      }
      if (productEntity.key === 'player-name') {
        productEntity.placeholderValue = 'PLAYER';
        productEntity.name = 'Names';
      }
      if (productEntity.key === 'player-number') {
        productEntity.placeholderValue = '00';
        productEntity.name = 'Number';
      }
      let warning = !this.findBestEmptyLocation(productEntity.key) ? "warning" : "";
      let active = "";
      if (this.props.navigation.pendingLocation && this.props.navigation.pendingLocation.entity.type === productEntity.type) {
        active = "active";
      }
      return (


        <div className="col-4 p-0" key={i}>
          {this.props.decoration.editDecorationType === "" ?
            <div className="row p-4">
              <div className="col-12 d-flex justify-content-center pb-1 align-bottom pl-0 pr-0">
                {productEntity.name}
              </div>
              <div className="col-12 h-100 w-100 pl-0 pr-0">
                <div >
                  <div className={this.props.decoration.selectedDecType === "" ? `card card-button ${active} ${warning}` : selectedType ? "card card-button-active" : "card-button-disabled"} value={productEntity.key}
                    // onClick={this.handleOnClick}
                    onClick={
                      async () => {
                        this.setState({ selectedType: productEntity.key });
                        await this.handleOnClick(productEntity.key);
                      }}>
                    <div className="card-body text-center pl-1 pr-1 pt-2 pb-2">
                      {productEntity.placeholderValue}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            : null}
        </div>



      );
    });
    return buttons;
  }

  render() {
    return (
      <div className="CardToggleButtonGroup">
        <div className="row">
          {this.renderButtons()}
        </div>
      </div>
    )
  }
}

const reduxActions = {
  deleteModal,
  deleteLocation,
  setPendingLocation,
  setActiveLocation,
  setActiveTab,
  setView,
  setCustomTextInput,
  addPendingLocationToLocations,
  previewSample,
  locationUsed,
  SetDecorationSubtab,
  SetDecorationType,
}

function mapStateToProps(state) {
  return {
    navigation: state.navigation,
    baseProduct: state.baseProduct,
    customConfig: state.customConfig,
    decoration: state.decoration,
    colorOptions: state.colorOptions,
  }
}

export default connect(mapStateToProps, reduxActions)(CardToggleButtonGroup);