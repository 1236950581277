import _ from 'lodash';
import stateProvider from './stateProvider';




const _defaultSchoolColorKeys = ['royal', 'athletic_gold', 'white'];


// ColorsHelper
// helpers for parsing, converting and getting color related data.
// This helper depends on color defs loaded from  baseProduct.loadedDefs as well as some built in standard color defs
// NOTE: some of these color definitions are from the GARB schools list master colors.
// they need to match the allowed values in the schools list DB and the related server API definitions.
// Do not add or remove to this list without updating all other areas of use to match.
// key => color key.                                ex: 'white' (White), 'red' (Red)
// code => m3 color code.                           ex: 'WH' (White), 'RD' (Red)
// name => human readable color display name.       ex: 'White', 'Red'
// renderHex => RGB hex value for the color (to be used in rendering contexts).  This color is closer to the value used in manufacturing.
// pleasantHex => RGB hex value to be used in UI and theming elements.  This is a more pleasant variation of the color.
// textColor => This is the companion text color to be used when text is on top of this color. ex: page title on top of colored banner.
class ColorsHelper {

  // state helpers

  getStateVal(key, defaultValue = null) {
    return stateProvider.get(key, defaultValue);
  }


  // color helpers

  _getColorItemValue(colorKey, prop, defaultValue = null){
    let colorItems = this.getAllColorItems();
    let colorItem = _.find(colorItems, { key: colorKey }) || null;
    return _.get(colorItem, prop, defaultValue);
  }

  getColor(colorCode) {
    return this.getColorFromColorCode(colorCode);
  }

  getColorFromColorCode(colorCode) {
    if (!colorCode) { return null; }
    let colorItems = this.getAllColorItems();
    return _.find(colorItems, {code: colorCode});
  }

  getColorsFromColorCodes(colorCodes) {
    let colorItems = this.getAllColorItems();
    return _.map(colorCodes, colorCode => {
      return (colorCode && _.find(colorItems, { code: colorCode })) || null;
    });
  }

  getColorFromColorKey(colorKey) {
    if (!colorKey) { return null; }
    let colorItems = this.getAllColorItems();
    return _.find(colorItems, { key: colorKey }) || null;
  }

  getColorKeyStrFromColorCodes(colorCodes) {
    let colorKeys = this.getColorKeysFromColorCodes(colorCodes);
    return this.getColorKeyStrFromColorKeys(colorKeys);
  }

  getColorKeyStrFromColorKeys(colorKeys) {
    let retval = null;
    if (colorKeys) {
      if (_.isString(colorKeys)) {
        retval = colorKeys;
      } else {
        retval = _.join(_.compact(colorKeys), ',');
      }
    }
    return retval;
  }

  getColorDisplayName(colorKey) {
    return this._getColorItemValue(colorKey, 'name');
  }

  getColorRenderHexColor(colorKey) {
    return this._getColorItemValue(colorKey, 'renderHex');
  }

  getColorPleasantHexColor(colorKey) {
    return this._getColorItemValue(colorKey, 'pleasantHex');
  }

  getColorTextOverlayHexColor(colorKey) {
    return this._getColorItemValue(colorKey, 'textOverlayHex');
  }

  getNearestAllesonA4ColorCode(colorKey) {
    return this._getColorItemValue(colorKey, 'a4ColorCode');
  }

  getColorKeysFromColorCodes(colorCodes) {
    return _.map(colorCodes, v => {
      return this.getColorKeyFromColorCode(v);
    });
  }

  getColorKeyFromColorCode(colorCode) {
    let colorItems = this.getAllColorItems();
    let colorItem = _.find(colorItems, { code: colorCode });
    return _.get(colorItem, 'key', null);
  }

  getColorKeyFromDisplayName(colorName) {
    let colorItems = this.getAllColorItems();
    let colorItem = _.find(colorItems, { name: colorName });
    return _.get(colorItem, 'key', null);
  }

  getDefaultSchoolColorKeys() {
    return _defaultSchoolColorKeys;
  }

  getColorInColorArray(color_key, colors){
    let color = colors[color_key] || false;
    return color;
  }

  buildColors({ active:{ color1, color2, color3 }, useOrgColors, organization:{ colors } }){
    return (useOrgColors ? [colors[0].color1, colors[0].color2, colors[0].color3] : [color1, color2, color3]).join(',');
  }

  getAllColorItems(){
    return this.getStateVal('loadedDefs.colors');
  }

  isLightColor(colorObj) {

    // TODO: improve this. for now, just checking against some known white rgb values
    let whiteColors = ['#FFF', '#FFFFFF', '#FEFEFE', '#EEE', '#EEEEEE', '#F4EFE5'];

    if (!colorObj) { return false; }
    let hexUpper = this.toHexString(colorObj.rgb, '#', true);
    return (hexUpper === null || _.includes(whiteColors, hexUpper));
  }

  toHexString(val, prefix = '#', upperCase = false) {
    let str = null;
    if (_.isFinite(val)) {
      str = (prefix || '') + _.padStart(val.toString(16), 6, '0');
      if (upperCase) {
        str = _.toUpper(str);
      }
    }
    return str;
  }

  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
  }

  getSelectedProductColorCode(fallbackToDefaultColor = false) {
    let colorCode = this.getStateVal('customConfig.color');
    if (!colorCode && fallbackToDefaultColor) {
      colorCode = this.getDefaultProductColorCode();
    }
    return colorCode;
  }

  getSelectedProductColorDef(fallbackToDefaultColor = false) {
    let colorCode = this.getSelectedProductColorCode(fallbackToDefaultColor);
    return this.getColorFromColorCode(colorCode);
  }

  getDefaultProductColorCode() {
    return this.getStateVal('baseProduct.defaultColor');
  }

  getDefaultProductColorDef() {
    let colorCode = this.getDefaultProductColorCode();
    return this.getColorFromColorCode(colorCode);
  }

}

// export default ColorsHelper;
// export a static singleton instance
const colorsHelper = new ColorsHelper();
export default colorsHelper;

