import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import MultiDropdown from '../../../../global/multiDropdown/multiDropdown';
import { changeSetting } from "../../../../../redux/reducers/customConfig/customConfigActions";
import { toggleDelete } from '../../../../../redux/reducers/navigation/navigationActions';
import RotationSlider from "../../../../global/slider/slider";

function EditTextRotation(props) {

  let locationContent = _.find(props.customConfig.locations, { code: props.navigation.activeLocation });

  const handleOnChange = (e) => {
    let value = parseInt(e.value)  // value coming in as string converting it to number.
    let newBounds = _.cloneDeep(locationContent.entity.bounds);
    newBounds.r = value;
    props.changeSetting(props.customConfig.locations, props.navigation.activeLocation, { setting: "bounds", value: newBounds });
  }

  let value = parseInt(locationContent.entity.bounds.r);
  let rightLabel = 'degrees';

  return (
    <div className="EditTextRotation" id="custom-text-rotation" style={{ width: "100%" }}>
      <div className="row pl-4">
        <div className="col-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 label-rotation">
          <label className="decoration-label">{"ROTATION"}</label>
        </div>
        <div className="col-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12">
          <MultiDropdown
            marginTop=" drop-down-margin-top"
            // label="Rotation"
            rightLabel={rightLabel} />
          <RotationSlider
            toggleDelete={props.toggleDelete}
            onChange={e => handleOnChange({ setting: 'rotation', value: e })}
            rotation={value}
          />
        </div>
      </div>

    </div>
  )
}

const reduxActions = {
  changeSetting,
  toggleDelete
}

function mapStateToProps(state) {
  return {
    navigation: state.navigation,
    baseProduct: state.baseProduct,
    customConfig: state.customConfig
  }
}

export default connect(mapStateToProps, reduxActions)(EditTextRotation);