import React, { Component } from 'react'

export default class UserArtNumberOfColorsInput extends Component {
  render() {    
    let activeClassName = (this.props.isActive) ? "" : "not-sure-active"; 
    
    return (<div className={`user__numdc-num-div ${activeClassName}`}>
      <input className={`user__numdc-input ${activeClassName}`}
        type="number"   
        min={1}
        max={99}
        onClick={this.props.setActive}
        value={this.props.value} 
        onChange={this.props.onChange} />
    </div>
    )
  }
}
