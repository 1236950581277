import {UPDATE_MODAL}  from '../../actions/actionTypes';


const initialState = {
  size: null,
  isActive: false,
  title: "Beta Disclaimer",
  message: "InfoModal",
  locked: false
};

// check the env config var to decide whether to show/hide this beta warning modal popup during init
// initialState.isActive = _.get(AppConfig, 'autoShowBetaWarning', initialState.isActive);


export default function modalReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_MODAL: return Object.assign({}, state, action.modal);
    default:
      return state;
  }
}
