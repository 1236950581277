import ReactAppConfigLoader from '../lib/reactAppConfigLoader/reactAppConfigLoader.react';

// ---------------------------------------------------------------------------------------------------------
//            ,    _
//           /|   | |     There is some black magic going on here
//         _/_\_  >_<
//        .-\-/.   |      This file does not load the config values directly from their env source files,
//       /  | | \_ |      but rather it grabs already loaded process.env vars starting with the REACT_APP_ prefix,
//       \ \| |\__(/      removes the prefix and returns them as a new data object.
//       /(`---')  |
//      / /     \  |      This assumes that all app env config files were loaded into REACT_APP_ env
//   _.'  \'-'  /  |      variables at build time
//   `----'`=-='   '
//
// ---------------------------------------------------------------------------------------------------------

const AppConfig = ReactAppConfigLoader.LoadRuntimeAppConfig();
export default AppConfig;