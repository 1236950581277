import stateProvider from './stateProvider';
import helpers from '../helpers';


// CustomLayersHelper - for custom-sub and full-custom product configuration types
// helpers for reading customConfig.customLayers data.
// This helper assumes that the baseProduct is a custom product of some sort and baseProduct has customLayers data as well.
class CustomLayersHelper {

  // state helpers

  getStateVal(key, defaultValue = null) {
    return stateProvider.get(key, defaultValue);
  }


  // custom layer helpers

  isBasicMode() {
    return !this.isAdvancedMode();
  }
  isAdvancedMode() {
    return this.getStateVal('customConfig.customLayers.isAdvancedMode', true);
  }

  getPrimaryColorKeys() {
    let colorCodes = null;
    if (this.isBasicMode()) {
      colorCodes = this.getStateVal('customConfig.customLayers.primaryColors');
    } else {
      // NOTE: primaryColors should be maintained, even in advance mode.
      // we should create another helper to scan through advanced mode config to find
      // assumed primary colors when they do not exist
      colorCodes = this.getStateVal('customConfig.customLayers.primaryColors');
    }

    // convert color codes into color keys, then return
    let colorKeys = helpers.colors.getColorKeysFromColorCodes(colorCodes);
    return colorKeys;
  }

  getAdvancedLayerColors() {    
    // TODO: add impl
    // const layerColors = this.getStateVal('customConfig.customLayers.layers');
    // console.log(layerColors)

  }


}

// export a static singleton instance
const customLayersHelper = new CustomLayersHelper();
export default customLayersHelper;


