import React from 'react';
import _ from "lodash";
import { connect } from 'react-redux';
import { changeSetting, deleteLocation } from "../../../../../redux/reducers/customConfig/customConfigActions";
import { SetDecorationSubtab } from "../../../../../redux/reducers/decoration/decorationAction";
import LocationDetail from "./locationDetail";
import EmptyState from '../../../../global/emptyState/emptyState';
import { deleteModal } from "../../../../../redux/reducers/modal/modalActions";
import { setActiveTab } from "../../../../../redux/reducers/navigation/navigationActions";

class LocationSummary extends React.Component {

  constructor(props) {
    super(props);
    this.props.decoration.selectedSubTab = "";
    this.props.decoration.editDecorationType = "";
    this.props.decoration.selectedDecType = "";
    this.removeLocation = this.removeLocation.bind(this);
  }

  handleNewEntityBounds = (newBounds) => {
    // only update redux if I am the main view / aka not a thumbnail.
    let updatedLocations = this.props.customConfig.locations.filter(v => v.code != newBounds.code)
    if (newBounds && this.props.baseProduct.configurationType == "custom-sub") {
      let existingCode = updatedLocations.map(v => { return { code: v.code, groupName: v.groupName, order: v.displayOrder } });
      let isInit = newBounds.entity ? newBounds.entity.hasOwnProperty("_init") ? newBounds.entity._init : false : false;


      existingCode.sort(function (a, b) {
        var keyA = a.order,
          keyB = b.order;

        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
      _.forEach(existingCode, (previousCode) => {
        let previousLocationCode = this.props.customConfig.locationBounds;
        let sourceLocation = this.props.baseProduct.locations.find(v => v.code == previousLocationCode);
        if (this.props.baseProduct.configurationType === 'custom-sub') {
          if ((newBounds.displayOrder < previousCode.order && previousCode.code != 'LC' && previousCode.code != 'RC' && previousCode.groupName == newBounds.groupName) || ((previousCode.code == 'LC' || previousCode.code == 'RC') && !isInit && newBounds.displayOrder < previousCode.order && previousCode.groupName == newBounds.groupName)) {
            let existingOrdersArray = existingCode.filter(v => v.order != previousCode.order && v.groupName == previousCode.groupName).map(v => v.order)
            let lastGreater = existingOrdersArray.length > 0 ? Math.max(...existingOrdersArray) : previousCode.order;

            existingOrdersArray = existingOrdersArray.filter(v => v < previousCode.order);
            existingOrdersArray = existingOrdersArray.sort((a, b) => a - b);
            const closestFirst = existingOrdersArray.length > 0 ? existingOrdersArray.reduce((a, b) => {
              return Math.abs(b - previousCode.order) < Math.abs(a - previousCode.order) ? b : a;
            }) : previousCode.order;

            let considerOrder = closestFirst ? closestFirst : newBounds.displayOrder;
            if (newBounds.displayOrder < closestFirst) {
              considerOrder = closestFirst;
            }
            let boundsValue = this.props.customConfig.locations.find(v => v.displayOrder == previousCode.order);
            let currentBounds = _.cloneDeep(newBounds.entity.bounds);
            if (considerOrder != newBounds.displayOrder) {
              let boundsToConsider = this.props.customConfig.locations.find(v => v.displayOrder == considerOrder);
              currentBounds = _.cloneDeep(boundsToConsider.entity.bounds);
            }
            //Updated bounds are the bounds which will be updated after below conditions
            let updatedBounds = _.cloneDeep(boundsValue.entity.bounds);
            let viewImageDetails = _.find(this.props.baseProduct.views, { key: boundsValue.view });
            // zzz 600 is hard coded should be image size that we get from api works for now because request size is hard coded. relation: navigationActions.js
            let scale = 600 / viewImageDetails.masterImageSize.h;
            let newTop = 0;
            //To check the below display orders from current bounds in order to change bound values as per new bound orders
            if (this.props.customConfig.locations.find(v => v.displayOrder == lastGreater)) {
              // newTop = (currentBounds.h * scale )+ currentBounds.y +75;

              if (closestFirst == previousCode.order) {
                let rootLocation = _.find(this.props.customConfig.locations, { code: previousCode.code })
                let considerLocation = _.find(this.props.baseProduct.locations, { code: previousCode.code });
                let baseLocationSize = _.find(considerLocation.allowedEntities, { key: rootLocation.entity.type })
                let baseProductScale = 600 / viewImageDetails.masterImageSize.h;
                if (considerLocation.anchorOriginCode === 'CC') {
                  // zzz 600 is hard coded should be image size that we get from api works for now because request size is hard coded. relation: navigationActions.js
                  newTop = considerLocation.bounds.y * baseProductScale + considerLocation.bounds.h * baseProductScale / 2;
                } else if (considerLocation.anchorOriginCode === 'NC' || considerLocation.anchorOriginCode === 'SC') {
                  if (rootLocation.entity.size !== baseLocationSize.defaultSize) {
                    this.props.changeSetting(this.props.customConfig.locations, previousCode.code, { setting: "_rootSizeUpdated", value: true });
                  } else {
                    this.props.changeSetting(this.props.customConfig.locations, previousCode.code, { setting: "_deleteUpdated", value: true });
                  }
                }
              } else if (boundsValue.entity.type == 'player-number' && boundsValue.entity.size == '8in') {
                newTop = currentBounds.y + (currentBounds.h * scale) + 90;
              } else {
                newTop = currentBounds.y + (currentBounds.h * scale) + 75;
              }

              updatedBounds.y = newTop;
              updatedBounds.x = updatedBounds.x;
              this.props.changeSetting(this.props.customConfig.locations, previousCode.code, { setting: "bounds", value: updatedBounds });
              this.props.changeSetting(this.props.customConfig.locations, previousCode.code, { setting: "_heightUpdated", value: false });
              this.props.changeSetting(this.props.customConfig.locations, previousCode.code, { setting: "_widthUpdated", value: false });
              this.props.changeSetting(this.props.customConfig.locations, previousCode.code, { setting: "_init", value: false });
            }
          }
        }
      })


    }

  };

  removeLocation(location) {
    let locations = this.props.customConfig.locations;
    let index = _.findIndex(locations, { code: location.code });
    this.handleNewEntityBounds(locations[index]);
    this.props.deleteLocation(index, locations);
  }

  handleChangePage = () => {
    this.props.SetDecorationSubtab("");
    let tabObj = { tab: this.props.navigation.activeTab.tab, subTab: "add-text", page: "add" }
    this.props.setActiveTab(tabObj)
  }

  renderDetails() {
    let locationDetails = [];
    for (let l in this.props.customConfig.locations) {
      let loc = this.props.customConfig.locations[l];
      for (let filter in this.props.filters) {
        let checkVal = true;
        if (this.props.value) {
          let locationValue = loc.entity.value || "";
          let val = (this.props.value === 'art') ? "" : this.props.value;
          checkVal = (locationValue === val);
        }
        if (loc.entity.type === this.props.filters[filter] && checkVal) {
          let name = _.get(loc, "entity.type");
          let toolTip = _.find(this.props.toolTips, { key: `${name}-location-summary` }).message;
          let deleteToolTip = _.find(this.props.toolTips, { key: `edit-${name}-delete-button` }).message;
          if (name) {
            name = name.replace("-", " ");
          }
          locationDetails.push(<LocationDetail toolTip={toolTip} deleteToolTip={deleteToolTip} baseProduct={this.props.baseProduct} key={l} data={loc} remove={() => this.props.deleteModal(() => this.removeLocation(loc), name)} />);
        }
      }
    }
    return (locationDetails);

  }

  canAdd() {
    let toolTip = _.find(this.props.toolTips, { key: `add-new-${this.props.title.toLowerCase()}-button` }).message;
    if (this.props.availableLocations.length === 0) {
      return (<EmptyState title="All decoration locations are already being used!" message={"<p>You can edit an existing decoration by clicking on it from above.</p><p>You can also delete any decoation and create a new one.</p>"} />);
    }
    return (
      <div aria-label={toolTip} data-balloon-pos="down" className="text-location-summary-add  padding-right ">
        <button
          style={{ border: "none", margin: "0" }}
          onClick={this.handleChangePage}
          className="btn btn-lg btn-add-decoration btn-block button-font-slatestd ">
          ADD NEW DECORATION
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="text-location fade-in">
        {/* <div className="pt-3">
          <p className="decoration-tab-heading">Decorations</p>
        </div> */}
        <div className="text-location-summary-box">
          {this.renderDetails()}
        </div>
        {this.props.navigation.activeTab.tab === 'up-art' && this.props.navigation.activeTab.page === 'main' && this.props.baseProduct.configurationType === 'custom-sub' ? <div className="art-summary-text-red"><b>Please Note:</b> Custom Graphics require Art Approval after checkout.</div> : null}
        <div>
          {this.canAdd()}
        </div>
      </div>);
  }
}

function mapStateToProps(state) {
  return {
    baseProduct: state.baseProduct,
    customConfig: state.customConfig,
    navigation: state.navigation,
    toolTips: state.toolTips,
    decoration: state.decoration,
  }
}

let reduxActions = {
  deleteModal,
  deleteLocation,
  changeSetting,
  setActiveTab,
  SetDecorationSubtab
}
export default connect(mapStateToProps, reduxActions)(LocationSummary);
