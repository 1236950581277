import React from 'react'; 
import { connect } from "react-redux";
import { hideToast } from "../../../redux/reducers/toast/toastActions"; 
class UltimateToast extends React.Component{

  hideSelf(){
    this.props.hideToast(); 
  }

  render(){
    let val = (this.props.toast.isActive) ? "show" : "hide"; 
    return (<div>
      <div className={`snackbar ${val} font-italic text-uppercase`} style={{ zIndex:"1000" }}>
        {this.props.toast.message}
        <button type="button" className="close closeButton" aria-label="Close" onClick={() => this.hideSelf()}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>); 
  }
}

function mapStateToProps(state){
  return {
    toast: state.toast
  }
}

export default connect(mapStateToProps, { hideToast })(UltimateToast); 
