import React, { Component } from 'react';

export default class HtmlComponent extends Component {
  render() {
    let{line, word, title} = this.props; 
    var regex = new RegExp( '(' + word + ')', 'gi' );
    line = line.replace( regex, "<b>$1</b>" );
    line = (title) ? `${title} ${line}` : line; 
    return (<div dangerouslySetInnerHTML={{__html: line}} /> ); 
  } 
    
}
