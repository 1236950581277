import React, { Component } from 'react';
import { connect } from 'react-redux';
import helpers from '../../../../../helpers';
import StitchingColor from '../stitchingColor/StitchingColor'
import ChromaColors from '../ChromaColors/ChromaColors';
import NewChromaColors from '../NewChromaColors/NewChromaColors';
import ChromaPatterns from '../ChromaPatterns/ChromaPatterns';
import OptionHeading from '../../../../global/optionHeading/OptionHeading';
import _ from 'lodash';
import AdvancedBasicButton from '../AdvancedBasicButton/AdvancedBasicButton';
import { toggleAdvancedMode, defaultLayerColors} from '../../../../../redux/reducers/customConfig/customConfigActions';
import stateProvider from '../../../../../helpers/stateProvider';
import LogoBadgerIconSVG from '../../../../../assets/icons/BadgerIconSVG';
// import * as types from '../../../../../redux/actions/actionTypes';
// import PatternColors from "../patternColors/patternColors";

class ChromaDrawer extends Component {
  brandLogo = require("../../../../global/ultimateModal/sublimatedProductDetailsModal/CG-Logo_Horizontal_NoBG.png");

  constructor(props) {
    super(props)
    this.state = {
      chosenLayer: null,
      drawerOpen: false
    }
    this.renderAdvanced = this.renderAdvanced.bind(this);
    // this.props.toggleAdvancedMode(this.props.customConfig, stateProvider.get('baseProduct',null))
  }

  getStitchingOptions() {
    let retval = null;
    let options = helpers.product.getUpgrade('stitching');
    let allowed = !!(options && _.get(options, 'enabled', true));
    if (allowed) {
      let customOptions = helpers.locations.getUpgradeOptions('stitching');
      let defaultColorCode = _.get(options, 'defaultColor', null);
      let defaultColor = helpers.colors.getColorFromColorCode(defaultColorCode);
      let selectedColorCode = _.get(customOptions, 'color', null);
      let selectedColor = helpers.colors.getColorFromColorCode(selectedColorCode) || defaultColor;
      let allowedColors = helpers.colors.getColorsFromColorCodes(options.allowedColors);
      retval = { selectedColor, defaultColor, allowedColors, options };
    }
    return retval;
  }

    headerStock(){
      return (
        <div className="card w-100 pr-0 pl-0">
          <div className="row">
            <div className="col-3 pr-0 pt-2 pb-0">
              <div className="design-heading-main">{this.props.baseProduct.legacyStyleNum}</div>
            </div>
            <div className="col-3 pl-0 stock-brand-logo">
              <LogoBadgerIconSVG></LogoBadgerIconSVG>
            </div>
          </div>
          <div className="row">
            <div className="heading-low col-12 pt-1">
                {this.props.baseProduct.name}
            </div>
          </div>
        </div>
      );
    }

    headerSub(){
      return (
        <div className="card w-100 pr-0 pl-0">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6 pr-0 pt-2 pb-0">
              <div className="design-heading-main">{this.props.baseProduct.legacyStyleNum}</div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6 pr-0 pb-0">
              <img src={this.brandLogo} alt="brand logo" className="logo-image"/>
            </div>
          </div>
          <div className="row">
            <div className="heading-low col-12 pt-1">
                {this.props.baseProduct.name}
            </div>
          </div>
        </div>
      );
    }

  renderBasic = () => {
    let title = "Primary Colors";
    let description = "Choose from the available colors shown below";
    return (
      <div className="ChromaDrawer">
        <div>
          <OptionHeading
            title={title}
            description={description} />
          {/*<ChromaColors />*/}
          <NewChromaColors />
          <div className="chroma-colors-spacing-placeholder-2"></div>
          <ChromaPatterns />
        </div>
        <AdvancedBasicButton />
      </div>
    )
  }

  componentWillMount(){
    let baseProduct = stateProvider.get('baseProduct',null)
    //to load the customconfig layers only for the first time
    _.set(this.props.customConfig,"customLayers.primaryColors",baseProduct.customLayers.defaultColors)
    if(this.props.customConfig.customLayers.layers.length === 0 || (this.props.customConfig.customLayers.layers.length !== baseProduct.customLayers.defaultColors.length))
      this.props.toggleAdvancedMode(this.props.customConfig, baseProduct, this.props.launchContext)
  }

  renderAdvanced = () => {
    let title = "Choose your colors";
    let description = "Choose from the available colors shown below";
    // let patternColorsLength = _.get(this.props, "customConfig.customLayers.primaryPattern.colors.length", 0);
    // let patternMDD = (patternColorsLength !== 0) ? (<MultiDropdown label="Pattern Colors" />) : null; 
    return (
      <div className="ChromaDrawer">
        <div className="chromaStyle">
          <div>
              {this.props.baseProduct.configurationType === 'custom-sub' ? this.headerSub() : this.headerStock()}
          </div>
          <OptionHeading title={title} description={description} />
          <NewChromaColors />
          {/*<ChromaColors />*/}
          {/* <ChromaPatterns />
          <div className="chroma-colors-spacing-placeholder-2"></div>
            { patternMDD } */}
          {/* <PatternColors /> */}
        </div>
        {/* <AdvancedBasicButton /> */}
      </div>
    );
  }

  render() {
    let advanced = this.props.customConfig.customLayers.isAdvancedMode;
    // console.log("Advanced:", advanced);
    let stitchingOptions = this.getStitchingOptions();
    let stitchingColors = _.get(stitchingOptions, 'allowedColors', null);
    return (
      <div style={{ height: "100%" }}>
        {advanced ? this.renderAdvanced() : this.renderBasic()}
        {stitchingColors && stitchingColors.length > 0 ? <StitchingColor /> : null}
      </div>
    )
  }
}

const reduxActions = {
  toggleAdvancedMode,
  defaultLayerColors
}

function mapStateToProps(state) {
  return {
    customConfig: state.customConfig,
    toolTips: state.toolTips,
    launchContext: state.launchContext,
    baseProduct: state.baseProduct
  }
}

export default connect(mapStateToProps, reduxActions)(ChromaDrawer);