import _ from "lodash";
import base from "./defaults/defaultLoadedDefs";

class LoadedDefsValidator {
  creator(data, pricing) {
    let loadedDefs = _.get(data, "loadedDefs", {});
    loadedDefs.pricingDefaults = _.get(pricing, "entities", []);
    return _.get(data, "loadedDefs", {});
  }

  validate(loadedDefs) {
    loadedDefs = _.defaultsDeep({}, loadedDefs, base);
    return loadedDefs;
  }

  validateProductColorCode(loadedDefs, colorCode) {
    let retval = null;
    let colorDefs = _.get(loadedDefs, "colors");
    if (colorCode && colorDefs) {
      let tmp = _.find(colorDefs, { code: colorCode });
      if (tmp) {
        retval = colorCode;
      }
    }
    return retval;
  }

  validateProductColorCodes(loadedDefs, colorCodes) {
    let items = _.chain(colorCodes)
      .map(colorCode => this.validateProductColorCode(loadedDefs, colorCode))
      .compact()
      .value();
    if (_.isEmpty(items)) {
      items = null;
    }
    return items;
  }
}

const loadedDefsValidator = new LoadedDefsValidator();
export default loadedDefsValidator;
