export const defaultConfig = {
  "user action that generated issue": "default",
  "sourceKey": "default",
  "source": "default",
  "errorCode": "default",
  "severity": "high",
  "displayLocation": "toast",
  "title": "Error",
  "userMessage": "There was a problem and we are working on the solution!"
};

export default [
  {
    "user action that generated issue": "turning stitching on, off, color, hover",
    "sourceKey": "image_renderer_stiching",
    "source": "product image render API",
    "errorCode": "500",
    "severity": "high",
    "displayLocation": "modal",
    "title": "No Stitching Preview",
    "userMessage": "Preview image for stitching is not available."
},
{
    "user action that generated issue": "turning stitching on, off, color, hover",
    "sourceKey": "image_renderer_stiching",
    "source": "product image render API",
    "errorCode": "400",
    "severity": "high",
    "displayLocation": "modal",
    "title": "No Stitching Preview",
    "userMessage": "Preview image for stitching is not available."
},
{
    "user action that generated issue": "turning stitching on, off, color, hover",
    "sourceKey": "image_renderer_stiching",
    "source": "product image render API",
    "errorCode": "200",
    "severity": "low",
    "displayLocation": "message",
    "title": "No Stitching Preview",
    "userMessage": "Preview image for stitching is not available.",
    "comments": "if non image content-type data is returned"
},
{
    "user action that generated issue": "turning stitching on, off, color, hover",
    "sourceKey": "image_renderer_stiching",
    "source": "product image render API",
    "errorCode": "timeout",
    "severity": "low",
    "displayLocation": "message",
    "title": "No Stitching Preview",
    "userMessage": "Preview image for stitching is not available."
},
{
    "user action that generated issue": "turning stitching on, off, color, hover",
    "sourceKey": "image_renderer_stiching",
    "source": "product image render API",
    "errorCode": "custom",
    "severity": "low",
    "displayLocation": "message",
    "title": "No Stitching Preview",
    "userMessage": "Preview image for stitching is not available.",
    "comments": "For cases where we do not have PSD stiching image data, but stitching is allowed by product data and stitching is turned on.  Show warning message below preview image."
},
{
    "user action that generated issue": "modifying product colors",
    "sourceKey": "image_renderer_product_color",
    "source": "product image render API",
    "errorCode": "500",
    "severity": "high",
    "displayLocation": "modal",
    "title": "No Color Preview",
    "userMessage": "We tried to modify your colors but lost connection to the server. Try again later."
},
{
    "user action that generated issue": "modifying product colors",
    "sourceKey": "image_renderer_product_color",
    "source": "product image render API",
    "errorCode": "400",
    "severity": "high",
    "displayLocation": "toast",
    "title": "No Color Preview",
    "userMessage": "Preview image is not available."
},
{
    "user action that generated issue": "modifying product colors",
    "sourceKey": "image_renderer_product_color",
    "source": "product image render API",
    "errorCode": "200",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "No Color Preview",
    "userMessage": "Preview image is not available."
},
{
    "user action that generated issue": "modifying product colors",
    "sourceKey": "image_renderer_product_color",
    "source": "product image render API",
    "errorCode": "timeout",
    "severity": "medium",
    "displayLocation": "message",
    "title": "No Color Preview",
    "userMessage": "Preview image is not available."
},
{
    "user action that generated issue": "modifying product colors",
    "sourceKey": "image_renderer_product_color",
    "source": "product image render API",
    "errorCode": "custom",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "No Color Preview",
    "userMessage": "Preview image is not available."
},
{
  "user action that generated issue": "modifying product colors",
  "sourceKey": "image_renderer_product_color",
  "source": "product image render API",
  "errorCode": "custom",
  "severity": "medium",
  "displayLocation": "toast",
  "title": "No Color Preview",
  "userMessage": "Preview image is not available."
},
{
    "user action that generated issue": "adding text",
    "sourceKey": "image_renderer_add_text",
    "source": "product image render API",
    "errorCode": "500",
    "severity": "high",
    "displayLocation": "modal",
    "title": "No Text Preview",
    "userMessage": "We tried to modify your text but lost connection to the server. Try again later."
},
{
    "user action that generated issue": "adding text",
    "sourceKey": "image_renderer_add_text",
    "source": "product image render API",
    "errorCode": "400",
    "severity": "high",
    "displayLocation": "modal",
    "title": "No Text Preview",
    "userMessage": "Preview image is not available."
},
{
    "user action that generated issue": "adding text",
    "sourceKey": "image_renderer_add_text",
    "source": "product image render API",
    "errorCode": "200",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "No Text Preview",
    "userMessage": "Preview image is not available."
},
{
    "user action that generated issue": "adding text",
    "sourceKey": "image_renderer_add_text",
    "source": "product image render API",
    "errorCode": "timeout",
    "severity": "medium",
    "displayLocation": "message",
    "title": "No Text Preview",
    "userMessage": "Preview image is not available."
},
{
    "user action that generated issue": "adding text",
    "sourceKey": "image_renderer_add_text",
    "source": "product image render API",
    "errorCode": "custom",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "No Text Preview",
    "userMessage": "Preview image is not available."
},
{
    "user action that generated issue": "modifying text",
    "sourceKey": "image_renderer_modify_text",
    "source": "product image render API",
    "errorCode": "500",
    "severity": "high",
    "displayLocation": "modal",
    "title": "No Text Preview",
    "userMessage": "We tried to modify your text but lost connection to the server. Try again later."
},
{
    "user action that generated issue": "modifying text",
    "sourceKey": "image_renderer_modify_text",
    "source": "product image render API",
    "errorCode": "400",
    "severity": "high",
    "displayLocation": "toast",
    "title": "No Text Preview",
    "userMessage": "Preview text is not available."
},
{
    "user action that generated issue": "modifying text",
    "sourceKey": "image_renderer_modify_text",
    "source": "product image render API",
    "errorCode": "200",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "No Text Preview",
    "userMessage": "Preview text is not available."
},
{
    "user action that generated issue": "modifying text",
    "sourceKey": "image_renderer_modify_text",
    "source": "product image render API",
    "errorCode": "timeout",
    "severity": "medium",
    "displayLocation": "message",
    "title": "No Text Preview",
    "userMessage": "Preview text is not available."
},
{
    "user action that generated issue": "modifying text",
    "sourceKey": "image_renderer_modify_text",
    "source": "product image render API",
    "errorCode": "custom",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "No Text Preview",
    "userMessage": "Preview text is not available."
},
{
    "user action that generated issue": "uploading art",
    "sourceKey": "image_renderer_upload_art",
    "source": "product image render API",
    "errorCode": "500",
    "severity": "high",
    "displayLocation": "modal",
    "title": "No Art Preview",
    "userMessage": "We tried to upload your art but lost connection to the server. Try again later."
},
{
    "user action that generated issue": "uploading art",
    "sourceKey": "image_renderer_upload_art",
    "source": "product image render API",
    "errorCode": "400",
    "severity": "high",
    "displayLocation": "modal",
    "title": "No Art Preview",
    "userMessage": "Preview image is not available."
},
{
    "user action that generated issue": "uploading art",
    "sourceKey": "image_renderer_upload_art",
    "source": "product image render API",
    "errorCode": "200",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "No Art Preview",
    "userMessage": "Preview image is not available."
},
{
    "user action that generated issue": "uploading art",
    "sourceKey": "image_renderer_upload_art",
    "source": "product image render API",
    "errorCode": "timeout",
    "severity": "medium",
    "displayLocation": "message",
    "title": "No Art Preview",
    "userMessage": "Preview image is not available."
},
{
    "user action that generated issue": "uploading art",
    "sourceKey": "image_renderer_upload_art",
    "source": "product image render API",
    "errorCode": "custom",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "No Art Preview",
    "userMessage": "Preview image is not available."
},
{
    "user action that generated issue": "adding art",
    "sourceKey": "image_renderer_add_art",
    "source": "product image render API",
    "errorCode": "500",
    "severity": "high",
    "displayLocation": "modal",
    "title": "No Art Preview",
    "userMessage": "We were unable to update the art on your product because of a server errror. Try again later."
},
{
    "user action that generated issue": "adding art",
    "sourceKey": "image_renderer_add_art",
    "source": "product image render API",
    "errorCode": "400",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "No Art Preview",
    "userMessage": "Art preview image is not available."
},
{
    "user action that generated issue": "adding art",
    "sourceKey": "image_renderer_add_art",
    "source": "product image render API",
    "errorCode": "200",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "No Art Preview",
    "userMessage": "Preview image is not available."
},
{
    "user action that generated issue": "adding art",
    "sourceKey": "image_renderer_add_art",
    "source": "product image render API",
    "errorCode": "timeout",
    "severity": "medium",
    "displayLocation": "message",
    "title": "No Art Preview",
    "userMessage": "Preview image is not available.",
    "comments": "make message more unique"
},
{
    "user action that generated issue": "adding art",
    "sourceKey": "image_renderer_add_art",
    "source": "product image render API",
    "errorCode": "custom",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "No Art Preview",
    "userMessage": "Preview image is not available."
},
{
    "user action that generated issue": "modifying art",
    "sourceKey": "image_renderer_modify_art",
    "source": "product image render API",
    "errorCode": "500",
    "severity": "high",
    "displayLocation": "modal",
    "title": "No Art Preview",
    "userMessage": "We were unable to update the art on your product because of a server errror. Try again later."
},
{
    "user action that generated issue": "modifying art",
    "sourceKey": "image_renderer_modify_art",
    "source": "product image render API",
    "errorCode": "400",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "No Art Preview",
    "userMessage": "Preview image is not available."
},
{
    "user action that generated issue": "modifying art",
    "sourceKey": "image_renderer_modify_art",
    "source": "product image render API",
    "errorCode": "200",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "No Art Preview",
    "userMessage": "Preview image is not available."
},
{
    "user action that generated issue": "modifying art",
    "sourceKey": "image_renderer_modify_art",
    "source": "product image render API",
    "errorCode": "timeout",
    "severity": "medium",
    "displayLocation": "message",
    "title": "No Art Preview",
    "userMessage": "Preview image is not available."
},
{
    "user action that generated issue": "modifying art",
    "sourceKey": "image_renderer_modify_art",
    "source": "product image render API",
    "errorCode": "custom",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "No Art Preview",
    "userMessage": "Preview image is not available."
},
{
    "user action that generated issue": "downloading roster",
    "sourceKey": "image_renderer_download_roster",
    "source": "product image render API",
    "errorCode": "500",
    "severity": "high",
    "displayLocation": "modal",
    "title": "Roster Failure",
    "userMessage": "We were unable to download your roster because of a server error. Try again later."
},
{
    "user action that generated issue": "downloading roster",
    "sourceKey": "image_renderer_download_roster",
    "source": "product image render API",
    "errorCode": "400",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "Roster Failure",
    "userMessage": "We could not download your roster."
},
{
    "user action that generated issue": "downloading roster",
    "sourceKey": "image_renderer_download_roster",
    "source": "product image render API",
    "errorCode": "200",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "Roster Failure",
    "userMessage": "We could not download your roster."
},
{
    "user action that generated issue": "downloading roster",
    "sourceKey": "image_renderer_download_roster",
    "source": "product image render API",
    "errorCode": "timeout",
    "severity": "medium",
    "displayLocation": "message",
    "title": "Roster Failure",
    "userMessage": "We could not download your roster."
},
{
    "user action that generated issue": "downloading roster",
    "sourceKey": "image_renderer_download_roster",
    "source": "product image render API",
    "errorCode": "custom",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "Roster Failure",
    "userMessage": "We could not download your roster."
},
{
    "user action that generated issue": "uploading roster",
    "sourceKey": "image_renderer_upload_roster",
    "source": "product image render API",
    "errorCode": "500",
    "severity": "high",
    "displayLocation": "modal",
    "title": "Roster Failure",
    "userMessage": "We were unable to upload your roster because of a server error. Try again later."
},
{
    "user action that generated issue": "uploading roster",
    "sourceKey": "image_renderer_upload_roster",
    "source": "product image render API",
    "errorCode": "400",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "Roster Failure",
    "userMessage": "We could not upload your roster."
},
{
    "user action that generated issue": "uploading roster",
    "sourceKey": "image_renderer_upload_roster",
    "source": "product image render API",
    "errorCode": "200",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "Roster Failure",
    "userMessage": "We could not upload your roster."
},
{
    "user action that generated issue": "uploading roster",
    "sourceKey": "image_renderer_upload_roster",
    "source": "product image render API",
    "errorCode": "timeout",
    "severity": "medium",
    "displayLocation": "message",
    "title": "Roster Failure",
    "userMessage": "We could not upload your roster."
},
{
    "user action that generated issue": "uploading roster",
    "sourceKey": "image_renderer_upload_roster",
    "source": "product image render API",
    "errorCode": "custom",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "Roster Failure",
    "userMessage": "We could not upload your roster. Make sure your roster is in xls format."
},
{
    "user action that generated issue": "saving roster changes",
    "sourceKey": "image_renderer_save_roster",
    "source": "product image render API",
    "errorCode": "500",
    "severity": "high",
    "displayLocation": "modal",
    "title": "Roster Failure",
    "userMessage": "We were unable to save your roster changes because of a server error. Try again later."
},
{
    "user action that generated issue": "saving roster changes",
    "sourceKey": "image_renderer_save_roster",
    "source": "product image render API",
    "errorCode": "400",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "Roster Failure",
    "userMessage": "We could not save your roster changes."
},
{
    "user action that generated issue": "saving roster changes",
    "sourceKey": "image_renderer_save_roster",
    "source": "product image render API",
    "errorCode": "200",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "Roster Failure",
    "userMessage": "We could not save your roster changes."
},
{
    "user action that generated issue": "saving roster changes",
    "sourceKey": "image_renderer_save_roster",
    "source": "product image render API",
    "errorCode": "timeout",
    "severity": "medium",
    "displayLocation": "message",
    "title": "Roster Failure",
    "userMessage": "We could not save your roster changes because we lost our server connection. Try again later."
},
{
    "user action that generated issue": "saving roster changes",
    "sourceKey": "image_renderer_save_roster",
    "source": "product image render API",
    "errorCode": "custom",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "Roster Failure",
    "userMessage": "We could not save your roster changes."
},
{
    "user action that generated issue": "save",
    "sourceKey": "save",
    "source": "product image render API",
    "errorCode": "500",
    "severity": "high",
    "displayLocation": "modal",
    "title": "Save Failure",
    "userMessage": "We were unable to save your custom design because of a server error. Try again later."
},
{
    "user action that generated issue": "save",
    "sourceKey": "save",
    "source": "product image render API",
    "errorCode": "400",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "Save Failure",
    "userMessage": "We were unable to save your custom design. Try again later."
},
{
    "user action that generated issue": "save",
    "sourceKey": "save",
    "source": "product image render API",
    "errorCode": "200",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "Save Failure",
    "userMessage": "We were unable to save your custom design."
},
{
    "user action that generated issue": "save",
    "sourceKey": "save",
    "source": "product image render API",
    "errorCode": "timeout",
    "severity": "medium",
    "displayLocation": "message",
    "title": "Save Failure",
    "userMessage": "We were unable to save your custom design."
},
{
    "user action that generated issue": "save",
    "sourceKey": "save",
    "source": "product image render API",
    "errorCode": "custom",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "Save Failure",
    "userMessage": "We were unable to save your custom design."
},
{
    "user action that generated issue": "add to cart",
    "sourceKey": "add_to_cart",
    "source": "product image render API",
    "errorCode": "500",
    "severity": "high",
    "displayLocation": "modal",
    "title": "Add To Cart Failure",
    "userMessage": "We were unable to add your custom design to the cart because of a server error. Try again later."
},
{
    "user action that generated issue": "add to cart",
    "sourceKey": "add_to_cart",
    "source": "product image render API",
    "errorCode": "400",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "Add To Cart Failure",
    "userMessage": "We were unable to add your custom design to the cart. Try again later."
},
{
    "user action that generated issue": "add to cart",
    "sourceKey": "add_to_cart",
    "source": "product image render API",
    "errorCode": "200",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "Add To Cart Failure",
    "userMessage": "We could not add your custom design to the cart."
},
{
    "user action that generated issue": "add to cart",
    "sourceKey": "add_to_cart",
    "source": "product image render API",
    "errorCode": "timeout",
    "severity": "medium",
    "displayLocation": "message",
    "title": "Add To Cart Failure",
    "userMessage": "We could not add your custom design to the cart. Try again later."
},
{
    "user action that generated issue": "add to cart",
    "sourceKey": "add_to_cart",
    "source": "product image render API",
    "errorCode": "custom",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "Add To Cart Failure",
    "userMessage": "We could not add your custom design to the cart."
},
{
    "user action that generated issue": "auth",
    "sourceKey": "auth",
    "source": "product image render API",
    "errorCode": "500",
    "severity": "high",
    "displayLocation": "modal",
    "title": "Authorization Failure",
    "userMessage": "We could not load your custom profile. Try again later."
},
{
    "user action that generated issue": "auth",
    "sourceKey": "auth",
    "source": "product image render API",
    "errorCode": "400",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "Authorization Failure",
    "userMessage": "We could not load your custom profile. Try again later."
},
{
    "user action that generated issue": "auth",
    "sourceKey": "auth",
    "source": "product image render API",
    "errorCode": "200",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "Authorization Failure",
    "userMessage": "We could not load your custom profile. Try again later."
},
{
    "user action that generated issue": "auth",
    "sourceKey": "auth",
    "source": "product image render API",
    "errorCode": "timeout",
    "severity": "medium",
    "displayLocation": "message",
    "title": "Authorization Failure",
    "userMessage": "We could not load your custom profile because we lost our server connection. Try again later."
},
{
    "user action that generated issue": "auth",
    "sourceKey": "auth",
    "source": "product image render API",
    "errorCode": "custom",
    "severity": "medium",
    "displayLocation": "toast",
    "title": "Authorization Failure",
    "userMessage": "We could not load your custom profile. Try again later."
},
{
    "user action that generated issue": "initalize",
    "sourceKey": "initalize",
    "source": "app error",
    "errorCode": "500",
    "severity": "high",
    "displayLocation": "modal",
    "title": "Initalization Error",
    "userMessage": "We are having trouble loading the custom design tool. Try again soon!"
},
{
    "user action that generated issue": "initalize",
    "sourceKey": "initalize",
    "source": "app error",
    "errorCode": "custom",
    "severity": "low",
    "displayLocation": "message",
    "title": "Initalization Error",
    "userMessage": "We are having trouble loading the custom design tool. Try on a different browser or make sure js and canvas are enbaled."
},
{
    "user action that generated issue": "general crash",
    "sourceKey": "default",
    "source": "app error",
    "errorCode": "custom",
    "severity": "high",
    "displayLocation": "modal",
    "title": "General Error",
    "userMessage": "Something broke. We are working on a solution!"
},
{
  "user action that generated issue": "user is trying to add a customization to a view that cannot accept the entity",
  "sourceKey": "entity_not_allowed",
  "source": "issue modification warning",
  "errorCode": "custom",
  "severity": "high",
  "displayLocation": "toast",
  "title": "Not Allowed",
  "userMessage": "Cannot customize this view. Try another."
}
];
