import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { colorModeModal } from '../../../../../redux/reducers/modal/modalActions';
import { toggleAdvancedMode, defaultLayerColors } from '../../../../../redux/reducers/customConfig/customConfigActions';
import PaintBucketSVG from "../../../../../assets/icons/PaintBucketSVG";

class AdvancedBasicButton extends Component {

  toggleDrawer = colorMode => {
    let {customConfig, baseProduct} = this.props;
    if (colorMode) {
      this.props.colorModeModal();
    } else {
      this.props.toggleAdvancedMode(customConfig, baseProduct);
    }
  }
  
  render(){
    let colorMode = _.get(this.props, "customConfig.customLayers.isAdvancedMode");
    let buttonText = colorMode ? "Primary" : "Advanced";
    let primaryToolTip = _.find(this.props.toolTips, { key: "primary-color-mode" }).message;
    let advancedToolTip = _.find(this.props.toolTips, { key: "advanced-color-mode" }).message;

    return(
      <div aria-label={colorMode ? primaryToolTip : advancedToolTip} data-balloon-pos="up" className="AdvancedBasicButton add-note-button" onClick={() => this.toggleDrawer(colorMode)}>
        <PaintBucketSVG width="20" height="20" />
        <span>Select {buttonText} Colors</span>
      </div>
    )
  }
}

const reduxActions = {
  colorModeModal,
  toggleAdvancedMode,
  defaultLayerColors
}

function mapStateToProps(state){
  return{
    customConfig: state.customConfig,
    baseProduct: state.baseProduct,
    toolTips: state.toolTips
  }
}

export default connect(mapStateToProps, reduxActions)(AdvancedBasicButton);