import stateProvider from '../../../helpers/stateProvider';
import helpers from '../../../helpers';
import _ from 'lodash';
import { fabric } from 'fabric';


const ANIM_DURATION_MS = 150;
const DRAG_BORDER_OFFSET = 1;


const LocationBoxStyles = {

  initial: {
    fill: 'transparent',
    stroke: 'rgb(158, 255, 153)',
    strokeWidth: 1.5,
    strokeDashArray: [2, 2],
    opacity: 1,
    $selectable: true,
    shadow: {   // add a subtle drop shadow around location box borders with slight contrast to border colors to make it easier to see when underlyng product image is nearly the same color.
      color: 'rgba(0,0,0,0.2)',
      offsetX: 1,
      offsetY: 1,
      blur: 1.5,
    },
  },

  hidden: {
    fill: 'transparent',
    stroke: 'transparent',
    shadow: false,
    opacity: 0,
    $selectable: false,	  // NOTE: special $selected attribute which indicates that this item can not be selected. used by mouse click event handler.
  },

  canAccept: {
    stroke: 'rgb(158, 255, 153)',
  },
  canReplace: {
    stroke: 'transparent',
  },
  notAllowed: {
    stroke:  'transparent',
    $selectable: false,    // NOTE: special $selected attribute which indicates that this item can not be selected. used by mouse click event handler.
  },

  selected: {
    stroke: 'rgb(158, 255, 153)',
    fill: 'rgba(25, 205, 85, 0.25)'
  },
  neutral: {
    stroke: 'transparent'
  },

  dragOn: {
    strokeDashArray: [0, 0]
  },
  dragOff: {
    strokeDashArray: [2, 2]
  },

  // used when dragging over an item
  dragTargetAccepted: {
    fill: 'rgba(25, 205, 85, 0.25)',
    stroke: 'rgba(25, 205, 85, 1)',
    strokeDashArray: [0, 0],
  },
  dragTargetReplace: {
    fill: 'rgba(190, 200, 200, 0.5)',
    stroke: 'rgba(190, 200, 200, 1)',
    strokeDashArray: [0, 0],
  },
  dragTargetNotAllowed: {
    fill: 'rgba(180, 15, 15, 0.45)',
    stroke:  'rgba(180, 15, 15, 1)',
    strokeDashArray: [0, 0],
  },

};

export default class LocationBoxHelper {

  // state helpers
  getStateVal(key, defaultValue = null) {
    return stateProvider.get(key, defaultValue);
  };
  mount(canvas, view, setActiveLocation, addPendingLocationToLocations, setActiveTab, previewSample, locationUsed, dragItem, showBorders = false, setCustomTextInput, setPendingLocation, props, customConfig={}, updateLocationsBound) {
   
    let baseProduct = this.getStateVal('baseProduct');
    let allCanvasLocations = canvas.getObjects();
    let filterCanvasLocations = _.filter(allCanvasLocations,  (x)=> { if ( x.id.includes("location")) {return x;} });
    _.forEach(filterCanvasLocations, (x)=>{
      canvas.remove(x);
    });
    let locations = _.filter(baseProduct.locations, {view: view.key || view.view});
    _.forEach(locations, (locationDetails)=>{
      //create location box with border

      let viewImageDetails = _.find(baseProduct.views, {key:locationDetails.view});
      // zzz 600 is hard coded should be image size that we get from api works for now because request size is hard coded. relation: navigationActions.js
      let scale = 600 / viewImageDetails.masterImageSize.h;
      let style = _.extend({}, LocationBoxStyles.initial);
      if (!showBorders) { style = _.extend(style, LocationBoxStyles.hidden); }
 
       let rect = new fabric.Rect({
        id: `location-${locationDetails.code}`,
        left: locationDetails.bounds.x * scale,
        top: locationDetails.bounds.y * scale,
        // top:  customConfig &&  customConfig.locations && (condition.length > 0 || conditionLB.length>0) && filterLocation.length >0 && locationDetails.groupName == filterLocation[0].groupName ?
        // (((locationDetails.view === 'back' && locationDetails.displayOrder == 2) || (locationDetails.view === 'front' && locationDetails.displayOrder ==4) && condition.length > 0) ? ( locationDetails.bounds.h + 270)*scale :(locationDetails.view === 'front' && locationDetails.displayOrder ==7 ) ? (upperBackBound* scale) : locationDetails.bounds.y * scale) : locationDetails.bounds.y * scale,
        width: locationDetails.bounds.w * scale,
        height:  locationDetails.bounds.h *  scale,
        centeredRotation: true,
        selectable: false,
        ...style,
      });
      // add events onto location boxes , handleDragLeave, handleDrop
      rect.rotate(locationDetails.bounds.r);
      rect.on("dragenter", (e)=>this.handleDragEnter(e, canvas, setPendingLocation));
      rect.on("dragleave", (e)=>this.handleDragLeave(e, canvas, setPendingLocation));
      rect.on("drop", (e)=>this.handleDrop(e, canvas, setActiveLocation, addPendingLocationToLocations, setActiveTab, previewSample, locationUsed, dragItem, setCustomTextInput, setPendingLocation, props, updateLocationsBound));
      canvas.add(rect);
    });
  };

  style(canvas, showBorders) {
    let baseProduct = this.getStateVal('baseProduct');
    let customConfig = this.getStateVal('customConfig');
    let decoration = this.getStateVal('decoration');
    let pendingLocation = this.getStateVal('navigation.pendingLocation');
    let activeLocation = this.getStateVal('navigation.activeLocation');
    let drag = this.getStateVal('navigation.drag');
    let toggleBorders = this.getStateVal('navigation.borders', true);
    //let toggleBorders = false;
    let canvasLocationBox = _.filter(canvas.getObjects(),(x) => {if(x.id.includes("location")) {return x}});

    _.forEach(canvasLocationBox , (location) => {

      let locationCode = location.id.split("-")[1];
      let locationDetails = _.find(baseProduct.locations, {code: locationCode});
      let allowedEntities= [];
      let content = helpers.locations.getLocationContent(locationCode);

      // if showBorders is false, hide the locationBox borders.
      if (!showBorders || !toggleBorders) {
        location.set(LocationBoxStyles.hidden);
        return;
      }

      if (locationDetails) {
        allowedEntities =  _.map(locationDetails.allowedEntities, (x)=>x.type);
      }

      let style = _.extend({}, LocationBoxStyles.initial, LocationBoxStyles.neutral);
      // if this location is active, indicate it is selected
      if (locationCode === activeLocation) {
        style = _.extend(style, LocationBoxStyles.selected);
      }

      // if I have pending entity data
      if (pendingLocation) {
        // Type Selected //
        if (_.includes(allowedEntities, pendingLocation.entity.type)) {
          if (content) {
            style = _.extend(style, LocationBoxStyles.canReplace);
          } else {
            style = _.extend(style, LocationBoxStyles.canAccept);
            location.set(LocationBoxStyles.hidden);
          }

          // if this location is active, indicate it is selected
	      if (locationCode === activeLocation) {
	        style = _.extend(style, LocationBoxStyles.selected);
	      }

        } else {
          // selected is not allowed in this case
          style = _.extend(style, LocationBoxStyles.notAllowed)
          location.set(LocationBoxStyles.notAllowed);
          return;
        }
      }


      if (drag) {
        style = _.extend(style, LocationBoxStyles.dragOn);
      } else {
        style = _.extend(style, LocationBoxStyles.dragOff);
      }

      location.set(style);
      let activeLocationDetails = _.find(customConfig.locations, {code: activeLocation});
      let activeLocationEntityType = activeLocationDetails && activeLocationDetails.entity.type;
      if(activeLocationDetails){
      if(allowedEntities.includes(activeLocationEntityType)){
        location.set(LocationBoxStyles.canAccept)
      }else{
        location.set(LocationBoxStyles.notAllowed)
      }
    }
    });
    canvas.renderAll();
  };

  handleDragEnter(e, canvas, setPendingLocation) {
    let location = e.target;
    let locationCode = location.id.split("-")[1];
    let baseProduct = this.getStateVal('baseProduct');
    let locationDetails = _.find(baseProduct.locations, {code: locationCode});
    let pendingLocation = this.getStateVal('navigation.pendingLocation');

    let style = _.cloneDeep(LocationBoxStyles.initial);

    if(pendingLocation){
      let content = helpers.locations.getLocationContent(locationCode);

      let allowedEntities =  _.map(locationDetails && locationDetails.allowedEntities, (x)=>x.type);
      let entityType = pendingLocation && pendingLocation.entity.type;
      if (allowedEntities && entityType && _.includes(allowedEntities, entityType)) {
        if (content) {
          // we can replace this target
          style = _.extend(style, LocationBoxStyles.dragTargetReplace);
        } else {
          // we can drop into this target and location is empty
          style = _.extend(style, LocationBoxStyles.dragTargetAccepted);
        }
        pendingLocation.code = locationCode;
        setPendingLocation( pendingLocation );
        // check to see if item is a desktop item / file / folder.
        let desktopFile = _.find(_.get(e, 'e.dataTransfer.items'), (x) => {
          return (x && x.kind === "file");
        });
        if (desktopFile) {
          // we can NOT drop into this target
          style = _.extend(style, LocationBoxStyles.dragTargetNotAllowed);
        }

        let rect = new fabric.Rect({
          id: `trash-${locationCode}`,
          left: location.left - DRAG_BORDER_OFFSET,
          top: location.top - DRAG_BORDER_OFFSET,
          width: location.width + 2*DRAG_BORDER_OFFSET,
          height:  location.height + 2*DRAG_BORDER_OFFSET,
          centeredRotation: true,
          angle: locationDetails.bounds.r,
          selectable: false,
          evented: false,
          ...style,
          opacity: 0  // set to 0 for fade-in anim
        });
        rect.animate('opacity', 1, {
          duration: ANIM_DURATION_MS,
          onChange: canvas.renderAll.bind(canvas),
        });
        canvas.add(rect);
        canvas.renderAll();
      } else {
        // we can NOT drop into this target
        style = _.extend(style, LocationBoxStyles.dragTargetNotAllowed);
      }
      let rect = new fabric.Rect({
        id: `trash-${locationCode}`,
        left: location.left - DRAG_BORDER_OFFSET,
        top: location.top - DRAG_BORDER_OFFSET,
        width: location.width + 2*DRAG_BORDER_OFFSET,
        height:  location.height + 2*DRAG_BORDER_OFFSET,
        centeredRotation: true,
        angle: _.get(locationDetails, 'bounds.r', 0),
        selectable: false,
        evented: false,
        ...style,
        opacity: 0  // set to 0 for fade-in anim
      });
      rect.animate('opacity', 1, {
        duration: ANIM_DURATION_MS,
        onChange: canvas.renderAll.bind(canvas),
      });
      canvas.add(rect);
      canvas.renderAll();
    }
  };

  handleDragLeave(e, canvas, setPendingLocation) {
    let pendingLocation = this.getStateVal('navigation.pendingLocation');
    let activeLocation = this.getStateVal('navigation.activeLocation');
    if(pendingLocation!=null){
      pendingLocation.code = activeLocation;
      setPendingLocation( pendingLocation );
    }

    let location = e.target;
    let locationCode = location.id.split("-")[1];
    let canvasLocationObjs = _.filter(canvas.getObjects(), (x)=>{if(x.id === `trash-${locationCode}`) {return x}});
    _.forEach(canvasLocationObjs, (canvasLocationObj)=>{
      if (canvasLocationObj) {
        canvasLocationObj.animate('opacity', 0 , {
          duration: ANIM_DURATION_MS,
          onChange: canvas.renderAll.bind(canvas),
          onComplete: ()=>canvas.remove(canvasLocationObj)
        });
      }
    });
  }

  handleDrop(e, canvas, setActiveLocation, addPendingLocationToLocations, setActiveTab, previewSample, locationUsed, dragItem, setCustomTextInput, setPendingLocation, props, updateLocationsBound) {
    // TODO: prevent default is needed for Firefox to not open another window , but then cancels update and refresh of image?
    if (e && e.e && e.e.preventDefault) { e.e.preventDefault(); }

    let location = e.target;
    let locationCode = location.id.split("-")[1];
    //----- styles start ------//
    let canvasLocationObjs = _.filter(canvas.getObjects(), (x)=>{if(x.id === `trash-${locationCode}`) {return x}});
    _.forEach(canvasLocationObjs, (canvasLocationObj)=>{
      if (canvasLocationObj) {
        canvasLocationObj.animate('opacity', 0 , {
          duration: ANIM_DURATION_MS,
          onChange: canvas.renderAll.bind(canvas),
          onComplete: ()=>canvas.remove(canvasLocationObj)
        });
      }
    });
    //----- styles end ------//
    //----- drop functionality start ------//
    let baseProduct = this.getStateVal('baseProduct');
    let navigation = this.getStateVal('navigation');
    let customConfig = this.getStateVal('customConfig');
    let locationDetails = _.find(baseProduct.locations, {code: locationCode});
    let pendingLocation = this.getStateVal('navigation.pendingLocation');
    let pendingEntityType = pendingLocation && pendingLocation.entity.type;
    let content = helpers.locations.getLocationContent(locationCode);
    let allowedEntities =  _.map(locationDetails.allowedEntities, (x)=>x.type);

    //////////////////drag and drop /////////////////
    dragItem(false);

    let desktopFile = _.find(_.get(e, 'e.dataTransfer.items'), (x) => {
      return (x && x.kind === "file");
    });
    if (desktopFile) {
      // if this is true it is a desktop file or folder cancel drop function.
      return;
    }

    if ( !content ) {
      // location is empty
      if (allowedEntities && pendingEntityType && _.includes(allowedEntities, pendingEntityType)) {
        let type = pendingLocation.entity.type;
        let value = pendingLocation.entity.value;
        let view = _.find( baseProduct.locations, {code: locationCode }).view;
        let code = locationCode;
        let artAsset = null;
        if ( pendingLocation.entity.type === "custom-art"){
          artAsset = _.cloneDeep(pendingLocation.entity);
          artAsset.id = artAsset.userArtId;
        }
        let newPendingLocation = helpers.navigation.makePendingLocation( type, value, view, code, artAsset);
        let groupName= props.baseProduct.locations.filter(v=>v.code===newPendingLocation.code);
        props.setPendingLocation( newPendingLocation );
        addPendingLocationToLocations( customConfig.locations,  newPendingLocation, groupName );
        updateLocationsBound();
        setActiveLocation(locationCode, navigation);
        let tabObj = {tab: navigation.activeTab.tab, page:"sub-edit"};
        setActiveTab(tabObj);
        setCustomTextInput("");
        setPendingLocation(null);
      } else {
        // Location can not accept entity type.
      }

    } else {
      // location has content
      if (pendingLocation) {
        previewSample( baseProduct, locationCode, null, pendingLocation);
        if (_.includes(allowedEntities, pendingLocation.entity.type)) {
          // Location can accept entity type.
          let sourceLocation = pendingLocation;
          let targetLocation = content;
          locationUsed( sourceLocation, targetLocation );
        } else {
          // Location can not accept entity type.
        }
      }
    }
    //----- drop functionality end ------//
    canvas.renderAll();
  }
}

