import {combineReducers} from "redux";
import actions from "./reducers/appActions/appActionsReducer";
import baseProduct from "./reducers/baseProduct/baseProductReducer";
import launchContext from "./reducers/launchContext/launchContextReducer"; 
import loadedDefs from "./reducers/loadedDefs/loadedDefsReducer"; 
import customConfig from "./reducers/customConfig/customConfigReducer"; 
import modal from "./reducers/modal/modalReducer"; 
import changeDesignColor from "./reducers/change/changeReducer";
import decoration from "./reducers/decoration/decorationReducer"
import toast from "./reducers/toast/toastReducer"; 
import hover from "./reducers/hover/hoverReducer"; 
import user from "./reducers/user/userReducer"; 
import navigation from "./reducers/navigation/navigationReducer";
import notifications from "./reducers/notifications/notificationsReducer"
import issues from "./reducers/issues/issuesReducer";
import toolTips from "./reducers/toolTips/toolTipsReducer";
import systemArt from "./reducers/systemArt/systemArtReducer"; 


export default combineReducers({
  modal,
  changeDesignColor,
  decoration, 
  toast,
  user,
  hover,
  customConfig,
  loadedDefs,
  baseProduct, 
  navigation,
  notifications,
  actions,
  launchContext,
  issues,
  toolTips,
  systemArt
});
