import React from "react";
import PropTypes from 'prop-types';
import BaseInputComponent from "../baseInputComponent/baseInputComponent";
import NewColorSwatch from "./NewColorSwatch";
import { Tooltip } from "reactstrap";
import _ from 'lodash';



export default class NewColorSwatchGroup extends BaseInputComponent {

    // NOTE: if you extend propTypes in child component class, you will get a react warning.
    static get propTypes() {
        return {
            // eslint-disable-next-line
            ...super.propTypes,
            colorOptions: PropTypes.array
        }
    };

    static get defaultProps() {
        return {
            ...super.defaultProps,
            colorOptions: [],
        }
    };

    constructor(props) {

        super(props);

        this.onSwatchSelected = this.onSwatchSelected.bind(this);
        this.onSwatchHovered = this.onSwatchHovered.bind(this);
        this.onSwatchHoveredDebounced = _.debounce(this.onSwatchHovered.bind(this), 50);

        this.state = {
            ...this.state,
        };
    }

    get selectedColorCode() {
        return _.get(this.state.value, 'code', null);
    }

    onSwatchSelected(color) {
        this.setValue(color, true);
    }

    onSwatchHovered(color) {
        this.setHover(color, true);
    }

    //todo remove onswatch leave, may be deprecated - logan
    onSwatchLeave() {
        this.setHover(null, true);
    }

    checkImportantColors(availableColors) {
        const colorsForImportant = ["PU", "PK", "SC", "MA", "OR", "LG", "VG", "KE", "DG", "BK"];

        availableColors.find(v => {
            if (colorsForImportant.includes(v.code))
                v.isImportant = true;
            else
                v.isImportant = false;
        });

        return availableColors;
    }

    sortColors(colors) {
        for (var c = 0; c < colors.length; c++) {
            if (colors[c].hex && colors[c].hex !== null) {
                /* Get the hex value without hash symbol. */
                var hex = colors[c].hex.substring(1);

                /* Get the RGB values to calculate the Hue. */
                var r = parseInt(hex.substring(0,2),16)/255;
                var g = parseInt(hex.substring(2,4),16)/255;
                var b = parseInt(hex.substring(4,6),16)/255;

                /* Getting the Max and Min values for Chroma. */
                var max = Math.max.apply(Math, [r,g,b]);
                var min = Math.min.apply(Math, [r,g,b]);

                /* Variables for HSV value of hex color. */
                var chr = max-min;
                var hue = 0;
                var val = max;
                var sat = 0;

                if (val > 0) {
                    /* Calculate Saturation only if Value isn't 0. */
                    sat = chr/val;
                    if (sat > 0) {
                        if (r == max) {
                            hue = 60*(((g-min)-(b-min))/chr);
                            if (hue < 0) {hue += 360;}
                        } else if (g == max) {
                            hue = 120+60*(((b-min)-(r-min))/chr);
                        } else if (b == max) {
                            hue = 240+60*(((r-min)-(g-min))/chr);
                        }
                    }
                }

                /* Modifies existing objects by adding HSV values. */
                colors[c].hue = hue;
                colors[c].sat = sat;
                colors[c].val = val;
            } else {
                colors[c].hue = 0;
            }
        }

        /* Sort by Hue. */
        return colors.sort(function(a,b){return a.hue - b.hue;});
    }

    renderChildComponent() {
        let colorOptions = this.props.colorOptions || [];
        let selectedColorDef = this.state.value;

        colorOptions = this.checkImportantColors(colorOptions);
        colorOptions = this.sortColors(colorOptions);

        //let sortColors = this.sortColors(colorOptions);
        //console.log("SortColors:", sortColors);

        return (
            <div className="color-swatch-group">
                {
                    colorOptions.map(color => {
                        // console.log("Color:", color);
                        if (color) {
                            let elemId = (this.props.type || 'csgi') + '-color-' + _.get(color, 'key', 'none') + '-' + (this.props.dropdownName || 'dd');
                            let isSelected = (color === selectedColorDef);

                            let isDisabled = this.props.isDisabled;
                            let colorName = _.get(color, 'name', 'None');
                            colorName = colorName.toUpperCase();
                            let classes = !isDisabled? 'clickable': '';
                            if (isSelected)
                                classes += ' selected';

                            let style1 = {};
                            style1.fontSize = '10px';
                            style1.textAlign = 'Center';
                            if (isSelected)
                                style1.color = '#ffffff';
                            else
                                style1.color = '#2e2e2e';
                            style1.fontFamily = 'Forza, Medium';
                            style1.width = '60px';
                            style1.whiteSpace = 'normal';
                            style1.wordBreak = 'break-word';
                            style1.display = 'block';

                            let style = {};
                            //style.border = '1px, black';

                            return (
                                <div id={elemId} key={elemId} className={`color-swatch-group-item ${classes}`}
                                     onClick={() => this.onSwatchSelected(color)}
                                     onMouseEnter={() => this.onSwatchHoveredDebounced(color)}
                                     onMouseLeave={() => this.onSwatchHoveredDebounced(null)}
                                >
                                    <NewColorSwatch
                                        value={color}
                                        isSelected={isSelected}
                                        isDisabled={isDisabled}
                                        inlinePadding={1}
                                    />
                                    <div style={style1}>{colorName}</div>
                                    {/*<Tooltip target={elemId} placement="top" delay={{ hide: 50 }}>
                                        Select {colorName}
                                    </Tooltip>*/}
                                </div>
                            );
                        } else { return null}
                    })
                }
            </div>
        );
    }
}

