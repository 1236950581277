import React from "react";

const LoadingSVG =(props)=>{
  const { className, height=32, width=32, fill="black", stroke = "black", strokeWidth = "0", onClick } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" className={className} height={height} width={width} fill={fill} stroke={stroke} strokeWidth={strokeWidth} onClick={onClick}>
      <path fill={fill} d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">
        <animateTransform attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 25 25"
          to="360 25 25"
          dur="0.6s"
          repeatCount="indefinite"/>
      </path>
    </svg>
  );
};

export default LoadingSVG;
