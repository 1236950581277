import React, { Component } from "react";
import { connect } from "react-redux";
import { messageIframe } from "../../redux/reducers/notifications/notificationsActions"
import Title from "./titleAndDescription/titleAndDescription";
import CustomerService from "./customerService/customerService";
import AddToCartButton from "./mobileHeaderActions/addToCartButton";
import SaveButton from "./mobileHeaderActions/saveButton";
import appConfig from "../../config";
import Navigation from '../sidebar/navigation/navigation.js';
import _ from "lodash";
// import InfoSVG from "../../assets/icons/InfoSVG";
import { infoModal } from "../../redux/reducers/modal/modalActions"

class Header extends Component {

  backClicked(e) {
    e.preventDefault();
    this.props.messageIframe('cancel', null);
  }

  // handleInfoClick=()=>{
  //   this.props.infoModal()
  // }
  designHeader() {
    const baseProduct = _.get(this.props, 'baseProduct') || {};
    const brand = _.get(baseProduct, 'brand') || {};
    let desc = _.get(baseProduct, "description");
    return (
      <Title
        styleNum={baseProduct.styleNum}
        legacyStyleNum={baseProduct.legacyStyleNum}
        brandLogoSrc={baseProduct.logoUrlSm}
        brandKey={brand.key}
        brandName={brand.name}
        desc={desc}
        productName={baseProduct.name}
      />
    );
  }

  masterDesignHeader() {
    const baseProduct = _.get(this.props, 'baseProduct') || {};
    const brand = _.get(baseProduct, 'brand') || {};
    let desc = _.get(baseProduct, "description");
    let masterDesign = "MASTER DESIGN";
    return (
      <Title
        styleNum={baseProduct.styleNum}
        legacyStyleNum={baseProduct.legacyStyleNum}
        brandLogoSrc={baseProduct.logoUrlSm}
        brandKey={brand.key}
        brandName={brand.name}
        desc={desc}
        productName={`${baseProduct.name} - ${masterDesign}`}
      />
    );
  }
  
  showAddToCartButton() {
    return (
      <div>
        <AddToCartButton mobile={true} />
      </div>

    );
  }

  render() {
    let toolTip = _.find(this.props.toolTips, { key: "back-to-products-button" }).message;
    // let customerServiceTooltip = _.find(this.props.toolTips, { key: "customer-service" }).message;
    return (
      <div className="header">
        <a onClick={(e) => this.backClicked(e)} href="/" className="back-to-products-button" title="Back to Products" aria-label="Back to Products">
          <svg className="header__chevron--left" width="18" height="18" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1427 301L896 832l531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19L429 877q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z" /></svg>
          <span title="" aria-label={toolTip} data-balloon-pos="right">Back to Products</span>
        </a>
        <div className="header__main p-2">
          <Navigation />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    baseProduct: state.baseProduct,
    toolTips: state.toolTips,
    launchContext: state.launchContext
  };
}

const reduxActions = {
  messageIframe,
  infoModal
}

export default connect(mapStateToProps, reduxActions)(Header);
