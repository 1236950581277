import authValidator from './authValidator';
import baseProductValidator from './baseProductValidator';
import customConfigValidator from './customConfigValidatior';
import userValidator from './userValidator';
import loadedDefsValidator from './loadedDefsValidator'; 
import navigationValidator from './navigationValidator'; 


// Notice: these helpers should treat the redux state as READ-ONLY.
// they should not manipulate or write to the redux state.
// Doing so would cause redux state update thrashing.
// they are intended to interpret and parse the redux state in a read only manner.
// all helper objects should already be a singleton instance.


export default {
  auth: authValidator,
  baseProduct: baseProductValidator,
  customConfig: customConfigValidator,
  user: userValidator,
  loadedDefs : loadedDefsValidator, 
  nav: navigationValidator
};
