import React, { Component } from "react"; 
import LoadingSVG from "../../../assets/icons/LoadingSVG"; 

export default class LoadingPage extends Component {
  render() {
    return (
      <div className="loading-page fade-in">
        <div className="loading-content">
          <div className="loading-message">
            One moment while we get things ready for you!
          </div>
          <div className="loading-spinner">
            <LoadingSVG height="120" width="120" fill={"black"} />
          </div>
        </div>
      </div>
    );
  }
}
