import { RestService } from '../lib';


/**
 * Service Endpoint Implementation
 */
export default class CustomProductAssetsService extends RestService {

  constructor(opts = null) {
    super(opts);
    this.serviceName = 'customProductAssets';
    this.routePath = 'custom-product-assets';
  }

}


