import React from "react";

const LogoAllesonIconSVG =(props)=>{
  const { className, height=32, width=32, fill="black", fill2="#e52649", fill3="white", stroke = "black", strokeWidth = "0", onClick } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" className={className} height={height} width={width} fill={fill} fill2={fill2} fill3={fill3} stroke={stroke} strokeWidth={strokeWidth} onClick={onClick}>
      <g id="LogoAllesonIcon">
        <path id="Path_449" d="M77.2 34.4L49.9 64H1.5l38.4 11.7L33 84l10.7 8.9L64 77.6l10.7 16 11.9-8.4-1.2-7.5 41.2-13.4-43.4.2-6-30.1z" fill={fill3}/>
        <path id="Path_450" d="M70 70.7l9.4-4.1-9 .2-.1-6.6-5.1 6.4h-9.4l5.8 3.7-5.7 6.7 8.7-4.1 5.8 4.1-.4-6.3z" fill={fill2}/>
        <path id="Path_451" d="M12.1 65.5h38l8.1 5.2-9.1 11.6 15.3-6.9-20.7 15.4-8.5-7.1 7.5-8.9-30.6-9.3z"/>
        <path id="Path_452" d="M86.6 66.1l-14.2 6.1.4 9.4-8.5-6.2 10.7 16 9.8-6.9-1.3-7.9 33.7-10.8-30.6.3z"/>
        <path id="Path_453" d="M51.5 64.5h12.4l8.2-9.9.6 9.9h8.7l-5.1-27-24.8 27z" fill={fill2}/>
      </g>
    </svg>
  );
};

export default LogoAllesonIconSVG;