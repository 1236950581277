import * as types from '../../actions/actionTypes';

export default function baseProductReducer(state = null, action) {
  let newState = null;
  switch (action.type) {
    case types.SET_BASE_PRODUCT:
      newState = Object.assign({}, action.baseProduct);
      break;

    default:
      return state;
  }

  return newState;
}
