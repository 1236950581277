import React from 'react';
import PropTypes from 'prop-types';
import BaseInputComponent from '../baseInputComponent/baseInputComponent';
import CheckmarkSVG from '../../../assets/icons/CheckmarkSVG';
import { connect } from 'react-redux';
import { clearHover } from '../../../redux/reducers/hover/hoverActions';
import helpers from '../../../helpers';
import _ from 'lodash';
import SlashLeftSVG from "../../../assets/icons/SlashLeftSVG";


const SLASH_COLOR = '#fe0000';
const DISABLED_COLOR = '#e2e2e2';
const MISSING_COLOR = null; // '#c1ff93';



class ColorSwatch extends BaseInputComponent {

  // NOTE: if you extend propTypes in child component class, you will get a react warning.
  static get propTypes() {
    return {
      // eslint-disable-next-line
      ...super.propTypes,
      className: PropTypes.string,
      onClick: PropTypes.func,
      inlinePadding: PropTypes.number
    }
  };

  static get defaultProps() {
    return {
      ...super.defaultProps,
      onClick: _.noop,
      inlinePadding: 0
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      // initialValue: props.chosenColorObject     // TODO: $DATA_CLEANUP$  use BaseInputComponent value. remove initialValue. remove chosenColorObject
    };

    this.onButtonClick = this.onButtonClick.bind(this);
    this.onButtonHover = this.onButtonHover.bind(this);
    this.onButtonHoverLeave = this.onButtonHoverLeave.bind(this);
    this.setHoverDebounced = _.debounce(this.setHover.bind(this), 50);
  }

  onButtonClick() {
    this.setValue(this.state.value, true);
    this.props.onClick(this.state.value);
  }

  onButtonHover() {
    this.setHoverDebounced(this.state.value);
  }

  onButtonHoverLeave() {
    this.setHoverDebounced(null);
  }

  renderChildComponent() {

    // we need to be able to render a hex rgb color and a swatch image url
    // add ability to toggle between rendering a color in the button and rendering an svg?
    let color = this.state.value;
    let isLightColor = helpers.colors.isLightColor(color);

    let classes = 'color-swatch btn-color-swatch';

    if (this.hasChangeOrHoverListener()) {
      classes += ' clickable';
    }

    let isColorNone = (!color || color.key === "none");

    let innerSwatches = null;
    let childColorCodes = _.split(color && color.code, '/');
    let isMultiColor = (_.size(childColorCodes) >= 2);
    if (isMultiColor) {
      // render multi color child swatches
      childColorCodes = _.slice(childColorCodes, 0, 4); // enforce max limit of 4 color swatches
      let childColorDefs = _.map(childColorCodes, v => { return helpers.colors.getColor(v); });
      innerSwatches = _.map(childColorDefs, this.renderInnerSwatch.bind(this));
    } else {
      // render single color swatch
      innerSwatches = [this.renderInnerSwatch(color)];
    }

    if (isLightColor) {
      classes += ' light-color';
    }

    if (isMultiColor) {
      classes += ' multi-color multi-color-' + _.size(childColorCodes);
    }

    let showSelectedCheckMark = (this.props.isSelected && !this.props.isDisabled);
    if (showSelectedCheckMark) {
      classes += ' selected';
    }
    if (this.props.isDisabled) {
      classes += ' disabled';
    }

    let customWidth;
    if (this.props.navigation.activeTab.tab === "colors") {
      if (this.props.customStyle) {
        customWidth = "75px"
      }
    } else {
      if (this.props.customStyle) {
        customWidth = "34px"
      }
    }

    return (
      <div
        className={classes}
        style={{ margin: this.props.inlinePadding, width: customWidth }}
        disabled={this.props.isDisabled}
        onClick={this.onButtonClick}
        onMouseOver={this.onButtonHover}
        onMouseLeave={this.onButtonHoverLeave}
        title={this.props.value ? this.props.value.name : "none"}
      >
        { innerSwatches}
        { showSelectedCheckMark ? this.renderSelectedCheckmark() : null}
        { isColorNone ? this.renderColorNone() : null}
      </div>
    );
  }

  renderInnerSwatch(colorDef, swatchIdx = 0) {

    let colorHex = null;
    if (this.props.isDisabled) {
      colorHex = DISABLED_COLOR;
    } else if (colorDef) {
      if (colorDef.rgb === null || colorDef.rgb === undefined) {
        colorHex = MISSING_COLOR;
      } else {
        colorHex = helpers.colors.toHexString(colorDef.rgb);
      }
    } else {
      colorHex = MISSING_COLOR;
    }

    let style = {};
    if (colorHex) {
      style.backgroundColor = colorHex;
    }

    let className = `color-swatch-icon ${this.props.className}`;
    return (
      <div key={'idx-' + swatchIdx} className={className} style={style}>&nbsp;</div>
    );
  }

  renderColorNone() {
    return (
      <div className="color-none-icon">
        <SlashLeftSVG className="fillxy slash-svg" fill={SLASH_COLOR} height="25" width="15" />
      </div>
    );
  }

  renderSelectedCheckmark() {
    return (
      <div className="selected-checkmark">
        <CheckmarkSVG
          height="20"
          width="20"
          stroke={"black"}
          strokeWidth="4"
          fill={"white"}
          className={"checkmark-svg"}
        />
      </div>
    );
  }

}

function mapStateToProps(state) {
  return {
    baseProduct: state.baseProduct,
    customConfig: state.customConfig,
    loadedDefs: state.loadedDefs,
    user: state.user,
    hover: state.hover,
    toolTips: state.toolTips,
    navigation: state.navigation,
  };
}

export default connect(mapStateToProps, { clearHover })(ColorSwatch);
