import React, { Component } from 'react';

class MessageBox extends Component{
  render(){
    return (<div className={`message-box ${this.props.className}`}>	
    <div style={{fontSize: "16px"}}>
    {this.props.title}
    </div>
      <div>
      {this.props.message}
      </div>
      <div>
      {this.props.message2}
      </div>
    </div>); 
  }
}

export default MessageBox; 