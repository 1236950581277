import React, { Component } from 'react'; 
import helpers from "../../../../helpers"; 
import _ from "lodash"; 
import { connect } from "react-redux"; 
import { loadNewConfig } from "../../../../redux/reducers/customConfig/customConfigActions"; 

class LoadFromStorage extends Component {

  loadData(){
    this.props.loadNewConfig(this.props.modal.value); 
    this.props.toggle(); 
  }

  render() {
    let isoTime = _.get(this.props , "modal.value.autoSavedAt"); 
    let lastupdate = helpers.display.formatDateTime(isoTime);  
    let toolTip = _.find(this.props.toolTips, {key: "load-last-design"}).message;
    let cancelToolTip = _.find(this.props.toolTips, {key: "load-cancel-button"}).message;
    return (
      <div className="lfs-main">
        <p className="lfs-message">Did you want to load the saved data from: </p><p> {lastupdate} ?</p> 
        <div className="lfs-btn-group">
          <button aria-label={cancelToolTip} data-balloon-pos="up" className="lfs-no btn btn-outline-primary" onClick={ () => this.props.toggle()}>No, start a new design</button>
          <button aria-label={toolTip} data-balloon-pos="up" className="lfs-yes btn btn-primary" onClick={ () => this.loadData()}>Yes, load last design</button>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state){
  return{
    modal: state.modal, 
    config: state.customConfig,
    toolTips: state.toolTips
  }
}

export default connect(mapStateToProps, { loadNewConfig })(LoadFromStorage); 
