import * as types from "../../actions/actionTypes";
import helpers from "../../../helpers";
import _ from "lodash";

export function changeNavigationOption(option) {
  return { type: types.CHANGE_NAVIGATION_OPTION, option };
}
export function toggleDisplayOptionMobile(option) {
  return { type: types.TOGGLE_DISPLAY_OPTION_MOBILE, option };
}

export function dragItem(drag = true) {
  return { type: types.DRAG_ART, drag };
}

export function setView(view) {
  const payload = { activeView: view };
  const type = types.SET_VIEW;
  return { type, payload };
}

export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const TOGGLE_MOBILE = "TOGGLE_MOBILE";
export const SET_ACTIVE_LOCATION = "SET_ACTIVE_LOCATION";
export const SET_PENDING_ENTITY_DATA = "SET_PENDING_ENTITY_DATA";
export const SET_PENDING_LOCATION = "SET_PENDING_LOCATION";
export const CLEAR_ACTIVE_LOCATION = "CLEAR_ACTIVE_LOCATION";
export const SET_PRODUCT_URLS = "SET_PRODUCT_URL";
export const TOGGLE_BORDERS = "TOGGLE_BORDERS";
export const SET_CUSTOM_TEXT_INPUT = "SET_CUSTOM_TEXT_INPUT";
export const PREVIEW_SAMPLE = "PREVIEW_SAMPLE";
export const DND_LOC_TO_LOC = "DND_LOC_TO_LOC";
export const ORIGINAL_ENTITY_POSITION = "ORIGINAL_ENTITY_POSITION";
export const DELETE_TOGGLE = "DELETE_TOGGLE";
export const SET_FLIP_ART_INPUT = 'SET_FLIP_ART_INPUT';


export function dndLocToLoc(hasLocation, locationA, locationB) {
  let payload = { dndLocToLoc: { hasLocation, locationA, locationB } };
  return { type: DND_LOC_TO_LOC, payload };
}

export function setOriginalEntityPosition(originalEntityPosition) {
  let payload = { originalEntityPosition };
  return { type: ORIGINAL_ENTITY_POSITION, payload };
}

export function previewSample(
  baseProduct,
  locationCodeA,
  locationCodeB,
  pendingLocation
) {
  let locationDetailsA = helpers.product.getLocationDetails(
    baseProduct,
    locationCodeA
  );
  let locationContentA = helpers.locations.getLocationContent(locationCodeA);
  if (locationContentA) {
    let type = locationContentA.entity.type;
    let value = locationContentA.entity.value;
    let view = locationContentA.view;
    let code = locationContentA.code;
    let artAsset;
    if (locationContentA.entity.userArtId) {
      artAsset = _.cloneDeep(locationContentA.entity);
      artAsset.id = artAsset.userArtId;
    }
    let newLocationContentA = helpers.navigation.makePendingLocation(
      type,
      value,
      view,
      code,
      artAsset
    );
    locationContentA = newLocationContentA;
  }

  let locationDetailsB;
  let locationContentB;
  let pendingLocationDetails;
  let pendingLocationContent;
  if (locationCodeB === null) {
    pendingLocationDetails = _.cloneDeep(locationDetailsA);
    pendingLocationContent = _.cloneDeep(pendingLocation);

    locationDetailsB = null;
    locationContentB = null;
  } else {
    locationDetailsB = helpers.product.getLocationDetails(
      baseProduct,
      locationCodeB
    );
    locationContentB = helpers.locations.getLocationContent(locationCodeB);
    let type = locationContentB.entity.type;
    let value = locationContentB.entity.value;
    let view = locationContentB.view;
    let code = locationContentB.code;
    let artAsset;
    if (locationContentB.entity.userArtId) {
      artAsset = _.cloneDeep(locationContentB.entity);
      artAsset.id = artAsset.userArtId;
    }
    let newLocationContentB = helpers.navigation.makePendingLocation(
      type,
      value,
      view,
      code,
      artAsset
    );
    locationContentB = newLocationContentB;
  }
  let payload = {
    previewSample: {
      locationDetailsA,
      locationDetailsB,
      locationContentA,
      locationContentB,
      pendingLocationDetails,
      pendingLocationContent
    }
  };
  
  return { type: PREVIEW_SAMPLE, payload };
}

export function setCustomTextInput(value) {
  const payload = { customTextInput: value };
  const type = SET_CUSTOM_TEXT_INPUT;
  return { type, payload };
}

export function toggleBorders(bool) {
  const payload = { borders: bool };
  const type = TOGGLE_BORDERS;
  return { type, payload };
}

export function clearActiveLocation() {
  const payload = { activeLocation: null };
  const type = CLEAR_ACTIVE_LOCATION;
  return { type, payload };
}

export function toggleMoblieTab(displayMobileTab) {
  let payload = { displayMobileTab };
  let type = TOGGLE_MOBILE;
  return { type, payload };
}

export function setActiveTab(tabObj) {
  const payload = { activeTab: tabObj };
  const type = SET_ACTIVE_TAB;
  return { type, payload };
}

export function setActiveLocation(location) {
  const payload = { activeLocation: location };
  const type = SET_ACTIVE_LOCATION;
  return { type, payload };
}

export function setPendingLocation(pendingLocation) {
  const payload = { pendingLocation: pendingLocation };
  const type = SET_PENDING_LOCATION;
  return { type, payload };
}

export function toggleDelete(payload) {
  let type = DELETE_TOGGLE;
  return { type, payload };
}

export function toggleLibraries(setting) {
  let type = types.TOGGLE_LOGOS;
  return { type, setting };
}

export function enableTabs(opts, list) {
  let options = _.cloneDeep(opts);
  if (!list.fabric) {
    setTabFalse(options, "fabric");
  }
  if (!list.art) {
    setTabFalse(options, "up-art");
    setTabFalse(options, "system-art");
  }
  if (!list.text) {
    setTabFalse(options, "add-text");
  }
  if (!list.fabric) {
    setTabFalse(options, "add-design");
  }

  let type = types.SET_OPTIONS;

  return { type, options };
}

function setTabFalse(options, key) {
  let tab = _.find(options, { key }, false);
  if (tab) {
    tab.isAvailable = false;
  }
}

export function setInIframe(value) {
  return {
    type: types.SET_IN_IFRAME,
    iframe: value
  };
}

export function toggleUserArtEditMode(isActive = false) {
  return { type: types.TOGGLE_USER_EDIT_MODE, isActive };
}

export function setPendingEntityData(entityData) {
  
  const payload = { pendingEntityData: entityData };
  const type = SET_PENDING_ENTITY_DATA;
  return {type, payload};
}

export function setFlipArtInput(value){
  const payload = { flipArtInput: value };
  const type = SET_FLIP_ART_INPUT;
  return { type, payload };
}

