import {
  CLEAR_NOTIFICATION,
  SEND_NOTIFICATION,
  SEND_IFRAME_MESSAGE,
  SEND_ADD_TO_CART_MESSAGE,
  SET_SCRIM
} from "../../actions/actionTypes";
import AppConstants from "../../../constants";

export function messageIframe(action, data){
  return {type: SEND_IFRAME_MESSAGE, message: { action, data }};
}

export function clearIframeMessages(){
  return {type: SEND_IFRAME_MESSAGE, message: null};
}

export function sendAddToCartMessage(){
  return {type: SEND_ADD_TO_CART_MESSAGE, message: { action: AppConstants.Cart.addToCart, data: null }};
}

export function clearAddToCartMessage(){
  return {type: SEND_ADD_TO_CART_MESSAGE, message: null };
}

export function actionNotification(notification){
  return {type:SEND_NOTIFICATION, notification}
}

export function clearNotification(){
  let notification = {};
  return {type:CLEAR_NOTIFICATION, notification}
}

export function enableScrim(payload=true){
  let type = SET_SCRIM;
  return {type, payload}
}


