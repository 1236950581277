import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import OptionHeading from "../../../global/optionHeading/OptionHeading";
import TabHeader from "../../../global/TabHeader/TabHeader";
import FabricDetail from "./fabricDetail";
import { changeFabric } from "../../../../redux/reducers/customConfig/customConfigActions";

class Fabrics extends Component {
  renderDetails() {
    let details = [];
    _.each(this.props.baseProduct.fabrics, (code, idx) => {
      let fabric = _.find(this.props.loadedDefs.fabrics, { code });
      if (!fabric) {
        return null;
      }
      let selected = (code === this.props.customConfig.fabric);
      details.push(<FabricDetail
        key={idx}
        code={code}
        fabric={fabric}
        selected={selected}
        select={() => this.props.changeFabric(code)} />);
    });
    return details;
  }

  render() {
    var text = this.props.baseProduct.configurationType === 'custom-sub' ? this.props.baseProduct.legacyStyleNum : this.props.baseProduct.styleNum;
    if (this.props.baseProduct.configurationType === 'custom-sub' && text.length > 6) {
      text = text.slice(0, -1);
    }
    return (
      <div className="AddArtDrawer">
        <div className="option-body">
          <div className="row">
            <div className="col-12">
              <TabHeader text={text} name={this.props.baseProduct.name}></TabHeader>
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-12">
            <ul className="ul-list">
                <li>Semi-fitted, moderately loose through the body with smaller armholes than our traditional Basketball cut.</li>
                <li>Self material V-neck.</li>
                <li>Straight hemmed bottom and sleeves</li>
              </ul>
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-12">
              <div className="row">
                <div className="col-6">
                  <p className="design-tab-heading">Select a Fabric</p>
                </div>
                <div className="col-6 selected-design-name text-right pt-2">
                  SELECTED:{`Fabric ${this.props.customConfig.fabric}`}
                </div>
              </div>
            </div>
            <div className="col-12">
              {this.renderDetails()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loadedDefs: state.loadedDefs,
    customConfig: state.customConfig,
    baseProduct: state.baseProduct
  };
}

export default connect(mapStateToProps, { changeFabric })(Fabrics);
