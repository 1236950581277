import React, {Component} from "react";
import PropTypes from "prop-types";
import Slider from 'rc-slider';   // NOTE: rc-slider css is required.  it is included in main.scss


export default class RotationSlider extends Component {

  static propTypes = {
    onChange: PropTypes.func,
    rotation: PropTypes.number
  };

  static defaultProps = {
    onChange: () => {}
  };

  render() {
    let {onChange, rotation} = this.props
    return (
      <div className="slider">
        <div className="slider__slide">
          <Slider
            onBlur = {()=> this.props.toggleDelete(false)}
            onFocus = {()=>this.props.toggleDelete(true)}
            value={rotation}
            handleStyle={[{borderRadius: "50%", borderColor: "grey", width: "15px", height: "15px"}]}
            trackStyle={[{backgroundColor: "#E9E9E9"}]}
            onChange={e => onChange(e)}
            step={1}
            max={360}
            min={0} />
        </div>
        <div className="slider__dropdown">
          <input
            onBlur = {()=> this.props.toggleDelete(false)}
            onFocus = {()=>this.props.toggleDelete(true)}
            type="number"
            value={rotation}
            id="number"
            min={0}
            max={365}
            onChange={e => onChange(e.target.value)}/>
          <div className="slider__dropdown__degreediv">°</div>
        </div>
    </div>
    )
  }
}
