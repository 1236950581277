import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { setPendingLocation, setCustomTextInput, previewSample, setActiveTab, setView } from '../../../../redux/reducers/navigation/navigationActions';
import { addPendingLocationToLocations } from '../../../../redux/reducers/customConfig/customConfigActions';
import { locationUsed } from "../../../../redux/reducers/modal/modalActions";

function AddToDesignButton(props) {

  const handleAddToDesign = () => {
    let pendingLocation = props.navigation.pendingLocation;
    let groupName = props.baseProduct.locations.filter(v => v.code === pendingLocation.code);
    let locationCodeA = pendingLocation.code;
    props.previewSample(props.baseProduct, locationCodeA, null, pendingLocation);

    let isLocationFilled = _.find(props.customConfig.locations, { code: pendingLocation.code });
    if (isLocationFilled) {
      let sourceLocation = pendingLocation;
      let targetLocation = isLocationFilled;
      props.locationUsed(sourceLocation, targetLocation);
    } else {
      props.addPendingLocationToLocations(props.customConfig.locations, pendingLocation, groupName);
      let tabObj = { tab: props.navigation.activeTab.tab, page: "edit" };
      props.setActiveTab(tabObj);
    }
    props.setCustomTextInput("");
    props.setPendingLocation(null);
    props.setView(pendingLocation.view);

  }

  const isDisabled = () => {
    let isDisabled = "custom-disabled-button disabled";
    if (props.navigation.pendingLocation) {
      isDisabled = "custom-black-button";
    }
    return isDisabled;
  }

  return (
    <div style={{ border: "none", width: "100%" }} className={`AddToDesignButton ${isDisabled()}`} onClick={handleAddToDesign}>
      Add to Design
    </div>
  )
}

const reduxActions = {
  setPendingLocation,
  setCustomTextInput,
  addPendingLocationToLocations,
  previewSample,
  locationUsed,
  setActiveTab,
  setView
}

function mapStateToProps(state) {
  return {
    navigation: state.navigation,
    baseProduct: state.baseProduct,
    customConfig: state.customConfig
  }
}

export default connect(mapStateToProps, reduxActions)(AddToDesignButton);