import {RENDER_PREVIEWS_ACTION} from '../../actions/actionTypes';

export function triggerRenderPreviewImagesAction(callback) {
  return {type: RENDER_PREVIEWS_ACTION, callback };
}

export function clearRenderPreviewImagesAction() {
  return {type: RENDER_PREVIEWS_ACTION, callback: null};
}

/*
export function setRenderPreviewImagesStandbyAction() {
  return {type: RENDER_PREVIEWS_STANDBY, standby: true };
}

export function clearRenderPreviewImagesStandbyAction() {
  return {type: RENDER_PREVIEWS_STANDBY, standby: false };
}
 */
