import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import designManager from "../../../helpers/designManager";
import AppConfig from "../../../config";


class ClosePopup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      lastSavedConfig: []
    }
    //Temporarily disable this for Selenium testing Chromagear products
    this.showPopupOnUnload = this.showPopupOnUnload.bind(this);
  }

  componentDidUpdate(prevProps) {
    let curIframe = _.get(this.props, "reduxState.notifications.iframe", null);
    let preIframe = _.get(prevProps, "reduxState.notifications.iframe", null);
    let currentConfig = _.get(this.props, "reduxState.customConfig", null);
    let iframeEmpty = _.isEmpty(curIframe);

    if (curIframe !== preIframe && !iframeEmpty) {
      let action = _.get(curIframe, "action", null);
      if (action === "saved" || action === "add-to-cart") {
        this.setState({ lastSavedConfig: currentConfig });
        //Temporarily disable this for Selenium testing Chromagear products
        this.setPopup(true);
      }
    }

    if (currentConfig !== prevProps.reduxState.customConfig) {
      let sameConfig = designManager.isConfigDuplicate(currentConfig, this.state.lastSavedConfig);
      // Temporarily disable this for Selenium testing Chromagear products
      this.setPopup(sameConfig);
    }
  }


  componentDidMount() {
    window.onbeforeunload = null;
    this.setState({ lastSavedConfig: this.props.reduxState.customConfig });
  }

  showPopupOnUnload(e) {
    e = e || window.event;
    if (e) {
      e.returnValue = "Do you want to close without saving your progress";
    }
    return "Do you want to close without saving your progress";
  }

  //Temporarily disable this for Selenium testing Chromagear products
  setPopup(sameConfig) {
    if (sameConfig || !AppConfig.showReloadPageConfirmPrompt) {
      window.onbeforeunload = null;
    } else {
      window.onbeforeunload = this.showPopupOnUnload;
    }
  }

  render() {
    return (null);
  }
}

function mapStateToProps(state) {
  return {
    reduxState: state
  }
}

export default connect(mapStateToProps, null)(ClosePopup);
