import React from "react";

const FlipArtSVG = function (props) {
  const { className, height, width, fill, stroke = "grey", strokeWidth, onClick, viewBox } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} height={height} width={width} fill={fill} onClick={onClick} strokeWidth={strokeWidth}><path d="M0 0h24v24H0V0z" fill="none" opacity=".87" /><path d="M17.51 3.87L15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z" /></svg>
  );
};

export default FlipArtSVG;
