
import React, { Component } from "react";
import helpers from "../../../../../helpers";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { connect } from 'react-redux';

class TextPatternsDesigns extends Component {

  constructor(props) {
    super(props);
    // let this.props.location = _.find(this.props.customConfig.locations, { code: this.props.navigation.activeLocation });
    this.state = {
      chosenPattern: null,
      name: this.props.location.entity.capabilities.textPatternFillId ? this.props.location.entity.capabilities.textPatternFillId.description : "None",
      url: this.props.location.entity.capabilities.textPatternFillId ? this.props.location.entity.capabilities.textPatternFillId.thumbnail : "",
    };
    this.buildTextPatternPreviewStyle = this.buildTextPatternPreviewStyle.bind(this);
  }

  buildTextPatternPreviewStyle() {
    // TODO: logan- if in advanced mode, pass in colors string (combined color keys string)
    let style = {};
    let url = this.state.url;
    if (url) {
      style.backgroundImage = `url(${url})`;
    }
    return style;
  }

  render() {

    let { loadedDefPatterns,
      isDisabled,
      onChange } = this.props;

    let disabledClass = "";
    if (isDisabled) {
      disabledClass = "custom-disabled";
    }

    let patternGroupClass;
    let advanced = helpers.customLayers.isAdvancedMode();
    if (advanced) {
      patternGroupClass = "pattern-group-margin-top";
    } else {
      patternGroupClass = "pattern-group";
    }
    let togglePattern = this.buildTextPatternPreviewStyle();

    return (

      <div className="patterns-container">
        <div >
          <div className={`pattern-card ${disabledClass}`}>
            <div className="row">
              <div className="col-3">
                <UncontrolledDropdown aria-label={this.props.toolTip} data-balloon-pos="up">
                  <DropdownToggle style={togglePattern} className="pattern-texture">
                    {/* <div className="advanced-layer-caret-container">
                      <div className="custom-caret" />
                    </div> */}
                  </DropdownToggle>
                  <DropdownMenu className="pattern-list-container fancy-scrollbar">
                    <DropdownItem header>Select a Pattern</DropdownItem>
                    <DropdownItem className="pattern-list fancy-scrollbar">
                      {loadedDefPatterns.map((e, i) => {
                        return (
                          <div className="pattern-container">
                            <div className="pattern"
                              onClick={() => {
                                onChange(e);
                                this.setState({ name: e.description, url: e.thumbnail })
                              }}>
                              <img src={e.thumbnail} alt={e.description} style={{ height: "65px", width: "65px" }}></img>
                            </div>
                            <div className="pattern-title">
                              {e.description}
                            </div>
                          </div>
                        )
                      })}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              {/* <div className="col-9">
<div className="text-color-name-subheading">
                    {this.state.name}
                  </div>
</div> */}
            </div>
            {/* <div className={patternGroupClass}>
                    Design Patterns
                  </div> */}
          </div>
        </div>
      </div>


    )
  }
}

function mapStateToProps(state) {
  return {
    customConfig: state.customConfig,
    baseProduct: state.baseProduct,
    loadedDefs: state.loadedDefs,
    toolTips: state.toolTips
  }
}
export default connect(mapStateToProps,)(TextPatternsDesigns);