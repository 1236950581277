import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { setActiveTab } from '../../../../redux/reducers/navigation/navigationActions';
// import EditTitle from './editTitle/EditTitle';
import EditLocationPicker from './editLocationPicker/EditLocationPicker';
import EditTextFont from './editTextFont/EditTextFont';
import EditTextPatternFill from './editTextPatternFill/EditTextPatternFill';
import EditTextEffect from './editTextEffect/EditTextEffect';
import EditSize from './editSize/EditSize';
import EditHeightAndWidth from './editHeightAndWidth/EditHeightAndWidth';
import EditRotation from './editRotation/EditRotation';
import EditDecorationMethod from './editDecorationMethod/EditDecorationMethod';
import EditTextValue from './editTextValue/EditTextValue';
import EditBottomButtons from './editBottomButtons/EditBottomButtons';
// import EditPreview from './editPreview/EditPreview';
// import EditArtDetails from './editArtDetails/EditArtDetails';
import EditTextColorPicker from './editTextColorPicker/editTextColorPicker';
import AddNote from "./addNote/AddNote";
import EditArtFlip from './editArtFlip/EditArtFlip';
import EditArtDetails from './editArtDetails/EditArtDetails';
import EditArtName from './editArtName/editArtName';
import EditCustomArtColors from "./editCustomArtColors/editCustomArtColors";

function EditDrawer(props) {
  // logic for what should be displayed in the edit tab.

  // these get all the data of whats needed for display logic. ex: base product location does not allow for rotation. don't display rotation Comp.
  let locationContent = _.find(props.customConfig.locations, { code: props.navigation.activeLocation });

  let bpLocation;
  let bpAllowedEntity;
  let bpDecorationMethod;

  // show / display variables
  let hasLocationContent;
  let showEditTextSize;
  let showEditTextRotation;
  let showEditTextValue;
  let showEditTextFonts;
  let showEditTextEffects;
  let showEditTextColors;
  let isArt;
  let textPattern;

  if (locationContent) {
    bpLocation = _.find(props.baseProduct.locations, { code: locationContent.code });
    bpAllowedEntity = _.find(bpLocation.allowedEntities, { key: locationContent.entity.type });
    bpDecorationMethod = _.find(bpAllowedEntity.allowedDecorationMethods, { key: locationContent.entity.decorationMethod })
    // show / display variables
    hasLocationContent = locationContent;
    textPattern = locationContent.entity.patternFill || "No";
    showEditTextSize = locationContent.entity.size !== "free-size";
    showEditTextRotation = bpDecorationMethod.capabilities.rotation;
    showEditTextValue = locationContent.entity.type === "custom-text";
    showEditTextFonts = bpDecorationMethod.allowedFonts.length > 0;
    showEditTextEffects = bpDecorationMethod.allowedTextEffects.length > 0;
    showEditTextColors = locationContent.entity.capabilities.fillColors && locationContent.entity.capabilities.fillColors.length > 0;
    isArt = locationContent.entity.type === 'custom-art';
  }

  // function checkLocations() {
  //   let UpdatedLocations = props.customConfig.locations;
  //   // UpdatedLocations = _.filter(UpdatedLocations, loc =>
  //   //   // loc.saveEntity === true || (this.props.decoration.editDecorationType !== "" && loc.editEntity === true)
  //   //   loc.saveEntity === true
  //   // );
  //   if (props.decoration.prevLocation !== "") {
  //     UpdatedLocations = _.each(UpdatedLocations, loc => {
  //       if (loc.editEntity !== true) {
  //         if (props.decoration.prevLocation.code === loc.code) {
  //           let returnLoc = props.decoration.prevLocation;
  //           props.decoration.prevLocation = {}
  //           return returnLoc;
  //         }
  //       }
  //       else{
  //         return loc;
  //       }
  //     })
  //     props.customConfig.locations = UpdatedLocations;
  //   }
  // }

  return (
    <div className="EditDrawer">
      {hasLocationContent ?
        <div className="option-body">
          {isArt ? <EditArtName locationContent={locationContent} /> : null}
          {isArt ? <EditArtFlip /> : null}
          {isArt ? <EditCustomArtColors locationContent={locationContent} /> : null}
          {showEditTextValue ? <EditTextValue /> : null}
          {showEditTextColors && !isArt && (textPattern === 'No') ? <EditTextColorPicker /> : null}
          {hasLocationContent ? <EditLocationPicker /> : null}
          {showEditTextSize ? <EditSize /> : null}
          {!showEditTextSize ? <EditHeightAndWidth /> : null}
          {showEditTextFonts ? <EditTextFont /> : null}
          {showEditTextEffects ? <EditTextEffect /> : null}
          {showEditTextColors && !isArt && props.baseProduct.configurationType === "custom-sub" ? <EditTextPatternFill /> : null}
          {showEditTextColors && !isArt && (textPattern === 'Yes') ? <EditTextColorPicker /> : null}
          {showEditTextRotation && !isArt ? <EditRotation /> : null}
          {hasLocationContent ? <EditDecorationMethod /> : null}
          {/* { hasLocationContent ? <EditPreview /> : null } */}
          {isArt ? <AddNote /> : null}
          {hasLocationContent ? <EditBottomButtons /> : null}
        </div>
        : null}
    </div>
  )
}

const reduxActions = {
  setActiveTab,
}

function mapStateToProps(state) {
  return {
    navigation: state.navigation,
    baseProduct: state.baseProduct,
    customConfig: state.customConfig,
    decoration: state.decoration
  }
}

export default connect(mapStateToProps, reduxActions)(EditDrawer);