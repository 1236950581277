import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import api from "../../../api";
import helpers from "../../../helpers";
import CartPlus from "../../../assets/icons/CartPlus";
import LoadingSVG from "../../../assets/icons/LoadingSVG";
import RightArrowSVG from "../../../assets/icons/RightArrowSVG";
import ShoppingCartSVG from "../../../assets/icons/ShoppingCartSVG";
import { messageIframe, clearAddToCartMessage, enableScrim } from "../../../redux/reducers/notifications/notificationsActions";
import { setActiveTab, toggleMoblieTab } from '../../../redux/reducers/navigation/navigationActions';
import { triggerRenderPreviewImagesAction } from "../../../redux/reducers/appActions/appActionsActions";
import { missingRosterModal, quantityModal } from "../../../redux/reducers/modal/modalActions";
import AppConstants from "../../../constants";
import AppConfig from "../../../config";
import { setConfigId } from "../../../redux/reducers/customConfig/customConfigActions";
let initialValue = false;
let initialOrder = false;
let nextTab = "";

class AddToCartButton extends Component {

  constructor(props) {
    super(props);
    let isDisabled = false;
    if (!!AppConfig.blockAddToCartButtonWhenNotHosted) {
      if (!this.props.reduxState.navigation.inIframe) {
        isDisabled = true;
      }
    }

    this.state = {
      isDisabled,
      addtocartLoading: false,
      previews: [],
      accountCheck: false,
    };
    this.addToCartClicked = this.addToCartClicked.bind(this);
  }

  async capturePreviewImages() {
    let timeoutMS = 20000;
    return new Promise((resolve, reject) => {
      let timeoutRef = null;
      let handleDone = _.once((err, res) => {
        if (timeoutRef) { clearTimeout(timeoutRef); timeoutRef = null; }
        if (err) {
          console.error(err);
          return reject(err);
        }
        resolve(res);
      });
      /*if (timeoutMS > 0) {
        timeoutRef = setTimeout(() => {
          handleDone(new Error('capturePreviewImages timed out'), null);
        }, timeoutMS);
      }*/
      this.props.triggerRenderPreviewImagesAction(res => {
        handleDone(null, res);
      });
    });
  };

  async addToCartClicked() {
    if (nextTab.length <= 0 || nextTab === "") {
      let previews = await this.capturePreviewImages();
      this.setState({ previews });
      if (this.state.addtocartLoading) { return; }
      this.setState({ addtocartLoading: true });
      if (this.props.configAcc.webUserId !== undefined) {
        if (this.props.user.corpAccount.id !== this.props.configAcc.corpAcctId && this.props.customConfig.configId === this.props.configAcc.configId) {
          await this.setState({ accountCheck: true });
          initialValue = true;
          initialOrder = true;
        }
        else if (this.props.user.corpAccount.id === this.props.configAcc.corpAcctId && this.props.configAcc.isOrderedDesign === true && this.props.customConfig.configId === this.props.configAcc.configId) {
          initialOrder = false;
          initialValue = false;
        }
      }

      // Show modal at correct times based on logic.
      // When â€œAdd to Cartâ€ button is pressed.
      //
      // case 1) If customConfig locations for player name OR player number exist
      //   and no roster data exists. (even if size&quantity data exist)
      //   => show roster modal dialog (rosterModal)
      //
      // case 2) If NO customConfig locations exist for player name or player number
      //   and no roster data or size data exists
      //  => show quantity & size modal dialog


      let canContinue = true;

      let hasPlayerName = helpers.locations.hasFilledLocationByEntityType(helpers.appConst.EntityTypes.PlayerName);
      let hasPlayerNumber = helpers.locations.hasFilledLocationByEntityType(helpers.appConst.EntityTypes.PlayerNumber);
      let hasPlayerNameOrNumber = hasPlayerName || hasPlayerNumber;
      if (hasPlayerNameOrNumber) {
        // check case 1)
        let hasRosterData = helpers.roster.doesAnyRowHaveValidRosterData(hasPlayerName, hasPlayerNumber, this.props.baseProduct.configurationType);
        if (!hasRosterData) {
          // we have a name or number on the product, but no roster data
          // show me the roster dialog warning prompt
          this.props.missingRosterModal(previews);
          canContinue = false;
        }
      } else {
        // check case 2)
        let hasQuantityData = helpers.roster.doesAnyRowHaveValidQuantityData();
        if (!hasQuantityData) {
          // we do not have sizes and quantities yet,
          // show me the size & quantity dialog warning prompt
          this.props.quantityModal(previews);
          canContinue = false;
        }
      }

      if (canContinue) {
        this.finalProcess();
      }
      this.setState({ addtocartLoading: false });
    } else {
      let tabObj = { tab: nextTab, page: "main" }
      this.props.setActiveTab(tabObj);
      this.props.toggleMoblieTab(true);
    }
  }

  async finalProcess() {
    // trigger add to cart logic
    // NOTE: this config data is temporary.  It is not added to redux.
    // we pass some of the data to AE, then do not keep it locally
    let iframeDataList = [];
    let iframeData = {};
    let parentStyleNum = this.props.customConfig.styleNum;
    let parentRosterData = [];
    parentRosterData = this.props.customConfig.rosters[0].items;
    let stylesList = [
      {
        "key": "J5JV1A",
        "value": "AS1105"
      },
      {
        "key": "J5JV1Y",
        "value": "AS1107"
      },
      {
        "key": "J5JV1W",
        "value": "AS1106"
      },
      {
        "key": "J5JV1G",
        "value": "AS1982"
      },
      {
        "key": "J5JC1A",
        "value": "AS0224"
      },
      {
        "key": "J5JC1W",
        "value": "AS0225"
      },
      {
        "key": "J5JC1Y",
        "value": "AS0226"
      }
    ]
    let rosterStylesList = [];
    if (this.props) {
      if (this.props.customConfig) {
        if (this.props.customConfig.rosters && this.props.customConfig.rosters.length > 0) {
          for (let i = 0; i < this.props.customConfig.rosters[0].items.length; i++) {
            if (!rosterStylesList.includes(this.props.customConfig.rosters[0].items[i].youthToAdult)) {
              rosterStylesList.push(this.props.customConfig.rosters[0].items[i].youthToAdult);
            }
          }
        }
      }
    }
    if (rosterStylesList[0] && this.props.baseProduct.configurationType == 'custom-sub') {
      this.props.enableScrim();
      let previews = await this.capturePreviewImages();
      let rosterDataPerStyle1 = [];
      if (this.props) {
        if (this.props.customConfig) {
          if (this.props.customConfig.rosters && this.props.customConfig.rosters.length > 0) {
            for (let i = 0; i < this.props.customConfig.rosters[0].items.length; i++) {
              if (this.props.baseProduct.legacyStyleNum != null && this.props.baseProduct.legacyStyleNum != '') {
                if (this.props.baseProduct.legacyStyleNum.includes(this.props.customConfig.rosters[0].items[i].youthToAdult)) {
                  rosterDataPerStyle1.push(this.props.customConfig.rosters[0].items[i]);
                }
              }
            }
          }
        }
      }
      if (rosterDataPerStyle1 && rosterDataPerStyle1.length > 0) {
        this.props.customConfig.rosters[0].items = (rosterDataPerStyle1 && rosterDataPerStyle1.length > 0) ?
          rosterDataPerStyle1 : this.props.customConfig.rosters[0].items;
        this.props.customConfig.decorationPricing.entities[0].quantity = this.props.customConfig.rosters[0].items.length;
        this.props.customConfig.decorationPricing.quantity = this.props.customConfig.rosters[0].items.length;
        let newDbConfigModel = await this.saveConfigBeforeAddingToCart(this.props.baseProduct, this.props.customConfig, previews);
        iframeData = await helpers.serialization.prepareAddToCartMessageData(this.props.baseProduct, this.props.customConfig, newDbConfigModel);
        iframeDataList.push(iframeData);
      }

      for (let k = 0; k < rosterStylesList.length; k++) {
        if (rosterStylesList[k] != null && rosterStylesList[k] != '') {
          if (this.props.baseProduct.legacyStyleNum != null && this.props.baseProduct.legacyStyleNum != '') {
            if (!this.props.baseProduct.legacyStyleNum.includes(rosterStylesList[k])) {
              let styleNum = '';
              stylesList.map((key, val) => {
                if (key.key.includes(rosterStylesList[k])) {
                  styleNum = key.value;
                }
              });
              let productDataInfo = await this.getProductData(styleNum, this.props.baseProduct.designNum);
              let baseProduct = _.get(productDataInfo, "data", {});
              let rosterDataPerStyle = [];
              if (parentRosterData && parentRosterData.length > 0) {
                for (let i = 0; i < parentRosterData.length; i++) {
                  if (parentRosterData[i].youthToAdult == rosterStylesList[k]) {
                    rosterDataPerStyle.push(parentRosterData[i]);
                  }
                }
              }

              let customConfigInfo = {};
              customConfigInfo = this.props.customConfig;
              customConfigInfo.styleNum = styleNum;
              customConfigInfo.rosters[0].items = rosterDataPerStyle;
              customConfigInfo.decorationPricing.entities[0].quantity = rosterDataPerStyle.length;
              customConfigInfo.decorationPricing.quantity = rosterDataPerStyle.length;
              this.props.enableScrim();
              let previews = await this.capturePreviewImages();
              let newDbConfigModel = await this.saveConfigBeforeAddingToCart(baseProduct, customConfigInfo, previews);
              iframeData = await helpers.serialization.prepareAddToCartMessageData(baseProduct, customConfigInfo, newDbConfigModel);
              iframeDataList.push(iframeData);
            }
          }
        }
      }
    } else {
      this.props.enableScrim();
      let previews = await this.capturePreviewImages();
      let newDbConfigModel = await this.saveConfigBeforeAddingToCart(this.props.baseProduct, this.props.customConfig, previews);
      iframeData = await helpers.serialization.prepareAddToCartMessageData(this.props.baseProduct, this.props.customConfig, newDbConfigModel);
      iframeDataList.push(iframeData);
      //this.props.messageIframe('add-to-cart', iframeData);
    }
    this.props.messageIframe('add-to-cart', iframeDataList);
    //this.props.messageIframe('add-to-cart', iframeData);
    // console.log("IframeDataList: ", iframeDataList);
    /*if (this.props.baseProduct.configurationType && this.props.baseProduct.configurationType == 'custom-sub')
      this.props.messageIframe('add-to-cart', iframeDataList);
    else if (this.props.baseProduct.configurationType && this.props.baseProduct.configurationType == 'stock-dec')
      this.props.messageIframe('add-to-cart', iframeData);*/
  }

  async getProductData(styleNum, designNum) {
    return api.baseProducts.get(styleNum, designNum ? { designNum } : null).then(res => {
      return res;
    }).catch((err) => {
      console.error("Error occured while fetching the product data, due to:", err);
      return false;
    });
  }

  async saveConfigBeforeAddingToCart(baseProductData, customConfigData, previews) {
    let baseProduct = baseProductData;
    let customConfig = customConfigData;
    let isSavedDesign = true;
    let isOrderedDesign = true;
    let overwrite = false;

    if (initialOrder && initialValue) {
      const actionPayload = { configId: '', isSavedDesign: true, isOrderedDesign: true }
      this.props.setConfigId(actionPayload);
      overwrite = false;
    } else {
      if (customConfig.configId) {
        overwrite = true;
      }
    }
    if (baseProduct && customConfig && baseProduct.configurationType === 'custom-sub') {
      customConfig.color = "custom"
    }
    let params = await helpers.serialization.prepareAddToCartApiSaveData(baseProduct, customConfig, previews, isSavedDesign, isOrderedDesign, overwrite);

    return api.webUserConfigs.save(params.configId, params).then(res => {
      return res;
    }).catch((err) => {
      console.error("error saving config", err);
      return false;
    });
  }

  componentDidUpdate() {
    const addToCartMessage = _.get(this.props, "reduxState.notifications.addToCart.action");
    const shouldAddToCart = (addToCartMessage === AppConstants.Cart.addToCart);

    if (shouldAddToCart) {
      this.finalProcess();

      // clear the addToCart redux message once the action has been handled.
      this.props.clearAddToCartMessage();
    }
  }

  nextStepText() {
    let currrentTab = this.props.navigation.activeTab.tab;
    let productType = this.props.baseProduct.configurationType;
    let nextStep = {
      desktop: "ADD TO CART",
      mobile: "ADD",
      icon: <ShoppingCartSVG />,
      cartTotal: ""
    }

    switch (currrentTab) {
      case "style": {
        nextStep.desktop = productType == "stock-dec" ? "CHOOSE COLOR" : "CHOOSE DESIGN";
        nextStep.mobile = productType == "stock-dec" ? "COLOR" : "DESIGN";
        nextStep.icon = <RightArrowSVG />;
        nextTab = productType == "stock-dec" ? "colors" : "add-design";
        break;
      }
      case "add-design": {
        nextStep.desktop = "CHOOSE COLOR";
        nextStep.mobile = "COLOR";
        nextStep.icon = <RightArrowSVG />;
        nextTab = "colors";
        break;
      }
      case "colors": {
        nextStep.desktop = productType == "stock-dec" ? "CHOOSE DECORATIONS" : "CHOOSE FABRIC";
        nextStep.mobile = productType == "stock-dec" ? "DECORATIONS" : "FABRIC";
        nextStep.icon = <RightArrowSVG />;
        nextTab = productType == "stock-dec" ? "decorations" : "fabric";
        break;
      }
      case "fabric": {
        nextStep.desktop = "CHOOSE DECORATIONS";
        nextStep.mobile = "DECORATIONS";
        nextStep.icon = <RightArrowSVG />;
        nextTab = "decorations";
        break;
      }
      // case "add-text": {
      //   nextStep.desktop = "ADD ART";
      //   nextStep.mobile = "ART";
      //   nextStep.icon = <RightArrowSVG />;
      //   nextTab = "up-art";
      //   break;
      // }
      case "decorations": {
        let customAddText = this.props.customConfig.locations;
        nextTab = "names-and-numbers";
        if (customAddText.length > 0) {
          let NameOrNumber = customAddText.some(val => {
            return (val.entity.type == "player-number" || val.entity.type == "player-name")
          });
          nextStep.desktop = NameOrNumber ? "ENTER ROSTER" : "CHOOSE SIZES";
          nextStep.mobile = NameOrNumber ? "ROSTER" : "SIZES"
          nextStep.icon = <RightArrowSVG />;
        } else {
          nextStep.desktop = "CHOOSE SIZES";
          nextStep.mobile = "SIZES";
          nextStep.icon = <RightArrowSVG />;
        }
        break;
      }
      case "names-and-numbers": {
        nextStep.desktop = "REVIEW SUMMARY";
        nextStep.mobile = "REVIEW";
        nextStep.icon = <RightArrowSVG />;
        nextTab = "order-summary";
        break;
      }
      case "order-summary": {
        let roster = this.props.customConfig.rosters.length > 0 ?
          this.props.customConfig.rosters[0] !== undefined && this.props.customConfig.rosters[0].items.length > 0 ? true : false
          : false;
        let qty = 0;
        if (this.props.customConfig.rosters.length > 0) {
          this.props.customConfig.rosters[0].items.forEach(element => {
            element.qty = +element.qty;
            qty += element.qty && element.qty !== null ? element.qty : 1;
          });
        }

        if(isNaN(qty)){
          qty = this.props.customConfig.rosters[0].items.length
        }
        let rosterLength = this.props.customConfig.rosters.length > 0 ? qty : 0

        // if (roster) {
        //   nextStep.desktop = "ADD TO CART";
        //   nextStep.mobile = "ADD";
        //   nextStep.icon = <ShoppingCartSVG />;
        //   nextStep.cartTotal = "(" + rosterLength + ")";
        //   nextTab = "";
        //   break;
        // }
        // else {
        //   let customAddText = this.props.customConfig.locations;
        //   nextTab = "names-and-numbers";
        //   if (customAddText.length > 0) {
        //     let NameOrNumber = customAddText.some(val => {
        //       return (val.entity.type == "player-number" || val.entity.type == "player-name")
        //     });
        //     nextStep.desktop = NameOrNumber ? "ENTER ROSTER" : "CHOOSE SIZES";
        //     nextStep.mobile = NameOrNumber ? "ROSTER" : "SIZES"
        //     nextStep.icon = <RightArrowSVG />;
        //   } else {
        //     nextStep.desktop = "CHOOSE SIZES";
        //     nextStep.mobile = "SIZES";
        //     nextStep.icon = <RightArrowSVG />;
        //   }
        //   break;
        // }
        nextStep.desktop = "ADD TO CART";
        nextStep.mobile = "ADD";
        nextStep.icon = <ShoppingCartSVG />;
        nextStep.cartTotal = "(" + rosterLength + ")";
        nextTab = "";
      }
      default: {
        nextStep.desktop = "ADD TO CART"
        nextStep.mobile = "ADD";
        nextStep.icon = <ShoppingCartSVG />;
        nextTab = "";
        break;
      }
    }
    return nextStep;
  }

  renderMobile() {
    let click = this.addToCartClicked;
    let className = ".mobile__area mobile-add-to-cart";
    let nextStepVal = this.nextStepText();
    if (this.state.isDisabled) {
      click = null;
      className = ".mobile__area mobile-add-to-cart atc-disabled"
    }
    return (
      <div
        className={className}
        onClick={click} >
        <CartPlus />
        <div className="service__name">{nextStepVal.mobile}</div>
      </div>
    );
  }

  renderDesktop() {
    let nextStepVal = this.nextStepText();
    let finalquantity = 0;

    if (this.props.customConfig.rosters.length > 0) {
      finalquantity = this.props.customConfig.rosters[0].items.length;
    }
    if (finalquantity === 0 && nextStepVal.desktop === 'ADD TO CART') {
      this.state.addToCartState = true;
    } else {
      this.state.addToCartState = false;
    }

    if (this.state.isDisabled) {
      let disabledToolTip = "No cart connected to builder";
      let className = nextStepVal.desktop == "ADD TO CART" ? "btn cart-button addCart custom-disabled-button-atc w-100" : "btn cart-button custom-disabled-button-atc w-100"
      return (<button
        // aria-label={disabledToolTip}
        // data-balloon-pos="right"
        type="button"
        className={className}>
        {nextStepVal.desktop}{nextStepVal.icon}{nextStepVal.cartTotal}<CartPlus />
      </button>);
    }

    let toolTip = _.find(this.props.toolTips, { key: "add-to-cart-button" }).message;
    let className = nextStepVal.desktop == "ADD TO CART" ? "btn cart-button addCart" : "btn cart-button"
    return (<button
      // aria-label={toolTip}
      // data-balloon-pos="up"
      className={className}
      disabled={this.state.addToCartState}
      onClick={this.addToCartClicked}>{nextStepVal.desktop}
      {nextStepVal.icon}{nextStepVal.cartTotal}</button>);
  }

  render() {
    if (this.state.addtocartLoading) {
      return (<LoadingSVG />);
    }
    if (this.props.mobile) {
      return this.renderMobile();
    }
    return this.renderDesktop();
  }
}

function mapStateToProps(state) {
  return {
    reduxState: state,
    user: state.user,
    customConfig: state.customConfig,
    baseProduct: state.baseProduct,
    toolTips: state.toolTips,
    navigation: state.navigation
  }
}

const reduxActions = {
  triggerRenderPreviewImagesAction,
  messageIframe,
  missingRosterModal,
  quantityModal,
  clearAddToCartMessage,
  enableScrim,
  setConfigId,
  setActiveTab,
  toggleMoblieTab
}

export default connect(mapStateToProps, reduxActions)(AddToCartButton);