import _ from 'lodash';
import { fabric } from 'fabric';
import helpers from '../../../helpers';



const DEFAULT_SHADOW = { color: 'rgba(0,0,0,0.3)', blur: 5 };

export default class ProductImageHelper {

  mount(canvas, url, canvasSizer, setScale, animate = false, showShadow = false) {

    // BLD-287. isSafariCORS append a special suffix if browser does not support CORS header to ensure that image gets reloaded by browser.
    let isSafariCORS = helpers.state.get("navigation.isSafariCORS");
    if ( isSafariCORS ){
      url = helpers.urlRender.appendParamStringToUrl(url, 'cb=1')
    }

    if (!url || this._curImageUrl === url) { return false; }
    this._curImageUrl = url;

    fabric.Image.fromURL(url, (img) => {

      // check that this image is still the active image we are expecting and should be set
      // if it is not, then abort and ignore this image
      if (this._curImageUrl && this._curImageUrl !== url) {
        return false;
      }
      if (!img) {
        console.error('ProductImageHelper.mount invalid image for url: ' + url);
        return false;
      }

      let oldImages = _.filter(canvas.getObjects(), (x)=>{if(x.id === 'productImage') {return x}});
      /// get scale and ratio from image to put on canvas
      if (setScale) {
        let width = canvasSizer.offsetWidth;
        let height = canvasSizer.offsetHeight;
        let ratio = img.width / img.height;
        if((width / height)>ratio) {
          width = height*ratio;
        } else {
          height = width / ratio;
        }
        let scale = width / img.width;
        let zoom = canvas.getZoom();
        zoom *= scale;
        canvas.setDimensions({ width: width, height: height });
        canvas.setViewportTransform([zoom , 0, 0, zoom , 0, 0]);
        ////
      }

      let startingOpacity = animate? 0: 1;
      img.id = 'productImage';
      img.evented = false;
      img.selectable = false;
      let imgObj = img.set({opacity: startingOpacity});

      // NOTE: joe- 5/27/2019.  disabled this recently added shadow as it was causing a visual flicker while images were fading
      if (false && showShadow) {
        // put a sweet drop shadow around the product image to help them stand out
        imgObj.setShadow(_.isObject(showShadow)? showShadow: DEFAULT_SHADOW);
      }

      if (animate) {
        _.forEach(oldImages, (x, i) => {
          canvas.sendToBack(x);
          setTimeout(() => {
            x.animate('opacity', 0, {
              duration: 200,
              onChange: canvas.renderAll.bind(canvas),
              onComplete: ()=>{canvas.remove(x)}
            });
          }, 200);
        });

        canvas.add(imgObj);
        canvas.sendToBack(imgObj);
        imgObj.animate('opacity', 1 , {
          duration: 200,
          onChange: canvas.renderAll.bind(canvas),
        });

      } else {
        _.forEach(oldImages, (x, i) => {
          canvas.remove(x);
        });
        canvas.add(imgObj);
        canvas.sendToBack(imgObj);
        canvas.bringForward(imgObj);
      }
    }, { crossOrigin: 'anonymous' });
  }

}

