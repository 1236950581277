import React from "react";
import _ from "lodash";
import ViewSelectorCard from "./viewSelectorCard/ViewSelectorCard";
import { connect } from "react-redux";

const ViewSelector = (props) => {

  let views = _.get(props, 'baseProduct.views', null);
  let numViews = _.size(views);
  if (numViews <= 0) {
    // there is nothing to show if there is only 1 view
    return null;
  }

  const viewSelectorCards = _.map(views, (x,i) => {
    return (
      <ViewSelectorCard key={i} item={x} unique={i}/>
    );
  });
  
  return (
    <div className="ViewSelectorContainer">
      <div className="ViewSelector">
        {viewSelectorCards}
      </div>
    </div>
  );
} ;

function mapStateToProps(state){
  return{
    baseProduct: state.baseProduct
  }
}

export default connect(mapStateToProps)(ViewSelector);
