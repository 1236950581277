import _ from 'lodash';
import stateProvider from './stateProvider';
import navigationHelper from "./navigationHelper";
import Constants from '../constants';


class LocationsHelper {

  // state helpers

  getStateVal(key, defaultValue = null) {
    return stateProvider.get(key, defaultValue);
  }

  // tests

  unitTests() {
    return {
      hasLocations: this.hasLocations(),
      getAllLocations: this.getAllLocations(),
      getAllCustomConfigLocations: this.getAllCustomConfigLocations(),
      getSelectedViewKey: this.getSelectedViewKey(),
      getSelectedLocationCode: this.getSelectedLocationCode(),
      getSelectedLocation: this.getSelectedLocation(),
      getEmptyLocations: this.getEmptyLocations(),
      getFilledLocations: this.getFilledLocations(),
      findBestLocationByEntityType: this.findBestLocationByEntityType(Constants.EntityTypes.PlayerNumber),
      getProductEntityTypes: this.getProductEntityTypes(),
      getProductArtEntityTypes: this.getProductArtEntityTypes(),
      getProductTextEntityTypes: this.getProductTextEntityTypes(),
      getFilledTextLocations: this.getFilledTextLocations(),
      getEmptyTextLocations: this.getEmptyTextLocations(),
      getFilledArtLocations: this.getFilledArtLocations(),
      doesAnyFilledLocationHaveArt: this.doesAnyFilledLocationHaveArt(),
      doesAnyFilledLocationHaveText: this.doesAnyFilledLocationHaveText(),
      getEmptyArtLocations: this.getEmptyArtLocations()
    };
  }


  // location helpers

  hasLocations() {
    return !_.isEmpty(this.getStateVal('baseProduct.locations'));
  }

  hasEmptyLocations() {
    return !_.isEmpty(this.getEmptyLocations());
  }

  hasEmptyArtLocations() {
    return !_.isEmpty(this.getEmptyArtLocations());
  }

  hasEmptyTextLocations() {
    return !_.isEmpty(this.getEmptyTextLocations());
  }

  hasFilledLocations() {
    return !_.isEmpty(this.getFilledLocations());
  }

  hasFilledArtLocations() {
    return !_.isEmpty(this.getFilledArtLocations());
  }

  hasFilledTextLocations() {
    return !_.isEmpty(this.getFilledTextLocations());
  }

  hasFilledLocationByEntityType(entityType) {
    return !_.isEmpty(this.getCustomConfigLocationsByEntityType(entityType));
  }

  getAllViews() {
    return this.getStateVal('baseProduct.views');
  }

  getAllViewKeys() {
    return _.map(this.getAllViews(), 'key');
  }

  /// $DEPRECATED$ use getAllViews instead
  getAllViewInformation() {
    return this.getAllViews();
  }

  getView(viewKey) {
    let views = this.getAllViews();
    return _.find(views, { key: viewKey }) || null;
  }

  getDefaultView() {
    return this.getView(this.getDefaultViewKey());
  }

  getSelectedView() {
    return this.getView(this.getSelectedViewKey());
  }

  getDefaultViewKey() {
    return this.getStateVal('baseProduct.defaultView');
  }

  getSelectedViewKey() {
    return this.getStateVal('navigation.activeView');
  }


  getAllLocations() {
    // assumes all locations have at least 1 valid allowed entity
    return this.getStateVal('baseProduct.locations');
  }

  getAllViewLocations(view) {
    let locations = this.getAllLocations();
    return _.filter(locations, { view });
  }

  getAllEntityLocations(entityType){
    let allLocations = this.getAllLocations();
    let availableLocations = [];

    _.each(allLocations, e => {
      _.each(e.allowedEntities, f => {
        if (f.type === entityType) {
          availableLocations.push({ ...e, view: e.view })
        }
      })
    });

    return availableLocations;
  }

  getLocation(locationCode) {
    let locations = this.getAllLocations();
    return _.find(locations, { code: locationCode });
  }

  getViewLocation(view, locationCode) {
    let locations = this.getAllLocations();
    return _.find(locations, { view, code: locationCode });
  }

  getViewLocationEntity(view, locationCode, entityType) {
    let location = this.getViewLocation(view, locationCode);
    return _.find(location && location.allowedEntities, { type: entityType }) || null;
  }

  getViewLocationEntityDecorationMethod(view, locationCode, entityType, decorationMethod) {
    let entity = this.getViewLocationEntity(view, locationCode, entityType);
    return _.find(entity && entity.allowedDecorationMethods, { key: decorationMethod }) || null;
  }

  getViewLocationEntitySize(view, locationCode, entityType, decorationMethod, sizeKey) {
    let decMethod = this.getViewLocationEntityDecorationMethod(view, locationCode, entityType, decorationMethod);
    return _.find(decMethod && decMethod.allowedSizes, { key: sizeKey }) || null;
  }

  getAllArtLocations() {
    let locations = this.getAllLocations();
    return _.filter(locations, this.doesLocationAllowArtEntityTypes.bind(this));
  }

  getAllTextLocations() {
    let locations = this.getAllLocations();
    return _.filter(locations, this.doesLocationAllowTextEntityTypes.bind(this));
  }

  // returns all custom config location entities
  getAllCustomConfigLocations() {
    return this.getStateVal('customConfig.locations');
  }

  getCustomConfigLocationEntityData(locationCode) {
    let customLocations = this.getAllCustomConfigLocations();
    return _.find(customLocations, { code: locationCode });
  }

  getCustomConfigLocationsByEntityType(entityType) {
    let customLocations = this.getAllCustomConfigLocations();
    return _.filter(customLocations, v => {
      return (_.get(v, 'entity.type') === entityType);
    });
  }

  getCustomConfigTextLocations() {
    return _.filter(this.getAllCustomConfigLocations(), v => {
      let entityType = _.get(v, 'entity.type', null);
      return (entityType && _.includes(Constants.TextEntityTypes, entityType));
    });
  }

  getCustomConfigArtLocations() {
    return _.filter(this.getAllCustomConfigLocations(), v => {
      let entityType = _.get(v, 'entity.type', null);
      return (entityType && _.includes(Constants.ArtEntityTypes, entityType));
    });
  }

  getSelectedLocationCode() {
    return this.getStateVal('navigation.activeLocation', null);
  }

  getSelectedLocation() {
    // we need to know the selected view and location code in order to identify the selected location.
    // the code is not enough.  we need to know the view + code.
    let view = this.getStateVal('navigation.activeView');
    let code = this.getStateVal('navigation.activeLocation');
    return this.getViewLocation(view, code);
  }

  // $DEPRECATED$ use getCustomConfigLocationEntityData instead
  getLocationContent(locationCode) {
    return this.getCustomConfigLocationEntityData(locationCode);
  }

  getEmptyLocations() {
    let locations = this.getAllLocations();
    let customLocations = this.getAllCustomConfigLocations();
    return _.differenceBy(locations, customLocations, 'code');
  }

  getEmptyArtLocations() {
    let locations = this.getEmptyLocations();
    return _.filter(locations, this.doesLocationAllowArtEntityTypes.bind(this));
  }

  getEmptyTextLocations() {
    let locations = this.getEmptyLocations();
    return _.filter(locations, this.doesLocationAllowTextEntityTypes.bind(this));
  }

  getFilledLocations() {
    let locations = this.getAllLocations();
    let customLocations = this.getAllCustomConfigLocations();
    return _.intersectionBy(locations, customLocations, 'code');
  }

  getFilledArtLocations() {
    let locations = this.getAllLocations();
    let customLocations = this.getCustomConfigArtLocations();
    return _.intersectionBy(locations, customLocations, 'code');
  }

  getFilledTextLocations() {
    let locations = this.getAllLocations();
    let customLocations = this.getCustomConfigTextLocations();
    return _.intersectionBy(locations, customLocations, 'code');
  }

  findBestLocationByEntityType(entityType, view) {
    // priority when finding the best location
    // if a selected location exists and supports the entityType, use it.
    // otherwise scan through empty, then filled locations
    // TODO: grab this from baseProduct data.  it may be null
    let defaultLocationCode = null;

    if (!defaultLocationCode) {
      // if we don't have a location defined, try one of the fallback locations
      defaultLocationCode = this.findFallbackLocationByEntityType(entityType, view);
    }

    if (!defaultLocationCode) {
      // find first view location that allows this entity type
      let tmpLocations = this.getAllViewLocations(view);
      let tmp = this.getLocationByEntityType(tmpLocations, entityType, view);
      defaultLocationCode = _.get(tmp, 'code', null);
    }

    let location = null;
    let content = this.getCustomConfigLocationEntityData(defaultLocationCode);

    if (!content) {
      if (!location && !_.isEmpty(defaultLocationCode)) {
        let locations = this.getAllLocations();
        location = _.find(locations, {code: defaultLocationCode})
      }
    } else {
      if (!location) {
        location = this.getLocationByEntityType(this.getEmptyLocations(), entityType, view);
        // Alan Commented out search filled locations because it prevented moving on to next view. and would just overwrite
        // current view before checking other views.
        // if (!location) {
        //   location = this.getLocationByEntityType(this.getFilledLocations(), entityType);
        // }
      }
      // if (!location) {
      //   location = this.doesLocationAllowEntityType(selectedLocation, entityType) ? selectedLocation: null;
      // }
    }
    return location;
  }

  findBestEmptyLocationByEntityType(entityType, view) {
    // priority when finding the best location
    // if a selected location exists and supports the entityType, use it.
    // scan through empty locations
    // TODO: grab this from baseProduct data.  it may be null
    let defaultLocationCode = null;

    if (!defaultLocationCode) {
      // if we don't have a location defined, try one of the fallback locations
      defaultLocationCode = this.findFallbackLocationByEntityType(entityType, view);
    }

    if (!defaultLocationCode) {
      // find first view location that allows this entity type
      let tmpLocations = this.getAllViewLocations(view);
      let tmp = this.getLocationByEntityType(tmpLocations, entityType, view);
      defaultLocationCode = _.get(tmp, 'code', null);
    }

    let location = null;
    let content = this.getCustomConfigLocationEntityData(defaultLocationCode);

    if (!content) {
      if (!location && !_.isEmpty(defaultLocationCode)) {
        let locations = this.getAllLocations();
        location = _.find(locations, {code: defaultLocationCode})
      }
    } else {
      if (!location) {
        location = this.getLocationByEntityType(this.getEmptyLocations(), entityType, view);
      }
    }
    return location;
  }

  findBestFilledLocation( entityType, view ){

    let bpLocations = this.getStateVal('baseProduct.locations');
    let locationsFilteredByView = _.filter( bpLocations, (location)=>{if(location.view === view){ return location;}});
    let locationsFilteredByAllowedEntity =  _.filter( locationsFilteredByView, (location)=>{
      let doesAllowEntity = _.find(location.allowedEntities, { key: entityType });
      if( doesAllowEntity ){
        return location;
      }
    });

    let location = _.first( locationsFilteredByAllowedEntity );
    return location;
  }

  getAnyLocationInfoForEntityType(entityType) {
    let entity;
    let views = this.getAllViews();
    _.each(views, view => {
      let check = this.findBestLocationByEntityType(entityType, view.key);
      if (check) {
        _.each(check.allowedEntities, allowed => {
          if (allowed.key === entityType) {
            entity = allowed;
          }
        })
      }
    })
    if (entity) {
      return entity;
    } else {
      return {};
    }
  }

  findFallbackLocationByEntityType(entityType, view) {

    if (!view || !entityType) { return null; }

    let locationCode = null;

    switch (view) {
      case 'front':
        switch (entityType) {
          case 'custom-text':
            locationCode = 'CF';
            break;
          case 'player-name':
            locationCode = 'UF';
            break;
          case 'player-number':
            locationCode = 'CF';
            break;
          case 'custom-art':
            locationCode = 'UF';
            break;
          default:
            break;
        }
        break;

      case 'back':
        switch (entityType) {
          case 'custom-text':
            locationCode = 'UB';
            break;
          case 'player-name':
            locationCode = 'UB';
            break;
          case 'player-number':
            locationCode = 'BK';
            break;
          case 'custom-art':
            locationCode = 'BK';
            break;
          default:
        }
        break;

      default:
        break;
    }

    if (!this.doesViewLocationAllowEntityType(view, locationCode, entityType)) {
      // if the location does not exist in this product, then clear it
      locationCode = null;
    }

    return locationCode;
  }

  getLocationByEntityType(locations, entityType, view) {
    if (!entityType) { return null; }

    let newLocations = [];

    _.each(locations, e => {
      if (e.view === view) {
        newLocations.push(e)
      }
    });

    if (newLocations.length === 0) {
      newLocations = locations
    }

    // rather than doing a direct find, let's make this a little smarter
    // we want to prioritize locations that have this entityType defined higher in their allowed
    // entity list, so let's filter, sort, then grab the first matching item

    return _.chain(newLocations)
      .filter(v => {
        // filter out items that don't have this entity type
        return _.find(v.allowedEntities, { type: entityType });
      })
      .sortBy(v => {
        // sort by the index order that we find this entity type in the list of allowed entities
        return _.findIndex(v.allowedEntities, { type: entityType });
      })
      .first()
      .value() || null;
  }

  getLocationsByEntityTypes(locations, entityTypes) {
    if (_.isEmpty(entityTypes)) { return []; }

    return _.chain(locations)
      .filter(v => {
        // filter out items that don't have this entity type
        return _.find(v.allowedEntities, v => {
          return _.includes(entityTypes, v.type);
        });
      })
      .first()
      .value() || null;
  }


  getDefaultEntityData() {
    // set the initial rending item state
    let text = null;
    let placeholder = null;
    let location = this.getSelectedLocation();
    let locationCode = (location && location.code) || null;
    let view = (location && location.view) || null;
    let entityType = _.first(this.getLocationTextEntityTypes(location));
    if (entityType) {
      let selectedItem = _.find(Constants.ToggleButtonItems, { entityType });
      if (selectedItem) {
        text = selectedItem.text;
        placeholder = selectedItem.placeholder;
      }
    }
    return { action: 'add-text', view, locationCode, entityType, text, placeholder };
  }

  getPendingEntityOrDefaultTextEntityData(entityType, locationCode = null) {

    let entityData = navigationHelper.getPendingLocationEntity();

    if (!entityData) {
      entityData = this.getDefaultTextEntityDataByLocationCodeAndEntityType(locationCode, entityType);
    }

    if (!entityData) {
      entityData = this.getDefaultTextEntityDataByEntityType(entityType);
    }

    return entityData;
  }

  getDefaultTextEntityDataByEntityType(entityType) {
    let locationCode = this.getSelectedLocationCode();
    return this.getDefaultTextEntityDataByLocationCodeAndEntityType(locationCode, entityType);
  }

  getDefaultTextEntityDataByLocationCodeAndEntityType(locationCode, entityType) {

    // TODO: $DATA_CLEANUP$.  fix this.  these values should be coming from the data, these should not be hard coded.

    let value = null;
    let placeholder = null;
    let viewKey = null;
    let entityDef = null;
    let location = this.getLocation(locationCode);
    if (location) {
      viewKey = location.view;
      entityDef = _.find(location.allowedEntities, { key: entityType }) || null;
      if (entityDef) {
        value = entityDef.defaultValue;
        placeholder = entityDef.placeholderValue;
      } else {
        // fallback to the toggle button defaults
        let selectedButtonItem = _.find(Constants.ToggleButtonItems, { entityType });
        if (selectedButtonItem) {
          value = selectedButtonItem.value;
          placeholder = selectedButtonItem.placeholder;
        } else {
          // fallback if we can't find the matching data we need
          value = 'ENTER TEXT';
          placeholder = 'ENTER TEXT';
        }
      }
    }

    // TODO: $DATA_CLEANUP$.  text should not be used.  use value instead
    return { action: 'add-text', view: viewKey, viewKey, locationCode, entityType, text: value, value, placeholder, entity: entityDef };
  }


  // location entity helpers

  doesAnyFilledLocationHaveArt() {
    return !_.isEmpty(this.getFilledArtLocations());
  }

  doesAnyFilledLocationHaveText() {
    return !_.isEmpty(this.getFilledTextLocations());
  }

  doesViewAllowEntityType(viewKey, entityType) {
    if (!viewKey || !entityType) { return false; }
    let locations = this.getAllViewLocations(viewKey);
    return !!_.find(locations, location => {
      return this.doesLocationAllowEntityType(location, entityType);
    });
  }

  doesViewLocationAllowEntityType(viewKey, locationCode, entityType) {
    let location = this.getViewLocation(viewKey, locationCode);
    return location && this.doesLocationAllowEntityType(location, entityType);
  }

  doesLocationAllowEntityType(location, entityType) {
    if (!entityType) { return false; }
    return !!_.find(location && location.allowedEntities, { type: entityType });
  }

  doesLocationAllowArtEntityTypes(location) {
    return !!_.find(location && location.allowedEntities, v => { return this.isArtEntityType(v && v.type); });
  }

  doesLocationAllowTextEntityTypes(location) {
    return !!_.find(location && location.allowedEntities, v => { return this.isTextEntityType(v && v.type); });
  }

  isArtEntityType(entityType) {
    return _.includes(Constants.ArtEntityTypes, entityType);
  }

  isTextEntityType(entityType) {
    return _.includes(Constants.TextEntityTypes, entityType);
  }

  getProductEntityTypes(sort = true) {
    return this.getLocationEntityTypes(this.getAllLocations(), sort);
  }

  getProductArtEntityTypes(sort = true) {
    return this.getLocationArtEntityTypes(this.getAllLocations(), sort);
  }

  getProductTextEntityTypes(sort = true) {
    return this.getLocationTextEntityTypes(this.getAllLocations(), sort);
  }

  getLocationEntityByEntityType(location, entityType) {
    return _.find(location && location.allowedEntities, { type: entityType }) || null;
  }

  getLocationEntityTypes(locations, sort = true) {
    if (!_.isArray(locations)) {
      locations = _.compact([ locations ]);
    }
    let entityTypes = _.chain(locations)
      .map(v => {
        return _.map(v.allowedEntities, 'type');
      })
      .flatten()
      .uniq()
      .compact()
      .value();

    if (sort) {
      entityTypes = _.sortBy(entityTypes, v => {
        return _.indexOf(Constants.OrderedEntityTypes, v);
      });
    }
    return entityTypes;
  }

  getLocationArtEntityTypes(locations, sort = true) {
    let entityTypes = this.getLocationEntityTypes(locations, sort);
    return _.filter(entityTypes, this.isArtEntityType.bind(this));
  }

  getLocationTextEntityTypes(locations, sort = true) {
    let entityTypes = this.getLocationEntityTypes(locations, sort);
    return _.filter(entityTypes, this.isTextEntityType.bind(this));
  }

  getLocationAllowedEntities( locationCode ){
    let bpLocations = this.getStateVal('baseProduct.locations');
    let allowedEntities = _.find( bpLocations, {code: locationCode}).allowedEntities;
    return allowedEntities;
  }

  getLocationEntityColorInfo(entity) {

    let fillColors = _.get(entity, 'capabilities.fillColors');
    let colors = _.compact(_.map(fillColors, fillColor => {
      return _.get(fillColor, 'value');
    }));

    let uniqueColors = _.uniq(colors);
    let decorationColors = uniqueColors;

    // apparently the decoration pricing for some decoration methods
    // is based off the number of unique colors rather than the
    // total number of colors
    let numColors = _.size(colors);
    let numUniqueColors = _.size(uniqueColors);
    let numDecorationColors = null;

    if (this.isArtEntityType(entity.type)) {
      numDecorationColors = entity.numDecorationColors;
    } else {
      switch (entity.decorationMethod) {
        case 'screenprint-silicone':
        case 'screenprint-plastisol':
          numDecorationColors = numUniqueColors;
          break;
      default:
          numDecorationColors = numColors;
          break;
      }
    }

    return {
      numColors,
      numUniqueColors,
      numDecorationColors,
      colors,
      uniqueColors,
      decorationColors
    };
  }

  // upgrades helpers

  getUpgradeOptions(upgradeKey) {
    let upgrades = this.getStateVal('customConfig.upgrades');
    return _.find(upgrades, { key: upgradeKey }) || null;
  }

  getEnabledUpgrades() {
    let upgrades = this.getStateVal('customConfig.upgrades');
    return _.filter(upgrades, v => {
      return v && _.get(v, 'value', false);
    });
  }
}

// export a static singleton instance
const locationsHelper = new LocationsHelper();
export default locationsHelper;


