import { RestService } from '../lib';

/**
 * Service Endpoint Implementation
 */
export default class MockupService extends RestService {

    constructor(opts = null) {
        super(opts);
        this.serviceName = 'mockUp';
        this.routePath = 'builder/erp/configs';
    }
}


