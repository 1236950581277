import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import helpers from '../../../../helpers';
import AddUploadArt from './addUploadArt';
import OptionHeading from '../../../global/optionHeading/OptionHeading';
import LocationSummary from '../addText/locationSummary/locationSummary';
import EditDrawer from '../editDrawer/EditDrawer';
import MyUploads from './artGallery/MyUploads';
class UploadArt extends Component {

  renderPage() {
    let navObject = _.get(this.props, "navigation.activeTab", null);
    let page = _.get(navObject, "page", "");
    let hasCustomLocations = _.filter(this.props.customConfig.locations, x => (x.entity.type === "custom-art" && x.entity.value !== 'system-art'));
    if (hasCustomLocations.length > 0) {
      let SummryNotes = "User has uploaded Custom Art"
      this.props.customConfig.summaryNotes = SummryNotes
    } else {
      let SummryNotes = ""
      this.props.customConfig.summaryNotes = SummryNotes
    }
    switch (page) {
      // case "main": return this.renderMain(hasCustomLocations); 
      case "add": return (<div><AddUploadArt activeArtTab={this.props.activeArtTab} used={hasCustomLocations} /></div>);
      case "sub-add-art": return (<MyUploads />);
      case "sub-edit": return (<EditDrawer />);
      default: return (<div> Default </div>);
    }
  }

  renderMain(customLocations) {
    if (!customLocations.length > 0) {
      return (<div><AddUploadArt used={customLocations} /></div>);
    }
    return (<div id="drawer">
      <OptionHeading
        backFunction={false}
        title={"Uploaded Art"}
        description={"Upload custom artwork to use in your design."} />
      <div className="option-body">
        <LocationSummary
          title={"Art"}
          value={"art"}
          availableLocations={helpers.product.getOpenLocations(this.props.baseProduct.locations, this.props.customConfig.locations)}
          filters={['custom-art']} />
      </div>
    </div>);

  }

  render() {
    return (
      <div>
        {this.renderPage()}
      </div>
    )
  }
}

function MapStateToProps(state) {
  return {
    navigation: state.navigation,
    baseProduct: state.baseProduct,
    customConfig: state.customConfig,
    toolTips: state.toolTips
  }
}

export default connect(MapStateToProps, {})(UploadArt);
