// LOGIC KEYS TO DISPLAY VALUES
// EG:

// eventually generate from loaded defs state and appConstants?
const defaultConfig = [
  {key: 'center-front', value: 'Center Front'},
  {key: 'center-back', value: 'Center Back'},
  {key: 'upper-back', value: 'Upper Back'},
  {key: 'left-chest', value: 'Left Chest'},
  {key: 'screenprint-silicone', value: 'Silicone'},
  {key: 'screenprint-duratek', value: 'Duratek'},
  {key: 'screenprint-plastisol', value: 'Plastisol'},
  {key: 'sublimation', value: 'Sublimation'},
  {key: 'custom-text', value: 'Custom Text'},
  {key: 'custom-art', value: 'Custom Art'},
  {key: 'player-name', value: 'Player Name'},
  {key: 'player-number', value: 'Player Number'},
  {key: 'artwork', value: 'Artwork'},
  {key: 'CB', value: 'Center Back'},
  {key: 'UB', value: 'Upper Back'},
  {key: 'LC', value: 'Left Arm'},
  {key: 'CF', value: 'Center Front'},
  {key: 'default', value: 'Default'},
  {key: 'front', value: 'Front'},
  {key: 'back', value: 'Back'},
  {key: 'left', value: 'Left'},
  {key: 'right', value: 'Right'},
  {key: 'side', value: 'Side'},
  {key: 'stitching', value: 'Stitching'},
  {key: 'braid', value: 'Braid'},
  {key: 'braid-3-16in', value: '3/16" Braid'},
  {key: 'braid-3-8in', value: '3/8" Braid'},
  {key: 'braid-1in', value: '1" Braid'},
  {key: 'fabric', value: 'Fabric'},
];

export default function translateKey(config = defaultConfig){
  return (logicStr, defaultDisplayVal = null) => {
    const match = config.find(opt => opt.key === logicStr);
    return match ? match.value : defaultDisplayVal;
  }
}
