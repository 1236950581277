import React, { Component } from 'react';
import Patterns from '../patterns/patterns';
import { connect } from 'react-redux';
import _ from 'lodash';
import { updatePattern } from '../../../../../redux/reducers/customConfig/customConfigActions';
import { patternHover, clearHover } from "../../../../../redux/reducers/hover/hoverActions";
import helpers from "../../../../../helpers";

class ChromaPatterns extends Component {

  onPatternChange = (pattern, index, loadedDefs) => {
    let baseProduct = this.props.baseProduct;
    this.props.updatePattern(this.props.customConfig, pattern, index, loadedDefs, baseProduct);
    this.props.clearHover();
  }

  handleMouseEnter = pattern => {
    if (pattern) {
      if (helpers.customLayers.isAdvancedMode()) {
        let colors = _.get(this.props.customConfig, "customLayers.primaryPattern.colors");
        let layerColorArray = [];
        _.each(colors, color => {
          let layerColorKey = helpers.colors.getColorKeyFromColorCode(color);
          layerColorArray.push(layerColorKey);
        })
        let layerColorsJoined = layerColorArray.join(",");
        pattern = { [pattern]: layerColorsJoined };
      }
      this.props.patternHover(pattern);
    } else {
      this.props.clearHover();
    }
  }

  render() {
    const { customConfig, baseProduct, loadedDefs } = this.props
    let defaultPatterns = customConfig.customLayers.primaryPattern;
    let defaultColors = _.get(baseProduct, "customLayers.defaultColors");
    let baseProductLayers = _.get(baseProduct, "customLayers.layers");
    let loadedDefPatterns = loadedDefs.layerPatterns;
    let toolTip = _.find(this.props.toolTips, { key: "pattern-dropdown" }).message;
    return (
      <div>
        {loadedDefPatterns ?
          <Patterns
            toolTip={toolTip}
            advancedColors={null}
            defaultPatterns={defaultPatterns}
            defaultColors={defaultColors}
            baseProductLayers={baseProductLayers}
            loadedDefPatterns={loadedDefPatterns}
            loadedDefs={loadedDefs}
            onHover={e => this.handleMouseEnter(e)}
            onChange={this.onPatternChange}
            customConfig={customConfig}
          />
          : null}
      </div>
    )
  }
}

const reduxActions = {
  updatePattern,
  patternHover,
  clearHover

}

function mapStateToProps(state) {
  return {
    customConfig: state.customConfig,
    baseProduct: state.baseProduct,
    loadedDefs: state.loadedDefs,
    toolTips: state.toolTips
  }
}
export default connect(mapStateToProps, reduxActions)(ChromaPatterns);