import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setActiveTab } from '../../../../redux/reducers/navigation/navigationActions';
import { SetArtSubtab } from "../../../../redux/reducers/decoration/decorationAction";
import ArtGallery from './artGallery/ArtGallery';
import FileUploader from '../../../global/fileUploader/fileUploader';
class AddUploadArt extends Component {

  isArtOptions = this.props.activeArtTab === "" ? true : false;
  artLocker = this.props.activeArtTab === "my-art-locker" ? true : false;
  newGraphic = this.props.activeArtTab === "new-graphic" ? true : false;
  artLockerClass = "";
  newGraphicClass = "";

  onArtLocker = () => {
    this.artLocker = true;
    this.newGraphic = false;
    this.artLockerClass = "active-art-header";
    this.newGraphicClass = "";
    this.props.SetArtSubtab("my-art-locker");
    let tabObj = { tab: "decorations", page: "sub-add-art", subTab: "add-art" }
    this.props.setActiveTab(tabObj);
  }
  onNewGraphic = () => {
    this.newGraphic = true;
    this.artLocker = false;
    this.artLockerClass = "";
    this.newGraphicClass = "active-art-header";
    this.props.SetArtSubtab("new-graphic");
  }

  render() {
    return (
      <div>
        {this.props.user.userArtAssets.length > 0 ?
          <div className="MyUploads">
            <div className="row artHeader pb-3">
              <div className={`col-6 ${this.artLockerClass} graphic-heading text-center`} onClick={this.onArtLocker}>
                <div className="class-pointer">
                  My Art Locker
                </div>
              </div>
              <div className={`col-6 ${this.newGraphicClass} graphic-heading text-center`} onClick={this.onNewGraphic}>
                <div className="class-pointer">
                  New Graphic
                </div>
              </div>
            </div>
            <div className="row artOptions pl-1 pr-2">
              <div className="col-6 pr-1">
                <div className="card  art-card">
                  <div className="card-body">
                    <div onClick={this.onArtLocker}> <ArtGallery count="4" /></div>
                  </div>
                </div>
                <div className="art-options-body pt-3">Choose from your library of previously uploaded and pre-approved graphics</div>
              </div>
              <div className="col-6 pl-1">
                <div className="card art-card">
                  <div className="card-body file-upload-card">
                    <FileUploader
                      fileUpload="image"
                      allowedFiles={['svg', 'jpg', 'jpeg', 'png', 'gif', 'bmp', 'pdf', 'ai', 'eps', 'pds']}
                      message="CLICK TO UPLOAD   " />
                  </div>
                </div>
                <div className="art-options-body pt-3">Upload a new custom graphic. Art approval & vectorization will be required for this order</div>
              </div>
            </div>
          </div> :
          <div className="MyUploads">
            <div className="row artHeader pb-3">
              <div className={`col-12 ${this.newGraphicClass} graphic-heading text-center`} onClick={this.onNewGraphic}>
                <div className="class-pointer">
                  New Graphic
                </div>
              </div>
            </div>
            <div className="row artOptions pl-1 pr-2">
              <div className="col-12 pl-3 pr-3">
                <div className="card art-card">
                  <div className="card-body file-upload-card">
                    <FileUploader
                      fileUpload="image"
                      allowedFiles={['svg', 'jpg', 'jpeg', 'png', 'gif', 'bmp', 'pdf', 'ai', 'eps', 'pds']}
                      message="CLICK TO UPLOAD   " />
                  </div>
                </div>
                <div className="art-options-body pt-3">Upload a new custom graphic. Art approval & vectorization will be required for this order</div>
              </div>
            </div>
          </div>}
      </div>

    )
  }
}
const reduxActions = {
  SetArtSubtab,
  setActiveTab
}
function mapStateToProps(state) {
  return {
    user: state.user,
    navigation: state.navigation,
  }
}
export default connect(mapStateToProps, reduxActions)(AddUploadArt);