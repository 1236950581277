import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import helpers from "../../../../../../helpers";
import {
  setPendingLocation,
  setActiveTab,
  previewSample,
  setCustomTextInput,
  setView,
  setFlipArtInput,
} from "../../../../../../redux/reducers/navigation/navigationActions";
import {
  addPendingLocationToLocations,
  changeSetting,
} from "../../../../../../redux/reducers/customConfig/customConfigActions";
import { locationUsed } from "../../../../../../redux/reducers/modal/modalActions";
import { SetDecorationSubtab } from "../../../../../../redux/reducers/decoration/decorationAction";
import { dragItem } from "../../../../../../redux/reducers/navigation/navigationActions";
import { setArtAssetToDefault } from "../../../../../../redux/reducers/user/userActions";
import { editUserArtDataModal } from "../../../../../../redux/reducers/modal/modalActions";
import PencilSVG from "../../../../../../assets/icons/PencilSVG";
import addUploadArt from "../../addUploadArt";
import EditDrawer from "../../../editDrawer/EditDrawer";
import FlipArtSVG from "../../../../../../assets/icons/FlipArtSVG";
// import RotateArtSVG from '../../../../../../assets/icons/RotateArtSVG';
import BackArrowSVG from "../../../../../../assets/icons/BackArrowSVG";
let rotateImg = require("../../../../../../../src/assets/images/Rotateimg1.png");

class ArtGalleryCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flip: this.props.flipVal ? this.props.flipVal : false,
      rotateAngle: this.props.rotateVal ? this.props.rotateVal : 0,
    };

    if (this.props.flipVal) {
      this.props.setFlipArtInput(this.props.flipVal);
    }
    // if(this.props.rotateVal){
    //   this.props.setRotateArtInput(this.props.rotateVal);
    // }
    this.handleOnFlip = this.handleOnFlip.bind(this);
    this.handleOnRotate = this.handleOnRotate.bind(this);
    this.handleOnBackLib = this.handleOnBackLib.bind(this);
  }

  componentDidMount() {
    this.renderPlaceholder(this.props.item.url);
  }

  handleOnClick = () => {
    let type = "custom-art";
    let view = _.get(this.props, "navigation.activeView");
    let code = _.get(this.props, "navigation.activeLocation");
    let artAsset = this.props.item;

    let pendingLocation = helpers.navigation.makePendingLocation(
      type,
      "",
      view,
      code,
      artAsset
    );
    this.props.setPendingLocation(pendingLocation);
    if (!this.props.artOptions) {
      let groupName = this.props.baseProduct.locations.filter(
        (v) => v.code === pendingLocation.code
      );
      let locationCodeA = pendingLocation.code;
      this.props.previewSample(
        this.props.baseProduct,
        locationCodeA,
        null,
        pendingLocation
      );

      let isLocationFilled = _.find(this.props.customConfig.locations, {
        code: pendingLocation.code,
      });
      if (isLocationFilled) {
        let sourceLocation = pendingLocation;
        let targetLocation = isLocationFilled;
        // this.props.locationUsed(sourceLocation, targetLocation);
      } else {
        let tabObj = {
          tab: this.props.navigation.activeTab.tab,
          subTab: "add-art",
          page: "sub-edit",
        };
        this.props.setActiveTab(tabObj);
        let subTab = "GRAPHIC";
        this.props.SetDecorationSubtab(subTab);
        this.props.addPendingLocationToLocations(
          this.props.customConfig.locations,
          pendingLocation,
          groupName
        );
      }
      this.props.setCustomTextInput("");
      this.props.setPendingLocation(null);
      this.props.setView(pendingLocation.view);
    }
  };

  handleDrag = () => {
    // if (!this.props.navigation.drag) {
    this.handleOnClick();
    this.props.dragItem(true);
    // }
  };

  myClassName = () => {
    let pendingLocationUserArtId = _.get(
      this.props.navigation.pendingLocation,
      "entity.userArtId",
      null
    );
    if (pendingLocationUserArtId === this.props.item.id) {
      return "active-art-gallery-card";
    }
    return "";
  };

  renderEditMode = () => {
    if (this.props.navigation.editUserAssestsMode) {
      return (
        <div className="art-edit-mode">
          <button
            className="btn btn-edit btn-round btn-icon"
            onClick={() => {
              this.props.editUserArtDataModal(this.props.item);
            }}
          >
            <PencilSVG width="15" height="15" />
          </button>
        </div>
      );
    } else if (
      this.props.navigation.activeTab.tabEdit &&
      this.props.navigation.activeTab.tabEdit == "art"
    ) {
      return <EditDrawer />;
    }
    return null;
  };

  renderPlaceholder(url) {
    let splitUrl = url.split(".");
    let fileType = splitUrl[splitUrl.length - 1];
    if (
      fileType === "ai" ||
      fileType === "pdf" ||
      fileType === "eps" ||
      fileType === "pds"
    ) {
      let id = this.props.item.id;
      let userArtAssets = this.props.user.userArtAssets;
      const placeholder = require("../../../../../../assets/images/artwork-placeholder.png");
      this.props.item.url = placeholder;
      this.props.setArtAssetToDefault(id, placeholder, userArtAssets);
    }
  }

  async handleOnFlip() {
    if (this.state.flip == true) {
      await this.setState({ flip: false });
    } else {
      await this.setState({ flip: true });
    }
    this.props.setFlipArtInput(this.state.flip);
    this.props.changeSetting(
      this.props.customConfig.locations,
      this.props.navigation.activeLocation,
      { setting: "flipArt", value: this.state.flip }
    );
  }

  handleOnRotate = () => {
    let locationContent = _.find(this.props.customConfig.locations, {
      code: this.props.navigation.activeLocation,
    });
    let rotateVal =
      this.state.rotateAngle + 15 === 360 ? 0 : this.state.rotateAngle + 15;
    this.setState({ rotateAngle: rotateVal });
    let newBounds = _.cloneDeep(locationContent.entity.bounds);
    newBounds.r = rotateVal;
    this.props.changeSetting(
      this.props.customConfig.locations,
      this.props.navigation.activeLocation,
      { setting: "bounds", value: newBounds }
    );
    this.props.changeSetting(
      this.props.customConfig.locations,
      this.props.navigation.activeLocation,
      { setting: "rotateArt", value: rotateVal }
    );
  };

  preventDragDefault = (e) => {
    e.preventDefault();
  };

  async handleOnBackLib() {
    let tabObj = { tab: "decorations", subTab: "add-art", page: "add" };
    this.props.setActiveTab(tabObj);
    let subTab = "GRAPHIC";
    this.props.SetDecorationSubtab(subTab);
  }

  getImgClassName() {
    let imgFlip = this.state.flip ? "flipImageX" : "";
    let rotateAngleClass = "rotate-img";
    let imgRotate = this.state.rotateAngle ? this.state.rotateAngle : "";
    if (imgFlip !== "" && imgRotate !== "") {
      return `flip-${rotateAngleClass}`;
    } else if (imgFlip !== "" && imgRotate === "") {
      return "flipImageX";
    } else if (imgFlip === "" && imgRotate !== "") {
      return rotateAngleClass;
    }
    return "";
  }

  render() {
    let artOptionsClass = this.props.artOptions
      ? " art-gallery-card-options"
      : " card art-gallery-card";
    let imgClass = this.getImgClassName();
    let pageEdit = this.props.page === "edit" ? true : false;
    if (this.props.artOptions) {
      return (
        <div>
          <div
            className={`${artOptionsClass} btn`}
            onClick={this.handleOnClick}
            onDragStart={this.preventDragDefault}
          >
            <img src={this.props.item.url} alt="art" crossOrigin="anonymous" />
          </div>
        </div>
      );
    }
    return (
      <div>
        {pageEdit ? (
          <div className="row pl-4 pr-4">
            <div
              className="col-12 card pl-2 pr-2 custom-card card-body"
              onClick={this.handleOnClick}
              onDrag={this.handleDrag}
            >
              <div className="row">
                <div className="col-1"></div>
                <div className="col-2 align-svg">
                  <div type="button" onClick={this.handleOnFlip}>
                    <FlipArtSVG />
                  </div>
                </div>
                <div className="col-6 pt-1 pb-1">
                  <div className="text-center img-grid">
                    <img
                      src={this.props.item.url}
                      alt="art"
                      width="50"
                      height="50"
                      crossOrigin="anonymous"
                      className={`${imgClass} artImage`}
                      style={{
                        "--angle": `rotate(${this.state.rotateAngle}deg)`,
                      }}
                    />
                  </div>
                </div>
                <div className="col-2 align-svg">
                  <div type="button">
                    <img
                      src={rotateImg}
                      alt="rotate"
                      width="20"
                      height="25"
                      onClick={this.handleOnRotate}
                    />
                  </div>
                </div>
                <div className="col-1"></div>
              </div>
            </div>
          </div>
        ) : (
          // : pageEdit && !editDecType ?
          //   <div className="row">
          //     <div className="col-4 btn " onClick={this.handleOnBackLib}>
          //       <div className="row">
          //         <div className="col-1 button-back-to-library">
          //           <BackArrowSVG
          //             fill="#b4b4b4"
          //             height="35"
          //             width="24"
          //             strokeWidth="1.5"
          //             viewBox="0 0 24 24"></BackArrowSVG></div>
          //         <div className="col-7 pl-0 button-back-to-library"> BACK TO LIBRARY</div>
          //         <div className="col-4"></div>
          //       </div>
          //     </div>
          //     <div
          //       className={`col-4 ${artOptionsClass} btn ${this.myClassName()}`}
          //       onClick={this.handleOnClick}
          //       onDrag={this.handleDrag}>
          //       <div className="row">
          //         <div className="col-12 mt-2 pt-3">
          //           <img src={this.props.item.url} alt="art" crossOrigin="anonymous" className={imgFlip} />
          //         </div>
          //         <div className="col-12 d-flex justify-content start align-items-end pb-0 pl-0 mt-2 pt-2">
          //           <div className="" onClick={this.handleOnFlip}><FlipArtSVG /></div>
          //         </div>
          //       </div>
          //     </div>
          //     <div className="col-3"></div>
          //   </div>
          <div
            className={`art-gallery btn ${this.myClassName()}`}
            onClick={this.handleOnClick}
            onDrag={this.handleDrag}
          >
            <img src={this.props.item.url} alt="art" crossOrigin="anonymous" />
          </div>
        )}
        {/* {this.renderEditMode()} */}
      </div>
    );
  }
}

const reduxActions = {
  setPendingLocation,
  editUserArtDataModal,
  dragItem,
  setArtAssetToDefault,
  setActiveTab,
  previewSample,
  addPendingLocationToLocations,
  setCustomTextInput,
  setView,
  locationUsed,
  SetDecorationSubtab,
  setFlipArtInput,
  changeSetting,
};

function mapStateToProps(state) {
  return {
    navigation: state.navigation,
    user: state.user,
    baseProduct: state.baseProduct,
    customConfig: state.customConfig,
    decoration: state.decoration,
  };
}

export default connect(mapStateToProps, reduxActions)(ArtGalleryCard);
