import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { FaPencilAlt } from "react-icons/fa";
import _ from 'lodash';
import { SetCutomArtName } from "../../../../../redux/reducers/decoration/decorationAction";


function EditArtName(props) {
  const artName = useRef(null);
  let artAsset = _.find(props.user.userArtAssets, { url: props.locationContent.entity.url });

  let changeFileName = _.debounce((text) => {
    props.SetCutomArtName(text);
  }, 1000);


  function handleFocus() {
    artName.current.focus()
  }

  return (
    <div className="row pl-4 pr-4">
      <div className="col-12 pl-2 pr-2">
        <div className="row">
          <div className="col-2"> </div>
          <div className="col-8">
            <div className="input-group mb-3">
              <input type="text" className="form-control text-height-number-input-text-value border-0 art-name-input rounded-0"
                placeholder={artAsset.name}
                aria-describedby="basic-addon2"
                onChange={(e) => changeFileName(e.target.value)}
                ref={artName} />
              <div className="input-group-append">
                <span className="input-group-text border-0 bg-white class-pointer" id="basic-addon2" onClick={handleFocus}>
                  <FaPencilAlt />
                </span>
              </div>
            </div>
          </div>
          <div className="col-2"></div>
        </div>
      </div>
    </div>
  );
}

const reduxActions = {
  SetCutomArtName,
}

function mapStateToProps(state) {
  return {
    navigation: state.navigation,
    baseProduct: state.baseProduct,
    customConfig: state.customConfig,
    decoration: state.decoration,
    user: state.user,
  }
}

export default connect(mapStateToProps, reduxActions)(EditArtName);