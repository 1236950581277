import React, { Component } from 'react';
import UltimateBuilder from './components/ultimateBuilder/UltimateBuilder';
import 'bootstrap/dist/css/bootstrap.min.css';
// import ApiTestSandbox from "./components/sandbox/apiTestSandbox/apiTestSandbox";
// import ConfigSandbox from "./components/sandbox/configSandbox/configSandbox";

class App extends Component {
  render() {
    return (
      <div className="App">
        {/* <ApiTestSandbox /> */}
        {/* <ErrorWarningsNotifications> */}
        <UltimateBuilder/>
        {/* </ErrorWarningsNotifications> */}
        {/* <ConfigSandbox /> */}
      </div>
    );
  }
}

export default App;
