import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import EditLocationPicker from '../../../../global/editLocationPicker/editLocationPicker'; 
import { locationEditHover, clearHover } from "../../../../../redux/reducers/hover/hoverActions";
import { moveToEmptyLocation, updateLocationsBound} from "../../../../../redux/reducers/customConfig/customConfigActions";
import { locationUsed } from "../../../../../redux/reducers/modal/modalActions";
import { setActiveLocation, setView, previewSample } from "../../../../../redux/reducers/navigation/navigationActions";
import helpers from '../../../../../helpers';

function EditTextLocationPicker (props) {

  let locationContent = _.find(props.customConfig.locations, {code: props.navigation.activeLocation});
  let view  = locationContent.view;
  let locationName = _.find( props.baseProduct.locations, {code: locationContent.code}).name;
  let availableLocations = helpers.locations.getAllEntityLocations(locationContent.entity.type); 

  const swapOrReplace = (e, currentLocationCode) => {
    props.clearHover();
    let chosenLocationCode = e && e.code;
    
    if( !chosenLocationCode || chosenLocationCode === locationContent.code ){ return null; }

    let chosenLocation = helpers.locations.getLocation(chosenLocationCode); 
    let chosenLocationEntityData = helpers.locations.getCustomConfigLocationEntityData(chosenLocationCode);

    // if there is nothing in the location move entity to selected location
    if ( !chosenLocationEntityData ){
      let sourceLocationCode = props.navigation.activeLocation;
      let targetLocationCode = e.code;
      props.moveToEmptyLocation( props.customConfig.locations, sourceLocationCode, targetLocationCode )
      props.updateLocationsBound( sourceLocationCode)
      props.setActiveLocation(chosenLocationCode, props.navigation);
      props.setView(chosenLocation && chosenLocation.view); 
    } else {
      // else something is in that location, trigger swap and replace modal.
      let sourceLocation = _.find( props.customConfig.locations, { code: props.navigation.activeLocation});
      let targetLocation = _.find( props.customConfig.locations, { code: e.code });
      props.previewSample(props.baseProduct, sourceLocation.code, targetLocation.code, props.navigation.pendingLocation);
      props.locationUsed(  sourceLocation, targetLocation, true );
    }
  }

  const hoverActiveLocation = ( location ) =>{
    props.locationEditHover( location );
  }

  return (
    <div className="EditTextLocationPicker">
      <EditLocationPicker
        view={view}
        selectedLocation={locationName}
        currentLocations={availableLocations}
        hover={hoverActiveLocation}
        change={swapOrReplace} 
      />
    </div>
  )
}

const reduxActions = {
  locationEditHover,
  clearHover,
  moveToEmptyLocation,
  updateLocationsBound,
  setActiveLocation,
  setView,
  locationUsed,
  previewSample
}

function mapStateToProps (state){
  return {
    navigation : state.navigation,
    baseProduct: state.baseProduct,
    customConfig: state.customConfig
  }
}

export default connect(mapStateToProps, reduxActions)(EditTextLocationPicker);