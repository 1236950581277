import React from "react";

const FlipArtSVG = function(props) {
  const { className, height=22, width=24, fill="black", stroke="black", strokeWidth="0", onClick } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height={height} viewBox="0 0 24 24" width={width} fill="#000000" onClick={onClick}>
        <path d="M7 7h10v1.79c0 .45.54.67.85.35l2.79-2.79c.2-.2.2-.51 0-.71l-2.79-2.79c-.31-.31-.85-.09-.85.36V5H6c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1s1-.45 1-1V7zm10 10H7v-1.79c0-.45-.54-.67-.85-.35l-2.79 2.79c-.2.2-.2.51 0 .71l2.79 2.79c.31.31.85.09.85-.36V19h11c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1v3z"/></svg>
  );
};

export default FlipArtSVG;
