
import React, { Component } from 'react'; 
import { connect } from 'react-redux'; 
import _ from 'lodash'; 
import SystemArtSearchBar from '../../../sidebar/tabs/addArt/AssetLibrary/assetElements/SystemArtSearchBar'; 
import AssetSearchInterface from './AssetSearchInterface'; 
import api from '../../../../api'; 
import { changeCatalogNavigation, updateArtAssets, updateSearchCategoryTerm } from '../../../../redux/reducers/systemArt/systemArtActions'; 
import stuff from './testStuff'; 
let searchResults = []; 

class SystemArtModal extends Component {
  constructor(props){
    super(props); 
    this.state = {
      searchValue: '', 
      searchResults: [] 
    }
  }

  useSearchBar = (event) => {
    searchResults = []; 
    let searchValue = _.get( event, "target.value" , ""); 
    this.props.updateSearchCategoryTerm(searchValue); 
    searchValue = _.trim(searchValue); 
    if (searchValue !== ""){
      this.searchForTerm(stuff, searchValue, []);
    }
  } 

  searchForTerm = (treeObj, termString, depthArray) => {
    let { children, key } = treeObj; 
    let depth = _.clone(depthArray);
    depth.push(key); 
    if (children){
      _.each(children, (child) =>{
        (this.searchForTerm(child, termString, depth)); 
      });
    }
    let matchFound = (key.includes(termString)  && (key !== "all")); 
    if (matchFound){ 
      searchResults = [...searchResults, Object.assign({}, {key, depth} )]; 
    }
}




  searchAPI = async (mainValue) => {
    this.props.updateSearchCategoryTerm(mainValue); 
    api.logos.getAllItems().then((res) => {
      let items = res.data; 
      _.each(items, (item) => {
        item.url = `http://api.dev.foundersportgroup.com/api/custom-mascot-logos/${item.id}/render`; 
      }); 
      this.props.updateArtAssets(items); 
      this.props.toggle();
    }); 
  }

  render() {
    return (
      <div>
        <SystemArtSearchBar 
          search={this.useSearchBar} 
          searchValue={this.props.systemArt.searchCategoryTerm} />
         <AssetSearchInterface 
          results = {searchResults}
          searchTerm={this.props.systemArt.searchCategoryTerm}
          catalog = {stuff}
          search={this.searchAPI} 
          setTitle={() => null}
          setNav={this.props.changeCatalogNavigation}
          nav={ this.props.systemArt.catalogNavigation } />
        </div>
    )
  }
}

function mapStateToProps(state){
  return {
    systemArt: state.systemArt
  }; 
}

export default connect (mapStateToProps, { changeCatalogNavigation, updateArtAssets, updateSearchCategoryTerm })(SystemArtModal); 
