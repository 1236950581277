import React, { Component } from 'react';
import { connect } from 'react-redux';
import SublimatedProductsDesignImageLoader from './SublimatedProductsDesignImageLoader';
import { SublimatedProductDetailsModal, saveModal } from "../../../src/redux/reducers/modal/modalActions";
import { messageIframe } from "../../redux/reducers/notifications/notificationsActions";
import ChromagearBadgerSVG from "../../assets/icons/ChromagearBadgerSVG";

class SublimatedProduct extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isShown: false,
    }
  }
  render() {
    return (
      <div className="row pl-4">
        {
          this.props.data.map((list, index) => (
            <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-xs-4 pt-2 row-center pl-0 pr-0 pt-3 pb-3" key={index}>
              <div className="card card-hover">
                <div className="button-wrapper">
                  <button type="button" className="btn btn-light btn-sm button-hover" onClick={() => { this.props.SublimatedProductDetailsModal(list, this.props.style, list['name'], list['configId']) }}>QUICK<br />VIEW</button>
                </div>
                <div className="d-flex justify-content-center">
                  <button type="button" className="btn btn-black custom-button-hover" onClick={() => {
                    let data = {
                      style: this.props.style,
                      design: list['designNum'],
                    };
                    this.props.messageIframe('launch-builder-app', data);
                    // window.open("/?SKU=" + this.props.styleNum + "&designNum=" + this.designData.designNum,"_self") 
                  }
                  } >CUSTOMIZE  <ChromagearBadgerSVG></ChromagearBadgerSVG></button>
                </div>
                <div onClick={() => { this.props.SublimatedProductDetailsModal(list, this.props.style, list['name'], list['configId']) }} >
                  <SublimatedProductsDesignImageLoader src={list['thumbnailURL']} alt={list['designNum']}></SublimatedProductsDesignImageLoader>
                </div>
                {/* <img src={list['thumbnailURL']} alt="design" className="card-img-top" /> */}
                <div className="card-body p-1">
                  <div className="text-center card-details text-uppercase" onClick={() => { this.props.SublimatedProductDetailsModal(list, this.props.style, list['name'], list['configId']) }} >
                    {list['name']}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    toolTips: state.toolTips,
    baseProduct: state.baseProduct,
    customConfig: state.customConfig,
    launchContext: state.launchContext
  }
}
const reduxActions = {
  SublimatedProductDetailsModal,
  saveModal,
  messageIframe
}

export default connect(mapStateToProps, reduxActions)(SublimatedProduct);