import React from "react";
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Tooltip } from 'reactstrap'
import BaseInputComponent from "../baseInputComponent/baseInputComponent";
import ColorSwatch from "./colorSwatch"
import NewColorSwatch from './NewColorSwatch';
import NewColorSwatchGroup from "./NewColorSwatchGroup";
import ColorSwatchGroup from "./colorSwatchGroup";

const modifiers = {
  setMaxHeight: {
    enabled: true,
    order: 890,
    fn: (data) => {
      return {
        ...data,
        styles: {
          ...data.styles,
          overflow: 'auto',
          maxHeight: 300,
        },
      };
    },
  },
}

export default class ColorPicker extends BaseInputComponent {

  // NOTE: if you extend propTypes in child component class, you will get a react warning.
  static get propTypes() {
    return {
      // eslint-disable-next-line
      ...super.propTypes,
      colorOptions: PropTypes.array
    }
  };

  static get defaultProps() {
    return {
      ...super.defaultProps,
      colorOptions: [],
      dropdownHeader: 'Select a color'
    }
  };

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.onSwatchSelected = this.onSwatchSelected.bind(this);
    //this.onSwatchHovered = this.onSwatchHovered.bind(this);

    this.state = {
      ...this.state,
      hoverColor: null,
      isDropdownOpen: !!this.props.isOpen
    };
  }

  toggle() {
    this.props.toggleDelete(!this.state.toggle);
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen
    });
  }

  onSwatchSelected(colorKey) {
    this.setValue(colorKey);
  }

  onSwatchHovered(hoverColorDef, groupIndex) {
    if (this.state.hoverColor !== hoverColorDef) {
      this.setState({ hoverColor: hoverColorDef, groupIndex: groupIndex });
      this.setHover(hoverColorDef)
    }
  }

  renderChildComponent() {
    let colorOptions = this.props.colorOptions || [];
    let isStock = this.props.isStock;

    let chromagearStyle = null;
    if (this.props.chromagear) {
      chromagearStyle = {
        width: "75px",
        height: "75px"
      }
    }

    return (
      <div className={`color-picker ${this.props.disabled ? "disabled-color-picker" : ""}`}>
        <Dropdown isOpen={this.state.isDropdownOpen} toggle={this.toggle}>
          <div aria-label={this.props.toolTip} data-balloon-pos={this.props.toolTipDirection} className={`color-picker-container`}>
            <DropdownToggle style={chromagearStyle} className={`dropdown-toggle-button`}>
              <div style={chromagearStyle} className={`dropdown-toggle-button-content`}>
                <ColorSwatch className={'dropdown-toggle-color-swatch'} customFooter={this.props.customFooter} customStyle={this.props.chromagear} value={this.state.value} isDisabled={this.props.disabled} watchProps={true} />
              </div>
              {/* <div className="carent-container">
                <div className="caret"></div>
              </div> */}
            </DropdownToggle>
          </div>
          {this.props.chromagear ?
            <div>
              <DropdownMenu modifiers={modifiers} className="dropdown-menu fancy-scrollbar">
                <DropdownItem header className="design-tab-heading">
                  {this.props.dropdownHeader}
                </DropdownItem>
                <div className="chromagear-color-swatch-group1 fancy-scrollbar">
                  {colorOptions.map((e, i) => {
                    return (
                      <div key={e.name + this.props.uniqueKey}>
                        <DropdownItem header>
                          {e.name}
                        </DropdownItem>
                        <DropdownItem>
                          <NewColorSwatchGroup
                            dropdownName={this.props.type}
                            value={this.state.value}
                            colorOptions={e.colors}
                            watchProps={true}
                            hint={this.props.hoverColorName}
                            onChange={this.onSwatchSelected}
                          //onHover={color => this.onSwatchHovered(color, i)}
                          />
                        </DropdownItem>
                      </div>
                    )
                  })}
                </div>
                {this.state.isDropdownOpen ?
                  <div>
                    <div className="chromagear-hover-color-tip">
                      {this.state.hoverColor ?
                        <Tooltip tooltipShowDelay={0} data-toggle="tooltip" data-placement="right" title={this.state.hoverColor.name} interactive>
                        </Tooltip>
                        : null}
                    </div>
                  </div>
                  : null}
              </DropdownMenu>
            </div>
            : <DropdownMenu className="dropdown-menu
            ">
              <DropdownItem header className="design-tab-heading">
                {this.props.dropdownHeader}
              </DropdownItem>
              <DropdownItem className="color-picker-hover">
                {isStock ?
                  <div className="chromagear-color-swatch-group1 fancy-scrollbar">
                    <NewColorSwatchGroup
                      dropdownName={this.props.type}
                      value={this.state.value}
                      colorOptions={colorOptions}
                      watchProps={true}
                      hint={this.props.hoverColorName}
                      onChange={this.onSwatchSelected}
                    //onHover={e => this.onSwatchHovered(e)}
                    />
                  </div> :
                  <div className="chromagear-color-swatch-group1 fancy-scrollbar">
                    {colorOptions.map((e, i) => {
                      return (
                        <div key={e.name + this.props.uniqueKey}>
                          <DropdownItem header>
                            {e.name}
                          </DropdownItem>
                          <DropdownItem>
                            <NewColorSwatchGroup
                              dropdownName={this.props.type}
                              value={this.state.value}
                              colorOptions={e.colors}
                              watchProps={true}
                              hint={this.props.hoverColorName}
                              onChange={this.onSwatchSelected}
                              //onHover={color => this.onSwatchHovered(color, i)}
                              title="abc"
                            />
                          </DropdownItem>
                        </div>
                      )
                    })}
                    {this.state.isDropdownOpen ?
                      <div>
                        <div className="chromagear-hover-color-tip">
                          {this.state.hoverColor ?
                            <Tooltip tooltipShowDelay={0} data-toggle="tooltip" data-placement="right" title={this.state.hoverColor.name} interactive>
                            </Tooltip>
                            : null}
                        </div>
                      </div>
                      : null}
                  </div>
                }
              </DropdownItem>
            </DropdownMenu>}
        </Dropdown>
      </div>
    );
  }
}

