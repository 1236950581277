import _ from "lodash"; 

export default class updateDimentionsHelper {
 working = false; 

  async update(id, canvas){
    this.working = true;
    let initalCanvasWidth = canvas.getWidth(); 
    let initialCanvasHeight = canvas.getHeight(); 
    let ratio = initalCanvasWidth / initialCanvasHeight;
    let dimentions  = this.getCurrentDimentions(id);
    while(this.working && dimentions){   
      let width = _.clone(dimentions.width);
      let height = _.clone(dimentions.height); 
      if((width / height)>ratio){
        width = height*ratio;
      } else {
        height = width / ratio;
      }
      let scale = width / canvas.getWidth();
      
      let zoom = canvas.getZoom();
      zoom *=scale; 
      
      canvas.setDimensions({ width: width, height: height });
      canvas.setViewportTransform([zoom , 0, 0, zoom , 0, 0]);
      
      let newDimentions = this.getCurrentDimentions(id);
      if (newDimentions.width === dimentions.width && newDimentions.height === dimentions.height){
        this.working = false; 
      } else {
        dimentions = newDimentions; 
      }
    }

    return true; 
  }

  getCurrentDimentions(id){
    let canvasSizer = document.getElementById(`canvasSizer-${id}`);
    if(canvasSizer){
      let width = canvasSizer.offsetWidth ;
      let height = canvasSizer.offsetHeight;
      return {width, height}; 
    }
    return null; 
  }
}
