import React, {Component} from 'react';
import api from '../../api';
import helpers from '../../helpers';
import _ from 'lodash';
import pluralize from 'pluralize';
import queryString from 'query-string';
import SearchSVG from '../../assets/icons/SsearchSVG';
import TestProductImageLoader from './testProductImageLoader';


class TestProductsPage extends Component {

  constructor(props) {
    super(props);

    this.onSearchChanged = this.onSearchChanged.bind(this);
    this.handleSearchTextChanged = _.debounce(this._handleSearchTextChanged.bind(this), 500, { maxWait: 3000 });
    this.gotoPageIdx = this.gotoPageIdx.bind(this);

    let headerMenuItems = [
      {
        key: 'all',
        name: 'All Products',
        url: '/test-products'
      },
      {
        key: 'badger-stock',
        name: 'Badger Stock',
        url: '/test-products?brand=badger'
      },
      {
        key: 'alleson-stock',
        name: 'Alleson Stock',
        url: '/test-products?brand=alleson&stock=1'
      },
      {
        key: 'alleson-custom',
        name: 'Alleson Custom',
        url: '/test-products?brand=alleson&custom=1'
      }
    ];

    let filters = null;

    let queryParams = this.tryParseQueryParams();
    if (queryParams) {
      filters = {};
      switch (queryParams.brand) {
        case 'fsg':
        case 'founders':
          filters.supplierId = 100;
          break;
        case 'alleson':
        case 'chromagear':
          filters.supplierId = 102;
          break;
        case 'badger':
          filters.supplierId = 103;
          break;
        case 'garb':
          filters.supplierId = 101;
          break;
        case 'teamwork':
          filters.supplierId = 104;
          break;
        default:
          break;
      }

      if (queryParams.stock) {
        filters.configurationType = 'stock-dec';
      } else if (queryParams.custom) {
        filters.configurationType = 'custom-sub';
      }

      if (_.isEmpty(filters)) {
        filters = null;
      }
    }

    // update headers to indicate active header items
    if (!_.isEmpty(filters)) {
      switch (filters.supplierId) {
        case 103:
          _.set(_.find(headerMenuItems, { key: 'badger-stock' }), 'active', true);
          break;
        case 102:
          switch (filters.configurationType) {
            case 'stock-dec':
              _.set(_.find(headerMenuItems, { key: 'alleson-stock' }), 'active', true);
              break;
            case 'custom-sub':
              _.set(_.find(headerMenuItems, { key: 'alleson-custom' }), 'active', true);
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    } else {
      _.set(_.find(headerMenuItems, { key: 'all' }), 'active', true);
    }


    let pagination = {
      pageIdx: 0,
      pageSize: 5 * 6,
      numTotalItems: 0,
    };

    this.state = {
      headerMenuItems,
      filters,
      pagination,
      searchText: null,
      products: null,
      loading: true,
    };

    this.queryIdx = 0;
    this.prevQuery = null;

    this.loadProducts();
  }

  async loadProducts() {
    let pagination = this.state.pagination;
    let q = {
      $attributes: ['id', 'supplierId', 'styleNum', 'designNum', 'legacyStyleNum', 'name'],
      $skip: pagination.pageIdx * pagination.pageSize,
      $limit: pagination.pageSize,
      $sort: ['styleNum']
    };

    if (this.state.filters) {
      q.$where = this.state.filters;
    }

    if (this.state.searchText) {
      let searchExpr =  { $ilike: '%' + this.state.searchText + '%' };
      let where = {
        $or: [
          { name: searchExpr },
          { styleNum: searchExpr },
          { designNum: searchExpr },
          { legacyStyleNum: searchExpr },
        ]
      };
      q.$where = q.$where? { $and: [q.$where, where] }: where;
    }


    if (_.isEqual(this.prevQuery, q)) { return; }
    this.prevQuery = q;
    let queryIdx = ++this.queryIdx;

    let productsRes = await api.baseProducts.find(q);
    if (this.queryIdx !== queryIdx) { return; }

    let products = _.get(productsRes, 'data') || [];
    let numTotalProducts = _.get(productsRes, 'total') || 0;

    let urlSuffix = _.trim(_.get(window, 'location.search'));
    if (_.size(urlSuffix) >= 3) {
      urlSuffix = _.replace(_.trim(urlSuffix), /^\?/, '&');
    }
    if (_.isEmpty(urlSuffix)) {
      urlSuffix = '';
    }
    products = _.map(products, v => {
      v.linkUrl = '/?style=' + v.styleNum + urlSuffix;
      v.previewUrl = helpers.urlRender.getProductRenderUrl(v.styleNum, 'front', 100, 'royal,white,athletic_gold');
      return v;
    });

    pagination = this.state.pagination;
    pagination.numTotalItems = numTotalProducts;
    this.setState({ products, pagination, loading: false });
  }

  tryParseQueryParams() {
    let queryParams = null;
    try {
      let queryStr = (window && window.location && window.location.search) || '';
      queryParams = queryString.parse(queryStr);
      let json = queryParams.json;
      if (_.size(queryParams) === 1 && _.first(_.values(queryParams)) === null) {
        json = _.first(_.keys(queryParams));
        queryParams = {};
      }
      if (json && _.isString(json)) {
        try {
          let jsonObj = JSON.parse(json);
          delete queryParams.json;
          queryParams = _.extend(queryParams, jsonObj);
        } catch (ex) { }
      }
    } catch (ex) { }
    return queryParams
  }

  renderLoadingMessage() {
    return (
      <div className="loading-view">
        <h2 className="center-text">Loading...</h2>
      </div>
    );
  }

  renderEmptyResultsMessage() {
    return (
      <div className="no-results-warning">
        <h2 className="center-text">No products were found!</h2>
      </div>
    );
  }

  onSearchChanged(e) {
    let searchText = _.get(e, 'currentTarget.value') || null;
    // console.log('onSearchChanged: ' + searchText);
    this.handleSearchTextChanged(searchText);
  }

  _handleSearchTextChanged(searchText) {
    if (this.state.searchText !== searchText) {
      this.setState({ searchText }, this.loadProducts.bind(this));
    }
  }

  gotoPageIdx(pageIdx) {
    let pagination = this.state.pagination;
    if (pageIdx !== pagination.pageIdx) {
      pagination.pageIdx = pageIdx;
      this.setState({ pagination }, this.loadProducts.bind(this));
    }
  }

  render() {

    let products = this.state.products || [];
    let numProducts = Math.max(_.get(this.state, 'pagination.numTotalItems', 0), _.size(products));
    let productsCountStr = '';
    if (numProducts > 0) {
      productsCountStr = '(' + _.toString(numProducts) + ' ' + pluralize('Products', numProducts) + ')';
    }

    return (
      <div className="test-page test-products-page fade-in">
        <div className="main">
          <div className="main-header">
            <h1 className="header-title center-text">FSG Builder Products Test Page <span className="product-count-text">{ productsCountStr }</span></h1>
            <div className="header-menu">
              <div className="header-filter-menu">
                {_.map(this.state.headerMenuItems, (menuItem, menuItemIdx) => {
                  return (
                    <div key={menuItemIdx} className={"header-filter-menu-item" + (menuItem.active? " active": "")}>
                      <a className="center-text" href={menuItem.url}>{menuItem.name}</a>
                    </div>
                  );
                })}
              </div>
              {/* <div className="header-search-area mt-1">
                <div>searchText: { this.state.searchText }</div>
              </div> */}
              <div className="header-search-area mt-3">
                <div className="header-search-input input-group">
                  <input type="search" className="form-control" placeholder="Search" onChange={this.onSearchChanged} />
                  <div className="input-group-append">
                    <span className="input-group-text"><SearchSVG height="16" /></span>
                  </div>
                </div>
              </div>
              {/* <div className="header-search-area pagination-area mt-3">
                { this.renderPagination() }
              </div> */}
            </div>
          </div>

          <div className="product-list">
            <div className="container-fluid">
              <div className="row">

                {this.state.loading? this.renderLoadingMessage(): null}

                {(!this.state.loading && _.isEmpty(products))? this.renderEmptyResultsMessage(): null}

                {_.map(products, (product, productIdx) => {
                  return (
                    <div key={"product-"+productIdx} className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-xs-4">
                      <a href={product.linkUrl} target="test-products">
                        <div className="card card-item">
                          <TestProductImageLoader src={product.previewUrl} alt={product.styleNum} />
                          <div className="card-body">
                            <div className="card-text details">
                              <div className="style-num-text">
                                <div>{ product.styleNum }</div>
                                <div>{ product.legacyStyleNum }</div>
                              </div>
                              <div className="name-text">{ product.name }</div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="main-pagination-area">
            <div className="pagination-area">
              { this.renderPagination() }
            </div>
          </div>

        </div>
      </div>
    );
  }

  renderPagination() {

    let pagination = this.state.pagination;


    let minPageIdx = 0;
    let maxPageIdx = Math.max(Math.ceil(pagination.numTotalItems / pagination.pageSize) - 1, 0);
    let currPageIdx = pagination.pageIdx;
    let prevEnabled = (currPageIdx > minPageIdx);
    let nextEnabled = (currPageIdx < maxPageIdx);

    let pageItems = [];
    let maxNumPageItemButtons = 5;

    let canAddPrev = true;
    let canAddNext = true;
    let prevPageIdx = currPageIdx - 1;
    let nextPageIdx = currPageIdx + 1;

    // add the active page
    pageItems.push({ pageIdx: currPageIdx, active: true });

    while (_.size(pageItems) < maxNumPageItemButtons && (canAddPrev || canAddNext)) {
      if (prevPageIdx >= minPageIdx) {
        // add a page before
        pageItems.unshift({ pageIdx: prevPageIdx });
        prevPageIdx--;
      } else {
        canAddPrev = false;
      }
      if (nextPageIdx <= maxPageIdx) {
        // add a page after
        pageItems.push({ pageIdx: nextPageIdx });
        nextPageIdx++;
      } else {
        canAddNext = false;
      }
    }


    return (
      <div className="pagination-area">
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-end">
            <li className="page-item">
              <button className={"page-link clickable" + (!prevEnabled? " disabled": "")} onClick={() => this.gotoPageIdx(minPageIdx)}>
                <span aria-hidden="true">&laquo;</span>
              </button>
            </li>
            {_.map(pageItems, (pageItem, pageItemIdx) => {
              return (
                <li key={pageItemIdx} className={"page-item" + (pageItem.active? " active": "")}>
                  <button className="page-link clickable" onClick={() => this.gotoPageIdx(pageItem.pageIdx)}>{pageItem.pageIdx + 1}</button>
                </li>
              );
            })}
            <li className="page-item">
              <button className={"page-link clickable" + (!nextEnabled? " disabled": "")} onClick={() => this.gotoPageIdx(maxPageIdx)}>
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    );
  }

}


export default TestProductsPage;
