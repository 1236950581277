import React, { Component } from "react";
// import ReactCrop
//   from "react-image-crop";
// import "react-image-crop/dist/ReactCrop.css";
import {setAdditionalAssets} from "../../../../redux/reducers/customConfig/customConfigActions";
import { closeModal } from "../../../../redux/reducers/modal/modalActions";
import { showToast } from "../../../../redux/reducers/toast/toastActions"; 
import { addUploadedArt } from "../../../../redux/reducers/user/userActions";
import UserArtImage from "../../userArtModalComponents/userArtImage"; 
import UserArtNumberOfColorsInput from "../../userArtModalComponents/userArtNumberOfColorsInput"; 
import UserArtButtonGroup from "../../userArtModalComponents/userArtButtonGroup"; 
import UserArtNameInput from "../../userArtModalComponents/userArtNameInput"; 
import UserArtNotSureButton from "../../userArtModalComponents/userArtNotSureButton"; 
import { connect } from "react-redux";
import api from "../../../../api";
const PlaceholderImage = require("../../../../assets/images/artwork-placeholder.png");

class ImageUploadModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: "",
      totalColors: "1",
      imagePreviewUrl: "",
      loading: false, 
      isNotSure: false,
      crop: {
        unit: "%",
        width: 30,
        height: 30,
        // aspect: 16 / 9
      },
    };
  }

  componentDidMount() {
    this.displayFileToUpload();
  }

  handleChangeValue = (totalColors) => {
    this.setState({ totalColors });
  };

  dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
  }
  uploadAdditionalFiles = () => {
    if (this.state.convertedFile) {
      const promisesArr = [api.webUserArtAssets.uploadImage(this.state.originalFile, 0)];
      return Promise.all(promisesArr);
    } else {
      return Promise.resolve([]);
    }
  }
  // uploadAdditionalFiles = () => {
  //   // const promisesArr = [api.webUserArtAssets.uploadImage(this.state.originalFile, 0)];

  //   if (this.state.convertedFile) {
  //     // promisesArr.push(api.webUserArtAssets.uploadImage(this.state.convertedFile, 0));
  //     const promisesArr = [api.webUserArtAssets.uploadImage(this.state.originalFile, 0)];
  //   }
  //   return Promise.all(promisesArr);
  // }

  displayFileToUpload = async () => {
    let reader = new FileReader();
    let selectedFile = this.props.content;
    const uploadedFileMimeType = selectedFile.type;
    const placeholderTypes = ['psd', 'xls', 'xlsx', 'txt', 'doc', 'cad'];
    const fileTypesForConversion = ['ai', 'pdf', 'eps',];
    let type = selectedFile.path.split(".");
    type = type[type.length - 1];
    let accepted = true;
    if (placeholderTypes.includes(type)) {
      accepted = false;
    }
    if (fileTypesForConversion.includes(type)) {
      const convertedFile = await api.webUserArtAssets.convertFile(selectedFile);
      const convertedFileName = `${type[0]}.png`;
      const bas64Img = `data:image/png;base64,${Buffer.from(convertedFile.data, 'binary').toString('base64')}`;
      this.setState({
        imagePreviewUrl: bas64Img,
        fileMimeType: 'image/png',
        uploadedFileType: 'png',
        originalFile: selectedFile,
        convertedFile: this.dataURLtoFile(bas64Img, convertedFileName),
        selectedFile: this.dataURLtoFile(bas64Img, convertedFileName)
      })
    } else {
      reader.onloadend = () => {
        let result = reader.result;
        if (accepted === false) {
          result = PlaceholderImage;
        }
        this.setState({
          originalFile: selectedFile,
          selectedFile,
          imagePreviewUrl: result,
          fileMimeType: uploadedFileMimeType,
          uploadedFileType: type,
          name: selectedFile.name
        });
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  handleNameChange = (e) => {
    let name = e.target.value; 
    this.setState({name}); 
  }

  handleNext = async () => {
    if (this.state.loading){
      return null;
    }
    let file = this.state.selectedFile;
    let numDecorationColors = parseInt(this.state.totalColors);
    if (numDecorationColors <= 0) { numDecorationColors = null; }
    numDecorationColors = (this.state.isNotSure) ? null : numDecorationColors; 
    this.setState({ loading: true });
    let uploadRes = await api.webUserArtAssets.uploadImage(file, numDecorationColors, this.state.name);
    if (uploadRes){
      let type = uploadRes.url.split(".");
      type = type[type.length - 1];
      let accepted = true;
      let placeholderTypes = ['ai', 'psd', 'eps', 'xls', 'xlsx', 'txt', 'doc', 'pdf', 'cad'];
      if (placeholderTypes.includes(type)) {
        accepted = false;
      }
      if (!accepted) {
        uploadRes.url = PlaceholderImage;
      }
      const additionalAssets = await this.uploadAdditionalFiles();
      this.props.setAdditionalAssets(additionalAssets);
      this.props.addUploadedArt(this.props.user, uploadRes);


    } else {
      this.props.showToast("upload failed"); 
    }
    this.props.closeModal();
  };
  handleNotSure = async () => {
    let file = this.state.selectedFile;
    let numDecorationColors = null;
    this.setState({loading: true});
    let uploadRes = await api.webUserArtAssets.uploadImage(file, numDecorationColors, this.state.name);
    const additionalAssets = await this.uploadAdditionalFiles();
    this.props.setAdditionalAssets(additionalAssets);
    this.props.addUploadedArt(this.props.user, uploadRes);
    this.props.closeModal();
  };

  // Image Crop Feature
  // onImageLoaded = image => {
  //   this.imageRef = image;
  // };

  // onCropComplete = crop => {
  //   this.makeClientCrop(crop);
  // };

  // onCropChange = (crop, percentCrop) => {
  //   this.setState({crop});
  // };

  // async makeClientCrop(crop) {
  //   if (this.imageRef && crop.width && crop.height) {
  //     const croppedImageUrl = await this.getCroppedImg(
  //       this.imageRef,
  //       crop,
  //       ''
  //     );
  //     this.setState({selectedFile: this.dataURLtoFile(croppedImageUrl, `${Date.now()}.${this.state.uploadedFileType}`)});
  //   }
  // }

  // getCroppedImg(image, crop, fileName) {

  //   const canvas = document.createElement('canvas');
  //   const scaleX = image.naturalWidth / image.width;
  //   const scaleY = image.naturalHeight / image.height;
  //   canvas.width = crop.width;
  //   canvas.height = crop.height;
  //   const ctx = canvas.getContext('2d');

  //   ctx.drawImage(
  //     image,
  //     crop.x * scaleX,
  //     crop.y * scaleY,
  //     crop.width * scaleX,
  //     crop.height * scaleY,
  //     0,
  //     0,
  //     crop.width,
  //     crop.height
  //   );

  //   return canvas.toDataURL(this.state.fileMimeType);
  // }


  render(){
    return ( <div className = "edit-userart">
    <div className = "edit-userart__panels">
      <div className="edit-userart__lpanel">
      {/* {this.state.imagePreviewUrl && (
            <ReactCrop
            className="userart__img"
              src={this.state.imagePreviewUrl}
              crop={this.state.crop}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
          )} */}
        <UserArtImage url={this.state.imagePreviewUrl} alt={""} />
        <div className="edit-userart__approval-message">Our expert artists will correct the art and contact you for approval before shipping</div>
      </div>
      <div className="edit-userart__rpanel">
        <UserArtNameInput name={this.state.name} onChange={this.handleNameChange} />
        <div className="edit-userart__numdc">
          <div className="edit-userart__numdc-label">How many colors does your artwork contain?</div>
          <div className="edit-userart__numdc-inputs">
            <UserArtNumberOfColorsInput 
              isActive={!this.state.isNotSure}
              setActive={() => this.setState({isNotSure:false})}
              value = {this.state.totalColors}
              onChange = {(e) => this.handleChangeValue(e.target.value)} />
            <UserArtNotSureButton 
              isNotSure={this.state.isNotSure} 
              onClick={ () => this.setState({isNotSure:true}) } />
           </div>
          <div className="edit-userart__numdc-use">We will use this to customize your decoration estimate.</div>
        </div>           
        <UserArtButtonGroup
              canSave = {true}
              save={this.handleNext}
              close={() => this.props.closeModal()} />
      </div>
    </div>
  </div>); 
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

const outputActions = {
  closeModal,
  addUploadedArt,
  showToast,
  setAdditionalAssets,
};

export default connect(mapStateToProps, outputActions)(ImageUploadModal);
