
const EntityTypes = {
  CustomArt:      'custom-art',
  CustomText:     'custom-text',
  PlayerName:     'player-name',
  PlayerNumber:   'player-number'
};

const ArtEntityTypes = [ EntityTypes.CustomArt ];
const TextEntityTypes = [ EntityTypes.CustomText, EntityTypes.PlayerName, EntityTypes.PlayerNumber ];
const OrderedEntityTypes = [ EntityTypes.CustomArt, EntityTypes.CustomText, EntityTypes.PlayerName, EntityTypes.PlayerNumber ];


// this is the value that let's us know which bullder mode this product should be in.
// see appConstants.ProductConfigurationTypes.
// current allowed options are: 'stock-decorated' (default), 'custom-sub', 'full-custom',
const ProductConfigurationTypes = {
  Default: 'stock-dec',
  StockDecorated: 'stock-dec',        // ex: Badger stock decorated products
  CustomSublimated: 'custom-sub',     // ex: Alleson Chromagear sublimated products
  FullCustom: 'full-custom',          // ex: Garb full custom products
};

const DecorationMethods = {
  Silicone:           'screenprint-silicone',
  Plastisol:          'screenprint-plastisol',
  Duratek:            'screenprint-duratek',
  Stitching:          'stitching',
  Braid:              'braid',
  Braid316in:         'braid-3-16in',
  Braid38in:          'braid-3-8in',
  Braid1in:           'braid-1in',
  Sublimation:        'sublimation'
};

const InkDecorationMethods = [
  DecorationMethods.Silicone,
  DecorationMethods.Plastisol,
  DecorationMethods.Duratek,
  DecorationMethods.Sublimation,
];

// these colors are only costly for stock-dec products for silicone and plastisol inks
// keep in sync with pricing data on on server
// see src/lib/decoration-pricing/decoration-pricing-data.js
const _StockDecCostlyColors = [
  'SY',
  'SO',
  'HP',
  'HC',
  'LM',
  'EB'
];

const DecorationMethodCostlyColors = {
  'screenprint-silicone': _StockDecCostlyColors,
  'screenprint-plastisol': _StockDecCostlyColors,
};

const UpgradeTypes = {
  Stitching:      'stitching',
  Braid1:         'braid-1in',      // 1" braid
  Braid38:        'braid-38in',     // 3/8" braid
  Braid316:       'braid-316in'     // 3/16" braid
};


// TODO: $DATA_CLEANUP$.  fix this.  these values should be coming from the data, these should not be hard coded.  they should be used as a fallback only.

// entity toggle buttons
const ToggleButtonItems = [
  {
    entityType: EntityTypes.CustomText,
    name: 'Text',
    text: '',                   // TODO: $DEPRECATED$.  use value instead.  text should not be used
    value: '',                  // TODO: $DATA_CLEANUP$ value should be null
    placeholder: 'ENTER TEXT',
    enabled: true,
    editable: true,
    showHelpMessage: false
  },
  {
    entityType: EntityTypes.PlayerName,
    name: 'Player Name',
    text: 'PLAYER NAME',        // TODO: $DEPRECATED$.  use value instead.  text should not be used
    value: 'PLAYER NAME',       // TODO: $DATA_CLEANUP$ value should be null
    placeholder: 'PLAYER NAME',
    enabled: true,
    editable: false,
    showHelpMessage: true
  },
  {
    entityType: EntityTypes.PlayerNumber,
    name: 'Player Number',
    text: '00',                 // TODO: $DEPRECATED$.  use value instead.  text should not be used
    value: '00',                // TODO: $DATA_CLEANUP$ value should be null
    placeholder: '00',
    enabled: true,
    editable: false,
    showHelpMessage: true
  }
];

const ToolTip = [
  {key: 'screenprint-plastisol', value: 'Industry Standard'},
  {key: 'screenprint-duratek', value: 'Screenprint Transfer'},
  {key: 'screenprint-silicone', value: 'Best In Class'},
  {key: 'sublimation', value: 'Custom Sublimation'}
];

const QuickLinks = ["football", "basketball", "baseball", "tennis", "hockey"];

const Cart = {
  addToCart: 'add-to-cart'
};
const DefaultPricingRequestData = {
  entities: [
    { decorationMethod: 'screenprint-silicone', entityType: 'custom-text', numDecorationColors: 1 },
    { decorationMethod: 'screenprint-silicone', entityType: 'custom-art', numDecorationColors: 1 },
    { decorationMethod: 'screenprint-silicone', entityType: 'player-name', numDecorationColors: 1 },
    { decorationMethod: 'screenprint-silicone', entityType: 'player-number', numDecorationColors: 1 },
    { decorationMethod: 'screenprint-plastisol', entityType: 'custom-text', numDecorationColors: 1 },
    { decorationMethod: 'screenprint-plastisol', entityType: 'custom-art', numDecorationColors: 1 },
    { decorationMethod: 'screenprint-plastisol', entityType: 'player-name', numDecorationColors: 1 },
    { decorationMethod: 'screenprint-plastisol', entityType: 'player-number', numDecorationColors: 1 },
    { decorationMethod: 'screenprint-duratek', entityType: 'custom-text', numDecorationColors: 1 },
    { decorationMethod: 'screenprint-duratek', entityType: 'custom-art', numDecorationColors: 1 },
    { decorationMethod: 'screenprint-duratek', entityType: 'player-name', numDecorationColors: 1 },
    { decorationMethod: 'screenprint-duratek', entityType: 'player-number', numDecorationColors: 1 },
    { decorationMethod: 'sublimation', entityType: 'custom-text', numDecorationColors: 1 },
    { decorationMethod: 'sublimation', entityType: 'custom-art', numDecorationColors: 1 },
    { decorationMethod: 'sublimation', entityType: 'player-name', numDecorationColors: 1 },
    { decorationMethod: 'sublimation', entityType: 'player-number', numDecorationColors: 1 },
    { decorationMethod: 'stitching', entityType: 'stitching', numDecorationColors: 1 },
    { decorationMethod: 'braiding', entityType: 'soutsche-316in', numDecorationColors: 1 },
    { decorationMethod: 'braiding', entityType: 'braid-38in', numDecorationColors: 1 },
    { decorationMethod: 'braiding', entityType: 'braid-1in', numDecorationColors: 1 },
    { decorationMethod: 'braiding', entityType: 'soutsche-136in', numDecorationColors: 1 },
    { decorationMethod: 'braiding', entityType: 'soutsche-316in', numDecorationColors: 1 },
    { decorationMethod: 'braiding', entityType: 'braid-shorts', numDecorationColors: 1 },
    { decorationMethod: 'braiding', entityType: 'braid-seam', numDecorationColors: 1 },
    { decorationMethod: 'braiding', entityType: 'braid-pants', numDecorationColors: 1 },
    { decorationMethod: 'fabric', entityType: 'fabric', numDecorationColors: 1 },
  ],
  quantity: 1
};

const AppConstants = {
  EntityTypes,
  ArtEntityTypes,
  TextEntityTypes,
  OrderedEntityTypes,
  ProductConfigurationTypes,
  InkDecorationMethods,
  DecorationMethods,
  DecorationMethodCostlyColors,
  UpgradeTypes,
  ToggleButtonItems,
  ToolTip,
  QuickLinks,
  DefaultPricingRequestData,
  Cart
};
export default AppConstants;
