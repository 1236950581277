import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux"; 
import { setActiveLocation, setView } from "../../../redux/reducers/navigation/navigationActions";
import PreviewCanvasTwo from "../../previewCanvasTwo/PreviewCanvasTwo";
import KeyboardActions from "../../global/keyboardActions/keyboardActions"; 

// see BLD-262
const HIDE_LOCATIONS_WHEN_NOT_USED = true;

class ViewActive extends Component {

  constructor(props) {
    super(props);

    this.state = {
      canvasses : []
    };

    this.handleClick = this.handleClick.bind(this);
    this.locationBorderTabs = ['add-text', 'decorations', 'edit-text', 'add-art', 'edit-art', 'up-art', 'system-art'];
  }

  componentDidMount(){
    let canvasses = []
    _.forEach( this.props.baseProduct.views, (x)=>{
       let id = `canvas-${x.key}`;
       canvasses.push(id);
    })
    this.setState({canvasses});

   }

  handleClick() {
    // unselect the previously selected location
    this.props.setActiveLocation(null);
  }

  render() {
    let activeView = null;
    let activeViewKey = this.props.navigation.activeView || this.props.baseProduct.defaultView || null;
    if (activeViewKey) {
      activeView = _.find(this.props.baseProduct.views, { key: activeViewKey});
    }
    if (!activeView) {
      activeView = _.first(this.props.baseProduct.views);
    }

    // No, No, No!  do not modify state in a render function      
    // if (activeView && this.props.navigation.activeView !== activeView.key) {
    //   this.props.setView(activeView.key);
    // }

    let showBorders = true;
    if (HIDE_LOCATIONS_WHEN_NOT_USED) {
      let activeTab = _.get(this.props, 'navigation.activeTab.tab', null);
      showBorders = !!(activeTab && _.includes(this.locationBorderTabs, activeTab));
    }

    // make canvas for each view.
    let views = _.map(this.props.baseProduct.views, (view, i)=>{
      let active = "inactive";
      if ( activeView.key === view.key ){
        active = "active";
      }
      return (
          <PreviewCanvasTwo
            className={`${active}`}
            key={i}
            view={view}
            borders={showBorders}
            showHover={true}
            showShadow={true}
            isThumbnail={false}
          />
      );
    })

    return (
      <div className="ViewActive" id="main-preview-container">
        <KeyboardActions />
        { views }
        { this.props.issues && this.props.issues.body ? (
          <span style={{color: this.props.issues.textColor}}>
            {this.props.issues.body}
          </span>
          ): null }
      </div>
    )
  }
}


const outputActions = {
  setActiveLocation,
  setView,
};

function mapStateToProps(state){
  return{
    baseProduct: state.baseProduct,
    navigation: state.navigation
  }
}

export default connect(mapStateToProps, outputActions)(ViewActive);
