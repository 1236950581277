/* eslint-disable react/jsx-no-undef */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import OptionHeading from '../../../global/optionHeading/OptionHeading'
import Table from '../../../global/table/table'
import { CSVLink } from 'react-csv'
import {
  updateCell,
  addRow,
  removeRow,
  deleteAll,
  changeSetting,
} from '../../../../redux/reducers/customConfig/customConfigActions'
import { showToast } from '../../../../redux/reducers/toast/toastActions'
import PlusSVG from '../../../../assets/icons/PlusSVG'
import ArrowDropDownSVG from '../../../../assets/icons/ArrowDropDownSVG'
import PreviewSVG from '../../../../assets/icons/PreviewSVG'
import RemoveEyeSVG from '../../../../assets/icons/RemoveEyeSVG'
import TrashSVG from '../../../../assets/icons/Trash'
import _ from 'lodash'
import helpers from '../../../../helpers'
import FileUploaderRoster from '../../../global/fileUploader/fileUploaderRoster'
import SizeRoster from './SizeRoster'
import MultiDropdown from '../../../global/multiDropdown/multiDropdown'
import DownloadRosterSVG from '../../../../assets/icons/DownloadSVG'
const passRef = (fn, ...args) => ({ target: { value: v } }) => fn(args, v)
let playerName = false
let playerNumber = false
let initial = false
let playerNameValue = ''
let playerNumberValue = ''
let rosterIndex = []
let sizeValue
let playerNumValue
let firstItem = {}
let updateRow = []
let updateRowSize = []
let updateRowQty = []
let qtyValue
let icon = ''
//sample roster csv sheet

class PlayerRoster extends Component {
  constructor(props) {
    super(props)
    // For Stock Products
    let rosterColumns = [' ', 'Name', 'Number', 'Size', ' ']
    qtyValue = this.props.navigation.rosterQtyInput
      ? this.props.navigation.rosterQtyInput.qty
      : 1
    sizeValue = this.props.navigation.rosterQtyInput
      ? this.props.navigation.rosterQtyInput.size
      : 'XS'
    this.tableConfig = {
      columns: this.TableConfig(),
      sizeOptions: this.props.sizes ? this.props.sizes : [],
    }

    // For Sublimated Products
    let stylesList = []
    let styleDesignList = [
      {
        key: 'J5JV1A',
        value: [
          'JDB004',
          'JDB007',
          'JDB010',
          'JDB027',
          'JDB028',
          'JDB029',
          'JDBT01',
          'JDBT04',
          'JDBT09',
          'JDS001',
          'JDS014',
          'JDS023',
          'JDS024',
          'JDS025',
          'JDS026',
        ],
      },
      {
        key: 'J5JV1Y',
        value: [
          'JDB004',
          'JDB007',
          'JDB010',
          'JDB027',
          'JDB028',
          'JDB029',
          'JDBT01',
          'JDBT04',
          'JDBT09',
          'JDS001',
          'JDS014',
          'JDS023',
          'JDS024',
          'JDS025',
          'JDS026',
        ],
      },
      {
        key: 'J5JV1W',
        value: [
          'JDB004',
          'JDB007',
          'JDB010',
          'JDB027',
          'JDB028',
          'JDB029',
          'JDBT01',
          'JDBT04',
          'JDBT09',
          'JDS001',
          'JDS014',
          'JDS023',
          'JDS024',
          'JDS025',
          'JDS026',
        ],
      },
      {
        key: 'J5JV1G',
        value: [
          'JDB004',
          'JDB007',
          'JDB010',
          'JDB027',
          'JDB028',
          'JDB029',
          'JDBT01',
          'JDBT04',
          'JDBT09',
          'JDS001',
          'JDS014',
          'JDS023',
          'JDS024',
          'JDS025',
          'JDS026',
        ],
      },
      {
        key: 'J5JC1A',
        value: [
          'JDB004',
          'JDB007',
          'JDB010',
          'JDB027',
          'JDB028',
          'JDB029',
          'JDBT01',
          'JDBT04',
          'JDBT09',
          'JDS001',
          'JDS014',
          'JDS023',
          'JDS024',
        ],
      },
      {
        key: 'J5JC1W',
        value: [
          'JDB007',
          'JDB010',
          'JDB027',
          'JDB028',
          'JDBT01',
          'JDBT04',
          'JDBT09',
          'JDS001',
          'JDS014',
          'JDS023',
          'JDS024',
          'JDS025',
          'JDS026',
        ],
      },
      {
        key: 'J5JC1Y',
        value: [
          'JDB004',
          'JDB007',
          'JDB027',
          'JDB029',
          'JDBT01',
          'JDBT04',
          'JDBT09',
          'JDS001',
          'JDS014',
          'JDS023',
          'JDS024',
        ],
      },
    ]

    let sampleRoster = [rosterColumns]

    this.state = {
      sampleRoster: sampleRoster,
      previewImg: [],
    }
  }

  TableConfig() {
    let hasPlayerName = false
    let hasPlayerNumber = false
    this.props.customConfig.locations.forEach((item) => {
      if (item.entity.type === 'player-name') {
        hasPlayerName = true
      } else if (item.entity.type === 'player-number') {
        hasPlayerNumber = true
      }
    })

    if (hasPlayerName && hasPlayerNumber) {
      return [
        {
          key: 'preview',
          name: ' ',
          placeholder: 'preview',
          renderer: this.renderPreview,
        },
        { key: 'name', name: 'Name', placeholder: 'Ex. Smith', renderer: null },
        {
          key: 'number',
          name: 'Number',
          placeholder: 'Ex. 10',
          isCount: true,
          renderer: null,
        },
        {
          key: 'size',
          name: 'Size',
          placeholder: 'Size',
          renderer: this.renderDatalist,
        },
        { key: 'qty', name: 'Qty', placeholder: 'qty', renderer: null },
        { key: 'price', name: 'Price', placeholder: 'price', renderer: null },
        {
          key: 'delete',
          name: ' ',
          placeholder: 'delete',
          renderer: this.renderDeleteIcon,
        },
      ]
    } else if (hasPlayerName) {
      return [
        {
          key: 'preview',
          name: ' ',
          placeholder: 'preview',
          renderer: this.renderPreview,
        },
        { key: 'name', name: 'Name', placeholder: 'Ex. Smith', renderer: null },
        {
          key: 'size',
          name: 'Size',
          placeholder: 'Size',
          renderer: this.renderDatalist,
        },
        { key: 'qty', name: 'Qty', placeholder: 'qty', renderer: null },
        { key: 'price', name: 'Price', placeholder: 'price', renderer: null },
        {
          key: 'delete',
          name: ' ',
          placeholder: 'delete',
          renderer: this.renderDeleteIcon,
        },
      ]
    } else {
      return [
        {
          key: 'preview',
          name: ' ',
          placeholder: 'preview',
          renderer: this.renderPreview,
        },
        {
          key: 'number',
          name: 'Number',
          placeholder: 'Ex. 10',
          isCount: true,
          renderer: null,
        },
        {
          key: 'size',
          name: 'Size',
          placeholder: 'Size',
          renderer: this.renderDatalist,
        },
        { key: 'qty', name: 'Qty', placeholder: 'qty', renderer: null },
        { key: 'price', name: 'Price', placeholder: 'price', renderer: null },
        {
          key: 'delete',
          name: ' ',
          placeholder: 'delete',
          renderer: this.renderDeleteIcon,
        },
      ]
    }
  }

  componentDidMount() {
    // For Stock Products
    let sampleRoster = [[' ', 'Name', 'Number', 'Size', ' ']]
    let newRoster = _.get(this.props, 'rosters[0].items')
    // For Sublimated Products
    if (
      this.props.baseProduct.configurationType &&
      this.props.baseProduct.configurationType === 'custom-sub'
    ) {
      sampleRoster = [[' ', 'Name', 'Number', 'Size', ' ']]
      if (newRoster) {
        _.each(newRoster, (e) => {
          sampleRoster.push([
            e.preview,
            e.name,
            e.number,
            e.size,
            // e.qty,
            // e.price,
            e.delete,
          ])
        })
      }
    } else {
      sampleRoster = [[' ', 'Name', 'Number', 'Size', ' ']]
      if (newRoster) {
        _.each(newRoster, (e) => {
          sampleRoster.push([
            e.preview,
            e.name,
            e.number,
            e.size,
            // e.qty,
            // e.price,
            e.delete,
          ])
        })
      }
    }
    this.setState({ sampleRoster: sampleRoster })
    let tableData = _.get(this.props, ['rosters', 0, 'items'], [])
    if (tableData.length > 0) {
      tableData = tableData.map((data) => ({ ...data, preview: false }))
      this.props.customConfig.rosters[0].items = tableData
      let previewVal = true
      updateRow = ['preview', 0]
      this.props.updateCell(updateRow, previewVal)
      this.previewSelection(0)
    }
    // this.props.customConfig.locations.forEach(item => {
    //   if (item.entity.type === 'player-name' && item.entity.value !== '' && item.entity.value !== 'PLAYER NAME') {
    //     playerName = true
    //     playerNameValue = item.entity.value;
    //   } else if (item.entity.type === 'player-number' && item.entity.value !== '' && item.entity.value !== '00') {
    //     playerNumber = true;
    //     playerNumberValue = item.entity.value;
    //   }
    // });
    // if (playerName || playerNumber) {
    //   if (this.props.navigation.rosterQtyInput) {
    //     playerNumValue = playerNumberValue !== '' ? playerNumberValue : this.props.navigation.rosterQtyInput ? this.props.navigation.rosterQtyInput.number :  "";;
    //   } else {
    //     playerNumValue = playerNumberValue
    //   }
    //   rosterIndex = [0];
    //   updateRow = ["number", 0];
    //   updateRowSize = ["size", 0];
    //   updateRowQty = ["qty", 0]
    //   this.props.deleteAll();
    //   this.props.addRow('name', playerNameValue, rosterColumns);
    //   this.props.updateCell(updateRow, playerNumValue);
    //   this.props.updateCell(updateRowSize, sizeValue);
    //   this.props.updateCell(updateRowQty, qtyValue);
    //   let test = !initial ? tableData.shift() : tableData;
    //   // let test = tableData.shift();
    //   tableData.map((e, index) => {
    //     let updateIndex = index + 1;
    //     this.props.addRow('name', e.name, rosterColumns)
    //     let updateNumber = ["number", updateIndex];
    //     let updateSize = ["size", updateIndex];
    //     let updateQuantity = ["qty", updateIndex];
    //     this.props.updateCell(updateNumber, e.number);
    //     this.props.updateCell(updateSize, e.size);
    //     this.props.updateCell(updateQuantity, e.qty);
    //     initial = false;
    //   })
    // } else {
    //   initial = true;
    // }
    // playerName = false;
    // playerNumber = false;
    // playerNameValue = ''
    // playerNumberValue = ''
  }

  componentDidUpdate(newProps) {
    // For Stock Products
    let sampleRoster = [[' ', 'Name', 'Number', 'Size', ' ']]
    let newRoster = _.get(newProps, 'rosters[0].items')
    // For Sublimated Products
    if (
      this.props.baseProduct.configurationType &&
      this.props.baseProduct.configurationType == 'custom-sub'
    ) {
      sampleRoster = [[' ', 'Name', 'Number', 'Size', ' ']]
      if (newRoster) {
        _.each(newRoster, (e) => {
          sampleRoster.push([
            e.preview,
            e.name,
            e.number,
            e.size,
            // e.qty,
            // e.price,
            e.delete,
          ])
        })
      }
    } else {
      sampleRoster = [[' ', 'Name', 'Number', 'Size', ' ']]
      if (newRoster) {
        _.each(newRoster, (e) => {
          sampleRoster.push([
            e.preview,
            e.name,
            e.number,
            e.size,
            // e.qty,
            // e.price,
            e.delete,
          ])
        })
      }
    }

    return { sampleRoster: sampleRoster }
  }

  componentWillUnmount() {
    let locations = _.get(this.props.customConfig, ['locations'], [])
    locations.forEach((item) => {
      if (item.entity.type === 'player-name') {
        this.props.changeSetting(this.props.customConfig.locations, item.code, {
          setting: 'value',
          value: 'PLAYER NAME',
        })
      } else if (item.entity.type === 'player-number') {
        this.props.changeSetting(this.props.customConfig.locations, item.code, {
          setting: 'value',
          value: '00',
        })
      }
    })
  }

  renderDatalist1 = (
    updateCell1,
    deleteRow1,
    addRow1,
    isLastRow1,
    keys1,
    styleOpts,
  ) => {
    return (cellData1, columnIndex1, rowIndex1, columnKey1) => {
      const onChange1 = isLastRow1
        ? passRef(addRow1, columnKey1, keys1)
        : passRef(updateCell1, columnKey1, rowIndex1)
      const defaultOpt1 = cellData1 ? null : (
        <option value="" disabled select="true"></option>
      )
      return (
        <span className="tbl-complex1" key={columnIndex1}>
          <ArrowDropDownSVG className="tblc-arrow" height={20} />
          <select
            className="tblc-select"
            onChange={onChange1}
            value={
              cellData1
                ? cellData1
                : helpers.product.getDefaultStyles(this.props.style)[0]
            }
          >
            {defaultOpt1}
            {styleOpts.map((val1, i) => (
              <option key={i} value={val1}>
                {val1}
              </option>
            ))}
          </select>
          <div onClick={isLastRow1 ? () => { } : passRef(deleteRow1, rowIndex1)}>
            {isLastRow1 ? (
              <PlusSVG height="0px" width="15px" fill={'none'} />
            ) : (
                <PlusSVG height="15px" width="15px" fill={'black'} />
              )}
          </div>
        </span>
      )
    }
  }

  renderDatalist = (
    updateCell,
    deleteRow,
    addRow,
    isLastRow,
    keys,
    sizeOpts,
  ) => {
    return (cellData, columnIndex, rowIndex, columnKey) => {
      // console.log("deleteRow====>",deleteRow)
      const onChange = isLastRow
        ? passRef(addRow, columnKey, keys)
        : passRef(updateCell, columnKey, rowIndex)
      const defaultOpt = cellData ? null : (
        <option value="" disabled select="true"></option>
      )
      // if(!initial && rowIndex === 0 ) {
      //   sizeValue = cellData;
      // }
      return (
        <span className="tbl-complex" key={columnIndex}>
          <select
            className="tblc-select border-0 form-select"
            onChange={onChange}
            value={cellData ? cellData : helpers.product.getDefaultSize()}
          >
            {defaultOpt}
            {/* <ArrowDropDownSVG className="tblc-arrow" height={20} /> */}
            {sizeOpts.map((val, i) => (
              <option key={i} value={val}>
                {val}
              </option>
            ))}
          </select>
          {/* <MultiDropdown
                marginTop="15px"
                // label={newLocations.length !== 0 ? "Location" : null}
                menuHeader="Select a Size"
                value={cellData ? cellData : helpers.product.getDefaultSize()}
                options={sizeOpts}
                onMouseLeave={() => { }}
                onChange={e => console.log(e)}
              /> */}
          {/* <div className="deleterow" onClick={isLastRow ? () => { } : passRef(deleteRow, rowIndex)}>
            {
              isLastRow ? (
                <TrashSVG height="0px" width="15px" fill={"none"} />
              )
                : (
                  <TrashSVG onClick={async () => { await this.defaultPreview(rowIndex) }} height="15px" width="15px" fill={"black"} />
                )
            }
          </div> */}
        </span>
      )
    }
  }

  renderDeleteIcon = (
    updateCell,
    deleteRow,
    addRow,
    isLastRow,
    keys,
    sizeOpts,
  ) => {
    return (cellData, columnIndex, rowIndex, columnKey) => {
      return (
        <span key={columnIndex}>
          <div
            type="button"
            onClick={isLastRow ? () => { } : passRef(deleteRow, rowIndex)}
          >
            {isLastRow ? (
              <TrashSVG height="0px" width="15px" fill={'none'} />
            ) : (
                <TrashSVG
                  onClick={async () => {
                    await this.defaultPreview(rowIndex)
                  }}
                  height="18px"
                  width="18px"
                  fill={'black'}
                />
              )}
          </div>
        </span>
      )
    }
  }

  defaultPreview(row) {
    let tableData = _.get(this.props, ['rosters', 0, 'items'], [])
    let deleteData = tableData
    let previewLength = 0
    deleteData.map((data, i) => {
      if (data && data.preview && i != row) {
        previewLength++
      }
    })
    let locations = _.get(this.props.customConfig, ['locations'], [])
    locations.forEach((item) => {
      if (item.entity.type === 'player-name') {
        if (previewLength == 0) {
          this.props.changeSetting(
            this.props.customConfig.locations,
            item.code,
            { setting: 'value', value: '' },
          )
        }
      } else if (item.entity.type === 'player-number') {
        if (previewLength == 0) {
          this.props.changeSetting(
            this.props.customConfig.locations,
            item.code,
            { setting: 'value', value: '' },
          )
        }
      }
    })
  }

  previewSelection(row) {
    this.setState({ selectedOne: true })
    this.setState({ rowNum: row })
    let rowVal = row
    let tableData = _.get(this.props, ['rosters', 0, 'items'], [])
    if (tableData[row].preview === false) {
      tableData = tableData.map((data) => ({ ...data, preview: false }))
      this.props.customConfig.rosters[0].items = tableData
      let playerData = tableData[row]
      let previewVal = true
      updateRow = ['preview', rowVal]
      this.props.updateCell(updateRow, previewVal)
      this.renderIcon(tableData, row)
      let locations = _.get(this.props.customConfig, ['locations'], [])
      locations.forEach((item) => {
        if (item.entity.type === 'player-name') {
          this.props.changeSetting(
            this.props.customConfig.locations,
            item.code,
            { setting: 'value', value: playerData.name },
          )
        } else if (item.entity.type === 'player-number') {
          this.props.changeSetting(
            this.props.customConfig.locations,
            item.code,
            { setting: 'value', value: playerData.number },
          )
        }
      })
    } else {
      tableData = tableData.map((data) => ({ ...data, preview: false }))
      this.props.customConfig.rosters[0].items = tableData
      // let previewVal = false
      // updateRow = ["preview", rowVal];
      // this.props.updateCell(updateRow, previewVal);
      this.renderIcon(tableData, row)
      let locations = _.get(this.props.customConfig, ['locations'], [])
      locations.forEach((item) => {
        if (item.entity.type === 'player-name') {
          this.props.changeSetting(
            this.props.customConfig.locations,
            item.code,
            { setting: 'value', value: '' },
          )
        } else if (item.entity.type === 'player-number') {
          this.props.changeSetting(
            this.props.customConfig.locations,
            item.code,
            { setting: 'value', value: '' },
          )
        }
      })
    }
  }

  renderIcon(data, row, last) {
    switch (true) {
      case data.length > 0 &&
        data[row] &&
        (data[row].name != '' || data[row].number != '') &&
        (data[row].name != null || data[row].number != null) &&
        data[row].preview === true &&
        last === false:
        {
          icon = <PreviewSVG className="preview" size={30} />
        }
        break

      case data.length > 0 &&
        data[row] &&
        (data[row].name != '' || data[row].number != '') &&
        (data[row].name != null || data[row].number != null) &&
        data[row].preview === false &&
        last === false:
        {
          icon = <RemoveEyeSVG className="preview" size={30} />
        }
        break

      case data.length > 0 &&
        data[row] &&
        (data[row].name != '' || data[row].number != '') &&
        (data[row].name != null || data[row].number != null) &&
        last === false:
        {
          icon = <RemoveEyeSVG className="preview" size={30} />
        }
        break

      default:
        {
          icon = ''
        }
        break
    }
  }

  renderPreview = (
    updateCell,
    deleteRow,
    addRow,
    isLastRow,
    keys,
    sizeOpts,
  ) => {
    return (cellData, columnIndex, rowIndex, columnKey) => {
      const onChange = isLastRow
        ? passRef(addRow, columnKey, keys)
        : passRef(updateCell, columnKey, rowIndex)
      const defaultOpt = cellData ? (
        true
      ) : (
          <option value="" disabled select="true"></option>
        )
      let tableData = _.get(this.props, ['rosters', 0, 'items'], [])
      // if(!initial && rowIndex === 0 ) {
      //   sizeValue = cellData;
      // }
      return (
        // <span className="tbl-complex" key={columnIndex}>
        <span>
          <div
            type="button"
            onClick={async () => {
              await this.previewSelection(rowIndex)
            }}
          >
            {/* { tableData[this.state.rowNum] ? 
               <PreviewSVG className="preview" size={30} /> :
                 <RemoveEyeSVG className="preview" size={30} />
                } */}
            {this.renderIcon(tableData, rowIndex, isLastRow)}
            {icon}
          </div>
        </span>
      )
    }
  }

  fileHandler(event) { }

  getsizeCount(size) {
    let count = 0
    if (this.props.customConfig.rosters.length > 0) {
      let sizeroster = this.props.customConfig.rosters[0].items
      sizeroster.forEach((sr) => {
        if (size === sr.size) {
          count = count + 1
        }
      })
    }
    return count
  }

  render() {
    let tableData = _.get(this.props, ['rosters', 0, 'items'], [])
    let locations = _.get(this.props.customConfig, ['locations'], [])
    let hasNameOrNumber = false
    locations.forEach((item) => {
      if (
        item.entity.type === 'player-name' &&
        item.entity.value !== '' &&
        item.entity.value !== 'PLAYER NAME'
      ) {
        hasNameOrNumber = true
      } else if (
        item.entity.type === 'player-number' &&
        item.entity.value !== '' &&
        item.entity.value !== '00'
      ) {
        hasNameOrNumber = true
      } else {
        hasNameOrNumber = false
      }
    })

    if (hasNameOrNumber === true) {
      firstItem = _.first(tableData)
      this.props.navigation.rosterQtyInput = firstItem
    }
    let toolTip = _.find(this.props.toolTips, { key: 'upload-roster-button' })
      .message

    let downloadToolTip = _.find(this.props.toolTips, {
      key: 'download-roster-template',
    }).message

    let tabLocation =
      this.props.navigation.activeTab.tab === 'names-and-numbers' ? true : false
    let allowedProductSizes = helpers.product.getAllowedProductSizes()
    let rosterChange = false
    this.props.customConfig.locations.forEach((item) => {
      if (
        item.entity.type === 'player-name' ||
        item.entity.type === 'player-number'
      ) {
        rosterChange = true
        return
      }
    })
    return !rosterChange ? (
      <SizeRoster />
    ) : (
        <div className="roster-fade-in">
          <div className="roster-data d-flex flex-column">
            {this.props.navigation.activeTab.tab === 'order-summary' ? (
              <div></div>
            ) : (
                <OptionHeading
                  title={'Add Roster'}
                  description={'Fill out your team roster below.'}
                />
              )}
            <div className="container-fluid">
              <div className="row">
                <div className="roster-table fillx">
                  <Table
                    config={this.tableConfig}
                    tableData={tableData}
                    isEditable={tabLocation}
                    startInEditMode={false}
                    rosterchange={rosterChange}
                  />
                </div>
              </div>
            </div>

            {/* {_.size(firstItem) === 0 ?
                    <EmptyState title="Your roster is empty" message="<p>You can add new items by clicking on any row.</p><p>You can also upload a spreadsheet file with roster information below.</p>" />
                    : null} */}
            {this.props.navigation.activeTab.tab === 'order-summary' ? (
              <div></div>
            ) : (
                <div>
                  <div className="roster__download text-center">
                    <div className="roster__download-link d-inline-flex" href="/">
                      {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="#000000"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M19 9h-4V3H9v6H5l7 7 7-7zm-8 2V5h2v6h1.17L12 13.17 9.83 11H11zm-6 7h14v2H5z" />
                </svg> */}

                      <span
                        aria-label={downloadToolTip}
                        data-balloon-pos="up"
                        style={{ fontSize: '16px' }}
                      >
                        <CSVLink
                          filename={'roster_template.csv'}
                          data={this.state.sampleRoster}
                        >
                          <DownloadRosterSVG />
                          Download Roster
                </CSVLink>
                      </span>
                    </div>
                  </div>
                  <div className="roster-thin-line"></div>
                </div>
              )}
            {this.props.navigation.activeTab.tab === 'order-summary' ? (
              <div></div>
            ) : (
                <FileUploaderRoster
                  fileUpload="roster"
                  toolTip={toolTip}
                  message="Upload Roster"
                  allowedFiles={['xls', 'xlsx', 'csv']}
                />
              )}
          </div>
          {this.props.navigation.activeTab.tab === 'order-summary' ? (
            <div></div>
          ) : (
              <div className="container-fluid pt-4">
                <div className="row">
                  <div className="col-12">
                    <div className="notes">
                      PLEASE NOTE: The actual size of player names and numbers may
                      vary from the preview image.
                </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      )
  }
}

function mapStateToProps({
  customConfig,
  baseProduct,
  toolTips,
  navigation,
  decoration,
}) {
  return {
    rosters: customConfig.rosters,
    sizes: baseProduct.sizes,
    toolTips: toolTips,
    baseProduct: baseProduct,
    style: baseProduct.legacyStyleNum,
    customConfig: customConfig,
    navigation: navigation,
    decoration: decoration,
  }
}

const actions = {
  addRow,
  removeRow,
  updateCell,
  deleteAll,
  changeSetting,
  showToast,
}

export default connect(mapStateToProps, actions)(PlayerRoster)
