import stateProvider from './stateProvider';
import AppConfig from '../config';
import CProdRenderUrl from '../lib/cProdRenderUrl';
import helpers from '../helpers';
import urljoin from 'url-join';
import _, { template } from 'lodash'
import { EDIT_SEARCH_CATEGORIES_TERM } from '../redux/actions/actionTypes';

class UrlRenderHelper {
  // state helpers
  getStateVal(key, defaultValue = null) {
    return stateProvider.get(key, defaultValue);
  }

  // tests
  unitTests() {
    return {
    };
  }

  // util helper reference

  get CProdRenderUrl() {
    return CProdRenderUrl;
  }

  newCProdRenderUrl(opts = null) {
    return new CProdRenderUrl(opts);
  }

  // location helpers

  returnProductRenderUrl(current) {
    if (current) {
      let type = _.get(current, 'type', null);
      if (type === 'custom-art') {
        /*
        // NOTE: custom art is just using its own url. //
        /////////////////////////////////////////////////
        let artRenderUrl, artRef, size, optParams;
        artRef = _.get(current, 'userArtId', null);
        size = `${current.bounds.w}x${current.bounds.h}`;
        optParams = null;
        artRenderUrl = this.getArtRenderUrl(artRef, size, optParams);
        */
        // alan overwrote this //
        // let userArtAssets = this.getStateVal("user.userArtAssets");
        // let artAsset = _.find(userArtAssets, { id: current.userArtId });
        // let url = _.get(artAsset, "url", null);
        // return url;
      } else {
        let textRenderUrl, textValue, size, colors, font, textEffect, textPatternid, textPatternColors, optParams, decType, realH, textFill;
        textFill = _.get(current, 'patternFill', null);

        size = _.get(current, 'bounds.h', null);

        size = Math.floor(size);

        textValue = _.get(current, 'value', null);
        if (!textValue) {
          type = _.get(current, 'type', null);
          if (type === 'player-number'){
            textValue = _.get(current, "placeholder", "00");
          }else if(type === 'player-name'){
            textValue = _.get(current, "placeholder", "Player Name");
          }else{
          textValue = _.get(current, "placeholder", "Enter Text");
          }
        }
        textPatternid = _.get(current, 'capabilities.textPatternFillId.id', null);

        textPatternColors = _.get(current, 'capabilities.textPatternColors', null);

        let formaTextFillColors = [];
        if (textPatternColors !== null) {
          let textFillColorArray = [];
          _.each(textPatternColors, e => {
            textFillColorArray.push(e.value);
          });
          _.each(textPatternColors, e => {
            if (!e) {
              formaTextFillColors.push(`transparent`);
            } else {
              let colorKey = helpers.colors.getColor(e);
              let hexValue = helpers.colors.toHexString(colorKey && colorKey.rgb);
              let rgbValue = helpers.colors.hexToRgb(hexValue) || {};
              formaTextFillColors.push(`rgb(${rgbValue.r},${rgbValue.g},${rgbValue.b})`);
            }

          });
        }
        while (_.get(_.last(formaTextFillColors), "color") === `transparent`) {
          formaTextFillColors.pop();
        }
        font = _.get(current, 'font', null);
        let fontsUpperCase = [
          'aachen',
          'add_city_boy',
          'air_millhouse',
          'apache',
          'arial',
          'collegiate',
          'elite',
          'evolution',
          'futura',
          'falcon',
          'geared',
          'homestead',
          'hometown',
          'interval',
          'machine',
          'playmaker',
          'unicorn'
        ]
        if (fontsUpperCase.includes(font)) {
          textValue = textValue.toUpperCase();
        }
        textEffect = _.get(current, 'textEffect', null);
        optParams = null;

        colors = _.get(current, 'capabilities.fillColors', null);

        let formatOutlineColors = [];
        let formatFillColor;

        if (colors !== null) {

          let fillColorArray = [];
          let outlineColorArray = [];

          _.each(current.capabilities.fillColors, e => {
            if (e.key === 'fill') {
              fillColorArray.push(e.value);
            } else {
              outlineColorArray.push(e.value);
            }
          });

          let fillColorKey = helpers.colors.getColor(fillColorArray[0]);
          if (fillColorKey === null) {
            formatFillColor = `rgb(246,205,11)`;
          } else {
            let fillHexValue = helpers.colors.toHexString(fillColorKey.rgb);
            let fillRgbValue = helpers.colors.hexToRgb(fillHexValue);
            formatFillColor = `rgb(${fillRgbValue.r},${fillRgbValue.g},${fillRgbValue.b})`;
          }

          _.each(outlineColorArray, e => {
            if (!e) {
              formatOutlineColors.push({ color: `transparent` });
            } else {
              let colorKey = helpers.colors.getColor(e);
              let hexValue = helpers.colors.toHexString(colorKey && colorKey.rgb);
              let rgbValue = helpers.colors.hexToRgb(hexValue) || {};
              formatOutlineColors.push({ color: `rgb(${rgbValue.r},${rgbValue.g},${rgbValue.b})` });
            }
          });
        }
        _.each(current.capabilities.fillColors, e => {
          if (e.key === 'outline1' && e.value === null) {
            formatOutlineColors = [];
          } else {
            formatOutlineColors = formatOutlineColors
          }
        });
        while (_.get(_.last(formatOutlineColors), "color") === `transparent`) {
          formatOutlineColors.pop();
        }

        size = 200;  // Alan put size override here as a test.
        decType = this.getStateVal('baseProduct.configurationType')
        let textSize = _.get(current, 'size', null);
        realH = textSize.replace(/[^0-9\.]/g, "");
        textRenderUrl = this.getTextRenderUrl(textValue, size, formatOutlineColors, font, textEffect, formatFillColor, textPatternid, formaTextFillColors, textFill, decType, realH, optParams);
        return textRenderUrl
      }
    }
    return null;
  }

  getDefaultDesignPatternThumbnailUrl(patternDef, patternColors = null) {

    if (!patternDef || !patternDef.key) { return null; }

    // for the CG products I was using, a side view looks better than the front view for showing off the pattern
    let preferredViews = ['right', 'left', 'side', 'default', 'front', 'back'];
    let defaultPatternColors = {
      6: 'white,gray,charcoal,black',
      5: 'white,gray,charcoal,black',
      4: 'white,gray,charcoal,black',
      3: 'white,gray,black',
      2: 'white,black',
      1: 'white',
    };

    let styleNum = this.getStateVal('baseProduct.styleNum');

    let allViewKeys = helpers.locations.getAllViewKeys();
    preferredViews = _.intersection(preferredViews, allViewKeys);
    let view = _.first(preferredViews) || _.first(allViewKeys) || 'default';
    let size = 200;
    let primaryColors = 'preview_med_white_fade';
    let optParams = {};

    if (patternColors === null) {
      let numColors = +patternDef.numPatternColors;
      if (!_.isFinite(numColors) || numColors <= 0) { numColors = 3; }
      numColors = _.clamp(numColors, 1, 6);
      patternColors = defaultPatternColors[numColors];
    } else if (_.isArray(patternColors)) {
      patternColors = _.join(patternColors, ',');
    }

    return this.getProductDesignPatternPreviewRenderUrl(styleNum, view, size, primaryColors, patternDef, patternColors, optParams);
  }

  getProductDesignPatternPreviewRenderUrl(styleNum, view, size, primaryColors, patternDef, patternColors = null, optParams = null) {
    optParams = optParams || {};
    optParams.crop = [0.2, 0.2, 0.6, 0.6];

    //include the pattern if it has a valid key and it is not a virtual pattern
    if (patternDef && patternDef.key && !patternDef.virtual) {
      optParams.layers = optParams.layers || {};
      optParams.layers[patternDef.key] = patternColors || true;
    }
    return this.getProductRenderUrl(styleNum, view, size, primaryColors, optParams);
  }

  getProductRenderUrl(styleNum, view, size, primaryColors, optParams = null) {
    let renderUrl = new CProdRenderUrl();
    renderUrl.baseUrl = AppConfig.cprodRenderApiUrl;
    let designNum = this.getStateVal('baseProduct.designNum');
    if (this.getStateVal('baseProduct.configurationType') === 'custom-sub') {
      let designNum = this.getStateVal('baseProduct.designNum');
      renderUrl.routePath = '/styles/' + styleNum + '/' + designNum + '/' + view + '/render';
    } else {
      renderUrl.routePath = '/styles/' + styleNum + '/stock/' + view + '/render';
    }

    renderUrl.size = size;
    //primary colors only for stock products and for sublimated products just get the layers
    if (designNum === 'default') {
      renderUrl.setPrimaryColorKeys(primaryColors);
    }
    renderUrl.optParams = optParams;
    return renderUrl.toUrl();
  }

  getTextRenderUrl(text, size, strokes, font, textEffect, color, textPatternid, textPatternColors, textFill, decType, realH, optParams = null) {
    let baseUrl = AppConfig.textRenderApiUrl;
    let serviceRoute = '/builder/custom-text';
    let params = {
      text,
      size,
      strokes,
      font,
      textEffect,
      color,
      textPatternid,
      textPatternColors,
      textFill,
      decType,
      realH,
      ...optParams
    };

    if (!params.textEffect) {
      delete params.textEffect
    }
    if (!params.font) {
      delete params.font
    }
    if (!params.textPatternid) {
      delete params.textPatternid
    }
    if (!params.textPatternColors) {
      delete params.textPatternColors
    }
    if (!params.color) {
      delete params.color
    }
    if (!params.size) {
      delete params.size
    }
    if (!params.strokes) {
      delete params.strokes
    }
    if (!params.decType) {
      delete params.decType
    }
    if (!params.realH) {
      delete params.realH
    }

    if (params.textFill) {
      if (params.textFill === "No") {
        if (params.textPatternid || params.textPatternColors) {
          params.textPatternid = null;
          params.textPatternColors = [];
        }
      }
      delete params.textFill;
    } else {
      delete params.textFill;
    }

    // https://builder-api.dev.foundersportgroup.com/api/builder/custom-text?json={%22text%22:%22Ruskin%22,%22height%22:%22500%22,%22strokes%22:[{%22color%22:%22red%22},{%22color%22:%22blue%22}],%22font%22:%22nfl-texans%22,%22textEffect%22:%22straight%22,%22color%22:%22pink%22}
    let url = urljoin(baseUrl, serviceRoute) + '?json=' + encodeURIComponent(JSON.stringify(params));
    return url;
  }

  getArtRenderUrl(artId, size, optParams) {
    let baseUrl = AppConfig.artRenderApiUrl;
    let serviceRoute = '/custom-art/' + artId + '/render';
    let params = { size, ...optParams };
    let url = urljoin(baseUrl, serviceRoute) + '?json=' + encodeURIComponent(JSON.stringify(params));
    return url;
  }

  appendParamStringToUrl(url, paramsStr) {
    let retval = url;
    if (retval && paramsStr && _.isString(retval) && _.isString(paramsStr)) {
      let delim = _.includes(retval, '?') ? '&' : '?';
      retval += delim + paramsStr;
    }
    return retval;
  }

  generateUrlOptions(customConfig, launchContext, baseProduct) {
    let opts = {};
    if (helpers.product.isCustomSub() || helpers.product.isFullCustom()) {
      let layerPatternVal = true;
      if (helpers.customLayers.isAdvancedMode()) {
        // TODO: we can move some of this logic into this helper
        // helpers.customLayers.getAdvancedLayerColors();
        let layerColors = _.get(customConfig, "customLayers.primaryPattern.colors");
        layerColors = helpers.colors.getColorKeyStrFromColorCodes(layerColors);
        if (!_.isEmpty(layerColors)) {
          layerPatternVal = layerColors;
        }
      }
      let layerPattern = _.get(customConfig, "customLayers.primaryPattern.key");
      if (layerPattern && layerPatternVal) {
        _.set(opts, ['layers', layerPattern], layerPatternVal);
      }
    }
    let layers = _.get(customConfig, "customLayers.layers");
    let launchContextLayers = _.get(launchContext, "layers");
    if (layers) {
      if (layers.length > 0) {
        // if(!test){
        //   _.each(layers, (layer,index) => {
        //     _.set(opts, ['layers', layer.key], helpers.colors.getColorKeyFromColorCode(launchContextLayers[index].color));
        //   })
        // }
        _.each(layers, layer => {
          _.set(opts, ['layers', layer.key], helpers.colors.getColorKeyFromColorCode(layer.color));
        })
      }
      else {
        layers = launchContextLayers
        // let defaultColors = _.get(baseProduct, "customLayers.defaultColors");
        _.each(layers, layer => {
          _.set(opts, ['layers', layer.key], helpers.colors.getColorKeyFromColorCode(layer.color));
        })
      }
    }

    let upgrades = _.get(customConfig, 'upgrades');
    _.each(upgrades, upgrade => {
      if (upgrade && upgrade.value) {
        let upgradeColorKey = helpers.colors.getColorKeyFromColorCode(upgrade.color);
        _.set(opts, ['layers', upgrade.key], upgradeColorKey);
      }
    });
    return opts;
  }

}

// export a static singleton instance
const urlRenderHelper = new UrlRenderHelper();
export default urlRenderHelper;