import React from "react";

const RecommenedSVG =(props)=>{
  const { className, height=32, width=32, fill="black", stroke = "black", strokeWidth = "0", onClick } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.116 16.863" className={className} height={height} width={width} fill={fill} stroke={stroke} strokeWidth={strokeWidth} onClick={onClick} >
      <g id="Group_988" data-name="Group 988" transform="translate(-533.529 -170.951)">
        <path id="path" d="M755.442,1185.2a1.922,1.922,0,0,1,1.874,1.874v14.99l-6.558-2.811-6.558,2.811v-14.99a1.921,1.921,0,0,1,1.874-1.874Z" transform="translate(-210.671 -1014.249)"/>
        <path id="path-2" data-name="path" d="M930.26,982.032l-2.641,1.619.681-2.982-2.3-2,3.067-.255,1.193-2.812,1.193,2.812,3.067.255-2.3,2,.682,2.982Z" transform="translate(-390.32 -801.624)" fill="#fff"/>
      </g>
</svg>
  );
};

export default RecommenedSVG;
