import React, { Component } from 'react';
import { connect } from 'react-redux';
import TabContent from './tabContent/tabContent';
import { messageIframe } from "../../redux/reducers/notifications/notificationsActions";
import ColorsTab from './tabs/colors/colors';
import Fabrics from "./tabs/fabrics/fabrics";
import PlayerRoster from "./tabs/playerRoster/playerRoster";
import AddArt from "./tabs/addArt/addArt"
import SummaryTab from "./tabs/summaryTab/summaryTab";
import UploadArt from './tabs/uploadArt/UploadArt';
import AddDesign from "./tabs/AddDesign/AddDesign";
import StyleDetails from "./tabs/StyleDetails/StyleDetails"
import AddDecoration from "./tabs/AddDecoration/AddDecoration";

class Sidebar extends Component {

  renderOption() {
    switch (this.props.navigation.activeTab.tab) {
      case 'style':
        return <StyleDetails />;
      case 'add-design':
        return <AddDesign />;
      case 'colors':
        return <ColorsTab />;
      case 'fabric':
        return <Fabrics />;
      case 'names-and-numbers':
        return <PlayerRoster />;
      case 'decorations':
        return <AddDecoration />;
      // <AddText />;
      case 'up-art':
        return <UploadArt />;
      case 'system-art':
        return <AddArt />;
      case 'order-summary':
        return <SummaryTab />;
      default:
        return null;
    }
  }

  cancelClicked = () => {
    this.props.messageIframe('cancel', null);
  };

  render() {
    // let addToolTip = _.find(this.props.toolTips, { key: "add-to-cart-button" }).message;
    // let saveToolTip = _.find(this.props.toolTips, { key: "save-button" }).message;
    // let cancelToolTip = _.find(this.props.toolTips, { key: "cancel-button" }).message;
    return (
      <div className="sidebar">
        <div className="sidebar__wrapper">
          <TabContent children={this.renderOption()} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    navigation: state.navigation,
    reduxState: state,
    toolTips: state.toolTips
  };
}

export default connect(mapStateToProps, { messageIframe })(Sidebar);


