import React, { Component } from 'react';
import queryString from "query-string";
import _ from 'lodash';
import SearchSVG from '../../assets/icons/SsearchSVG';
import SublimatedProduct from '../sublimatedProductsDesigns/sublimatedProduct';
import InfinitScroll from 'react-infinite-scroll-component'
import api from "../../api";
import { connect } from 'react-redux';

class SublimatedProductsPage extends Component {
    filteredData = [];
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            loading: false,
            query: "",
            designs: [],
            count: 16,
            end: 16,
            start: 0,
        }
        this.fetchNextDesigns = this.fetchNextDesigns.bind(this);
        this.handleOnInputChange = this.handleOnInputChange.bind(this);
    }

    componentDidMount() {
        const queryParams = this.tryParseQueryParams();
        this.initializeDesigns(queryParams.styleNumber || null);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.designs.length !== this.state.designs.length) {
            this.setState({
                designs: this.state.designs
            })
        }
    }

    async initializeDesigns(styleNumber) {
        let styleNumToQuery = styleNumber;
        const { end, start } = this.state;
        if (!styleNumToQuery) {
            if (this.props.launchContext.styleNum) {
                styleNumToQuery = this.props.launchContext.styleNum;
                console.log(`Initializing designs for the default ${styleNumToQuery}`);
            }
            else {
                styleNumToQuery = 'AS0225';
                console.log(`Initializing designs for the default ${styleNumToQuery}`);
            }
        }

        let dataRes = await api.sublimatedProducts.getDesignsByStyleNumber(styleNumToQuery);
        this.setState({ data: dataRes })
        if (_.isEmpty(this.state.data)) {
            this.state.data = { styleNumber: styleNumToQuery, designs: [] }
        };
        this.setState({ designs: this.state.data.designs.slice(start, end), styleNumber: styleNumToQuery })
    }

    fetchNextDesigns() {
        this.setState({ start: this.state.start + this.state.count, end: this.state.end + this.state.count })
        this.setState({ designs: this.state.designs.concat(this.state.data.designs.slice(this.state.start, this.state.end)) })
    }

    handleOnInputChange(event) {
        if (!this.state.query) {
            this.setState({ query: event.target.value.toUpperCase() });
        } else {
            this.setState({ query: event.target.value.toUpperCase() }, () => {
            });
            this.filteredData = this.state.data.designs.filter(filterdata => filterdata.name.toUpperCase().includes(event.target.value.toUpperCase()));
            this.setState({ designs: this.filteredData })
        }
    };

    renderLoadingMessage() {
        return (
            <div className="loading-view">
                <h2 className="center-text">Loading...</h2>
            </div>
        );
    }

    renderEmptyResultsMessage() {
        return (
            <div className="no-results-warning">
                <h2 className="center-text">No products were found!</h2>
            </div>
        );
    }

    tryParseQueryParams() {
        let queryParams = null;
        try {
            let queryStr = (window && window.location && window.location.search) || '';
            queryParams = queryString.parse(queryStr);
        } catch (ex) {
        }
        return queryParams
    }
    render() {
        return (
            <div className="container">
                <div className="row mt-5">
                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-4 col-xs-4">
                        <div className="row">
                            <div className="col-12">
                                <div className="heading-main">CHOOSE YOUR DESIGN</div>
                                <div className="heading-sub">Colors are for illustration purposes only. Click on any
                                    design to see more details and start customizing.</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-4 col-xs-4 ">
                        <div className="header-search-input input-group">
                            <div className="input-group-append">
                                <span className="input-group-text search-button border-0"><SearchSVG height="16" /></span>
                            </div>
                            <input type="search" value={this.state.query.toUpperCase()} className="form-control input-text border-0"
                                placeholder="Find a design..." onChange={this.handleOnInputChange} id="input-search" />

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="">
                        {this.state.loading ? this.renderLoadingMessage() : null}
                        {(!this.state.loading && _.isEmpty(this.state.designs)) ? this.renderEmptyResultsMessage() : null}
                        <InfinitScroll
                            dataLength={this.state.designs.length}
                            next={this.fetchNextDesigns}
                            hasMore={true}>
                            <SublimatedProduct data={this.state.designs} style={this.state.styleNumber} />
                        </InfinitScroll>
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        toolTips: state.toolTips,
        baseProduct: state.baseProduct,
        customConfig: state.customConfig,
        launchContext: state.launchContext
    }
}

export default connect(mapStateToProps, {})(SublimatedProductsPage);
