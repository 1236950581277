import React, {Component} from "react";
import ViewActive from "./viewActive/ViewActive";

class PreviewStage extends Component {
  render() {
    return (
      <div className={"preview d-flex flex-lg-column"}>
        <ViewActive issues={this.props.issues}/>
      </div>
    );
  }
}


export default PreviewStage;
