import stateProvider from "./stateProvider";

//improve once this gets used more than once (line 8, add other getters, getters prefix, tests, other)
export class UserHelper {
  getWebUserId(){
    return stateProvider.get("user.webUser.id");
  }
}

const userHelperInstance = new UserHelper();
export default userHelperInstance;