import { RestService } from '../lib';


/**
 * Service Endpoint Implementation
 */
export default class WebUserConfigsService extends RestService {

  constructor(opts = null) {
    super(opts);
    this.serviceName = 'webUserConfigs';
    this.routePath = 'builder/web-user/configs';
    this.authEnabled = true;
  }
}


