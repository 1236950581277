import _ from "lodash";


export default function loadGoogleAnalytics(googleAnalyticsKey) {

  // do not load if key is empty
  if (!googleAnalyticsKey || _.isEmpty(googleAnalyticsKey)) { return; }

  let head = document.getElementsByTagName("head")[0]; // for IE 9 :/
  const googleAnalyticsScript = "googleAnalyticsScript";
  const hasGoogleAnalyticsScript = document.getElementById(googleAnalyticsScript);
  if (!hasGoogleAnalyticsScript) {
    attachGoogleAnalyticsScript(head, googleAnalyticsScript);
  }
}


function attachGoogleAnalyticsScript(head, googleAnalyticsScript, googleAnalyticsKey) {

  // do not load if key is empty
  if (!googleAnalyticsKey || _.isEmpty(googleAnalyticsKey)) { return; }

  let ua = googleAnalyticsKey;
  let script = document.createElement("script");
  script.id = googleAnalyticsScript;
  script.innerHTML = `
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://dev.foundersportgroup.com/analytics.js','ga');
    ga('create', '${ua}', 'auto');
    ga(function(tracker) {
      tracker.set('sendHitTask', function(model) {
        var payLoad = model.get('hitPayload');
        var gifRequest = new XMLHttpRequest();
        var gifPath = "http://dev.foundersportgroup.com/api/analytics";
        gifRequest.open('get', gifPath + '?' + payLoad, true);
        gifRequest.send();
      });
    });
    ga('send', 'pageview');
  `;
  head.appendChild(script);
}
