import * as types from '../../actions/actionTypes';
import helpers from "../../../helpers";
import _ from "lodash";
import { initLaunchContext } from '../launchContext/launchContextActions';

let resetBounds = { x: 0, y: 0, h: 0, w: 0, r: 0 };

export function formatQuantityRoster(newRosterData) {
  if (newRosterData && newRosterData.length > 0) {
    return { type: types.UPLOAD_QUANTITY_ROSTER, payload: newRosterData };
  }
}

export function addCustomEntity(currentConfig, newEntity) {
  currentConfig = currentConfig.push(newEntity);
  return { type: types.UPDATE_LOCATION, currentConfig };
}

export function moveLocationFromDropdown(oldCustomLocations, oldEntityCode, newEntityCode, baseProduct) {
  let locations = _.clone(oldCustomLocations);
  let entity = _.find(locations, { code: oldEntityCode });
  let locationDetails = helpers.product.getLocationDetails(baseProduct, newEntityCode);
  let index = _.findIndex(locations, { code: oldEntityCode });
  let view = locationDetails.view;
  entity.entity._init = true;
  entity.entity.bounds = resetBounds;
  locations[index] = { code: newEntityCode, view, entity: entity.entity };
  return { type: types.AMEND_LOCATION, locations };
}

export function addNewLocation(oldlocations, locationInfo, selectedType, text, url = null) {
  //this prevents the user from creating an empty value
  if (!text || text === "") {
    text = "ENTER TEXT"
  }
  let locations = _.clone(oldlocations) || [];
  let views = helpers.locations.getAllViewInformation();
  let myView = _.find(views, { key: locationInfo.view });
  let entity = helpers.product.createDefaultEntity(locationInfo, selectedType, myView.masterImageDpi, text);

  if (selectedType === "custom-art") {
    entity.url = url;
  }

  locations.push({ code: locationInfo.code, view: locationInfo.view, entity });
  return { type: types.AMEND_LOCATION, locations };
}

export function setAdditionalAssets(assets) {
  return { type: types.SET_ADDITIONAL_ASSETS, payload: assets };
}

export function formatRoster(newRosterData) {
  if (newRosterData && newRosterData.length > 0) {
    return { type: types.UPLOAD_ROSTER, payload: newRosterData };
  }
}

export function changeTextLocation(location, input) {
  let newLocation = location;
  newLocation.code = input.code;
  return { type: types.UPDATE_LOCATION, newLocation };
}

export function setStyleNum(styleNum) {
  return { type: types.SET_STYLE_NUM, styleNum };
}
/*
export function setConfigId(configId) {
  return { type: types.SET_CONFIG_ID, configId };
}
*/
export function setConfigId(actionPayload) {
  return { type: types.SET_CONFIG_ID, actionPayload };
}

export function setWebRef(webRef) {
  return { type: types.SET_WEB_REF, webRef };
}

export function changeSetting(oldLocations, code, settings, baseProduct) {
  let locations = _.clone(oldLocations);
  let x = _.findIndex(locations, { code });
  let locationDetails = _.find(locations, { code })
  if (x > -1) {
    if(settings.setting === 'saveEntity'){
      locations[x][settings.setting] = settings.value;
    }
    else if(settings.setting === 'editEntity') {
      locations[x][settings.setting] = settings.value;
    }
    else{
    locations[x].entity[settings.setting] = settings.value;
    }
    // if decoration setting is changed set entity to that decoration methods default values.
    if (settings.setting === "decorationMethod") {
      let baseProductLocation = _.find(baseProduct.locations, { code })
      let entity = _.find(baseProductLocation.allowedEntities, { type: locationDetails.entity.type });
      let allowedDecorationMethod = _.find(entity.allowedDecorationMethods, { key: settings.value });
      if (allowedDecorationMethod) {
        let existing = locations[x].entity;

        if (allowedDecorationMethod.defaultFont) {
          locations[x].entity['font'] = (_.indexOf(allowedDecorationMethod.allowedFonts, existing.font) > -1) ? existing.font : allowedDecorationMethod.defaultFont;
        }

        if (allowedDecorationMethod.defaultSize) {
          locations[x].entity['size'] = (_.indexOf(allowedDecorationMethod.allowedSizes, existing.size) > -1) ? existing.size : allowedDecorationMethod.defaultSize;
        }

        if (allowedDecorationMethod.defaultTextEffect) {
          locations[x].entity['textEffect'] = (_.indexOf(allowedDecorationMethod.allowedTextEffects, existing.textEffect) > -1) ? existing.textEffect : allowedDecorationMethod.defaultTextEffect;
        }
        if (allowedDecorationMethod.defaultColor) {
          let fillColors = _.cloneDeep(allowedDecorationMethod.capabilities.fillColors);
          _.each(fillColors, (e, i) => {
            if (e) {
              let existingValue = _.get(existing, `capabilities.fillColors[${i}].value`, null);
              if (existingValue && _.includes(allowedDecorationMethod.allowedColors, existingValue)) {
                e.value = existingValue;
              } else {
                e.value = _.get(e, 'defaultColor', null);
              }
            }
          });
          locations[x].entity.capabilities['fillColors'] = fillColors;
        }
      }
    }
    // after all changes update url.
    let locationContentClone = _.cloneDeep(locations[x]);
    let url = null;
    if (locations[x].entity.type === "custom-art") {
      url = locations[x].entity.url;
    } else {
      url = helpers.urlRender.returnProductRenderUrl(locationContentClone.entity);
    }
    locations[x].entity.url = url;
  }
  return { type: types.AMEND_LOCATION, locations };
}

export function replaceLocation(oldLocations, sourceLocation, targetLocation) {
  // make a brand new location for location. just incase values like deco method or color are not allowed. This sets to defaults for location.
  // TODO: could check to see if incoming values are allowed then let them through. color, size , decoMethod etc...
  let type = sourceLocation.entity.type;
  let value = sourceLocation.entity.value;
  let view = targetLocation.view;
  let code = targetLocation.code;
  let artAsset = null;
  if (sourceLocation.entity.type === "custom-art") {
    artAsset = _.cloneDeep(sourceLocation.entity);
    artAsset.id = artAsset.userArtId;
  }
  let newLocation = helpers.navigation.makePendingLocation(type, value, view, code, artAsset);

  let locations = _.cloneDeep(oldLocations);
  let locationToRemove = _.findIndex(locations, { code: sourceLocation.code });
  let locationToUpdate = _.findIndex(locations, { code: targetLocation.code });
  locations[locationToUpdate] = newLocation;
  // if source code and target code are same that means it came from pendingLocation and has no location to delete.
  if (sourceLocation.code !== targetLocation.code) {
    locations.splice(locationToRemove, 1);
  }
  return { type: types.AMEND_LOCATION, locations };
}

export function toggleBasicMode(customConfig, baseProduct) {
  let temp = _.cloneDeep(customConfig);
  let configLayers = _.get(customConfig, 'customLayers.layers');
  temp.customLayers.layers = null;
  temp.customLayers.primaryPattern.colors = null;
  let tempColors = [];
  _.each(configLayers, layer => {
    tempColors.push(layer.color);
  })
  temp.customLayers.primaryColors = tempColors;
  temp.customLayers.isAdvancedMode = false;
  let newCustomLayers = temp.customLayers;
  return { type: types.TOGGLE_COLOR_MODE, customLayers: newCustomLayers };
}

export function toggleAdvancedMode(customConfig, baseProduct, launchContext) {
  let temp = _.cloneDeep(customConfig);
  let productLayers = _.get(baseProduct, 'customLayers.layers');
  let prevPrimaryColors = _.get(launchContext, 'colors');
  let launchContextLayers = _.get(launchContext, 'layers');
  let primaryColors = _.get(temp, 'customLayers.primaryColors');
  let newAdvancedLayers = [];
  if (prevPrimaryColors !== null && prevPrimaryColors !== undefined) {
    if (prevPrimaryColors.length > 0) {
      _.each(prevPrimaryColors, (color, index) => {
        primaryColors[index] = color
      })
    }
  }
  //switching to advanced mode
  // let newLaunchContext = _.cloneDeep(launchContext)
  if (launchContextLayers) {
    if (_.isEqual(launchContextLayers, _.get(customConfig, 'customLayers.layers'))) {
      // let tempLayers = productLayers
      _.each(productLayers, (layer, index) => {
        let layerName = layer.name;
        if (layerName) {
          layerName = layerName.replace(/[\{].+?[\}]/g, '');
          layerName = layerName.trim();
          if (index <= launchContextLayers.length-1) {
            newAdvancedLayers.push(launchContextLayers[index]);
          }
          else {
            newAdvancedLayers.push({ key: layer.key, color: primaryColors[index], layerName: layerName, colorGroup: layer.colorGroup });
          }
        }
      })
    }
  }else{
  _.each(productLayers, (layer, index) => {
    let layerName = layer.name;
    if (layerName) {
      layerName = layerName.replace(/[\{].+?[\}]/g, '');
      layerName = layerName.replace('Layer', '');
      layerName = layerName.trim();
    }
    newAdvancedLayers.push({ key: layer.key, color: primaryColors[index], layerName: layerName, colorGroup: layer.colorGroup });
  })
}
  // _.each(newAdvancedLayers, layer => {
  //   layer.color = primaryColors[layer.colorGroup];
  // })
  temp.customLayers.layers = newAdvancedLayers;
  let numOfColors = _.get(temp, "customLayers.primaryPattern.numPatternColors");
  let newPatternColors = primaryColors.slice(0, numOfColors);
  temp.customLayers.primaryPattern.colors = newPatternColors;
  temp.customLayers.isAdvancedMode = true;
  let newCustomLayers = temp.customLayers;
  return { type: types.TOGGLE_COLOR_MODE, customLayers: newCustomLayers };
}

export function changeTextPatternColor(customConfig, index, color) {
  let temp = _.cloneDeep(customConfig);
  temp.locations[0].entity.capabilities.textPatternColors[index] = color.code;
  return { type: types.CHANGE_PATTERN_COLOR, temp };
}



export function changePatternAdvanced(patternKey, layerKey, customConfig, loadedDefs, userPatterns) {
  let oldConfig = _.cloneDeep(customConfig);
  let patternDef = _.find(loadedDefs.patterns, { key: patternKey })
  let numberOfColors = patternDef.numPatternColors;
  let configLayer = _.find(oldConfig.customLayers.layers, { key: layerKey });
  let configLayerIndex = _.findIndex(oldConfig.customLayers.layers, { key: layerKey });
  let tempColorArray = [];
  let primaryColors = oldConfig.customLayers.primaryColors;
  for (var i = 0; i < 5; i++) {
    _.each(primaryColors, color => {
      tempColorArray.push(color)
    })
  }
  let newArray = tempColorArray.slice(0, numberOfColors)
  configLayer.pattern = {
    key: patternKey,
    colors: newArray
  }
  let patternHistory = _.find(userPatterns, { key: patternKey })

  if (patternHistory && patternHistory.key !== "solid") {
    configLayer.pattern.colors = patternHistory.colors;
  } else {
    configLayer.pattern.colors = newArray;
  }
  oldConfig.customLayers[configLayerIndex] = configLayer;
  return { type: types.CHANGE_PATTERN_ADVANCED, payload: oldConfig.customLayers };
}


export function swapLocations(oldLocations, sourceLocation, targetLocation) {
  // make a brand new location for location. just incase values like deco method or color are not allowed. This sets to defaults for location.
  // TODO: could check to see if incoming values are allowed then let them through. color, size , decoMethod etc...
  let locationAType = sourceLocation.entity.type;
  let locationABounds = sourceLocation.entity.bounds;
  let locationAValue = sourceLocation.entity.value;
  let locationAView = targetLocation.view;
  let locationACode = targetLocation.code;
  let locationAGroupName = sourceLocation.groupName;
  let locationADisplayOrder = sourceLocation.displayOrder;
  let artAssetA = null;
  if (sourceLocation.entity.type === "custom-art") {
    artAssetA = _.cloneDeep(sourceLocation.entity);
    artAssetA.id = artAssetA.userArtId;
  }
  let locationBType = targetLocation.entity.type;
  let locationBBounds = targetLocation.entity.bounds;
  let locationBValue = targetLocation.entity.value;
  let locationBGroupName = targetLocation.groupName;
  let locationBDisplayOrder = targetLocation.displayOrder;
  let locationBView = sourceLocation.view;
  let locationBCode = sourceLocation.code;
  let artAssetB = null;
  if (targetLocation.entity.type === "custom-art") {
    artAssetB = _.cloneDeep(targetLocation.entity);
    artAssetB.id = artAssetB.userArtId;
  }
  let newLocationA = helpers.navigation.makePendingLocation(locationAType, locationAValue, locationAView, locationACode, artAssetA, locationBBounds, locationBGroupName, locationBDisplayOrder);
  let newLocationB = helpers.navigation.makePendingLocation(locationBType, locationBValue, locationBView, locationBCode, artAssetB, locationABounds, locationAGroupName, locationADisplayOrder);

  let locations = _.cloneDeep(oldLocations);
  let locationA = _.findIndex(locations, { code: sourceLocation.code });
  let locationB = _.findIndex(locations, { code: targetLocation.code });

  locations[locationA] = newLocationB;
  locations[locationB] = newLocationA;

  return { type: types.AMEND_LOCATION, locations }
}

export function moveToEmptyLocation(oldLocations, sourceLocationCode, targetLocationCode) {
  // make a brand new location for location. just incase values like deco method or color are not allowed. This sets to defaults for location.
  // TODO: could check to see if incoming values are allowed then let them through. color, size , decoMethod etc...
  let locations = _.cloneDeep(oldLocations);
  let sourceLocation = _.find(locations, { code: sourceLocationCode });
  let targetLocation = _.find(helpers.product.getStateVal('baseProduct.locations'), { code: targetLocationCode });

  let type = sourceLocation.entity.type;
  let value = sourceLocation.entity.value;
  let view = targetLocation.view;
  let code = targetLocation.code;
  let artAsset = null;
  if (sourceLocation.entity.type === "custom-art") {
    artAsset = _.cloneDeep(sourceLocation.entity);
    artAsset.id = artAsset.userArtId;
  }
  let newLocation = helpers.navigation.makePendingLocation(type, value, view, code, artAsset);
  newLocation.entity = { ...sourceLocation.entity, bounds: newLocation.entity.bounds }
  newLocation.saveEntity = sourceLocation.saveEntity;
  newLocation.editEntity = sourceLocation.editEntity;
  let allowed = _.find(targetLocation.allowedEntities, {key: sourceLocation.entity.type});
  newLocation.entity.size = allowed.defaultSize
  newLocation.groupName = targetLocation.groupName;
  newLocation.displayOrder = targetLocation.displayOrder;
  locations.push(newLocation);

  // if source code and target code are same that means it came from pendingLocation and has no location to delete.
  let locationToRemove = _.findIndex(locations, { code: sourceLocation.code });
  if (sourceLocation.code !== targetLocation.code) {
    locations.splice(locationToRemove, 1);
  }

  return { type: types.AMEND_LOCATION, locations };
}

export function addPendingLocationToLocations(oldLocations, pendingLocation, newProd) {
  let locations = _.cloneDeep(oldLocations);
  pendingLocation = _.cloneDeep(pendingLocation);
  const code = pendingLocation.code;
  pendingLocation = { ...pendingLocation, groupName: newProd[0].groupName, displayOrder: newProd[0].displayOrder }

  locations.push(pendingLocation);
  return { type: types.AMEND_LOCATION, locations };
}

export function updateLocationsBound(locArray = '') {
   return { type: types.UPDATE_BOUNDS, locArray };
 }

export function changeProductColor(customConfig, color) {
  let temp = _.clone(customConfig);
  temp['color'] = (color) ? color.code : null;
  return { type: types.UPDATE_PRODUCT_COLOR, temp };
}

export function changeChromagearColor(customConfig, color, colorIndex, type) {
  let temp = _.cloneDeep(customConfig);
  let layerIndex = _.findIndex(temp.customLayers.layers, { key: type });
  let configLayer = temp.customLayers.layers[layerIndex];
  if (configLayer.color === null) {
    temp.customLayers.layers[layerIndex].pattern.colors[colorIndex] = color.code;
  } else {
    temp.customLayers.layers[layerIndex].color = color.code;
  }

  return { type: types.UPDATE_CHROMAGEAR_COLOR, temp };
}

export function changeChromagearGroup(customConfig, color, index) {
  let temp = _.cloneDeep(customConfig);
  let newColorObject = color.code;
  temp.customLayers.primaryColors[index] = newColorObject;
  if (index === 0) {
    temp.color = color.code;
  }
  return { type: types.UPDATE_CHROMAGEAR_COLOR, temp };

}

export function defaultLayerColors(customConfig) {
  let temp = _.cloneDeep(customConfig);
  let primaryColors = temp.customLayers.primaryColors;
  _.each(temp.customLayers.primaryPattern.colors, (color, index) => {
    temp.customLayers.primaryPattern.colors[index] = primaryColors[index];
  })
  return { type: types.UPDATE_CHROMAGEAR_COLOR, temp };
}

export function changeChromagearLayerColor(customConfig, color, index, type, baseProduct) {

  let temp = _.cloneDeep(customConfig);
  let newColorObject = color.code;

  if (helpers.customLayers.isAdvancedMode()) {
    // let productLayer = _.find(baseProduct.customLayers.layers, {key: type});
    // let colorGroup = _.get(productLayer, "colorGroup");
    // _.each(temp.customLayers.layers, (layer) => {
    //   if (layer.colorGroup === colorGroup) {
    //     layer.color = newColorObject;
    //   }
    // })
    temp.customLayers.layers[index].color = newColorObject
    temp.customLayers.primaryColors[index] = color.code;
  } else if (helpers.customLayers.isBasicMode()) {
    temp.customLayers.primaryColors[index] = newColorObject;
  }
  return { type: types.UPDATE_CHROMAGEAR_COLOR, temp };
}

export function deleteLocation(index, locations) {
  let updatedLocations = _.cloneDeep(locations);
  let locationCode = index.code;
  if (locationCode) {
    _.remove(updatedLocations, (element) => {
      return element.code === locationCode;
    })
  } else {
    updatedLocations.splice(index, 1);
  }
  return { type: types.DELETE_LOCATION, updatedLocations };
}

export function updateStitching(customConfig, stitching) {
  let upgrades = _.cloneDeep(customConfig.upgrades)
  if (upgrades.length > 0) {
    let s = _.findIndex(upgrades, { key: 'stitching' });
    if (s > -1) {
      let newStitchingObject = _.cloneDeep(upgrades[s]);
      newStitchingObject['color'] = stitching.code;
      upgrades[s] = newStitchingObject;
    }
  }
  return { type: types.UPDATE_UPGRADES, upgrades };
}

export function removeUpgrade(customConfig, upgradeKey) {
  let upgrades = _.cloneDeep(customConfig.upgrades);
  if (upgrades.length > 0) {
    let s = _.findIndex(upgrades, { key: upgradeKey });
    if (s > -1) {
      upgrades.splice(s, 1);
    }
  }
  return { type: types.UPDATE_UPGRADES, upgrades };
}

export function updateAddStitchingUpgrade(customConfig, checkboxValue) {
  let upgrades = customConfig.upgrades;
  let upgradeKey = 'stitching';
  let targetUpgrade = _.find(upgrades, { key: upgradeKey });
  if (targetUpgrade) {
    targetUpgrade.value = checkboxValue;
  } else {
    let prodUpgrade = helpers.product.getUpgrade(upgradeKey);
    targetUpgrade = {
      key: upgradeKey,
      color: _.get(prodUpgrade, 'defaultColor', null),
      value: checkboxValue
    };
    upgrades.push(targetUpgrade);
  }
  customConfig.upgrades = upgrades;
  return { type: types.UPDATE_UPGRADES, upgrades };
}

export function updatePattern(customConfig, pattern, index, loadedDefs) {
  let temp = _.clone(customConfig);
  let patternDef = _.find(loadedDefs.layerPatterns, { key: pattern })
  let numberOfColors = patternDef.numPatternColors;
  let tempColorArray = [];
  let primaryColors = temp.customLayers.primaryColors;
  for (var i = 0; i < 5; i++) {
    _.each(primaryColors, color => {
      tempColorArray.push(color)
    })
  }
  let newColorArray = tempColorArray.slice(0, numberOfColors)
  let layerPattern = _.find(loadedDefs.layerPatterns, { key: pattern })
  layerPattern.colors = newColorArray;
  temp.customLayers.primaryPattern = layerPattern;
  return { type: types.UPDATE_PATTERN, temp };
}

export function updateAddPatternUpgrade(customConfig, checkboxValue) {
  let upgrades = customConfig.upgrades;
  let upgradeKey = 'pattern';
  let targetUpgrade = _.find(upgrades, { key: upgradeKey });
  if (targetUpgrade) {
    targetUpgrade.value = checkboxValue;
  } else {
    let prodUpgrade = helpers.product.getUpgrade(upgradeKey);
    targetUpgrade = {
      key: upgradeKey,
      color: _.get(prodUpgrade, 'defaultColor', null),
      value: checkboxValue
    };
    upgrades.push(targetUpgrade);
  }
  customConfig.upgrades = upgrades;
  return { type: types.UPDATE_UPGRADES, upgrades };
}

export function changePatternColor(customConfig, index, color, navigation) {
  let temp = _.cloneDeep(customConfig);
  let locationContent = _.find(temp.locations, { code: navigation.activeLocation });
  locationContent.entity.capabilities.textPatternColors[index] = color.code;
  let url = helpers.urlRender.returnProductRenderUrl(locationContent.entity);
  locationContent.entity.url = url;
  // temp.locations[0].entity.capabilities.textPatternColors[index] = color.code;
  // let url = helpers.urlRender.returnProductRenderUrl(temp.locations[0].entity);
  // console.log(url)
  // temp.locations[0].entity.url = url;
  // temp.customLayers.primaryPattern.colors[index] = color.code;
  return { type: types.CHANGE_PATTERN_COLOR, temp };
}

export function changeTextColor(oldLocations, code, type, input) {
  let locations = _.cloneDeep(oldLocations)
  let x = _.findIndex(locations, { code })
  if (x > -1) {
    let capabilities = (locations[x].entity.capabilities) ? locations[x].entity.capabilities : {};
    let y = _.findIndex(capabilities.fillColors, { "key": type })
    if (y > -1) {
      capabilities.fillColors[y].value = input.code
    }
    let y1 = _.findIndex(capabilities.fillColors, { "key": 'outline1' })
    let y2 = _.findIndex(capabilities.fillColors, { "key": 'outline2' })
    if(capabilities.fillColors[y1] && capabilities.fillColors[y1].value === null) {
      if(capabilities.fillColors[y2]) {
        capabilities.fillColors[y2].value = null;
      }
    }
    // update location url after changing color.
    let url = helpers.urlRender.returnProductRenderUrl(locations[x].entity);
    locations[x].entity.url = url;
  }
  return { type: types.AMEND_LOCATION, locations };
}

export function updateCell(args, newValue) {
  const [columnToModify, rowToModify] = args;
  const value = newValue ? newValue : null;
  const payload = { row: rowToModify, col: columnToModify, value };
  return { type: types.UPDATE_ROSTER_ITEMS, payload };
}

export function setAdvancedColorDefaults(loadedDefs, customConfig) {

}

export function addRow(key, value, keys) {
  if (!keys) [key, keys] = key;
  value = value ? value : null;
  const payloadArray = keys.map((el) => el === key ? { [key]: value } : { [el]: null });

  let firstPayload;
  payloadArray.forEach((el) => {
    const tempKey = Object.keys(el)[0];
    firstPayload = { ...firstPayload, [tempKey]: el[tempKey] }
  });

  return { type: types.ADD_ROSTER_ITEM, payload: firstPayload };
}

export function addMultipleRows(rowObject, quantity) { //WARNING possible memory leak here with high quantity numbers see quantityModal.js line 105 and 33
  let payload = [];
  let i;
  for (i = 0; i < quantity; i++) {
    payload.push(rowObject);
  }

  return { type: types.ADD_ROSTER_ITEMS, payload };
}

export function removeMultipleRows(sizeToModify, oldCount, newCount) {
  return { type: types.REMOVE_ROSTER_ITEMS, payload: { sizeToModify, oldCount, newCount } };
}

export function removeRow(args) {
  const [index] = args;
  return { type: types.REMOVE_ROSTER_ITEM, payload: index };
}

export function updateQuantity(oldQuantities, code, name, input) {

  let quantities = _.clone(oldQuantities)
  let size = _.find(quantities, { 'code': code }) //roster data, only shows when no name number
  if (size) {
    size = {
      code: code,
      size: size.name,
      quantity: (size.quantity < input) ? size.quantity++ : size.quantity--
    }
  } else {
    quantities.push({
      code: code,
      size: name,
      quantity: input
    })
  }

  return { type: types.UPDATE_QUANTITY, payload: quantities };
}

export function deleteAll() {
  return { type: types.DELETE_ROSTER, payload: null };
}

export function editSummaryNotes(text) {
  return { type: types.UPDATE_NOTES, text };
}

export function updateName(name) {
  return { type: types.UPDATE_NAME, name };
}

export function updateId(id) {
  return { type: types.UPDATE_ID, id }
}

export function loadNewConfig(data) {
  return { type: types.LOAD_CONFIG, data };
}

export function changeFabric(fabricCode) {
  return { type: types.CHANGE_FABRIC, fabric: fabricCode };
}