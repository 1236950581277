import stateProvider from './stateProvider';
import helpers from '../helpers';
import _ from 'lodash';


class CustomConfigHelper {

  // state helpers

  getStateVal(key, defaultValue = null) {
    return stateProvider.get(key, defaultValue);
  }

  // get the selected fabric.  or default baseProduct fabric if no fabric is selected.
  // if the prduct does not support user selected fabrics, then return null
  // returns the fabric key string

  getSelectedFabricCode() {
    let retval = null;
    if (helpers.product.supportsCustomFabrics()) {
      retval = this.getStateVal('customConfig.fabric');
    }
    return retval;
  }

  getSelectedFabricDef() {
    let fabricCode = this.getSelectedFabricCode();
    let fabricDefs = this.getStateVal('loadedDefs.fabrics');
    return _.find(fabricDefs, { code: fabricCode }) || null;
  }


  // TODO: refactor custom config specific helpers into this class

}

// export a static singleton instance
const customConfigHelper = new CustomConfigHelper();
export default customConfigHelper;


