
import React, { Component } from 'react';
import { connect } from 'react-redux'
import Dropzone from 'react-dropzone';
import _ from 'lodash';
import UploadCloudSVG from '../../../assets/icons/UploadCloudSVG';
import PublishSVG from '../../../assets/icons/PublishSVG';
import { showToast } from '../../../redux/reducers/toast/toastActions';
import { imageUploadModal } from '../../../redux/reducers/modal/modalActions';
import { formatRoster } from '../../../redux/reducers/customConfig/customConfigActions';
import helpers from '../../../helpers';
import api from '../../../api';
import Papa from 'papaparse';
import CloudUploadIconSVG from '../../../assets/icons/CloudUploadIconSVG';

class FileUploaderRoster extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isMouseOver: false,
    };

    this.accept = this.accept.bind(this);
  }

  handleMouseEnter = () => {
    this.setState({ isMouseOver: true })
  }

  handleMouseLeave = () => {
    this.setState({ isMouseOver: false })
  }

  getImage = (e) => {
    e.preventDefault();
    this.props.upload(e)
    e.target.value = null;
  }

  handleImageUpload = (e) => {
    let tmp = _.first(e);
    if (!_.isEmpty(tmp)) {
      this.props.imageUploadModal(tmp);
    }
  }

  handleRosterUpload(e) {
    const file = _.get(e, "[0]");
    const acceptableSize = helpers.roster.validateSize(file);
    let allowedProductSizes = helpers.product.getAllowedProductSizes();
    //if file is acceptable size try formatting, dispatch action or error message according to parse results
    //else if file size isnt acceptable dispatch error message
    Papa.parse(file, {
      delimiter: ",",
      complete: async (results) => {
        const isACsv = _.get(file, "path", "").includes("csv");
        if (!isACsv) {
          results = await api.rosterUploader.translateExcelToCsv(file);
          for (var i in results[0]) {
            if (i === "Quantity") {
              helpers.roster.parseQuantityRoster(results, allowedProductSizes, this.getData);
            } else if (i === "number") {
              helpers.roster.parseRoster(results, allowedProductSizes, this.getData);
            }
          }
        } else {
          if (e && e.length > 0 && acceptableSize) {
            if (_.isEmpty(allowedProductSizes) || _.isEmpty(e)) {
              return false
            };
            _.each(results.data[0], header => {
              if (header === "Quantity") {
                helpers.roster.parseQuantityRoster(results, allowedProductSizes, this.getData);
              } else if (header === "Number") {
                helpers.roster.parseRoster(results, allowedProductSizes, this.getData);
              }
            })
          } else if (!acceptableSize) {
            this.props.showToast("Try a smaller size.");
          }
        }
      }
    }
    )
  }

  getData = (values) => {
    if (values && !_.isEmpty(values)) {
      this.props.formatRoster(values);
    } else {
      this.props.showToast("Formatting issue. Use our roster template.");
    }
  }

  accept(file) {
    const size = _.get(file, "[0].size");
    let path = _.get(file, "[0].path");
    path = path.split(".");
    path = path[1];
    path = path.toLowerCase();

    if (_.get(this.props, "allowedFiles", [""]).includes(path) && size !== 0) {
      switch (this.props.fileUpload) {
        case "roster": this.handleRosterUpload(file); break;
        case "image": this.handleImageUpload(file); break;
        default: this.props.showToast("FileUpload not set in properties", 5000); break;
      }
    } else {
      this.props.showToast("Invalid File Type - Try Again", 5000);
    }
  }

  render() {
    let size = { height: "25px", width: "155px%" };
    let margin;
    if (this.props.useTiny) {
      size = { height: "35px", width: `${this.props.rosterSize ? this.props.rosterSize : "180px"}`, display: "flex", flexDirection: "row" };
      margin = "0px";
    }
    // let backgroundColor = this.state.isMouseOver? "#BFBCBC": "transparent";
    let backgroundColor = this.props.navigation.activeTab.tab == 'names-and-numbers' ?
      (this.state.isMouseOver ? "transparent" : "transparent")
      : "lightgrey";
    let nodragProp = this.props.navigation.activeTab.tab == 'names-and-numbers' ? 'false' : 'true';
    return (
      <Dropzone noDrag={nodragProp} onDrop={acceptedFiles => this.accept(acceptedFiles)} className="FileUploader">
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()} style={{ width: '140px', margin: 'auto' }}>
              <div aria-label={this.props.toolTip} data-balloon-pos="down" className="FileUploader" style={{ marginTop: margin }}>
                <div className="content-container button"
                  style={{ backgroundColor, position: "relative", ...size }}
                  onMouseEnter={this.handleMouseEnter}
                  onMouseLeave={this.handleMouseLeave}
                  onDragEnter={this.handleMouseEnter}
                  onDragLeave={this.handleMouseLeave}
                >

                  {this.props.navigation.activeTab.tab === 'names-and-numbers' ?
                    <div>
                      <div><PublishSVG />{this.props.message}</div>
                    </div>
                    :
                    <div className="artUpload">
                      <div>File Types: JPG, PNG, SVG,</div>
                      <div>EPS, AI, PDF</div>
                    </div>
                  }
                </div>
              </div>
              <input {...getInputProps()} />
            </div>
          </section>
        )}
      </Dropzone>
    );
  }
}

const reduxActions = {
  imageUploadModal,
  showToast,
  formatRoster
}

function mapStateToProps(state) {
  return {
    toast: state.toast,
    navigation: state.navigation
  }
}

export default connect(mapStateToProps, reduxActions)(FileUploaderRoster);