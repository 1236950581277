import { UPDATE_HOVER } from '../../actions/actionTypes'; 


export default function hoverReducer(state = [], action) {
  switch (action.type) {
    case UPDATE_HOVER: return action.hover;
    default:
      return state;
  }
}
