import { RestService } from '../lib';


export default class RosterUploaderService extends RestService {

  constructor(opts = null) {
    super(opts);
    this.serviceName = 'rosterUploader';
    this.routePath = 'builder/roster-uploader';
    // this.authEnabled = true;
  }

  async translateExcelToCsv(fileObj = {}) {
    let filename = fileObj.name;
    let filesize = fileObj.size;
    let contentType = fileObj.type || null;
    let formData = new FormData();
    formData.append('file', fileObj);
    formData.append('filename', filename);
    formData.append('filesize', filesize);
    formData.append('contentType', contentType);
    return await this.uploadFile(formData);
  }
}


