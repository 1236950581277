import stateProvider from './stateProvider';
import productHelper from './productHelper';
import _ from 'lodash';


class NavigationHelper {

  // state helpers

  getStateVal(key, defaultValue = null) {
    return stateProvider.get(key, defaultValue);
  }


  // navigation helpers

  getActiveViewKey() {
    return this.getStateVal('navigation.activeView');
  }

  getActiveViewDef() {
    let viewKey = this.getActiveViewKey();
    return productHelper.getViewDef(viewKey);
  }

  getActiveLocationCode() {
    return this.getStateVal('navigation.activeLocation');
  }

  getPendingLocationEntity() {
    return this.getStateVal('navigation.pendingLocation.entity');
  }

  getSafariCORS() {
    let vendor = _.get(navigator, 'vendor') || '';
    let ua = _.get(navigator, 'userAgent') || '';
    let isChrome = !!(window && window.chrome);
    let isSafari = !!(vendor.indexOf('Apple') >= 0) && (ua.indexOf('CriOS') === -1) && (ua.indexOf('FxiOS') === -1);
    let isDesktopSafari = !!((window && window.safari) || (isSafari && !isChrome));
    return isDesktopSafari;
  }

  makePendingLocation( entityType, value, view, locationCode, artAsset=null, bounds, groupName, displayOrder ){

    let entity = productHelper.makeDefaultEntity( entityType, value, view, locationCode, artAsset, bounds );
    let pendingLocation = {
      code: locationCode,
      view: view,
      groupName: groupName,
      displayOrder: displayOrder,
      entity: entity,
      saveEntity: false,
      editEntity: true
    };
    return pendingLocation;
  }
}

// export a static singleton instance
const navigationHelper = new NavigationHelper();
export default navigationHelper;


