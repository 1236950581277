import React from "react";

const LogoGarbIconSVG =(props)=>{
  const { className, height=32, width=32, fill="black", stroke = "black", strokeWidth = "0", onClick } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" className={className} height={height} width={width} fill={fill} stroke={stroke} strokeWidth={strokeWidth} onClick={onClick}>
      <g id="LogoGarbIcon">
        <g id="Group_645" transform="translate(0 .012)">
          <path id="Path_456" d="M38.1 70.2c-.7-1.7-1.1-3.5-1-5.4C37.2 54.5 49 47.1 49 47.1c-1.1-.1-2.3.1-3.4.5-1.3.3-7.2 2.5-18 15.4-.2.2-.9 1.1-1.1 1.3C12 78.9 2 80.8 1.9 80.9h49.9c-7.6-1.9-12-6.9-13.7-10.7"/>
          <path id="Path_457" d="M63.1 80.9c1.5-.5 6.8-2.2 10.4-5.7l-3.1 5.7h-7.3z"/>
          <path id="Path_458" d="M106.6 63.8C98.3 75.4 88.2 79.3 82 80.9h-3.3s7.8-16.5 7.9-17.5H67.2l-2.5 4.4h6.9c-.9 1.5-3.5 3.6-7.2 4.3-2.3.4-7.1.5-9.5-3.3-4.3-7 3.2-17.3 12.8-17.5 1.8 0 4.9.6 4.8 2.8l-2 3.6H88l4.2-10.3h33.9c-2.6.5-12.9 7-19.5 16.4"/>
        </g>
      </g>
    </svg>
  );
};

export default LogoGarbIconSVG;