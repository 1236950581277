import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';


// special var for noop function so that we can easily check if onChange or onHover handlers have been passed in
const _noop = () => {};


class BaseInputComponent extends Component {

  // NOTE: if you extend propTypes directly in child component class, you will get a react warning.  use _propTypes alias instead
  static propTypes = {
    value: PropTypes.any,
    label: PropTypes.string,
    hint: PropTypes.string,
    rightLabel: PropTypes.string,
    displayHint: PropTypes.bool,
    isSelected: PropTypes.bool,
    isDisabled: PropTypes.bool,
    watchProps: PropTypes.bool,
    onChange: PropTypes.func,
    onHover: PropTypes.func
  };

  static defaultProps = {
    value: null,
    label: null,
    hint: null,
    rightLabel: null,
    displayHint: false,
    isSelected: false,
    isDisabled: false,
    watchProps: false,    // this creates a 2-way binding and tells the component to update props.value for props changes after the initialization
    onChange: _noop,
    onHover: _noop
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    // if watchProps is enabled, then check if the props.value is different from the previously saved state.value
    // if props.value is different, then we want to update our internal state with the new value
    if (nextProps.watchProps && nextProps.value !== prevState.value) {
      // return new state fragment with the updated value from props
      return { value: nextProps.value };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.resolve = this.resolve.bind(this);
    this.setValue = this.setValue.bind(this);
    this.setHover = this.setHover.bind(this);
    this.clearHover = this.clearHover.bind(this);

    this.state = {
      value: this.props.value,
      hover: null
    };
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this.clearHover();
    this._isMounted = false;
  }

  isMounted() {
    return this._isMounted;
  }

  isDisabled() {
    return this.props.isDisabled;
  }

  setValue(value, forceUpdate = false) {
    if (!this._isMounted || (this.isDisabled() && !forceUpdate)) { return; }

    // only update the state value and trigger the change event if the value has changed
    if (this.state.value !== value || forceUpdate) {
      this.setState({ value });
      this.props.onChange(value);
    }
  }

  setHover(hover, forceUpdate = false) {
    if (!this._isMounted || this.isDisabled()) { return; }

    // only update the state value and trigger the change event if the value has changed
    if (this.state.hover !== hover || forceUpdate) {
      this.setState({ hover });
      this.props.onHover(hover);
    }
  }

  clearHover() {
    // clear the hover state by setting the hover value to null
    this.setHover(null);
  }

  hasChangeOrHoverListener() {
    return ((this.props.onChange && this.props.onChange !== _noop) ||
            (this.props.onHover && this.props.onHover !== _noop));
  }

  resolve(value) {
    if (_.isFunction(value)) {
      value = value(this);
    } else {
      if (value != null && value != 'Layer Colors') {
        value = value.replace(/[\(].+?[\)]/g,'');
        value = value.replace('Layer', '');
        value = value.trim();
      } else {
        value = value;
      }
    }
    return value;
  }

  render() {
    let customFooter = this.props.customFooter;
    return (
      <div className={"component__base" + (this.props.isDisabled ? ' is-disabled' : '' + this.props.marginTop)}>
        {this.props.label ?
          <div className="header-container">
            <div className="label">
              {this.resolve(this.props.label)}
            </div>
            <div className="label label--right">
              {this.resolve(this.props.rightLabel)}
            </div>
          </div>
          : null}
        <div className="child-component">
          {this.renderChildComponent()}
        </div>
        {(this.props.hint || this.props.displayHint) ?
          <div style={customFooter ? {maxWidth: "33px"} : null} className="footer-container">
            <span>{this.resolve(this.props.hint)}&nbsp;</span>
          </div>
          : null}
      </div>
    );
  }

  renderChildComponent() {
    // this render function should be implemented by the inheriting child class
    return (
      <div></div>
    );
  }
}

export default BaseInputComponent
