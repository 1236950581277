import { CHANGE_DECORATION_GRID, EDIT_DECORATION_TYPE, SET_DECORATION_SUBTAB, SET_ART_SUBTAB, SET_DECORATION_TYPE, SET_PREV_LOCATION_DETAILS, UPDATE_ART_NAME, UPDATE_ART_COLORS, PLAYER_NAME_NUMBER_UPDATED } from "../../actions/actionTypes";


export function EnableCanvasGrid() {
  let decoration = {
    canvasGrid: true,
  };
  return { type: CHANGE_DECORATION_GRID, decoration };
}

export function DisableCanvasGrid() {
  let decoration = {
    canvasGrid: false
  };
  return { type: CHANGE_DECORATION_GRID, decoration };
}

export function SetEditDecorationType(value) {
  let decoration = {
    editDecorationType: value
  };
  return { type: SET_DECORATION_TYPE, decoration };
}

export function SetDecorationType(value) {
  let decoration = {
    selectedDecType: value
  };
  return { type: EDIT_DECORATION_TYPE, decoration };
}

export function SetDecorationSubtab(value) {
  let decoration = {
    selectedSubTab: value
  };
  return { type: SET_DECORATION_SUBTAB, decoration };
}

export function SetCutomArtName(value) {
  let decoration = {
    customArtName: value
  };
  return { type: UPDATE_ART_NAME, decoration };
}

export function SetCustomArtColors(value) {
  let decoration = {
    customArtColors: value
  };
  return { type: UPDATE_ART_COLORS, decoration };
}

export function SetArtSubtab(value) {
  let decoration = {
    selectedArtTab: value
  };
  return { type: SET_ART_SUBTAB, decoration };
}

export function SetPrevLocationDetails(value) {
  let decoration = {
    prevLocation: value
  };
  return { type: SET_PREV_LOCATION_DETAILS, decoration };
}


export function setDecorationFlag(value) {
  let decoration = {
    playerNameNumber: value
  };
  return { type: PLAYER_NAME_NUMBER_UPDATED, decoration };
}