import React, {Component} from "react";
import {connect} from 'react-redux';

class GeneralError extends Component {

  render() {
    return (
      <div className="not-found-404 text-center fade-in">
        <h1 className="d-inline-block mx-auto">Error</h1>
        <br />
        <p className="d-inline-block">Hello?&hellip; We know it’s scary, but there was an issue with the application. Try again soon.</p>
        <div className="ghost-wrapper mx-auto">
          <div className="ghost">
            <svg xmlns="http://www.w3.org/2000/svg" width="128" viewBox="0 0 384 512"><path d="M192 0c-1.96 0-3.93.03-5.91.09C81.01 3.24 0 94.92 0 200.05v263.92C0 473.61 7.89 480 16.12 480c3.93 0 7.94-1.46 11.2-4.72l24.92-18.53c2.86-2.12 6.21-3.16 9.54-3.16 4.43 0 8.82 1.83 11.97 5.38l42.95 48.35c3.12 3.12 7.22 4.69 11.31 4.69s8.19-1.56 11.31-4.69l40.72-45.85c3.18-3.58 7.57-5.38 11.96-5.38s8.78 1.79 11.96 5.38l40.72 45.85c3.12 3.12 7.22 4.69 11.31 4.69s8.19-1.56 11.31-4.69l42.95-48.35a15.994 15.994 0 0 1 21.51-2.22l24.92 18.53c3.26 3.26 7.27 4.72 11.2 4.72 8.22 0 16.12-6.39 16.12-16.03V192C384 85.96 298.04 0 192 0zm144 407.07c-4.48-.98-9.09-1.48-13.77-1.48-18.28 0-35.72 7.83-47.86 21.5L256 447.77l-16.15-18.18c-12.13-13.66-29.58-21.5-47.85-21.5s-35.71 7.84-47.85 21.5L128 447.77l-18.38-20.69a64.069 64.069 0 0 0-47.86-21.49c-4.68 0-9.29.5-13.77 1.48V200.05c0-81.49 62.6-149.67 139.53-151.98L192 48c79.4 0 144 64.6 144 144v215.07zM128 160c-17.67 0-32 14.33-32 32s14.33 32 32 32 32-14.33 32-32-14.33-32-32-32zm128 0c-17.67 0-32 14.33-32 32s14.33 32 32 32 32-14.33 32-32-14.33-32-32-32z"/></svg>
          </div>
          <div className="ghost--shadow"></div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reduxState: state
  };
}

export default connect(mapStateToProps)(GeneralError);
