import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { setActiveTab, toggleMoblieTab } from '../../../redux/reducers/navigation/navigationActions';
import NavButton from '../../configurationPanel/configurationNavigation/ConfigurationNavigationButton';
import _ from "lodash";

class Navigation extends Component {

  rosterChange = false;

  constructor(props) {
    super(props)
    this.state = {
      toggle: false
    }
  }

  changeRosterPageName() {
    let hasPlayerNameNumber = false;
    this.props.customConfig.locations.forEach(item => {
      if (item.entity.type === 'player-name' || item.entity.type === 'player-number') {
        hasPlayerNameNumber = true;
      }
    })
    if (hasPlayerNameNumber) {
      this.props.customConfig.isPlayerRoster = true;
    } else {
      this.props.customConfig.isPlayerRoster = false;
    }
    this.rosterChange = this.props.customConfig.isPlayerRoster;
  }

  onClickNavigate = tab => () => {
    this.changeRosterPageName();
    let tabObj = { tab: tab, page: "main" }
    this.props.setActiveTab(tabObj);
    this.props.toggleMoblieTab(true);
  }

  render() {
    const { navigation } = this.props;
    this.changeRosterPageName();
    const accessibleNavBtns = navigation.options.filter(option => option.isAvailable)
    return (
      <nav className={"c-nav"}>
        <div className={"c-nav__container fancy-scrollbar"}>
          {
            accessibleNavBtns.map((option, i) => {
              let toolTip = _.find(this.props.toolTips, { key: option.key }).message;
              let displayName;
              if (option.key === 'names-and-numbers') {
                displayName = this.rosterChange ? 'Complete Roster' : 'Size'
              }
              else {
                displayName = option.displayName
              }
              if ((this.props.configType === 'custom-sub') || this.props.configType === 'stock-dec') {
                return (
                  <NavButton
                    toolTip={toolTip}
                    key={i}
                    isActive={navigation.activeTab.tab === option.key}
                    selectorKey={option.key}
                    onClick={this.onClickNavigate}
                    displayName={displayName} />
                )
              }
            }
            )
          }
        </div>
      </nav>
    )
  }
}

function mapStateToProps(state) {
  return {
    navigation: state.navigation,
    toolTips: state.toolTips,
    configType: state.baseProduct.configurationType,
    customConfig: state.customConfig,
    decoration: state.decoration
  };
}

const outputActions = {
  setActiveTab,
  toggleMoblieTab
}

export default connect(mapStateToProps, outputActions)(Navigation);