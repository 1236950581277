import {
  CLEAR_NOTIFICATION,
  SEND_NOTIFICATION,
  SEND_IFRAME_MESSAGE,
  SEND_ADD_TO_CART_MESSAGE, 
  SET_SCRIM
} from "../../actions/actionTypes";

const initialState = {
  action: {},  
  iframe: '', 
  scrim: false
}; 

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case SEND_NOTIFICATION: return Object.assign({}, state, { action: action.notification });
    case CLEAR_NOTIFICATION: return Object.assign({},state,{ action: action.notification });
    case SEND_IFRAME_MESSAGE: return Object.assign({}, state, { iframe: action.message });
    case SEND_ADD_TO_CART_MESSAGE: return Object.assign({}, state, { addToCart: action.message });
    case SET_SCRIM: return Object.assign({}, state, {scrim: action.payload}); 
    default:
      return state;
  }
}

