import React, { Component } from "react";
import { connect } from 'react-redux';
import { initLaunchContext } from "../../../../../src/redux/reducers/launchContext/launchContextActions.js";
import { closeModal } from "../../../../redux/reducers/modal/modalActions";

class MockupWarningModal extends Component {
    render() {
        return (
            <div className="info-modal-container">
                <div className="row">
                    <div className="col-12 pt-3">
                        <div className="info-1 text-warning">
                            {
                                (this.props.contentType === 'mockup') ? <b>Please save the design, before downloading the mockup.</b> :
                                    <b>Please click on refresh button, after a minute or 2, since there are some updates happen in the mockup.</b>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapDispatchToProps = {
    initLaunchContext,
    closeModal,
};

function mapStateToProps(state) {
    return {
        reduxState: state,
        baseProduct: state.baseProduct,
        customConfig: state.customConfig,
        launchContext: state.launchContext,
        changeDesignColor: state.changeDesignColor
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MockupWarningModal);