import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import api from "../../../../../api";
import { changeSetting, deleteLocation } from '../../../../../redux/reducers/customConfig/customConfigActions';
import { setActiveTab, previewSample, } from '../../../../../redux/reducers/navigation/navigationActions';
import { deleteModal } from '../../../../../redux/reducers/modal/modalActions';
import { addPendingLocationToLocations } from '../../../../../redux/reducers/customConfig/customConfigActions';
import { SetDecorationSubtab, SetDecorationType, setDecorationFlag } from "../../../../../redux/reducers/decoration/decorationAction"
import { updateUserArtAssetData } from "../../../../../redux/reducers/user/userActions";

function EditTextBottomButtons(props) {

  let locationContent = _.find(props.customConfig.locations, { code: props.navigation.activeLocation });
  let bpLocation = _.find(props.baseProduct.locations, { code: locationContent.code });
  let bpAllowedEntity = _.find(bpLocation.allowedEntities, { key: locationContent.entity.type });
  let editDectype = false;

  const handleBack = (e) => {
    if (locationContent.entity.type === "player-name" || locationContent.entity.type === "player-number") {
      let value = true;
      props.setDecorationFlag(value);
    }
    if (props.decoration.customArtName !== "" || props.decoration.customArtColors !== "") {
      let assets = _.cloneDeep(props.user.userArtAssets);
      let artAsset = _.find(assets, { url: locationContent.entity.url });
      let artName = props.decoration.customArtName !== "" ? props.decoration.customArtName : artAsset['name'];
      artAsset['name'] = artName
      let customArtColors = props.decoration.customArtColors !== "" ? props.decoration.customArtColors : artAsset['numDecorationColors'];
      artAsset['numDecorationColors'] = customArtColors;
      api.webUserArtAssets.updateNameAndNumberOfColors(artAsset.id, artName, customArtColors).then(artAsset, () => { });
      props.updateUserArtAssetData(assets);
    }

    if (props.decoration.editDecorationType !== "") {
      editDectype = true;
    }
    props.decoration.editDecorationType = "";
    props.customConfig.locations.forEach(item => {
      if (editDectype) {
        props.changeSetting(props.customConfig.locations, item.code, { setting: "editEntity", value: true });
      }
      if (editDectype) {
        props.changeSetting(props.customConfig.locations, item.code, { setting: "editEntity", value: true });
      }
      props.changeSetting(props.customConfig.locations, item.code, { setting: "saveEntity", value: true });
    })

    let tabObj = { tab: props.navigation.activeTab.tab, page: "main" };
    props.setActiveTab(tabObj);

  }

  let handleNewEntityBounds = (newBounds) => {
    // only update redux if I am the main view / aka not a thumbnail.
    let updatedLocations = props.customConfig.locations.filter(v => v.code != newBounds.code)
    if (newBounds && props.baseProduct.configurationType == "custom-sub") {
      let existingCode = updatedLocations.map(v => { return { code: v.code, groupName: v.groupName, order: v.displayOrder } });
      let isInit = newBounds.entity ? newBounds.entity.hasOwnProperty("_init") ? newBounds.entity._init : false : false;


      existingCode.sort(function (a, b) {
        var keyA = a.order,
          keyB = b.order;

        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
      _.forEach(existingCode, (previousCode) => {
        let previousLocationCode = props.customConfig.locationBounds;
        let sourceLocation = props.baseProduct.locations.find(v => v.code == previousLocationCode);
        if (props.baseProduct.configurationType === 'custom-sub') {
          if ((newBounds.displayOrder < previousCode.order && previousCode.code != 'LC' && previousCode.code != 'RC' && previousCode.groupName == newBounds.groupName) || ((previousCode.code == 'LC' || previousCode.code == 'RC') && !isInit && newBounds.displayOrder < previousCode.order && previousCode.groupName == newBounds.groupName)) {
            let existingOrdersArray = existingCode.filter(v => v.order != previousCode.order && v.groupName == previousCode.groupName).map(v => v.order)
            let lastGreater = existingOrdersArray.length > 0 ? Math.max(...existingOrdersArray) : previousCode.order;

            existingOrdersArray = existingOrdersArray.filter(v => v < previousCode.order);
            existingOrdersArray = existingOrdersArray.sort((a, b) => a - b);
            const closestFirst = existingOrdersArray.length > 0 ? existingOrdersArray.reduce((a, b) => {
              return Math.abs(b - previousCode.order) < Math.abs(a - previousCode.order) ? b : a;
            }) : previousCode.order;

            let considerOrder = closestFirst ? closestFirst : newBounds.displayOrder;
            if (newBounds.displayOrder < closestFirst) {
              considerOrder = closestFirst;
            }
            let boundsValue = props.customConfig.locations.find(v => v.displayOrder == previousCode.order);
            let currentBounds = _.cloneDeep(newBounds.entity.bounds);
            if (considerOrder != newBounds.displayOrder) {
              let boundsToConsider = props.customConfig.locations.find(v => v.displayOrder == considerOrder);
              currentBounds = _.cloneDeep(boundsToConsider.entity.bounds);
            }
            //Updated bounds are the bounds which will be updated after below conditions
            let updatedBounds = _.cloneDeep(boundsValue.entity.bounds);
            let viewImageDetails = _.find(props.baseProduct.views, { key: boundsValue.view });
            // zzz 600 is hard coded should be image size that we get from api works for now because request size is hard coded. relation: navigationActions.js
            let scale = 600 / viewImageDetails.masterImageSize.h;
            let newTop = 0;
            //To check the below display orders from current bounds in order to change bound values as per new bound orders
            if (props.customConfig.locations.find(v => v.displayOrder == lastGreater)) {
              // newTop = (currentBounds.h * scale )+ currentBounds.y +75;

              if (closestFirst == previousCode.order) {
                let rootLocation = _.find(props.customConfig.locations, { code: previousCode.code })
                let considerLocation = _.find(props.baseProduct.locations, { code: previousCode.code });
                let baseLocationSize = _.find(considerLocation.allowedEntities, { key: rootLocation.entity.type })
                let baseProductScale = 600 / viewImageDetails.masterImageSize.h;
                if (considerLocation.anchorOriginCode === 'CC') {
                  // zzz 600 is hard coded should be image size that we get from api works for now because request size is hard coded. relation: navigationActions.js
                  newTop = considerLocation.bounds.y * baseProductScale + considerLocation.bounds.h * baseProductScale / 2;
                } else if (considerLocation.anchorOriginCode === 'NC' || considerLocation.anchorOriginCode === 'SC') {
                  if (rootLocation.entity.size !== baseLocationSize.defaultSize) {
                    props.changeSetting(props.customConfig.locations, previousCode.code, { setting: "_rootSizeUpdated", value: true });
                  } else {
                    props.changeSetting(props.customConfig.locations, previousCode.code, { setting: "_deleteUpdated", value: true });
                  }
                }
              } else if (boundsValue.entity.type == 'player-number' && boundsValue.entity.size == '8in') {
                newTop = currentBounds.y + (currentBounds.h * scale) + 90;
              } else {
                newTop = currentBounds.y + (currentBounds.h * scale) + 75;
              }
              updatedBounds.y = newTop;
              updatedBounds.x = updatedBounds.x;
              props.changeSetting(props.customConfig.locations, previousCode.code, { setting: "bounds", value: updatedBounds });
              props.changeSetting(props.customConfig.locations, previousCode.code, { setting: "_heightUpdated", value: false });
              props.changeSetting(props.customConfig.locations, previousCode.code, { setting: "_widthUpdated", value: false });
              props.changeSetting(props.customConfig.locations, previousCode.code, { setting: "_init", value: false });
            }
          }
          // } else if ((sourceLocation && sourceLocation.displayOrder < previousCode.order && sourceLocation.groupName == previousCode.groupName && newBounds.locationCode != 'LC' && newBounds.locationCode != 'RC' && previousCode.groupName != newBounds.groupName)) {
          //   console.log('test99', previousCode.groupName, newBounds, newBounds.groupName)
          //   let existingOrdersArray = existingCode.filter(v => v.order != previousCode.order && v.groupName == previousCode.groupName).map(v => v.order)
          //   let lastGreater = Math.max(...existingOrdersArray);

          //   existingOrdersArray = existingOrdersArray.filter(v => v < previousCode.order);
          //   existingOrdersArray = existingOrdersArray.sort((a, b) => a - b);
          //   const closestFirst = existingOrdersArray.length > 0 ? existingOrdersArray.reduce((a, b) => {
          //     return Math.abs(b - previousCode.order) < Math.abs(a - previousCode.order) ? b : a;
          //   }) : previousCode.order;

          //   let considerOrder = closestFirst ? closestFirst : sourceLocation.displayOrder;
          //   if (sourceLocation.displayOrder < closestFirst) {
          //     considerOrder = closestFirst;
          //   }
          //   let boundsValue = props.customConfig.locations.find(v => v.displayOrder == previousCode.order);
          //   let considerBounds = props.customConfig.locations.find(v => v.displayOrder == considerOrder);
          //   let currentBounds = _.cloneDeep(considerBounds.entity.bounds);

          //   //Updated bounds are the bounds which will be updated after below conditions
          //   let updatedBounds = _.cloneDeep(boundsValue.entity.bounds);
          //   let viewImageDetails = _.find(props.baseProduct.views, { key: boundsValue.view });
          //   // zzz 600 is hard coded should be image size that we get from api works for now because request size is hard coded. relation: navigationActions.js
          //   let scale = 600 / viewImageDetails.masterImageSize.h;
          //   let newTop = 0;
          //   //To check the below display orders from current bounds in order to change bound values as per new bound orders
          //   if (props.customConfig.locations.find(v => v.displayOrder == lastGreater)) {
          //     newTop = currentBounds.y + (currentBounds.h * scale) + 65;
          //     updatedBounds.y = newTop;
          //     updatedBounds.x = updatedBounds.x;
          //     props.changeSetting(props.customConfig.locations, previousCode.code, { setting: "bounds", value: updatedBounds });
          //     props.changeSetting(props.customConfig.locations, previousCode.code, { setting: "_heightUpdated", value: false });
          //     props.changeSetting(props.customConfig.locations, previousCode.code, { setting: "_widthUpdated", value: false });
          //     props.changeSetting(props.customConfig.locations, previousCode.code, { setting: "_init", value: false });
          //   } else if (existingOrdersArray.length == 0) {
          //     let considerBounds = props.customConfig.locations.find(v => v.code == previousCode.code);
          //     let currentEntity = _.cloneDeep(considerBounds.entity);
          //     let considerLocation = _.find(props.baseProduct.locations, { code: previousCode.code });
          //     let baseProductScale = 600 / viewImageDetails.masterImageSize.h;

          //     // zzz 600 is hard coded should be image size that we get from api works for now because request size is hard coded. relation: navigationActions.js
          //     let newTop = considerLocation.bounds.y * baseProductScale + considerLocation.bounds.h * baseProductScale / 2;
          //     updatedBounds.y = newTop;
          //     updatedBounds.x = currentEntity.bounds.x;
          //     props.changeSetting(props.customConfig.locations, previousCode.code, { setting: "bounds", value: updatedBounds });
          //     props.changeSetting(props.customConfig.locations, previousCode.code, { setting: "_heightUpdated", value: false });
          //     props.changeSetting(props.customConfig.locations, previousCode.code, { setting: "_widthUpdated", value: false });
          //     props.changeSetting(props.customConfig.locations, previousCode.code, { setting: "_init", value: false });

          //   }


          // }
        }
      })


    }

  };
  // const handleDelete = () => {
  //   let locations = props.customConfig.locations;
  // let location = _.findIndex(locations, { code: props.navigation.activeLocation });
  //   handleNewEntityBounds(locations[location]);
  //   props.deleteLocation(location, locations);
  //   handleBack();
  // }

  const onCLickCancel = () => {
    if (props.decoration.editDecorationType !== "") {
      editDectype = true;
    }
    if (editDectype) {
      let Alllocations = props.customConfig.locations;
      let prevLoc = props.decoration.prevLocation;
      Alllocations = _.filter(Alllocations, loc =>
        // loc.saveEntity === true || (this.props.decoration.editDecorationType !== "" && loc.editEntity === true)
        loc.saveEntity === true
      );
      props.customConfig.locations = Alllocations;
      if (prevLoc !== "") {
        let Locations = props.customConfig.locations;
        let UpdatedLocations = [];
        _.map(Locations, loc => {
          if (loc.editEntity === false) {
            prevLoc.editEntity = true;
            UpdatedLocations.push(prevLoc);
          }
          else {
            UpdatedLocations.push(loc);
          }
        })
        props.customConfig.locations = UpdatedLocations;
      }

      let tabObj = { tab: props.navigation.activeTab.tab, page: "main" };
      props.setActiveTab(tabObj);
    }
    else {
      let tabObj = { tab: props.navigation.activeTab.tab, page: "main" };
      props.setActiveTab(tabObj);
    }

    props.SetDecorationSubtab("");
    props.SetDecorationType("");
  }

  let type = bpAllowedEntity.name

  return (
    <div className="EditTextBottomButtons" >
      <div className="row">
        <div className="col-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 pr-0">
          <button
            className="btn custom-cancel-button"
            // onClick={() => props.deleteModal(() => handleDelete(), type)}
            onClick={onCLickCancel}
          >
            CANCEL
          </button>
        </div>
        <div className="col-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12 padding-bottom-buttons">
          <button
            className="btn custom-save-button"
            style={{ minWidth: "80px" }}
            onClick={handleBack}>
            SAVE CHANGES
          </button>
        </div>
      </div>
    </div>
  )

}

const reduxActions = {
  changeSetting,
  setActiveTab,
  previewSample,
  addPendingLocationToLocations,
  deleteModal,
  deleteLocation,
  SetDecorationSubtab,
  SetDecorationType,
  updateUserArtAssetData,
  setDecorationFlag
}

function mapStateToProps(state) {
  return {
    navigation: state.navigation,
    baseProduct: state.baseProduct,
    customConfig: state.customConfig,
    decoration: state.decoration,
    user: state.user
  }
}

export default connect(mapStateToProps, reduxActions)(EditTextBottomButtons);