import _ from 'lodash';
import stateProvider from "./stateProvider";
import helpers from "../helpers"
import appConstants from "../constants/appConstants";

function arr_diff (a1, a2) {
  var a = [], diff = [];
  for (var j = 0; j < a1.length; j++) {
    a[a1[j]] = true;
  }
  for (var i = 0; i < a2.length; i++) {
    if (a[a2[i]]) {
      delete a[a2[i]];
    } else {
      a[a2[i]] = true;
    }
  }
  for (var k in a) {
    diff.push(k);
  }

  return diff;
}


class ProductHelper {

  // state helpers

  getStateVal(key, defaultValue = null) {
    return stateProvider.get(key, defaultValue);
  }

  // tests

  unitTests() {
    return {
    };
  }

  getAllowedProductSizes() {
    return this.getStateVal('baseProduct.sizes');
  }

  // product configuration type helpers

  getConfigurationType() {
    return this.getStateVal('baseProduct.configurationType', appConstants.ProductConfigurationTypes.Default);
  }

  isStockDec() {
    return (this.getConfigurationType() === appConstants.ProductConfigurationTypes.StockDecorated);
  }

  isCustomSub() {
    return (this.getConfigurationType() === appConstants.ProductConfigurationTypes.CustomSublimated);
  }

  isFullCustom() {
    return (this.getConfigurationType() === appConstants.ProductConfigurationTypes.FullCustom);
  }

  isCustomAny() {
    return (this.isCustomSub() || this.isFullCustom())
  }

  supportsCustomFabrics() {
    let fabrics = this.getStateVal('baseProduct.fabrics');
    return !_.isEmpty(fabrics);
  }

  supportsCustomPatterns() {
    let retval = false;
    if (this.isCustomAny()) {
      let patterns = this.getStateVal('baseProduct.customLayers.allowedLayerPatternPalettes');
      let numPatterns = this.getStateVal('baseProduct.customLayers.numLayerPatterns');
      if (!_.isEmpty(patterns) && numPatterns > 0) {
        retval = true;
      }
    }
    return retval;
  }

  // product style helpers

  getViewDef(viewKey) {
    return _.find(this.getStateVal('baseProduct.views'), { key: viewKey }) || null;
  }

  // original helpers

  getLocationDetails(baseProduct, locationCode) {
    let location = _.find(baseProduct.locations, { code: locationCode});
    return location;
  }

  getProductLocationInformation(baseProductLocations, locationKey, string) {

    let answer;
    let artLocations = [];

    if (string === "custom-art" || string === "art") {

      _.each(baseProductLocations, e => {
        _.each(e.allowedEntities, f => {
          if (f.type === "custom-art" || string === "art") {
            artLocations.push(e)
          }
        })
      });

      answer = _.find(artLocations, {code: locationKey});
    } else {
      answer = _.find(baseProductLocations, {code: locationKey});
    }

    return answer;
  }

  getCustomLocationInformation(customLocations, locationKey) {
    return _.find(customLocations, {code: locationKey});
  }

  getOpenLocations(baseProductLocations, customConfigLocations) {

    let productLocations = _.map(baseProductLocations, 'code');
    let customLocations = _.map(customConfigLocations, 'code');

    return arr_diff(productLocations, customLocations);
  }

  getOptions(key,  baseProductLocations) {
    let loc = _.find(baseProductLocations, {code: key});
    let entities = loc.allowedEntities.map((i) => {return i.type});
    return entities;
  }

  getUpgrade(upgradeKey) {
    let upgrades = this.getStateVal('baseProduct.allowedUpgrades');
    return _.find(upgrades, { key: upgradeKey }) || null;
  }


  // TODO: remove createDefaultArtEntity use makeDefaultEntity
  createDefaultArtEntity(baseProductlocation, locationKey, type, artAsset, scale) {

    let entity = _.find(baseProductlocation.allowedEntities, { type });
    if (!entity) {
      let tmpCode = _.get(baseProductlocation, 'code', null);
      console.error('this art entity type is not allowed for this location', '  locationCode: ' + tmpCode + ', entityType: ' + type);
      return {};
    }

    let temp = {type};

    if (entity.defaultDecorationMethod) { temp['decorationMethod'] = entity.defaultDecorationMethod; }
    if (entity.defaultDecorationMethod){
      let allowedDecorationMethod = _.find(entity.allowedDecorationMethods, {key:entity.defaultDecorationMethod});
      if(allowedDecorationMethod.defaultSize) temp['size'] = allowedDecorationMethod.defaultSize;
    }

    temp['_heightUpdated'] = false;
    temp['_widthUpdated'] = false;
    temp['_init'] = true;
    temp['bounds'] = {
      x: 0,
      y: 0,
      w: 0,
      h: 0,
      r: 0
    };
    temp['url'] = artAsset.url;
    temp['userArtId'] = artAsset.id;
    temp['numDecorationColors'] = artAsset.numDecorationColors;

    if (entity.capabilities) {
      if (entity.capabilities.fillColors) {
        temp['capabilities'] = {fillColors: entity.capabilities.fillColors.map(i => {
            return {"key": i.key, "value": null};
        })};
      }
    }
    return temp;
  }

  makeDefaultEntity( entityType, entityValue, view, locationCode, artAsset=null, newBounds ){
    let baseProduct = this.getStateVal('baseProduct');
    let bpLocationDetails = _.find(baseProduct.locations, { code: locationCode } );
    let allowedEntity = _.find( bpLocationDetails.allowedEntities, { key: entityType } );
    let allowedDecorationMethod = _.find( allowedEntity.allowedDecorationMethods, {key : allowedEntity.defaultDecorationMethod});

    // key values nulls
    let bounds = { h:0, w:0, x:0, y:0, r:0 }
    let capabilities = null;
    let decorationMethod = null;
    let font = null;
    let size = null;
    let textEffect = null;
    let type = null;
    let url = null;
    let value = null;
    let _heightUpdated = false;
    let _init = true;
    let _widthUpdated = false;
    let flipArt = false;
    let rotateArt = 0;
    // get values for keys
    bounds = { h:0, w:0, x:0, y:0, r:0 }
    capabilities = {
      fillColors: _.map( allowedEntity.capabilities.fillColors, (x)=>{ return {key:x.key, value:x.defaultColor }})
    };

    decorationMethod = allowedEntity.defaultDecorationMethod;
    font = allowedDecorationMethod.defaultFont;
    size = allowedDecorationMethod.defaultSize;
    textEffect = allowedDecorationMethod.defaultTextEffect;
    type = entityType;
    value = entityValue;

    // assign key values to keys.
    let entity = {};
    entity.bounds = newBounds ? newBounds : bounds;
    entity.bounds.y = newBounds ? newBounds.y : bounds.y
    entity.capabilities = capabilities;
    entity.decorationMethod = decorationMethod;
    entity.font = font;
    entity.size = size;
    entity.textEffect = textEffect;
    entity.type = type;
    entity.url = url;
    entity.value = value;
    entity._heightUpdated = _heightUpdated;
    entity._init = newBounds ? false :_init;
    entity._widthUpdated = _widthUpdated;
    entity.flipArt=flipArt;
    entity.rotateArt=rotateArt;
    if ( artAsset ){
      entity.userArtId = artAsset.id;
      entity.numDecorationColors = artAsset.numDecorationColors;
      url = artAsset.url;
    } else {
      url = helpers.urlRender.returnProductRenderUrl(entity);
    }

    entity.url = url;

    return entity;
  }

  // TODO: remove createDefaultEntity use makeDefaultEntity
  createDefaultEntity(baseProductlocation, type, scale, text) {
    let entity = _.find(baseProductlocation && baseProductlocation.allowedEntities, { type });

    if (!entity) {
      entity =   helpers.locations.getAnyLocationInfoForEntityType(type);
    }

    if (!_.isFinite(scale) || scale <= 0) {
      console.error('bad scale value in createDefaultArtEntity');
      scale = 1;
    }


    // TODO: $DATA_CLEANUP$ Why are we converting the defaultSize key into a height value?  Use the data!
    let height = entity.defaultSize;
    if (height && height.includes("in")) {
      height = height.replace("in", "");
    }

    // let width = null;

    // TODO: $DATA_CLEANUP$ Why are we converting the defaultSize key?  Use the data!
    if (entity.defaultSize && entity.defaultSize.includes("-") && entity.defaultSize !== "free-size") {
      let allowedDecorationMethod = _.find(entity.allowedDecorationMethods, {key:entity.defaultDecorationMethod});
      let defaultSizeKey = allowedDecorationMethod.defaultSize;
      let defaultSize = _.find(allowedDecorationMethod.allowedSizes, {"key": defaultSizeKey});
      height = defaultSize.h;
      // width = defaultSize.w;
    } else if (entity.defaultSize === "free-size") {
      height = 1;
      // width = 1;
    }
    let temp = _.cloneDeep({type});
    temp['value'] = text;
    if (entity.defaultDecorationMethod) temp['decorationMethod'] = entity.defaultDecorationMethod;
    if (entity.defaultDecorationMethod){
      let allowedDecorationMethod = _.find(entity.allowedDecorationMethods, {key:entity.defaultDecorationMethod});
      if(allowedDecorationMethod.defaultFont) temp['font'] = allowedDecorationMethod.defaultFont;
      if(allowedDecorationMethod.defaultSize) temp['size'] = allowedDecorationMethod.defaultSize;
      if(allowedDecorationMethod.defaultTextEffect) temp['textEffect'] = allowedDecorationMethod.defaultTextEffect;
    }

    temp['_heightUpdated'] = false;
    temp['_widthUpdated'] = false;
    temp['_init'] = true;

    temp['bounds'] = {
      x: 0,
      y: 0,
      w: 0,
      h: 0,
      r: 0
    };
    if (entity.capabilities) {
      if (entity.capabilities.fillColors) {
        temp['capabilities'] = {fillColors: entity.capabilities.fillColors.map(i => {
            let color = (type === "custom-art") ? null : i.defaultColor;
            return {"key": i.key, "value": color};
        })};
      }
    }
    if (type === "custom-art") {
      temp["url"] = "art";
      temp["userArtId"] = "7";
    } else {
      temp["url"] = helpers.urlRender.returnProductRenderUrl(temp)
    }
    return temp;
  }

  renderDisplayName(data, key, string) {
    let name = "";
    _.each(data, e => {
      if (e[string] === key) {
        name = e.name;
      }
    });
    return {key, name}
  }

  renderLocationOptions(data, locations) {
    let options = [];
    _.each(locations, e => {
      let location = _.find(data, {"code": e.key});
      let view = location.view;
      options.push({name: e.name, key: e.key, view: view});
    });
    return options
  }

  formatCentsToPrice(cents) {

    let plusOrMinus = "";

    if (_.toString(cents).includes("-")) {
      plusOrMinus = "-"
      cents = _.toString(cents).replace("-", "")
      cents = Number(cents)
    } else {
      plusOrMinus = "+"
    }

    let retval = null;
    if (_.isFinite(cents)) {
      retval = plusOrMinus + "$" + (cents / 100).toFixed(2);
    }
    return retval;
   }

   getCustomTextMaxCharLength(baseProductlocation){
     let max = 50; // default text size
     let custTextEntity = _.find(_.get(baseProductlocation, "allowedEntities"), {type: "custom-text"});
     if(custTextEntity){
       max = custTextEntity.maxCharLength || max;
     }
     return max;
   }
  ////Put the above renderDisplayName into the createDefaultEntity function

  getDefaultSize(){
    let defaultSize = this.getStateVal('baseProduct.defaultSize');
    if(defaultSize){
      return defaultSize;
    }
    return (this.getStateVal('baseProduct.sizes[0]') || "");
  }

  // New method to get the List of Styles based on a given Style
  getDefaultStyles(style){
    // console.log("Style:", style);
    let stylesList = [];
    if (style != null && style != '') {
      if (style.includes('J5JV1A'))
        stylesList.push("J5JV1A", "J5JV1Y", "J5JV1W", "J5JV1G");
      else if (style.includes('J5JV1Y'))
        stylesList.push("J5JV1Y", "J5JV1A", "J5JV1W", "J5JV1G");
      else if (style.includes('J5JV1W'))
        stylesList.push("J5JV1W", "J5JV1A", "J5JV1Y", "J5JV1G");
      else if (style.includes('J5JV1G'))
        stylesList.push("J5JV1G", "J5JV1A", "J5JV1W", "J5JV1Y");
      else if (style.includes('J5JC1A'))
        stylesList.push("J5JC1A", "J5JC1Y", "J5JC1W");
      else if (style.includes('J5JC1Y'))
        stylesList.push("J5JC1Y", "J5JC1A", "J5JC1W");
      else if (style.includes('J5JC1W'))
        stylesList.push("J5JC1W", "J5JC1A", "J5JC1Y");
      else
        stylesList.push("J5JV1G", "J5JV1A", "J5JV1Y", "J5JV1W");
    } else {
      stylesList.push("J5JV1G", "J5JV1A", "J5JV1Y", "J5JV1W");
    }
    return stylesList
  }

  // New method to get the LegacyStyle based on a given Style
  getDefaultLegacyStyle(style){
      let legacyStyle = '';
      if (style != null && style != '') {
          if (style.includes('AS0224'))
              legacyStyle = "J5JC1A";
          else if (style.includes('AS0225'))
              legacyStyle = "J5JC1W";
          else if (style.includes('AS0226'))
              legacyStyle = "J5JC1Y";
          else if (style.includes('AS1105'))
              legacyStyle = "J5JV1A";
          else if (style.includes('AS1106'))
              legacyStyle = "J5JV1W";
          else if (style.includes('AS1107'))
              legacyStyle = "J5JV1Y";
          else if (style.includes('AS1982'))
              legacyStyle = "J5JV1G";
      }
      return legacyStyle;
  }

  formatTypeDisplayOrder(listOfTypes){

    let mySort = (a, b) => {
      let valA = 0; 
      let valB = 0; 
      let aKey = _.get(a, "key", " "); 
      let bKey = _.get(b, "key", " "); 
      if(aKey === "custom-text"){
        valA=3; 
      } 
      if(aKey === "player-name"){
        valA=2; 
      } 
      if(aKey === "player-number"){
        valA=1; 
      } 
      
      if(bKey === "custom-text"){
        valB=3; 
      } 
      if(bKey === "player-name"){
        valB=2; 
      } 
      if(bKey === "player-number"){
        valB=1; 
      } 
      
      return (valB - valA); 
    }

    let orderedList = _.cloneDeep(listOfTypes);
    orderedList.sort(mySort); 
    return orderedList; 
  }


}

// export a static singleton instance
const productHelper = new ProductHelper();
export default productHelper;


