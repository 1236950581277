import {SHOW_TOOL_TIP} from '../../actions/actionTypes';
import toolTipsData from "./toolTips";

export default function toolTips(state = toolTipsData, action) {
  switch (action.type) {
    case SHOW_TOOL_TIP: 
      return Object.assign( {},  state,  {message: action.payload}); 
    default:
      return state;
  }
}
