export const testEsFeatures = () => { //export import
  // http://es6-features.org/#Constants //not necessarily authoritative, just an easy to use site
 
   let otherValue = false; //block scoping and let
   let fn = () => { //constants and arrow fns
     return otherValue;
   };
   let x = `${fn()} text`; //str interpolation
   let z = [1, 2, 3];
   let y = [x, ...z]; //spread
   let [val] = y; //destructuring
   let mapDS = new Map(); //maps
   let obj = {other: "rrr"};
   let otherObj = {val: "str"}
   let newObj = Object.assign({}, obj, otherObj); //obj assign w spread
   let otherNewObj = {...obj, ...otherObj};
   y.find(el => el === 1); //find
   "hello".includes("ello"); //includes (es7)
 
   function msgAfterTimeout (msg, who, timeout) {
     return new Promise((resolve, reject) => { //promise syntax
         setTimeout(() => resolve(`${msg} Hello ${who}!`), timeout)
     })
   };
   msgAfterTimeout("", "Foo", 100).then((msg) => msgAfterTimeout(msg, "Bar", 200));
 
   return {val, mapDS, newObj, otherNewObj};
 };

