import {HIDE_TOAST, SHOW_TOAST} from '../../actions/actionTypes';

const initialState = {
  isActive: false,
  timeDelay: 0,
  message: ''
};

export default function toastReducer(state = initialState, action) {
  switch (action.type) {
    case HIDE_TOAST: 
      return Object.assign({}, state,  { isActive: false}); 
    case SHOW_TOAST: 
      return Object.assign( {},  state,  {message: action.payload, isActive:true}); 
    default:
      return state;
  }
}
