import React, { Component } from 'react'; 
import _ from 'lodash'; 

export default class UserArtImage extends Component {
  render() {
    let alt = _.get(this.props, "alt", "art")
    let url = _.get(this.props, "url", ""); 
    return (
    <div className="userart__img">
      <img src={url} alt={alt} crossOrigin="anonymous" />
  </div>); 
  }
}
