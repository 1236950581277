import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import MultiDropdown from '../../../../global/multiDropdown/multiDropdown';
import { addHoverObject, clearHover } from "../../../../../redux/reducers/hover/hoverActions";
import { changeSetting } from "../../../../../redux/reducers/customConfig/customConfigActions";
import helpers from '../../../../../helpers';
import { FaLock } from "react-icons/fa";

function EditTextFont(props) {

  let locationContent = _.find(props.customConfig.locations, { code: props.navigation.activeLocation });
  let bpLocation = _.find(props.baseProduct.locations, { code: locationContent.code });
  let bpAllowedEntity = _.find(bpLocation.allowedEntities, { key: locationContent.entity.type });
  // bpDecorationMethod has all allowed fonts for this location and entity type.
  let bpDecorationMethod = _.find(bpAllowedEntity.allowedDecorationMethods, { key: locationContent.entity.decorationMethod });

  const handleOnChange = (e) => {
    let font = e.key // update redux location entity font
    props.changeSetting(props.customConfig.locations, props.navigation.activeLocation, { setting: "font", value: font });

    let url; // update redux location entity url
    let locationContentClone = _.cloneDeep(locationContent);
    locationContentClone.entity.font = e.key;
    url = helpers.urlRender.returnProductRenderUrl(locationContentClone.entity);
    props.changeSetting(props.customConfig.locations, props.navigation.activeLocation, { setting: "url", value: url });

    props.clearHover();
  }
  const handleOnHover = (e) => {
    let locationContentClone = _.cloneDeep(locationContent);
    locationContentClone.entity.font = e.key;

    let key = "url";
    let value = helpers.urlRender.returnProductRenderUrl(locationContentClone.entity);
    props.addHoverObject({ [key]: value });
  }
  const handleOnHoverOff = () => {
    props.clearHover();
  }

  let label = "Font";
  let value = helpers.loadedDefs.getFontName(locationContent.entity.font);

  let options = _.map(bpDecorationMethod.allowedFonts, (x) => {
    return helpers.loadedDefs.getFontDef(x);
  });

  for (let i = 0; i < props.customConfig.locations.length; i++) {
    if (props.customConfig.locations[i].entity.type === 'player-number' && props.customConfig.locations[i].entity.decorationMethod === 'screenprint-duratek') {
      if (options.length > 1) {
        options.splice(1, 1);
      }
    }
  }

  let rightLabel = null;
  let menuHeader = null;

  if (options.length > 1) {
    return (
      <div className="EditTextFont" style={{ marginTop: "15px", position: "relative" }}>
        <div className="row  pl-4">
          <div className="col-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 label-padding-top">
            <label className="decoration-label">{label.toUpperCase()}</label>
          </div>
          <div className="col-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12">
            <MultiDropdown
              customClass="multidropdown-menu"
              // label={label}
              rightLabel={rightLabel}
              menuHeader={menuHeader}
              value={value}
              options={options}
              onChange={handleOnChange}
              onHover={handleOnHover}
              onHoverOff={handleOnHoverOff}
              imageKey={"previewUrl"}
            />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="EditTextFont" style={{ marginTop: "15px", position: "relative" }}>
        <div className="row  pl-4">
          <div className="col-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 label-padding-top">
            <label className="decoration-label">{label.toUpperCase()}</label>
          </div>
          <div className="col-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12">
            <MultiDropdown
              customClass="multidropdown-menu"
            // label={label} 
            />
            <div className="input-group">
              <input value={value} className="text-height-number-input form-control border-right-0" disabled />
              <div className="input-group-append ">
                <span className="input-group-text border-left-0 append-border"><FaLock /></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const reduxActions = {
  changeSetting,
  addHoverObject,
  clearHover
}

function mapStateToProps(state) {
  return {
    navigation: state.navigation,
    baseProduct: state.baseProduct,
    customConfig: state.customConfig
  }
}

export default connect(mapStateToProps, reduxActions)(EditTextFont);