import React, { Component } from "react";
import { connect } from "react-redux";
import ColorPickerPattern from "../../../../global/colorSwatch/colorPickerPattern";
import { clearHover, patternHover } from "../../../../../redux/reducers/hover/hoverActions";
import { toggleDelete } from "../../../../../redux/reducers/navigation/navigationActions";
import { changeTextPatternColor, changePatternColor, changeSetting } from "../../../../../redux/reducers/customConfig/customConfigActions";
import helpers from "../../../../../helpers";
import _ from 'lodash';

class TextPatternColors extends Component {

  changePatternColor(customConfig, color, index, navigation) {
    this.props.changePatternColor(customConfig, color, index, navigation);
  }

  render() {
    let { customConfig, baseProduct, loadedDefs, navigation } = this.props;
    let numPatternColors = this.props.colorData;
    let locationContent = _.find(this.props.customConfig.locations, { code: this.props.navigation.activeLocation });
    let patternColors = locationContent.entity.capabilities.textPatternColors;
    let paletteDefs = _.get(loadedDefs, "colorPalettes");
    let tempPatternColors = []
    for (var f = 0; f < 5; f++) {
      for (var i = 0; i < patternColors.length; i++) {
        tempPatternColors.push(patternColors[i])
      }
    }
    let adjustedPatternColors = tempPatternColors.slice(0, numPatternColors);

    return (
      <div className="pattern-chroma-color-selector-container">
        {adjustedPatternColors.map((colorCode, index) => {
          let colorDef = helpers.colors.getColor(colorCode);
          let allowedColorPalettes = _.get(baseProduct, `customLayers.layers[0].allowedColorPalettes`);
          let allowedColors = [];
          _.each(allowedColorPalettes, paletteKey => {
            let paletteDef = _.find(paletteDefs, { key: paletteKey });
            let paletteColors = [];
            _.each(paletteDef.colors, colorCode => {
              paletteColors.push(helpers.colors.getColor(colorCode));
            })
            allowedColors.push({ name: paletteDef.name, colors: paletteColors });
          })
          let uniqueKey = `${colorCode + index}`;
          let toolTip = _.find(this.props.toolTips, { key: 'pattern-color' }).message;
          return (
            <div key={uniqueKey}>
              {adjustedPatternColors ?
                <div key={uniqueKey + "color picker"}>
                  <div className="row">
                    <div className="col-3">
                      <ColorPickerPattern
                        toggleDelete={this.props.toggleDelete}
                        value={colorDef}
                        chromaDrawer={true}
                        chromagear={true}
                        customFooter={true}
                        colorOptions={allowedColors}
                        watchProps={true}
                        dropdownHeader="Select a color"
                        toolTip={toolTip}
                        toolTipDirection={"up"}
                        type={colorCode}
                        // label={`Color ${index + 1}`}
                        // hint={colorDef.name}
                        displayHint={true}
                        onChange={e => this.changePatternColor(customConfig, index, e, navigation)}
                      />
                    </div>
                    <div className="col-8 pt-2">
                      <div className="row">
                        <div className="col-12 text-color-hint-heading">
                          {`Color ${index + 1}`.toUpperCase()}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 text-color-name-subheading">
                          {colorDef.name.toUpperCase()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                : null}
            </div>
          )
        })}
      </div>
    )
  }
}

const outputActions = {
  toggleDelete,
  changeSetting,
  changeTextPatternColor,
  changePatternColor,
  clearHover,
  patternHover
};

function mapStateToProps(state) {
  return {
    baseProduct: state.baseProduct,
    customConfig: state.customConfig,
    loadedDefs: state.loadedDefs,
    user: state.user,
    hover: state.hover,
    toolTips: state.toolTips,
    navigation: state.navigation,
  };
}

export default connect(mapStateToProps, outputActions)(TextPatternColors);
