import axios from 'axios';
import AppConfig from '../../config';

// TODO: joe- we need to update this service to extend the RestService and use the same URL endpoint.
// main API needs to be updated to support these service endpoints first.

/**
 * Service Endpoint Implementation
 */
export default class LogoService {

  constructor() {
    this.serviceName='logos';
    this.routePath = AppConfig.baseApiUrl;
  }

  getCategories(){
   return axios.get(this.routePath + "/logo-categories", null);
  }

  getAllItems(){
   return axios.get( this.routePath + "/custom-mascot-logos", null);
  }

}
