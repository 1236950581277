import React, { Component } from 'react'

export default class UserArtButtonGroup extends Component {
  render() {
    // let saveButtonClassName = (this.props.canSave) ? "btn btn-primary btn-next" : "btn btn-not-ready"; 
    return (<div className="edit-userart__btnGroup">    
              <button className="userart__close btn btn-outline-primary btn-cancel" onClick={this.props.close}>Close</button>      
              <button className={"btn btn-primary btn-next"} onClick={this.props.save}>Save</button>
            </div>); 
  }
}
