import React, { Component } from "react"
import { connect } from "react-redux";
import LogoBadgerIconSVG from "../../../assets/icons/BadgerIconSVG";

class TabHeader extends Component {
  brandLogo = require("../ultimateModal/sublimatedProductDetailsModal/CG-Logo_Horizontal_NoBG.png");

  headerStock() {
    return (
      <div className={this.props.navigation.activeTab.tab==="names-and-numbers"?"card border-0 w-100 pr-0 pl-0":"card w-100 pr-0 pl-0"}>
        <div className="row">
          <div className="col-3 pr-0 pt-2 pb-0">
            <div className="design-heading-main">{this.props.text}</div>
          </div>
          <div className="col-3 pl-0 stock-brand-logo">
            <LogoBadgerIconSVG></LogoBadgerIconSVG>
          </div>
        </div>
        <div className="row">
          <div className="heading-low col-12 pt-1">
            {this.props.name}
          </div>
        </div>
      </div>
    );
  }

  headerSub() {
    return (
      <div className={this.props.navigation.activeTab.tab==="names-and-numbers"?"card border-0 w-100 pr-0 pl-0":"card w-100 pr-0 pl-0"}>
        <div className="row">
          <div className=" col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-6 pr-0 pt-2 pb-0">
            <div className="design-heading-main">{this.props.text}</div>
          </div>
          <div className=" col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-6 pl-0">
            <img
              src={this.brandLogo}
              alt="brand logo"
              className="design-brand-image"
            />
          </div>
        </div>
        <div className="row">
          <div className="heading-low col-12 pt-1">
            {this.props.name}
          </div>
        </div>
      </div>
    );
  }

  render() {

    return (
      <div>
        {this.props.baseProduct.configurationType === 'custom-sub' ? this.headerSub() : this.headerStock()}
      </div>
    );
  }
}
const mapDispatchToProps = {
};

function mapStateToProps(state) {
  return {
    toolTips: state.toolTips,
    baseProduct: state.baseProduct,
    customConfig: state.customConfig,
    launchContext: state.launchContext,
    changeDesignColor: state.changeDesignColor,
    navigation: state.navigation
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TabHeader);
