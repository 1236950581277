import React from "react";

const SelectedDesignSVG =(props)=>{
  const { className, height, width, fill="black", stroke = "black", strokeWidth = "0", onClick, viewBox } = props;

  return (
      
    <svg xmlns="http://www.w3.org/2000/svg" className={className} height={height} width={width} viewBox={viewBox} preserveAspectRatio="none"><path xmlns="http://www.w3.org/2000/svg" d="M9.357,16.875,4.889,12.408,3.4,13.9l5.957,5.957L22.121,7.089,20.632,5.6Z" transform="translate(0.217 0.357)"/></svg>
  );
};

export default SelectedDesignSVG;