import _ from 'lodash';


// NOTE: these config values come from env specific config in AppConfig now
const _defaultConfig = {
  baseApiUrl: null,
  renderApiUrl: null,
  cprodRenderApiUrl: null,
  artRenderApiUrl: null,
  textRenderApiUrl: null,
  imagesRasterizerApiUrl: null
};

export default class ApiConfig {

  static getDefaultConfigKeys() {
    return _.keys(_defaultConfig);
  }

  constructor(opts = null) {
    let config = _.defaultsDeep({}, opts, _defaultConfig);
    _.extend(this, config);
  }
}
