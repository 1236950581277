import React, { Component } from 'react';
import TextPatternsDesigns from '../patterns/textPatternsDesigns';
import { connect } from 'react-redux';
import _ from 'lodash';
import api from "../../../../../api";
import helpers from "../../../../../helpers";
import { changeSetting } from "../../../../../redux/reducers/customConfig/customConfigActions";

class TextPatterns extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        }
    }

    componentDidMount() {
        this.getTextpatterns();
    }

    async getTextpatterns() {
        let resData = await api.textDesignPatterns.getTextDesignPatterns();
        this.setState({ data: resData.data });
    }

    onTextPatternChange = (data) => {
        let locationContent = _.find(this.props.customConfig.locations, { code: this.props.navigation.activeLocation });
        locationContent.entity.capabilities.textPatternFillId = data;
        this.defaultPatterns = data;
        this.props.changeSetting(this.props.customConfig.locations, this.props.navigation.activeLocation, { setting: "textPatternid", value: data.id });
        let locationContentClone = _.cloneDeep(locationContent);
        locationContentClone.entity.textPatternID = data.id;
        let url = helpers.urlRender.returnProductRenderUrl(locationContentClone.entity);
        this.props.changeSetting(this.props.customConfig.locations, this.props.navigation.activeLocation, { setting: "url", value: url });
        let tempArray = ["SI", "CH", "BK", "WH"];
        this.patternColorsLength = 0;
        if (locationContent.entity.capabilities.textPatternFillId) {
            this.patternColorsLength = locationContent.entity.capabilities.textPatternFillId.designImages.length;
        }
        locationContent.entity.capabilities.textPatternColors = tempArray.slice(0, this.patternColorsLength);
    }

    render() {
        const { customConfig, baseProduct, loadedDefs } = this.props
        let defaultColors = _.get(baseProduct, "customLayers.defaultColors");
        let locationContent = _.find(this.props.customConfig.locations, { code: this.props.navigation.activeLocation });
        let baseProductLayers = _.get(baseProduct, "customLayers.layers");
        let toolTip = _.find(this.props.toolTips, { key: "pattern-dropdown" }).message;
        return (
            <div>
                {this.state.data ?
                    <TextPatternsDesigns
                        toolTip={toolTip}
                        advancedColors={null}
                        defaultPatterns={this.defaultPatterns}
                        defaultColors={defaultColors}
                        baseProductLayers={baseProductLayers}
                        loadedDefPatterns={this.state.data}
                        loadedDefs={loadedDefs}
                        onChange={this.onTextPatternChange}
                        customConfig={customConfig}
                        location={locationContent}
                    />
                    : null}
            </div>
        )
    }
}

const reduxActions = {
    changeSetting,
}

function mapStateToProps(state) {
    return {
        navigation: state.navigation,
        customConfig: state.customConfig,
        baseProduct: state.baseProduct,
        loadedDefs: state.loadedDefs,
        toolTips: state.toolTips
    }
}
export default connect(mapStateToProps, reduxActions)(TextPatterns);