 const stuff = {
  id: 1, 
  name: "All", 
  key: "all",
  children: [{
    id: 11, 
    name: "Sports", 
    key: "sports",
    children: [{
      id: 111, 
      name: "Football", 
      key: "football",

    },{
      id: 112, 
      name: "Baseball", 
      key: "baseball",

    },{
      id: 113, 
      name: "Hockey", 
      key: "hockey",
      children: [{
        id: 1131,
        name: "Ice", 
        key: "ice"
      },{
        id: 1132,
        name: "Street", 
        key: "street"
      },{
        id: 1133,
        name: "Field", 
        key: "field"
      },{
        id: 1134,
        name: "Quidditch", 
        key: "quidditch"
      }]
    },{
      id: 114, 
      name: "Basketball", 
      key: "basketball",

    },{
      id: 115, 
      name: "Volleyball", 
      key: "volleyball",

    },{
      id: 116, 
      name: "Tennis", 
      key: "tennis",
    }]
  },{
    id: 12, 
    name: "Animals", 
    key: "animals",
    children :[{
      id: 121, 
      name: "Dogs", 
      key: "dogs",   
      children: [{
        id: 1311,
        name: "Bull Dogs",
        key: "bulldogs"
      },{
        id: 1312,
        name: "Wolfs",
        key: "wolfs"
      },{
        id: 1313,
        name: "Pitbulls", 
        key: "pittbulls"
      }]
    },{
      id: 122, 
      name: "Bears", 
      key: "bears",
    },{
      id: 123, 
      name: "Eagles", 
      key: "eagles",
    },{
      id: 124, 
      name: "Sheep", 
      key: "sheep",
    }]
  },{
    id: 13, 
    name: "Locations", 
    key: "locations",
    children :[{
      id: 131, 
      name: "State", 
      key: "state",
    },{
      id: 132, 
      name: "National", 
      key: "national",
    },{
      id: 133, 
      name: "Country", 
      key: "country",
    },{
      id: 134, 
      name: "Valley", 
      key: "valley",
    }]
  },{
    id: 14, 
    name: "Games", 
    key: "games",
    children :[{
      id: 141, 
      name: "Poker", 
      key: "poker",
    },{
      id: 142, 
      name: "Debate", 
      key: "debate",
    },{
      id: 143, 
      name: "Monoply", 
      key: "monoply",
    },{
      id: 144, 
      name: "Chess", 
      key: "chess",
    }]
  }]
}; 

export default stuff