export default [
    {
      "name": "Plastisol Screen Print",
      "type": "decoration-method",
      "key": "screenprint-plastisol",
      "message": "Industry Standard",
    },
    {
      "name": "Silicone Screen Print",
      "type": "decoration-method",
      "key": "screenprint-silicone",
      "message": "Best In Class"
    },
    {
      "name": "Duratek Screen Print",
      "type": "decoration-method",
      "key": "screenprint-duratek",
      "message": "Screenprint Transfer"
    },
    {
      "name": "Custom Sublimation",
      "type": "decoration-method",
      "key": "sublimation",
      "message": "Custom Sublimation Printing",
    },
    {
      "name": "Style Tab",
      "type": "navigation-sidebar",
      "key":  "style",
      "message": "Change the style of the garment",
    },
    {
      "name": "Colors Tab",
      "type": "navigation-sidebar",
      "key":  "colors",
      "message": "Change the color of the garment",
    },
    {
      "name": "Fabric Tab",
      "type": "navigation-sidebar",
      "key":  "fabric",
      "message": "Change the fabric type of the garment",
    },
    {
      "name": "Add Text Tab",
      "type": "navigation-sidebar",
      "key":  "decorations",
      "message": "Add a player's name, number or some custom text",
    },
    {
      "name": "Add Art Tab",
      "type": "navigation-sidebar",
      "key":  "system-art",
      "message": "Add some artwork to the customization",
    },
    {
      "name": "Add Designs Tab",
      "type": "navigation-sidebar",
      "key":  "add-design",
      "message": "Add some design to the customization",
    },
    {
      "name": "Add Art Tab",
      "type": "navigation-sidebar",
      "key":  "up-art",
      "message": "Upload your own images",
    },
    {
      "name": "Add Text Back Button",
      "type": "add-text-drawer",
      "key":  "add-text-back-button",
      "message": "Go back",
    },
    {
      "name": "Player Roster Tab",
      "type": "navigation-sidebar",
      "key":  "names-and-numbers",
      "message": "Update / upload your team's roster",
    },
    {
      "name": "Summary Tab",
      "type": "navigation-sidebar",
      "key":  "order-summary",
      "message": "View your summary / grand total",
    },
    {
      "name": "Summary Tab",
      "type": "navigation-sidebar",
      "key":  "summary-table",
      "message": "Breakdown of your pricing information",
    },
    {
      "name": "Summary Tab",
      "type": "navigation-sidebar",
      "key":  "summary-notes",
      "message": "Please add any notes or questions you may have",
    },
    {
      "name": "Custom Text Location Summary",
      "type": "decorations",
      "key":  "custom-text-location-summary",
      "message": "Open and edit this custom text location",
    },
        {
      "name": "Player Name Location Summary",
      "type": "decorations",
      "key":  "player-name-location-summary",
      "message": "Open and edit this player name location",
    },
        {
      "name": "Player Number Location Summary",
      "type": "decorations",
      "key":  "player-number-location-summary",
      "message": "Open and edit this player number location",
    },
        {
      "name": "Custom Art Location Summary",
      "type": "decorations",
      "key":  "custom-art-location-summary",
      "message": "Open and edit this custom art location",
    },
    {
      "name": "Custom Text Toggle Button",
      "type": "custom-text-toggle-button",
      "key":  "custom-text-toggle-button",
      "message": "Select Custom Text",
    },
    {
      "name": "Player Name Toggle Button",
      "type": "text-toggle-button",
      "key":  "player-name-toggle-button",
      "message": "Select Player Name",
    },
    {
      "name": "Player Number Toggle Button",
      "type": "text-toggle-button",
      "key":  "player-number-toggle-button",
      "message": "Select Player Number",
    },
    {
      "name": "Custom Text Input",
      "type": "add-text-drawer",
      "key":  "custom-text-input",
      "message": "Enter your custom text",
    },
    {
      "name": "Add New Text Button",
      "type": "add-text-drawer",
      "key":  "add-new-text-button",
      "message": "Add text to garment",
    },
    {
      "name": "Add New Art Button",
      "type": "add-art-drawer",
      "key":  "add-new-art-button",
      "message": "Add art to garment",
    },
    {
      "name": "Add Custom Text To Design Button",
      "type": "add-text-drawer",
      "key":  "add-custom-text-to-design-button",
      "message": "Add custom text to garment",
    },
        {
      "name": "Add Player Name To Design Button",
      "type": "add-text-drawer",
      "key":  "add-player-name-to-design-button",
      "message": "Add player name to garment",
    },
        {
      "name": "Add Player Number To Design Button",
      "type": "add-text-drawer",
      "key":  "add-player-number-to-design-button",
      "message": "Add player number to garment",
    },
    {
      "name": "Drag Custom Text To Design",
      "type": "add-text-drawer",
      "key":  "drag-custom-text-to-design",
      "message": "Drag custom text onto the garment",
    },
        {
      "name": "Drag Player Name To Design",
      "type": "add-text-drawer",
      "key":  "drag-player-name-to-design",
      "message": "Drag player name onto the garment",
    },
        {
      "name": "Drag Player Number To Design",
      "type": "add-text-drawer",
      "key":  "drag-player-number-to-design",
      "message": "Drag player number onto the garment",
    },
    {
      "name": "Drag Custom Art To Design",
      "type": "drag-custom-art-to-design",
      "key":  "drag-custom-art-to-design",
      "message": "Drag custom art onto the garment",
    },
    {
      "name": "Edit Text Back Button",
      "type": "edit-text-drawer",
      "key":  "edit-text-back-button",
      "message": "Return to main menu",
    },
    {
      "name": "Edit Text Location Dropdown",
      "type": "edit-text-drawer",
      "key":  "edit-text-location-dropdown",
      "message": "Change or replace the custom text's location",
    },
    {
      "name": "Edit Text Input",
      "type": "edit-text-drawer",
      "key":  "edit-text-input",
      "message": "Enter text to appear on the garment",
    },
    {
      "name": "Edit Text Font Dropdown",
      "type": "edit-text-drawer",
      "key":  "edit-text-font-dropdown",
      "message": "Choose a custom font",
    },
    {
      "name": "Edit Text Font Dropdown",
      "type": "edit-text-drawer",
      "key":  "edit-text-font-dropdown",
      "message": "Choose a custom font",
    },
    {
      "name": "Edit Text Font Dropdown",
      "type": "edit-text-drawer",
      "key":  "edit-text-font-dropdown",
      "message": "Choose a custom font",
    },
    {
      "name": "Edit Text Font Dropdown",
      "type": "edit-text-drawer",
      "key":  "edit-text-font-dropdown",
      "message": "Choose a custom font",
    },
    {
      "name": "Edit Text Fill Color Picker",
      "type": "edit-text-drawer",
      "key":  "edit-text-fill-color-picker",
      "message": "Change the text fill color",
    },
    {
      "name": "Edit Text Outline 1 Color Picker",
      "type": "edit-text-drawer",
      "key":  "edit-text-outline-1-color-picker",
      "message": "Change the text outline 1 color",
    },
        {
      "name": "Edit Text Outline 2 Color Picker",
      "type": "edit-text-drawer",
      "key":  "edit-text-outline-2-color-picker",
      "message": "Change the text outline 2 color",
    },
    {
      "name": "Edit Text Effect Dropdown",
      "type": "edit-text-drawer",
      "key":  "edit-text-effect-dropdown",
      "message": "Choose a custom text effect",
    },
    {
      "name": "Edit Text Width Input",
      "type": "edit-text-drawer",
      "key":  "edit-text-width-input",
      "message": "Change the text width",
    },
    {
      "name": "Edit Text Height Input",
      "type": "edit-text-drawer",
      "key":  "edit-text-height-input",
      "message": "Change the text height",
    },
    {
      "name": "Edit Text Size Input",
      "type": "edit-text-drawer",
      "key":  "edit-text-size-input",
      "message": "Change the text size",
    },
    {
      "name": "Edit Text Rotation Slider",
      "type": "edit-text-drawer",
      "key":  "edit-text-rotation-slider",
      "message": "Rotate the text angle",
    },
    {
      "name": "Edit Text Decoration Method Dropdown",
      "type": "edit-text-drawer",
      "key":  "edit-text-decoration-method-dropdown",
      "message": "Choose a decoration method",
    },
    {
      "name": "Edit Custom Text Delete Button",
      "type": "edit-text-drawer",
      "key":  "edit-custom-text-delete-button",
      "message": "Delete the custom text entity",
    },
        {
      "name": "Edit Player Name Delete Button",
      "type": "edit-text-drawer",
      "key":  "edit-player-name-delete-button",
      "message": "Delete the player name entity",
    },
        {
      "name": "Edit Player Number Delete Button",
      "type": "edit-text-drawer",
      "key":  "edit-player-number-delete-button",
      "message": "Delete the player number entity",
    },
    {
      "name": "Edit Text Done Button",
      "type": "edit-text-drawer",
      "key":  "edit-text-done-button",
      "message": "Finish editing and go back",
    },
    {
      "name": "Add Art Browse Button",
      "type": "add-art-drawer",
      "key":  "add-art-browse-button",
      "message": "Browse through the custom artwork gallery",
    },
    {
      "name": "Custom Art Gallery Card",
      "type": "add-art-drawer",
      "key":  "custom-art-gallery-card",
      "message": "Select custom art to add to garment",
    },
    {
      "name": "Add Art To Design Button",
      "type": "add-art-drawer",
      "key":  "add-art-to-design-button",
      "message": "Add selected artwork to garment",
    },
        {
      "name": "Add Art Back Button",
      "type": "add-art-drawer",
      "key":  "add-art-back-button",
      "message": "Go back",
    },
    {
      "name": "Edit Art Back Button",
      "type": "edit-art-drawer",
      "key":  "edit-art-back-button",
      "message": "Return to main menu",
    },
    {
      "name": "Edit Art Location Dropdown",
      "type": "edit-art-drawer",
      "key":  "edit-art-location-dropdown",
      "message": "Change or replace the custom art's location",
    },
    {
      "name": "Edit Art Width Input",
      "type": "edit-art-drawer",
      "key":  "edit-art-width-input",
      "message": "Change the custom art's width",
    },
    {
      "name": "Edit Art Height Input",
      "type": "edit-art-drawer",
      "key":  "edit-art-height-input",
      "message": "Change the custom art's height",
    },
    {
      "name": "Edit Art Rotation Slider",
      "type": "edit-art-drawer",
      "key":  "edit-art-rotation-slider",
      "message": "Rotate the custom art's angle",
    },
    {
      "name": "Edit Art Rotation Slider",
      "type": "edit-art-drawer",
      "key":  "edit-art-rotation-slider",
      "message": "Rotate the custom art's angle",
    },
    {
      "name": "Edit Art Decoration Method Dropdown",
      "type": "edit-art-drawer",
      "key":  "edit-art-decoration-method-dropdown",
      "message": "Choose a decoration method",
    },
    {
      "name": "Edit Art Delete Button",
      "type": "edit-art-drawer",
      "key":  "edit-custom-art-delete-button",
      "message": "Delete the custom art entity",
    },
    {
      "name": "Edit Art Done Button",
      "type": "edit-art-drawer",
      "key":  "edit-art-done-button",
      "message": "Finish editing and go back",
    },
    {
      "name": "Edit Roster Button",
      "type": "player-roster",
      "key":  "edit-roster-button",
      "message": "Edit your player roster",
    },
    {
      "name": "Confirm Roster Button",
      "type": "player-roster",
      "key":  "confirm-roster-button",
      "message": "Close edit mode",
    },
    {
      "name": "Finish Editing Roster Button",
      "type": "player-roster",
      "key":  "finish-editing-roster-button",
      "message": "Finish editing",
    },
    {
      "name": "Finish Editing Roster Button",
      "type": "player-roster",
      "key":  "finish-editing-roster-button",
      "message": "Finish editing",
    },
    {
      "name": "Upload Roster Button",
      "type": "player-roster",
      "key":  "upload-roster-button",
      "message": "Upload your own roster (.csv, .xlsx, .xls)",
    },
    {
      "name": "Upload Custom Art Button",
      "type": "add-art",
      "key":  "upload-custom-art-button",
      "message": "Upload custom art to the garment",
    },
    {
      "name": "Download Roster Template",
      "type": "player-roster",
      "key":  "download-roster-template",
      "message": "Download a sample roster",
    },
    {
      "name": "Summary Notes Input",
      "type": "summary",
      "key":  "summary-notes-input",
      "message": "Add a note for the dealer",
    },
    {
      "name": "Cancel Button",
      "type": "footer-nav",
      "key":  "cancel-button",
      "message": "Return to shopping",
    },
    {
      "name": "Save Button",
      "type": "footer-nav",
      "key":  "save-button",
      "message": "Save your custom garmet",
    },
    {
      "name": "Add To Cart",
      "type": "footer-nav",
      "key":  "add-to-cart-button",
      "message": "Add garment to cart and proceed to checkout",
    },
    {
      "name": "Back To Product Button",
      "type": "header-nav",
      "key":  "back-to-products-button",
      "message": "Return to shopping",
    },
    {
      "name": "View Card Front",
      "type": "view-card",
      "key":  "view-card-front",
      "message": "Switch to the front view",
    },
    {
      "name": "View Card Back",
      "type": "view-card",
      "key":  "view-card-back",
      "message": "Switch to the back view",
    },
    {
      "name": "View Card Left",
      "type": "view-card",
      "key":  "view-card-left",
      "message": "Switch to the left view",
    },
    {
      "name": "View Card Right",
      "type": "view-card",
      "key":  "view-card-right",
      "message": "Switch to the right view",
    },
    {
      "name": "Cancel Replace Location Button",
      "type": "replace-location",
      "key":  "cancel-replace",
      "message": "Cancel and return to editing",
    },
    {
      "name": "Swap Replace Location Button",
      "type": "replace-location",
      "key":  "swap-replace",
      "message": "Swap locations and begin editing",
    },
    {
      "name": "Replace Location Button",
      "type": "replace-location",
      "key":  "replace",
      "message": "Replace location and begin editing",
    },
    {
      "name": "Load Last Design Button",
      "type": "load-from-storage-modal",
      "key":  "load-last-design",
      "message": "Load your previous design & pick up where you left off",
    },
    {
      "name": "Load From Storage Cancel Button",
      "type": "load-from-storage-modal",
      "key":  "load-cancel-button",
      "message": "Start from stratch with a new design",
    },
    {
      "name": "Skip Step Button",
      "type": "missing-roster-modal",
      "key":  "skip-step",
      "message": "Upload your player roster later and add item to cart",
    },
    {
      "name": "Missing Roster Cancel Button",
      "type": "missing-roster-modal",
      "key":  "missing-roster-cancel",
      "message": "Return to editing your item",
    },
    {
      "name": "Missing Roster Next Button",
      "type": "missing-roster-modal",
      "key":  "missing-roster-next",
      "message": "Confirm your roster and add item to cart",
    },
    {
      "name": "Missing Roster Upload Button",
      "type": "missing-roster-modal",
      "key":  "missing-roster-upload",
      "message": "Update / upload your team's roster (.csv, .xlsx, .xls)",
    },
    {
      "name": "Custom Text Add To Design",
      "type": "decorations",
      "key":  "custom-text-add-to-design",
      "message": "Add custom text to design",
    },
    {
      "name": "Add Art Help Bubble",
      "type": "add-art",
      "key": "add-art-help-bubble",
      "message": "Acceptable image types include: .JPG .PNG .GIF .BMP .AI .PSD .EPS .PDF"
    },
    {
      "name": "Customer Service",
      "type": "customer-service",
      "key": "customer-service",
      "message": "Click to redirect to the customer service portal"
    },
    {
      "name": "Summary Breakdown",
      "type": "summary",
      "key":  "summary-breakdown",
      "message": "Click to view the summary price breakdown",
    },
    {
      "name": "Add A Note",
      "type": "summary",
      "key":  "add-a-note",
      "message": "Click to add any notes or questions you may have",
    },
    {
      "name": "Total Decoration Cost",
      "type": "summary",
      "key":  "total-decoration-cost",
      "message": "Total cost for all decorations",
    },
    {
      "name": "Player Roster Next Button",
      "type": "player-roster",
      "key":  "player-roster-next-button",
      "message": "Add customization to cart and return to shopping",
    },
    {
      "name": "Quantity Tool Tip",
      "type": "quantity-roster",
      "key":  "quantity-input",
      "message": "Enter in item quantity",
    },
    {
      "name": "Select Primary Color",
      "type": "color-tab",
      "key":  "select-primary-color",
      "message": "Select your primary color",
    },
    {
      "name": "Select Secondary Color",
      "type": "color-tab",
      "key":  "select-secondary-color",
      "message": "Select your secondary color",
    },
    {
      "name": "Select Terinary Color",
      "type": "color-tab",
      "key":  "select-terinary-color",
      "message": "Select your terinary color",
    },
    {
      "name": "Advanced Colors Button",
      "type": "color-tab",
      "key":  "advanced-colors-button",
      "message": "Select advanced colors",
    },
    {
      "name": "Primary Colors Button",
      "type": "color-tab",
      "key":  "primary-colors-button",
      "message": "Select primary colors",
    },
    {
      "name": "Select Color Default",
      "type": "color-tab",
      "key":  "select-color-default",
      "message": "Select color",
    },
    {
      "name": "Select Color Default",
      "type": "color-tab",
      "key":  "color-1",
      "message": "Select color",
    },
    {
      "name": "Pattern Dropdown",
      "type": "color-tab",
      "key":  "pattern-dropdown",
      "message": "Select a design pattern",
    },
    {
      "name": "Pattern Color",
      "type": "color-tab",
      "key":  "pattern-color",
      "message": "Select a pattern color",
    },
    {
      "name": "Primary Color Mode",
      "type": "color-tab",
      "key":  "primary-color-mode",
      "message": "Switch to Primary Color Mode",
    },
    {
      "name": "Advanced Color Mode",
      "type": "color-tab",
      "key":  "advanced-color-mode",
      "message": "Switch to Advanced Color Mode",
    },
    {
      "name": "Go Back Advanced Mode",
      "type": "color-tab",
      "key":  "go-back-advanced-mode",
      "message": "Return to select a layer",
    },
  ]
