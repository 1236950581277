import _ from 'lodash';
import BaseService from './base.service';


/**
 * Api Service with standard REST endpoints and logic for a standard API REST resource
 *
 * example usage
 * import { ProductModel } from '../models';
 * class ProductsService extends ApiRestService {
 *   constructor(opts) {
 *     super(opts);
 *     this._routePath = '/products';
 *     this._idParamName = 'id';
 *     this._modelClass = ProductModel;
 *   }
 * }
 */
export default class RestService extends BaseService {

    constructor(opts = null) {
        super(opts);
    }

    _getDefaultRequestOpts(opts = null) {
        if (this.authEnabled) {
            opts = _.defaults(opts || {}, opts, {auth: true});
        }
        return opts;
    }


    // returns a list of resource items.  list may be empty.
    // query and find parameters can be passed to the API.
    async find(queryParams = null, opts = null) {
        let path = this.routePath;
        opts = this._getDefaultRequestOpts(opts);
        return this.request.get(path, queryParams, opts);
    }

    // returns a list of resource items.  list may be empty.
    // search is different than find and requires the FTS (full text search) feature to be enabled at the table
    // level on the DB table resource and the exposed through the API service.
    async search(searchText, queryParams = null, opts = null) {
        searchText = _.trim(searchText);
        if (_.isEmpty(searchText)) {
            return [];
        }

        let path = [this.routePath, 'search', searchText];
        opts = this._getDefaultRequestOpts(opts);
        return this.request.get(path, queryParams, opts);
    }

    // returns a single resource item
    async get(id, queryParams = null, opts = null) {
        if (!id) {
            return this.request.reject('invalid id');
        }
        let path = [this.routePath, id];
        opts = this._getDefaultRequestOpts(opts);
        return this.request.get(path, queryParams, opts);
    }

    // calls create if id is falsy, calls update if id exists
    async save(id = null, postParams = null, queryParams = null, opts = null) {
        if (!!id) {
            return this.update(id, postParams, queryParams, opts);
        } else {
            return this.create(postParams, queryParams, opts);
        }
    }

    // creates a new DB resource items. a new id will be created by the API.
    async create(postParams = null, queryParams = null, opts = null) {
        let path = this.routePath;
        opts = this._getDefaultRequestOpts(opts);
        return this.request.post(path, postParams, queryParams, opts);
    }

    // update will replace all DB resource fields.
    // If fields are missing from the update request, they may get deleted from the DB resource.
    async update(id, postParams = null, queryParams = null, opts = null) {
        if (!id) {
            return this.request.reject('invalid id');
        }
        let path = [this.routePath, id];
        opts = this._getDefaultRequestOpts(opts);
        return this.request.put(path, postParams, queryParams, opts);
    }

    // patch is similar to update, but it will attempt to merge with existing DB resource data instead of fully replacing.
    // If fields are missing from the patch request, they should not get deleted from the DB resource.
    async patch(id, postParams = null, queryParams = null, opts = null) {
        if (!id) {
            return this.request.reject('invalid id');
        }
        let path = [this.routePath, id];
        opts = this._getDefaultRequestOpts(opts);
        return this.request.patch(path, postParams, queryParams, opts);
    }

    // upload a single file.
    // the default behavior is to create a new resource.
    // Depending on service endpoint, this may be create a new resource or simply update an existing resource.
    // formData may include additional resource fields in addition to the file data content.
    async uploadFile(formData = null, queryParams = null, opts = null) {
        let path = this.routePath;
        opts = this._getDefaultRequestOpts(opts);
        return this.request.postFile(path, formData, queryParams, opts);
    }

    async convertVectorFile(formData = null) {
        const path = '/rasterize';
        return this.request.postFileToConvert(path, formData);
    }

    // mark a resource item as deleted.
    // Depending on the service endpoing, this may mark an item as deleted or purge the item.
    async remove(id, queryParams = null, opts = null) {
        if (!id) {
            return this.request.reject('invalid id');
        }
        let path = [this.routePath, id];
        opts = this._getDefaultRequestOpts(opts);
        return this.request.delete(path, queryParams, opts);
    }
}
