import React, { Component } from 'react';
import { connect } from 'react-redux';
import MultiDropdown from '../../../../global/multiDropdown/multiDropdown';
import { changeDesignColors } from "../../../../../redux/reducers/change/changeActions";
import TextColorSelector from '../../../tabs/editDrawer/editTextColorPicker/textColorSelector/textColorSelector';
import { toggleDelete } from '../../../../../redux/reducers/navigation/navigationActions';
import { changeChromagearLayerColor } from '../../../../../redux/reducers/customConfig/customConfigActions';
import helpers from "../../../../../helpers";
import { addHoverObject, clearHover, productColorHover, primaryColorsHover } from "../../../../../redux/reducers/hover/hoverActions";
import _ from 'lodash';

class ChromaColors extends Component{

  constructor(props) {
    super(props);
    this.state = {
      hoverColorName: null
    }
  }

  onChromagearGroupChanged = (color, type, index) => {
    this.props.changeDesignColors();
      this.props.changeChromagearLayerColor(this.props.customConfig, color, index,  type, this.props.baseProduct);      
  }

  handleMouseEnter(color, type, index) {
    if (color) {
      // in Advanced Mode, we update the layer color, NOT the primary color
      let currentPattern = _.get(this.props, "customConfig.customLayers.primaryPattern.key");
      let currentPatternColors = _.clone(_.get(this.props, "customConfig.customLayers.primaryPattern.colors"));//"SI"
  

      if (helpers.customLayers.isAdvancedMode()) {
              // in Advanced Mode, we update the layer color, NOT the primary color
              let currentLayers = _.get(this.props, "customConfig.customLayers.layers");
              if(currentPatternColors === null){
                let colors = []
                colors[0] = currentLayers[0].color
                this.props.customConfig.customLayers.primaryPattern.colors = colors
                currentPatternColors = _.clone(_.get(this.props, "customConfig.customLayers.primaryPattern.colors"));//"SI"
              }                
              _.each(currentPatternColors, (color, index) => {
                currentPatternColors[index] = helpers.colors.getColorKeyFromColorCode(color);
              })
              let newLayersObj = {[currentPattern]: currentPatternColors.join(",")};
              _.each(currentLayers, patternColor => {
                newLayersObj[patternColor.key] = helpers.colors.getColorKeyFromColorCode(patternColor.color);
              })
              newLayersObj[type] = color.key;
              this.props.primaryColorsHover(newLayersObj);
      } else {
        // in Basic Mode, we update the primary colors and do not include any opt params
        // _.each(currentPrimaryColors, (color, index) => {
        //   currentPrimaryColors[index] = helpers.colors.getColorKeyFromColorCode(color);
        // })
        // currentPrimaryColors[index] = color.key;
        // currentPrimaryColors = currentPrimaryColors.join(",");
        // this.props.primaryColorsHover(currentPrimaryColors);
      }



    } else {
      this.props.clearHover();
    }
  }

  getColorPalettes = () => {
    const { loadedDefs, baseProduct } = this.props;
    let colorPalettes = [];
    if (baseProduct.configurationType === "custom-sub") {
      _.each(baseProduct.customLayers.allowedColorPalettes, palette => {
        let paletteDef = _.find(loadedDefs.colorPalettes, {key: palette});
        let tempPalette = _.cloneDeep(paletteDef);
        let defColors = [];
        _.each(tempPalette.colors, (color) => {
            let defColor = _.find(loadedDefs.colors, {code: color});
            defColors.push(defColor);
          tempPalette.colors = defColors;
        })
        colorPalettes.push(tempPalette);
      })
    }
    return colorPalettes;
  }

  render(){
    const { loadedDefs, baseProduct, customConfig, toolTips } = this.props;
    let colorPalettes = this.getColorPalettes();
    let defaultColors;
    let chromaDrawer;
    if (helpers.customLayers.isBasicMode()) {
      chromaDrawer = false;
      defaultColors = _.get(baseProduct, "customLayers.defaultColors");
    } else if (helpers.customLayers.isAdvancedMode()) {
      chromaDrawer = true;
      let patternColors = [];
      let configLayers = _.get(customConfig, "customLayers.layers");
      _.each(configLayers, layer => {
        patternColors.push(layer.color);
      })
      defaultColors = patternColors;
    }

    return(

      <div className="ChromaColors">
        <MultiDropdown
          label='Layer Colors' />
          <div className="chroma-colors-container">
            <TextColorSelector
              entity={null}
              loadedDefs={loadedDefs.colors}
              chromagear={true}
              chromaDrawer={chromaDrawer}
              colorPalettes={colorPalettes}
              hoverColorName={this.state.hoverColorName}
              primary={true}
              defaultColors={defaultColors}
              allowedColors={ null /*allowedColors*/ }
              customConfig={customConfig}
              toggleDelete={toggleDelete}
              baseProduct={baseProduct}
              toolTips={toolTips}
              onColorHovered={(e, f, g) => this.handleMouseEnter(e, f, g)}
              changeColor={(color, type, index) => this.onChromagearGroupChanged(color, type, index)}
              completeLoadedDefs={loadedDefs}
            />
          </div>
      </div>
    )
  }
}

const reduxActions = {
  toggleDelete,
  changeChromagearLayerColor,
  productColorHover,
  addHoverObject,
  primaryColorsHover,
  clearHover,
  changeDesignColors
}

function mapStateToProps(state){
  return{
    customConfig: state.customConfig,
    baseProduct: state.baseProduct,
    loadedDefs: state.loadedDefs,
    toolTips: state.toolTips,
    Change: state.Change,
  }
}

export default connect(mapStateToProps, reduxActions )(ChromaColors);
