import React from 'react';
import { connect } from "react-redux";
import { closeModal } from "../../../redux/reducers/modal/modalActions";
import {
  updateQuantity,
  formatRoster,
  formatQuantityRoster,
  addRow,
  removeRow,
  addMultipleRows,
  removeMultipleRows,
  defaultLayerColors
} from "../../../redux/reducers/customConfig/customConfigActions";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import LoadFromStorage from './loadFromStorage/loadFromStorage';
import ReplaceArtwork from './replaceArtwork';
import SystemArtModal from './systemArtModal/SystemArtModal';
import BrowseArtGallery from './browseArtGallery';
import SaveModal from "./saveModal/saveModal";
import ChangeDesignModal from "./changeDesignModal/changeDesignModal";
import MockupWarningModal from "./mockupWarningModal/MockupWarningModal";
import ItemTotalModal from "./itemTotalModal/ItemTotalModal";
import LeadTimeModal from "./leadTimeModal/LeadTimeModal";
import EditUserArtDataModal from "./editUserArtDataModal/EditUserArtDataModal";
import ImageUploadModal from "./imageUploadModal/ImageUploadModal";
import MissingRosterModal from "./missingRoster/missingRoster";
import QuantityModal from "./quantityModal/quantityModal";
import WaitingForHost from "./waitingForHost/waitingForHost";
import ColorModeModal from "./colorModeModal/colorModeModal";
import ErrorModal from "./errorModal/errorModal";
import DeleteModal from "./deleteModal/deleteModal";
import SublimatedProductDetailsModal from './sublimatedProductDetailsModal/sublimatedProductDetailsModal'
import helpers from "../../../helpers";
import _ from "lodash"
import { sendAddToCartMessage } from "../../../redux/reducers/notifications/notificationsActions";
import InfoModal from './infoModal/infoModal'
import ShareModal from './shareModal/shareModal'
import DuplicateModal from './duplicateModal/duplicateModal'

class UltimateModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: true
    };

    this.toggle = this.toggle.bind(this);
    this.addToCart = this.addToCart.bind(this);
  }

  toggle() {
    this.props.closeModal(this.props.modal.locked);
  }


  addToCart() {
    this.props.closeModal(this.props.modal.locked);
    setTimeout(() => {
      this.props.sendAddToCartMessage();
    }, 10);
  }

  setCompType(toggle) {
    let allowedProductSizes = helpers.product.getAllowedProductSizes();
    let primaryRoster = helpers.roster.getPrimaryRoster();
    // console.log("Message is" ,this.props.modal.message);
    switch (this.props.modal.message) {
      case "ReplaceArtwork": return (<ReplaceArtwork />);
      case "BrowseArtGallery": return (<BrowseArtGallery toggle={toggle} />);
      case "LoadFromStorage": return (<LoadFromStorage toggle={toggle} />);
      case "SystemArtModal": return (<SystemArtModal toggle={toggle} />)
      case "SaveModal": return (<SaveModal />);
      case "EditUserArtDataModal": return (< EditUserArtDataModal item={this.props.modal.content} toggle={toggle} />);
      case "ImageUploadModal": return (<ImageUploadModal content={this.props.modal.content} />);
      case "SublimatedProductDetailsModal": return (<SublimatedProductDetailsModal designNum={this.props.modal.content} styleNum={this.props.modal.contentStyle} name={this.props.modal.contentName}  configId={this.props.modal.contentconfigID}/>);
      case "Waiting": return (<WaitingForHost />);
      case "InfoModal": return (<InfoModal toggle={toggle} />);
      case "ShareModal": return (<ShareModal toggle={toggle} />);
      case "DuplicateModal": return (<DuplicateModal toggle={toggle} />);
      case "ChangeDesign": return(<ChangeDesignModal design={ this.props.modal.design } isMaster={ this.props.modal.isMaster } configId = { this.props.modal.configID } tempLayers = { this.props.modal.tempLayers }/>);
      case "MockupWarning": return(<MockupWarningModal contentType={ this.props.modal.contentType }/>);
      case "ItemTotalModal": return(<ItemTotalModal contentType={ this.props.modal.contentType }/>);
      case "LeadTimeModal": return(<LeadTimeModal contentType={ this.props.modal.contentType }/>);
      case "MissingRoster": return (<MissingRosterModal
        customConfig={this.props.customConfig}
        format={this.props.formatRoster}
        toggle={toggle}
        next={this.addToCart}
        rosters={this.props.rosters}
        toolTips={this.props.toolTips}
        previews={this.props.modal.content}
        allowedProductSizes={allowedProductSizes}
      />);
      case "ColorMode": return (
        <ColorModeModal
          baseProduct={this.props.baseProduct}
          customConfig={this.props.customConfig}
          loadedDefs={this.props.loadedDefs}
          defaultLayerColors={defaultLayerColors}
          toggle={this.props.modal.toggle}
          boolean={this.props.modal.boolean}
          toolTips={this.props.toolTips}
          switchDrawer={this.props.modal.switchDrawer}
        />
      );
      case "Quantity": return (
        <QuantityModal
          toggle={toggle}
          format={this.props.formatQuantityRoster}
          next={this.addToCart}
          previews={this.props.modal.content}
          sampleRoster={primaryRoster}
          addRow={this.props.addRow}
          toolTips={this.props.toolTips}
          removeRow={this.props.removeRow}
          addMultipleRows={this.props.addMultipleRows}
          removeMultipleRows={this.props.removeMultipleRows}
          primaryRosterItems={_.get(helpers.roster.getPrimaryRoster(), "items")}
          sizeQuantities={helpers.roster.getSizeQuantitiesData()}
          allowedProductSizes={allowedProductSizes}
          loadedDefs={this.props.loadedDefs}
          showToast={this.props.showToast}
        />
      );
      case "Delete": return (<DeleteModal
        toggle={toggle}
        entityName={this.props.modal.entityName}
        delete={this.props.modal.delete} />)
      default:
        return (<ErrorModal modal={this.props.modal} />);
    } //get rid of errorModal as default as soon as we stop switching on the error message contents
  }

  buildQtyModalSizes = () => {
    const availableSizes = _.get(this.props.baseProduct, "sizes", []);
    const currentRoster = _.get(helpers.roster.getPrimaryRoster(), "items", false);
    const formattedAvailableSizes = availableSizes.map(name => {
      return { name, key: name.toUpperCase(), quantity: 0 };
    });

    //if we have a roster with current values (this fn gets called each time we update a category because of how this was originally was set up)
    if (currentRoster) {
      return formattedAvailableSizes.map(sizeCategory => {
        const sizeKey = _.get(sizeCategory, "key");

        if (sizeKey) {
          currentRoster.forEach(rosterRow => {
            if (sizeKey === rosterRow.size) {
              //look through sizes we are displaying and increment matching
              sizeCategory = { ...sizeCategory, quantity: sizeCategory.quantity + 1 };
            }
          });
        }

        return sizeCategory;
      });
    } else {
      return formattedAvailableSizes;
    }
  }

  showHeader() {
    if (this.props.modal.locked) {
      if (this.props.modal.message === "Waiting") {
        return (null);
      }
      return (<ModalHeader className={"locked h-title"}> {this.props.modal.title}</ModalHeader>);
    }
    return (<ModalHeader className="h-title" toggle={this.toggle}> {this.props.modal.title}</ModalHeader>);
  }

  openMoadal() {
    let { size } = this.props.modal;
    let className =  '';
    if ( this.props.modal.message === 'ItemTotalModal')
      className = 'item-total-modal';
    if ( this.props.modal.message === 'LeadTimeModal')
      className = 'leadtime-modal';
    return (
      <div>
        <Modal size={size} isOpen={this.props.modal.isActive} toggle={this.toggle} className={className}>
          {(this.props.modal.message !== 'ItemTotalModal' && this.props.modal.message !== 'LeadTimeModal') ? this.showHeader() : null}
          <ModalBody size="modal-xl">
            {this.setCompType(this.toggle)}
          </ModalBody>
        </Modal>
      </div>
    );
  }

  openInfoModal() {
    let { size } = this.props.modal;
    return (

      <div>
        <Modal size={size} isOpen={this.props.modal.isActive} toggle={this.toggle} className="modal-dialog-infomodal" >
          {this.showHeader()}
          <ModalBody size="modal-xl">
            {this.setCompType(this.toggle)}
          </ModalBody>
        </Modal>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.props.modal.message === 'InfoModal' ? this.openInfoModal() : this.openMoadal()}
      </div>
    );
  }
  // render() {
  //   let { size } = this.props.modal;
  //   return (<div>
  //     <Modal size={size} isOpen={this.props.modal.isActive} toggle={this.toggle}>
  //       {this.showHeader()}
  //       <ModalBody size="modal-xl">
  //         {this.setCompType(this.toggle)}
  //       </ModalBody>
  //     </Modal>
  //   </div>
  //   );
  // }
}

function mapStateToProps(state) {
  return {
    rosters: state.customConfig.rosters,
    customConfig: state.customConfig,
    modal: state.modal,
    baseProduct: state.baseProduct,
    loadedDefs: state.loadedDefs,
    toolTips: state.toolTips,
  }
}

const fns = { closeModal, updateQuantity, formatRoster, formatQuantityRoster, addRow, addMultipleRows, removeRow, removeMultipleRows, sendAddToCartMessage, defaultLayerColors };

export default connect(mapStateToProps, fns)(UltimateModal); 
