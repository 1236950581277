import {RestService} from '../lib';

/**
 * Sublimated Products Service
 */
export default class SublimatedProductsService extends RestService {

    constructor(opts = null) {
        super(opts);
        this.serviceName = 'sublimatedProducts';
        this.routePath = 'custom-products/designs';
    }

    async getDesignsByStyleNumber(styleNumber) {
        return this.get(styleNumber);
    }
}
