  
import React, { Component } from "react";
import _ from "lodash";
import helpers from "../../../../../helpers";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


export default class Patterns extends Component {

  constructor(props) {
    super(props);
    this.state = {
      chosenPattern: null,
    };
    this.buildPatternPreviewStyle = this.buildPatternPreviewStyle.bind(this);
  }

  buildPatternPreviewStyle(patternDef, patternColors = null) {
    // TODO: logan- if in advanced mode, pass in colors string (combined color keys string)
    let style = {};
    let url = helpers.urlRender.getDefaultDesignPatternThumbnailUrl(patternDef, patternColors);
    if (url) {
      style.backgroundImage = `url(${url})`;
    }
    return style;
  }

  render() {

    let {loadedDefPatterns, 
      defaultPatterns, 
      loadedDefs, 
      isDisabled,
      onChange } = this.props;

    let disabledClass = "";
    if (isDisabled) {
      disabledClass = "custom-disabled";
    }

    let patternGroupClass;
    let advanced = helpers.customLayers.isAdvancedMode();

    if (advanced) {
      patternGroupClass = "pattern-group-margin-top";
    } else {
      patternGroupClass = "pattern-group";
    }

    let configPattern = defaultPatterns;
    let togglePattern = this.buildPatternPreviewStyle(configPattern);

    return (
      <div className="patterns-container">
            <div key={configPattern.key + "advanced"}>
                <div key={configPattern.key} className={`pattern-card ${disabledClass}`}>
                  <div className={patternGroupClass}>
                    Design Patterns
                  </div>
                  <UncontrolledDropdown aria-label={this.props.toolTip} data-balloon-pos="up">
                    <DropdownToggle style={togglePattern} className="pattern-texture">
                <div className="advanced-layer-caret-container">
                  <div className="custom-caret"/>
                </div>
                    </DropdownToggle>
                    <DropdownMenu className="pattern-list-container fancy-scrollbar">
                      <DropdownItem header>Select a Pattern</DropdownItem>
                      <DropdownItem className="pattern-list fancy-scrollbar">
                        {loadedDefPatterns.map((e, i) => {
                          let patternStyle = this.buildPatternPreviewStyle(e);
                          return (
                            <div key={e.name + i + "pattern"} className="pattern-container">
                              <div className="pattern" style={patternStyle} 
                              onMouseEnter={() => this.props.onHover(e.key)} 
                              onMouseLeave={() => this.props.onHover(null)} 
                              onClick={() => onChange(e.key, 0, loadedDefs)}>
                              </div>
                              <div className="pattern-title">
                                {e.name}
                              </div>
                            </div>
                          )
                        })}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <div className="pattern-title">
                    {_.get(configPattern, "name")}
                  </div>
                </div>
            </div>
      </div>
    )
  }
}