import _ from 'lodash';
import helpers from '../../../helpers';

export default function mouseDown(event, props) {
  let target = event.target;
  if (target) {
    let locationCode = target.id.split('-')[1];
    // let targetType = target.id.split('-')[0];

    let currentPendingLocation = props.navigation.pendingLocation;
    let allowedEntities = helpers.locations.getLocationAllowedEntities(locationCode);
    if (currentPendingLocation) {

      let doesSelectedLocationAllowEntityType = _.find(allowedEntities, { key: currentPendingLocation.entity.type });
      let isLocationFilled = _.find(props.customConfig.locations, { code: locationCode });
      if (doesSelectedLocationAllowEntityType) {
        // if selected location allows entity type update pending location entity
        if (isLocationFilled) {
          props.setActiveLocation(locationCode, props.navigation);
          let tab = "decorations";
          let tabObj = {};
          if (isLocationFilled.entity.type === "custom-art") {
            tab = "decorations";
            tabObj = { tab: tab, subTab: 'add-art', page: 'sub-edit' };
          } else {
            tabObj = { tab: tab, subTab: 'add-text', page: 'add' };
          }
          props.SetEditDecorationType(isLocationFilled.entity.type);
          props.setActiveTab(tabObj);
          props.setPendingLocation(null);
        } else {
          let artAsset = null;
          let tabObj;
          if (currentPendingLocation.entity.type === "custom-art") {
            artAsset = _.cloneDeep(currentPendingLocation.entity);
            artAsset.id = artAsset.userArtId;
            let page = props.navigation.activeTab.page === "sub-add-art" ? "sub-add-art" : "add";
            tabObj = { tab: props.navigation.activeTab.tab, subTab: "add-art", page: page };
          } else {
            tabObj = { tab: props.navigation.activeTab.tab, subTab: "add-text", page: 'add' };
          }
          let pendingLocation = helpers.navigation.makePendingLocation(currentPendingLocation.entity.type, currentPendingLocation.entity.value, props.navigation.activeView, locationCode, artAsset);
          props.setActiveTab(tabObj);
          props.setPendingLocation(pendingLocation);
          props.setActiveLocation(locationCode, props.navigation);
        }
      } else {
        // if it does not support entity type then change pending location entity.
        let nextAllowedEntity = _.first(allowedEntities);
        if (props.navigation.activeTab.tab === 'decorations') {
          nextAllowedEntity = _.first(allowedEntities.filter(v => v.key === 'player-number' || v.key === 'custom-text'));
        }
        let value = nextAllowedEntity.placeholderValue;
        if (nextAllowedEntity.key === "custom-text") {
          value = props.navigation.customTextInput;
          if (value === null) {
            value = "";
          }
        } else if (nextAllowedEntity.key === "player-name") {
          value = props.navigation.playerNameInput;
          if (value === null) {
            value = "";
          }
        } else if (nextAllowedEntity.key === "player-number") {
          value = props.navigation.playerNumberInput;
          if (value === null) {
            value = "";
          }
        }
        if (isLocationFilled) {
          props.setActiveLocation(locationCode, props.navigation);
          let tab = "decorations";
          let tabObj = {};
          if (isLocationFilled.entity.type === "custom-art") {
            tab = "decorations";
            tabObj = { tab: tab, subTab: 'add-art', page: 'sub-edit' };
          } else {
            tabObj = { tab: tab, subTab: 'add-text', page: 'add' };
          }
          props.SetEditDecorationType(isLocationFilled.entity.type);
          // let tabObj = {tab: tab, subTab: "add-art", page:'sub-edit'};
          props.setActiveTab(tabObj);
          props.setPendingLocation(null);
        } else {
          let artAsset = null;
          if (currentPendingLocation.entity.type === "custom-art") {
            artAsset = _.cloneDeep(currentPendingLocation.entity);
            artAsset.id = artAsset.userArtId;
          }
          let pendingLocation = helpers.navigation.makePendingLocation(nextAllowedEntity.key, value, props.navigation.activeView, locationCode, artAsset);
          let tab = "decorations";
          let tabObj = {};
          if (nextAllowedEntity.key === "custom-art") {
            tab = "decorations";
            tabObj = { tab: tab, subTab: 'add-art', page: 'sub-edit' };
          } else {
            tabObj = { tab: tab, subTab: 'add-text', page: 'add' };
          }
          props.SetEditDecorationType(isLocationFilled.entity.type);
          // let tabObj = {tab: tab, subTab: "add-text", page:'add'};
          props.setActiveTab(tabObj);
          props.setPendingLocation(pendingLocation);
          props.setActiveLocation(locationCode, props.navigation);
        }
      }
    } else {
      // no current pending location. 
      // if it does not support entity type then change pending location entity.
      let isLocationFilled = _.find(props.customConfig.locations, { code: locationCode });
      if (isLocationFilled) {
        props.setActiveLocation(locationCode, props.navigation);
        let tab = "decorations";
        let tabObj = {};
        if (isLocationFilled.entity.type === "custom-art") {
          tab = "decorations";
          tabObj = { tab: tab, subTab: 'add-art', page: 'sub-edit' };
        } else {
          tabObj = { tab: tab, subTab: 'add-text', page: 'add' };
        }
        props.SetEditDecorationType(isLocationFilled.entity.type);
        // let tabObj = {tab: tab, subTab: "add-art", page:'sub-edit'};
        props.setActiveTab(tabObj);
        props.setPendingLocation(null);
      } else {
        let tabObj = { tab: props.navigation.activeTab.tab, subTab: "add-text", page: 'add' };
        props.setActiveTab(tabObj)
        props.setActiveLocation(locationCode, props.navigation);
      }
    }
  }
}


function findArtTab(entity) {
  let val = _.get(entity, "value", "");
  return (val === "system-art") ? "system-art" : "decorations";
}
