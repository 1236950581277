import * as types from "../../actions/actionTypes";
import {
  TOGGLE_MOBILE,
  SET_ACTIVE_TAB,
  SET_ACTIVE_LOCATION,
  SET_PENDING_ENTITY_DATA,
  CLEAR_ACTIVE_LOCATION,
  SET_PRODUCT_URLS,
  TOGGLE_BORDERS,
  PREVIEW_SAMPLE,
  DND_LOC_TO_LOC,
  DELETE_TOGGLE,
  ORIGINAL_ENTITY_POSITION,
  SET_PENDING_LOCATION,
  SET_CUSTOM_TEXT_INPUT,
  SET_FLIP_ART_INPUT
} from "./navigationActions";
import helpers from "../../../helpers";

require("es6-object-assign").polyfill();

const initialState = {
  borders: true,
  drag: false,
  inIframe: false,
  inputHasFocus: false,
  customTextInput: "",
  flipArtInput:false,
  activeView: "front",
  activeLocation: "",
  pendingLocation: null,
  isSafariCORS: helpers.navigation.getSafariCORS(),
  showAssetLibrary: true,
  editUserAssestsMode: false,
  activeTab: {
    tab: "colors",
    page: "main"
  },
  options: [
    { displayName: "Style", key: "style", isAvailable: true },
    { displayName: "Design", key: "add-design", isAvailable: true },
    { displayName: "Colors", key: "colors", isAvailable: true },
    { displayName: "Fabric", key: "fabric", isAvailable: true },
    { displayName: "Decorations", key: "decorations", isAvailable: true },
    // { displayName: "Add Art", key: "up-art", isAvailable: true },
    // { displayName: "Add Art", key: "system-art", isAvailable: false },
    { displayName: "Complete Roster", key: "names-and-numbers", isAvailable: true },
    { displayName: "Final Review", key: "order-summary", isAvailable: true }
  ],
  options1: [
    { displayName: "Style", key: "style", isAvailable: true },
    { displayName: "Design", key: "add-design", isAvailable: true },
    { displayName: "Colors", key: "colors", isAvailable: true },
    { displayName: "Fabric", key: "fabric", isAvailable: true },
    { displayName: "Decorations", key: "decorations", isAvailable: true },
    // { displayName: "Add Art", key: "up-art", isAvailable: true },
    // { displayName: "Add Art", key: "system-art", isAvailable: false },
    { displayName: "Size", key: "names-and-numbers", isAvailable: true },
    { displayName: "Final Review", key: "order-summary", isAvailable: true }
  ],
  displayMobileTab: false,
  dndLocToLoc: {
    hasLocation: false,
    locationA: null,
    locationB: null
  }
};

export default function navigationReducer(state = initialState, action) {
  switch (action.type) {
    case types.CHANGE_NAVIGATION_OPTION:
      state = Object.assign({}, state, action.option);
      return state;
    case types.TOGGLE_USER_EDIT_MODE:
      return (state = Object.assign({}, state, {
        editUserAssestsMode: action.isActive
      }));
    case types.DRAG_ART:
      return Object.assign({}, state, { drag: action.drag });
    case types.SET_VIEW:
      state = Object.assign({}, state, action.payload);
      return state;
    case SET_ACTIVE_TAB:
      state = Object.assign({}, state, action.payload);
      return state;
    case SET_ACTIVE_LOCATION:
      state = Object.assign({}, state, action.payload);
      return state;
    case SET_CUSTOM_TEXT_INPUT:
      state = Object.assign({}, state, action.payload);
      return state;
    case SET_PENDING_ENTITY_DATA:
      state = Object.assign({}, state, action.payload);
      return state;
    case SET_PENDING_LOCATION:
      state = Object.assign({}, state, action.payload);
      return state;
    case CLEAR_ACTIVE_LOCATION:
      state = Object.assign({}, state, action.payload);
      return state;
    case SET_PRODUCT_URLS:
      state = Object.assign({}, state, action.payload);
      return state;
    case TOGGLE_BORDERS:
      state = Object.assign({}, state, action.payload);
      return state;
    case PREVIEW_SAMPLE:
      state = Object.assign({}, state, action.payload);
      return state;
    case DND_LOC_TO_LOC:
      state = Object.assign({}, state, action.payload);
      return state;
    case ORIGINAL_ENTITY_POSITION:
      state = Object.assign({}, state, action.payload);
      return state;
    case TOGGLE_MOBILE:
      return Object.assign({}, state, action.payload);
    case DELETE_TOGGLE:
      return Object.assign({}, state, { inputHasFocus: action.payload });
    case types.TOGGLE_LOGOS:
      return Object.assign({}, state, { showAssetLibrary: action.setting });
    case types.SET_IN_IFRAME:
      return Object.assign({}, state, { inIframe: action.iframe });
    case types.SET_OPTIONS:
      return Object.assign({}, state, { options: action.options });
    case SET_FLIP_ART_INPUT:
        state = Object.assign({}, state, action.payload);
        return state;
    default:
      return state;
  }
}