export const chromagear = {
  locations: [],
  color: null,
  configId: null,
  decorationPricing: {},
  fabric: null,
  name: '',
  rosters: [],
  summaryNotes: '',
  upgrades: [],
  webRef: null,
  customLayers: {  // SETTING advance mode == true
    isAdvancedMode: true,
    primaryColors: [],
    primaryPattern: {key: null},
    layers: [],
  },
  isPlayerRoster: false,
  isSavedDesign: false
};

export const badgerBase = {
  locations: [],
  color: null,
  configId: null,
  decorationPricing: {},
  fabric: null,
  name: '',
  rosters: [],
  summaryNotes: '',
  upgrades: [],
  webRef: null,
  isPlayerRoster:false,
  isSavedDesign: false
};
