import React, { Component } from 'react';
import { connect } from 'react-redux';
import { messageIframe } from "../../../../redux/reducers/notifications/notificationsActions";
import ChromagearBadgerSVG from "../../../../assets/icons/ChromagearBadgerSVG";
import ChromagearBadgerBigSVG from "../../../../assets/icons/ChromagearBigSVG";

class SublimatedProductDetailsModal extends Component {
  designData = this.props.designNum;
  brandLogo = require("../sublimatedProductDetailsModal/CG-Logo_Horizontal_NoBG.png");

  cutomizeDesignButton() {
    return (
      <div className="row">
        <div className="col-6">
          <button type="button" className="btn btn-sm btn-master-customize " onClick={() => {
            let data = {
              style: this.props.styleNum,
              design: this.designData.designNum
            };
            this.props.messageIframe('launch-builder-app', data);
            // window.open("/?SKU=" + this.props.styleNum + "&designNum=" + this.designData.designNum,"_self") 
          }
          } >CUSTOMIZE THE DESIGN <ChromagearBadgerSVG></ChromagearBadgerSVG></button>
        </div>
        <div className="col-6">
          <button type="button" className="btn btn-sm btn-master-customize" onClick={() => {
            let data = {
              style: this.props.styleNum,
              design: this.designData.designNum,
              isMasterDesign: true
            };
            this.props.messageIframe('launch-builder-app', data);
            if (this.props.launchContext.isMasterDesign) {
              window.open("/?SKU=" + this.props.styleNum + "&designNum=" + this.designData.designNum + "&isMasterDesign=" + this.props.launchContext.isMasterDesign, "_self");
            }
          }
          } >CUSTOMIZE MASTER DESIGN <ChromagearBadgerSVG></ChromagearBadgerSVG></button>
        </div>
      </div>
    );
  }

  cutomizeButton() {
    return (
      <div>
        <button type="button" className="btn btn-lg btn-block btn-customize" onClick={() => {
          let data = {
            style: this.props.styleNum,
            design: this.designData.designNum
          };
          this.props.messageIframe('launch-builder-app', data);
          // window.open("/?SKU=" + this.props.styleNum + "&designNum=" + this.designData.designNum,"_self") 
        }
        } >CUSTOMIZE THE DESIGN <ChromagearBadgerBigSVG></ChromagearBadgerBigSVG></button>
      </div>
    );
  }

  render() {
    let indexFront = this.designData.views.map(function (views) { return views.view; }).indexOf("front");
    let indexBack = this.designData.views.map(function (views) { return views.view; }).indexOf("back");
    let indexLeft = this.designData.views.map(function (views) { return views.view; }).indexOf("left");
    let indexRight = this.designData.views.map(function (views) { return views.view; }).indexOf("right");
    return (
      <div className="preview-container" >
        <div className="container-fluid">
          <div className="row">
            <div className="col-7">
              <div className="row">
                <div className="col-12" id="middiv" >
                  <div id="myCarousel" className="carousel slide" data-ride="carousel" align="center">
                    <div className="row">
                      <div className="col-4">
                        <div className="row d-flex justify-content-center">
                          <div className="card h-100 border-0">
                            <div className="col-12 mb-1">
                              <div className="card modal-card-height mh-100 card-modal-hover">
                                <a id="carousel-selector-0" className="selected" data-slide-to="0" data-target="#myCarousel">
                                  <img src={this.designData.views[indexFront].previewURL} className="img-fluid card-img-top modal-card-img-top" />
                                </a>
                              </div>
                            </div>
                            <div className="col-12 mb-1">
                              <div className="card modal-card-height mh-100 card-modal-hover">
                                <a id="carousel-selector-1" data-slide-to="1" data-target="#myCarousel">
                                  <img src={this.designData.views[indexBack].previewURL} className="img-fluid card-img-top modal-card-img-top" />
                                </a>
                              </div>
                            </div>
                            <div className="col-12 mb-1">
                              <div className="card modal-card-height mh-100 card-modal-hover">
                                <a id="carousel-selector-2" data-slide-to="2" data-target="#myCarousel">
                                  <img src={this.designData.views[indexLeft].previewURL} className="img-fluid card-img-top modal-card-img-top" />
                                </a>
                              </div>
                            </div>
                            <div className="col-12 mb-1">
                              <div className="card modal-card-height mh-100 card-modal-hover">
                                <a id="carousel-selector-3" data-slide-to="3" data-target="#myCarousel">
                                  <img src={this.designData.views[indexRight].previewURL} className="img-fluid card-img-top modal-card-img-top" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-8 pl-0">
                        <div className="card h-100 pt-2">
                          <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                            <div className="carousel-inner">
                              <div className="carousel-item active">
                                <img src={this.designData.views[indexFront].previewURL} className="d-block w-100" alt="..." />
                              </div>
                              <div className="carousel-item">
                                <img src={this.designData.views[indexBack].previewURL} className="d-block w-100" alt="..." />
                              </div>
                              <div className="carousel-item">
                                <img src={this.designData.views[indexLeft].previewURL} className="d-block w-100" alt="..." />
                              </div>
                              <div className="carousel-item">
                                <img src={this.designData.views[indexRight].previewURL} className="d-block w-100" alt="..." />
                              </div>
                            </div>
                            <a className="carousel-control-prev text-dark" href="#carouselExampleControls" role="button" data-slide="prev">
                              <span className="carousel-control-prev-icon " aria-hidden="true"></span>
                              <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                              <span className="carousel-control-next-icon" aria-hidden="true"></span>
                              <span className="sr-only">Next</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-5">
              <div className="card border-0 h-100">
                <div className="row">
                  <div className="col-6">
                    <div className="row mb-4">
                      <div className="col-12 modal-heading-style">
                        {this.props.styleNum}
                      </div>
                      <div className="col-12 modal-heading-design-name text-uppercase font-weight-bold">
                        {this.props.name}
                      </div>
                      <div className="col-12 modal-heading-design">
                        {this.designData.designNum}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 d-flex justify-content-end">
                    <img src={this.brandLogo} alt="brand logo" className="brand-image" />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="pl-4">
                          <ul className="text-list">
                            <li>Personalize with your colors</li>
                            <li>Add team graphics</li>
                            <li>Player names and numbers</li>
                            <li>ALL INCLUSIVE PRICING</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    {this.props.launchContext.isMasterDesign ? this.cutomizeDesignButton() : this.cutomizeButton()}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    reduxState: state,
    launchContext: state.launchContext
  }
}
const reduxActions = {
  messageIframe,
}

export default connect(mapStateToProps, reduxActions)(SublimatedProductDetailsModal);

