import React, { Component } from "react";
import { connect } from "react-redux";
import { updateCell, addRow, removeRow, deleteAll, addMultipleRows, removeMultipleRows } from "../../../../redux/reducers/customConfig/customConfigActions";
import { setDecorationFlag } from "../../../../redux/reducers/decoration/decorationAction";

import { showToast } from "../../../../redux/reducers/toast/toastActions";
import _ from "lodash";
import helpers from "../../../../helpers";
import TabHeader from "../../../global/TabHeader/TabHeader";
import Table from 'react-bootstrap/Table';
import { triggerRenderPreviewImagesAction } from '../../../../redux/reducers/appActions/appActionsActions';

class SizeRoster extends Component {
  constructor(props) {
    super(props);

    this.state = {
      previewImg: []
    }
    if (this.props.customConfig.locations.length === 0) {
      let value = false;
      this.props.setDecorationFlag(value);
  }
  }

  componentDidMount() {
    this.Previews();
  }


  getsizeCount(size) {
    let count = 0
    if (this.props.customConfig.rosters.length > 0) {
      let sizeroster = this.props.customConfig.rosters[0].items
      sizeroster.forEach(sr => {
        if (size === sr.size) {
          count = count + 1
        }
      })
    }

    return count;
  }
  onLoadImages() {
    this.counter++;
    if (this.counter === this.state.previewImg.length) {
      this.setState({ loaded: true });
    }
  }
  async LoadPreviews() {
    return new Promise((resolve, reject) => {
      let timeoutRef = null;
      let handleDone = _.once((err, res) => {
        if (timeoutRef) { clearTimeout(timeoutRef); timeoutRef = null; }
        if (err) {
          return reject(err);
        }
        resolve(res);
      });
      this.props.triggerRenderPreviewImagesAction(res => {
        handleDone(null, res);
      });
    });
  }

  async Previews() {
    let previews = await this.LoadPreviews();
    this.setState({
      previewImg: previews
    });

  }

  updateQuantity(value, size) {
    const safeValue = Number(value);
    const isIncreasing = (size.quantity < safeValue);
    const isDecreasing = (size.quantity > safeValue);

    if (isIncreasing) {
      const objectToAdd = { name: null, number: null, size: size.key };
      this.props.addMultipleRows(objectToAdd, safeValue - size.quantity);
    } else if (isDecreasing) {
      this.props.removeMultipleRows(size.key, safeValue, size.quantity);
    }
  }

  getPricePerSize(size) {
    let sku, skuPrice, productPrice = 0;

    if (this.props.baseProduct.configurationType === 'custom-sub')
      sku = _.find(this.props.baseProduct.skus, x => (x.size === size));
    else if (this.props.baseProduct.configurationType === 'stock-dec')
      sku = _.find(this.props.baseProduct.skus, x => (x.size === size) && (x.color === this.props.customConfig.color));

    if (sku) {
      if (this.props.baseProduct.baseProductPrices && this.props.baseProduct.baseProductPrices !== null) {
        skuPrice = _.find(this.props.baseProduct.baseProductPrices, p => p.sku === sku.sku);
        productPrice = productPrice + (skuPrice ? skuPrice.price : 0);
      }
    }

    if (productPrice > 0) {
      productPrice = Number(productPrice).toFixed(2);
    }

    return productPrice;
  }

  render() {
    let inputTooltip = _.find(this.props.toolTips, { key: "quantity-input" }).message;
    let allowedProductSizes = helpers.product.getAllowedProductSizes();
    let sizeQuantities = helpers.roster.getSizeQuantitiesData2();

    const sizesWithQuantities = _.map(allowedProductSizes, sizeKey => {
      let sqItem = _.cloneDeep(_.find(sizeQuantities, { size: sizeKey }));
      if (!sqItem) { sqItem = { size: sizeKey, quantity: 0 }; }
      if (!sqItem.qty < 0) { sqItem.qty = 0; }
      sqItem.key = sizeKey;
      return sqItem;
    });

    return (
      <div className="row">
        <div className="col-12 roster-details">
          <Table bordered size="sm">
            <thead>
              <tr>
                <th colSpan={this.props.baseProduct.sizes.length}>
                  <div className="row">
                    <div className="col-1 pl-4">
                      {this.state.previewImg.map(list => {
                        if (list['view'] === 'front') {
                          return (
                            <img src={list['data']} alt="design" style={{ 'height': '60px', 'width': '60px', 'justifyContent': 'center' }} onLoad={() => { this.onLoadImages() }} loading="eager" />
                          )
                        }
                      })}
                    </div>
                    <div className="col-6 pl-0">
                      <TabHeader text={this.props.baseProduct.styleNum} name={this.props.baseProduct.name} ></TabHeader>
                    </div>
                    <div className="col-5">
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr rowSpan="3">
                {sizesWithQuantities.map((e, i) => {
                  return (
                    <td>
                      <div className="row">
                        <div className="col-6"><div className="size-key">{e.key}</div></div>
                        <div className="col-6 d-flex justify-content-end">
                          <div className="price-key">
                            ${this.getPricePerSize(e.key)}
                          </div>
                        </div>
                      </div>
                      <div aria-label={inputTooltip} data-balloon-pos="up" >
                        <input
                          onChange={f => this.updateQuantity(f.target.value, e, i)}
                          type="number"
                          value={e.quantity}
                          className="custom-input-field"
                          id="number"
                          min={0}
                          max={10000} />
                      </div>
                    </td>
                  )
                })}
              </tr>
            </tbody>
          </Table>
        </div>
      </div>

    );
  }
}

function mapStateToProps({ customConfig, baseProduct, toolTips, navigation }) {
  return { rosters: customConfig.rosters, sizes: baseProduct.sizes, toolTips: toolTips, baseProduct: baseProduct, style: baseProduct.legacyStyleNum, customConfig: customConfig, navigation: navigation };
}

const actions = { addRow, removeRow, updateCell, deleteAll, showToast, triggerRenderPreviewImagesAction, addMultipleRows, removeMultipleRows, setDecorationFlag};

export default connect(mapStateToProps, actions)(SizeRoster);