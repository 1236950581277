import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux"
import helpers from "../../../../helpers";
import { clearNotification } from "../../../../redux/reducers/notifications/notificationsActions";
import { setActiveTab, toggleBorders } from "../../../../redux/reducers/navigation/navigationActions";
import { deleteAll } from "../../../../redux/reducers/customConfig/customConfigActions";
import LocationSummary from "../addText/locationSummary/locationSummary";
import AddDecorationButton from "./AddDecorationButton/AddDecorationButton";
import TabHeader from "../../../global/TabHeader/TabHeader";
import DecorationDetails from "./DecorationDetails/DecorationDetails";
class AddDecoration extends Component {
    componentWillUnmount() {
        let Alllocations = this.props.customConfig.locations;
        let prevLoc = this.props.decoration.prevLocation;
        Alllocations = _.filter(Alllocations, loc =>
            // loc.saveEntity === true || (this.props.decoration.editDecorationType !== "" && loc.editEntity === true)
            loc.saveEntity === true
        );
        this.props.customConfig.locations = Alllocations;
        if (prevLoc !== "") {
            let Locations = this.props.customConfig.locations;
            let UpdatedLocations = [];
            _.map(Locations, loc => {
                if (loc.editEntity === false) {
                    prevLoc.editEntity = true;
                    UpdatedLocations.push(prevLoc);
                }
                else {
                    UpdatedLocations.push(loc);
                }
            })
            this.props.customConfig.locations = UpdatedLocations;
        }
        // let hasPlayerNameNumber = false;
        // this.props.customConfig.locations.forEach(item => {
        //     if (item.entity.type === 'player-name' || item.entity.type === 'player-number') {
        //         hasPlayerNameNumber = true;
        //     }
        // })
        // if (hasPlayerNameNumber) {
        //     this.props.customConfig.isPlayerRoster = true;
        // } else {
        //     this.props.customConfig.isPlayerRoster = false;
        // }
    }

    showDisplay() {
        let text;
        let display;
        let addTextDrawer = (
            <div id="drawer">
                <DecorationDetails />
            </div>
        );
        let decorationButton = (
            <div id="drawer">
                <AddDecorationButton />
            </div>
        );
        switch (this.props.navigation.activeTab.page) {
            case "main":
                let containsTextEntities = _.filter(this.props.customConfig.locations, x => x.entity.type === "custom-text" || x.entity.type === "player-name" || x.entity.type === "player-number" || x.entity.type === "custom-art");
                let containsPlayerInfo = _.filter(this.props.customConfig.locations, x => x.entity.type === "player-name" || x.entity.type === "player-number");
                let containsRosterInfo = _.get(this.props.customConfig, ['rosters', 0, 'items'], []);
                text = this.props.baseProduct.configurationType === 'custom-sub' ? this.props.baseProduct.legacyStyleNum : this.props.baseProduct.styleNum;
                if (this.props.baseProduct.configurationType === 'custom-sub') {
                    text = this.props.baseProduct.legacyStyleNum;
                    if (text.length > 6) {
                        text = text.slice(0, -1);
                    }
                }
                if (containsTextEntities.length > 0) {
                    if (this.props.navigation.borders === true) {
                        this.props.toggleBorders(false);
                    }
                    display = (<div id="drawer">
                        <TabHeader text={text} name={this.props.baseProduct.name}></TabHeader>
                        <div className="option-body">
                            <LocationSummary title={"Text"} filters={['player-name', 'custom-text', 'player-number', 'custom-art']} availableLocations={helpers.product.getOpenLocations(this.props.baseProduct.locations, this.props.customConfig.locations)} />
                        </div>
                    </div>);
                    let containsPlayerInfomation = _.filter(this.props.customConfig.locations, x => x.entity.type === "player-name" || x.entity.type === "player-number");
                    if (containsPlayerInfomation.length === 0 && containsRosterInfo.length > 0 && this.props.decoration.playerNameNumber) {
                        this.props.deleteAll();
                    }
                } else {
                    display = decorationButton;
                    if (containsPlayerInfo.length === 0 && containsRosterInfo.length > 0 && this.props.decoration.playerNameNumber) {
                        this.props.deleteAll();
                    }
                }
                break;
            case "add":
                display = addTextDrawer;
                break;
            case "edit":
                display = addTextDrawer;
                break;
            case "sub-add-art":
                display = addTextDrawer;
                break;
            case "duplicate":
                display = addTextDrawer;
                break;
            case "sub-edit":
                display = addTextDrawer;
                break;
            case "duplicate-art":
                display = addTextDrawer;
                break;
            default:
                display = (
                    <div> display defaulted</div>
                );
                break;
        }
        return display;
    }

    render() {
        return (
            <div className="fade-in">
                {this.showDisplay()}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        hover: state.hover,
        notifications: state.notifications,
        reduxState: state,
        customConfig: state.customConfig,
        navigation: state.navigation,
        colorOptions: state.colorOptions,
        baseProduct: state.baseProduct,
        decoration: state.decoration
    }
}

export default connect(mapStateToProps, { clearNotification, setActiveTab, toggleBorders, deleteAll })(AddDecoration)

//1. find all text locations that are available (all locations, open locations, and closed locations)
//2. all the locations that have text, are open to text or cannot have text
//3. find all different freestyle text, roster name text and roster number text (text, name or number)
//4. Either a name with free text, or number & name pointing to a roster
//5. Determine what fonts and styles are available (might have a standard set of fonts to use as default)

//build out the three views - add name text, number text and freestyle text
