import React, { Component } from "react";
import { connect } from 'react-redux';

 class DeleteModal extends Component {

   delete() {
    this.props.delete();
    this.props.toggle();
  }

   render() {
    return (
      <div className="delete-modal-container">
        <div className="delete-title">
          Do you want to delete this {this.props.entityName}?
     </div>
        <div className="delete-cancel-buttons-container">
          <div style={{ width: "50%" }}>
            <div className="delete-end-message">Don't worry -- Any previously uploaded artwork will still be available in your artwork library</div>
          </div>
          <div className="delete-modal-button-container">
            <button className="btn btn-outline-primary btn-cancel h-100 w-50" style={{ marginRight: "5px" }} onClick={() => this.props.toggle()}>Cancel</button>
            <button className={`btn btn-primary btn-next h-100 w-50`} onClick={() => this.delete()}>Delete</button>
          </div>
        </div>
      </div>
    )
  }
}
 function mapStateToProps(state) {
  return {
    reduxState: state
  }
}
 export default connect(mapStateToProps, {})(DeleteModal); 

