import React, { Component } from "react";
import { connect } from "react-redux";
import { messageIframe } from "../../redux/reducers/notifications/notificationsActions";
import _ from "lodash";
import SaveButton from "../header/mobileHeaderActions/saveButton";
import AddToCartButton from "../header/mobileHeaderActions/addToCartButton";
import LeadTime from "../header/mobileHeaderActions/leadTime";
import AppConstants from "../../constants";
import makeReadable from "../global/table/tableFormatter";
import { translatePrice } from "../../helpers/translation";
import {
  itemTotalModal,
  closeModal,
} from "../../redux/reducers/modal/modalActions";
import ItemTotalModal from "../../components/global/ultimateModal/itemTotalModal/ItemTotalModal";
import { ModalBody } from "reactstrap";
class Footer extends Component {
  constructor(...args) {
    super(...args);
    this.handleMouseHover = this.handleMouseHover.bind(this);
    this.handleMouseleave = this.handleMouseleave.bind(this);
    this.state = {
      productPrice: "0.00",
      itemTotal: 0,
      quantityNumber: 0,
      isHovering: false,
      content: [],
      dollars: 0,
      cents: 0.0,
      hasTableData: false,
      tableConfig: {},
      tableData: [],
    };

    if (
      this.props.baseProduct.designNum === null ||
      this.props.baseProduct.designNum === "default"
    ) {
      this.state.tooltipinfo = "stockTooltip";
    } else {
      this.state.tooltipinfo = "";
    }
  }

  handleMouseHover() {
    console.log("on mouse hover intial data", this.props.modal.isActive);
    this.props.modal.isActive = true;
    this.props.modal.message = "ItemTotalModal";
    console.log(
      "on mouse hover after changing data",
      this.props.modal.isActive
    );
    this.setState({ isHovering: true });
  }
  handleMouseleave() {
    // setTimeout(() => {
    console.log("on mouse leave", this.props.modal.isActive);
    this.props.modal.isActive = false;
    console.log(
      "on mouse leave after changing data",
      this.props.modal.isActive
    );
    this.setState({ isHovering: false });
    // }, 1000);
  }

  showAddButton() {
    let addToolTip = _.find(this.props.toolTips, { key: "add-to-cart-button" })
      .message;
    return (
      <div>
        <AddToCartButton
          configAcc={this.props.configAccount}
          accountId={this.props.actionAccount}
        />
      </div>
    );
  }

  render() {
    let saveToolTip = _.find(this.props.toolTips, { key: "save-button" })
      .message;
    let priceCents = this.props.price;
    if (priceCents <= 0) {
      priceCents = 0;
    }
    let priceStr = translatePrice(priceCents);
    let parts = _.split(priceStr, ".");
    let dollars = _.get(parts, 0, "0");
    let cents = "." + _.get(parts, 1, "00");

    const decorationPricing =
      _.get(this.props, "customConfig.decorationPricing") || {};
    this.decorationPricing = decorationPricing;
    let tableConfig = {
      columns: [
        {
          key: ["entityType", "upgradeKey"],
          name: "Type",
          symbol: null,
          lastRowVal: null,
        },
        {
          key: "numDecorationColors",
          name: "Colors",
          isCount: true,
          lastRowVal: null,
        },
        { key: "ink", name: "Ink", symbol: null },
        { key: "priceCents", name: "Price", symbol: "$" },
      ],
    };
    let tableReadyPricing = _.cloneDeep(decorationPricing);
    _.each(tableReadyPricing && tableReadyPricing.entities, (entity) => {
      let isInk =
        entity &&
        entity.decorationMethod &&
        _.includes(AppConstants.InkDecorationMethods, entity.decorationMethod);
      if (isInk) {
        entity.ink = entity.decorationMethod;
      }
      let value = entity.priceCents;
      value = (value + "").replace(/[^\d.-]/g, "");
      value = parseFloat(value);
      value = value ? value / 100 : 0;
      entity.priceCents = value.toFixed(2);
    });
    let tableData = makeReadable(tableReadyPricing, tableConfig);
    const hasTableData = !_.isEmpty(tableData);
    const roster = _.get(this.props, "customConfig.rosters") || {};
    if (roster != undefined) {
      let quantity = roster[0];
      if (quantity) {
        this.state.quantityNumber = quantity.items.length;
      }
    }
    this.state.quantityNumber =
      this.state.quantityNumber > 0 ? this.state.quantityNumber : 1;

    let defaultSize = _.filter(this.props.baseProduct.sizes, (s) => {
      if (s.includes("/")) {
        if (s.includes("L")) {
          return s[0];
        }
      } else if (s === "L") {
        return s;
      }
    });
    defaultSize = defaultSize ? defaultSize[0] : "L";

    let productPrice = 0;
    let rosterSku,
      sku,
      skuPrice,
      rosterSkuPrice,
      defaultSkuPrice,
      rosterQty = 0;
    let rosterFlag = false;

    if (this.props.customConfig.rosters.length > 0) {
      if (this.props.customConfig.rosters[0].items.length > 0) {
        rosterFlag = true;
        for (
          var i = 0;
          i < this.props.customConfig.rosters[0].items.length;
          i++
        ) {
          rosterQty =
            rosterQty +
            (this.props.customConfig.rosters[0].items[i].qty
              ? parseInt(this.props.customConfig.rosters[0].items[i].qty)
              : this.props.customConfig.rosters[0].items[i].qty &&
                this.props.customConfig.rosters[0].items[i].qty !== null
              ? this.props.customConfig.rosters[0].items[i].qty
              : 1);

          // Finding the sku from the base product based on the size for custom sub, size and color for stock dec
          if (this.props.baseProduct.configurationType === "custom-sub")
            sku = _.find(
              this.props.baseProduct.skus,
              (x) => x.size === this.props.customConfig.rosters[0].items[i].size
            );
          else if (this.props.baseProduct.configurationType === "stock-dec")
            sku = _.find(
              this.props.baseProduct.skus,
              (x) =>
                x.size === this.props.customConfig.rosters[0].items[i].size &&
                x.color === this.props.customConfig.color
            );

          // Finding the default sku price from base product prices list
          if (sku) {
            if (
              this.props.baseProduct.baseProductPrices &&
              this.props.baseProduct.baseProductPrices !== null
            ) {
              skuPrice = _.find(
                this.props.baseProduct.baseProductPrices,
                (p) => p.sku === sku.sku
              );
              productPrice = rosterQty
                ? skuPrice.price
                : productPrice + skuPrice.price;
            }
          }
        }
      } else {
        rosterFlag = false;

        // Finding the sku from the base product based on the size for custom sub, size and color for stock dec
        if (this.props.baseProduct.configurationType === "custom-sub")
          sku = _.find(
            this.props.baseProduct.skus,
            (x) => x.size === defaultSize
          );
        else if (this.props.baseProduct.configurationType === "stock-dec")
          sku = _.find(
            this.props.baseProduct.skus,
            (x) =>
              x.size === defaultSize &&
              x.color === this.props.customConfig.color
          );

        // Finding the default sku price from base product prices list
        if (sku) {
          if (
            this.props.baseProduct.baseProductPrices &&
            this.props.baseProduct.baseProductPrices !== null
          ) {
            skuPrice = _.find(
              this.props.baseProduct.baseProductPrices,
              (p) => p.sku === sku.sku
            );
            productPrice = productPrice + (skuPrice ? skuPrice.price : 0);
          }
        }
      }
    } else {
      rosterFlag = false;

      // Finding the sku from the base product based on the size for custom sub, size and color for stock dec
      if (this.props.baseProduct.configurationType === "custom-sub")
        sku = _.find(
          this.props.baseProduct.skus,
          (x) => x.size === defaultSize
        );
      else if (this.props.baseProduct.configurationType === "stock-dec")
        sku = _.find(
          this.props.baseProduct.skus,
          (x) =>
            x.size === defaultSize && x.color === this.props.customConfig.color
        );

      // Finding the default sku price from base product prices list
      if (sku) {
        if (
          this.props.baseProduct.baseProductPrices &&
          this.props.baseProduct.baseProductPrices !== null
        ) {
          skuPrice = _.find(
            this.props.baseProduct.baseProductPrices,
            (p) => p.sku === sku.sku
          );
          productPrice = productPrice + (skuPrice ? skuPrice.price : 0);
        }
      }
    }
    this.state.itemTotal =
      Number(this.state.quantityNumber) * Number(dollars + cents);
    if (productPrice > 0) {
      if (!rosterFlag)
        this.state.itemTotal =
          Number(this.state.quantityNumber) *
          (Number(productPrice) + Number(dollars + cents));
      else
        this.state.itemTotal = rosterQty
          ? rosterQty * (Number(productPrice) + Number(dollars + cents))
          : Number(productPrice) + Number(dollars + cents);
    }

    if (this.state.itemTotal >= 0) {
      this.state.itemTotal = this.state.itemTotal.toFixed(2);
    }

    this.state.quantityNumber =
      rosterQty > 0 && rosterQty ? rosterQty : this.state.quantityNumber;
    this.state.dollars = dollars;
    this.state.cents = cents;
    this.state.baseProductPrice = productPrice ? productPrice.toFixed(2) : 0.0;
    this.state.hasTableData = hasTableData;
    this.state.tableConfig = tableConfig;
    this.state.tableData = tableData;

    return (
      <div className="footer">
        <div className="container-fliud ">
          <div className="row row-padding">
            <div className="col-8">
              <div className="row">
                <div className="col-3">
                  <div className="row">
                    <div className="col-lg-1 col-xl-2"></div>
                    <div className="col-lg-9 col-xl-8 saveicon">
                      <SaveButton
                        toolTip={saveToolTip}
                        accountType={this.props.actionAccount}
                        configAcc={this.props.configAccount}
                      />
                    </div>
                  </div>
                </div>
                <div className="vertical-line"></div>
                <div className="col-3">
                  {/* code for lead time */}
                  <LeadTime />
                </div>
                <div className="vertical-line"></div>
                <div
                  className="col-3"
                  onMouseEnter={
                    this.props.baseProduct.configurationType === "stock-dec"
                      ? this.handleMouseHover
                      : undefined
                  }
                >
                  <div className="d-flex item-total-content">
                    <div className="item-total">ITEM TOTAL</div>
                    <div className="pl-3 value"> ${this.state.itemTotal}</div>
                    {this.state.isHovering === true &&
                    this.props.baseProduct.configurationType === "stock-dec" ? (
                      <div
                        className="card item-total-modal-card item-total-modal"
                        onMouseLeave={
                          this.props.baseProduct.configurationType ===
                          "stock-dec"
                            ? this.handleMouseleave
                            : undefined
                        }
                      >
                        <ModalBody size="modal-md" className="">
                          <ItemTotalModal contentType={this.state} />
                        </ModalBody>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="vertical-line"></div>
                <div className="col-3">{/* code for subtotal */}</div>
              </div>
            </div>
            <div className="col-4">
              {/* code for next step and button */}
              <div className="row">
                <div className="col-12 d-flex flex-row ">
                  <div className="next-step pr-4">NEXT STEP</div>
                  <div className="pl-2">
                    {!this.props.launchContext.isMasterDesign
                      ? this.showAddButton()
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    baseProduct: state.baseProduct,
    toolTips: state.toolTips,
    launchContext: state.launchContext,
    product: state.baseProduct,
    customConfig: state.customConfig,
    modal: state.modal,
  };
}

const reduxActions = {
  messageIframe,
  itemTotalModal,
  closeModal,
};

export default connect(mapStateToProps, reduxActions)(Footer);
