import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { EnableCanvasGrid, DisableCanvasGrid, SetDecorationSubtab, SetArtSubtab, SetEditDecorationType, SetDecorationType } from "../../../../../redux/reducers/decoration/decorationAction";
import { setActiveTab, toggleBorders } from "../../../../../redux/reducers/navigation/navigationActions";

import CardToggleButtonGroup from '../../../../global/cardToggleButtonGroup/cardToggleButtonGroup';
import EditDrawer from "../../editDrawer/EditDrawer";
import UploadArt from '../../uploadArt/UploadArt';
import BackArrowSVG from '../../../../../../src/assets/icons/BackArrowSVG';


class DecorationDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            toggleButton: this.props.decoration.canvasGrid,
            subTabs: ["TEXT", "GRAPHIC"],
            // selectedSubTab: this.props.decoration.selectedSubTab,
            selectedSubTab: "",
            selectedArtTab: this.props.decoration.selectedArtTab
        }
        if (this.props.decoration.canvasGrid === true) {
            this.props.toggleBorders(true)
        }
    }


    triggerToggle(event) {
        if (event.target.checked === true) {
            this.props.EnableCanvasGrid();
            this.setState({ toggleButton: true });

        }
        else {
            this.props.DisableCanvasGrid();
            this.setState({ toggleButton: false });
        }
    }

    showArt() {
        let display;
        if (this.props.navigation.activeTab.subTab = "add-art") {
            switch (this.props.navigation.activeTab.page) {
                case "add" || "sub-add-art":
                    display = (<div id="drawer" className={` fade-in`}>
                        <UploadArt />
                    </div>);
                    break;
                case "edit":
                    display = (<div id="drawer" className={` fade-in`}>
                        <EditDrawer />
                    </div>);
                    break;
                case "sub-edit":
                    display = (<div id="drawer" className={` fade-in`}>
                        <EditDrawer />
                    </div>);
                    break;
                case "duplicate-art":
                    display = (<div id="drawer" className={` fade-in`}>
                        <EditDrawer />
                    </div>);
                    break;
                default:
                    display = (
                        <UploadArt />
                    );
                    break;
            }
        }

        return display;
    }

    checkLocations() {
        let UpdatedLocations = this.props.customConfig.locations;
        UpdatedLocations = _.filter(UpdatedLocations, loc =>
            // loc.saveEntity === true || (this.props.decoration.editDecorationType !== "" && loc.editEntity === true)
            loc.saveEntity === true
        );
        this.props.customConfig.locations = UpdatedLocations;
    }

    onClickBackToDecorations() {
        let tabObj = { tab: "decorations", page: "main" };
        this.props.setActiveTab(tabObj);
        this.props.SetDecorationSubtab("");
        this.props.SetDecorationType("");
        this.props.SetEditDecorationType("");
    }

    render() {
        let selectedSubTab;
        return (
            <div className="text-location fade-in">
                <div className="row pt-2 decoration-header-row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-2 btn " onClick={() => this.onClickBackToDecorations()}>
                                <div className="row">
                                    <div className="col-2">
                                        <div className="arrow-svg">
                                            <BackArrowSVG
                                                fill="#000000"
                                                height="35"
                                                width="25"
                                                strokeWidth="5"
                                                viewBox="5 5 20 20"></BackArrowSVG>
                                        </div>
                                    </div>
                                    <div className="col-8 pl-2 btn-back-to-decoration text-left">BACK TO DECORATION SUMMARY</div>
                                </div>
                            </div>
                            <div className="col-7 pt-2 text-center">
                                {this.props.decoration.editDecorationType === "" ? <p className="decoration-tab-heading">ADD NEW DECORATION</p> : <p className="decoration-tab-heading">EDIT {this.props.decoration.editDecorationType.toUpperCase()}</p>}
                            </div>
                            <div className="col-3 d-flex justify-content-end pr-0">
                                <div className="row">
                                    <div className="col-4 d-flex justify-content-end pt-2">
                                        <p className="text-muted" >GRID</p>
                                    </div>
                                    <div className="col-6 pl-0">
                                        <div className="custom-control custom-switch custom-switch-lg">
                                            <input type="checkbox" className="custom-control-input" id="customSwitch1" checked={this.state.toggleButton === true} onChange={(e) => {
                                                if (e.target.checked === true) {
                                                    this.props.EnableCanvasGrid();
                                                    this.setState({ toggleButton: true });
                                                    this.props.toggleBorders(true);
                                                }
                                                else {
                                                    this.props.DisableCanvasGrid();
                                                    this.setState({ toggleButton: false });
                                                    this.props.toggleBorders(false)
                                                }
                                            }} />
                                            <label className="custom-control-label text-muted" htmlFor="customSwitch1">{this.state.toggleButton ? "ON" : "OFF"}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.decoration.editDecorationType === "" ?
                    <div className="row pt-2">
                        {/* {this.props.navigation.activeTab.page != 'edit' ? */}
                        <div className="col-12 padding-row">
                            <div className="row">
                                {
                                    this.state.subTabs.map((list, index) => {
                                        selectedSubTab = (list === this.props.decoration.selectedSubTab);
                                        return (
                                            <div className=" col-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12" key={index}>
                                                {/* <div className={this.props.decoration.editDecorationType !== "" ? "btn-decoration-disabled " : selectedSubTab ? "card btn-decoration-active " : "card btn-decoration"} onClick={this.props.decoration.editDecorationType === "" ? async (e) => { */}
                                                <div className={this.props.decoration.selectedSubTab === "" ? "card btn-decoration" : selectedSubTab ? "card btn-decoration-active " : "btn-decoration-disabled "} onClick={async (e) => {
                                                    if (list === 'TEXT') {
                                                        // this.setState({ selectedSubTab: list });
                                                        this.props.SetDecorationSubtab(list);
                                                        this.props.SetEditDecorationType("");
                                                        // this.props.SetArtSubtab("");
                                                        let tabObj = { tab: "decorations", page: "add", subTab: "add-text", };
                                                        this.props.setActiveTab(tabObj);
                                                        this.checkLocations();

                                                    } else {
                                                        // this.setState({ selectedSubTab: list });
                                                        this.props.SetDecorationSubtab(list);
                                                        this.props.SetEditDecorationType("");
                                                        // this.props.SetArtSubtab("");
                                                        let tabObj = { tab: "decorations", page: "add", subTab: "add-art" }
                                                        this.props.setActiveTab(tabObj);
                                                        this.checkLocations();
                                                    }
                                                }} >
                                                    <div className="card-body p-2">
                                                        <div className="card-text text-center ">
                                                            {list}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div> : ''}

                {this.props.decoration.selectedSubTab === "" ? null :
                    this.props.navigation.activeTab.tab === 'decorations' && this.props.navigation.activeTab.subTab === 'add-text' ?
                        <div className="row">
                            <div className=" col-12 ">
                                <div className="row col-outline">
                                    <div className="col-12">
                                        {this.props.navigation.activeTab.page != 'edit' ?
                                            <CardToggleButtonGroup />
                                            : ''}
                                    </div>
                                    <div className="col-12">
                                        <EditDrawer />
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="row">
                            <div className=" col-12 col-outline">
                                <div className="row">
                                    <div className="col-12 p-3">
                                        {this.showArt()}
                                    </div>

                                </div>
                            </div>
                        </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        hover: state.hover,
        notifications: state.notifications,
        reduxState: state,
        customConfig: state.customConfig,
        navigation: state.navigation,
        colorOptions: state.colorOptions,
        baseProduct: state.baseProduct,
        decoration: state.decoration
    }
}
let reduxActions = {
    setActiveTab,
    EnableCanvasGrid,
    DisableCanvasGrid,
    SetDecorationSubtab,
    toggleBorders,
    SetArtSubtab,
    SetEditDecorationType,
    SetDecorationType
}

export default connect(mapStateToProps, reduxActions)(DecorationDetails)
