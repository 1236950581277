import {CHANGE_DESIGN_COLOR}  from '../../actions/actionTypes';


const initialState = {
 isChanged: false,
 isDesignLoading: false,
};

// check the env config var to decide whether to show/hide this beta warning modal popup during init
// initialState.isActive = _.get(AppConfig, 'autoShowBetaWarning', initialState.isActive);


export default function changeReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_DESIGN_COLOR: return Object.assign({}, state, action.changeDesignColor);
    default:
      return state;
  }
}
