import React, { Component } from 'react';
import ArtGallery from './ArtGallery';
import { connect } from "react-redux";
import { SetDecorationSubtab, SetEditDecorationType, SetDecorationType, SetArtSubtab } from "../../../../../redux/reducers/decoration/decorationAction";
import { setActiveTab } from "../../../../../redux/reducers/navigation/navigationActions";

class MyUploads extends Component {

  constructor(props) {
    super(props);

    this.state = {
      editMode: false
    };
  }

  onClickUploadGraphic() {
    let subTab = 'GRAPHIC';
    this.props.SetDecorationSubtab(subTab);
    this.props.SetEditDecorationType("");
    let tabObj = { tab: "decorations", page: "add", subTab: "add-art" }
    this.props.setActiveTab(tabObj);
    this.props.SetArtSubtab("new-graphic");
  }

  render() {
    return (
      <div className="MyUploads">
        <div className="row artHeader pb-3">
          <div className="col-6 active-art-header text-center">
            <span className="class-pointer">
              My Art Locker
            </span>
          </div>
          <div className="col-6 inactive-art-header">
            <span className="class-pointer" onClick={() => this.onClickUploadGraphic()}>
              New Graphic
            </span>
          </div>
        </div>
        <ArtGallery count="10" />
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    hover: state.hover,
    notifications: state.notifications,
    reduxState: state,
    customConfig: state.customConfig,
    navigation: state.navigation,
    colorOptions: state.colorOptions,
    baseProduct: state.baseProduct,
    decoration: state.decoration
  }
}
let reduxActions = {
  setActiveTab,
  SetDecorationSubtab,
  SetEditDecorationType,
  SetDecorationType,
  SetArtSubtab
}

export default connect(mapStateToProps, reduxActions)(MyUploads)