import _ from "lodash";


class StateProvider {

  _init = false;
  _state = null;

  initReduxStore(store) {
    if (this._init) { throw new Error('you cannot set the redux store more than once'); }
    this._init = true;

    this._state = store.getState();
    store.subscribe(() => {
      this._state = store.getState();
    });
  }

  get state() {
    return this._state;
  }

  get(key = undefined, defaultValue = null) {
    if (key === undefined) { return this._state; }
    return _.get(this._state, key, defaultValue);
  }

}

const stateProvider = new StateProvider();
export default stateProvider;