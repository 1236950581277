import React, {Component} from "react";
import {connect} from 'react-redux';


class TabContent extends Component {

  render() {
    return (
      <div className={"fancy-scrollbar tab" + (this.props.navigation.displayMobileTab ? "" : " tab__hide")}>
        {this.props.children}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return  {
    navigation: state.navigation
  };
}


export default connect(mapStateToProps, null)(TabContent);