import React from "react";

const PlusSVG =(props)=>{
  const { className, height=32, width=32, fill="black", fill2="white", stroke = "black", strokeWidth = "0", onClick } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" className={className} height={height} width={width} fill={fill} fill2={fill2} stroke={stroke} strokeWidth={strokeWidth} onClick={onClick}>
      <g data-name="Group 819">
        <circle data-name="Ellipse 35" cx="64" cy="64" r="60.98" fill={fill2} />
        <path d="M63.61 8.73a52.69 52.69 0 0 1 39.2 16.47 55.78 55.78 0 0 1 16.47 39.2 52.71 52.71 0 0 1-16.47 39.2 54.88 54.88 0 0 1-39.2 16.46 52.7 52.7 0 0 1-39.2-16.46A54.92 54.92 0 0 1 8 64.4a52.69 52.69 0 0 1 16.41-39.2 53.92 53.92 0 0 1 39.2-16.47zm5.88 89V69.49h28.23v-11H69.49v-28.2h-11v28.23h-28.2v11h28.23v28.2z"/>
      </g>
    </svg>
  );
};

export default PlusSVG;