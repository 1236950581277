import React, { Component } from "react";
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

class InfoModal extends Component {

  close() {
    this.props.toggle();
  }

  render() {
    return (
      <div className="info-modal-container">
        <div className="row">
          <div className="col-12 pt-3">
            <div className="info-1">
              <p>Please click on <strong> Save & Share </strong> on the bottom left when you are finished </p>
            </div>
          </div>
        
        <div className="col-12 pt-3">
          <div className="info-1">
            <p> This will be how you can retrieve a Sharing Link for your new design, so you can share it with your dealer to come back to it at any time </p>
          </div>
        </div>
        <div className="col-12 pt-3 pl-5">
          <div className="row">
            <div className="col-4">
              <div className="text-center" >
                <FontAwesomeIcon icon={faChevronDown} className="fa-4x" />
              </div>
            </div>
            <div className="col-7 align-middle pt-3">
              <div className="buttons-container">
                <button className="btn btn-outline-dark btn-block btn-outline" onClick={() => this.close()}><strong>CONTINUE TO DESIGN </strong></button>
              </div>
            </div>
          </div>

        </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    reduxState: state
  }
}
export default connect(mapStateToProps, {})(InfoModal);

/*
Welcome to the NEW Founder’s Builder beta version!  Please be aware that you may encounter some minor software bugs as we continuously improve our builder.
All orders will be reviewed by a customer service representative, and art proofs will be sent within 1 business day.
Please send any special instructions or vector artwork (.ai, .eps, .pdf, etc) to artdepartment@badgersportswear.com.
If you are having trouble placing your order or looking to add tackle twill please contact our customer service builder specialists at decoration@foundersportgroup.com
*/

