import React, { Component } from 'react'

export default class UserArtNameInput extends Component {
  render() {
    return (<div className="userart__name">
    <div className="userart__name-label">Name</div>
    <input type="text" value={this.props.name} onChange={this.props.onChange} />
  </div>); 
  }
}
