import * as types from '../../actions/actionTypes';
import _ from 'lodash';



const NONE_COLOR_DEF = {
  id: null,
  hex: '#ffffff',
  key: 'none',
  rgb: 0xFFFFFF,
  code: null,
  name: 'None',
  rgbObj: {
    b: 255,
    g: 255,
    r: 255
  },
  swatchUrl: null
};


function _mergeLoadedDefsState(oldState, newState) {
  let mergedState = _.merge({}, oldState, newState);
  mergedState.colors = _.chain([])
    .concat(_.get(newState, 'colors'))
    .concat(_.get(oldState, 'colors'))
    .concat([NONE_COLOR_DEF])
    .uniqBy('key')
    .compact()
    .value();

  return mergedState;
}

export default function loadedDefsReducer(state = {}, action) {
  let newState = null;
  switch (action.type) {
    case types.MERGE_LOADED_DEFS:
      newState = _mergeLoadedDefsState(state, action.loadedDefs);
      break;
    case types.SET_LOADED_DEFS:
      newState = Object.assign({}, action.loadedDefs);
      break;

    default:
      return state;
  }

  return newState;
}
