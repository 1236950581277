import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { setActiveTab } from '../../../../../redux/reducers/navigation/navigationActions';
import PencilSVG from '../../../../../assets/icons/PencilSVG';
import _ from 'lodash';

function AddNote(props) {
  let locationContent = _.find(props.customConfig.locations, { code: props.navigation.activeLocation });
  let len = locationContent.entity.notes ? locationContent.entity.notes.length : 0;
  // sets the counter to length of notes in textArea or 0 if no notes
  const [count, setCount] = useState(len);
  useEffect(() => {
    let len = locationContent.entity.notes ? locationContent.entity.notes.length : 0;
    setCount(len)
  });
  const navigateToSummary = () => props.setActiveTab({ tab: "order-summary", page: "main" });

  const handleChange = (e) => {
    let locationContent = _.find(props.customConfig.locations, { code: props.navigation.activeLocation });
    locationContent.entity.notes = e.target.value;
    let len = e.target.value.length
    setCount(e.target.value.length);
  }

  // if (props.baseProduct.configurationType === 'custom-sub') {
  let value = locationContent.entity.notes !== null ? locationContent.entity.notes : ' ';
  let placeholder = "Enter notes for custom art here...";
  let title = "NOTES";
  let maxLength = 50;
  return (
    <div className=" w-100" style={{ marginTop: "15px", position: "relative" }}>
      <div className="row pl-4">
        <div className="col-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 label-padding-decoration pl-0 pr-0 pt-2">
          <label className="decoration-label">{title}</label>
        </div>
        <div className="col-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12">
          <textarea
            rows="2"
            maxlength={maxLength}
            className="text-height-number-input"
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
          ></textarea>
          <div className="pull-right "><small className="text-muted">{count}/{maxLength}</small></div>
        </div>
      </div>
    </div>
  )
  // } 
  // else {
  //     return (
  //       <button data-balloon-pos="up" className="add-art-container_navigate-button" onClick={navigateToSummary}>
  //         <PencilSVG width="20" height="20" />
  //         <span>Add A Note</span>
  //       </button>
  //     )
  //   }
}

function mapStateToProps(state) {
  return {
    navigation: state.navigation,
    customConfig: state.customConfig,
    baseProduct: state.baseProduct,
    loadedDefs: state.loadedDefs,
    toolTips: state.toolTips
  }
}

const reduxActions = {
  setActiveTab,
}

export default connect(mapStateToProps, reduxActions)(AddNote);