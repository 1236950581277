import React from "react";

const UploadCloudSVG = function(props) {
  const { className, height=32, width=32, fill="black", stroke="black", strokeWidth="0", onClick } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61.25 61" className={className} height={height}
           width={width} fill={fill} stroke={stroke} strokeWidth={strokeWidth} onClick={onClick}><path d="M51.75 9.36V0h-7v9.36h-9.5v7.28h9.5V26h7v-9.36h9.5V9.36h-9.5z"/><path d="M42 19H32V9H6a6 6 0 0 0-6 6v40a6 6 0 0 0 6 6h40a6 6 0 0 0 6-6V29H42zm3 36H7l9-12 6 8 11-12z"/></svg>
    // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 131 87.09" className={className} height={height} width={width} fill={fill} stroke={stroke} strokeWidth={strokeWidth} onClick={onClick}>
    //   <g data-name="Layer 2"><path d="M106 34S102 0 67 0 32 24 32 24 0 28 0 54c0 18.3 13.87 27.19 22.09 30.85A25.33 25.33 0 0 0 32.39 87H108s23 3 23-25-25-28-25-28zM91.16 48H77v23H54V48H39.84l-.5-.5L65.5 21.34 91.66 47.5z" data-name="Layer 1"/></g>
    // </svg>
  );
};

export default UploadCloudSVG;
